import { ITEMS_IDS } from '../actions/action';

const DEFAULT_STATE = [];

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ITEMS_IDS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
