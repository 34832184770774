import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { Div, fonts, spaces } from '../../styles/style';

import CenteredLoader from '../../components/Loader/CenteredLoader';
import useCRUD from '../../_Hooks/useCRUD';
import SimpleAccordion from '../../components/Accordion/SimpleAccordion';
import Description from '../../components/Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';

import { productColumns, chargeColumns } from '../../lib/mapping/TableOrList/productsChargeInvoiceColumns';
import SimpleTable from '../../components/Table/SimpleTable';
import TableOrListV2 from '../../components/List/TableOrListV2';
import { Paragraph, Subtitle } from '../../components/Text/Text';
import useViewport from '../../_Hooks/useViewport';
import { tableDynamicHeight } from '../../lib/helpers/helper';

const StyledDiv = styled.div`
  padding-bottom: ${spaces.space5};
  td {
    padding-right: ${spaces.space2};
  }
`;
const InvoicePage = ({ id, initialData }) => {
  const { modShipping } = useSelector(state => state.setup.enums);
  const { isMobile } = useViewport(window.innerWidth);
  const { data, handleGet, loading, setData } = useCRUD({
    model: 'paymentInvoice',
    immediatelyLoadData: false,
    pathOptions: `/${id}`
  });

  useEffect(() => {
    if (id) handleGet();
    else setData(initialData);
  }, [id, initialData]);

  return loading ? (
    <CenteredLoader />
  ) : (
    <StyledDiv>
      <Div align="start" padding={spaces.space2} direction="column" gap={spaces.space1}>
        <Subtitle weight={fonts.weight600}>Informações gerais</Subtitle>
        <Description
          column={{ md: 4, xs: 2 }}
          noPadding
          mapping={mappingDescriptionConf.invoiceInfo}
          data={data?.xmlInfo}
          $customAfter=" "
        />
      </Div>
      <Divider style={{ marginTop: 0 }} />
      <SimpleAccordion title="Emitente" initOpen id="emit-invoice" withPaddingContainer>
        <Description
          column={{ md: 4, xs: 2 }}
          noPadding
          mapping={mappingDescriptionConf?.emit}
          data={data?.emit}
          $customAfter=" "
        />
      </SimpleAccordion>
      <Divider />
      <SimpleAccordion title="Destinatário" initOpen id="dest-invoice" withPaddingContainer>
        <Description
          column={{ md: 4, xs: 2 }}
          noPadding
          mapping={mappingDescriptionConf?.dest}
          data={data?.dest}
          $customAfter=" "
        />
      </SimpleAccordion>
      <Divider />
      {data?.location ? (
        <>
          <SimpleAccordion
            title={`Local da ${data?.location?.type}`}
            initOpen
            id="location-invoice"
            withPaddingContainer
          >
            <Description
              column={{ md: 4, xs: 2 }}
              noPadding
              mapping={mappingDescriptionConf?.location}
              data={data?.location}
              $customAfter=" "
            />
          </SimpleAccordion>
          <Divider />
        </>
      ) : null}

      {data?.shippingCompany ? (
        <>
          <SimpleAccordion title="Transporte" initOpen id="shipping-invoice" withPaddingContainer>
            <Description
              column={{ md: 4, xs: 2 }}
              noPadding
              mapping={mappingDescriptionConf?.shippingCompany(modShipping)}
              data={data?.shippingCompany}
              $customAfter=" "
            />
          </SimpleAccordion>
          <Divider />
        </>
      ) : null}

      <SimpleAccordion title="Produtos" initOpen id="products-invoice" withPaddingContainer>
        <TableOrListV2
          rowSelection={false}
          $displayBlock
          columns={productColumns(isMobile())}
          pagination={false}
          list={data?.products}
          isExpandable={false}
          mobileItemFooter={null}
          readOnlyMobile
          scroll={{ y: tableDynamicHeight({ list: data?.products }), x: '100%' }}
        />
      </SimpleAccordion>
      <Divider />
      <SimpleAccordion title="Totais" initOpen id="totals-invoice" withPaddingContainer>
        <Description
          column={{ md: 5, xs: 2 }}
          noPadding
          mapping={mappingDescriptionConf?.invoiceTotals}
          data={data?.totals}
          $customAfter=" "
        />
      </SimpleAccordion>
      {data?.charges?.installments?.length ? (
        <>
          <Divider />
          <SimpleAccordion title="Duplicatas" initOpen id="charge-invoice" withPaddingContainer>
            <Div $maxWidth="460px">
              <SimpleTable
                columns={chargeColumns()}
                pagination={false}
                data={data?.charges?.installments}
                isExpandable={false}
              />
            </Div>
          </SimpleAccordion>
        </>
      ) : null}
      {data?.additionalInfo ? (
        <>
          <Divider />
          <SimpleAccordion title="Observações" initOpen id="obs-invoice" withPaddingContainer>
            <Paragraph type="small">{data?.additionalInfo}</Paragraph>
          </SimpleAccordion>
        </>
      ) : null}
    </StyledDiv>
  );
};
InvoicePage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.instanceOf(Object)
};
export default InvoicePage;
