import { toast } from 'react-toastify';
import { getBrazilianDate } from '../../lib/helpers/helper';

const getOrderWhenTargetIsStep = ({ isMovingUp, steps, targetStep }) => {
  const targetStepOrder = targetStep?.order || steps[1]?.order;

  if (isMovingUp) {
    const prevStepOrder = steps[steps.indexOf(targetStep) - 1]?.order || 0;
    return (parseFloat(targetStepOrder) + parseFloat(prevStepOrder)) / 2;
  }

  const { order: nextStepOrder, extra } = steps[steps.indexOf(targetStep) + 1] || {};

  if (extra) {
    return parseFloat(targetStepOrder) + 1;
  }

  return (parseFloat(targetStepOrder) + parseFloat(nextStepOrder)) / 2;
};

const getOrderWhenTargetIsTask = ({ isMovingUp, tasks, targetTask }) => {
  const targetTaskOrder = targetTask?.order;

  const taskIndex = tasks.indexOf(targetTask);

  if (isMovingUp) {
    const prevTaskOrder = tasks[taskIndex - 1]?.order || 0;
    return (parseFloat(prevTaskOrder) + parseFloat(targetTaskOrder)) / 2;
  }

  if (taskIndex === tasks.length - 1) {
    return parseFloat(tasks[taskIndex]?.order) + 1;
  }

  const nextTaskOrder = tasks[taskIndex + 1]?.order;

  return (parseFloat(targetTaskOrder) + parseFloat(nextTaskOrder)) / 2;
};

const onItemResize = ({ itemId, time, edge, groups, handleUpdateState }) => {
  if (!itemId) {
    return;
  }

  const item = groups.find(group => group.id === itemId);
  const date = edge === 'left' ? 'startDate' : 'endDate';
  const objTime = getBrazilianDate(time).toObject();

  if (item.isRoot && item.taskBasedDate) {
    toast.error('Para editar a data da etapa, remova o vínculo com as datas das tarefas.');
    return;
  }

  handleUpdateState(
    {
      id: itemId,
      startDate: item.startDate,
      endDate: item.endDate,
      [date]: getBrazilianDate(item[date]).isValid()
        ? getBrazilianDate(item[date])
            .year(objTime.years)
            .month(objTime.months)
            .date(objTime.date)
        : getBrazilianDate(time)
    },
    !item.isRoot
  );
};

const daysMapping = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
};

const countValidDays = (startDate, endDate, customDays) => {
  if (!startDate || !endDate) return 0;

  const { workDays, dayoffs = [] } = customDays || {};

  const start = getBrazilianDate(startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  const end = getBrazilianDate(endDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  let _workDays = null;

  let count = end.diff(start, 'day') + 1;

  if (workDays) {
    const dayOfWeekEnd = end.day();
    const dayOfWeekStart = start.day();

    const totalDiff = Math.abs(Math.floor(end.diff(start, 'day') / 7));

    const days = {
      sunday: totalDiff,
      monday: totalDiff,
      tuesday: totalDiff,
      wednesday: totalDiff,
      thursday: totalDiff,
      friday: totalDiff,
      saturday: totalDiff
    };

    days[daysMapping[dayOfWeekEnd]]++;

    for (let i = dayOfWeekStart; i !== dayOfWeekEnd; i++) {
      days[daysMapping[i]]++;
      if (i === 6) i = -1;
    }

    _workDays = Object.entries(workDays).filter(([, val]) => !val.active);

    _workDays.forEach(([key]) => {
      count -= days[key];
    });
  }

  if (dayoffs?.length) {
    const _dayoffs = dayoffs.filter(({ date }) => {
      const _dayoff = getBrazilianDate(date);

      if (_dayoff.year() === 1900) {
        let dayoff = getBrazilianDate(date).year(end.year());
        if (!_workDays?.find(([key]) => daysMapping[dayoff.day()] === key) && dayoff.isBetween(start, end, 'day')) {
          return true;
        }

        dayoff = getBrazilianDate(date).year(start.year());
        if (!_workDays?.find(([key]) => daysMapping[dayoff.day()] === key) && dayoff.isBetween(start, end, 'day')) {
          return true;
        }
        return false;
      }

      if (_workDays && _workDays.find(([key]) => daysMapping[_dayoff.day()] === key)) {
        return false;
      }
      return _dayoff.isBetween(start, end, 'day');
    });

    count -= _dayoffs.length;
  }

  return count;
};

const getPercentage = (startDate, endDate, verifyObj) => {
  if (!startDate || !endDate) return '';
  if (
    getBrazilianDate(startDate).isAfter(getBrazilianDate(), 'day') ||
    (!getBrazilianDate(startDate).isValid() && getBrazilianDate(endDate).isValid())
  )
    return '0%';
  if (!getBrazilianDate(endDate).isAfter(getBrazilianDate(), 'day')) {
    return '100%';
  }
  const days = countValidDays(startDate, endDate, verifyObj);
  const difference = countValidDays(startDate, getBrazilianDate(), verifyObj);

  return `${((parseFloat(difference) / days) * 100).toFixed(0)}%`;
};

const onItemMove = ({
  itemId,
  dragTime,
  newGroupOrder,
  isCustomer,
  groups,
  treeGroups,
  setPreventDoubleClick,
  handleUpdateState,
  setOpenGroups,
  _handleChange,
  verificationObj,
  findValidRange,
  updateArrow = () => {}
}) => {
  if (isCustomer || !itemId) {
    return null;
  }

  setPreventDoubleClick(true);

  const item = groups.find(group => group.id === itemId);
  const isChildren = !item?.isRoot;
  const dragTimeDate = getBrazilianDate(dragTime).toObject();

  if (item.isRoot && item.taskBasedDate) {
    return toast.error('Para editar a data da etapa, remova o vínculo com as datas das tarefas.');
  }

  const validDaysDiff = countValidDays(item.startDate, item?.endDate, verificationObj);

  let newStartDate = getBrazilianDate(item?.startDate)
    .year(dragTimeDate.years)
    .month(dragTimeDate.months)
    .date(dragTimeDate.date);

  let newEndDate = getBrazilianDate(item?.endDate)
    .year(dragTimeDate.years)
    .month(dragTimeDate.months)
    .date(dragTimeDate.date);

  [newStartDate, newEndDate] = findValidRange(newStartDate, newEndDate, validDaysDiff);

  const values = {
    id: itemId,
    idRefurbishStep: item.idRefurbishStep,
    idTemplateStep: item.idTemplateStep,
    idParent: item.idParent,
    startDate: newStartDate,
    endDate: newEndDate
  };

  const target = treeGroups[newGroupOrder];
  const isMovingSideways = target?.id === itemId;

  if (isMovingSideways) {
    setTimeout(() => {
      setPreventDoubleClick(state => !state);
    }, 50);
    return handleUpdateState(values, isChildren);
  }

  const targetIndex = groups.findIndex(group => group.id === target.id);
  const groupIndex = groups.findIndex(group => group.id === itemId);
  const prevGroup = groups[targetIndex - 1] || {};

  const isMovingUp = targetIndex < groupIndex;
  const isMovingToAnotherStep = target?.idRefurbishStep !== item?.idRefurbishStep;

  if (isChildren && isMovingToAnotherStep) {
    const targetIdRefurbishStep = target?.extra ? prevGroup?.idRefurbishStep : target?.idRefurbishStep;
    const stepGroupId = groups.find(group => {
      return group?.idRefurbishStep === targetIdRefurbishStep && group?.isRoot;
    }).id;

    setOpenGroups(state => {
      return { ...state, [stepGroupId]: true };
    });
    values.idRefurbishStep = targetIdRefurbishStep;
  }

  const steps = groups?.filter(group => group?.isRoot);
  const stepTasksGroups = groups?.filter(group => !group.isRoot && group.idRefurbishStep === target.idRefurbishStep);

  // target is step
  if (target?.isRoot) {
    // target is "adicionar"
    if (target?.extra) {
      if (prevGroup?.isRoot) {
        values.order = parseFloat(prevGroup?.order) + 1;
      } else {
        values.order = item.isRoot
          ? parseFloat(steps[steps.length - 2]?.order) + 1
          : parseFloat(groups[groups.length - 2]?.order) + 1;
      }
    } else {
      values.order = !item.isRoot
        ? parseFloat(stepTasksGroups[0]?.order || 2) / 2
        : getOrderWhenTargetIsStep({ isMovingUp, steps, targetStep: target });
    }
  }

  // target is task
  else if (item.isRoot) {
    const indexOfStepOfTargetTask = steps.findIndex(step => step.idRefurbishStep === target.idRefurbishStep);
    const targetStep = steps[indexOfStepOfTargetTask];
    // aqui mesmo o target não sendo step, eu usei essa função
    // pois o comportamento deve ser o mesmo de quando for step
    values.order = getOrderWhenTargetIsStep({ isMovingUp, steps, targetStep });
  } else
    values.order = getOrderWhenTargetIsTask({
      isMovingUp,
      targetTask: target,
      tasks: stepTasksGroups
    });

  setTimeout(() => {
    setPreventDoubleClick(state => !state);
  }, 50);

  updateArrow();

  return _handleChange(values, isChildren);
};

export { onItemMove, onItemResize, getPercentage, countValidDays };
