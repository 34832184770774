import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../store/auth/actions/action';
import { AnonymousContainer, StyledButton } from './AnonymousViewAlert.style';

const AnonymousViewAlert = () => {
  const { user } = useSelector(state => state.authReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const { refurbishToken } = useSelector(state => state.authReducer) || {};

  const handleRegister = () => {
    dispatch(logout());

    history.push(`/login/cadastrar?redirect=/convite/${refurbishToken}?&redirect2=${pathname}`);
  };

  const handleLogin = () => {
    dispatch(logout());

    history.push(`/login?redirect=/convite/${refurbishToken}?&redirect2=${pathname}`);
  };

  return user?.anonymous ? (
    <AnonymousContainer>
      <span id="anonymous-view-alert">
        Para poder interagir com os itens, crie uma conta{' '}
        <StyledButton aria-hidden="true" onClick={handleRegister} id="register-button">
          aqui
        </StyledButton>
        ou faça{' '}
        <StyledButton aria-hidden="true" onClick={handleLogin} id="login-button">
          login
        </StyledButton>
      </span>
    </AnonymousContainer>
  ) : null;
};

export default AnonymousViewAlert;
