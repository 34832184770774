import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Footer from './Footer';
import ConfirmModal from '../Modal/ConfirmModal';
import { Div, spaces } from '../../styles/style';

const QuoteSupplierFooter = ({ handleSave, handleRefuse, isViewAsSupplier }) => {
  const [isRefuse, setIsRefuse] = useState();
  const [showModal, setShowModal] = useState();
  return (
    <Footer>
      <div />
      <Div gap={spaces.space2}>
        <Button
          id="quote-supplier-refuse"
          gosth
          width="120px"
          disabled={isViewAsSupplier}
          onClick={() => {
            setIsRefuse(true);
            setShowModal(true);
          }}
        >
          Recusar cotação
        </Button>
        <Button
          id="quote-supplier-save"
          type="primary"
          width="120px"
          disabled={isViewAsSupplier}
          onClick={() => setShowModal(true)}
        >
          Salvar e enviar
        </Button>
      </Div>
      {showModal && (
        <ConfirmModal
          onSubmit={isRefuse ? handleRefuse : handleSave}
          onClose={() => setShowModal(false)}
          text={
            isRefuse
              ? 'Deseja realmente recusar esse pedido de cotação?'
              : 'Deseja enviar essa proposta de cotação para a empresa?'
          }
        />
      )}
    </Footer>
  );
};

QuoteSupplierFooter.propTypes = {
  handleSave: PropTypes.func,
  handleRefuse: PropTypes.func,
  isViewAsSupplier: PropTypes.bool
};

export default QuoteSupplierFooter;
