import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { AboutContent, Group } from './Onboarding.styled';
import { Subtitle, Title } from '../../../components/Text/Text';
import Input from '../../../components/Input/Input';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import { fonts, spaces, colors } from '../../../styles/style';

const CompanyDetails = ({ data, onChange }) => {
  const [_data, setData] = useState(data);

  const handleChange = ({ onboarding }) => {
    const newData = { onboarding: { ..._data?.onboarding, ...onboarding } };
    setData(newData);
    onChange(newData);
  };

  const tooltipText = (
    <>
      Áreas como:
      <ul>
        <li>• Engenharia</li>
        <li>• Comercial</li>
        <li>• Suprimentos</li>
        <li>• Similares</li>
      </ul>
    </>
  );

  return (
    <AboutContent>
      <Group gap={spaces.space1}>
        <Title size={fonts.sizeH5} id="onboarding-page-title">
          Estamos quase lá!
        </Title>
        <Subtitle weight={fonts.weight500} color={colors.neutral600}>
          Estamos nos últimos passos para personalizar sua experiência.
        </Subtitle>
      </Group>

      <Group maxWidth="616px">
        <Group gap={spaces.space2}>
          <Group gap="0px">
            <Subtitle color={colors.neutral600}>Qual é o tamanho da equipe que compõe sua empresa?</Subtitle>
            <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral500}>
              (Considere todos aqueles envolvidos diretamente nas atividades da empresa, incluindo sócios, funcionários
              com carteira assinada)
            </Subtitle>
          </Group>
          <Input
            type="number"
            placeholder="Informe somente o número"
            value={_data?.onboarding?.teamSize}
            onChange={e => handleChange({ onboarding: { teamSize: e?.target?.value } })}
          />
        </Group>

        <Group gap={spaces.space2} width="100%">
          <Subtitle color={colors.neutral600}>
            Agora, informe quantas trabalham somente no administrativo
            <TooltipIcon
              style={{ color: colors.primary600, marginLeft: '10px' }}
              text={tooltipText}
              icon={faInfoCircle}
              tooltipColor={colors.neutral700}
            />
          </Subtitle>
          <Input
            type="number"
            placeholder="Informe somente o número"
            value={_data?.onboarding?.adminSize}
            onChange={e => handleChange({ onboarding: { adminSize: e?.target?.value } })}
          />
        </Group>
      </Group>
    </AboutContent>
  );
};

CompanyDetails.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default CompanyDetails;
