import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { units } from '../../lib/mapping/Form/extraFormSchema';
import { compositionInlineFieldsMapping as inlineFieldsMapping, itemSchema } from '../../lib/mapping/Form/itemSchema';
import { bdiValuesColumns, columns } from '../../lib/mapping/TableOrList/compositionItemsColumns';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import { colors } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import AddTableButton from '../Button/AddTableButton';
import ListDrawer from '../Drawer/ListDrawer';
import TableOrList from '../List/TableOrList';
import { Container, TableHeader, TotalTable } from './CompositionItems.styled';
import CompositionMobileCard from './CompositionMobileCard';
import EditItemModal from '../Modal/EditItemModal';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import { Subtitle } from '../Text/Text';

const CompositionItems = ({
  isCustomer,
  idReference,
  model,
  handleLoadItems,
  list,
  handleCreate,
  setOpenCatalog,
  setExtraDrawerStyle = f => f,
  renderColumns,
  readOnly,
  isTemplate,
  idCompany,
  parentData,
  externalTriggerProps,
  data,
  hideProductService
}) => {
  const { refurbishItemType, specificationDefaultColumnsToShow } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const expandedRows = useRef([]);
  const { isMobile } = useViewport(window.innerWidth);
  const [showUnitItem, setShowUnitItem] = useState(false);
  const [forceCellUpdate, setForceCellUpdate] = useState('');
  const [editItem, setEditItem] = useState();
  const _hasPermissionUnitEdit = hasPermission(user, ['units'], plans, permissions);
  const [loading, setLoading] = useState(false);
  const { setEditOrCreateComposition, setPreventSave } = externalTriggerProps || {};

  const { list: unitList, handleGet: getUnit } = useCRUD({
    model: 'unit',
    options: { where: { idCompany }, order: [['name', 'desc']] },
    immediatelyLoadData: !isCustomer
  });

  const { handleDelete, handleUpdate } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleDelete: deleteRelation, handleUpdate: updateRelation } = useCRUD({
    model: 'relation-item',
    immediatelyLoadData: false
  });

  const refetchLists = column => () => {
    const loadList = {
      unit: getUnit
    };
    loadList[column]();
  };

  const handleItemClick = clickData => {
    const { modal } = clickData;
    if (modal === 'catalogDrawer') {
      setOpenCatalog(true);
      setExtraDrawerStyle(156);
    }
    if (modal === 'createComposition') {
      setEditOrCreateComposition(true);
    }
  };

  const AddButton = useCallback(
    params => (
      <AddTableButton
        {...params}
        buttonId="add-composition-item"
        model={allDropdown.addCompositionItems(isMobile())}
        type="dropdown"
        handleCreate={handleCreate}
        onItemClick={handleItemClick}
        showParentReference
        buttonStyle={{ marginTop: '-6px' }}
        formProps={
          !isMobile()
            ? {
                mapping: inlineFieldsMapping({ refurbishItemTypeEnum: refurbishItemType }),
                schema: itemSchema({}),
                onSubmit: handleCreate,
                addTextStyle: { color: colors.neutral400 },
                style: { width: '100%' }
              }
            : undefined
        }
      />
    ),
    []
  );

  const handleChange = (row, field) => value => {
    setPreventSave(true);
    const notRelationItem = row?.idRefurbish || row?.idTemplate || field !== 'quantity';
    const update = notRelationItem ? handleUpdate : updateRelation;
    update({
      id: notRelationItem ? row?.id : row?.relationItem?.id,
      values: { [field]: value },
      updateOptions: {},
      refresh: false
    }).then(() => {
      setPreventSave(false);
      handleLoadItems(false);
    });
  };

  const handleEditItem = itemToEdit => {
    if (itemToEdit?.type === refurbishItemType.composition) {
      setEditOrCreateComposition(itemToEdit);
      return;
    }
    setEditItem(itemToEdit);
  };

  const _handleDelete = row => {
    const { id, relationItem, idParent, idRefurbish, idTemplate } = row;
    const notRelationItem = idRefurbish || idTemplate;
    const deleteFn = notRelationItem ? handleDelete : deleteRelation;
    if (!id) return;
    setForceCellUpdate('actions');
    setLoading(prev => !prev);
    deleteFn({ id, values: { idParent: relationItem?.idParent || idParent }, refresh: false }).then(resp => {
      setLoading(prev => !prev);
      if (!resp?.error) {
        toast.success('Item removido da composição com sucesso.');
        handleLoadItems(false);
      }
    });
  };

  useEffect(() => {
    setForceCellUpdate('unit');
  }, [unitList]);

  const _columns = columns({
    expandedRows,
    refurbishItemType,
    isCustomer,
    handleChange,
    onRemoveClick: _handleDelete,
    isMobile: isMobile(),
    refetchLists,
    unitList,
    hasPermission: _hasPermissionUnitEdit,
    setShowUnitItem,
    forceCellUpdate,
    loading,
    idReference,
    columnsToShow: renderColumns,
    readOnly,
    parentData
  });

  const _bdiValuesColumns = bdiValuesColumns({
    isMobile: isMobile(),
    hideProductService
  });

  const dataSource = [
    ...(data?.totalBdi !== data?.total && idReference
      ? [
          {
            rowName: 'Preço total',
            totalProduct: data?.totalProduct,
            totalLabor: data?.totalLabor,
            totalBdi: data?.totalBdi
          },
          {
            rowName: 'Custo total',
            totalProduct: data?.totalProductRaw,
            totalLabor: data?.totalLaborRaw,
            totalBdi: data?.total
          }
        ]
      : [
          {
            rowName: 'Total',
            totalProduct: data?.totalProductRaw,
            totalLabor: data?.totalLaborRaw,
            totalBdi: data?.total
          }
        ])
  ];

  return (
    <>
      <Container>
        <TableHeader>
          <Subtitle>Itens</Subtitle>
        </TableHeader>
        {isMobile() ? (
          <CompositionMobileCard list={list} renderColumns={renderColumns} />
        ) : (
          <TableOrList
            list={list}
            columns={_columns}
            AddComponent={!isCustomer && !readOnly ? AddButton : undefined}
            addItemText="+ Adicionar item"
            addSubItemText="+ Adicionar item à"
            margin="0 0"
            customRowKey={row => row.key}
            onClick={handleEditItem}
          />
        )}
        {showUnitItem && (
          <ListDrawer
            onClose={() => {
              setShowUnitItem(false);
              refetchLists('unit')();
            }}
            model="unit"
            where={{ isActive: true }}
            tab="units"
            title="Configuração das unidades"
            subtitle="Unidades"
            renderTabs={units}
            textToDelete="Ao excluir esse item, ele será removido permanentemente da
          sua lista e de todos os produtos e serviços em que estiver sendo utilizado."
          />
        )}
        {editItem?.id ? (
          <EditItemModal
            {...editItem}
            idReference={idReference}
            model={idReference ? model : 'item'}
            inCludeRefurbish={false}
            onClose={item => {
              if (item) handleLoadItems(item);
              setEditItem(null);
            }}
            renderColumns={renderColumns || specificationDefaultColumnsToShow}
            isLibrary={model === 'item'}
            isTemplate={isTemplate}
            readOnly={readOnly}
          />
        ) : null}
      </Container>
      {renderColumns?.price || !isCustomer ? (
        <TotalTable>
          <TableOrList list={dataSource} columns={_bdiValuesColumns} margin="0 0" />
        </TotalTable>
      ) : null}
    </>
  );
};

CompositionItems.propTypes = {
  isCustomer: PropTypes.bool,
  idReference: PropTypes.number,
  handleLoadItems: PropTypes.func,
  model: PropTypes.string,
  list: PropTypes.arrayOf(Array),
  handleCreate: PropTypes.func,
  setOpenCatalog: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  setExtraDrawerStyle: PropTypes.func,
  renderColumns: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  isTemplate: PropTypes.bool,
  idCompany: PropTypes.number,
  parentData: PropTypes.instanceOf(Object),
  externalTriggerProps: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  hideProductService: PropTypes.bool
};

export default CompositionItems;
