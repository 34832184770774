import styled from 'styled-components';
import { fonts, spaces } from '../../styles/style';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spaces.space4};
  width: 100%;
`;

const Description = styled.span`
  margin: ${spaces.space3} ${spaces.space2};
  font-size: ${fonts.sizeSm};
`;

export { Content, Description };
