import React from 'react';
import PropTypes from 'prop-types';

import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

const { TabPane } = AntdTabs;
const StyledLabel = styled.label`
  display: inline-block;
  font-size: ${props => props.$fontSize};
  color: ${props => props.$color};
  font-weight: ${props => props.$fontWeight};
  margin-bottom: ${props => props.$marginBottom};
`;

const Label = ({
  fontSize = fonts.sizeSm,
  color = colors.neutral500,
  fontWeight = 400,
  marginBottom = spaces.space1,
  children,
  ...props
}) => {
  return (
    <StyledLabel $fontSize={fontSize} $color={color} $fontWeight={fontWeight} $marginBottom={marginBottom} {...props}>
      {children}
    </StyledLabel>
  );
};

Label.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
};

export default Label;

export { TabPane };
