import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { Select } from 'antd';
import useViewport from '../../_Hooks/useViewport';
import useCRUD from '../../_Hooks/useCRUD';

import Modal from './Modal';
import Form from '../Form/Form';
import Button from '../Button/Button';

import { colors, spaces, breakpoints, fonts, radius } from '../../styles/style';

import { schema, templateMapping } from '../../lib/mapping/Form/templateSchema';

const StyledModal = styled.div`
  margin-top: ${spaces.space1};
  justify-content: space-between;
  width: 100%;
  display: flex;
  gap: ${spaces.space1};
`;

const RenderTemplateModuleStyle = styled.div`
  display: contents;
  span {
    margin-left: ${spaces.space2};
    padding: 2px 5px;
    border-radius: ${radius.radius1};
    color: ${props => (props.options.module === 1 ? colors.teal500 : colors.primary600)};
    background: ${props => (props.options.module === 1 ? colors.teal100 : colors.neutral100)};
  }
`;

const TemplateModal = styled.div`
  border-radius: ${radius.radius1};
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${breakpoints.tablet}) {
    border: none;
    justify-content: flex-start;
    overflow: auto;
  }
  span {
    font-size: ${fonts.sizeSm};
    font-weight: 100;
    display: inline-flex;
    b {
      font-weight: 600;
      margin-right: ${spaces.space0};
      @media (max-width: ${breakpoints.tablet}) {
        margin-right: ${spaces.space1};
        font-size: ${fonts.sizeLg};
      }
    }
    li {
      width: 100%;
      button {
        padding: ${spaces.space1} ${spaces.space2};
      }
    }
  }
  button {
    padding: 0;
    height: auto;
    border-radius: 0;
    margin-left: ${spaces.space2};
    @media (max-width: ${breakpoints.tablet}) {
      margin-left: 0;
    }
    span {
      font-size: ${fonts.sizeLg};
      font-weight: ${fonts.weight500};
    }
  }
  div {
    @media (max-width: ${breakpoints.tablet}) {
      &:last-child {
        button {
          color: ${colors.white};
          margin-left: ${spaces.space1};
        }
      }
    }
  }
  .c-bulk-actions-list {
    display: flex;
    @media (max-width: ${breakpoints.tablet}) {
      margin-left: 0;
      overflow: hidden;
    }
  }
`;

const AddToTemplateModal = ({ onClose = f => f, list, selectedItems, idRefurbish }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { userType, templateModule } = useSelector(state => state.setup.enums) || {};
  const [isCreate, setIsCreate] = useState(false);
  const [idTemplate, setIdTemplate] = useState(null);
  const isOperator = user.userType === userType.operator.value;

  const { handleCreate: handleCreateTemplate, loading: submitLoading } = useCRUD({
    model: `refurbish-items/template`,
    immediatelyLoadData: false
  });

  const { list: listTemplate, loading } = useCRUD({
    model: 'template',
    options: { onlyMine: !isOperator }
  });

  const getElementsIds = () => {
    const elementsIds = [];

    selectedItems.forEach(item => {
      if (item.idParent) {
        elementsIds.push(item.idParent);

        const parent = list?.find(el => el.children?.find(child => child.id === item.idParent));
        if (parent) elementsIds.push(parent.id);
      }

      elementsIds.push(item.id);
    });

    return [...new Set(elementsIds)];
  };

  const createOrApplyTemplate = ({ name, description, type, module, category }) => {
    handleCreateTemplate({
      values: {
        idRefurbish,
        description,
        name,
        idItems: getElementsIds(),
        idTemplate,
        type,
        module,
        category
      },
      displayToast: isCreate ? 'Template criado com sucesso' : 'Operação realizada com sucesso!',
      refresh: false
    }).then(() => {
      onClose();
    });
  };

  const RenderTemplateType = ({ options }) => {
    return (
      <RenderTemplateModuleStyle options={options}>
        {options.name}
        <span>{options.module === templateModule.budget.value ? 'Orçamento' : 'Projeto'}</span>
      </RenderTemplateModuleStyle>
    );
  };

  RenderTemplateType.propTypes = {
    options: PropTypes.instanceOf(Object)
  };

  return (
    <Modal
      formId="template"
      title={isCreate ? 'Dê um nome para seu template' : 'Adicionar a um template existente'}
      open
      loading={loading || submitLoading}
      onSubmit={!isCreate && createOrApplyTemplate}
      hideSubmit={!isCreate && !idTemplate}
      onClose={onClose}
      width={1000}
    >
      {isCreate ? (
        <Form
          id="template"
          mapping={templateMapping({
            isMobile: isMobile(),
            isModal: true,
            templateModule: templateModule.budget.value,
            templateModuleOptions: templateModule
          })}
          schema={schema(false)}
          onSubmit={createOrApplyTemplate}
          data={{ module: templateModule.budget.value }}
          keepOldValues
          marginTop={isMobile() ? '0' : '-20px'}
          displayButtons={false}
        />
      ) : (
        <TemplateModal>
          <StyledModal>
            <Select
              allowClear
              optionFilterProp="title"
              filterOption={(input, option) => option?.title?.toLowerCase()?.includes(input?.toLowerCase())}
              onClear={() => {
                setIdTemplate(null);
              }}
              style={{ width: '100%' }}
              showSearch
              placeholder="Selecione um template"
              onChange={id => setIdTemplate(id)}
            >
              {listTemplate.map(opt => (
                <Select.Option key={opt?.id} value={opt?.id} title={opt?.name}>
                  <RenderTemplateType options={opt} />
                </Select.Option>
              ))}
            </Select>
            <Button text style={{ color: colors.primary600 }} onClick={() => setIsCreate(true)}>
              + Criar template
            </Button>
          </StyledModal>
        </TemplateModal>
      )}
    </Modal>
  );
};

AddToTemplateModal.propTypes = {
  onClose: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Array),
  list: PropTypes.instanceOf(Array),
  idRefurbish: PropTypes.number
};

export default AddToTemplateModal;
