import styled from 'styled-components';
import { breakpoints, spaces } from '../../styles/style';

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    padding: 0 ${spaces.space2};
  }
`;

export const AddFileContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${spaces.space2} 0;
  @media (max-width: ${breakpoints.mobile}) {
    margin-top: ${spaces.space1};
  }

  span {
    padding: 0;
  }

  button {
    width: 220px;
    text-align: center;
    margin-right: ${spaces.space3};
  }
`;

export const FilesList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spaces.space1};
  margin-top: ${spaces.space2};
`;

export const FilesPrintList = styled.div`
  display: none;

  @media print {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${spaces.space1};
  }
`;
