import styled from 'styled-components';
import { Radio, Checkbox } from 'antd';
import { breakpoints, colors, fonts, radius, spaces } from '../../../styles/style';

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${spaces.space6} ${spaces.space2};
  gap: ${spaces.space6};

  @media (min-width: ${breakpoints.mobile}) {
    width: 80%;
    padding: ${spaces.space6} 0;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${props => props.gap || spaces.space4};
  ${props => `width: ${props.width};` || ''}
  ${props => `max-width: ${props.maxWidth};` || ''}
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${spaces.space1};
  max-width: 870px;
`;

const StyledCheckbox = styled(Checkbox)`
  font-weight: ${fonts.weight500};
  font-size: ${fonts.sizeSm};
  padding: ${spaces.space1};
  width: 245px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  background-color: ${colors.neutral50};
`;

const TableHeader = styled.th`
  color: ${colors.neutral600};
  font-size: ${fonts.sizeSm};
  font-weight: ${fonts.weight500};
  padding: 8px 2px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${colors.neutral100};
`;

const TableCell = styled.td`
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${colors.neutral100};
`;

const ServiceName = styled(TableCell)`
  text-align: left;
  word-break: break-word;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .stepColumn {
    width: 60%;
    padding: ${spaces.space5} 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .stepsSubtitle {
      margin: ${spaces.space1} 0 ${spaces.space2};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .stepColumn {
      padding: 0;
      width: 100%;
    }
  }
`;

const StepsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 400px;
  margin: 0 17%;
  padding: ${spaces.space1} 0 ${spaces.space2};

  gap: ${spaces.space6};

  @media (max-width: ${breakpoints.tablet}) {
    margin: 0;
    padding: ${spaces.space6} ${spaces.space2};
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  padding: 0 0 ${spaces.space1};
  gap: ${spaces.space2};

  @media (min-width: ${breakpoints.mobile}) {
    padding: ${spaces.space2} 0;
  }
`;

const StyledRadio = styled(Radio)`
  width: 100%;
  padding: ${spaces.space2};
  border-radius: ${radius.radius1};
  border: 1px solid ${colors.neutral75};
  background-color: ${colors.neutral50};
  color: ${colors.neutral600};
  font-weight: ${fonts.weight600};
  min-height: 80px;

  ${props =>
    props.$selected &&
    `
    background-color: ${colors.primary50};

  .ant-typography {
    color: ${colors.primary600};
  }
  `}

  span {
    display: flex;
    flex-direction: column;
    gap: ${spaces.space1};
  }

  .ant-typography {
    color: ${colors.primary500};
    font-size: ${fonts.sizeSm};
    font-weight: ${fonts.weight500};
    text-align: justify;
  }
`;

export {
  AboutContent,
  Group,
  CheckboxGroup,
  StyledCheckbox,
  Table,
  TableHead,
  TableHeader,
  TableCell,
  ServiceName,
  StepsContainer,
  StepsContent,
  StyledRadioGroup,
  StyledRadio
};
