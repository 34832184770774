import React from 'react';
import * as dayjs from 'dayjs';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';

import formatCurrency from '../../helpers/formatCurrency';
import { Div, colors, fonts, spaces } from '../../../styles/style';
import { Paragraph } from '../../../components/Text/Text';
import { ExportTypes, formatPhoneNumber, transformHtmlToWhatsappMessage } from '../../helpers/helper';
import QuoteSupplierActionDropdown from '../../../components/Dropdown/QuoteSupplierActionDropdown';
import LowestPriceTag from '../../../components/Tag/LowestPriceTag';
import ApproveButton from '../../../components/Button/ApproveButton';
import DropdownV2 from '../../../components/Dropdown/DropdownV2';
import { StyledMenu } from '../../../components/Dropdown/DropdownV2.styled';
import Button from '../../../components/Button/Button';

const columns = ({
  handleExport,
  quoteSupplierStatus,
  quoteSupplierStatusMap,
  isMobile,
  bestPrice,
  handleLoad,
  whatsappMessage = '',
  handleOpenEditQuoteSupplierDrawer,
  handleUpdate = f => f
}) => {
  const supplierNameColumn = {
    title: 'Nome do fornecedor',
    dataIndex: 'supplier',
    key: 'supplier',
    width: 280,
    alwaysShow: true,
    sorter: true,
    mobileWidth: '100%',
    render: (val, row) => {
      const { supplier, status, id, approved } = row || {};

      return (
        <Div justify="space-between" $fullWidth>
          <Paragraph
            type="small"
            id="quote-supplier-name"
            color={status === quoteSupplierStatus.refused.id ? colors.neutral400 : colors.primary500}
          >
            <b>{supplier?.name}</b>
          </Paragraph>
          {quoteSupplierStatus.canApprove.includes(status) && (
            <ApproveButton approved={!!approved} onClick={value => handleUpdate(id, 'approved', value)} />
          )}
        </Div>
      );
    }
  };

  const statusColumn = {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 160,
    renderCollapseIcon: true,
    alwaysShow: true,
    render: val => {
      const status = quoteSupplierStatusMap[val];
      return (
        <Paragraph id="quote-supplier-status" color={colors[status?.color || 'neutral600']}>
          {status?.name}
        </Paragraph>
      );
    }
  };

  const offerColumn = {
    title: 'Oferta',
    dataIndex: 'total',
    key: 'total',
    width: 200,
    renderCollapseIcon: true,
    alwaysShow: true,
    sorter: true,
    align: 'right',
    render: (val, row) => {
      return (
        <Div $fullWidth justify="flex-end">
          {bestPrice && row.total === bestPrice && <LowestPriceTag />}
          <Paragraph
            id="quote-supplier-subTotal"
            color={row?.status === quoteSupplierStatus.refused.id ? colors.neutral400 : undefined}
          >
            {formatCurrency(val, { currencySymbol: 'R$' })}
          </Paragraph>
        </Div>
      );
    }
  };

  const sendDateColumn = {
    title: 'Data de envio',
    dataIndex: 'sendDate',
    key: 'sendDate',
    width: 96,
    render: (val, row) => {
      return (
        <Paragraph
          id="quote-supplier-send-date"
          color={row?.status === quoteSupplierStatus.refused.id ? colors.neutral400 : undefined}
        >
          {val ? dayjs(val).format('DD/MM/YY') : '-'}
        </Paragraph>
      );
    }
  };

  const responseDateColumn = {
    title: 'Data da resposta',
    dataIndex: 'responseDate',
    key: 'responseDate',
    width: 120,
    render: (val, row) => {
      if (!val) return '-';

      return (
        <Paragraph
          id="quote-supplier-response-date"
          color={row?.status === quoteSupplierStatus.refused.id ? colors.neutral400 : undefined}
        >
          {val ? dayjs(val).format('DD/MM/YY') : '-'}
        </Paragraph>
      );
    }
  };

  const contactColumn = {
    title: 'Contato',
    dataIndex: 'contact',
    key: 'contact',
    width: 250,
    renderCollapseIcon: true,
    mobileWidth: '100%',
    render: (val, row) => {
      const { supplier } = row || {};

      return (
        <Div
          direction="column"
          align="flex-start"
          style={{ color: row?.status === quoteSupplierStatus.refused.id ? colors.neutral400 : undefined }}
        >
          <Paragraph>{supplier.email}</Paragraph>
          <Paragraph>{formatPhoneNumber(supplier.phone)}</Paragraph>
        </Div>
      );
    }
  };

  const actionsColumn = {
    title: !isMobile && 'Ações',
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    inlineMobile: true,
    inlinePadding: '0px',
    render: (val, row) => {
      const { status } = row || {};
      const link = `${window.location.origin}/qs/${row?.id}`;

      const menu = (
        <StyledMenu $alignLeftOptions={false}>
          <React.Fragment key={`menu-export-${row?.id}`}>
            <Menu.Item>
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  handleOpenEditQuoteSupplierDrawer({ data: { ...row }, print: true });
                }}
              >
                Exportar resposta em PDF
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  handleExport(row?.id, ExportTypes.csv);
                }}
              >
                Exportar resposta em CSV
              </Button>
            </Menu.Item>
          </React.Fragment>
        </StyledMenu>
      );

      return (
        <Div justify="flex-end" $fullWidth gap={spaces.space1}>
          {!isMobile && (
            <DropdownV2 trigger={['hover']} menu={menu} padding="0" onClick={e => e.stopPropagation()}>
              <Div width={spaces.space3} justify="center" height={spaces.space4}>
                <FontAwesomeIcon icon={faArrowDownToLine} size="lg" color={colors.primary600} />
              </Div>
            </DropdownV2>
          )}
          {!quoteSupplierStatusMap.disabledStatusUpdate.includes(status) && (
            <Tooltip placement="bottom" title="Enviar via whatsapp">
              <Div width={spaces.space3} justify="center" height={spaces.space4}>
                <a
                  href={`https://wa.me/?text=${encodeURI(transformHtmlToWhatsappMessage(whatsappMessage))}${link}`}
                  target="_blank"
                  onClick={e => {
                    e.stopPropagation();
                    handleUpdate(row?.id, 'status', quoteSupplierStatus.waitingResponse.id);
                  }}
                >
                  <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: fonts.sizeLg }} color={colors.primary600} />
                </a>
              </Div>
            </Tooltip>
          )}
          <QuoteSupplierActionDropdown
            quoteSupplier={row}
            handleLoad={() => handleLoad({ delay: false })}
            handleOpenEditQuoteSupplierDrawer={() => handleOpenEditQuoteSupplierDrawer({ data: row })}
          />
        </Div>
      );
    }
  };

  const allColumns = isMobile
    ? [supplierNameColumn, statusColumn, offerColumn, contactColumn, sendDateColumn, responseDateColumn, actionsColumn]
    : [supplierNameColumn, statusColumn, offerColumn, sendDateColumn, responseDateColumn, contactColumn, actionsColumn];

  return allColumns;
};

export { columns };
