import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faLayerGroup, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { replaceDecimalNumber } from '../lib/helpers/helper';
import { colors, Div, spaces } from '../styles/style';

import ColorPickerDropDown from '../components/Dropdown/ColorPickerDropDown';
import formatCurrency from '../lib/helpers/formatCurrency';
import Button from '../components/Button/Button';
import { getRefetchOneOptions } from '../lib/helpers/projectFunctions';
import useCRUD from './useCRUD';
import useGantt from './useGantt';
import GanttLabel from '../components/Label/GanttLabel';

const usePlanningGantt = ({
  columnsToShow,
  isPrintGantt,
  columnsToPrint,
  idRefurbish,
  afterChange = f => f,
  isGeneral,
  disabled,
  users = [],
  ...props
}) => {
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const readOnly = isPrintGantt || isGeneral || disabled;

  const {
    ganttInstance,
    transformAndFindUsers,
    batchRefresh,
    updateResources,
    getTaskAvatarHtml,
    returnItemLabel,
    editors,
    formatTaskLinksToText,
    ...useGanttProps
  } = useGantt({
    ganttModel: 'planning',
    columnsToShow,
    isPrintGantt,
    columnsToPrint,
    isGeneral,
    disabled,
    isParent: node => node?.originalType === refurbishItemType?.parent,
    isRootNode: node => node?.countItems !== null && node?.countItems !== undefined,
    mappingForExportXlsx: _item => {
      return {
        ..._item,
        originalType: (() => {
          switch (_item?.originalType) {
            case refurbishItemType.labor:
              return 'Serviço';
            case refurbishItemType.product:
              return 'Produto';
            case refurbishItemType.composition:
              return 'Composição';
            default:
              if (_item?.originalType === refurbishItemType?.parent) {
                return _item.idParentLevel ? 'Subnível' : 'Nível';
              }
              return null;
          }
        })()
      };
    },
    readOnly,
    ...props
  });

  const { handleUpdate, handleGet } = useCRUD({
    model: 'refurbish-items',
    immediatelyLoadData: false
  });

  const handleChangeColor = values => {
    return handleUpdate({
      updatePathOptions: '/planning',
      values: { ...values, idRefurbish },
      refresh: false,
      updateOptions: getRefetchOneOptions()
    }).then(() => {
      return handleGet({
        refetchPathOptions: `/${values?.id}`,
        refetchOptions: getRefetchOneOptions({ withIncludeOnChildren: true })
      }).then(response => {
        if (response?.error) {
          return;
        }

        const result = transformAndFindUsers([response], values?.idParent, null, false);
        updateResources(result?.resources);
        batchRefresh(result?.data, true);
        afterChange(response);
      });
    });
  };

  const columns = [
    {
      name: 'number',
      showColumnKey: 'number',
      label: ReactDOMServer.renderToString(<GanttLabel text="Nº" textAlign="left" />),
      align: 'left',
      min_width: 70,
      width: 70,
      template(task) {
        if (!task.parent) {
          return '';
        }

        return task?.index;
      }
    },
    {
      name: 'name',
      showColumnKey: true,
      label: returnItemLabel(),
      tree: true,
      min_width: 340,
      width: 340,
      onrender: (task, node) => {
        const content = node.querySelector('.gantt_tree_content');
        const container = document.createElement('div');

        container.className = 'custom-text-gantt-container';

        let goToTaskComponent = ReactDOMServer.renderToString(
          <Button
            className="show-on-hover-button"
            type="outline"
            padding={0}
            width={spaces.space3}
            style={{ height: spaces.space3, flexShrink: 0 }}
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ cursor: 'pointer' }}
              color={colors.primary600}
              className={`show-on-hover-button go-to-task-${task.id}`}
            />
          </Button>
        );

        goToTaskComponent = goToTaskComponent.replace(
          '<button',
          `<button onclick="goToTask(${task.originalId || task.id},${task?.isRoot})" `
        );

        container.innerHTML = task?.unscheduled ? '' : goToTaskComponent;

        if (task.parent && task.$level === 1) {
          const colorAndNameContainer = document.createElement('div');
          colorAndNameContainer.style.cssText = `
            display: flex;
            align-items: center;
            gap: ${spaces.space0};
            width: ${isGeneral ? '252px' : '268px'};
          `;

          const colorPicker = document.createElement('div');

          createRoot(colorPicker).render(
            <ColorPickerDropDown
              color={task.color || colors.neutral300}
              disabled={readOnly}
              onSelect={value => {
                handleChangeColor({ id: task.id, color: value }, false);
              }}
            />
          );

          colorAndNameContainer.appendChild(colorPicker);
          colorAndNameContainer.appendChild(content);
          container.insertBefore(colorAndNameContainer, container.firstChild);
        } else {
          container.insertBefore(content, container.firstChild);
        }

        node.appendChild(container);

        return node;
      }
    },
    {
      name: 'quantity',
      showColumnKey: 'quantity',
      label: ReactDOMServer.renderToString(<GanttLabel text="Qtd" textAlign="right" />),
      align: 'right',
      min_width: 70,
      width: 70,
      template(task) {
        if (task?.quantity === null || task?.quantity === undefined) {
          return '-';
        }
        return formatCurrency(task?.quantity, { decimalCount: replaceDecimalNumber(task?.quantity) });
      }
    },
    {
      name: 'duration',
      showColumnKey: 'duration',
      label: ReactDOMServer.renderToString(<GanttLabel text="Duração" textAlign="right" />),
      align: 'right',
      min_width: 70,
      width: 70,
      editor: { type: 'number', map_to: 'duration' },
      template({ duration, unscheduled, originalDuration }) {
        if (unscheduled) return originalDuration || '-';
        if (duration === 0) return duration + 1;
        return duration || '-';
      }
    },
    {
      name: 'start_date',
      showColumnKey: 'plDates',
      label: ReactDOMServer.renderToString(<GanttLabel text="Início" textAlign="left" />),
      align: 'left',
      min_width: 115,
      width: 115,
      editor: readOnly ? undefined : { type: 'customDateEditor', map_to: 'start_date' }
    },
    {
      name: 'end_date',
      showColumnKey: 'plDates',
      label: ReactDOMServer.renderToString(<GanttLabel text="Fim" textAlign="left" />),
      align: 'left',
      min_width: 115,
      width: 115,
      editor: readOnly ? undefined : { type: 'customDateEditor', map_to: 'end_date' }
    },
    {
      name: 'idResponsible',
      showColumnKey: 'idResponsible',
      label: ReactDOMServer.renderToString(<GanttLabel text="Responsável" textAlign="left" />),
      align: 'left',
      min_width: 110,
      width: 110,
      editor: readOnly ? undefined : { type: 'select', map_to: 'idResponsible', options: users },
      template(task) {
        return task?.idResponsible || (readOnly && task?.users?.length) ? getTaskAvatarHtml(task) : '-';
      }
    },
    {
      name: 'predecessors',
      showColumnKey: 'targetLinks',
      label: ReactDOMServer.renderToString(<GanttLabel text="Predecessoras" textAlign="left" />),
      min_width: 120,
      width: 120,
      align: 'left',
      editor: readOnly ? undefined : editors.predecessors,
      template(task) {
        if (!task.parent) {
          return '';
        }

        return formatTaskLinksToText(task);
      }
    }
  ];

  ganttInstance.config.columns = columns.flatMap(({ showColumnKey, ...otherProps }) => {
    return !columnsToShow || (isPrintGantt ? columnsToPrint : columnsToShow)?.[showColumnKey] || showColumnKey === true
      ? [otherProps]
      : [];
  });

  ganttInstance.templates.grid_file = item => {
    let icon;
    switch (item.originalType) {
      case refurbishItemType.labor:
        icon = faScrewdriverWrench;
        break;
      case refurbishItemType.product:
        icon = faBoxOpen;
        break;
      case refurbishItemType.composition:
        icon = faLayerGroup;
        break;
      default:
        return '';
    }
    return ReactDOMServer.renderToString(
      <Div align="center" width={spaces.space3} height="100%" margin={`0 ${spaces.space0} 0 0`}>
        <Div width={spaces.space3} justify="center">
          <FontAwesomeIcon icon={icon} style={{ color: colors.neutral300 }} />
        </Div>
      </Div>
    );
  };

  return {
    ganttInstance,
    transformAndFindUsers,
    batchRefresh,
    updateResources,
    ...useGanttProps
  };
};

export default usePlanningGantt;
