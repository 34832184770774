import * as Yup from 'yup';
import { text, name } from '../schema';

const folderSchema = Yup.object().shape({
  name,
  description: text
});

const folderMapping = () => ({
  name: {
    type: 'text',
    name: 'Nome*',
    placeholder: 'Informe o nome',
    md: 24
  },
  description: {
    type: 'textarea',
    name: 'Descrição',
    placeholder: 'Informe a descrição (opcional)',
    md: 24
  }
});

export { folderMapping, folderSchema };
