import { name, phone, isWpp, email, city, state } from '../mapping';

export const companyProject = {
  name: { label: 'Responsável' },
  cnpj: { label: 'CNPJ' },
  phone,
  email
};
export const companyInfo = {
  name: { ...name, otherProps: { span: 2 } },
  phone,
  isWpp,
  email,
  description: { label: 'Descrição', otherProps: { html: true, span: 12 } }
};
export const companyOperator = {
  MGMCode: {
    type: 'text',
    name: 'MGMCode',
    placeholder: 'Informe o mgm da empresa'
  },
  userLimit: {
    type: 'number',
    name: 'userLimit',
    placeholder: 'Quantidade de usuários contratados'
  }
};

export const companyCustomer = {
  name: { ...name, otherProps: { ellipsis: true } },
  email: { ...email, otherProps: { ellipsis: true } },
  phone: { ...phone, otherProps: { ellipsis: true } },
  street: { label: 'Endereço', otherProps: { ellipsis: true } },
  complement: { label: 'Complemento', otherProps: { ellipsis: true } },
  state,
  city,
  zipcode: { label: 'CEP', spacerSetup: 'default', otherProps: { ellipsis: true } }
};

export const companyNetwork = {
  facebook: { label: 'Facebook' },
  pinterest: { label: 'Pinterest' },
  linkedin: { label: 'Linkedin' },
  twitter: { label: 'Twitter' },
  instagram: { label: 'Instagram' }
};
