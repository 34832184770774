import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AboutContent, Group } from './Onboarding.styled';
import { Subtitle, Title } from '../../../components/Text/Text';
import Select from '../../../components/Select/Select';
import { fonts, spaces, colors } from '../../../styles/style';

const ProjectDetails = ({ data, onChange }) => {
  const { projectQuantityList, projectSizeList, revenueList } = useSelector(state => state.setup.systemData);
  const [_data, setData] = useState(data);

  const handleChange = ({ onboarding }) => {
    const newData = { onboarding: { ..._data?.onboarding, ...onboarding } };
    setData(newData);
    onChange(newData);
  };

  return (
    <AboutContent>
      <Group gap={spaces.space1}>
        <Title size={fonts.sizeH5} id="onboarding-page-title">
          Estamos quase lá!
        </Title>
        <Subtitle weight={fonts.weight500} color={colors.neutral600}>
          Estamos nos últimos passos para personalizar sua experiência.
        </Subtitle>
      </Group>

      <Group width="100%" maxWidth="616px">
        <Group width="100%" gap={spaces.space2}>
          <Subtitle color={colors.neutral600}>
            {_data?.onboarding?.mainActivity === 4 ? 'Quantos projetos' : 'Quantas obras'} em média a sua empresa
            costuma ter simultaneamente?
          </Subtitle>
          <Select
            id="projectQuantityOption"
            name="projectQuantityOption"
            options={projectQuantityList}
            value={_data?.onboarding?.projectQuantity}
            type="select"
            placeholder="Escolha uma opção"
            onChange={e => handleChange({ onboarding: { projectQuantity: e } })}
          />
        </Group>

        {_data?.onboarding?.mainActivity !== 4 && (
          <Group width="100%" gap={spaces.space2}>
            <Subtitle color={colors.neutral600}>Qual o porte médio das obras que você realiza?</Subtitle>
            <Select
              id="projectSizeOption"
              name="projectSizeOption"
              options={projectSizeList}
              value={_data?.onboarding?.projectSize}
              type="select"
              placeholder="Escolha uma opção"
              onChange={e => handleChange({ onboarding: { projectSize: e } })}
            />
          </Group>
        )}

        <Group width="100%" gap={spaces.space2}>
          <Group gap="0px">
            <Subtitle color={colors.neutral600}>Qual o faturamento anual estimado da sua empresa?</Subtitle>
            <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral500}>
              (Receita bruta gerada sem dedução de quaisquer custos operacionais, despesas ou impostos)
            </Subtitle>
          </Group>
          <Select
            id="revenueOption"
            name="revenueOption"
            options={revenueList}
            value={_data?.onboarding?.revenue}
            type="select"
            placeholder="Escolha uma opção"
            onChange={e => handleChange({ onboarding: { revenue: e } })}
          />
        </Group>
      </Group>
    </AboutContent>
  );
};

ProjectDetails.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default ProjectDetails;
