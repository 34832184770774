import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faEllipsis,
  faShareNodes,
  faSquareDollar,
  faXmark,
  faMessage
} from '@fortawesome/pro-regular-svg-icons';
import { faBoxOpen, faLayerGroup, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';
import styled from 'styled-components';
import useViewport from '../../_Hooks/useViewport';
import { Title } from '../Text/Text';
import Drawer from './Drawer';
import { colors, Div, spaces, fonts } from '../../styles/style';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import CopyLinkButton from '../Button/CopyLinkButton';
import EditModalFactory from '../EditModalFactory';
import useCRUD from '../../_Hooks/useCRUD';
import { planningMapping, planningSchema } from '../../lib/mapping/Form/planningSchema';
import FormV2 from '../Form/FormV2';
import ConfirmModal from '../Modal/ConfirmModal';
import useRefurbishPlanningDrawer from '../../_Hooks/useRefurbishPlanningDrawer';
import useTemplatePlanningDrawer from '../../_Hooks/useTemplatePlanningDrawer';
import usePlanningGantt from '../../_Hooks/usePlanningGantt';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import useFilter from '../../_Hooks/useFilter';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import PredecessorsSection from '../Sections/PredecessorsSection';
import DrawerComments, { calcDrawerWidth } from './DrawerComponents/DrawerComments';
import DrawerTabs from './DrawerComponents/DrawerTabs';
import DrawerHeaderComponents from './DrawerComponents/DrawerHeaderComponents';

const ContainerDropdownTreeSelect = styled(Div)`
  .ant-tree-select-dropdown {
    padding: 0px;
  }

  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-open {
    &:hover {
      background-color: ${colors.neutral75};
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    &:hover {
      background-color: ${colors.neutral75};
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    transition: all 0s !important;
  }

  .ant-select-tree .ant-select-tree-treenode {
    padding: 0px 4px !important;
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: ${colors.neutral75} !important;
  }

  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-selected {
    background-color: ${colors.neutral75} !important;
  }
`;

const childrenModel = {
  'refurbish-items': 'refurbishItems',
  'template-item': 'templateItem'
};

const EditPlanningDrawer = ({
  open,
  onClose,
  tab = '0',
  desktopWidth = '780px',
  id,
  dataGantt,
  afterSubmit = f => f,
  isTemplate,
  idReference,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { plans, permissions } = useSelector(state => state.setup);
  const { refurbishItemType, userType, specificationDefaultColumnsToShow } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const [files, setFiles] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { storedFilters, updateFilters } = useFilter({
    persistKey: 'show-comments-side-component'
  });

  const isCustomer = user.userType === userType.customer.value;
  const isAnonymous = !!user?.anonymous;

  let initialShowComments = storedFilters !== null ? storedFilters?.showCommentsSideComponent : true;
  if (isAnonymous || _isMobile) initialShowComments = false;

  const [showComponents, setShowComponents] = useState({
    showMobileOptionsModal: false,
    showShareDropdown: false,
    showBudgetModal: false,
    showSelectPredecessors: false,
    showComments: initialShowComments
  });
  const [selectedTab, setSelectedTab] = useState(!isCustomer ? tab : '1');

  const analytics = AnalyticsServiceFactory();

  const [selectedItems, setSelectedItems] = useState([]);
  const [newFormValues, setNewFormValues] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [internalDataGantt, setInternalDataGantt] = useState({ data: [], links: [], resources: [] });

  const model = isTemplate ? 'template-item' : 'refurbish-items';
  const referenceKey = isTemplate ? 'idTemplate' : 'idRefurbish';
  const chatWidth = '360px';

  const { data, loading } = useCRUD({
    model,
    immediatelyLoadData: !!id,
    pathOptions: `/${id}`,
    options: {
      where: { [referenceKey]: idReference },
      attributes: [
        'id',
        'name',
        'code',
        'quantity',
        'type',
        'description',
        'plStartDate',
        'plEndDate',
        'plIdResponsible',
        'duration',
        'quantity',
        referenceKey
      ],
      include: [
        { model: 'user', attributes: ['id', 'name', 'avatarFullpath'] },
        { model: 'supplier', attributes: ['id', 'name'], as: 'supplier' },
        { model: childrenModel[model], attributes: ['id', 'name', 'color'], as: 'parent' },
        ...(!isTemplate
          ? [
              'files',
              {
                model: 'refurbish',
                attributes: ['id', 'workingDays', 'itemsToShare', 'clientColumns'],
                include: [{ model: 'user', attributes: ['id', 'name'] }, 'refurbishDayoffs']
              },
              { model: 'comments', attributes: ['id'], as: 'comments' }
            ]
          : [])
      ]
    }
  });

  const { ganttInstance, transformAndFindUsers } = usePlanningGantt({
    idRefurbish: data?.refurbish?.id,
    workingDays: data?.refurbish?.workingDays,
    dayoffs: data?.refurbish?.refurbishDayoffs,
    tasks: internalDataGantt
  });

  const { handleGet: getItems } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleGet: handleGetPlanningLink } = useCRUD({
    model: 'planningLink',
    immediatelyLoadData: false
  });

  const hookToUse = useMemo(() => (isTemplate ? useTemplatePlanningDrawer : useRefurbishPlanningDrawer), [isTemplate]);
  const propsForTrack = { idCompany: user?.idCompany, idRefurbish: data?.idRefurbish, idUser: user?.id };

  const { createLink, removeLink, updateLink, handleSubmit, onChangeDateRelatedField } = hookToUse({
    data,
    setNewFormValues,
    newFormValues,
    setIsSubmitting,
    afterSubmit,
    setHasChanges,
    setSelectedItems,
    ganttInstance
  });

  useEffect(() => {
    if (!ganttInstance) return;

    ganttInstance.plugins({
      auto_scheduling: true
    });
  }, [ganttInstance]);

  useEffect(() => {
    if (!ganttInstance || internalDataGantt.data.length === 0) return;
    ganttInstance.parse(internalDataGantt);
  }, [internalDataGantt]);

  useEffect(() => {
    if (!data || !dataGantt) return;
    const ganttTasks = dataGantt.data || [];
    const links = dataGantt.links || [];

    const promise = ganttTasks?.length
      ? Promise.resolve([ganttTasks, links])
      : Promise.all([
          getItems({ refetchOptions: { where: { [referenceKey]: idReference } } }),
          handleGetPlanningLink({ refetchOptions: { where: { [referenceKey]: idReference } } })
        ]).then(([_ganttasks, _links]) => {
          const result = transformAndFindUsers(_ganttasks, null, null, false, {
            ...(!isTemplate && data?.refurbish?.plBasedDate && { type: 'task' })
          });
          return [result?.data, _links];
        });

    promise.then(([_tasks, _links]) => {
      const newSelectedItems = [];

      // Process links to build newSelectedItems
      _links.forEach(link => {
        if (Number(link.target) === data.id) {
          const sourceTask = _tasks.find(task => task.id === Number(link.source));
          if (sourceTask) {
            newSelectedItems.push({
              source: Number(link.source),
              type: link.type || ganttInstance.config.links.finish_to_start,
              lag: link.lag,
              id: link.id
            });
          }
        }
      });

      setInternalDataGantt({
        data: _tasks,
        links: _links
      });

      setSelectedItems(newSelectedItems);
    });
  }, [data, dataGantt]);

  useEffect(() => {
    if (data) {
      setFiles(data.files || []);
    }
  }, [data]);

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmModal(true);
    } else {
      onClose({});
    }
  };

  const handleConfirmModalClose = (_, opts) => {
    if (opts?.discard) {
      setHasChanges(false);
      onClose();
    } else {
      setShowConfirmModal(false);
    }
  };

  const itemTypeMap = {
    [refurbishItemType.labor]: { name: 'Serviço', icon: faScrewdriverWrench },
    [refurbishItemType.product]: { name: 'Produto', icon: faBoxOpen },
    [refurbishItemType.composition]: { name: 'Composição', icon: faLayerGroup }
  };

  const mappedItemType = itemTypeMap[data?.type] || null;

  const { list, handleGet: getList } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (data) {
      getList({
        refetchOptions: {
          attributes: ['name', 'id', 'type', 'color', 'idParent', 'plStartDate', 'plEndDate', 'duration'],
          where: {
            type: refurbishItemType.parent,
            idParent: null,
            [referenceKey]: data?.[referenceKey]
          },
          include: [
            {
              model: childrenModel[model],
              as: 'children',
              attributes: ['name', 'id', 'type', 'color', 'idParent', 'plStartDate', 'plEndDate', 'duration'],
              required: false,

              include: [
                {
                  model: childrenModel[model],
                  as: 'children',
                  attributes: ['name', 'id', 'type', 'color', 'idParent', 'plStartDate', 'plEndDate', 'duration'],
                  required: false,
                  where: {
                    [`$children.type$`]: refurbishItemType.parent
                  }
                }
              ]
            }
          ],
          order: [['order']]
        }
      });
    }
  }, [data]);

  const handleShareWhatsapp = () => {
    analytics.track({
      event: 'construct-schedule-item-shared',
      eventProperties: { ...propsForTrack, type: 'Whatsapp' }
    });
    window.open(
      `https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20platafo
      rma:%20${window.location.origin}/o/${id}`,
      '_blank'
    );
  };

  const sections = [
    {
      key: '0',
      title: 'Dependências',
      component: (
        <PredecessorsSection
          showComponents={showComponents}
          setShowComponents={setShowComponents}
          selectedItems={selectedItems}
          list={list}
          updateLink={updateLink}
          removeLink={removeLink}
          createLink={createLink}
          ganttInstance={ganttInstance}
          refurbishItemType={refurbishItemType}
          childrenKey="children"
          id={id}
        />
      )
    },
    ...(!isTemplate
      ? [
          {
            key: '1',
            title: 'Anexos',
            component: (
              <Div padding={spaces.space2} width="100%">
                <ItemAttachmentContent
                  readOnly={isCustomer}
                  hideTitle
                  hasDescription={false}
                  data={{ files }}
                  setFiles={setFiles}
                />
              </Div>
            )
          }
        ]
      : [])
  ];

  const menu = (
    <Menu>
      <Menu.Item>
        <Button onClick={handleShareWhatsapp} id="share-planning" text>
          Compartilhar no Whatsapp
        </Button>
      </Menu.Item>
      <Menu.Item>
        <CopyLinkButton
          customEvent="construct-schedule-item-shared"
          customEventProperties={{ ...propsForTrack, type: 'Copy' }}
          urlContext="o"
          idData={id}
          id="copy-link"
          text
        >
          Copiar link
        </CopyLinkButton>
      </Menu.Item>
    </Menu>
  );

  const headerComponents = [
    {
      icon: faMessage,
      title: 'Comentários',
      hasBadge: true,
      badgeCount: data?.comments?.length || 0,
      showOnMobile: true,
      isPressed: showComponents.showComments,
      onClick: () =>
        setShowComponents({
          ...showComponents,
          showComments: !showComponents.showComments
        }),
      shouldDisplayWhen: !isTemplate && !isAnonymous
    },
    {
      icon: faSquareDollar,
      title: 'Ver detalhes do orçamento',
      onClick: () => {
        analytics.track({
          event: 'construct-schedule-budget-item-viewed',
          eventProperties: { ...propsForTrack }
        });
        setShowComponents({ ...showComponents, showBudgetModal: true });
      },
      shouldDisplayWhen:
        hasPermission(user, ['item'], plans, permissions) && (!isCustomer || data?.refurbish?.itemsToShare?.item)
    },
    {
      icon: faShareNodes,
      title: 'Compartilhar',
      menu,
      isOpen: showComponents?.showShareDropdown,
      onClick: v => setShowComponents({ ...showComponents, showShareDropdown: v })
    },
    {
      icon: faEllipsis,
      title: 'Opções',
      onlyMobile: true,
      onClick: () => setShowComponents({ ...showComponents, showMobileOptionsModal: true })
    },
    {
      icon: faXmark,
      title: 'Fechar',
      onClick: handleClose
    }
  ];

  const renderItemTypeIcon = () => {
    const itemType = itemTypeMap[data?.type];
    if (!itemType) return null;

    return (
      <Div gap={spaces.space1}>
        <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
          <FontAwesomeIcon icon={itemType.icon} style={{ color: colors.neutral300 }} />
        </Div>
      </Div>
    );
  };

  useEffect(() => {
    if (showComponents.showComments !== storedFilters?.showCommentsSideComponent)
      updateFilters({ ...storedFilters, showCommentsSideComponent: showComponents.showComments });
  }, [showComponents.showComments]);

  return (
    <>
      <Drawer
        open={open}
        formId="planningForm"
        title={
          <Div gap={spaces.space1}>
            {_isMobile && (
              <Div onClick={handleClose} padding={spaces.space1}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Div>
            )}
            {renderItemTypeIcon()}
            <Title id="planning-title">{mappedItemType?.name}</Title>
          </Div>
        }
        onClose={handleClose}
        padding="0px"
        width={calcDrawerWidth(showComponents?.showComments, _isMobile)}
        closeIcon={false}
        extra={<DrawerHeaderComponents components={headerComponents} />}
        loading={isSubmitting || loading}
        submitText="Salvar"
        showSubmit={!isCustomer}
      >
        <Div direction="row" $fullHeight>
          <Div $minWidth={_isMobile ? '100%' : desktopWidth} direction="column" $fullHeight align="flex-start">
            <ContainerDropdownTreeSelect id="containerTreeSelect" />
            <Div display="block" padding={spaces.space2} width="100%">
              <Div margin={`0 0 ${spaces.space3} 0`} align="center" gap={spaces.space1}>
                <Title weight={fonts.weight700}>{data?.name}</Title>
              </Div>
              <FormV2
                id="planningForm"
                newValues={newFormValues}
                data={{ ...data, responsible: data?.user }}
                schema={planningSchema}
                onValueChanged={setHasChanges}
                mapping={planningMapping({
                  parent: data?.parent,
                  supplier: data?.supplier,
                  onChangeDateRelatedField,
                  workDays: data?.refurbish?.workingDays,
                  dayoffs: data?.refurbish?.refurbishDayoffs,
                  isTemplate,
                  isMobile: _isMobile,
                  userType
                })}
                onSubmit={submitData => {
                  handleSubmit({ ...submitData, ...newFormValues }, files);
                }}
                displayButtons={false}
                readOnly={isCustomer}
              />
            </Div>
            <DrawerTabs sections={sections} selectedTab={selectedTab} onTabChange={setSelectedTab} />
            {showComponents?.showBudgetModal && (
              <EditModalFactory
                {...props}
                onClose={() => {
                  setShowComponents({ ...showComponents, showBudgetModal: false, showMobileOptionsModal: false });
                }}
                isTemplate={isTemplate}
                readOnly
                hideAttachments
                hideComments
                id={id}
                idReference={idReference}
                idCompany={user?.idCompany}
                type={data?.type}
                model={model}
                renderColumns={(isCustomer && data?.refurbish?.clientColumns) || specificationDefaultColumnsToShow}
              />
            )}
          </Div>
          {!isTemplate && (
            <DrawerComments
              show={showComponents.showComments}
              onToggle={value => {
                setShowComponents({ ...showComponents, showComments: value });
              }}
              width={chatWidth}
              data={data}
              model={model}
              tooltipText="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
        Tudo que você comentar aqui também será apresentado no Orçamento."
              isMobile={_isMobile}
            />
          )}
        </Div>
      </Drawer>
      {showConfirmModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar."
          formId="planningForm"
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Salvar"
          showDiscardButton
          onClose={handleConfirmModalClose}
        />
      )}
      {showComponents?.showMobileOptionsModal && (
        <Modal
          title="Opções"
          open={showComponents?.showMobileOptionsModal}
          hideFooter
          onClose={() => setShowComponents({ ...showComponents, showMobileOptionsModal: false })}
        >
          {hasPermission(user, ['item'], plans, permissions) && (!isCustomer || data?.refurbish?.itemsToShare?.item) ? (
            <Button
              text
              onClick={() => {
                setShowComponents({ ...showComponents, showBudgetModal: true });
                analytics.track({
                  event: 'construct-schedule-budget-item-viewed',
                  eventProperties: { ...propsForTrack }
                });
              }}
            >
              Ver detalhes do orçamento
            </Button>
          ) : null}
          <CopyLinkButton
            customEvent="construct-schedule-item-shared"
            customEventProperties={{ ...propsForTrack, type: 'Copy' }}
            urlContext="o"
            idData={id}
            id="copy-link"
            text
          >
            Copiar link
          </CopyLinkButton>
          <Button text onClick={handleShareWhatsapp}>
            Compartilhar no Whatsapp
          </Button>
        </Modal>
      )}
    </>
  );
};

EditPlanningDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  tab: PropTypes.string,
  desktopWidth: PropTypes.string,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  dataGantt: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  idReference: PropTypes.number
};

export default EditPlanningDrawer;
