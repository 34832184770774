import React, { useEffect, useState, useRef } from 'react';
import { Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { faFolder, faFolderOpen, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import Form from '../Form/Form';
import { folderSchema, folderMapping } from '../../lib/mapping/Form/folderSchema';

import {
  StyledFolder,
  SharedIcon,
  StyledDropdownFolder,
  StyledFolderName,
  StyledFolderGrid,
  StyledFolderDiv
} from './RenderFolder.styled';
import Modal from '../Modal/Modal';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import ConfirmModal from '../Modal/ConfirmModal';
import useViewport from '../../_Hooks/useViewport';
import { spaces } from '../../styles/style';

const RenderFolder = ({
  idReference,
  referKey = 'idRefurbish',
  shared = f => f,
  setSideBarTab = f => f,
  isApply,
  onSelectedFolder = f => f,
  showModalFolder,
  setShowModalFolder,
  setSelectedTab,
  includeOptions,
  goToFolder,
  reload,
  refreshFolder,
  isProject,
  forceLoad,
  selectedTab,
  HeaderActions
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [modalTitle, setModalTitle] = useState('Adicionar pasta');
  const [modalSharedTitle, setModalSharedTitle] = useState(null);
  const [editFolder, setEditFolder] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSharedConfirmModal, setShowSharedConfirmModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showFolder, setShowFolder] = useState(!isMobile() || referKey === 'idTemplate');
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const ref = useRef(null);
  const refPrevent = useRef(null);
  const {
    list: folderList,
    handleGet: getFolder,
    handleCreate: createFolder,
    handleUpdate: updateFolder,
    handleDelete: deleteFolder,
    loading,
    setList
  } = useCRUD({
    model: 'folder',
    immediatelyLoadData: false
  });

  const handleFolderModal = (e, folder) => {
    if (e) {
      e.stopPropagation();
    }

    setEditFolder(folder);
    setModalTitle(folder ? 'Editar pasta' : 'Adicionar pasta');
    setShowModalFolder(true);
  };

  const _getFolder = () =>
    getFolder({
      refetchOptions: {
        where: {
          or: [
            { [referKey]: idReference, ...(isCustomer && { shared: { gte: '2000-01-01' } }) },
            { id: 1 },
            { ...(includeOptions && { '$files.shared$': { gte: '2000-01-01' } }) }
          ]
        },
        ...(includeOptions && { include: includeOptions }),
        order: [[referKey, 'desc'], 'name']
      },
      generateLoading: !reload
    })
      .then(folders =>
        !folders?.length
          ? getFolder({
              refetchOptions: { where: { id: 1, idCompany: null } }
            })
          : folders
      )
      .then(folders => {
        if (folders.error) return null;
        let _folder = folders?.find(folder => folder.id === (goToFolder || selectedFolder?.id)) || folders[0];
        _folder = { ..._folder, files: _folder.files || [] };
        setSelectedFolder(_folder);
        onSelectedFolder(_folder);
        return _folder;
      });

  const handleSubmitFolder = ({ name, description }) => {
    const { id } = editFolder || {};

    if (!name) {
      toast.error('Informe o nome da pasta.');
      return;
    }

    setShowModalFolder(false);

    const submit = id
      ? updateFolder({ id, values: { description, name, [referKey]: idReference, isProject }, refresh: false })
      : createFolder({ values: { description, name, [referKey]: idReference, isProject }, refresh: false });
    submit.then(() => setEditFolder(null)).then(() => _getFolder());
  };

  const handleDeleteFolder = () => {
    const { id } = editFolder;

    deleteFolder({ id, values: { isProject }, refresh: false }).then(() => {
      if (selectedFolder.id === id) {
        setSelectedFolder(folderList[0]);
      }

      setEditFolder(null);
      setShowConfirmModal(false);
      setSideBarTab('info');

      if (setSelectedTab && selectedTab !== 'all') {
        setSelectedTab('all');
      } else {
        _getFolder();
      }
    });
  };
  const handleSharedFolder = () => {
    const { id } = editFolder;

    updateFolder({
      updatePathOptions: `/share`,
      id,
      values: {
        idCompany: editFolder.idCompany,
        [referKey]: idReference,
        shared: !shared(editFolder) ? new Date() : null,
        isProject
      },
      refresh: false,
      verb: 'patch'
    })
      .then(() => setEditFolder(null))
      .then(() => _getFolder().then(setModalTitle('')));
  };

  useEffect(() => {
    setShowModalFolder(showModalFolder);
  }, [showModalFolder]);

  useEffect(() => {
    const folderIndex = folderList.findIndex(f => f.id === refreshFolder?.id);
    if (folderIndex >= 0) {
      setList(prev => {
        const _list = [...prev];
        _list[folderIndex] = refreshFolder;
        return _list;
      });
    } else {
      setList(prev => {
        setSelectedFolder(folderList[0]);
        return prev.filter(item => item.id !== selectedFolder?.id);
      });
    }
  }, [refreshFolder]);

  useEffect(() => {
    _getFolder();
  }, [reload, forceLoad]);

  const RenderFolderInfo = ({ folders, isDropdownFolder = false }) => {
    const ParentComponent = isDropdownFolder ? StyledDropdownFolder : StyledFolder;
    const GridComponent = isDropdownFolder ? StyledFolderDiv : StyledFolderGrid;
    return (
      <GridComponent ref={refPrevent}>
        {folders?.map(folder => (
          <ParentComponent
            selected={selectedFolder?.id === folder.id}
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              setSideBarTab('info');
              setIsDropdownVisible(false);
              setSelectedFolder(folder);
              onSelectedFolder(folder);
            }}
            style={{ position: 'relative', cursor: 'pointer', flex: 1 }}
            key={folder.id}
          >
            {selectedFolder?.id === folder.id ? (
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faFolderOpen} size="lg" />
              </span>
            ) : (
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faFolder} size="lg" style={{ fontSize: '20px' }} />
                <span className="fa-layers-text fa-inverse" style={{ fontSize: '10px', marginTop: 1 }}>
                  {folder.total || 0}
                </span>
              </span>
            )}
            {!isCustomer && !!folder.shared && (
              <SharedIcon style={isDropdownFolder ? { top: 8, right: 2 } : {}}>
                <FontAwesomeIcon inverse size="xs" icon={faUserFriends} />
              </SharedIcon>
            )}
            {isDropdownFolder ? (
              <StyledFolderName style={isMobile() ? { padding: 8 } : {}}>{folder.name}</StyledFolderName>
            ) : (
              <Tooltip title={folder?.name}>
                <StyledFolderName>{folder.name}</StyledFolderName>
              </Tooltip>
            )}

            {!isDropdownFolder && !isApply && !isCustomer && folder.id !== 1 ? (
              <Dropdown
                trigger={['click']}
                slim
                menu={
                  <Menu>
                    {folder[referKey] ? (
                      <Menu.Item key={0}>
                        <Button text onClick={e => handleFolderModal(e, folder)}>
                          Editar pasta
                        </Button>
                      </Menu.Item>
                    ) : null}
                    {referKey === 'idRefurbish' && isProject ? (
                      <Menu.Item key={1}>
                        <Button
                          text
                          onClick={() => {
                            setShowSharedConfirmModal(true);
                            setEditFolder(folder);
                            setModalSharedTitle(!shared(folder) ? 'compartilhar' : 'descompartilhar');
                          }}
                        >
                          {!shared(folder) ? 'Compartilhar Pasta' : 'Descompartilhar Pasta'}
                        </Button>
                      </Menu.Item>
                    ) : null}

                    {folder[referKey] ? (
                      <Menu.Item key={2}>
                        <Button
                          text
                          onClick={e => {
                            e.stopPropagation();
                            setEditFolder(folder);
                            setShowConfirmModal(true);
                          }}
                        >
                          Excluir pasta
                        </Button>
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
              />
            ) : null}
          </ParentComponent>
        ))}
      </GridComponent>
    );
  };

  RenderFolderInfo.propTypes = {
    folders: PropTypes.instanceOf(Object),
    isDropdownFolder: PropTypes.bool
  };

  return loading ? (
    <CenteredLoader />
  ) : (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {showFolder ? (
            <b>Pastas</b>
          ) : (
            <Dropdown
              overlayStyle={{ width: 250 }}
              padding="0"
              trigger={['click']}
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              menu={<RenderFolderInfo isDropdownFolder folders={folderList} />}
            >
              <div ref={ref} id="folder-dropdown">
                <b style={{ marginRight: spaces.space1 }}>Pastas</b>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </Dropdown>
          )}
          <Button
            id="hide-folder"
            type="primary"
            text
            onClick={() => setShowFolder(!showFolder)}
            icon={<FontAwesomeIcon icon={showFolder ? faEye : faEyeSlash} />}
          >
            {showFolder ? 'Esconder pastas' : 'Mostrar Pastas'}
          </Button>
        </div>
        {HeaderActions && <HeaderActions />}
      </div>
      {showFolder && <RenderFolderInfo folders={folderList} />}
      {showModalFolder && (
        <Modal hideFooter open title={modalTitle} onClose={() => setShowModalFolder(false)}>
          <Form schema={folderSchema} mapping={folderMapping} data={editFolder} onSubmit={handleSubmitFolder} />
        </Modal>
      )}
      {showConfirmModal && (
        <ConfirmModal
          text="Tem certeza que deseja excluir esta pasta?"
          description="ATENÇÃO: Todos arquivos contidos na pasta também serão apagados."
          onSubmit={handleDeleteFolder}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      {showSharedConfirmModal && (
        <ConfirmModal
          text={`Tem certeza que deseja ${modalSharedTitle} esta pasta?`}
          description={`ATENÇÃO: Voce irá ${modalSharedTitle} todos os arquivos desta pasta .`}
          onSubmit={handleSharedFolder}
          onClose={() => setShowSharedConfirmModal(false)}
        />
      )}
    </>
  );
};

RenderFolder.propTypes = {
  idReference: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  referKey: PropTypes.string,
  shared: PropTypes.func,
  setSideBarTab: PropTypes.func,
  isApply: PropTypes.bool,
  refreshFolder: PropTypes.instanceOf(Object),
  onSelectedFolder: PropTypes.func,
  showModalFolder: PropTypes.bool,
  setShowModalFolder: PropTypes.func,
  setSelectedTab: PropTypes.func,
  includeOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  goToFolder: PropTypes.number,
  reload: PropTypes.number,
  isProject: PropTypes.bool,
  forceLoad: PropTypes.number,
  selectedTab: PropTypes.string,
  HeaderActions: PropTypes.element
};

export default RenderFolder;
