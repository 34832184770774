import { Card } from 'antd';
import styled from 'styled-components';
import { colors, fonts, radius, spaces, breakpoints } from '../../styles/style';

const StyledCard = styled(Card)`
  cursor: pointer;
  height: 288px;
  width: 240px;
  background-color: ${colors.neutral75};
  border-radius: ${radius.radius2};

  img {
    border-radius: ${radius.radius2} ${radius.radius2} 0 0;
  }

  .ant-card-cover {
    background-color: ${colors.primary50};
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${radius.radius2} ${radius.radius2} 0 0;
    svg {
      height: ${spaces.space4};
      width: ${spaces.space4};
      color: ${colors.primary200};
    }
  }

  .ant-card-body {
    padding: 0 ${spaces.space2};
    border-radius: ${radius.radius2};

    .ant-card-meta {
      font-size: ${fonts.sizeMd};
    }

    .ant-card-meta-title {
      font-size: ${fonts.sizeMd};
      color: ${colors.neutral600};
    }

    .ant-card-meta-description {
      font-size: ${fonts.sizeSm};
      color: ${colors.neutral500};
      font-weight: ${fonts.weight400};
      height: ${spaces.space6};
      line-height: ${spaces.space2};
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .ant-card-meta-detail {
      height: 78px;
    }
  }
  :hover {
    .ant-card-cover {
      background-color: ${colors.neutral100};
    }
    background-color: ${colors.neutral100};
  }
`;

const StyledBtnCard = styled(Card)`
  cursor: pointer;
  height: 288px;
  width: 240px;
  background-color: ${colors.primary100};
  border-radius: ${radius.radius2};

  &.ant-card-bordered {
    border: 1px dashed ${colors.primary300};
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-container {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .btn-text {
      font-size: ${fonts.sizeMd};
      color: ${colors.neutral600};
      margin-top: ${spaces.space4};
      font-weight: ${fonts.weight500};
    }
    .btn-icon-container {
      height: ${spaces.space8};
      width: ${spaces.space8};
      svg {
        width: 100%;
        height: 100%;
        --fa-primary-color: ${colors.primary500};
        --fa-secondary-color: ${colors.primary200};
      }
    }
  }
`;

const StyleTag = styled.div`
  width: ${spaces.space11};
  height: ${spaces.space3};
  text-align: center;
  position: absolute;
  z-index: 1;
  top: ${spaces.space1};
  right: ${spaces.space1};
  background-color: ${colors.white};
  border-radius: ${radius.radius1};
  text-transform: uppercase;
  font-size: ${fonts.sizeXs};
  color: ${colors.neutral500};
  font-weight: ${fonts.weight500};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDiv = styled.div`
  position: relative;
  button {
    display: none;
    z-index: 2;
    position: absolute;
    right: -${spaces.space1};
    top: -${spaces.space1};
  }
  :hover {
    button {
      display: block;
    }
    p {
      display: block;
    }
  }

  img {
    display: inline;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: ${spaces.space1} ${spaces.space2};
  }
`;

const StyledAuthor = styled.div`
  margin: ${spaces.space1} 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  .authorName {
    padding: 0 ${spaces.space1};
    font-size: ${fonts.sizeSm};
  }
  .vobi-logo {
    height: ${spaces.space3};
    width: ${spaces.space3};
    border-radius: 100%;
    background-color: ${colors.white};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  margin: ${spaces.space1} 0;
  align-items: center;
  color: ${colors.neutral500};
  justify-content: space-between;
  span {
    font-size: ${fonts.sizeXs};
    text-transform: uppercase;
    font-weight: ${fonts.weight500};
  }
`;

const StyledIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${spaces.space4};
  width: ${spaces.space4};
`;

export { StyledDiv, StyledCard, StyleTag, StyledAuthor, StyledFooter, StyledIconBox, StyledBtnCard };
