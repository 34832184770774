import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useFilter = ({ persistKey, idRefurbish }) => {
  const { user } = useSelector(state => state.authReducer);
  const _persistKey = `filter-${persistKey}${idRefurbish ? `-${idRefurbish}` : ''}`;
  const getFilters = () => {
    return window.localStorage.getItem(_persistKey);
  };

  const [filters, setFilters] = useState(() => {
    const storedData = getFilters();
    if (!storedData) return null;

    const { idUser, filters: _filters } = JSON.parse(storedData);
    if (idUser !== user.id) return null;
    return _filters;
  });

  useEffect(() => {
    const storedData = getFilters();
    const storedIdUser = storedData ? JSON.parse(storedData).idUser : null;

    if (storedIdUser !== user.id) setFilters(null);
  }, [user.id]);

  useEffect(() => {
    const dataToStore = {
      idUser: user.id,
      filters
    };
    window.localStorage.setItem(_persistKey, JSON.stringify(dataToStore));
  }, [filters, user.id]);

  return {
    storedFilters: filters,
    updateFilters: setFilters
  };
};

export default useFilter;
