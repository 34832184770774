import PropTypes from 'prop-types';
import React from 'react';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { replaceDecimalNumber } from '../../lib/helpers/helper';
import ImageInput from '../Input/ImageInput';
import { InlineItems, StyledCardMobile, StyledCardRow } from './CompositionMobileCard.styled';
import Label from '../Label/Label';
import { Paragraph } from '../Text/Text';

const TextOrEmpty = ({ children, ...props }) => {
  return (
    <Paragraph type="small" {...props}>
      {children != null ? children : '-'}
    </Paragraph>
  );
};
TextOrEmpty.propTypes = {
  children: PropTypes.arrayOf(PropTypes.instanceOf(Object))
};

const quantityReplace = val => {
  const decimalCount = replaceDecimalNumber(val);
  return formatCurrency(val, { decimalCount });
};

const COMPONENT_MAP = {
  code: item => (
    <StyledCardRow key={`code${item.id}`}>
      <Label>Código</Label>
      <TextOrEmpty className="field">{item.id}</TextOrEmpty>
    </StyledCardRow>
  ),
  quantity: item => (
    <StyledCardRow key={`quantity${item.id}`}>
      <Label>Quantidade</Label>
      <TextOrEmpty className="field">{quantityReplace(item?.quantity)}</TextOrEmpty>
    </StyledCardRow>
  ),
  units: item => (
    <StyledCardRow key={`units${item.id}`}>
      <Label>Unidade</Label>
      <TextOrEmpty className="field">{item.units?.name}</TextOrEmpty>
    </StyledCardRow>
  ),
  priceBdi: item => (
    <StyledCardRow key={`priceBdi${item.id}`}>
      <Label>Preço un</Label>
      <TextOrEmpty className="field">{formatCurrency(item.price, { currencySymbol: 'R$' })}</TextOrEmpty>
    </StyledCardRow>
  ),
  totalBdi: item => (
    <StyledCardRow key={`totalBdi${item.id}`}>
      <Label>Total</Label>
      <TextOrEmpty bold className="field">
        {formatCurrency(item.total, { currencySymbol: 'R$' })}
      </TextOrEmpty>
    </StyledCardRow>
  )
};

const CompositionMobileCard = ({ list = [], renderColumns }) => {
  // eslint-disable-next-line react/prop-types

  const renderComponents = (item, validationObj) => {
    return Object.keys(validationObj || COMPONENT_MAP)
      .filter(key => (renderColumns ? validationObj[key] : true))
      .map(key => COMPONENT_MAP[key]?.(item))
      .filter(component => component !== undefined);
  };

  return (
    list &&
    list?.map(item => {
      return (
        <StyledCardMobile key={item?.id}>
          <InlineItems description>
            {renderColumns?.image ? (
              <StyledCardRow key={`image${item.id}`}>
                <ImageInput id={item.id} value={item.image} images={item.images} disabled />
              </StyledCardRow>
            ) : null}
            <StyledCardRow>
              <Label>Descrição</Label>
              <TextOrEmpty className="field">{item.name}</TextOrEmpty>
            </StyledCardRow>
          </InlineItems>
          <InlineItems>{renderComponents(item, renderColumns)}</InlineItems>
        </StyledCardMobile>
      );
    })
  );
};

CompositionMobileCard.propTypes = {
  list: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isView: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmitDropdown: PropTypes.func,
  renderColumns: PropTypes.arrayOf(PropTypes.Object)
};

export default CompositionMobileCard;
