import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider } from 'antd';
import { faEye, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../Button/Button';
import Modal from './Modal';
import useCRUD from '../../_Hooks/useCRUD';
import { updateCompany } from '../../store/auth/actions/action';
import { Paragraph, Subtitle } from '../Text/Text';
import { colors, Div, spaces } from '../../styles/style';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import TooltipIcon from '../Tooltip/TooltipIcon';
import RenderSupplierData from './RenderSupplierData';
import Card from '../Card/Card';
import useViewport from '../../_Hooks/useViewport';

const SendQuoteToSupplierModal = ({ onClose, idQuoteSupplier, quoteSupplierList, name, email, idQuote }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authReducer);
  const { company } = user || {};

  const [message, setMessage] = useState(company?.configuration?.sendQuoteMessage || company?.sendQuoteMessage || '');
  const [checked, setChecked] = useState(false);

  const { isMobile } = useViewport(window.innerWidth);

  const { handleUpdate } = useCRUD({
    model: 'company',
    immediatelyLoadData: false
  });

  const handleSaveAndClose = () => {
    const newConfiguration = { ...company?.configuration, sendQuoteMessage: message };

    if (!checked || !company) {
      onClose();
      return;
    }

    handleUpdate({
      id: company.id,
      values: { configuration: newConfiguration },
      displayToast: true
    }).then(response => {
      if (response?.error) return;
      if (checked) {
        setMessage(newConfiguration?.sendQuoteMessage);
        dispatch(updateCompany({ ...company, configuration: newConfiguration }));
      }
      onClose();
    });
  };

  return (
    <Modal
      footer={
        <>
          <span />
          <Button id="close-send-quote-modal" text onClick={() => handleSaveAndClose()}>
            Salvar alterações e Fechar
          </Button>
        </>
      }
      title="Enviar pedido de cotação para fornecedores"
      open
      width="664px"
      height="672px"
      noPadding
      onClose={() => onClose(false)}
    >
      <Card
        bodyBackgroundColor={colors.neutral75}
        margin={spaces.space2}
        width="auto"
        bodyPadding={isMobile() ? spaces.space2 : `${spaces.space1} ${spaces.space2}`}
        noBorder
      >
        <Div gap={spaces.space2} align="center" justify="space-between">
          <FontAwesomeIcon icon={faEye} color={colors.primary500} />
          <Paragraph type="small">Visualize como os fornecedores vão receber o pedido de cotação.</Paragraph>
          <Button
            id="view-as-client-send-modal"
            type="primary"
            onClick={() => window.open(`/qs/${idQuote}`, '_blank')}
            ghost
          >
            Ver como fornecedor
          </Button>
        </Div>
      </Card>
      <Div direction="column" align="flex-start" padding={`0 ${spaces.space2} ${spaces.space2}`} gap={spaces.space2}>
        <Div gap={spaces.space1}>
          <Subtitle>Mensagem</Subtitle>
          <TooltipIcon
            icon={faInfoCircle}
            iconColor={colors.primary500}
            text="Personalize a mensagem que será enviada para os fornecedores"
            tooltipProps={{ placement: 'right' }}
          />
        </Div>

        <RichTextEditor
          id="quote-suppliers-message"
          toolbar="bold italic underline"
          onChange={setMessage}
          value={message}
          height="200px"
        />
        <Checkbox value={checked} onChange={e => setChecked(e.target.checked)}>
          <Div gap={spaces.space1}>
            <Paragraph type="small">Definir como mensagem padrão</Paragraph>
            <TooltipIcon
              icon={faInfoCircle}
              iconColor={colors.primary500}
              text={`Ao definir essa mensagem como padrão, ela vai ser apresentada
                em todos os projetos no momento de envio de uma cotação`}
              tooltipProps={{ placement: 'right' }}
            />
          </Div>
        </Checkbox>
      </Div>

      <Divider style={{ margin: 0 }} />

      <Div
        direction="column"
        align="flex-start"
        padding={`${spaces.space3} ${spaces.space2}`}
        gap={spaces.space1}
        $fullWidth
      >
        <Subtitle>Envie o pedido de cotação para os fornecedores</Subtitle>
        {quoteSupplierList ? (
          <>
            {quoteSupplierList?.length ? (
              quoteSupplierList?.map(quoteSupplier => {
                return (
                  <>
                    <RenderSupplierData
                      message={message}
                      idQuoteSupplier={quoteSupplier.id}
                      name={quoteSupplier?.supplier?.name}
                      email={quoteSupplier?.supplier?.email}
                    />
                    <Divider style={{ margin: 0 }} />
                  </>
                );
              })
            ) : (
              <Paragraph>Nenhum fornecedor encontrado</Paragraph>
            )}
          </>
        ) : (
          <RenderSupplierData message={message} name={name} email={email} idQuoteSupplier={idQuoteSupplier} />
        )}
      </Div>
    </Modal>
  );
};

SendQuoteToSupplierModal.propTypes = {
  idQuoteSupplier: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  quoteSupplierList: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  idQuote: PropTypes.string
};

export default SendQuoteToSupplierModal;
