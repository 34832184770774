import { Table } from 'antd';
import styled, { css } from 'styled-components';
import { colors, fonts, radius, spaces } from '../../styles/style';

export const StyledTable = styled(Table)`
  border-radius: ${radius.radius1};
  padding-bottom: ${spaces.space1};
  border: 1px solid ${colors.neutral100};
  width: ${props => props.width && props.width};

  .ant-table-body {
    margin: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: ${fonts.sizeSm};
    }
  }

  .white-row {
    background: ${colors.white} !important;
  }

  .blank-row {
    height: ${spaces.space4};
    text-align: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .blank-row-fixed {
    background: ${colors.neutral50};
  }

  .row-with-value {
    text-align: center;
    background: ${colors.neutral50};

    > div {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .ant-table-row {
    > td {
      height: 44px;
      vertical-align: middle;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${fonts.sizeSm};
        font-weight: ${fonts.weight500};
        height: 100%;
        line-height: 1.5;
      }
      .description-value {
        justify-content: space-between;
        font-size: ${fonts.sizeMd};
      }

      ${props => css`
        &:hover {
          ${!props.$withRowShowHover &&
            css`
              .showHover {
                display: inherit;
              }
            `}
          ${props.dre &&
            css`
              > td {
                background: ${colors.primary50} !important;
              }
            `}
        }
      `}
    }
    ${props =>
      props.$withRowShowHover &&
      css`
        &:hover {
          .showHover {
            display: inherit;
          }
        }
      `}
  }

  .col-with-border {
    border-left: 1px solid ${colors.neutral100};
    text-align: center;

    text-align: right;
    > div {
      justify-content: space-between !important;
      width: 100%;
    }
  }

  .ant-table-expanded-row-fixed {
    padding: 0;
  }

  .ant-table-row-level-0 {
    .blank-row {
      background: ${colors.neutral75};
    }

    > td {
      background: ${props => (props.dre ? colors.neutral50 : colors.white)};
      > div {
        font-weight: ${fonts.weight600};
      }
    }
  }

  .subtitle {
    > td {
      background: ${colors.neutral50};
      vertical-align: middle;
      > div {
        font-weight: ${fonts.weight500};
      }
    }
  }
  .ant-table-row-level-1 {
    > td {
      background: ${colors.neutral50};
      > div {
        vertical-align: middle;
      }

      .description-value {
        padding-left: ${spaces.space2};
      }
    }

    ${props =>
      props.dre &&
      css`
        > td {
          background: ${colors.white};

          .description-value {
            font-size: ${fonts.sizeSm};
            padding-left: ${spaces.space3};
          }
        }
      `}
  }

  .ant-table-row-level-2 {
    > td {
      vertical-align: middle;
      background: ${colors.white};

      .description-value {
        padding-left: ${spaces.space3};
        font-size: ${fonts.sizeSm};

        > svg {
          font-size: ${fonts.sizeMd};
        }
      }
    }
  }

  .ant-table-row-level-3 {
    > td {
      background: ${colors.neutral50};
      vertical-align: middle;

      > div {
        font-weight: 400;
      }

      .description-value {
        font-size: ${fonts.sizeSm};
        padding-left: ${spaces.space5};
      }
    }
  }

  .cell-with-children {
    cursor: pointer;
  }

  .cashFlow-today > td {
    background-color: ${colors.neutral75};
  }

  .cashFlow-after > td {
    background-color: ${colors.neutral50};
  }
`;

export const TableContainer = styled.div`
  padding: ${props => props.padding && props.padding};
  .checkCircle {
    display: none;
  }
`;
