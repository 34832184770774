import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { spaces } from '../../styles/style';

const StyledSpin = styled(Spin)`
  margin-right: ${spaces.space1};
`;

const Loader = ({ size = 20 }) => {
  const Icon = <LoadingOutlined style={{ fontSize: size }} spin />;
  return <StyledSpin indicator={Icon} />;
};

Loader.propTypes = {
  size: PropTypes.number
};

export default Loader;
