import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { faCommentsAlt, faCommentAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import { Input, List } from 'antd';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import css from './CommentList.module.scss';
import CenteredLoader from '../Loader/CenteredLoader';
import Dropdown from '../Dropdown/Dropdown';
import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Button/Button';
import Avatar from '../AvatarEditor/Avatar';
import { Paragraph, Description } from '../Text/Text';
import { formatSinceTime, getInitials } from '../../lib/helpers/helper';

import useCRUD from '../../_Hooks/useCRUD';
import useFormState from '../../_Hooks/useFormState';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { colors, spaces } from '../../styles/style';

const schema = Yup.object().shape({
  comment: Yup.string()
    .required('Campo obrigatório')
    .test({
      test: value => value?.replace(/<("[^"]*?"|'[^']*?'|[^'">])*>/g, '').length > 0
    })
});
const initialState = {
  comment: ''
};

const CommentList = ({ data, idRefurbish, model, tooltipText, ...props }) => {
  const { formState, handleChange, isValid, reset } = useFormState(initialState, schema);
  const { values } = formState;
  const { comment } = values;
  const { user } = useSelector(state => state.authReducer);
  const [limit, setLimit] = useState(10);

  const { list: comments, handleGet, totalItems, handleCreate, loading } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const _handleGet = () => {
    return handleGet({
      refetchOptions: {
        include: [{ model: 'comments', paranoid: false }],
        where: { id: data?.id, idRefurbish },
        limit,
        order: [['comments', 'createdAt', 'asc']]
      }
    });
  };

  const handleComment = () => {
    if (!isValid()) return;

    handleCreate({
      postPathOptions: `/comments`,
      values: { comment, user, idRefurbish, idData: data.id, model },
      refresh: false
    }).then(resp => {
      if (resp?.error) return false;
      reset(initialState);
      return _handleGet();
    });
  };

  useEffect(() => {
    setLimit(10);
  }, [data]);

  useEffect(() => {
    _handleGet();
  }, [data, limit]);

  const _comments = comments[0];

  const loadMore =
    _comments?.comments?.length && _comments?.comments?.length < totalItems ? (
      <List.Item>
        <Button ghost fullWidth type="primary" size="small" shape="text" onClick={() => setLimit(limit + 10)}>
          Carregar mais comentários
        </Button>
      </List.Item>
    ) : null;

  return loading && !comments?.length ? (
    <CenteredLoader />
  ) : (
    <>
      <div className={`${css['c-file-comments']}`} {...props}>
        <div style={{ padding: `${spaces.space1} 0`, gap: spaces.space1, display: 'flex', alignItems: 'center' }}>
          <Paragraph ellipsis>{data.filename || 'Comentários'}</Paragraph>
          {tooltipText && <TooltipIcon icon={faCircleInfo} iconColor={colors.primary500} text={tooltipText} />}
        </div>
        <List level={3} loadMore={loadMore}>
          {_comments?.comments?.length ? (
            _comments?.comments?.map(item => {
              const isMine = item.user && item.user.id === user.id;
              const timeToShow = formatSinceTime(item.createdAt);
              return (
                <List.Item key={item.id} style={{ border: 0 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flex: 1,
                      ...(!isMine && { flexDirection: 'row-reverse' })
                    }}
                  >
                    <div style={{ marginRight: spaces.space1 }}>
                      <Avatar size="large" src={item.user?.avatarFullpath}>
                        {getInitials(item.user?.name)}
                      </Avatar>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Paragraph type="small">{item.user.name}</Paragraph>
                      <div>{parse(item.comment)}</div>
                      <Description>{timeToShow}</Description>
                    </div>
                    {item.user.id === user.id && !item?.deletedAt ? (
                      <div>
                        <Dropdown
                          slim
                          model="comments"
                          data={item}
                          trigger={['click']}
                          modal={{
                            confirmModal: ConfirmModal
                          }}
                          afterSubmit={() => {
                            _handleGet();
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </List.Item>
              );
            })
          ) : (
            <div className={css['c-no-comments']}>
              <FontAwesomeIcon icon={faCommentsAlt} />
              <Description>Comece uma conversa deixando uma mensagem ou anotação.</Description>
            </div>
          )}
        </List>
      </div>
      {!user?.anonymous && (
        <div className={css.action}>
          <div style={{ flex: 1, marginRight: 16 }}>
            <Input
              id="input-comment"
              placeholder="Deixe seu comentário..."
              value={comment}
              onChange={handleChange('comment')}
            />
          </div>
          <div>
            <Button id="send-comment" type="primary" onClick={handleComment} disabled={user.isCustomerView}>
              <FontAwesomeIcon icon={faCommentAlt} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

CommentList.propTypes = {
  data: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  model: PropTypes.string,
  tooltipText: PropTypes.string
};

export default CommentList;
