import AnalyticsServiceFactory from '../analytics/analytics';

const UserEventService = service => {
  const analyticsService = AnalyticsServiceFactory(service);

  return {
    identify: () => analyticsService.identify(),
    pageview: () => analyticsService.pageview(),
    onSave: (evt = 'user-finished-registered') => {
      analyticsService.track({
        event: evt
      });
    },
    onLogout: () => {
      analyticsService.track({
        event: 'user-logout'
      });
    },
    onDowngrade: removeUser => {
      analyticsService.track({
        event: 'user-downgrade',
        eventProperties: { removeUser }
      });
    }
  };
};

export default UserEventService;
