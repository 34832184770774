import AnalyticsServiceFactory from '../analytics/analytics';

const WorkReportEventService = service => {
  const analyticsService = AnalyticsServiceFactory(service);

  return {
    onReleased: workDiary =>
      analyticsService.track({
        event: 'daily-log-shared',
        eventProperties: { workDiary }
      }),
    onAccessed: (workDiary, userId) =>
      analyticsService.track({
        event: 'daily-log-accessed',
        eventProperties: { workDiary, userId }
      })
  };
};

export default WorkReportEventService;
