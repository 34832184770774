import React from 'react';
import dayjs from 'dayjs';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { colors } from '../../../styles/style';
import { getTypeOfSorter } from '../../helpers/helper';
import ExpandAllButton from '../../../components/Button/ExpandAllButton';
import ItemDetailButton from '../../../components/Button/ItemDetailButton';
import { ButtonGroup } from '../../../components/Button/ItemDetailButton.styled';

const columns = ({
  onEdit,
  onDelete,
  order,
  handleToggleAllRow,
  actualId,
  hashChildrenDREList,
  isDrawer,
  isMobile
}) => {
  const allColumns = [
    {
      title: 'Nº',
      dataIndex: 'index',
      width: 40,
      key: 'index',
      shouldCellUpdate: () => true
    },
    {
      title: () => <ExpandAllButton handleToggleAllRow={handleToggleAllRow} zIndex={1300} />,
      printTitle: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 310,
      sorter: true,
      sortOrder: order?.key === 'name' && getTypeOfSorter(order?.order),
      shouldCellUpdate: () => true
    },
    {
      title: 'Grupo do DRE',
      dataIndex: 'idDRE',
      key: 'idDRE',
      width: 150,
      render: (val, row) => {
        const isParent = row?.children?.length > 0;
        const name = isParent ? '-' : 'Não mostrar no DRE';
        if (isParent) return name;
        return hashChildrenDREList[val] || name;
      },
      shouldCellUpdate: () => true
    },
    ...(!isDrawer
      ? [
          {
            title: 'Criado em',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: isMobile ? 110 : 70,
            sorter: true,
            sortOrder: order?.key === 'createdAt' && getTypeOfSorter(order?.order),
            render: val => dayjs(val).format('DD [de] MMM YYYY')
          }
        ]
      : []),
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'action',
      width: isMobile ? 80 : 40,
      renderMobile: true,
      render: (_, row) => {
        return (
          <ButtonGroup onClick={e => e.stopPropagation()}>
            <ItemDetailButton
              titleTooltip="Editar categoria"
              onClick={() => onEdit(row.id)}
              iconColor={colors.primary500}
              icon={faPenToSquare}
            />
            <ItemDetailButton
              titleTooltip="Excluir categoria"
              onClick={() => onDelete(row)}
              iconColor={colors.red500}
              icon={faTrashCan}
              show={actualId !== row.id}
            />
          </ButtonGroup>
        );
      }
    }
  ];

  return allColumns;
};

export { columns };
