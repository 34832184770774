import { Checkbox, Table } from 'antd';
import styled, { css } from 'styled-components';
import { Div, breakpoints, colors, fonts, radius, spaces } from '../../styles/style';
import Input from '../Input/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space2};
  padding: ${spaces.space2};
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    gap: ${spaces.space1};
    margin-bottom: ${spaces.space4};
  }
`;

const Configuration = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space3};

  @media (max-width: ${breakpoints.tablet}) {
    > div {
      display: flex;
      flex-direction: column;
      gap: ${spaces.space2};
    }
  }

  @media print {
    break-inside: avoid !important;
    > div {
      flex-direction: row;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.neutral100};
  margin: ${spaces.space4} 0;
`;

const ContainerCheckboxes = styled(Checkbox.Group)`
  display: inline-block;
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const InfoLine = styled.div`
  display: flex;
  gap: ${spaces.space2};
`;

const StyledInput = styled(Input)`
  width: ${spaces.space6};
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutral75};
`;

const TableContainer = styled.div`
  border: 1px solid ${colors.neutral100};
  border-radius: ${radius.radius1};
  ${props =>
    props.withMargin &&
    css`
      margin-left: ${spaces.space2};
      margin-right: ${spaces.space2};
    `}
  .tableHeader {
    background-color: ${colors.neutral50};
    border-bottom: 1px solid ${colors.neutral100};
    border-radius: ${radius.radius1} ${radius.radius1} 0 0;
    padding: ${spaces.space1} ${spaces.space2} 0px ${spaces.space2};
  }
  .emptyState {
    color: ${colors.neutral400};
    margin: ${spaces.space2} ${spaces.space2} ${spaces.space2} ${spaces.space2};
  }
  .statusCol {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .tableRow {
    padding-left: ${spaces.space2};
    padding-right: ${spaces.space2};
    margin-bottom: ${spaces.space2};
  }
`;

const ContentRow = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  padding-top: ${spaces.space2};
`;

const ConfigurationCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space2};
`;

const ContentCreditConfirmation = styled(Div)`
  flex-direction: column;
  gap: ${spaces.space1};
`;

const ValuePaid = styled.span`
  font-weight: ${fonts.weight500};
  font-size: ${fonts.sizeH5};
  color: ${colors.green400};
`;

const StyledTable = styled(Table)`
  border-radius: ${radius.radius1};
  border: 1px solid ${colors.neutral100};

  .icon-column {
    text-align: center;
  }
  .selected-row {
    background-color: ${colors.primary50};
  }

  .ant-table-body {
    margin: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: ${colors.neutral600};
      background-color: ${colors.neutral50};
      font-size: ${fonts.sizeSm};
    }
  }

  .ant-table-thead {
    .ant-table-cell ::before {
      display: none;
    }
  }
  .ant-table-row {
    > td {
      vertical-align: middle;
      > div {
        display: flex;
        align-items: center;
        font-size: ${fonts.sizeSm};
        font-weight: ${fonts.weight600};
        height: 100%;
      }
    }
  }

  .col-with-border {
    border-left: 1px solid ${colors.neutral100};
    text-align: center;
  }

  .ant-table-expanded-row-fixed {
    padding: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => props.$gridTemplate || '1fr 1fr 1fr'};

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: ${spaces.space2};
    padding-bottom: ${spaces.space3};
  }
`;

export {
  Container,
  Configuration,
  Divider,
  ContainerCheckboxes,
  InfoLine,
  StyledInput,
  Line,
  TableContainer,
  ContentRow,
  ConfigurationCard,
  ContentCreditConfirmation,
  ValuePaid,
  StyledTable,
  Grid
};
