import { useState, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-toastify';
import curry from '../lib/helpers/curry';

const validate = (object, schema) => {
  return schema?.validate(object, { abortEarly: false });
};

const formatYupErrors = yupErrs => {
  if (!yupErrs.inner) {
    throw new Error(`Error in Validation Schema: ${yupErrs.message}`);
  }
  return yupErrs.inner.reduce((errs, err) => ({ ...errs, [err.path]: err.errors }), []);
};

let timeout;

const useFormState = (
  initValues,
  validationSchema,
  enableReinitialize = false,
  validateOnSubmit = false,
  customToast,
  setTouchedOnChange = false
) => {
  const [values, _setValues] = useState(initValues);
  const errors = useRef({});
  const [touched, _setTouched] = useState({});
  const initValuesRef = useRef(initValues);
  const valuesChangedRef = useRef(false);
  const onBlurRef = useRef(false);
  const keyChangedRef = useRef(null);

  const isValid = () => {
    return Object.values(errors.current).reduce((e, acc) => !!e.length && acc, true);
  };

  const handleChange = (key, e, noSubmit) => {
    keyChangedRef.current = key;
    const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    _setValues(prev => ({
      ...prev,
      [key]: newValue
    }));
    if (setTouchedOnChange) {
      _setTouched(prev => ({ ...prev, [key]: true }));
    }
    valuesChangedRef.current = !noSubmit;
    onBlurRef.current = false;
  };

  const handleFocus = key => {
    keyChangedRef.current = key;
    onBlurRef.current = false;
  };

  const handleResetValuesChanged = () => {
    valuesChangedRef.current = false;
  };

  const handleBlur = (key, e) => {
    e?.preventDefault && e?.preventDefault();
    onBlurRef.current = true;
    _setTouched(prevTouched => ({ ...prevTouched, [key]: true }));
  };

  const validateValues = () => {
    return validate(values, validationSchema)
      .then(() => {
        errors.current = {};
      })
      .catch(errs => {
        errors.current = formatYupErrors(errs);
      });
  };

  const handleSubmit = (callback, e) => {
    if (e) {
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
    }
    validateValues().finally(() => {
      if (customToast && !isValid()) {
        toast.error(customToast);
      }
      const _touched = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {});

      _setTouched(_touched);
      if (isValid()) {
        valuesChangedRef.current = false;
        callback(values);
      }
    });
  };

  const setField = (key, value, noSubmit) => {
    _setValues(prev => ({
      ...prev,
      [key]: value
    }));

    if (setTouchedOnChange) {
      _setTouched(prev => ({ ...prev, [key]: true }));
    }
    valuesChangedRef.current = !noSubmit;
    onBlurRef.current = true;
  };

  const setTouched = (key, value) => {
    const changed = { ...touched, [key]: value };
    _setTouched(changed);
    onBlurRef.current = false;
  };

  const setValues = changed => {
    _setValues(changed);
    valuesChangedRef.current = true;
  };

  const reset = (data, skipValuesReinitialize = false) => {
    if (enableReinitialize && !skipValuesReinitialize) initValuesRef.current = data || initValues;
    if (!skipValuesReinitialize) _setValues(initValuesRef.current);
    const _touched = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: false }), {});
    _setTouched(_touched);
    valuesChangedRef.current = false;
    onBlurRef.current = false;
    keyChangedRef.current = null;
  };

  // Goal, only save when the value is different from a initial state
  // we are using deep comparison
  useEffect(() => {
    if (Object.keys(values) && !isEqual(values, initValuesRef.current)) {
      valuesChangedRef.current = true;
    }
  }, [values]);
  // - //

  // Added use effect to validate data
  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      validationSchema && !validateOnSubmit && validateValues();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [values, touched, validationSchema]);

  useEffect(() => {
    errors.current = {};
    _setTouched({});
  }, [validationSchema]);

  useEffect(() => {
    if (enableReinitialize && !isEqual(initValues, initValuesRef.current)) reset();
  }, [initValues]);

  return {
    formState: { values, errors: errors.current, touched },
    handleSubmit: curry(handleSubmit),
    handleChange: curry(handleChange),
    handleBlur: curry(handleBlur),
    setField: curry(setField),
    setTouched: curry(setTouched),
    handleFocus,
    valuesChanged: valuesChangedRef.current,
    onBlur: onBlurRef.current,
    keyChanged: keyChangedRef,
    setValues,
    isValid,
    reset,
    handleResetValuesChanged,
    validateValues
  };
};

export default useFormState;
