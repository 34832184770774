import React from 'react';
import PropTypes from 'prop-types';
import { faSquareList } from '@fortawesome/pro-duotone-svg-icons';
import isEqual from 'react-fast-compare';
import NoContent from '../NoContent/NoContent';

import { Div, colors, fonts, spaces } from '../../styles/style';
import MobileCardItemV2 from './MobileCardItemV2';
import Card from '../Card/Card';
import Button from '../Button/Button';

const MobileCardListV2 = ({
  list = [],
  columns,
  footer,
  childrenColumnName,
  readOnlyMobile,
  noContentMobile,
  totalItems,
  handleLoad,
  onClick = () => {},
  handleChange,
  renderTitleMobile,
  renderFooterMobile = true,
  mobileItemFooter,
  customEmptyState,
  marginMobile,
  cardPadding,
  renderCustomRow
}) => {
  return (
    <>
      {list?.length || footer ? (
        <>
          {list?.length
            ? list?.map((item, index) => {
                const { customRow } = item || {};

                return customRow ? (
                  renderCustomRow(item, index)
                ) : (
                  <Div direction="column" margin={`${spaces.space1} 0}`} key={`card-item-${item?.id}`} $fullWidth>
                    <MobileCardItemV2
                      item={item}
                      index={index}
                      columns={columns}
                      childrenColumnName={childrenColumnName}
                      readOnlyMobile={readOnlyMobile}
                      renderTitleMobile={renderTitleMobile}
                      onClick={() => onClick(item, true)}
                      handleLoad={handleLoad}
                      handleChange={handleChange}
                      marginMobile={marginMobile}
                      cardPadding={cardPadding}
                      mobileItemFooter={mobileItemFooter}
                    />
                  </Div>
                );
              })
            : null}
          {footer && renderFooterMobile && (
            <Card
              bodyJustify="flex-start"
              bodyBackgroundColor={colors.neutral50}
              bodyPadding={`${spaces.space2} ${spaces.space2} 0 ${spaces.space2}`}
            >
              {footer()}
            </Card>
          )}
          {totalItems > list?.length ? (
            <Button onClick={handleLoad} type="primary" text>
              Carregar mais...
            </Button>
          ) : null}
        </>
      ) : (
        <>
          {customEmptyState ? (
            customEmptyState()
          ) : (
            <NoContent
              description={noContentMobile}
              icon={faSquareList}
              showButtonIcon={false}
              renderButton={false}
              styleIcon={{ color: colors.primary300 }}
              weight={fonts.weight500}
              descriptionColor={colors.neutral500}
            />
          )}
        </>
      )}
    </>
  );
};

MobileCardListV2.propTypes = {
  list: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  footer: PropTypes.func,
  childrenColumnName: PropTypes.string,
  readOnlyMobile: PropTypes.bool,
  noContentMobile: PropTypes.string,
  totalItems: PropTypes.number,
  handleLoad: PropTypes.func,
  onClick: PropTypes.func,
  handleChange: PropTypes.func,
  renderTitleMobile: PropTypes.bool,
  renderFooterMobile: PropTypes.bool,
  mobileItemFooter: PropTypes.func,
  customEmptyState: PropTypes.func,
  marginMobile: PropTypes.string,
  cardPadding: PropTypes.string,
  renderCustomRow: PropTypes.func
};

export default React.memo(MobileCardListV2, isEqual);
