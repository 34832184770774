import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'antd';
import { store } from '../../config/redux-store';
import ImageInput from '../../../components/Input/ImageInput';
import { colors, spaces, Div, fonts } from '../../../styles/style';
import Avatar from '../../../components/AvatarEditor/Avatar';

import { getDateWithCustomHours, getInitials, replaceDecimalNumber, validDate } from '../../helpers/helper';
import RangePicker from '../../../components/Datepicker/RangePicker';
import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import formatCurrency from '../../helpers/formatCurrency';
import EditableInput from '../../../components/Input/EditableInput';
import ExpandAllButton from '../../../components/Button/ExpandAllButton';
import { NameColumnContainer } from './columns.styles';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import ColorPickerDropDown from '../../../components/Dropdown/ColorPickerDropDown';
import TooltipText from '../../../components/Tooltip/TooltipText';
import { calculateColumnDurationChange } from '../../helpers/ganttHelperFunctions';

const columns = ({
  readOnly,
  handleChange = f => f,
  printColumns = [],
  isPrint,
  isCustomer,
  minWidth,
  handleToggleAllRow,
  workDays,
  plBasedDate,
  dayoffs,
  columnsToShow = {},
  ganttInstance,
  isTemplate,
  getFormattedLinks,
  handleChangePredecessor,
  userType
}) => {
  const _isPrint = !!printColumns?.length || isPrint;

  const sortable = !_isPrint || !isTemplate;

  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;

  const numberColumn = {
    title: 'Nº',
    dataIndex: 'number',
    width: 64,
    key: 'number',
    render: (val, row) => {
      return row.isRoot ? (
        <div />
      ) : (
        <div
          key={`number${row.id}`}
          style={{ display: 'flex', alignItems: 'center', minHeight: spaces.space4, marginLeft: spaces.space0 }}
        >
          {row?.index}
        </div>
      );
    },
    shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.index !== nextRecord.index
  };

  const itemColumn = {
    title: () => <ExpandAllButton handleToggleAllRow={handleToggleAllRow} />,
    printTitle: 'Item',
    dataIndex: 'name',
    key: 'name',
    ...minWidth,
    renderCollapseIcon: true,
    sorter: sortable,
    alwaysShow: true,
    render: (val, row) => {
      if (row.isRoot) {
        return (
          <Subtitle style={{ fontSize: fonts.sizeMd }} color={colors.neutral600} className="custom-content-pdf">
            {row?.name}
          </Subtitle>
        );
      }
      return (
        <NameColumnContainer
          className="table-name custom-content-pdf"
          padding={_isPrint && spaces.space0}
          gap={spaces.space1}
        >
          {!row?.idParent && (
            <ColorPickerDropDown
              color={row.color ? row.color : colors.neutral300}
              disabled={!!readOnly || _isPrint || isCustomer}
              onSelect={value =>
                handleChange(
                  {
                    id: row.id,
                    color: value,
                    from: 'List'
                  },
                  false
                )
              }
            />
          )}
          {refurbishItemType.notParent?.includes(row.type) && (
            <div
              className={row.type === refurbishItemType.product && row.images?.length && 'table-name-image-margin-left'}
            >
              <ImageInput
                cors={isPrint}
                key={`image${row.id}`}
                id={row.id}
                value={row.type === refurbishItemType.product ? row.image || 'boxOpen' : row.image}
                images={row.images}
                disabled={!!readOnly || row.isDisabled || isPrint || !!val}
                onClick={row.onClick}
                labelMargin={`0 ${spaces.space0} 0 0`}
              />
            </div>
          )}
          {val}
        </NameColumnContainer>
      );
    },
    shouldCellUpdate: false
  };

  const quantityColumn = {
    title: 'Qtd',
    dataIndex: 'quantity',
    width: 80,
    key: 'quantity',
    render: (val, row) => {
      return row.isRoot || !refurbishItemType?.notParent?.includes(row?.type) ? (
        <div />
      ) : (
        <Div>
          {`${formatCurrency(row?.quantity, { decimalCount: replaceDecimalNumber(row?.quantity) })}`}
          {row.idUnit > 1 ? ` / ${row.units?.name}` : ''}
        </Div>
      );
    },
    shouldCellUpdate: false
  };

  const durationColumn = {
    title: 'Duração',
    dataIndex: 'duration',
    width: 80,
    key: 'duration',
    render: (val, row) => {
      const disabled = plBasedDate && row?.type === refurbishItemType?.parent;

      let _val =
        disabled && row.plStartDate && row.plEndDate
          ? ganttInstance?.calculateDuration({
              start_date: ganttInstance.date.convert_to_utc(getDateWithCustomHours(row.plStartDate, { hours: 3 })),
              end_date: ganttInstance.date.convert_to_utc(getDateWithCustomHours(row.plEndDate, { hours: 15 }))
            })
          : val;

      if (disabled && (!row.plStartDate || !row.plEndDate)) _val = 0;

      if (row.isRoot) {
        return (
          <Subtitle color={colors.neutral600} className="custom-content-pdf">
            {_val}
          </Subtitle>
        );
      }
      if (_isPrint || isCustomer || readOnly)
        return (
          <Subtitle color={colors.neutral600} className="custom-content-pdf">
            {_val}
          </Subtitle>
        );

      return (
        row.id !== 0 && (
          <EditableInput
            key={`code${row.id}`}
            noWrap
            value={_val}
            disabled={disabled}
            row={row}
            width="100%"
            onChange={value =>
              handleChange(
                calculateColumnDurationChange(ganttInstance, isTemplate, row, {
                  startDateField: 'plStartDate',
                  endDateField: 'plEndDate'
                })(value)
              )
            }
            rowId={row.id}
          />
        )
      );
    },
    shouldCellUpdate: (prevRecord, nextRecord) =>
      prevRecord.duration !== nextRecord.duration ||
      prevRecord.plStartDate !== nextRecord.plStartDate ||
      prevRecord.plEndDate !== nextRecord.plEndDate
  };

  const startDateColumn = {
    title: 'Data inicio',
    dataIndex: 'plStartDate',
    key: 'plDates',
    width: 120,
    sorter: sortable,
    renderMobile: true,
    onCell: () => ({
      colSpan: 2
    }),
    render: (val, row) => {
      const { onClick = f => f, isRoot } = row;

      if (row?.isRoot || _isPrint || readOnly) {
        return (
          <Div $fullWidth justify="space-between">
            <Subtitle color={colors.neutral600} className="custom-content-pdf" style={{ flex: 1 }}>
              {validDate(row?.plStartDate, 'DD/MM/YY')}
            </Subtitle>
            <Subtitle color={colors.neutral600} className="custom-content-pdf" style={{ flex: 1 }}>
              {validDate(row?.plEndDate, 'DD/MM/YY')}
            </Subtitle>
          </Div>
        );
      }

      return !isRoot ? (
        <Div $fullWidth key={`endDate-${row.id}`} role="presentation" onClick={onClick} style={{ alignSelf: 'center' }}>
          <RangePicker
            id={`calendar-${row.id}`}
            size="small"
            format="DD/MM/YY"
            duration={row.duration}
            disabled={isCustomer}
            value={[
              row.plStartDate ? getDateWithCustomHours(row.plStartDate, { hours: 3 }) : row.plStartDate,
              row.plEndDate ? getDateWithCustomHours(row.plEndDate, { hours: 15 }) : row.plEndDate
            ]}
            isDelayed={row?.isDelayed || null}
            onChange={value => {
              const datesObj = {
                start_date: value.start
                  ? ganttInstance.date.date_part(getDateWithCustomHours(value.start, { hours: 3 }))
                  : value.start,
                end_date: value.end ? getDateWithCustomHours(value.end, { hours: 15 }) : value.end,
                unscheduled: !value.start || !value.end
              };

              handleChange({
                id: row.id,
                ...datesObj,
                basedDate: value.taskBasedDate,
                from: 'List'
              });
            }}
            taskCustomCalendar
            buttonProps={{ text: true, padding: 0, style: { textAlign: 'left' } }}
            taskBasedDate={plBasedDate && row?.type === refurbishItemType?.parent}
            textUnlocked={
              <Paragraph type="large">
                <b>Defina as datas e duração a partir dos itens.</b>
                <br />
                <br />
                Para preencher as datas nos níveis, você deve desativar o botão “Vincular datas dos níveis aos itens”
                nas Configurações
                <FontAwesomeIcon
                  style={{ marginLeft: spaces.space1 }}
                  size="sm"
                  icon={faGear}
                  color={colors.primary500}
                />
              </Paragraph>
            }
            planning
            workDays={workDays}
            dayoffs={dayoffs}
            externalFunctions={{
              calculateEndDate: ganttInstance?.calculateEndDate,
              convertToUtc: ganttInstance.date.convert_to_utc
            }}
          />
        </Div>
      ) : null;
    },
    shouldCellUpdate: (prevRecord, nextRecord) =>
      prevRecord.plStartDate !== nextRecord.plStartDate ||
      prevRecord.plEndDate !== nextRecord.plEndDate ||
      prevRecord.basedDate !== nextRecord.basedDate ||
      prevRecord.duration !== nextRecord.duration
  };

  const endDateColumn = {
    title: 'Data fim',
    dataIndex: 'plStartDate',
    key: 'plDates',
    sorter: sortable,
    width: 120,
    renderMobile: true,
    onCell: () => ({
      colSpan: 0
    }),
    shouldCellUpdate: (prevRecord, nextRecord) =>
      prevRecord.plStartDate !== nextRecord.plStartDate ||
      prevRecord.plEndDate !== nextRecord.plEndDate ||
      prevRecord.basedDate !== nextRecord.basedDate
  };

  const responsibleColumn = {
    title: 'Responsável',
    dataIndex: ['user', 'name'],
    key: 'idResponsible',
    className: 'col-grow-1',
    width: 140,
    sorter: sortable,
    render: (val, row) => {
      if (row.isRoot) return <div />;
      if (_isPrint || isCustomer || readOnly) {
        return <div className="custom-content-pdf">{row?.user?.name || '-'}</div>;
      }
      const { user, id } = row;
      return (
        <ColumnWrapper
          model="user"
          options={{
            where: { isActive: true, userType: [userType?.provider?.value, userType?.guest?.value] },
            order: [['name']]
          }}
          value={user}
          disabled={isCustomer}
          cleanValue={null}
          canCreate={false}
          onSelect={idResponsible => handleChange({ id, idResponsible, from: 'List' })}
          IconSelector={({ item }) => <Avatar src={item.avatarFullpath}>{getInitials(item?.name)}</Avatar>}
        >
          {user && (
            <Avatar id={`${id}-${user?.name}`} src={user?.avatarFullpath}>
              {getInitials(user?.name)}
            </Avatar>
          )}
        </ColumnWrapper>
      );
    },
    shouldCellUpdate: (prevRecord, nextRecord) => JSON.stringify(prevRecord) !== JSON.stringify(nextRecord)
  };

  const predecessorColumn = {
    title: !isCustomer ? (
      <TooltipText
        tooltipText={
          <div>
            Digite a <b>numeração</b> da predecessora + <b>sigla do tipo de dependência</b> + intervalo de dias para
            criar a dependência. Separe por vírgulas para criar múltiplas dependências.
            <br />
            <br />
            Ex: 1.1FF+2, 1.2FI+3
          </div>
        }
        text="Predecessoras"
      />
    ) : (
      'Predecessoras'
    ),
    dataIndex: 'targetLinks',
    width: 140,
    key: 'targetLinks',
    render: (val, row) => {
      if (row.isRoot) return null;

      const formattedLinks = getFormattedLinks({ value: val });

      if (_isPrint || isCustomer) {
        return (
          <Subtitle color={colors.neutral600} className="custom-content-pdf">
            {formattedLinks}
          </Subtitle>
        );
      }

      return row?.isRoot ? null : (
        <Tooltip
          title={
            formattedLinks ? (
              <div>
                {formattedLinks.split(',').map(item => (
                  <div key={item}>{item}</div>
                ))}
              </div>
            ) : null
          }
        >
          <EditableInput
            key={`predecessor${row.id}`}
            noWrap
            value={formattedLinks}
            disabled={row?.isRoot}
            row={row}
            width="100%"
            style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}
            onClick={e => e.stopPropagation()}
            map={value => handleChangePredecessor({ value, idTargetTask: row?.id })}
            rowId={row.id}
          />
        </Tooltip>
      );
    }
  };
  const allColumns = isTemplate
    ? [numberColumn, itemColumn, quantityColumn, durationColumn, responsibleColumn, predecessorColumn]
    : [
        numberColumn,
        itemColumn,
        quantityColumn,
        durationColumn,
        startDateColumn,
        endDateColumn,
        responsibleColumn,
        predecessorColumn
      ];

  if (_isPrint) {
    return allColumns?.filter(c => printColumns.includes(c.key) && c.dataIndex);
  }

  return allColumns.filter(c => {
    return columnsToShow?.[c.key] || c.alwaysShow;
  });
};

export { columns };
