import formatCurrency from '../../helpers/formatCurrency';

export const labor = {
  supplier: { label: 'Fornecedor', nestedKey: 'name' },
  refurbishGroup: { label: 'Grupo', nestedKey: 'name' },
  price: {
    label: 'Preço',
    format: value => formatCurrency(value || 0, { currencySymbol: 'R$' })
  },
  costCenter: { label: 'Categoria', nestedKey: 'name' },
  observation: { label: 'Observações', otherProps: { ellipsis: true, span: 2, html: true } }
};
