import dayjs from 'dayjs';
import formatCurrency from '../../helpers/formatCurrency';
import { formatDocument } from '../../helpers/helper';

export const withdrawInfo = isPix => ({
  ownerName: { label: 'Nome' },
  document: { label: 'CPF/CNPJ', format: value => formatDocument(value) || '-' },
  ...(isPix
    ? { keyPix: { label: 'Chave' } }
    : {
        bankAccountType: { label: 'Tipo de conta' },
        agency: { label: 'Agência' },
        account: { label: 'Conta' },
        bankName: { label: 'Instituição' }
      })
});

export const withdrawValues = (fees, isPix, totalValue) => {
  const quantityFree = fees?.transfer?.monthlyTransfersWithoutFee;
  const pixTax = quantityFree > 0 ? 0 : fees?.transfer?.pix?.feeValue;
  const tedTax = quantityFree > 0 ? 0 : fees?.transfer?.ted?.feeValue;

  return {
    transferDate: { label: 'Data do pagamento', format: () => dayjs().format('DD/MM/YYYY ') },
    value: {
      label: 'Valor',
      format: value => formatCurrency(value || 0, { currencySymbol: 'R$' })
    },
    tax: {
      label: 'Taxa',
      format: () => formatCurrency(isPix ? pixTax : tedTax, { currencySymbol: 'R$' })
    },
    debited: {
      label: 'Total debitado',
      format: () => formatCurrency(totalValue - (isPix ? pixTax : tedTax), { currencySymbol: 'R$' })
    },
    description: {
      label: 'Descrição',
      format: value => value || '-',
      otherProps: { span: 2 }
    }
  };
};
