import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faChevronUp, faChevronDown, faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import Dropdown from './Dropdown';
import { colors, spaces } from '../../styles/style';
import Avatar from '../AvatarEditor/Avatar';
import { getInitials, toHHMM } from '../../lib/helpers/helper';
import SelectDropdown from './SelectDropdown';
import DatePicker from '../Datepicker/Datepicker';
import MaskedInput from '../Input/MaskedInput';
import useCRUD from '../../_Hooks/useCRUD';
import { useContextHook as useRefurbish } from '../../contexts/Context';
import Button from '../Button/Button';
import useViewport from '../../_Hooks/useViewport';

import {
  Container,
  Menu,
  Header,
  ResponsibleInfo,
  Footer,
  AddHourContainer,
  RightItemsContainer,
  HoursContainer
} from './PerformedHoursDropdown.styled';
import { Paragraph } from '../Text/Text';

const PerformedHoursDropdown = ({
  totalHours,
  isApply,
  idTask,
  afterSubmit,
  onDraftSubmit,
  isForm = false,
  forceOpen = false,
  property,
  onClose = f => f,
  externalSetOpen = f => f
}) => {
  const { user } = useSelector(state => state.authReducer);
  const [taskTotalHours, setTaskTotalHours] = useState(totalHours);
  const [responsible, setResponsible] = useState(user);
  const [date, setDate] = useState(new Date());
  const [timePerformed, setTimePerformed] = useState(0);
  const [open, setOpen] = useState(forceOpen);
  const [collapsedRows, setCollapsedRows] = useState({});
  const [createdDrafts, setCreatedDrafts] = useState({});
  const [updatedDrafts, setUpdatedDrafts] = useState({});
  const [deletedDrafts, setDeletedDrafts] = useState({});
  const [totalHoursForResponsibles, setTotalHoursForResponsibles] = useState({});
  const { data: refurbish } = useRefurbish();
  const preventLoad = useRef(false);
  const { isMobile } = useViewport(window.innerWidth);

  const mode = onDraftSubmit ? 'draft' : 'component';

  const {
    handleCreate: createPerformedHour,
    handleUpdate: updatePerformedHour,
    handleDelete: deletePerformedHour
  } = useCRUD({
    model: 'performedHours',
    immediatelyLoadData: false
  });

  const { list, handleGet: getResponsibles } = useCRUD({
    model: 'user',
    options: {
      include: [
        {
          model: 'performedHours',
          where: {
            idTask
          }
        }
      ],
      order: [
        ['name', 'asc'],
        ['performedHours', 'date', 'desc']
      ],
      paranoid: false
    },
    immediatelyLoadData: false
  });

  const handleLoad = preventNext => {
    if (preventLoad.current) return;

    if (preventNext) {
      preventLoad.current = true;
    }

    if (!idTask) return;

    getResponsibles({}).then(responsibles => {
      if (mode === 'component') {
        setTaskTotalHours(responsibles?.reduce((acc, cur) => acc + cur.totalPerformedHours, 0));
      }
      setTotalHoursForResponsibles(
        responsibles.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.totalPerformedHours }), {})
      );
    });

    if (afterSubmit) afterSubmit();
  };

  useEffect(() => {
    if (mode === 'draft') {
      const totalHoursForResponsiblesValues = Object.values(totalHoursForResponsibles);
      setTaskTotalHours(
        totalHoursForResponsiblesValues.length
          ? totalHoursForResponsiblesValues.reduce((acc, cur) => acc + cur, 0)
          : totalHours
      );
    } else {
      setTaskTotalHours(totalHours);
    }
  }, [createdDrafts, updatedDrafts, deletedDrafts, totalHours]);

  useEffect(() => {
    if (isMobile() || forceOpen) {
      handleLoad();
    }
  }, []);

  const submitDraft = () => {
    const formattedCreatedDrafts = Object.keys(createdDrafts).reduce((acc, idResponsible) => {
      const hoursArray = createdDrafts[idResponsible].performedHours.reduce((hours, hour) => {
        if (!deletedDrafts[hour?.id]) {
          hours.push({
            ...hour,
            idResponsible,
            id: undefined,
            idTask
          });
        }

        return hours;
      }, []);
      return [...acc, ...hoursArray];
    }, []);

    const formattedUpdatedDrafts = Object.keys(updatedDrafts).reduce(
      (acc, cur) => ({
        ...acc,
        ...(deletedDrafts[cur] || cur.startsWith('timestamp-') ? {} : { [cur]: updatedDrafts[cur] })
      }),
      {}
    );

    const formattedDeletedDrafts = Object.entries(deletedDrafts).reduce((acc, [key, value]) => {
      if (!key.startsWith('timestamp-')) {
        acc[key] = value;
      }

      return acc;
    }, {});

    onDraftSubmit({
      createdDrafts: formattedCreatedDrafts,
      updatedDrafts: formattedUpdatedDrafts,
      deletedDrafts: formattedDeletedDrafts
    });
  };

  const handleCloseDropdown = () => {
    setOpen(!open);
    if (forceOpen) {
      onClose(taskTotalHours);
      externalSetOpen(false, property);
    }
    if (mode === 'draft') {
      submitDraft();
    }
  };

  const toggleRow = id => {
    setCollapsedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const functionsMap = {
    draft: {
      handleSubmit: () => {
        if (!timePerformed) return;
        setCreatedDrafts(prev => {
          const drafts = { ...prev };
          const performedHourObj = {
            date: new Date(date).toISOString(),
            timePerformed,
            id: `timestamp-${new Date().getTime()}`,
            idResponsible: responsible.id
          };
          if (drafts[responsible.id]) {
            drafts[responsible.id].performedHours.push(performedHourObj);
            drafts[responsible.id].totalPerformedHours += timePerformed;
          } else {
            drafts[responsible.id] = {
              id: responsible.id,
              name: responsible.name,
              avatarFullpath: responsible.avatarFullpath,
              totalPerformedHours: timePerformed,
              performedHours: [performedHourObj]
            };
          }
          return drafts;
        });
        setTotalHoursForResponsibles(prev => ({
          ...prev,
          [responsible.id]: (prev[responsible.id] || 0) + timePerformed
        }));
      },
      handleUpdate: ({ id, pickedDate, time, oldTime, idResponsible }) => {
        if (String(id).startsWith('timestamp-')) {
          const updated = {
            ...createdDrafts[idResponsible]?.performedHours.find(hour => hour.id === id),
            ...(pickedDate && { date: pickedDate.toISOString() }),
            ...(time && { timePerformed: time })
          };
          setCreatedDrafts(prev => ({
            ...prev,
            [idResponsible]: {
              ...prev[idResponsible],
              performedHours: createdDrafts[idResponsible]?.performedHours.map(hour =>
                hour.id === id ? updated : hour
              )
            }
          }));
        } else {
          setUpdatedDrafts(prev => ({
            ...prev,
            [id]: {
              date: pickedDate?.toISOString() || prev?.[id]?.date,
              timePerformed: time || prev?.[id]?.timePerformed
            }
          }));
        }
        if (time && time !== oldTime) {
          setTotalHoursForResponsibles(prev => ({
            ...prev,
            [idResponsible]: (prev[idResponsible] || 0) + time - oldTime
          }));
        }
      },
      handleDelete: ({ id, timePerformed: time, idResponsible }) => {
        setDeletedDrafts(prev => ({ ...prev, [id]: time }));
        setTotalHoursForResponsibles(prev => ({
          ...prev,
          [idResponsible]: (prev[idResponsible] || 0) - (updatedDrafts[id]?.timePerformed || time)
        }));
      },
      getResponsiblesList: () => {
        return list.concat(
          Object.entries(createdDrafts || {}).reduce((acc, [key, value]) => {
            if (!list.find(u => key === String(u.id))) {
              return [...acc, value];
            }
            return acc;
          }, [])
        );
      },
      getHoursList: hoursResponsible => {
        return hoursResponsible.performedHours.concat(
          (list.find(u => hoursResponsible.id === u.id) && createdDrafts?.[hoursResponsible.id]?.performedHours) || []
        );
      }
    },
    component: {
      handleSubmit: () => {
        createPerformedHour({
          values: { date, timePerformed, idResponsible: responsible.id, idTask, idRefurbish: refurbish?.id },
          displayToast: 'Horas adicionadas com sucesso.',
          refresh: false
        }).then(() => handleLoad());
      },
      handleUpdate: ({ id, pickedDate, time }) => {
        updatePerformedHour({
          id,
          values: {
            ...(pickedDate && { date: pickedDate.toISOString() }),
            ...(time && { timePerformed: time }),
            idRefurbish: refurbish?.id
          },
          refresh: false
        }).then(() => handleLoad());
      },
      handleDelete: ({ id, timePerformed: time }) => {
        deletePerformedHour({
          id,
          values: { timePerformed: time, idRefurbish: refurbish?.id },
          displayToast: true,
          refresh: false
        }).then(() => handleLoad());
      },
      getResponsiblesList: () => list,
      getHoursList: hoursResponsible => hoursResponsible.performedHours
    }
  };

  const HeaderComponent = () => (
    <Header $offsetTime={isMobile()}>
      <Paragraph>Horas realizadas</Paragraph>
      <Paragraph id="task-total-hours">{toHHMM(taskTotalHours)}</Paragraph>
      {!isMobile() && (
        <Button id="close-performed-hour-dropdown" type="text" padding={0} onClick={handleCloseDropdown}>
          <FontAwesomeIcon icon={faXmark} color={colors.neutral400} size="lg" />
        </Button>
      )}
    </Header>
  );

  const FooterComponent = () => (
    <Footer>
      <p>Adicionar hora</p>
      <AddHourContainer>
        {responsible && (
          <SelectDropdown
            model="user"
            trigger={['click']}
            options={{ where: { isActive: true, idCompany: responsible.idCompany }, order: [['name']] }}
            onSelect={val => {
              setResponsible(val);
            }}
            data={responsible}
            overlayStyle={{ zIndex: 12000 }}
            canCreate={false}
          >
            <Avatar id="responsible-dropdown" src={responsible?.avatarFullpath}>
              {getInitials(responsible?.name)}
            </Avatar>
          </SelectDropdown>
        )}
        <RightItemsContainer noBorder>
          <DatePicker
            id="performed-hours-date"
            name="performedHoursDate"
            format="DD/MM/YY"
            value={date ? dayjs(date) : dayjs()}
            allowClear={false}
            onChange={pickedDate => setDate(pickedDate)}
            suffixIcon={null}
            size="small"
          />
          <MaskedInput
            id="performed-hours"
            readOnly={isApply}
            type="tel"
            $noBorder
            placeholder="0:00"
            value={timePerformed}
            onBlur={value => setTimePerformed(value)}
          />
          <Button
            id="add-performed-hour"
            type="text"
            onClick={() => {
              functionsMap[mode].handleSubmit();
              setTimePerformed(0);
            }}
          >
            <FontAwesomeIcon icon={faCheck} color={colors.green400} size="lg" />
          </Button>
        </RightItemsContainer>
      </AddHourContainer>
    </Footer>
  );

  const ResponsibleRow = () => {
    return (
      <HoursContainer>
        {functionsMap[mode].getResponsiblesList().map(hoursResponsible => (
          <div className="responsible-row" key={hoursResponsible.id}>
            <ResponsibleInfo>
              <Avatar src={hoursResponsible?.avatarFullpath}>{getInitials(hoursResponsible?.name)}</Avatar>
              <MaskedInput
                readOnly
                type="tel"
                $noBorder
                placeholder="0:00"
                value={totalHoursForResponsibles[hoursResponsible.id]}
              />
              <Button
                id="expand-collapse"
                type="text"
                padding={spaces.space0}
                onClick={() => toggleRow(hoursResponsible.id)}
              >
                <FontAwesomeIcon
                  icon={collapsedRows[hoursResponsible.id] ? faChevronUp : faChevronDown}
                  color={colors.neutral400}
                />
              </Button>
            </ResponsibleInfo>
            {collapsedRows[hoursResponsible.id] &&
              functionsMap[mode].getHoursList(hoursResponsible).map(
                performedHour =>
                  !deletedDrafts[performedHour.id] && (
                    <RightItemsContainer className="hour-row" key={performedHour.id} dark>
                      <DatePicker
                        id={`performed-hours-date-${performedHour.id}`}
                        name={`performedHoursDate-${performedHour.id}`}
                        format="DD/MM/YY"
                        value={dayjs(updatedDrafts[performedHour.id]?.date || performedHour.date)}
                        allowClear={false}
                        onChange={pickedDate =>
                          functionsMap[mode].handleUpdate({
                            id: performedHour.id,
                            pickedDate,
                            idResponsible: hoursResponsible.id
                          })
                        }
                        suffixIcon={null}
                        size="small"
                      />
                      <MaskedInput
                        type="tel"
                        $noBorder
                        placeholder="0:00"
                        value={performedHour.timePerformed}
                        onBlur={time =>
                          functionsMap[mode].handleUpdate({
                            id: performedHour.id,
                            time,
                            oldTime: updatedDrafts[performedHour.id]?.timePerformed || performedHour.timePerformed,
                            idResponsible: hoursResponsible.id
                          })
                        }
                      />
                      <Button
                        id="delete-performed-hour"
                        type="text"
                        onClick={() => {
                          functionsMap[mode].handleDelete(performedHour);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} color={colors.red500} size="lg" />
                      </Button>
                    </RightItemsContainer>
                  )
              )}
          </div>
        ))}
      </HoursContainer>
    );
  };

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={e => {
        setOpen(e);
        if (mode === 'draft') {
          submitDraft();
        }
        if (!e && forceOpen) {
          onClose(taskTotalHours);
          externalSetOpen(false, property);
        }
        handleLoad(mode === 'draft');
      }}
      menuMaxHeight="416px"
      open={open}
      zIndex={1025}
      noModalPadding
      initiallyOpen={forceOpen && isMobile()}
      mobileTitle=""
      onMobileClose={() => {
        if (forceOpen) {
          onClose(taskTotalHours);
          externalSetOpen(false, property);
        }
        if (mode === 'draft') {
          submitDraft();
        }
      }}
      menu={
        <Menu id="#performed-hours-menu" onClick={e => e.stopPropagation()}>
          {HeaderComponent()}
          {ResponsibleRow()}
          {FooterComponent()}
        </Menu>
      }
    >
      <Container id="performed-hours-dropdown" onClick={e => e.stopPropagation()} isForm={isForm}>
        {isForm && <FontAwesomeIcon icon={faClock} color={colors.neutral400} />}
        {toHHMM(taskTotalHours)}
      </Container>
    </Dropdown>
  );
};

PerformedHoursDropdown.propTypes = {
  totalHours: PropTypes.number,
  isApply: PropTypes.bool,
  idTask: PropTypes.number,
  afterSubmit: PropTypes.func,
  onDraftSubmit: PropTypes.func,
  isForm: PropTypes.bool,
  forceOpen: PropTypes.bool,
  externalSetOpen: PropTypes.func,
  onClose: PropTypes.func,
  property: PropTypes.string
};

export default PerformedHoursDropdown;
