import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { colors, fonts } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const DescriptionDiv = styled.div`
  width: 100%;

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  td {
    border: 1px solid ${colors.neutral300};
    padding: 0.4rem;
  }

  p {
    font-size: ${fonts.sizeSm};
  }
`;

const RichTextViewer = ({ value, emptyStateText = '' }) => {
  return (
    <DescriptionDiv>
      <Paragraph type="small">{value ? parse(value) : emptyStateText}</Paragraph>
    </DescriptionDiv>
  );
};

RichTextViewer.propTypes = {
  value: PropTypes.string.isRequired,
  emptyStateText: PropTypes.string
};

export default RichTextViewer;
