import React, { useEffect, useState } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCRUD from '../_Hooks/useCRUD';
import CenteredLoader from '../components/Loader/CenteredLoader';

const Embed = ({ page, height = '90vh', _idRefurbish }) => {
  const { search } = useLocation();
  let params = search;
  if (_idRefurbish) {
    params = search ? `${search}&idRefurbish=${_idRefurbish}` : `?idRefurbish=${_idRefurbish}`;
  }
  params = params ? `${params}&embedded=true` : '?embedded=true';
  const [hasAccess, setHasAccess] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  const { handleGet } = useCRUD({
    model: 'refurbish',
    showToast: false,
    immediatelyLoadData: false
  });

  const handleIfrmeLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const { idRefurbish } = qs.parse(search, { ignoreQueryPrefix: true });

    if (!idRefurbish) {
      setHasAccess(true);
      return;
    }

    handleGet({
      refetchOptions: {
        include: [
          {
            model: 'user',
            where: isCustomer ? { id: user?.id } : { idCompany: user.idCompany }
          }
        ]
      },
      refetchPathOptions: `/${idRefurbish}`
    }).then(resp => {
      if (resp && !resp?.error) {
        setHasAccess(true);
        return;
      }
      toast.error('Acesso negado');
    });
  }, []);

  return (
    <>
      {hasAccess && (
        <iframe
          style={{ width: '100%', height, ...(loading && { display: 'none' }) }}
          title="bubbleContent"
          src={`${process.env.REACT_APP_BUBBLE_URL}/${page}${params}`}
          allow="clipboard-read; clipboard-write"
          data-hj-allow-iframe
          onLoad={handleIfrmeLoad}
        />
      )}
      {loading && <CenteredLoader />}
    </>
  );
};

Embed.propTypes = {
  page: PropTypes.string,
  height: PropTypes.string,
  _idRefurbish: PropTypes.string
};

export default Embed;
