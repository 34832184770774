import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useContextHook } from '../../contexts/GeneralContext';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import Select from '../Select/Select';
import Label from '../Label/Label';

const Content = styled(Div)`
  gap: ${spaces.space4};
  @media (max-width: ${breakpoints.mobile}) {
    flex-wrap: wrap;
    gap: ${spaces.space1};
    justify-content: space-between;
    div:last-child {
      min-width: 100%;
    }
  }
`;

const Segment = styled.div`
  display: flex;
  flex-direction: column;

  align-self: self-end;
  @media (max-width: ${breakpoints.mobile}) {
    min-width: ${props => (props.$fullWidth ? '100%' : '48%')};
  }

  .ant-select {
    min-width: 96px;
  }

  .view-data {
    font-size: ${fonts.sizeMd};
  }
`;

const FinalRecurrence = styled(Div)`
  gap: ${spaces.space1};
  height: ${spaces.space4};
  border: 1px solid ${colors.neutral200};
  border-radius: ${spaces.space0};
  padding: 0 ${spaces.space1};
  background-color: ${colors.neutral100};
  @media (max-width: ${breakpoints.mobile}) {
    margin-top: ${spaces.space0};
  }
  svg {
    color: ${colors.neutral300};
  }
  span {
    color: ${colors.neutral500};
  }
`;

const PaymentRecurrence = () => {
  const { setField, values } = useContextHook();
  const { id, isView, recurrence, billingDate, lastRecurrenceDate, frequency, interval } = values;
  const {
    systemData: { frequencyRecurrence }
  } = useSelector(state => state.setup);
  const disabled = !!id || isView;

  const [_interval, setInterval] = useState(interval || recurrence?.interval);
  const [_frequency, setFrequency] = useState(frequency || recurrence?.frequency);
  const [recurrenceCount, setRecurrenceCount] = useState(recurrence?.recurrenceCount);
  const [finalRecurrence, setFinalRecurrence] = useState(dayjs(lastRecurrenceDate || billingDate));

  const setRecurrence = (key, value) => {
    const _recurrence = { ...recurrence };
    _recurrence[key] = value;
    setField('recurrence')(_recurrence);
  };

  const calculateEndDate = () => {
    const start = dayjs(billingDate);
    if (recurrenceCount === 1) {
      setFinalRecurrence(start);
      return;
    }
    const finalDate = start.add((recurrenceCount - 1) * _interval, _frequency, { overflow: 'day' });
    setFinalRecurrence(dayjs(finalDate));
  };

  useEffect(() => {
    calculateEndDate();
  }, [billingDate, _frequency, _interval, recurrenceCount]);

  return (
    <Content>
      <Segment>
        <Label>Repetir a cada</Label>
        {disabled ? (
          <span className="view-data">{_interval}</span>
        ) : (
          <Select
            id="interval"
            value={_interval}
            dataType="intervalValues"
            onChange={val => {
              setInterval(val);
              setRecurrence('interval', val);
            }}
          />
        )}
      </Segment>
      <Segment>
        <Label>Frequência</Label>
        {disabled ? (
          <span className="view-data">{frequencyRecurrence?.find(freq => freq?.value === _frequency)?.label}</span>
        ) : (
          <Select
            name="frequency"
            id="select-frequency"
            disabled={id}
            value={_frequency}
            dataType="frequencyRecurrence"
            onChange={val => {
              setFrequency(val);
              setRecurrence('frequency', val);
            }}
          />
        )}
      </Segment>
      {!id && (
        <Segment $fullWidth>
          <Label>Recorrências</Label>
          <Select
            name="recurrence"
            id="select-recurrence"
            disabled={id}
            value={recurrenceCount}
            dataType="recurrenceCount"
            onChange={val => {
              setRecurrenceCount(val);
              setRecurrence('recurrenceCount', val);
            }}
          />
        </Segment>
      )}
      <Segment>
        <FinalRecurrence>
          <FontAwesomeIcon icon={faInfoCircle} />
          <span id="last-recurrence-info">
            Última recorrência será: {dayjs(id ? lastRecurrenceDate : finalRecurrence)?.format('DD/MM/YYYY')}
          </span>
        </FinalRecurrence>
      </Segment>
    </Content>
  );
};

PaymentRecurrence.propTypes = {};

export default PaymentRecurrence;
