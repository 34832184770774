const GoogleTagManagerPlugin = (dataLayer = window.dataLayer) => {
  const identify = () => {};

  const track = ({ event, eventProperties }) => {
    dataLayer.push({ event, eventProperties });
  };

  const pageview = () => {};

  return { identify, track, pageview };
};

export default GoogleTagManagerPlugin;
