import React from 'react';
import PropTypes from 'prop-types';
import ImageModal from './ImageModal';

const ViewFileListModal = ({ fileList, startIndex = 0, onClose }) => {
  return <ImageModal fileList={fileList} startIndex={startIndex} onClose={onClose} />;
};

ViewFileListModal.propTypes = {
  fileList: PropTypes.instanceOf(Array).isRequired,
  startIndex: PropTypes.number,
  onClose: PropTypes.func
};

export default ViewFileListModal;
