import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tag from './Tag';
import { breakpoints } from '../../styles/style';

const StyledDiv = styled.div`
  display: flex;
  padding-top: ${props => props.$paddingTop}px;

  @media (min-width: ${breakpoints.tablet}) {
    flex-wrap: wrap;
    flex: 1;
    width: 100%;
  }
`;

const FilterTags = ({ appliedFilters, setAppliedFilters, onFilter }) => {
  const clearFilterObj = (key, index) => {
    const newFields = { ...appliedFilters };
    if (index >= 0) {
      newFields[key][index].checked = false;
      onFilter(newFields);
    } else {
      newFields[key] = undefined;
      onFilter(newFields);
    }
    setAppliedFilters(newFields);
  };
  return (
    <StyledDiv $paddingTop={appliedFilters?.length ? 8 : 0} className="hideMobile">
      {Object.entries(appliedFilters || {}).map(([key, values]) => {
        if (values && values.length) {
          return values.map((item, index) =>
            item.checked ? (
              <div key={`${key}-${item.value}`}>
                <Tag
                  tagId={`${key}-${index + 1}`}
                  key={`${key}-${item.value}`}
                  title={item.filterName}
                  value={item.label}
                  onClick={() => clearFilterObj(key, index)}
                />
              </div>
            ) : null
          );
        }
        return Object.keys(values || {}).length ? (
          <div key={`div-${key}-${values.value}`}>
            <Tag
              key={`${key}-${values.value || values.label}`}
              title={values.filterName}
              value={values.label}
              onClick={() => clearFilterObj(key)}
              tagId={`${key}-${values.value || values.label}`}
            />
          </div>
        ) : null;
      })}
    </StyledDiv>
  );
};

FilterTags.propTypes = {
  appliedFilters: PropTypes.instanceOf(Object),
  setAppliedFilters: PropTypes.func,
  onFilter: PropTypes.func
};

export default FilterTags;
