import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import Dropdown from '../Dropdown/Dropdown';
import Button from './Button';
import Form from '../Form/Form';
import useViewport from '../../_Hooks/useViewport';
import SelectDropdown from '../Dropdown/SelectDropdown';
import Select from '../Select/Select';
import { colors } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const AddTableButton = ({
  buttonId,
  handleCreate,
  createParams = {},
  parent,
  children,
  type = 'button',
  model = 'addItems',
  modelOptions,
  text = 'Adicionar',
  showParentReference,
  formProps: { onCancel, onSubmit, mapping, addText, showSelect, addTextStyle, style: formStyle, ...formProps } = {},
  customTrigger = {},
  onCreateStep,
  onItemClick,
  buttonStyle,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const [showElement, setShowElement] = useState(false);
  const [initValues, setInitValues] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [keepOldValues, setKeepOldValues] = useState(false);
  const hasParent = showParentReference ? parent?.name : '';
  const keepForm = useRef(null);

  const handleCreateOrShowForm = values => {
    const _values = { ...values, ...(values.values || {}) };
    delete _values.values;
    setInitValues(_values);
    return !_values?.id && Object.keys(formProps).length ? setFormValues(_values) : handleCreate(values);
  };
  const onClickButton = () => (onCreateStep ? onCreateStep(true) : handleCreate(createParams));
  useEffect(() => {
    if (_isMobile && type === 'button' && customTrigger?.value) {
      onClickButton();
      customTrigger.setFunction(false);
    }
  }, [customTrigger?.value]);

  useEffect(() => {
    if (formValues || keepForm.current) {
      document.getElementById('add-item-name-input').focus();
      document.body.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
          keepForm.current = null;
          onCancel && onCancel();
          setFormValues(null);
        }
      });
    }
  }, [formValues, keepForm.current]);

  const addAction = ({ id }) =>
    handleCreateOrShowForm(id)?.then(() => {
      setShowElement(false);
    });

  let element;

  const formElement = Object.keys(formProps).length ? (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
        <Form
          small
          displayButtons={false}
          id="formBtn"
          data={formValues}
          onSubmit={values => {
            if (document.getElementById('add-item-name-input')) {
              document.getElementById('add-item-name-input').focus();
            }
            setKeepOldValues(false);
            onSubmit && onSubmit(values);
            setFormValues(values.name.length >= 255 ? values : { ...initValues, type: formValues.type });
            keepForm.current = true;
          }}
          forceUpdate={{ current: true }}
          mapping={mapping}
          keepOldValues={keepOldValues}
          cleanForm
          style={{ minHeight: 77, ...formStyle }}
          {...formProps}
        />
        <Button type="primary" text form="formBtn" htmlType="submit" style={buttonStyle} id="add-item-button">
          <FontAwesomeIcon icon={faCheck} />
        </Button>
        <Button
          type="danger"
          text
          style={{ color: colors.primary600, ...buttonStyle }}
          onClick={() => {
            setFormValues(null);
            onCancel && onCancel();
            keepForm.current = null;
          }}
          id="cancel-item-button"
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      {addText && <Paragraph style={addTextStyle}>{addText}</Paragraph>}
      {showSelect && (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <p>Adicionar um</p>
          <Select
            onChange={value => {
              setKeepOldValues(true);
              setFormValues(state => {
                return {
                  ...state,
                  type: value
                };
              });
            }}
            dataType="refurbishItemTypes"
            value={formValues?.type}
            style={{ width: 120, margin: '0 8px' }}
          />
          <p>ao orçamento</p>
        </div>
      )}
    </div>
  ) : null;

  switch (type) {
    case 'dropdown':
      element = (
        <div>
          <Dropdown
            data={createParams}
            mappingKey={model}
            trigger={['click']}
            forceSubmit={handleCreateOrShowForm}
            customTrigger={customTrigger}
            onItemClick={onItemClick}
            {...props}
          >
            <Button id={buttonId} type={hasParent ? 'secondary' : 'primary'} text style={buttonStyle}>
              {children || text} {hasParent || ''}
            </Button>
          </Dropdown>
        </div>
      );

      break;
    case 'select':
      element = !showElement ? (
        <div>
          <Button type="primary" text onClick={() => setShowElement(true)}>
            {text}
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: 300 }}>
          <SelectDropdown
            placeholder="Selecione uma etapa"
            model={model}
            options={modelOptions}
            onSelect={addAction}
            isOpen
            onClose={() => (_isMobile ? setShowElement(false) : f => f)}
            initialFocus={false}
          />
          {!_isMobile && (
            <Button text onClick={() => setShowElement(false)}>
              Fechar
            </Button>
          )}
        </div>
      );

      break;
    default:
      element = (
        <Button type={hasParent ? 'secondary' : 'primary'} text onClick={onClickButton}>
          {children || text} {hasParent || ''}
        </Button>
      );
      break;
  }

  return formValues || keepForm.current ? formElement : element;
};

AddTableButton.propTypes = {
  handleCreate: PropTypes.func,
  createParams: PropTypes.instanceOf(Object),
  parent: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  isDropdown: PropTypes.bool,
  mappingKey: PropTypes.string,
  onItemClick: PropTypes.func,
  buttonStyle: PropTypes.instanceOf(Object)
};

export default AddTableButton;
