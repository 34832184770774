import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

const ModalContainer = styled.div`
  height: 100%;

  .preview-modal-content {
    width: calc(100% - 300px);
    display: flex;
    align-items: center;
    padding: 0 ${spaces.space2};
    background-color: ${colors.neutral100};
    @media (max-width: ${breakpoints.tablet}) {
      width: 100%;
    }
    @media (max-width: ${breakpoints.mobile}) {
      height: 100%;
      flex-direction: column;
      padding: 0 0 0;
    }
  }

  ${props =>
    props.$noSide &&
    css`
      .preview-modal-content {
        width: 100%;
      }
    `}

  .preview-modal-file {
    height: 100%;
    width: 100%;
    padding: 0 ${spaces.space2};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 0;
    }
  }

  .preview-modal-button {
    flex-direction: row;
    position: fixed;
    display: flex;
    bottom: 50px;
    justify-content: space-evenly;
    width: 400px;
    :hover {
      background-color: ${colors.primary300};
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.primary600};
      background-color: ${colors.primary200};
      font-size: ${fonts.sizeLg};
    }
  }
`;

export { ModalContainer };
