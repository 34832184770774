import React from 'react';
import Text from 'antd/lib/typography/Text';

import { store } from '../../config/redux-store';
import { validDate } from '../../helpers/helper';
import { Div, spaces } from '../../../styles/style';
import formatCurrency from '../../helpers/formatCurrency';

const columns = ({ removeValues = false }) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;

  return [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      className: 'width-90',
      render: (val, row) => {
        const { type } = row;
        return <Text>{type === refurbishItemType?.product ? 'Produto' : 'Serviço'}</Text>;
      }
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      className: 'col-grow-1',
      render: (val, row) => {
        const { refurbishItem } = row || {};

        const itemName = row.name || row.item?.name || refurbishItem?.name || refurbishItem?.label || '-';

        return <Div padding={spaces.space0}>{itemName}</Div>;
      }
    },
    {
      title: 'Qtd.',
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'width-90',
      render: val => {
        return formatCurrency(val, { decimalCount: 3 });
      }
    },
    {
      title: 'Un.',
      dataIndex: 'idUnit',
      key: 'idUnit',
      className: 'width-90',
      render: (val, row) => {
        const { units } = row;
        const unitName = units?.name || '-';
        return (
          <Div id="appropriation-level-unit" $fullWidth justify="flex-start">
            {unitName}
          </Div>
        );
      }
    },
    {
      title: 'Necessidade',
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      className: 'width-90',
      render: (val, row) => {
        const { type } = row;
        if (!refurbishItemType?.notParent?.includes(type)) return null;
        return (
          <div
            id="item-purchase-date"
            style={{
              marginLeft: val ? '0' : '60px'
            }}
          >
            {validDate(val, 'DD/MM/YY')}
          </div>
        );
      }
    },
    ...(!removeValues
      ? [
          {
            title: 'Valor unitário',
            dataIndex: 'price',
            key: 'price',
            type: 'number',
            className: 'width-120',
            render: val => {
              return formatCurrency(val, { currencySymbol: 'R$' });
            }
          },
          {
            title: 'Valor total',
            dataIndex: 'total',
            key: 'total',
            type: 'number',
            className: 'width-120',
            render: (val, row) => {
              const { price, quantity } = row;
              const total = formatCurrency(Number(price) * Number(quantity) || 0, { currencySymbol: 'R$' });
              return <Div $fullWidth>{total}</Div>;
            }
          }
        ]
      : [])
  ];
};

export { columns };
