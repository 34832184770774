import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// style
import css from './Tag.module.scss';

const Tag = ({ type = 'withButton', style = '', value, title, onClick, tagId }) => {
  const classes = classNames({
    [css['simple-tag']]: true,
    [css[`simple-tag--${style}`]]: style
  });

  const tagMapping = {
    withButton: value && (
      <div className={css.tag} id={tagId}>
        {title ? `${title}: ` : null} {value}
        <button onMouseDown={onClick} id={`remove-${tagId}`}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    ),
    simple: <div className={classes}>{title}</div>
  };
  return tagMapping[type];
};

Tag.propTypes = {
  style: PropTypes.string,
  tagId: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Tag;
