import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faCircleCheck, faFlag } from '@fortawesome/pro-solid-svg-icons';

import { faDiagramNested, faListUl, faPlus, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import ReactDOMServer from 'react-dom/server';
import { createRoot } from 'react-dom/client';
import { colors, Div, fonts, spaces } from '../styles/style';

import ColorPickerDropDown from '../components/Dropdown/ColorPickerDropDown';
import Button from '../components/Button/Button';
import useGantt from './useGantt';
import GanttLabel from '../components/Label/GanttLabel';
import { Paragraph } from '../components/Text/Text';
import { parseArrayAsObject } from '../lib/helpers/parseArrayAsObject';
import StatusSelector from '../components/StatusSelector/StatusSelector';
import useCRUD from './useCRUD';
import TaskActionDropdown from '../components/Dropdown/TaskActionDropdown';

const useTaskGantt = ({
  columnsToShow,
  isPrintGantt,
  columnsToPrint,
  isGeneral,
  disabled,
  onAddTask = f => f,
  parentKey = 'idRefurbishStep',
  generalTask,
  users = [],
  updateStep,
  onTaskClick = f => f,
  onLoadData = f => f,
  preventShowTask,
  ...props
}) => {
  const { taskStatus, planningGanttColors, refurbishStatus: refurbishStatusEnum } = useSelector(
    state => state.setup.enums
  );
  const { taskPriority, taskStatus: taskStatusArr } = useSelector(state => state.setup.systemData);
  const { user } = useSelector(state => state.authReducer);
  const serializedPrioritiesArray = parseArrayAsObject(taskPriority, true);
  const serializedStatusArray = parseArrayAsObject(taskStatusArr, true);

  const isParent = node => !node?.[parentKey] && !node?.idStatus;
  const readOnly = isPrintGantt || isGeneral || disabled;

  const {
    ganttInstance,
    transformAndFindUsers,
    batchRefresh,
    updateResources,
    getTaskAvatarHtml,
    returnItemLabel,
    editors,
    formatTaskLinksToText,
    ...useGanttProps
  } = useGantt({
    ganttModel: 'task',
    columnsToShow,
    isPrintGantt,
    columnsToPrint,
    disabled,
    isGeneral,
    idResponsibleKey: 'idResponsible',
    startDateKey: 'startDate',
    endDateKey: 'endDate',
    isParent,
    isRootNode: () => false,
    getItemType: node => {
      if (preventShowTask) return 'task';
      if (node?.taskBasedDate) return 'project';
      return 'task';
    },
    isTask: true,
    mappingForExportXlsx: _item => {
      return {
        ..._item,
        originalStatus: _item?.status ? serializedStatusArray[_item.status]?.label : '',
        originalPriority: _item?.priority ? serializedPrioritiesArray[_item.priority]?.label : '',
        originalType: _item?.idTemplateStep || _item?.idRefurbishStep ? 'Tarefa' : 'Etapa'
      };
    },
    readOnly,
    onTaskClick,
    ...props
  });

  const { handleDelete: handleDeleteRefurbishStep } = useCRUD({
    model: 'refurbish-step',
    immediatelyLoadData: false
  });

  const { handleCreate: handleDuplicateTask } = useCRUD({
    model: 'task',
    pathOptions: '/duplicate',
    immediatelyLoadData: false
  });

  const handleChangeParent = ({ id, ...values } = {}) => {
    return updateStep({
      id,
      values,
      refresh: false
    }).then(resp => {
      if (resp.color) {
        const task = ganttInstance.getTask(id);
        const children = ganttInstance.getTaskBy('parent', id);

        task.originalColor = resp.color;
        children.forEach(c => {
          // eslint-disable-next-line no-param-reassign
          c.color = resp.color;
          ganttInstance.refreshTask(c.id);
        });

        task.color = planningGanttColors[resp.color]?.major || resp.color;
        ganttInstance.refreshTask(id);
      }
    });
  };

  window.addTaskGantt = id => {
    window.event.stopPropagation();
    onAddTask(id);
  };

  window.finishTask = id => {
    window.event.stopPropagation();
    const currentTask = ganttInstance.getTask(id);

    currentTask.status =
      currentTask.status !== taskStatus.finished.id ? taskStatus.finished.id : taskStatus.execution.id;

    ganttInstance.updateTask(currentTask.id);
  };

  window.goToRefurbish = (idStatus, id) => {
    window.event.stopPropagation();
    const path = idStatus === refurbishStatusEnum.execution.id ? 'projetos' : 'oportunidades';
    window.open(`/${user.type}/${path}/perfil/${id}/tarefas`, '_blank');
  };

  const columns = [
    {
      name: 'number',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Nº" textAlign="left" />),
      align: 'left',
      min_width: isPrintGantt ? 50 : 70,
      width: isPrintGantt ? 50 : 70,
      template: task => {
        if (generalTask && !task.parent) {
          return '';
        }

        return task?.index;
      }
    },
    {
      name: 'name',
      showColumnKey: true,
      label: returnItemLabel('Etapas/tarefas'),
      tree: true,
      min_width: 410,
      width: 410,
      editor: readOnly ? undefined : { type: 'text', map_to: 'name' },
      onrender: (task, node) => {
        const content = node.querySelector('.gantt_tree_content') || '';
        const container = document.createElement('div');
        container.className = 'custom-text-gantt-container';

        const refurbishLinkComponent = ReactDOMServer.renderToString(
          <Button
            className="go-to-refurbish-button"
            type="outline"
            padding={0}
            width={spaces.space3}
            style={{ height: spaces.space3, flexShrink: 0 }}
          >
            <FontAwesomeIcon
              icon={faExternalLink}
              style={{ cursor: 'pointer' }}
              color={colors.primary600}
              className={`go-to-refurbish-button go-to-refurbish-${task?.originalId}`}
            />
          </Button>
        ).replace('<button', `<button onclick="goToRefurbish(${task?.idStatus}, ${task?.originalId})"`);

        if (generalTask && !task.parent) {
          container.innerHTML = refurbishLinkComponent;
          container.insertBefore(content, container.firstChild);
          node.appendChild(container);
          return node;
        }

        const _isParent = isParent(task);
        const taskActionContainer = document.createElement('div');
        createRoot(taskActionContainer).render(
          <TaskActionDropdown
            task={task}
            generalTask={generalTask}
            onTaskClick={onTaskClick}
            isParent={_isParent}
            ganttInstance={ganttInstance}
            handleDeleteRefurbishStep={handleDeleteRefurbishStep}
            handleDuplicateTask={handleDuplicateTask}
            onLoadData={onLoadData}
            disabled={disabled}
            isGantt
          />
        );

        if (_isParent && !isPrintGantt) {
          const addTaskComponent = ReactDOMServer.renderToString(
            <Div gap={spaces.space1} $minWidth="100px" justify="flex-start">
              {!readOnly ? (
                <Button
                  className="add-task-gantt-button"
                  type="outline"
                  padding={0}
                  width={spaces.space3}
                  style={{ height: spaces.space3, flexShrink: 0 }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ cursor: 'pointer' }}
                    color={colors.primary600}
                    className={`add-task-gantt-button add-task-gantt-${task.id}`}
                  />
                </Button>
              ) : null}
              <Paragraph type="small" weight={fonts.weight500}>
                {task?.totalChildren || 0} tarefa
                {(task?.totalChildren || 0) > 1 || (task?.totalChildren || 0) === 0 ? 's' : ''}
              </Paragraph>
            </Div>
          ).replace('<button', `<button onclick="addTaskGantt(${task.originalId || task.id})" `);

          container.innerHTML += addTaskComponent;

          const colorAndNameContainer = document.createElement('div');
          colorAndNameContainer.style.cssText = `
            display: flex;
            align-items: center;
            gap: ${spaces.space0};
            width: 238px;
          `;

          const colorPicker = document.createElement('div');

          createRoot(colorPicker).render(
            <ColorPickerDropDown
              color={task.originalColor || task.color || colors.neutral300}
              disabled={readOnly || task?.id === 1}
              onSelect={value => handleChangeParent({ id: task.id, color: value })}
            />
          );

          if (task.id > 1) {
            colorAndNameContainer.appendChild(colorPicker);
          }
          colorAndNameContainer.appendChild(content);
          container.insertBefore(colorAndNameContainer, container.firstChild);
          container.insertBefore(taskActionContainer, container.lastChild);
        } else {
          const totalItemsChecklistDone = task?.checklist?.filter(item => item.checked).length;
          const hoverItemComponents = ReactDOMServer.renderToString(
            <Div gap={spaces.space1} padding={`0 ${spaces.space1}`}>
              {task?.$target?.length ? (
                <Div className="show-on-hover-button" gap={spaces.space0}>
                  <FontAwesomeIcon
                    icon={faDiagramNested}
                    style={{ cursor: 'pointer' }}
                    color={colors.neutral500}
                    className={`show-on-hover-button total-links-gantt-task-${task.id}`}
                  />
                  <Paragraph color={colors.neutral500} type="small" weight={fonts.weight500}>
                    {task?.$target?.length}
                  </Paragraph>
                </Div>
              ) : null}
              {task?.checklist?.length ? (
                <Div className="show-on-hover-button" gap={spaces.space0}>
                  <FontAwesomeIcon
                    icon={faListUl}
                    style={{ cursor: 'pointer' }}
                    color={colors.neutral500}
                    className={`show-on-hover-button total-checklist-gantt-task-${task.id}`}
                  />
                  <Paragraph color={colors.neutral500} type="small" weight={fonts.weight500}>
                    {totalItemsChecklistDone}/{task?.checklist?.length}
                  </Paragraph>
                </Div>
              ) : null}
              {!readOnly && (
                <Button
                  className="show-on-hover-button finish-task-gantt-button"
                  type="outline"
                  padding={0}
                  width={spaces.space3}
                  style={{ height: spaces.space3, flexShrink: 0 }}
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ cursor: 'pointer' }}
                    color={task.status === taskStatus.finished.id ? colors.green500 : colors.neutral500}
                    className={`show-on-hover-button finish-task-gantt-${task.id}`}
                  />
                </Button>
              )}
            </Div>
          ).replace('<button', `<button onclick="finishTask(${task.originalId || task.id})" `);

          if (!isPrintGantt) container.innerHTML += hoverItemComponents;
          if (!isPrintGantt) container.appendChild(taskActionContainer);
          container.insertBefore(content, container.firstChild);
        }

        node.appendChild(container);

        return node;
      }
    },
    {
      name: 'duration',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Duração" textAlign="right" />),
      align: 'right',
      min_width: 70,
      width: 70,
      editor: readOnly ? undefined : { type: 'number', map_to: 'duration' },
      template({ duration, unscheduled, originalDuration }) {
        if (unscheduled) return originalDuration || '-';
        if (duration === 0) return duration + 1;
        return duration || '-';
      }
    },
    {
      name: 'start_date',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Início" textAlign="left" />),
      align: 'left',
      min_width: isPrintGantt ? 80 : 115,
      width: isPrintGantt ? 80 : 115,
      editor: readOnly ? undefined : { type: 'customDateEditor', map_to: 'start_date' }
    },
    {
      name: 'end_date',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Fim" textAlign="left" />),
      align: 'left',
      min_width: isPrintGantt ? 80 : 115,
      width: isPrintGantt ? 80 : 115,
      editor: readOnly ? undefined : { type: 'customDateEditor', map_to: 'end_date' }
    },
    {
      name: 'idResponsible',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Responsável" textAlign="left" />),
      align: 'left',
      min_width: 110,
      width: 110,
      editor: readOnly ? undefined : { type: 'select', map_to: 'idResponsible', options: users },
      template(task) {
        return task?.idResponsible || (isGeneral && task?.users?.length) ? getTaskAvatarHtml(task) : '-';
      }
    },
    {
      name: 'status',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Status" textAlign="left" />),
      min_width: 120,
      width: 120,
      align: 'left',
      template(task) {
        if (!task.status) {
          return '';
        }

        const value = serializedStatusArray[task.status];
        return ReactDOMServer.renderToString(
          <Div align="center" $fullHeight>
            <StatusSelector inlineText status={value} />
          </Div>
        );
      }
    },
    {
      name: 'priority',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Prioridade" textAlign="left" />),
      min_width: 120,
      width: 120,
      align: 'left',
      template(task) {
        if (!task.priority) {
          return '';
        }

        const value = serializedPrioritiesArray[task.priority];
        return ReactDOMServer.renderToString(<StatusSelector text={value?.label} icon={faFlag} status={value} />);
      }
    },
    {
      name: 'predecessors',
      showColumnKey: true,
      label: ReactDOMServer.renderToString(<GanttLabel text="Predecessoras" textAlign="left" />),
      min_width: 120,
      width: 120,
      align: 'left',
      editor: readOnly ? undefined : editors.predecessors,
      template(task) {
        if (!task.parent) {
          return '';
        }

        return formatTaskLinksToText(task);
      }
    }
  ];

  ganttInstance.config.columns = columns.flatMap(({ showColumnKey, ...otherProps }) => {
    return !columnsToShow || (isPrintGantt ? columnsToPrint : columnsToShow)?.[showColumnKey] || showColumnKey === true
      ? [otherProps]
      : [];
  });

  return {
    ganttInstance,
    transformAndFindUsers,
    batchRefresh,
    updateResources,
    ...useGanttProps
  };
};

export default useTaskGantt;
