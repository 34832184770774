import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromLine } from '@fortawesome/pro-regular-svg-icons';
import { Col, Row } from 'antd';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import Drawer from './Drawer';
import UseOutsideClick from '../../_Hooks/useOutsideClick';
import Tabs, { TabPane } from '../Tabs/Tabs';

import { colors, Div, spaces } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';
import ConfirmModalAlertInfoBuilder from '../Modal/ConfirmModalAlertInfoBuilder';
import CatalogTab from '../Tabs/CatalogTab';
import MyLibraryTab from '../Tabs/MyLibraryTab';
import BudgetListTab from '../Tabs/BudgetListTab';
import Input from '../Input/Input';
import { Error, Paragraph, Subtitle } from '../Text/Text';
import ExternalBaseTab from '../Tabs/ExternalBaseTab';
import TreeSelectWrapper from '../Table/TreeSelectWrapper';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import BubbleModalButton from '../Button/BubbleModalButton';
import Select from '../Select/Select';
import Label from '../Label/Label';

const CatalogDrawer = ({
  open,
  onClose,
  onSubmit,
  isComposition,
  cardProps,
  newItemTypeRedirect,
  shouldConfirm,
  idReference,
  referKey,
  referModel,
  idParent,
  idParentLevel,
  tabsToOpen = [0, 1, 2],
  subtitle,
  confirmModalType = 'linkItem',
  onlyProducts,
  refurbishesOptions,
  ...tabProps
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const { plans, permissions } = useSelector(state => state.setup);
  const [tabIndex, setTabIndex] = useState(tabsToOpen[0]);
  const [keyword, setKeyword] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [keywordError, setKeywordError] = useState();
  const [idLevel, setIdLevel] = useState(idParent);
  const [_idParentLevel, setIdParentLevel] = useState(idParentLevel);
  const [offset, setOffset] = useState(1);
  const [disableCardButton, setDisableCardButton] = useState(false);
  const [_idReference, setIdReference] = useState(idReference);
  const [loadingContent, setLoadingContent] = useState(false);

  const [pendingExistingItemToLink, setPendingExistingItemToLink] = useState(null);
  const [createItemLibraryModal, setCreateItemLibraryModal] = useState({ open: false });

  const ref = useRef(null);
  const shouldSearchRef = useRef(true);
  const hasPermissionProduct = hasPermission(user, ['products'], plans, permissions);
  const hasPermissionCatalog = hasPermission(user, ['catalog'], plans, permissions);
  const hasExternalBasePermission = hasPermission(
    user,
    ['externalBase', 'opportunityExternalBase'],
    plans,
    permissions
  );

  const alertInfoMap = {
    [refurbishItemType.product]: ['Unidade', 'Dimensões', 'Peso'],
    [refurbishItemType.labor]: [],
    [refurbishItemType.composition]: ['Unidade', 'Classe', 'Itens dentro da composição']
  };

  const handleClose = () => {
    setKeyword('');
    setSearchValue('');
    onClose();
    setTabIndex(0);
  };

  UseOutsideClick(
    ref,
    () => {
      !createItemLibraryModal.open && !pendingExistingItemToLink && handleClose();
    },
    null,
    {
      classes: [
        'preview-modal-direction-button-container',
        'preview-modal-content',
        'ant-btn',
        'ant-select-item',
        'ant-input',
        'ant-prevent',
        'ant-modal-body',
        'ant-picker-cell-inner',
        'ant-picker-cell-in-view',
        'ant-picker-header',
        'ant-tree-select',
        'ant-select-tree-title',
        'ant-select-tree-treenode',
        'fa-xmark',
        'svg-inline--fa',
        'anticon'
      ],
      depth: 3
    }
  );

  const handleScroll = e => {
    const onBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight;

    if (onBottom) {
      setOffset(prev => prev + 1);
    }
  };

  const handleSubmit = (submitData, model) => {
    if (tabIndex === -1) {
      onSubmit(submitData, model, _idReference);
      return;
    }
    const newData = {
      ...submitData,
      create: true,
      ...(tabIndex === 2 ? { idSearch: submitData.id } : { idItem: submitData.id, internalId: submitData.id }),
      idParent: idLevel,
      idParentLevel: _idParentLevel,
      quantity: 1
    };

    setDisableCardButton(true);
    Promise.resolve(onSubmit(newData, model)).then(() => {
      setDisableCardButton(false);
    });
  };

  const handleQueryStringChange = e => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSelect = (data, model) => {
    if (shouldConfirm) {
      setPendingExistingItemToLink(data);
      return;
    }

    handleSubmit(data, model);
  };

  useEffect(() => {
    if (searchValue?.length > 0 && searchValue?.length < 3) {
      setKeywordError('Digite pelo menos 3 caracteres');
      return () => {};
    }

    setKeywordError();
    const timer =
      shouldSearchRef.current &&
      setTimeout(() => {
        setKeyword(searchValue);
      }, 600);
    shouldSearchRef.current = true;
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    setIdLevel(idParent);
  }, [idParent]);

  useEffect(() => {
    setIdParentLevel(idParentLevel);
  }, [idParentLevel]);

  useEffect(() => {
    setOffset(1);
  }, [tabIndex]);

  useEffect(() => {
    if (hasPermissionProduct) setTabIndex(tabsToOpen[0]);
    else if (hasExternalBasePermission) setTabIndex(tabsToOpen[1]);
    else if (hasPermissionCatalog) setTabIndex(tabsToOpen[2]);
  }, []);

  const isSinapi = pendingExistingItemToLink?.source === 'SINAPI';

  const confirmModalPropsMap = {
    linkItem: {
      title: 'Vincular item',
      alertInfo: (
        <ConfirmModalAlertInfoBuilder
          alertText={`Ao vincular esse item, os seguintes campos serão
            substituídos pelas informações do item na ${isSinapi ? 'SINAPI' : 'Biblioteca'}:`}
          afterAlertList={['Nome do item', 'Código', ...(alertInfoMap[pendingExistingItemToLink?.type] || [])]}
        />
      )
    },
    copySinapiItem: {
      title: 'Copiar item',
      alertInfo: 'Ao copiar esse item, todas as composições dentro dele também serão copiadas para sua biblioteca'
    }
  };

  useEffect(() => {
    setLoadingContent(true);
    setTimeout(() => setLoadingContent(false), 1);
  }, [_idReference]);

  const showLevelSelect = referModel && tabIndex !== -1;

  return (
    <Drawer
      ref={ref}
      width={800}
      open={open}
      id="catalogLoadMore"
      onClose={handleClose}
      hideFooter
      title="Procurar item"
      padding="0"
      mask={false}
      closeIcon={<FontAwesomeIcon icon={faArrowRightFromLine} />}
      destroyOnClose
      onScroll={handleScroll}
    >
      <ConfirmModal
        ref={ref}
        open={!!pendingExistingItemToLink}
        onClose={() => setPendingExistingItemToLink(null)}
        alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
        onSubmit={() => handleSubmit(pendingExistingItemToLink)}
        {...(confirmModalPropsMap[confirmModalType] || confirmModalPropsMap.linkItem)}
        preInfoDescription="Deseja confirmar ação?"
      />

      <div ref={ref}>
        <div style={{ padding: spaces.space2 }}>
          <Subtitle>
            {subtitle || 'Busque pelo produto, serviço ou composição que deseja adicionar no nível selecionado'}
          </Subtitle>
          <Row gutter={8} align="bottom">
            <Col flex="auto" style={{ ...(!showLevelSelect && { marginTop: spaces.space2 }) }}>
              <Input
                id="keyword-library"
                name="keyword"
                placeholder="Pesquisar pelo nome/código"
                onChange={handleQueryStringChange}
                value={searchValue}
              />
            </Col>

            {showLevelSelect ? (
              <Col flex="250px">
                <Paragraph type="small">Nível/Subnível</Paragraph>
                <TreeSelectWrapper
                  placeholder="Nível/Subnível"
                  model={referModel}
                  value={idLevel}
                  onChange={setIdLevel}
                  modelOptions={{
                    attributes: ['name', 'id', 'type'],
                    where: {
                      isActive: true,
                      type: 3,
                      idParent: null,
                      [referKey]: _idReference
                    },
                    include: [
                      {
                        model: referModel,
                        as: 'children',
                        attributes: ['name', 'id', 'type'],
                        where: {
                          isActive: true,
                          type: 3
                        },
                        required: false
                      }
                    ],
                    order: [['order']]
                  }}
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col>{keywordError && <Error>{keywordError}</Error>}</Col>
          </Row>
        </div>

        <Tabs
          onTabClick={index => {
            setTabIndex(Number(index));
          }}
          $tabsNavBorderBottom={`1px solid ${colors.neutral100}`}
          fullHeight
        >
          {loadingContent ? (
            <div />
          ) : (
            <>
              {tabsToOpen.includes(-1) ? (
                <TabPane key={-1} tab="Insumos do Orçamento">
                  {refurbishesOptions ? (
                    <Div
                      direction="column"
                      align="flex-start"
                      width="442px"
                      padding={`${spaces.space2} ${spaces.space2} 0px ${spaces.space2}`}
                    >
                      <Label>Projeto/Oportunidade</Label>
                      <Select
                        id="select-refurbish"
                        model="refurbish"
                        modelOptions={{
                          where: { id: refurbishesOptions },
                          order: [['name', 'asc']]
                        }}
                        placeholder="Selecione um projeto"
                        value={_idReference}
                        sendFullObject
                        onChange={val => setIdReference(val)}
                      />
                    </Div>
                  ) : null}
                  <BudgetListTab
                    cardProps={cardProps}
                    isComposition={isComposition}
                    onSelect={data => handleSelect(data, 'refurbishItems')}
                    newItemTypeRedirect={newItemTypeRedirect}
                    keyword={keyword}
                    tabIndex={tabIndex}
                    offset={offset}
                    idRefurbish={_idReference}
                    setOffset={setOffset}
                    onlyProducts={onlyProducts}
                    createItemLibraryModal={createItemLibraryModal}
                    setCreateItemLibraryModal={setCreateItemLibraryModal}
                    {...tabProps}
                  />
                </TabPane>
              ) : null}
              {tabsToOpen.includes(0) && hasPermissionProduct !== false ? (
                <>
                  {hasPermissionProduct ? (
                    <TabPane key={0} tab="Meus itens">
                      <MyLibraryTab
                        cardProps={cardProps}
                        isComposition={isComposition}
                        onSelect={data => handleSelect(data, 'item')}
                        newItemTypeRedirect={newItemTypeRedirect}
                        keyword={keyword}
                        tabIndex={tabIndex}
                        offset={offset}
                        setOffset={setOffset}
                        disableCardButton={disableCardButton}
                        {...tabProps}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      disabled
                      key={0}
                      tab={
                        <BubbleModalButton feature="products">
                          <div>
                            Minha biblioteca <FontAwesomeIcon icon={faLock} />
                          </div>
                        </BubbleModalButton>
                      }
                    />
                  )}
                </>
              ) : null}
              {tabsToOpen.includes(1) && hasExternalBasePermission !== false ? (
                <>
                  {hasExternalBasePermission ? (
                    <TabPane key={1} tab="SINAPI">
                      <ExternalBaseTab
                        cardProps={cardProps}
                        isComposition={isComposition}
                        onSelect={data => handleSelect(data)}
                        keyword={keyword}
                        tabIndex={tabIndex}
                        hasExternalBasePermission={hasExternalBasePermission}
                        offset={offset}
                        setOffset={setOffset}
                        disableCardButton={disableCardButton}
                        {...tabProps}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      disabled
                      key={1}
                      tab={
                        <BubbleModalButton feature="externalBase">
                          <div>
                            SINAPI <FontAwesomeIcon icon={faLock} />
                          </div>
                        </BubbleModalButton>
                      }
                    />
                  )}
                </>
              ) : null}
              {tabsToOpen.includes(2) && hasPermissionCatalog !== false ? (
                <>
                  {hasPermissionCatalog ? (
                    <TabPane key={2} tab="Vobi">
                      <CatalogTab
                        cardProps={cardProps}
                        isComposition={isComposition}
                        onSelect={data => handleSelect(data)}
                        keyword={keyword}
                        tabIndex={tabIndex}
                        offset={offset}
                        setOffset={setOffset}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      disabled
                      key={2}
                      tab={
                        <BubbleModalButton feature="catalog">
                          <div>
                            Vobi <FontAwesomeIcon icon={faLock} />
                          </div>
                        </BubbleModalButton>
                      }
                    />
                  )}
                </>
              ) : null}
            </>
          )}
        </Tabs>
      </div>
    </Drawer>
  );
};

CatalogDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isComposition: PropTypes.bool,
  cardProps: PropTypes.instanceOf(Object),
  newItemTypeRedirect: PropTypes.number,
  shouldConfirm: PropTypes.bool,
  idReference: PropTypes.number,
  referKey: PropTypes.string,
  referModel: PropTypes.string,
  idParent: PropTypes.number,
  idParentLevel: PropTypes.number,
  tabsToOpen: PropTypes.instanceOf(Array),
  subtitle: PropTypes.string,
  confirmModalType: PropTypes.string,
  onlyProducts: PropTypes.bool,
  refurbishesOptions: PropTypes.instanceOf(Array)
};

export default CatalogDrawer;
