import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { store } from '../config/redux-store';
import { colors } from '../../styles/style';

const id = {
  label: '#',
  type: 'text',
  hideWhenMobile: true,
  otherProps: { primary: true, className: 'width-90', color: colors.primary600 },
  renderMobile: true
};
const code = {
  label: 'Código',
  hideWhenMobile: true,
  otherProps: { primary: true, className: 'width-90', color: colors.primary600 },
  renderMobile: true
};
const number = { label: '#', otherProps: { xs: 1, primary: true, small: true } };
const name = { label: 'Nome', otherProps: { ellipsis: true, xs: 3 }, renderMobile: true };
const isActive = { label: 'Status', otherProps: { valPrimary: true } };
const email = { label: 'E-mail', otherProps: { ellipsis: true, xs: 12, md: 3 } };
const phone = { label: 'Telefone', otherProps: { xs: 2, md: 8, sm: 8 } };
const createdAt = { label: 'Criado em', otherProps: { ellipsis: true } };
const updatedAt = { label: 'Ultima atualização', otherProps: { xs: 2 } };
const dueDate = { label: 'Vencimento', otherProps: { xs: 2 } };
const website = { label: 'Website', otherProps: { xs: 2, ellipsis: true } };
const status = { label: 'Status', otherProps: { xs: 2 } };
const companyCustomer = {
  label: 'Cliente',
  nestedKey: ['name'],
  otherProps: { ellipsis: true, valPrimary: true },
  renderMobile: true
};
const refurbish = { label: 'Projeto', nestedKey: ['name'], otherProps: { ellipsis: true, xs: 4 } };
const provider = { label: 'Profissional', nestedKey: ['company', 'name'], otherProps: { ellipsis: true, xs: 3 } };
const company = { label: 'Empresa', nestedKey: ['name'], otherProps: { ellipsis: true, xs: 3 } };
const idCompany = { label: 'Cod. Emp.', otherProps: { ellipsis: true, xs: 1 } };
const type = { label: 'Tipo', nestedKey: ['label'], otherProps: { ellipsis: true, xs: 2 } };
const zipcode = { label: 'CEP' };
const street = { label: 'Endereço', otherProps: { ellipsis: true } };
const addressNumber = { label: 'Número', otherProps: { ellipsis: true } };
const complement = { label: 'Complemento', otherProps: { ellipsis: true } };
const city = { label: 'Cidade', otherProps: { ellipsis: true } };
const state = { label: 'Estado', otherProps: { ellipsis: true } };
const isWpp = {
  label: 'Whatsapp',
  otherProps: { md: 4, sm: 4 },
  format: (value, item) => {
    return item.wppUrl ? (
      <div>
        <a href={item.wppUrl} target="_blank">
          <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: 20 }} />
        </a>
      </div>
    ) : (
      '-'
    );
  }
};
const opportunityStatus = {
  nestedKey: ['name'],
  label: 'Status',
  aliasValue: item => {
    const { setup } = store.getState();
    const { idStatus: _idStatus } = item;
    const { refurbishStatus } = setup.enums;
    let _statusName;
    Object.keys(refurbishStatus).forEach(key => {
      if (refurbishStatus[key].id === _idStatus) _statusName = refurbishStatus[key].name;
    });
    let color;
    if (_idStatus === refurbishStatus.execution.id) {
      color = colors.green300;
    }
    if (_idStatus === refurbishStatus.archived.id) {
      color = colors.neutral400;
    }

    return (
      <span style={{ color }}>{_idStatus === refurbishStatus.execution.id ? 'Em andamento' : _statusName || '-'}</span>
    );
  }
};
const dropDown = {
  renderMobile: true,
  type: 'action',
  otherProps: { className: 'actions' }
};

const observation = {
  name: 'Observações',
  placeholder: 'Observações',
  type: 'textarea',
  xs: 24,
  height: 80
};

export {
  id,
  number,
  name,
  isActive,
  email,
  phone,
  isWpp,
  createdAt,
  updatedAt,
  dueDate,
  website,
  status,
  companyCustomer,
  refurbish,
  provider,
  company,
  idCompany,
  type,
  zipcode,
  street,
  addressNumber,
  complement,
  city,
  state,
  opportunityStatus,
  dropDown,
  observation,
  code
};
