import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import { TabContent } from '../Modal/EditItemModal.styles';
import Filters from '../Filters/Filters';
import CatalogCard from '../Card/CatalogCard';
import { images } from '../../lib/mapping/Tabs/catalogTab';
import { spaces } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';

const CatalogTab = ({ cardProps, isComposition, onSelect, keyword, tabIndex, offset, setOffset }) => {
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const [queryString, setQueryString] = useState();
  const [displayList, setDisplayList] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const { data, loading, handleGet, totalItems } = useCRUD({
    model: 'search',
    immediatelyLoadData: false
  });

  const { didYouMean, filters } = data || {};
  const NoContentInfo = images[!hasSearched ? 'initialState' : 'notFound'];

  const handleFilter = _filters => {
    const { order, Preço: price, Marca: brand = [], Loja: store = [] } = _filters;

    const { min, max } = price || {};
    const _brand = brand.map(({ value }) => value);
    const _store = store.map(({ value }) => value);

    const _min = min ?? 0;
    const _price = [Math.min(_min, max), Math.max(_min, max)];

    const newQueryString = {
      ...queryString,
      order,
      where: { price: _price, brand: _brand, store: _store },
      offset: 1
    };
    setOffset(1);
    setQueryString(newQueryString);
  };

  const handleQueryStringChange = value => {
    setOffset(1);
    setHasSearched(!!value);
    setQueryString({ ...queryString, offset: 1, keyword: value, where: {} });
  };

  useEffect(() => {
    setClearFilter(false);
    if (!queryString) return;

    handleGet({ refetchOptions: queryString, generateLoading: offset <= 1 }).then(resp => {
      if (offset === 1) {
        setDisplayList(resp);
      } else {
        setDisplayList(prev => prev.concat(resp));
      }
    });
  }, [queryString]);

  useEffect(() => {
    const hasItemsToLoad = totalItems > displayList.length && !loading;
    if (offset > 1 && hasItemsToLoad) setQueryString({ ...queryString, offset, where: {} });
  }, [offset]);

  useEffect(() => {
    if (tabIndex === 2) {
      setClearFilter(true);
      setOffset(1);
      setHasSearched(!!keyword);
      setQueryString({ ...queryString, offset: 1, keyword, where: {} });
    }
  }, [keyword, tabIndex]);

  return (
    <TabContent>
      <Filters
        key="search"
        filters={filters}
        onFilter={handleFilter}
        displaySorting={false}
        position="fixed"
        clearFilter={clearFilter}
      />

      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          {!totalItems || !displayList.length ? (
            <NoContentInfo keyword={keyword} suggestion={didYouMean || null} onClick={handleQueryStringChange} />
          ) : (
            <div style={{ paddingTop: spaces.space1 }} id="catalog">
              {displayList &&
                Array.isArray(displayList) &&
                displayList?.map(_data => (
                  <React.Fragment key={_data?.id}>
                    <CatalogCard
                      {...cardProps}
                      renderInComposition={isComposition}
                      id={_data?.id}
                      data={{ ..._data, type: refurbishItemType.product }}
                      onSelect={onSelect}
                      redirectUrl={`/catalogo/produto/${_data.id}`}
                      className="catalogItem"
                    />
                  </React.Fragment>
                ))}
            </div>
          )}
        </>
      )}
    </TabContent>
  );
};

CatalogTab.propTypes = {
  cardProps: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  isComposition: PropTypes.bool,
  keyword: PropTypes.string,
  tabIndex: PropTypes.number,
  offset: PropTypes.number,
  setOffset: PropTypes.func
};

export default CatalogTab;
