import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo, faLineColumns, faRulerTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faChartPieSimple, faBoltLightning, faFileInvoiceDollar, faPaste } from '@fortawesome/pro-duotone-svg-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Space } from 'antd';
import { spaces, colors, fonts } from '../../styles/style';
import Button from './Button';
import Modal from '../Modal/Modal';
import useCRUD from '../../_Hooks/useCRUD';
import { Paragraph } from '../Text/Text';

const version1Options = ['V1', 'v1', '1', null];

const FooterSpan = styled.span`
  background-color: ${colors.neutral100};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 0;
  height: ${spaces.space3};
  font-size: ${fonts.sizeSm};
  border-bottom-left-radius: ${spaces.space0};
  border-bottom-right-radius: ${spaces.space0};

  .link {
    color: ${colors.primary600};
    text-decoration: underline;
    font-weight: ${fonts.weight400};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  .iconBox {
    width: ${spaces.space4};
    height: ${spaces.space4};
    margin-right: ${spaces.space1};
  }
  .svgIcon {
    padding: ${spaces.space1};
    width: ${spaces.space2};
    height: ${spaces.space2};
    background-color: ${colors.primary50};
    border-radius: ${spaces.space0};
  }
`;

const ProjectMigrationV2Button = ({ data, bottomCardStyle, listStyle, initShowModal }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(initShowModal);
  const [modalStep, setModalStep] = useState(false);
  const { id } = useParams();
  const _id = data?.id || id;
  const { handleCreate, loading } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });
  const textIconMapping = [
    {
      text: 'Melhore a organização do seu orçamento com divisões em níveis e subníveis.',
      icon: faChartPieSimple
    },
    {
      text: 'Configure as colunas que estarão disponíveis no seu orçamento.',
      icon: faLineColumns
    },
    {
      text: 'Melhor desempenho e navegação mais rápida.',
      icon: faBoltLightning
    },
    {
      text: 'Melhore o acompanhamento dos pagamentos dos seus clientes para você e todos os seus fornecedores.',
      icon: faFileInvoiceDollar
    },
    {
      text: 'Faça o planejamento da sua obra.',
      icon: faRulerTriangle
    },
    {
      text: 'Acompanhe a execução da sua obra através da nova funcionalidade de medições.',
      icon: faPaste
    }
  ];

  const showUpdateVersionButton = version1Options.includes(data?.version);
  if (!showUpdateVersionButton) return <></>;

  const onSubmit = e => {
    e.stopPropagation();
    if (!modalStep) {
      setModalStep(true);
      return null;
    }

    if (!_id) return null;
    handleCreate({ postPathOptions: `/${_id}/migrate` }).then(() => {
      history.push(`/profissional/projetos/perfil/${_id}/geral`);
      window.location.reload();
    });
    return null;
  };

  const onOpen = e => {
    e.stopPropagation();
    setShowModal(true);
  };

  const onClose = e => {
    e.stopPropagation();

    if (initShowModal) {
      if (modalStep) setModalStep(false);
      else history.goBack();
      return;
    }

    setShowModal(initShowModal);
    setModalStep(false);
  };

  let Element = <></>;

  if (bottomCardStyle) {
    Element = (
      <FooterSpan key={`btn-${_id}`}>
        Atualização disponível.
        <Button id="update-kanban-button" text onClick={onOpen} className="link">
          Saiba mais
        </Button>
      </FooterSpan>
    );
  } else if (listStyle) {
    Element = (
      <Button size="small" type="light" id="update-list-button" onClick={onOpen}>
        <FontAwesomeIcon icon={faArrowsRotate} />
        <span>Atualizar</span>
      </Button>
    );
  } else {
    Element = (
      <Button size="small" type="light" id="update-project-button" onClick={onOpen} key={`btn-${_id}`}>
        <FontAwesomeIcon icon={faCircleInfo} />
        <span>Atualização disponível.</span>
      </Button>
    );
  }

  return (
    <>
      {initShowModal ? null : Element}
      <Modal
        open={showModal}
        title={initShowModal ? 'É necessário atualizar seu projeto' : 'Atualize seu projeto'}
        submitText={modalStep ? 'Atualizar' : 'Próximo'}
        hideCancel
        onSubmit={onSubmit}
        onClose={onClose}
        alignButtonRight
        width={560}
        auxButton={!modalStep}
        loading={loading}
      >
        <Space size={16} direction="vertical">
          {modalStep ? (
            <>
              <Paragraph>
                O seu projeto será atualizado para o novo modelo preservando todas as informações relacionadas à ele.
              </Paragraph>
              <Paragraph type="small">
                Assim você conseguirá utilizar o novo orçamento, menu financeiro em projetos e as novas funcionalidades
                de gestão de obra, sem complicações.
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph>Conheça os benefícios:</Paragraph>
              {textIconMapping.map(({ text, icon }) => {
                return (
                  <React.Fragment key={text}>
                    <StyledContainer>
                      <div className="iconBox">
                        <FontAwesomeIcon className="svgIcon" color={colors.primary400} icon={icon} />
                      </div>
                      <Paragraph type="small">{text}</Paragraph>
                    </StyledContainer>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Space>
      </Modal>
    </>
  );
};

ProjectMigrationV2Button.propTypes = {
  data: PropTypes.instanceOf(Object),
  bottomCardStyle: PropTypes.bool,
  listStyle: PropTypes.bool,
  initShowModal: PropTypes.bool
};

export default ProjectMigrationV2Button;
