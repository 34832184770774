import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';

import { changePasswordMapping, changePasswordSchema } from '../../lib/mapping/Form/changePasswordSchema';
import Form from '../Form/Form';
import Drawer from './Drawer';

const ChangePasswordDrawer = ({ open, onCancel }) => {
  const { user } = useSelector(state => state.authReducer);

  const { handleUpdate, loading } = useCRUD({
    model: 'auth',
    immediatelyLoadData: false
  });

  const handleSubmit = values => {
    handleUpdate({ id: `/${user.id}`, values, refresh: false, displayToast: true }).then(resp => {
      if (!resp?.error) {
        onCancel();
      }
    });
  };

  return (
    <Drawer title="Trocar senha" open={open} formId="passwordForm" onClose={onCancel}>
      <Form
        id="passwordForm"
        loading={loading}
        displayButtons={false}
        onSubmit={handleSubmit}
        mapping={changePasswordMapping}
        schema={changePasswordSchema}
      />
    </Drawer>
  );
};

ChangePasswordDrawer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default ChangePasswordDrawer;
