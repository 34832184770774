import { store } from '../../lib/config/redux-store';

let vobiToken;
const connection = { current: null };
const onMessageCallbacks = [];

const resetOnMessageCallbacks = () => {
  onMessageCallbacks.splice(0);
};

const getUserToken = () => {
  const { authReducer } = store.getState();
  return authReducer?.user?.jwt;
};

const onError = error => {
  // eslint-disable-next-line no-console
  console.log(`WebSocket error: ${error}`);
};
const heartbeat = () => {
  // eslint-disable-next-line no-use-before-define
  const { current: conn } = connection;
  if (!conn || conn.readyState !== conn.OPEN) return;
  conn.send('');
  setTimeout(heartbeat, 540000);
};

const onOpen = () => {
  heartbeat();
};

const onClose = () => {
  setTimeout(() => {
    // eslint-disable-next-line no-use-before-define
    const conn = connection.current;
    if (conn && conn.readyState === conn.OPEN) return;
    // eslint-disable-next-line no-use-before-define
    connect();
  }, 10000);
};

const onMessage = (messageCallback, id) => {
  if (!onMessageCallbacks.find(m => m.id === id)) {
    onMessageCallbacks.push({ id, messageCallback });
  }

  connection.current.onmessage = message => {
    onMessageCallbacks.forEach(({ messageCallback: callback }) => {
      callback(message);
    });
  };
};

const connect = () => {
  const token = getUserToken();
  if (!connection.current || token !== vobiToken) {
    vobiToken = token;
    resetOnMessageCallbacks();

    connection.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}?X-Authorization=${token}`, []);
    connection.current.onclose = onClose;
    connection.current.onerror = onError;
    connection.current.onopen = onOpen;
  }
};

export { connect, onMessage };
