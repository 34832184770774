import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import Input from '../Input/Input';
import { filterForm } from '../../lib/mapping/Form/filterForm';

const SearchInput = ({
  onFilter = f => f,
  appliedFilters = {},
  setAppliedFilters,
  model = 'common',
  textSearchKey = 'name',
  width = 'auto',
  placeholder = 'Pesquisar por nome...',
  minLength = 3,
  minWidth,
  init,
  setInit,
  ...props
}) => {
  const [searchText, setSearchText] = useState(appliedFilters[textSearchKey]?.label);

  useEffect(() => {
    if (!appliedFilters[textSearchKey]) {
      setSearchText();
    }
  }, [appliedFilters]);

  useEffect(() => {
    const _search = searchText?.trim();
    const applyFilters = () => {
      if ((!_search || _search.length < minLength) && !appliedFilters?.[textSearchKey]) return;

      const filterProp = filterForm[model]?.find(f => f.name === textSearchKey);
      const { filterFunc, filterName } = filterProp || {};

      setAppliedFilters(prev => {
        let _filter = { ...prev };

        if (_search?.length >= minLength) {
          _filter = {
            ...prev,
            [textSearchKey]: {
              where: filterFunc ? filterFunc(_search) : { [textSearchKey]: { like: `%${_search}%` } },
              label: _search,
              filterName: filterName || 'Nome'
            }
          };

          onFilter(_filter);
        } else {
          _filter[textSearchKey] = undefined;
          onFilter(_filter);
        }

        return _filter;
      });
    };

    if (init) return setInit(false);
    const timer = setTimeout(applyFilters, 700);
    return () => clearTimeout(timer);
  }, [searchText]);

  return (
    <Input
      id="search-name"
      style={{ minWidth: minWidth || '150px', width }}
      placeholder={placeholder}
      type="text"
      onChange={e => setSearchText(e.target.value)}
      prefix={<FontAwesomeIcon icon={faSearch} />}
      value={searchText}
      {...props}
    />
  );
};

SearchInput.propTypes = {
  onFilter: PropTypes.func,
  appliedFilters: PropTypes.instanceOf(Object),
  setAppliedFilters: PropTypes.func,
  model: PropTypes.string,
  textSearchKey: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  minWidth: PropTypes.string,
  props: PropTypes.instanceOf(Object),
  init: PropTypes.bool,
  setInit: PropTypes.func
};

export default SearchInput;
