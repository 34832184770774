import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Div, spaces } from '../../styles/style';
import { Paragraph, Subtitle } from '../../components/Text/Text';
import ProductConciliationCard from '../../components/Card/ProductConciliationCard';
import formatCurrency from '../../lib/helpers/formatCurrency';
import EditItemModal from '../../components/Modal/EditItemModal';
import CatalogDrawer from '../../components/Drawer/CatalogDrawer';
import useCRUD from '../../_Hooks/useCRUD';
import LoadingModal from '../../components/Modal/LoadingModal';
import { formatDecimalsDinamically } from '../../lib/helpers/helper';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spaces.space2};
  width: 100%;
`;

const ProductsConciliation = ({ products, onProductsChange, idRefurbish }) => {
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const [selectedItem, setSelectedItem] = useState(null);
  const { handleGet: handleLoadItem, loading, setLoading } = useCRUD({ model: '', immediatelyLoadData: false });

  const [showCatalogDrawer, setShowCatalogDrawer] = useState(null);
  const assembleSuggestionData = item => {
    const data = item.item || item.suggestion;
    return data
      ? {
          description: data.name,
          infos: [
            { label: 'Un', value: data.units?.name, minWidth: spaces.space7 },
            {
              label: 'Custo un.',
              value: formatCurrency(data.price, {
                currencySymbol: 'R$'
              }),
              minWidth: spaces.space12
            },
            { label: 'Fornecedor', value: data.supplier?.name, width: '100%' }
          ]
        }
      : null;
  };

  const assembleInvoiceProductData = item => ({
    description: item.name,
    infos: [
      { label: 'Código', value: item.code, minWidth: spaces.space12 },
      { label: 'Un.', value: item.unit?.name, minWidth: spaces.space7 },
      { label: 'Qnt.', value: formatDecimalsDinamically(Number(item.quantity)), minWidth: spaces.space7 },
      {
        label: 'Custo un.',
        value: formatCurrency(item.price, {
          currencySymbol: 'R$'
        }),
        width: '100%'
      }
    ]
  });

  const handleAfterEditItem = data => {
    setLoading(true);
    handleLoadItem({
      refetchPathOptions: `${selectedItem.model}/${data.id}`,
      refetchOptions: {
        attributes: [
          'name',
          'id',
          'idSupplier',
          'idUnit',
          'price',
          'code',
          ...(selectedItem.model === 'refurbishItems' ? ['idParentLevel'] : [])
        ],
        include: [
          { model: 'supplier', as: 'supplier', attributes: ['id', 'name'] },
          { model: 'unit', as: 'units', attributes: ['id', 'name'] },
          ...(selectedItem.model === 'refurbishItems'
            ? [{ model: 'refurbishItems', as: 'parentLevel', attributes: ['id', 'name'] }]
            : [])
        ]
      }
    }).then(resp => {
      setLoading(false);
      const _products = [...products];
      _products[selectedItem.index].item = { ...resp, model: selectedItem.model };
      _products[selectedItem.index].suggestion = null;
      onProductsChange(_products);
      setSelectedItem(null);
    });
  };
  return (
    <>
      <Div padding={`${spaces.space3} ${spaces.space2}`} gap={spaces.space2} direction="column" align="flex-start">
        <Paragraph>
          Vincule os itens da NF-e aos itens cadastrados na Vobi. Itens que não estão cadastrados serão criados na sua
          biblioteca.
        </Paragraph>

        <Grid>
          <Subtitle>Itens da NF-e</Subtitle>
          <Subtitle>Plataforma Vobi</Subtitle>
          {loading && <LoadingModal zIndex={1001} open title="Estamos atualizando seus itens." />}

          {products?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`product-conciliation-${index}`}>
              <ProductConciliationCard
                price={item.price}
                isLinked={!!item.suggestion || !!item.item}
                data={assembleInvoiceProductData(item)}
              />
              <ProductConciliationCard
                onCreateItem={() => {
                  setSelectedItem({
                    index,
                    model: 'item',
                    data: {
                      name: item.name,
                      code: item.code,
                      units: item.unit,
                      idUnit: item.unit?.id,
                      price: Number(item.price || 0)
                    }
                  });
                }}
                onEditItem={() => {
                  const data = item.item || item.suggestion;
                  setSelectedItem({
                    index,
                    model: data.model,
                    data: {
                      id: data.id
                    }
                  });
                }}
                onRemoveItem={() => {
                  const _products = [...products];
                  _products[index].item = null;
                  _products[index].suggestion = null;
                  onProductsChange(_products);
                }}
                onSearchItem={() => {
                  setShowCatalogDrawer({ index });
                }}
                isSuggestion={!!item.suggestion}
                data={assembleSuggestionData(item)}
                showActionButton
              />
            </React.Fragment>
          ))}
        </Grid>
      </Div>
      {selectedItem && (
        <EditItemModal
          id={selectedItem.data.id}
          initialData={!selectedItem.data.id ? selectedItem.data : null}
          type={refurbishItemType.product}
          isLibrary={selectedItem.model === 'item'}
          model={selectedItem.model}
          isChildrenDrawer
          onClose={() => {
            setSelectedItem(null);
          }}
          libraryProps={{ checkedValue: false }}
          afterSubmit={handleAfterEditItem}
          isProductConciliation
        />
      )}
      {showCatalogDrawer && (
        <CatalogDrawer
          open
          onClose={() => setShowCatalogDrawer(null)}
          onSubmit={(input, model) => {
            const _products = [...products];
            _products[showCatalogDrawer.index].item = { ...input, model };
            _products[showCatalogDrawer.index].suggestion = null;
            onProductsChange(_products);
            setShowCatalogDrawer(null);
          }}
          idReference={idRefurbish}
          tabsToOpen={[-1, 0]}
          subtitle="Busque pelo produto que deseja adicionar"
          priceWithBdi
          itemsOnListMap={{}}
          onlyProducts
          disableTypeFilter
          fixedType={refurbishItemType.product}
        />
      )}
    </>
  );
};

ProductsConciliation.propTypes = {
  products: PropTypes.instanceOf(Object),
  onProductsChange: PropTypes.func,
  idRefurbish: PropTypes.number
};

export default ProductsConciliation;
