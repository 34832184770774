import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import Modal from './Modal';

import { Div, spaces } from '../../styles/style';
import { Subtitle } from '../Text/Text';

const ProgressModal = ({ progress, open }) => {
  return open ? (
    <Modal hideCloseBtn closable={false} open hideFooter width={380} title="Processamento em andamento">
      <Div direction="column" gap={spaces.space3} align="left">
        <Subtitle type="secondary">Por favor, aguarde, estamos realizando a operação.</Subtitle>
        <Progress strokeLinecap="butt" percent={progress} />
      </Div>
    </Modal>
  ) : null;
};

ProgressModal.propTypes = {
  progress: PropTypes.number,
  open: PropTypes.bool
};

export default ProgressModal;
