import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { toast } from 'react-toastify';

import ConfirmModal from './ConfirmModal';
import { Paragraph, Subtitle } from '../Text/Text';
import { Div, colors, fonts, radius, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import NewMaskedInput from '../Input/NewMaskedInput';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import CenteredLoader from '../Loader/CenteredLoader';

const AdministrationFeeModal = ({ onClose, idOrders, ...props }) => {
  const [feePercentage, setFeePercentage] = useState(null);
  const [total, setTotal] = useState(null);
  const [totalWithFee, setTotalWithFee] = useState(null);

  const { handleGet, loading } = useCRUD({
    model: 'order',
    immediatelyLoadData: false,
    options: {
      include: [{ model: 'orderItems', attributes: ['price', 'quantity'] }],
      where: {
        id: idOrders
      }
    }
  });

  const calculateTotal = ({ _total = total } = {}) => {
    setTotalWithFee(_total * (feePercentage / 100));
  };

  useEffect(() => {
    if (idOrders) {
      handleGet().then(resp => {
        if (resp?.error) return;

        if (resp?.length !== idOrders?.length || !resp?.length) {
          toast.error('Não foi possível encontrar as ordens de compra.');
          return;
        }

        let _total = 0;
        resp?.forEach(item => {
          _total += Number(item?.performedCost);
        });
        setTotal(_total);
        calculateTotal({ _total });
      });
    }
  }, [idOrders]);

  useEffect(() => {
    calculateTotal();
  }, [feePercentage]);

  const handleSubmit = () => {
    return onClose({
      idOrders,
      feePercentage: feePercentage / 100
    });
  };

  return (
    <ConfirmModal
      open
      center
      title="Cobrar taxa de administração"
      onClose={() => onClose()}
      zIndex={1000}
      modalWidth={470}
      onSubmit={handleSubmit}
      disableButton={!totalWithFee}
      {...props}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <Subtitle type="secondary" weight={fonts.weight600} color={colors.neutral600}>
            Configure a taxa de administração para cobrar seu cliente a partir das ordens de compras/contratações
            selecionadas
          </Subtitle>
          <Space size={16} direction="vertical" style={{ width: '65%', paddingTop: spaces.space4 }}>
            <Div justify="space-between">
              <Paragraph type="small">Total selecionado:</Paragraph>
              <Paragraph type="small" id="total-selected">
                {formatCurrency(total, { currencySymbol: 'R$ ' })}
              </Paragraph>
            </Div>
            <Div justify="space-between">
              <Paragraph type="small">Taxa:</Paragraph>
              <NewMaskedInput
                style={{ borderRadius: radius.radius1, width: '35%', textAlign: 'right' }}
                placeholder="0%"
                type="number"
                size="middle"
                maskOptions={{ suffix: '%', prefix: '', includeThousandsSeparator: false, allowDecimal: true }}
                value={
                  feePercentage
                    ? formatCurrency(feePercentage, {
                        currencySymbol: ''
                      })
                    : null
                }
                onChange={e => setFeePercentage(formatNumber(e?.target?.value || 0))}
                id="administration-fee-input"
              />
            </Div>
            <Div justify="space-between">
              <Paragraph type="small">Valor a cobrar:</Paragraph>
              <Paragraph type="small" id="total-with-fee" color={colors.primary500}>
                {formatCurrency(totalWithFee, { currencySymbol: 'R$ ' })}
              </Paragraph>
            </Div>
          </Space>
        </>
      )}
    </ConfirmModal>
  );
};

AdministrationFeeModal.propTypes = {
  onClose: PropTypes.func,
  idOrders: PropTypes.instanceOf(Array)
};

export default AdministrationFeeModal;
