import React from 'react';
import PropTypes from 'prop-types';

const UnknownFile = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 77">
      <title>file</title>
      <g id="Files" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-19.000000, -15.000000)">
          <g id="file" transform="translate(20.000000, 16.000000)">
            <path
              d="M4.89473684,3.26086957 C3.99573684,3.26086957 3.26315789,3.99130435 3.26315789,4.89130435 L3.26315789,34.2391304 L-7.10542736e-15,34.2391304 L-7.10542736e-15,4.89130435 C-7.10542736e-15,2.19456522 2.19610526,0 4.89473684,0 L40.7894737,0 C41.2218421,0 41.6378947,0.171195652 41.943,0.477717391 L61.5219474,20.0429348 C61.8286842,20.3478261 62,20.7619565 62,21.1956522 L62,70.1086957 C62,72.8054348 59.8038947,75 57.1052632,75 L4.89473684,75 C2.19610526,75 -7.10542736e-15,72.8054348 -7.10542736e-15,70.1086957 L-7.10542736e-15,55.8043478 L3.26315789,55.8043478 L3.26315789,70.1086957 C3.26315789,71.0086957 3.99573684,71.7391304 4.89473684,71.7391304 L57.1052632,71.7391304 C58.0042632,71.7391304 58.7368421,71.0086957 58.7368421,70.1086957 L58.736,22.826 L40.7894737,22.826087 C39.8888421,22.826087 39.1578947,22.0972826 39.1578947,21.1956522 L39.1578947,4.16254918 C39.1578947,3.66456528 39.5580219,3.26086957 40.049567,3.26086957 L4.89473684,3.26086957 Z M56.429,19.565 L42.421,5.566 L42.4210526,19.5652174 L56.429,19.565 Z"
              id="Combined-Shape"
              stroke="#FFFFFF"
              strokeWidth="1.8"
              fill="#6D7278"
              opacity="0.900000036"
            />
            <path
              d="M50.5555556,34 C51.3528889,34 52,34.6556 52,35.4666667 L52,54.5333333 C52,55.3444 51.3528889,56 50.5555556,56 L1.44444444,56 C0.647111111,56 0,55.3444 0,54.5333333 L0,35.4666667 C0,34.6556 0.647111111,34 1.44444444,34 L50.5555556,34 Z"
              id="base"
              stroke="#FFFFFF"
              strokeWidth="1.8"
              fillOpacity="0.1"
              fill="#6D7278"
            />
            <path
              d="M14.336,48 L14.336,47.176 L13.512,47.176 L13.512,48 L14.336,48 Z M16.2727273,48 L16.2727273,45.384 L18.9847273,45.384 L18.9847273,44.832 L16.2727273,44.832 L16.2727273,42.856 L19.1447273,42.856 L19.1447273,42.304 L15.6247273,42.304 L15.6247273,48 L16.2727273,48 Z M20.8574546,48 L20.8574546,42.304 L20.2014546,42.304 L20.2014546,48 L20.8574546,48 Z M25.8501818,48 L25.8501818,47.44 L22.9941818,47.44 L22.9941818,42.304 L22.3381818,42.304 L22.3381818,48 L25.8501818,48 Z M30.3709091,48 L30.3709091,47.456 L27.4189091,47.456 L27.4189091,45.384 L30.2109091,45.384 L30.2109091,44.832 L27.4189091,44.832 L27.4189091,42.848 L30.3709091,42.848 L30.3709091,42.304 L26.7709091,42.304 L26.7709091,48 L30.3709091,48 Z"
              id=".FILE"
              fill="#7C8086"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
UnknownFile.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

UnknownFile.defaultProps = {
  width: 65,
  height: 77
};

export default UnknownFile;
