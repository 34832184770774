import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import Text from 'antd/lib/typography/Text';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { columns as purchaseColumns } from '../../lib/mapping/TableOrList/purchaseColumns';
import { useContextHook } from '../../contexts/GeneralContext';
import AddAppropriation from '../Button/AddAppropriation';
import { Div, colors, fonts, spaces } from '../../styles/style';
import ItemDetailButton from '../Button/ItemDetailButton';
import { HelperLink, Paragraph } from '../Text/Text';
import { CustomBadge } from '../Button/AddAppropriation.styled';
import Card from '../Card/Card';
import { DynamicColumn, InlineDivider } from '../List/MobileCardItemV2.style';

const PurchaseFooter = ({ item, index: _index, childrenColumnName, readOnlyMobile: readOnly }) => {
  const {
    levelsList,
    itemAppropriationMap,
    addAppropriation,
    limitedTable,
    deleteRow,
    editRow,
    paymentView,
    errors,
    parentColumnName,
    isSupplierView
  } = useContextHook();

  const [showChildren, setShowChildren] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const appropCount = item?.[childrenColumnName]?.length || 0;

  useEffect(() => {
    if (appropCount === 0 || limitedTable) setShowChildren(false);
    else if (appropCount > 0 && !firstRender) setShowChildren(true);

    setFirstRender(false);
  }, [appropCount, limitedTable]);

  const DeleteButton = child => (
    <ItemDetailButton
      show={!readOnly}
      onClick={() => {
        deleteRow(child?.id || item?.id, child?.idParent || item?.idParent);
      }}
      width={fonts.sizeLg}
      iconColor={colors.red500}
      icon={faTrashCan}
      iconId={`cancel-${child ? 'child' : 'item'}-button-${_index + 1}`}
    />
  );

  const _columns = purchaseColumns({
    readOnly,
    addAppropriation,
    editRow,
    deleteRow,
    levelsList,
    itemAppropriationMap,
    childrenColumnName,
    paymentView,
    errors,
    parentColumnName,
    limitedTable,
    isSupplierView,
    isMobile: true,
    isMobileChildren: true
  });

  const AddAppropriationMounted = () => (
    <AddAppropriation
      index={_index}
      item={item}
      childrenColumnName={childrenColumnName}
      levelsList={levelsList}
      itemAppropriationMap={itemAppropriationMap}
      addAppropriation={addAppropriation}
      isMobile
      readOnly={readOnly}
      deleteRow={deleteRow}
    />
  );

  return (
    <>
      <Div
        justify={limitedTable ? 'flex-end' : 'space-between'}
        align="center"
        padding={`${spaces.space1} ${spaces.space2}`}
      >
        {limitedTable ? null : (
          <>
            {appropCount === 0 ? (
              <AddAppropriationMounted />
            ) : (
              <Div
                gap={spaces.space0}
                height={spaces.space4}
                role="presentation"
                onClick={() => setShowChildren(!showChildren)}
              >
                <HelperLink color={colors.primary600} weight={fonts.weight500} lineHeight="18px">
                  {showChildren ? 'Fechar apropriações' : 'Ver apropriações'}
                </HelperLink>
                <Text>
                  <CustomBadge>{appropCount}</CustomBadge>
                </Text>
                <FontAwesomeIcon
                  icon={!showChildren ? faChevronDown : faChevronUp}
                  style={{ marginRight: spaces.space0 }}
                  color={colors.neutral500}
                />
              </Div>
            )}
          </>
        )}
        <DeleteButton />
      </Div>
      {showChildren && (
        <>
          {item?.[childrenColumnName]?.map((child, index) => (
            <Card
              borderRadius="0px"
              direction="column"
              justify="space-between"
              align="center"
              borderOnly="top"
              bodyBackgroundColor={colors.neutral75}
            >
              <Div wrap="wrap" gap={spaces.space1} $fullWidth>
                {_columns.map(col => (
                  <DynamicColumn $mobileWidth={col.mobileWidth} $minHeight={col.inlineMobile}>
                    <InlineDivider inline={col.inlineMobile}>
                      <Paragraph type="small">{col.title}</Paragraph>
                      {col.render ? col.render(child[col.dataIndex], child, index) : <div>{child[col.dataIndex]}</div>}
                    </InlineDivider>
                  </DynamicColumn>
                ))}
                <Div width="100%" justify="flex-end">
                  {DeleteButton(child)}
                </Div>
              </Div>
            </Card>
          ))}
          {readOnly ? null : (
            <Div
              borderTop={`1px solid ${colors.neutral100}`}
              padding={spaces.space2}
              $backgroundColor={colors.neutral75}
            >
              <AddAppropriationMounted />
            </Div>
          )}
        </>
      )}
    </>
  );
};

PurchaseFooter.propTypes = {
  item: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  childrenColumnName: PropTypes.string,
  readOnlyMobile: PropTypes.bool
};

export default PurchaseFooter;
