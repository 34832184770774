import * as Yup from 'yup';

const purchaseOrderAddressSchema = () => Yup.object().shape({});

const purchaseOrderAddressMapping = readOnly => ({
  zipcode: {
    name: 'CEP',
    type: readOnly ? 'string' : 'zipCode',
    placeholder: 'Informe o CEP'
  },
  street: {
    name: 'Rua',
    type: readOnly ? 'string' : 'text',
    placeholder: 'Rua'
  },
  number: {
    name: 'Número',
    type: readOnly ? 'string' : 'text',
    placeholder: 'Número'
  },
  complement: {
    name: 'Complemento',
    type: readOnly ? 'string' : 'text',
    placeholder: 'Complemento'
  },
  state: {
    name: 'Estado',
    dataType: 'states',
    type: readOnly ? 'string' : 'select',
    placeholder: 'Estado'
  },
  city: {
    name: 'Cidade',
    type: readOnly ? 'string' : 'text',
    placeholder: 'Cidade'
  }
});

export { purchaseOrderAddressSchema, purchaseOrderAddressMapping };
