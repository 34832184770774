import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, TreeSelect } from 'antd';
import { useSelector } from 'react-redux';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import { getLevelListFromItems, parentMap } from '../../lib/helpers/helper';
import Button from '../Button/Button';

const EditableDiv = styled.div`
  padding: ${props => (props.$showBord ? '4px' : null)};
  border: 1px solid transparent;
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  min-height: 28px;
  display: flex;
  align-items: center;
  word-break: break-word;
  width: 100%;

  > svg {
    display: none;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
    border: ${props => (props.$showBord && !props.disabled ? `1px solid ${colors.neutral500}` : null)};
    border-radius: ${radius.radius1};
    > svg {
      display: block;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 8px;
  }
`;

const StyledTreeSelect = styled(TreeSelect)`
  &.form-component {
    font-size: ${fonts.sizeSm};
    max-height: ${spaces.space4};
    color: ${colors.neutral600};
    border-color: transparent;

    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${colors.neutral100} !important;
        background-color: ${colors.white} !important;
        box-shadow: none !important;
      }
    }

    .ant-select-selector {
      border-color: transparent;

      &:hover {
        background-color: ${colors.neutral75};
        border-color: ${colors.neutral75};
      }
    }
  }
`;

const TreeSelectWrapper = ({
  value,
  placeholder = '-',
  _key,
  disabled,
  onChange,
  onBlur = f => f,
  levelList,
  extraVal,
  opened = false,
  model,
  modelOptions,
  isParentMap,
  parentMapOptions,
  withoutEditableDiv,
  disabledText,
  openCustomCreate,
  id = 'tree-select-level',
  ...props
}) => {
  const [isEdit, setEdit] = useState(false);
  const [_list, setList] = useState(null);
  const { refurbishItemType } = useSelector(state => state.setup.enums) || {};
  const _levelList = isParentMap ? parentMap({ levelList, parentMapOptions }) : levelList;
  const [searchValue, setSearchValue] = useState('');

  const { loading, list } = useCRUD({
    model,
    options: modelOptions,
    immediatelyLoadData: !!model
  });

  const handleClick = e => {
    e.stopPropagation();
    setEdit(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setEdit(opened);
    }, 100);
  }, [opened]);

  useEffect(() => {
    if (list?.length) setList(getLevelListFromItems(list, refurbishItemType.parent));
  }, [list]);

  const open = isEdit && !disabled && !loading;

  const handleCreateOption = () => {
    if (openCustomCreate) return openCustomCreate(searchValue);
    return null;
  };

  return withoutEditableDiv || model || open ? (
    <Tooltip title={disabled ? disabledText : ''} zIndex={1500}>
      <StyledTreeSelect
        open={!withoutEditableDiv ? open : undefined}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        key={_key}
        searchValue={searchValue}
        showSearch
        onSearch={search => setSearchValue(search)}
        style={{ width: '100%' }}
        value={(_list || _levelList) && (value || value === 0) ? value : undefined}
        treeDefaultExpandAll
        onChange={(val = null, label) => {
          setEdit(false);
          onChange(extraVal ? { val, label } : val);
        }}
        treeData={_list || _levelList}
        onClick={!isEdit ? handleClick : undefined}
        notFoundContent={
          openCustomCreate ? (
            <Button type="link" onClick={handleCreateOption}>
              {`+ criar "${searchValue}"`}
            </Button>
          ) : (
            undefined
          )
        }
        onBlur={() => {
          onBlur();
          !withoutEditableDiv && setEdit(false);
        }}
        treeNodeFilterProp="title"
        {...props}
      />
    </Tooltip>
  ) : (
    <EditableDiv role="presentation" onClick={handleClick} $showBord disabled={disabled} id="tree-select-level-value">
      {value || placeholder}
    </EditableDiv>
  );
};

TreeSelectWrapper.propTypes = {
  value: PropTypes.instanceOf(Object),
  _key: PropTypes.string,
  levelList: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  extraVal: PropTypes.bool,
  opened: PropTypes.bool,
  placeholder: PropTypes.string,
  model: PropTypes.string,
  modelOptions: PropTypes.instanceOf(Object),
  isParentMap: PropTypes.bool,
  parentMapOptions: PropTypes.instanceOf(Object),
  withoutEditableDiv: PropTypes.bool,
  disabledText: PropTypes.string,
  openCustomCreate: PropTypes.func,
  id: PropTypes.string
};

export default TreeSelectWrapper;
