import * as Yup from 'yup';
import {
  idReference,
  name,
  date,
  installments,
  todayDateValidate,
  requiredCpf,
  requiredCnpj,
  requiredPhone,
  requiredEmail
} from '../schema';

const paymentSchema = isVobiPay =>
  Yup.object().shape({
    name,
    billingDate: date,
    idRefurbish: idReference,
    ...(!isVobiPay ? { idCompanyCustomer: idReference } : {}),
    installments
  });

const vobiPaySchema = maxValues =>
  Yup.object().shape({
    dueDate: todayDateValidate,
    idCompanyCustomer: idReference,

    customerDoc: Yup.string().when('customerPersonType', {
      is: 'pj',
      then: requiredCnpj,
      otherwise: requiredCpf
    }),
    customerPhone: Yup.string().when('billingChannels', (billingChannels, _schema) =>
      billingChannels?.sms || billingChannels?.whatsapp ? requiredPhone : _schema.nullable()
    ),
    customerEmail: Yup.string().when('billingChannels', (billingChannels, _schema) =>
      billingChannels?.email ? requiredEmail : _schema.nullable()
    ),
    paymentTypes: Yup.array().min(1, 'É obrigatória uma opção de pagamento.'),
    installmentPrice: Yup.number()
      .max(maxValues.installment.value, `O valor máximo por parcela é R$ ${maxValues.installment.label}`)
      .nullable()
  });

export { paymentSchema, vobiPaySchema };
