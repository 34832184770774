import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from './Select';
import useCRUD from '../../_Hooks/useCRUD';
import { saveRefurbish } from '../../store/refurbish/actions/action';

const SelectCustomerRefurbish = () => {
  const history = useHistory();
  const refurbish = useSelector(state => state.refurbishReducer);
  const { user } = useSelector(state => state.authReducer);
  const dispatch = useDispatch();

  const where = user.isCustomerView ? { id: refurbish?.id } : { isShared: true, idCompany: { gte: 0 } };

  const { list } = useCRUD({
    model: 'refurbish',
    options: { where },
    showToast: false,
    immediatelyLoadData: !user?.anonymous
  });

  const dispatchRefurbish = useCallback(
    currentRefurbish => {
      return dispatch(saveRefurbish(currentRefurbish));
    },
    [dispatch]
  );

  const handleSelect = id => {
    dispatchRefurbish(list.find(item => item.id === id));
    history.push('/');
  };

  return !user.isCustomerView ? (
    <Select
      options={list}
      value={refurbish ? refurbish.id : null}
      allowClear={false}
      onChange={handleSelect}
      dropdownMatchSelectWidth={false}
      id="select-refurbish"
    />
  ) : (
    <span style={{ marginRight: '15px' }}> Projeto: {list[0]?.name} </span>
  );
};

export default SelectCustomerRefurbish;
