import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import Button from './Button';

const Action = ({ id = 'action-button', label = 'Ações', ...props }) => {
  return (
    <Button id={id} text {...props}>
      {label}
      <FontAwesomeIcon icon={faCaretDown} />
    </Button>
  );
};

Action.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string
};

export default Action;
