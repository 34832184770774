import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TimePicker, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar, faClock, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import DatePicker from '../Datepicker/Datepicker';
import Input from '../Input/Input';
import Label from '../Label/Label';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { replaceDecimalNumber, toHHMM } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import { useContextHook } from '../../contexts/GeneralContext';
import Select from '../Select/Select';
import ColumnWrapper from '../Table/ColumnWrapper';
import StatusSelector from '../StatusSelector/StatusSelector';
import EditableInput from '../Input/EditableInput';
import TreeSelectWrapper from '../Table/TreeSelectWrapper';
import TooltipIcon from '../Tooltip/TooltipIcon';

const FormInputLabel = styled(Label)`
  color: ${colors.neutral600};
  font-weight: ${fonts.weight500};
  margin-bottom: 0;
  width: ${spaces.space16};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: ${spaces.space1};

  ${props =>
    props.$isMobile &&
    css`
      font-size: ${fonts.sizeSm};
      font-weight: ${fonts.weight500};
    `}

  ${props =>
    props.$titleLabel &&
    css`
      font-size: ${fonts.sizeMd};
      font-weight: ${fonts.weight600};
      color: ${colors.neutral700};
    `}
`;

const StyledFormItemDiv = styled(Div)`
  ${props =>
    props.$isMobile
      ? css`
          flex-direction: column;
          gap: ${props.$titleLabel ? spaces.space1 : '2px'};
          align-items: flex-start;
          padding: ${spaces.space2} 0 11px 0;
          border-bottom: 1px solid ${colors.neutral100};
        `
      : css`
          width: 100%;
        `}
  width: ${props => props.width || '100%'};
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  height: ${spaces.space4};
  border: 1px solid transparent;

  &:hover {
    border-color: ${colors.neutral75};
    background-color: ${colors.neutral75};
  }

  .ant-picker-input {
    flex-direction: row-reverse;
    gap: ${spaces.space0};

    input {
      font-size: ${fonts.sizeSm};
      color: ${colors.neutral600};

      &::placeholder {
        color: ${colors.neutral400};
      }
    }
  }

  .ant-picker-suffix {
    margin-left: 0;
    color: ${colors.neutral400};
  }

  .ant-picker-clear {
    color: ${colors.neutral600};
    background-color: transparent;

    &:hover {
      color: ${colors.neutral700};
    }
  }

  &.ant-picker-focused {
    border-color: ${colors.neutral100};
    box-shadow: none;
    background-color: ${colors.white};
  }

  @media (max-width: ${breakpoints.tablet}) {
    .ant-picker-input {
      input {
        font-size: ${fonts.sizeMd};
      }
    }

    border: none;
  }
`;

const getElement = ({
  _type,
  currentProps = {},
  _property,
  currentValue,
  setField,
  handleChange = f => f,
  handleBlur = f => f,
  handleChangeSelect = f => f,
  isOpen,
  allValues,
  refetchOptions
}) => {
  const {
    type,
    value,
    onChange,
    tooltip,
    placeholder,
    readOnly,
    icon,
    iconColor,
    handleFunctions,
    property,
    unit,
    decimalCount,
    clearable,
    externalOnChange = f => f,
    minDate,
    maxDate,
    model,
    modelOptions,
    dataType,
    aliasOptions,
    Component,
    formatter = f => f,
    formId,
    splitCellWith,
    valueField,
    displayField,
    dateFormat = 'D MMM YYYY',
    CustomLabel,
    changeOptions,
    extraPropsOnOptions,
    isMobile,
    multiple,
    hasChildren,
    sendFullObject,
    ...props
  } = currentProps;

  let element;
  let _value;
  let _iconColor;

  switch (_type) {
    case 'timeHours':
      element = (
        <StyledTimePicker
          id={_property}
          disabled={readOnly}
          defaultOpenValue={moment(formatter(currentValue) || '00:00', 'HH:mm')}
          clearIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          suffixIcon={<FontAwesomeIcon icon={faClock} size="sm" />}
          onChange={hour => {
            setField(_property)(hour?.format('HH:mm'));
          }}
          onSelect={hour => {
            setField(_property)(hour?.format('HH:mm'));
          }}
          format="HH:mm"
          value={currentValue ? moment(currentValue, 'HH:mm') : null}
          placeholder="Selecionar hora"
        />
      );

      if (typeof currentValue === 'number') {
        _value = toHHMM(currentValue);
      } else {
        _value = readOnly ? '-' : placeholder || '';
        currentValue && (_value = currentValue);
      }
      return { element, value: _value };

    case 'statusDropdown':
      // eslint-disable-next-line no-case-declarations
      const { [dataType]: systemData = [] } = useSelector(state => state.setup.systemData);

      // eslint-disable-next-line no-case-declarations
      const currentValueObject =
        typeof currentValue === 'object' ? currentValue : systemData.find(item => item.value === currentValue);

      element = (
        <ColumnWrapper
          value={currentValueObject}
          _key={valueField}
          onSelect={selectedId => {
            handleChangeSelect(_property, selectedId, false, false);
          }}
          loadList={systemData}
          cleanValue={null}
          canCreate={false}
          showClean={false}
          className="form-component"
        >
          {(() => {
            if (!currentValue) return '-';
            if (icon) {
              return (
                <StatusSelector
                  className="form-component"
                  text={currentValueObject?.label}
                  icon={icon}
                  status={currentValueObject}
                />
              );
            }
            return <StatusSelector className="form-component" inlineText status={currentValueObject} />;
          })()}
        </ColumnWrapper>
      );

      _value = currentValueObject || placeholder || '-';

      return {
        element,
        value: _value
      };

    case 'select':
      element = (
        <Select
          name={_property}
          id={_property}
          model={model}
          disabled={readOnly}
          sendFullObject={sendFullObject}
          modelOptions={modelOptions}
          extraPropsOnOptions={extraPropsOnOptions}
          placeholder={placeholder || 'Selecione...'}
          value={valueField ? currentValue?.[valueField] || '' : currentValue || ''}
          onChange={(newValue, fullObject) => {
            handleChangeSelect(_property, sendFullObject ? fullObject : newValue);
            if (changeOptions) {
              changeOptions.forEach(option => {
                setField(option)(null);
              });
            }
          }}
          onBlur={_ => {
            handleBlur(_property)(_);
          }}
          className="form-component"
          allowClear={clearable}
          dataType={dataType}
          icon={icon ? <FontAwesomeIcon icon={icon} color={valueField ? currentValue?.color : iconColor} /> : null}
          aliasOptions={aliasOptions}
          clearIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          refetchOptions={refetchOptions}
        />
      );

      return { element, value: _value, iconColor: _iconColor };

    case 'date':
      element = (
        <DatePicker
          suffixIcon={<FontAwesomeIcon icon={faCalendar} size="sm" />}
          className="form-component"
          format={dateFormat}
          style={{ flexGrow: 1, width: '100%' }}
          clearIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          value={currentValue ? dayjs(currentValue) : null}
          onChange={date => {
            setField(_property)(date);
            // handleOpen(false, _property);
            externalOnChange(date);
          }}
          disabled={readOnly}
          minDate={minDate}
          maxDate={maxDate}
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      if (allValues?.[_property] || currentValue) _value = dayjs(currentValue).format(dateFormat);
      return { element, value: _value };

    case 'treeSelect':
      element = (
        <TreeSelectWrapper
          placeholder={placeholder || 'Selecione...'}
          value={currentValue || ''}
          onChange={v => handleChangeSelect(_property, v, multiple, hasChildren)}
          onBlur={handleBlur(_property)}
          model={model}
          className="form-component"
          modelOptions={modelOptions}
          allowClear={clearable}
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      return { element, value: currentValue || '' };

    case 'number':
      element = (
        <EditableInput
          value={
            currentValue
              ? formatCurrency(currentValue, {
                  decimalCount:
                    decimalCount !== undefined && decimalCount !== null
                      ? decimalCount
                      : replaceDecimalNumber(currentValue)
                })
              : null
          }
          onChange={newValue => {
            setField(_property)(newValue);
            externalOnChange(newValue || 0);
          }}
          className="form-component"
          type="number"
          placeholder={placeholder}
          readOnly={readOnly}
          suffix={unit}
          allowClear
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '-';
      if (currentValue !== null && currentValue !== undefined)
        _value = `${formatCurrency(currentValue, {
          decimalCount:
            decimalCount !== undefined && decimalCount !== null ? decimalCount : replaceDecimalNumber(currentValue)
        })} ${unit || ''}`;
      return { element, value: _value };

    case 'custom':
      element = (
        <Component
          value={currentValue}
          readOnly={readOnly}
          id={_property}
          formId={formId}
          property={_property}
          open={isOpen}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      currentValue && (_value = formatter(currentValue));
      return { element, value: _value };

    case 'textarea':
      element = (
        <Input
          value={currentValue}
          onChange={handleChange(_property)}
          onBlur={handleBlur(_property)}
          className="form-component"
          type="textarea"
          placeholder={placeholder}
          readOnly={readOnly}
          minRows={isMobile ? 1 : 3}
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      currentValue && (_value = currentValue);
      return { element, value: _value };

    case 'currency':
      element = (
        <EditableInput
          value={currentValue}
          onChange={newValue => {
            setField(_property)(newValue);
          }}
          hideCurrencySymbol
          type="currency"
          className="form-component"
          size="small"
          placeholder={placeholder}
          formReadOnly={readOnly}
          initEdit
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      return { element, value: currentValue };

    default:
      element = (
        <EditableInput
          value={currentValue}
          onChange={newValue => {
            setField(_property)(newValue);
          }}
          initEdit
          className="form-component"
          size="small"
          placeholder={placeholder}
          formReadOnly={readOnly}
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      currentValue && (_value = currentValue);
      return { element, value: _value };
  }
};

const FormItemCreatorV2 = () => {
  const {
    type,
    label,
    value,
    tooltip,
    handleFunctions,
    property,
    allValues,
    parent,
    parentValues,
    cantEditTooltip,
    titleLabel,
    nameTooltip,
    ...props
  } = useContextHook();

  const [_parent] = useState(parent);
  const [refetchOptions, setRefetchOptions] = useState();

  useEffect(() => {
    if (_parent) {
      if (parentValues[_parent?.name]) {
        if (type === 'select')
          setRefetchOptions(_parent?.makeRefetchOptions({ field: _parent?.name, value: parentValues[_parent?.name] }));
      }
    }
  }, [parentValues, _parent]);

  const { setField, handleChange, handleBlur, handleChangeSelect } = handleFunctions;

  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  let element;
  let _property;
  if (Array.isArray(type)) {
    const aggregatedElements = type.reduce(
      (acc, curr) => {
        const currentProperty = props?.[`${curr}Props`]?.property;

        const { element: _element, value: __value } = getElement({
          _type: curr,
          currentProps: { ...props, ...props?.[`${curr}Props`] },
          _property: currentProperty,
          currentValue: allValues?.[currentProperty],
          setField,
          handleChange,
          handleBlur,
          handleChangeSelect,
          allValues
        });
        acc.element.push(_element);
        acc.value.push(__value);
        acc.property.push(currentProperty);
        return acc;
      },
      { element: [], value: [], property: [] }
    );
    element = aggregatedElements.element;
    // _value = aggregatedElements.value;
    _property = aggregatedElements.property;
  } else {
    const { element: _element } = getElement({
      _type: type,
      currentProps: props,
      _property: property,
      currentValue: value,
      setField,
      handleChange,
      handleBlur,
      handleChangeSelect,
      refetchOptions,
      isMobile
    });
    element = _element;
    _property = property;
  }

  const ref = useRef(null);

  const wrapWithTooltip = el => {
    return tooltip || cantEditTooltip ? (
      <Tooltip placement="bottomLeft" title={cantEditTooltip || value}>
        {el}
      </Tooltip>
    ) : (
      el
    );
  };

  return (
    <StyledFormItemDiv $isMobile={isMobile} $titleLabel={titleLabel} width={props.width} ref={ref}>
      {label && (
        <FormInputLabel $isMobile={isMobile} $titleLabel={titleLabel}>
          {label}
          {!!nameTooltip && (
            <TooltipIcon
              tooltipProps={{ placement: 'right' }}
              icon={faInfoCircle}
              text={nameTooltip}
              iconColor={colors.primary500}
            />
          )}
        </FormInputLabel>
      )}
      {Array.isArray(element) ? (
        <Div width="100%" gap={spaces.space0}>
          {_property.map((e, index) => wrapWithTooltip(element[index]))}
        </Div>
      ) : (
        wrapWithTooltip(element)
      )}
    </StyledFormItemDiv>
  );
};

FormItemCreatorV2.propTypes = {
  width: PropTypes.string,
  handleFunctions: PropTypes.shape({
    setField: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChangeSelect: PropTypes.func.isRequired
  }).isRequired,
  property: PropTypes.string.isRequired
};

export default FormItemCreatorV2;
