import crypto from 'crypto-js';

const request = ({ ciphertext, hashToken }) => {
  try {
    const response = crypto.AES.decrypt(ciphertext, hashToken);
    return JSON.parse(response.toString(crypto.enc.Utf8))?.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    return console.error('Error on encrypt vault', e);
  }
};

const decrypt = ({ data, user }) => {
  if (!data?.ciphertext) return data;

  const { uuid } = user || {};
  return request({ ciphertext: data?.ciphertext, hashToken: uuid });
};

export { decrypt };
