import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Input from '../Input/Input';
import { parseSearchableOptions, removeAccents } from '../../lib/helpers/helper';

// components
import Align from '../Align/Align';
import CenteredLoader from '../Loader/CenteredLoader';
import useCRUD from '../../_Hooks/useCRUD';

// style
import css from './FilterDefault.module.scss';
import Button from '../Button/Button';
import { radius, colors, spaces } from '../../styles/style';

const StyledCheckbox = styled(Input)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: ${spaces.space1};
  border-radius: ${radius.radius1};
`;

const FilterDefault = ({ onClick, options, name, closeModal, appliedFilters, extra = () => [], onlyOne }) => {
  const offset = useRef(1);
  const stopLoad = useRef(false);
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState({});
  const appliedFiltersIds = appliedFilters?.[name]?.reduce(
    (obj, item) => ({ ...obj, [item.id || item.value]: item.checked }),
    {}
  );
  const hasFiltered = appliedFilters?.[name]?.length > 0;

  const { handleGet, list, loading, totalItems } = useCRUD({
    model: options?.model,
    immediatelyLoadData: false
  });

  const handleChange = e => {
    const { value } = e.target;
    offset.current = 1;
    setSearchText(value);
  };

  const handleChangeCB = (e, id) => {
    setData(prev => {
      if (onlyOne) {
        const updatedData = prev.map(item => {
          const isChecked = (item.id || item.value) === id;
          setSelectedItems(prevSelected => ({ ...prevSelected, [item.id || item.value]: isChecked }));
          return { ...item, checked: isChecked };
        });
        return updatedData;
      }

      const updatedData = prev.map(item => {
        if (item.id === id || item.value === id) {
          const newChecked = !item.checked;
          setSelectedItems(prevSelected => ({ ...prevSelected, [id]: newChecked }));
          return { ...item, checked: newChecked };
        }
        return item;
      });

      setSelectAll(updatedData.every(item => item.checked));
      return updatedData;
    });
  };

  const handleClick = () => {
    closeModal && closeModal();
    return onClick(
      data?.filter(p => p.checked),
      name
    );
  };

  const loadMore = (limitGet = true) => {
    const { modelOptions = { where: { isActive: true } } } = options;
    const { where } = modelOptions;
    const newOptions = {
      ...modelOptions,
      where: {
        ...where,
        ...(searchText && {
          ulike: { [`${options?.modelName || options?.model}.name`]: `%${removeAccents(searchText)}%` }
        })
      },
      ...(limitGet && !hasFiltered && { offset: offset.current, limit: 10 })
    };
    handleGet({ refetchOptions: { ...newOptions } });
  };

  const handleSelectAll = _selectAll => {
    setSelectAll(_selectAll);
    setData(prev => {
      const updatedData = prev.map(el => {
        setSelectedItems(prevSelected => ({ ...prevSelected, [el.id || el.value]: _selectAll }));
        return { ...el, checked: _selectAll };
      });
      return updatedData;
    });

    if (_selectAll && totalItems > data?.length) loadMore(false);
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight;
    if (bottom && totalItems > data?.length && !stopLoad.current) {
      offset.current += 1;
      loadMore();
    }
  };

  useEffect(() => {
    stopLoad.current = true;

    if (Array.isArray(options)) {
      const _data = searchText
        ? data?.filter(l => removeAccents(l?.name || l?.label)?.includes(removeAccents(searchText)))
        : parseSearchableOptions(options);

      setData(_data);

      return () => {};
    }

    const timer = setTimeout(() => {
      offset.current = 1;
      setSelectAll(false);
      loadMore();
    }, 400);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    if (Array.isArray(options)) {
      setData(
        parseSearchableOptions(options).map(item => {
          const dataChecked = data.find(d => d.value === item.value);
          return {
            ...item,
            checked: !!appliedFiltersIds?.[item.value] || dataChecked?.checked || selectedItems[item.id || item.value]
          };
        })
      );
    } else {
      const parsedList = parseSearchableOptions(list, options.customLabel).map(item => {
        const dataChecked = data.find(d => d.value === item.value);
        return {
          ...item,
          checked: !!appliedFiltersIds?.[item.value] || dataChecked?.checked || selectedItems[item.id || item.value]
        };
      });

      setData(prev => {
        const mergedData = offset.current > 1 ? [...prev, ...parsedList] : parsedList;
        const uniqueData = mergedData.filter(
          (item, index, self) => index === self.findIndex(t => t.value === item.value)
        );

        const updatedUniqueData = uniqueData.map(item => ({
          ...item,
          checked: selectedItems[item.id || item.value] ?? item.checked
        }));

        if (selectAll) return updatedUniqueData.map(item => ({ ...item, checked: true }));
        return updatedUniqueData;
      });

      stopLoad.current = false;
    }
  }, [list]);

  return (
    <div onScroll={e => e.stopPropagation()}>
      <Input
        id="search-filter"
        onChange={handleChange}
        icon={<FontAwesomeIcon icon={faSearch} />}
        margin={`0 0 ${onlyOne && spaces.space1} 0`}
      />
      {!onlyOne && (
        <Button id="select-unselect-all" type="primary" text onClick={() => handleSelectAll(!selectAll)}>
          {selectAll ? 'Limpar filtros selecionados' : 'Selecionar todos os filtros'}
        </Button>
      )}
      <div onScroll={handleScroll} className={css['c-filter-default']}>
        <div style={{ minHeight: 190 }} className="ant-prevent">
          {data?.map(item =>
            !item?.hideFilter ? (
              <div className={css['c-filter-default__checkbox']} key={`${item.id}_${item.value}`}>
                <StyledCheckbox
                  id={`checkbox-${item?.label?.toLowerCase()?.replace(/\s+/g, '')}`}
                  type="checkbox"
                  onChange={e => {
                    e.stopPropagation();
                    handleChangeCB(e, item.id || item.value);
                  }}
                  checked={item.checked}
                />
                <span
                  id={`label${item?.label?.toLowerCase()?.replace(/\s+/g, '')}`}
                  style={{ color: item.color ? colors[item.color] : null }}
                  className="ant-prevent"
                >
                  {item.label}
                </span>
                {extra({ item })}
              </div>
            ) : null
          )}
        </div>
        {loading && (
          <div>
            <CenteredLoader />
          </div>
        )}
      </div>
      <Align align="right">
        <Button id="apply-filter-button" size={1} type="default" container="outlined" onClick={handleClick} fullWidth>
          Aplicar filtro
        </Button>
      </Align>
    </div>
  );
};

FilterDefault.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  name: PropTypes.string,
  closeModal: PropTypes.func,
  appliedFilters: PropTypes.instanceOf(Object),
  extra: PropTypes.func,
  onlyOne: PropTypes.bool
};

export default FilterDefault;
