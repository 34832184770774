import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import styled, { css } from 'styled-components';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';
import 'antd/lib/date-picker/style/index';
import { TimePicker } from 'antd';
import * as dayjs from 'dayjs';
import toObject from 'dayjs/plugin/toObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';
import './DatePicker.scss';

dayjs.extend(toObject);

const Picker = generateRangePicker(dayjsGenerateConfig);

const StyledPicker = styled(Picker)`
  z-index: 1000;
  &.hideTxtPicker {
    overflow: hidden;
    display: flex;
    width: 0;
    height: 0;
    border: none;
  }
  &.ant-picker {
    font-family: ${fonts.family};
    color: ${colors.neutral700};

    input {
      color: ${colors.neutral700};
    }

    &:hover {
      border-color: ${colors.neutral500};
    }
  }

  &.ant-picker-small {
    input {
      font-size: ${fonts.sizeSm};
    }
  }

  &.ant-picker-focused {
    border-color: ${colors.neutral700};
    box-shadow: none;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  .ant-picker-panel-container
    .ant-picker-panel
    .ant-picker-time-panel
    .ant-picker-content
    .ant-picker-time-panel-column {
    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      &::-webkit-scrollbar {
        display: block;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        -webkit-border-radius: ${radius.radius1};
        border-radius: ${radius.radius1};
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: ${radius.radius1};
        border-radius: ${radius.radius1};
        background: ${colors.primary600};
      }
    }
  }
`;

const StyledPanel = styled.div`
  font-family: ${fonts.family};
  border-radius: ${radius.radius1};
  font-size: 12px;
  color: ${colors.neutral700};

  .ant-picker-header {
    border-bottom: none;
    span {
      color: ${colors.primary600};
    }
  }

  .ant-picker-content {
    thead {
      border-bottom: 1px solid ${colors.neutral100};
      tr > th {
        color: ${colors.neutral400};
      }
    }

    .ant-picker-cell {
      color: ${colors.neutral400};

      &.ant-picker-cell-in-view {
        color: ${colors.neutral700};
      }
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${fonts.sizeMd};
  opacity: 0.5;
  color: ${colors.neutral600};
  &:hover {
    opacity: 1;
  }
`;

const StyledBlockCalendar = styled.div`
  z-index: 1001;
  width: 560px;
  height: 268px;
  position: absolute;
  background-color: rgba(240, 243, 250, 0.9);
  top: 0;
  margin-top: 6px;
  margin-left: -12px;
  border-radius: ${radius.radius1};
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpan = styled.span`
  color: ${props => (props.$isDelayed ? colors.red500 : null)};
  display: flex;
  @media (max-width: ${breakpoints.mobile}) {
    gap: ${spaces.space2};
    font-size: ${fonts.sizeSm};
  }
`;

const CalendarIcon = styled.span`
  color: ${props => (props.$isDelayed ? colors.red500 : null)};
  ${props =>
    props.planning &&
    css`
      margin-right: ${spaces.space6};
      width: ${spaces.space9};
      span {
        margin-right: ${spaces.space3};
        margin-left: ${spaces.space2};
      }
    `}
`;

export { StyledPicker, StyledTimePicker, StyledPanel, StyledIcon, StyledBlockCalendar, StyledSpan, CalendarIcon };
