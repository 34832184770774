import styled, { css } from 'styled-components';
import { colors, spaces, fonts, radius } from '../../styles/style';

const Gallery = styled.div`
  margin-top: ${spaces.space2};
  display: flex;
  flex-direction: row;

  //todos os spans (container de img e svg)
  span {
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    ${props =>
      !props.disableFilterOnImageHover &&
      css`
        &:hover {
          img {
            filter: brightness(0.4);
          }
        }
      `}

    svg {
      cursor: pointer;
      display: none;
      position: absolute;

      background-color: ${colors.red500};
      color: ${colors.white};
      border-radius: ${radius.radius1};
    }

    img {
      object-fit: cover;
    }

    &:hover {
      svg {
        display: block;
      }
    }
  }

  //só o primeiro span (primeira imagem)
  > span {
    height: 180px;
    img {
      height: 180px;
      width: 180px;
      border-radius: ${radius.radius1};
      margin-right: ${spaces.space1};
    }
    svg {
      margin-right: ${spaces.space0};
      height: 20px;
      width: 20px;
      top: 0;
      right: 4px;
      padding: ${spaces.space1};
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 180px;
`;

const Grid = styled.div`
  margin-bottom: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${spaces.space1};

  img {
    height: 40px;
    width: 40px;
    border-radius: ${radius.radius1};
  }

  svg {
    top: 0;
    right: 0;
    padding: ${spaces.space0};
  }
`;

const EmptyText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  p {
    font-size: ${fonts.sizeSm};
    align-self: center;
  }
`;

const EmptyImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.neutral100};
  min-height: 180px;
  min-width: 180px;
  border-radius: ${radius.radius1};
  margin-right: ${spaces.space1};

  svg {
    color: ${colors.neutral300};
  }
`;

const AddImageButton = styled.span`
  margin: 0;
  margin-top: auto;
  margin-right: auto;
  padding: 0;

  span {
    padding: 0;
  }
`;

export { Gallery, AddImageButton, RightContent, EmptyImage, EmptyText, Grid };
