import Resizer from 'react-image-file-resizer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { uploadMultipart } from '../../lib/helpers/uploadMultipart';
import useCRUD from '../../_Hooks/useCRUD';

const THUMBNAIL_MAX_WIDTH = 300;
const THUMBNAIL_MAX_HEIGHT = 300;
const IMAGE_MAX_WIDTH = 9999;
const IMAGE_MAX_HEIGHT = 9999;
const IMAGE_COMPRESSION_QUALITY = 80;
const IMAGE_COMPRESSION_FORMAT = 'JPEG';

const useFileUpload = ({ limitSize } = {}) => {
  const { id } = useParams();
  const { user } = useSelector(state => state.authReducer);
  const preventUpload = !!user?.anonymous;

  const { handleCreate: uploadFile } = useCRUD({
    model: 'media',
    pathOptions: `/start${!user ? '/anonymous' : ''}`,
    immediatelyLoadData: false
  });

  const createThumbnail = file =>
    new Promise(resolve => {
      if (file.type.indexOf('image') < 0 || file.type.indexOf('tif') >= 0) return resolve(null);
      return Resizer.imageFileResizer(
        file,
        THUMBNAIL_MAX_WIDTH,
        THUMBNAIL_MAX_HEIGHT,
        IMAGE_COMPRESSION_FORMAT,
        IMAGE_COMPRESSION_QUALITY,
        0,
        uri => resolve(uri),
        'file'
      );
    });

  const compressImage = file =>
    new Promise(resolve => {
      if (file.type.indexOf('image') < 0 || file.type.indexOf('tif') >= 0) return resolve(file);
      return Resizer.imageFileResizer(
        file,
        IMAGE_MAX_WIDTH,
        IMAGE_MAX_HEIGHT,
        IMAGE_COMPRESSION_FORMAT,
        IMAGE_COMPRESSION_QUALITY,
        0,
        uri => resolve(uri),
        'file'
      );
    });

  const handleUpload = async ({ fileObject, metadata, onSuccess, onError }) => {
    if (!fileObject) return Promise.reject();
    if (preventUpload) return Promise.resolve();

    const _metadata = {
      lastModified: metadata?.lastModified || fileObject.lastModified,
      lastModifiedDate: metadata?.lastModifiedDate || fileObject.lastModifiedDate,
      name: metadata?.name || fileObject.name,
      size: metadata?.size || fileObject.size,
      type: metadata?.type || fileObject.type
    };

    return uploadFile({ values: { file: _metadata, limitSize, ...(!user && { identify: id }) }, refresh: false })
      .then(data => {
        const { signUrls, uploadId } = data;
        return uploadMultipart(fileObject, signUrls).then(parts => {
          return uploadFile({
            values: { file: _metadata, uploadId, parts, ...(!user && { identify: id }) },
            postPathOptions: `/complete${!user ? '/anonymous' : ''}`,
            refresh: false
          });
        });
      })
      .then(f => {
        onSuccess && onSuccess(f);
        return f;
      })
      .catch(err => {
        onError && onError(err);
        throw err;
      });
  };

  return { createThumbnail, compressImage, handleUpload };
};

export default useFileUpload;
