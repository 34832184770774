import { useEffect } from 'react';
import { useLocation } from 'react-router';

const useReplaceUrl = ({ preventTrigger = true, urlToReplace }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!preventTrigger) {
      window.history.replaceState(null, '', urlToReplace);
    }
  }, [preventTrigger]);

  const returnToOriginalUrl = () => {
    window.history.replaceState(null, '', pathname);
  };
  const replaceToUrl = url => {
    window.history.replaceState(null, '', url);
  };

  const removeSearch = searchStringToRemove => {
    const newQueryString = search?.split('&')?.reduce((acc, curr, index) => {
      if (curr?.includes(searchStringToRemove)) return acc;

      return acc?.length ? `${acc}&${curr}` : `${index !== 0 ? '?' : ''}${curr}`;
    }, '');

    window.history.replaceState(null, '', `${pathname}${newQueryString}`);
  };

  return { returnToOriginalUrl, replaceToUrl, removeSearch };
};

export default useReplaceUrl;
