import React from 'react';
import * as Yup from 'yup';
import { name, accountTransferValidate, priceValidationCurrency, requiredDate } from '../schema';
import formatNumber from '../../helpers/formatNumber';

const transferSchema = Yup.object().shape({
  information: name,
  idTransferOut: accountTransferValidate,
  idTransferIn: accountTransferValidate,
  value: priceValidationCurrency,
  billingDate: requiredDate
});

const transferMapping = ({
  accountList,
  isConciliation,
  initialData,
  disable,
  ButtonUndoReconciliation,
  data,
  setUndoReconciliation
}) => ({
  idTransferOut: {
    name: 'Conta de origem*',
    type: 'select',
    options: accountList,
    allowCustomOptions: true,
    allowClear: true,
    xs: 24,
    md: 8,
    customTooltip: (
      <ButtonUndoReconciliation transferType="income" data={data} setUndoReconciliation={setUndoReconciliation} />
    ),
    readOnly: (isConciliation && initialData.idTransferOut) || disable
  },
  idTransferIn: {
    name: 'Conta de destino*',
    type: 'select',
    options: accountList,
    allowCustomOptions: true,
    allowClear: true,
    xs: 24,
    md: 8,
    customTooltip: (
      <ButtonUndoReconciliation transferType="expense" data={data} setUndoReconciliation={setUndoReconciliation} />
    ),
    readOnly: (isConciliation && initialData.idTransferIn) || disable
  },
  value: {
    name: 'Valor*',
    type: 'currency',
    placeholder: 'R$',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val || 0),
    xs: 24,
    md: 8,
    readOnly: disable
  },
  billingDate: {
    name: 'Data da transferência*',
    type: 'date',
    placeholder: 'Selecione',
    format: 'DD/MM/YYYY',
    xs: 24,
    md: 8,
    readOnly: isConciliation || disable
  },
  information: {
    name: 'Descrição*',
    type: 'text',
    placeholder: '-',
    xs: 24,
    md: 16
  }
});

export { transferSchema, transferMapping };
