import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEye, faCloudDownload, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { Switch, Tooltip } from 'antd';

import TextArea from 'antd/lib/input/TextArea';

import { bytesToSize, downloadFile, getFileName, getFileType, isExtensionSupported } from '../../lib/helpers/helper';

import EditableInput from '../Input/EditableInput';
import Button from '../Button/Button';
import Select from '../Select/Select';
import ConfirmModal from '../Modal/ConfirmModal';
import ProjectOrOpportunityEventService from '../../lib/gtm/opportunity';
import CopyLinkButton from '../Button/CopyLinkButton';
import { getNameWithoutExtension, getExtension } from '../../lib/helpers/file';
import { Description, Paragraph, Subtitle } from '../Text/Text';

import { Div, radius, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import WarningBar from '../Alert/WarningBar';

const gtmEventService = ProjectOrOpportunityEventService();

const BtnContainer = styled.div`
  display: flex;
  margin-top: 10px;

  #visualization-file {
    min-width: 150px;
    padding: 14px;
    margin-right: 10px;
  }
  #open-file,
  #download-file,
  #delete-file {
    border-radius: ${radius.radius1};
    padding: 14px;
    margin-right: 10px;
    max-width: 30px;
    span {
      display: none;
    }
  }
  #btn-open span {
    display: none;
  }
`;

const RenderFileInfo = ({
  isFolderShared,
  isProject,
  isCustomer,
  data,
  isFolder,
  setSelectedIndexSideBar = f => f,
  selectedTab = 'info',
  idRefurbish,
  selectFile = f => f,
  onToggleModal = f => f,
  user,
  isModal,
  onShareItem = f => f,
  isModuleShared,
  onChangeFileFolder = f => f,
  reload = f => f,
  onlyInfo,
  viewImage
}) => {
  const shouldSave = useRef(false);
  const { handleUpdate, handleDelete } = useCRUD({
    model: isFolder ? 'folder' : 'file',
    immediatelyLoadData: false
  });

  const { user: userFile = {}, id, fullpath, src, filename, shared, isExternal, files, filesize } = data || {};

  const [name, setName] = useState(isFolder ? data?.name : filename);
  const [description, setDescription] = useState(data?.description);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const _name = name || getFileName(fullpath);
  const isMine = user?.userType === userFile?.userType;
  const { userType } = useSelector(state => state.setup.enums);
  const isSupported = data ? isExtensionSupported(getFileType(_name)) : false;
  const isFileShared = !isFolder && (!!shared || isFolderShared);
  const isWarningMessage = !isModuleShared && (isFileShared || isFolderShared);

  const dataUrl = fullpath || src;

  const canEdit =
    ((isMine && !isFolder) || (isFolder && !isCustomer)) && !user?.isCustomerView && !user?.anonymous && user;

  const handleChangeName = values => {
    handleUpdate({
      id,
      values: {
        idRefurbish,
        name: data?.name,
        ...values
      },
      refresh: false,
      noLoading: true
    }).then(resp => {
      if (!resp?.error) {
        reload();
      }
    });
  };

  const handleChangeSelectFolder = idFolder =>
    handleUpdate({
      id,
      values: { idFolder, idRefurbish, event: `${isProject ? 'project' : 'opportunity'}-file-moved` },
      refresh: false
    }).then(resp => {
      if (!resp?.error) {
        onChangeFileFolder(idFolder);
      }
    });

  useEffect(() => {
    setSelectedIndexSideBar(selectedTab);
    setDescription(data?.description);
    setName(isFolder ? data?.name : filename);
    shouldSave.current = false;
  }, [data]);

  const handleRemove = shouldRemove => {
    setShowConfirmModal(false);

    if (shouldRemove) {
      handleDelete({
        id,
        values: {
          idRefurbish
        },
        refresh: false
      }).then(resp => {
        if (resp?.error) return null;
        selectFile(null);
        reload();
        return null;
      });
      if (onToggleModal) onToggleModal(false);
    }
  };

  const handleChangeSwitch = oldShared => {
    handleUpdate(
      {
        id,
        updatePathOptions: '/share',
        values: {
          idRefurbish,
          shared: oldShared ? null : new Date()
        },
        refresh: false,
        verb: isFolder ? 'patch' : 'put'
      },
      true
    ).then(() => {
      reload();
      if (!oldShared) {
        onShareItem();
      }
    });
  };

  const renderText = useMemo(() => {
    if (isWarningMessage)
      return `Seu cliente não tem acesso aos itens compartilhados pois o
              compartilhamento do módulo de arquivos não está ativo.`;
    if (isFolderShared) {
      if (isFolder) return 'Esta pasta e todos os arquivos dentro dela estão compartilhados';
      return 'Este arquivo está compartilhado, pois a pasta está compartilhada';
    }
    return 'Este arquivo está compartilhado';
  }, [isWarningMessage, isFolderShared, isFolder]);

  const getNameWithExtension = newName => {
    let result = getNameWithoutExtension(newName);

    let extension = getExtension(newName);
    if (!extension) {
      extension = getExtension(_name);
    }

    if (extension) result += `.${extension}`;

    return result;
  };

  const onFileOrFolderNameInputChange = newName => {
    if (!isFolder)
      handleChangeName({ name: getNameWithoutExtension(newName), filename: getNameWithExtension(newName) });
    else handleChangeName({ name: newName });
  };

  return (
    <>
      <Div direction="column" gap={spaces.space2} align="start">
        <div style={{ marginTop: spaces.space1, wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
          {id > 1 ? (
            <EditableInput
              icon
              value={name}
              onChange={onFileOrFolderNameInputChange}
              map={!isFolder ? getNameWithExtension : undefined}
              disabled={!canEdit}
            />
          ) : (
            <Paragraph type="small">{name}</Paragraph>
          )}
        </div>
        {isFolder && id !== 1 ? (
          <div>
            <TextArea
              placeholder="Descrição da pasta"
              onChange={e => setDescription(e?.target?.value)}
              value={description}
              disabled={user?.userType !== userType.provider.value}
              onBlur={() => (data?.description !== description ? handleChangeName({ description }) : f => f)}
            />
          </div>
        ) : null}
        {!isFolder && !onlyInfo ? (
          <Tooltip
            title={
              isCustomer
                ? 'Copie o link desse arquivo para enviar para profissional'
                : 'Copie o link desse arquivo para enviar para seu cliente ou equipe'
            }
          >
            <CopyLinkButton
              urlContext="a"
              idData={id}
              eventButton="file"
              customUrl={isExternal ? dataUrl : null}
              text
              type="primary"
            >
              Copiar link
            </CopyLinkButton>
          </Tooltip>
        ) : null}
        {canEdit && !isCustomer ? (
          <>
            <div>
              <Subtitle>Configurações {isFolder ? ' da pasta' : ' do arquivo'}</Subtitle>
            </div>
            <div>
              {id !== 1 && (
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <Paragraph type="small">Compartilhar {isFolder ? ' pasta' : ' arquivo'}</Paragraph>
                    <Description>Habilite para compartilhar com seu cliente</Description>
                  </div>
                  <div>
                    <Switch
                      disabled={isFolder && !isCustomer ? null : isFolderShared}
                      checked={!!shared || isFolderShared}
                      onChange={() => handleChangeSwitch(shared)}
                      size="small"
                      id="switch-shared-file"
                    />
                  </div>
                </div>
              )}
              {(isFileShared || (isFolder && isFolderShared)) && (
                <WarningBar
                  icon={faUserFriends}
                  type={!isWarningMessage ? 'light' : null}
                  warning={renderText}
                  margin={`${spaces.space0} 0`}
                />
              )}
            </div>
          </>
        ) : null}
        <div>
          <Paragraph type="small">Data {isFolder ? ' da criação' : ' do envio'}:</Paragraph>
          <Description>{data ? dayjs(data.createdAt).format('DD [de] MMMM, YYYY [às] HH:mm') : '-'}</Description>
        </div>
        {!isFolder ? (
          <div>
            <Paragraph type="small">Tamanho:</Paragraph>
            <Description>{Number(filesize) ? bytesToSize(filesize) : 'N/A'}</Description>
          </div>
        ) : null}
        {data?.description && (
          <div>
            <Paragraph type="small">Descrição:</Paragraph>
            <Description>{data.description}</Description>
          </div>
        )}
        {!isCustomer && !isModal && !isFolder ? (
          <div>
            <Paragraph type="small">Pasta:</Paragraph>
            <Select
              id="dropdown-file"
              value={data?.idFolder}
              onChange={handleChangeSelectFolder}
              style={{ width: '100%' }}
              model="folder"
              modelOptions={{ where: { or: [{ idRefurbish }, { id: 1 }] } }}
              customValue="id"
              customLabel="name"
            />
          </div>
        ) : null}
        {isFolder && (
          <div>
            <Paragraph type="small">Arquivos</Paragraph>
            <Description>{files?.length}</Description>
          </div>
        )}
      </Div>
      <BtnContainer>
        {!isFolder && (
          <>
            {(!isModal || viewImage) && (
              <Tooltip placement="top" title="Visualizar">
                <Button
                  id="visualization-file"
                  ghost
                  fullWidth
                  size="small"
                  type={isSupported ? 'primary' : 'default'}
                  onClick={() => onToggleModal(true)}
                  disabled={!isSupported}
                  icon={<FontAwesomeIcon icon={faEye} />}
                >
                  {isSupported ? 'Visualizar' : 'Pré-visualização não suportada'}
                </Button>
              </Tooltip>
            )}
            {id > 1 || onlyInfo ? (
              <Tooltip placement="top" title="Fazer Download">
                <Button
                  id="download-file"
                  size="small"
                  ghost
                  shape=""
                  type="primary"
                  icon={<FontAwesomeIcon icon={faCloudDownload} />}
                  onClick={() => downloadFile(dataUrl, filename)}
                >
                  <span>Baixar</span>
                </Button>
              </Tooltip>
            ) : null}
            <Tooltip placement="top" title="Abrir">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={dataUrl}
                download={!isExternal}
                onClick={() => gtmEventService.onFileOpen(isProject)}
              >
                <Button
                  style={{ width: '100%' }}
                  size="small"
                  ghost
                  type="primary"
                  id="open-file"
                  icon={<FontAwesomeIcon icon={faExternalLink} />}
                >
                  Abrir
                </Button>
              </a>
            </Tooltip>
          </>
        )}
        {id > 1 && canEdit ? (
          <Tooltip placement="top" title="Excluir">
            <Button
              id={isFolder ? 'delete-folder' : 'delete-file'}
              size="small"
              style={{ width: '100%' }}
              ghost
              type="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => setShowConfirmModal(true)}
            >
              {!isFolder ? 'Excluir arquivo' : 'Excluir pasta'}
            </Button>
          </Tooltip>
        ) : null}
      </BtnContainer>
      {showConfirmModal && (
        <ConfirmModal
          text={`Deseja realmente excluir est${isFolder ? 'a pasta' : 'e arquivo'}?`}
          onSubmit={handleRemove}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

RenderFileInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  setSelectedIndexSideBar: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  isFolderShared: PropTypes.bool,
  isProject: PropTypes.bool,
  isCustomer: PropTypes.bool,
  isFolder: PropTypes.bool,
  selectedTab: PropTypes.string,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectFile: PropTypes.instanceOf(Object),
  onToggleModal: PropTypes.func,
  isModal: PropTypes.bool,
  onShareItem: PropTypes.func,
  isModuleShared: PropTypes.bool,
  onChangeFileFolder: PropTypes.func,
  reload: PropTypes.func,
  viewImage: PropTypes.bool,
  onlyInfo: PropTypes.bool
};

export default RenderFileInfo;
