import { faCopy, faTrashCan, faEye } from '@fortawesome/pro-regular-svg-icons';
import { copyLinkButton, deleteAction, taskDetails } from './_dropdown';
import { colors } from '../../../styles/style';

const task = [
  {
    ...taskDetails,
    iconProps: {
      color: colors.neutral400,
      icon: faEye
    }
  },
  {
    label: 'Duplicar',
    verb: 'post',
    pathOptions: '/duplicate',
    params: {
      postPathOptions: '/duplicate'
    },
    iconProps: {
      color: colors.neutral400,
      icon: faCopy
    }
  },
  {
    button: 'copyLink',
    buttonParams: {
      text: true,
      children: 'Copiar link'
    }
  },
  {
    ...deleteAction,
    iconProps: {
      color: colors.red500,
      icon: faTrashCan
    },
    style: { color: colors.red500 }
  }
];

const generalTask = [
  {
    ...taskDetails,
    iconProps: {
      color: colors.neutral400,
      icon: faEye
    }
  },
  {
    label: 'Duplicar',
    verb: 'post',
    pathOptions: '/duplicate',
    params: {
      postPathOptions: '/duplicate'
    },
    iconProps: {
      color: colors.neutral400,
      icon: faCopy
    }
  },
  copyLinkButton
];

export { task, generalTask };
