import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import Dropdown from './Dropdown';
import BubbleModalButton from '../Button/BubbleModalButton';

import useViewport from '../../_Hooks/useViewport';
import { breakpoints, spaces } from '../../styles/style';
import OptionButton from '../Button/OptionButton';
import Action from '../Button/Action';
import ImportInstructionModal from '../Modal/ImportInstructionsModal';

export const StyledDropdownDiv = styled.div`
  right: ${spaces.space1};
  padding: ${props => (props.padding || props.padding === 0 ? props.padding : `0 ${spaces.space1}`)};

  @media (max-width: ${breakpoints.tablet}) {
    position: relative;
    border: none;
    padding: 0;
    right: 0;
    bottom: 0;
  }
`;

const ActionButtonDropdown = ({
  onExportPdfClick,
  onExportCsvClick,
  onExportXlsxClick,
  onExportListClick,
  onExportTimelineClick,
  onImportClick,
  onImportOfxClick,
  onExportInvoicesClick,
  hasPermissionExport = true,
  showScheduleExport,
  showImport,
  showExport = true,
  showExportInvoices = false,
  ExtraOptions,
  model,
  hideButton,
  menu,
  showMobile = false,
  id = 'action-button',
  buttonProps,
  showImportOfx,
  helperImportId,
  text = 'Ações',
  dropdownPadding,
  ...params
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [importModal, setImportModal] = useState(false);

  return !isMobile() || showMobile ? (
    <StyledDropdownDiv {...params}>
      {importModal && (
        <ImportInstructionModal
          model={model}
          onClose={() => setImportModal(false)}
          goNext={() => {
            setImportModal(false);
            onImportClick();
          }}
          {...params}
        />
      )}
      <Dropdown
        padding={dropdownPadding}
        trigger={['click']}
        menu={
          menu || (
            <>
              {hasPermissionExport && showExport && (
                <>
                  {onExportPdfClick && !hideButton?.pdfButton && (
                    <OptionButton id="btn-export-pdf" onClick={onExportPdfClick} text>
                      Exportar PDF
                    </OptionButton>
                  )}
                  {onExportCsvClick && (
                    <OptionButton id="btn-export-csv" onClick={onExportCsvClick} text>
                      Exportar CSV
                    </OptionButton>
                  )}
                  {showScheduleExport ? (
                    <>
                      <OptionButton text>Exportar Excel</OptionButton>
                      <OptionButton id="btn-export-list" onClick={onExportListClick} text tab>
                        Lista
                      </OptionButton>
                      <OptionButton id="btn-export-timeline" onClick={onExportTimelineClick} text tab>
                        Linha do tempo
                      </OptionButton>
                    </>
                  ) : (
                    <>
                      {onExportXlsxClick && (
                        <OptionButton id="btn-export-excel" onClick={onExportXlsxClick} text>
                          Exportar Excel
                        </OptionButton>
                      )}
                    </>
                  )}
                  {showExportInvoices && (
                    <OptionButton id="btn-export-csv" onClick={onExportInvoicesClick} text>
                      Exportar notas fiscais
                    </OptionButton>
                  )}
                </>
              )}
              {showImportOfx && (
                <>
                  <OptionButton id="btn-import" onClick={onImportOfxClick} text>
                    Importar OFX
                  </OptionButton>
                </>
              )}
              {showImport && (
                <>
                  <OptionButton id="btn-import" onClick={() => setImportModal(true)} text>
                    Importar planilha
                  </OptionButton>
                  {helperImportId ? (
                    <OptionButton text id={helperImportId}>
                      Ajuda na importação
                    </OptionButton>
                  ) : null}
                </>
              )}
              {hasPermissionExport === null && (
                <OptionButton icon={<FontAwesomeIcon icon={faLock} />} text>
                  <BubbleModalButton feature="item">
                    <div>Exportar</div>
                  </BubbleModalButton>
                </OptionButton>
              )}
              {ExtraOptions && ExtraOptions}
            </>
          )
        }
      >
        <Action id={id} label={text} text {...buttonProps} />
      </Dropdown>
    </StyledDropdownDiv>
  ) : null;
};

ActionButtonDropdown.propTypes = {
  onExportPdfClick: PropTypes.func,
  onExportCsvClick: PropTypes.func,
  onExportXlsxClick: PropTypes.func,
  onExportListClick: PropTypes.func,
  onExportTimelineClick: PropTypes.func,
  onImportClick: PropTypes.func,
  hasPermissionExport: PropTypes.bool,
  showScheduleExport: PropTypes.bool,
  showImport: PropTypes.bool,
  showExport: PropTypes.bool,
  ExtraOptions: PropTypes.instanceOf(Object),
  model: PropTypes.string,
  hideButton: PropTypes.instanceOf(Object),
  params: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object),
  showMobile: PropTypes.bool,
  id: PropTypes.string,
  buttonProps: PropTypes.instanceOf(Object),
  showImportOfx: PropTypes.bool,
  onImportOfxClick: PropTypes.func,
  helperImportId: PropTypes.string,
  text: PropTypes.string,
  showExportInvoices: PropTypes.bool,
  onExportInvoicesClick: PropTypes.func,
  dropdownPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ActionButtonDropdown;
