import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import * as dayjs from 'dayjs';
import * as ptBt from 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';

import { Helmet } from 'react-helmet';
import { ApmRoute } from '@elastic/apm-rum-react';
import CacheBuster from './lib/cache/CacheBuster/CacheBuster';
import MainRoutes from './routes/MainRoutes';

import { store, persistor } from './lib/config/redux-store';
import CenteredLoader from './components/Loader/CenteredLoader';
import DndContext from './contexts/DndContext';

dayjs.locale(ptBt);
dayjs.extend(utc);

function App() {
  // eslint-disable-next-line no-console
  const consoleError = console.error;
  // eslint-disable-next-line no-console
  console.error = error => {
    if (typeof error?.indexOf === 'function' && error?.indexOf('useLayoutEffect does nothing on the server') === -1) {
      consoleError();
    }
  };

  return (
    <>
      <iframe
        id="loginIframe"
        style={{ position: 'absolute', top: 0, height: 0, width: 0 }}
        title="login"
        src={`${process.env.REACT_APP_BUBBLE_URL}/iframe`}
      />
      <Helmet htmlAttributes={{ lang: 'pt-BR', translate: 'no' }} />
      <ConfigProvider locale={ptBR}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DndContext>
              <BrowserRouter>
                <CacheBuster
                  isEnabled={process.env.REACT_APP_CACHE_BUSTER_ENABLED === 'true'}
                  loadingComponent={<CenteredLoader />}
                >
                  <ApmRoute
                    path={[
                      '/:rootPage/:page/:subpage/:action/:subaction',
                      '/:rootPage/:page/:subpage/:action',
                      '/:rootPage/:page/:subpage',
                      '/:rootPage/:page',
                      '/:rootPage',
                      '/'
                    ]}
                    component={MainRoutes}
                  />
                </CacheBuster>
              </BrowserRouter>
            </DndContext>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </>
  );
}

export default App;
