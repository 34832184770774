import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const QuoteSupplierItemFooter = ({ item, handleChange }) => {
  const quoteItemSupplier = item.quoteItemSuppliers?.[0] || {};
  return (
    <Div padding={spaces.space1} gap={spaces.space0}>
      <Switch
        id="mark-unavailable"
        checked={quoteItemSupplier?.isUnavailable}
        onChange={checked => handleChange({ id: item.id, isUnavailable: checked })}
        size="small"
      />
      <Paragraph type="small">Produto indisponível</Paragraph>
    </Div>
  );
};

QuoteSupplierItemFooter.propTypes = {
  item: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func
};

export default QuoteSupplierItemFooter;
