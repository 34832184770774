import * as Yup from 'yup';
import React from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import Button from '../../../components/Button/Button';
import { Div, fonts, spaces } from '../../../styles/style';

const schema = Yup.object().shape({
  email: Yup.string().email('Email inválido')
});

const mappingForm = ({ handleCopyToClipboard, link, urlText, loading, onShare = f => f, emailText, linkText }) => {
  return {
    email: {
      name: emailText || 'E-mail',
      type: 'text',
      placeholder: 'E-mail',
      labelClassName: 'info-form-label',
      xs: 24,
      md: 19
    },
    button: {
      type: 'custom',
      style: { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: 0 },
      Component: () => (
        <Button fullWidth loading={loading} type="primary" htmlType="submit" form="emailSharingForm">
          Enviar
        </Button>
      ),
      md: 5
    },
    link: {
      name: linkText || 'Link',
      type: 'text',
      placeholder: 'Link',
      labelClassName: 'info-form-label',
      xs: 18,
      md: 18,
      disabled: true,
      id: 'link-input'
    },
    linkButton: {
      type: 'custom',
      style: { display: 'flex', flexDirection: 'column', justifyContent: 'end' },
      Component: () => (
        <Div>
          <Tooltip placement="bottom" title="Copiar link" style={{ marginRight: spaces.space2 }}>
            <Button type="primary" id="copy-url" text onClick={handleCopyToClipboard}>
              <FontAwesomeIcon icon={faCopy} style={{ fontSize: fonts.sizeLg }} />
            </Button>
          </Tooltip>
          <Tooltip placement="bottom" title="Enviar via whatsapp">
            <a href={`https://wa.me/?text=${encodeURI(urlText)}${link}`} target="_blank" onClick={() => onShare()}>
              <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: fonts.sizeLg }} />
            </a>
          </Tooltip>
        </Div>
      ),
      xs: 6,
      md: 6
    }
  };
};

export { schema, mappingForm };
