import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';

import styled from 'styled-components';
import useViewport from '../../_Hooks/useViewport';

import Dropdown from './Dropdown';
import CopyLinkButton from '../Button/CopyLinkButton';

import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';

const StyledTagLink = styled.a`
  font-weight: 300;
  :hover {
    font-weight: 400;
  }
`;

const ShareLinkDropdown = ({
  id,
  urlContext,
  style,
  whatsapp,
  customUrl,
  customEvent = 'project-item-copy-link',
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);

  return id ? (
    <Dropdown
      id="share-link-dropdown"
      padding={`0 ${spaces.space1}`}
      mappingKey={whatsapp ? 'shareLinkWithWhatsapp' : 'shareLink'}
      trigger={['click']}
      button={{
        copyLink: ({ onClose: closeDropdown, ...params }) => (
          <CopyLinkButton
            onClose={closeDropdown}
            customEvent={customEvent}
            urlContext={urlContext}
            idData={id}
            customUrl={customUrl}
            {...params}
          />
        ),
        shareWhatsappLink: () => (
          <Button text>
            <StyledTagLink
              target="_blank"
              href={`https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20plataforma:%20
              ${customUrl || `${window.location.origin}/${urlContext}/${id}`}
              `}
            >
              WhatsApp
            </StyledTagLink>
          </Button>
        )
      }}
      {...props}
    >
      <Tooltip title="Compartilhar">
        <div
          style={{
            display: 'flex',
            height: spaces.space4,
            width: spaces.space4,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FontAwesomeIcon
            id="share-link-icon"
            style={{ padding: 0, ...style }}
            icon={faShareNodes}
            size={isMobile() ? 'sm' : 'lg'}
            color={colors.primary600}
          />
        </div>
      </Tooltip>
    </Dropdown>
  ) : null;
};

ShareLinkDropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  urlContext: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  whatsapp: PropTypes.bool,
  customUrl: PropTypes.string,
  customEvent: PropTypes.string
};

export default ShareLinkDropdown;
