import * as Yup from 'yup';
import { idReferenceNull, name, date, price, installments } from '../schema';

const billSchema = Yup.object().shape({
  name,
  billingDate: date.required('Campo obrigatório'),
  idSupplier: idReferenceNull,
  idRefurbish: idReferenceNull,
  value: price,
  installments
});

export { billSchema };
