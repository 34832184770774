import * as Yup from 'yup';
import dayjs from 'dayjs';
import { checkCnpj, checkCpf } from '../helpers/helper';
import formatNumber from '../helpers/formatNumber';

const name = Yup.string()
  .strict(false)
  .trim()
  .min(3, 'O campo deve ter pelo menos 3 caracteres')
  .nullable()
  .required('Campo obrigatório');

const simpleName = Yup.string()
  .strict(false)
  .trim()
  .nullable()
  .required('Campo obrigatório');

const text = Yup.string()
  .strict(false)
  .trim()
  .nullable();

const number = Yup.number()
  .typeError('O valor deve ser numérico')
  .nullable();
const price = Yup.number()
  .typeError('O valor deve ser numérico')
  .moreThan(0, 'O Valor deve ser diferente de zero')
  .required('Campo obrigatório');

const email = Yup.string()
  .email('E-mail inválido')
  .nullable();

const requiredEmail = email.required('Campo obrigatório');

const phone = Yup.string()
  .test({
    message: 'Telefone inválido',
    test: value => {
      const len = value ? value.replace(/\D+/g, '').length : 0;
      return len >= 10 || len === 0;
    }
  })
  .nullable();
const requiredPhone = phone.required('Campo obrigatório');

const website = Yup.string()
  .test({
    message: 'O site não está no formato correto',
    test: value => {
      if (!value) {
        return true;
      }
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return pattern.test(value);
    }
  })
  .nullable();

const date = Yup.date({ accept: [''] })
  .transform((curr, orig) => (orig === '' ? null : curr))
  .nullable();

const todayDateValidate = Yup.date().min(dayjs().subtract(1, 'day'), 'A data não pode ser anterior a hoje.');

const requiredDate = date.when('personType', (personType, _schema) =>
  personType === 'pj' ? _schema : _schema.required('Campo obrigatório')
);

const link = Yup.string()
  .nullable()
  .test({
    message: 'A URL informada não está no formato correto',
    test: value => {
      if (!value) return true;

      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return pattern.test(value);
    }
  });

const idReference = Yup.number()
  .nullable()
  .required('Campo obrigatório');

const idReferenceNotNull = Yup.string()
  .nullable()
  .required('Campo obrigatório');

const idReferenceNull = Yup.number().nullable();

const isActive = Yup.bool().nullable();

const isWpp = Yup.bool().default(false);

const password = Yup.string()
  .nullable()
  .required('Campo obrigatório')
  .min(8, 'Senha deve ter pelo menos 8 caracteres')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    'Senha deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número'
  );

const passwordConditional = Yup.string().when('id', (id, _schema) =>
  id
    ? _schema
    : _schema
        .required('Campo obrigatório')
        .nullable()
        .min(8, 'Senha deve ter no mínimo 8 caracteres')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Senha deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número'
        )
);

const confirmNewPassword = Yup.string()
  .min(8, 'Senha deve ter pelo menos 8 caracteres')
  .nullable()
  .required('Campo obrigatório')
  .min(8, 'Senha deve ter pelo menos 8 caracteres')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    'Senha deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número'
  )
  .oneOf([Yup.ref('newPassword'), null], 'Confirmação de senha errada');

const address = {
  zipcode: Yup.string().nullable(),
  street: Yup.string().nullable(),
  number: Yup.string().nullable(),
  complement: Yup.string().nullable(),
  state: Yup.string().nullable(),
  city: Yup.string().nullable()
};

const requiredAddress = {
  zipcode: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  street: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  number: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  state: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  city: Yup.string()
    .nullable()
    .required('Campo obrigatório')
};

const installments = Yup.array().of(
  Yup.object().shape({
    price,
    description: Yup.string().required('Campo obrigatório'),
    percentage: Yup.number()
      .moreThan(0, 'O valor não pode zero')
      .required('Campo obrigatório'),
    dueDate: date.required('Campo obrigatório')
  })
);

const code = Yup.string()
  .max(50, 'O campo não pode ser maior que 50 caracteres')
  .nullable();

const document = Yup.string()
  .strict(false)
  .trim()
  .nullable()
  .required('Campo obrigatório');

const validateDocument = Yup.string().when('personType', (personType, _schema) => {
  return personType === 'pf'
    ? _schema
        .test('cpf', 'CPF inválido', value => {
          return !!value && checkCpf(value.replace(/[,-./]/g, ''));
        })
        .nullable()
        .required('Campo obrigatório')
    : _schema
        .test('cnpj', 'CNPJ inválido', value => !!value && checkCnpj(value?.replace(/[,-./]/g, '')))
        .nullable()
        .required('Campo obrigatório');
});

const validateDocumentNotRequired = Yup.string().when('personType', (personType, _schema) => {
  return personType === 'pf'
    ? _schema
        .test('cpf', 'CPF inválido', value => {
          return !value || checkCpf(value.replace(/[,-./]/g, ''));
        })
        .nullable()
    : _schema.test('cnpj', 'CNPJ inválido', value => !value || checkCnpj(value?.replace(/[,-./]/g, ''))).nullable();
});

const requiredCpf = Yup.string()
  .test('cpf', 'CPF inválido', value => !!value && checkCpf(value?.replace(/[,-./]/g, '')))
  .nullable()
  .required('Campo obrigatório');

const requiredCnpj = Yup.string()
  .test('cnpj', 'CNPJ inválido', value => !!value && checkCnpj(value?.replace(/[,-./]/g, '')))
  .nullable()
  .required('Campo obrigatório');

const requiredArrayNumber = Yup.array()
  .of(Yup.number())
  .min(1, 'Selecione pelo menos uma opção')
  .required('Selecione pelo menos uma opção');

const requiredNumber = Yup.number()
  .typeError('Campo obrigatório')
  .required('Campo obrigatório');

const unavailableBalance = Yup.number()
  .required()
  .when('value', (value, schema) => {
    // OBS: Requires the value field in the schema to work
    return schema.test('saldo-disponivel', 'Valor não pode ser maior do que o saldo disponível', withdrawValue => {
      return value === 0 ? true : withdrawValue >= value;
    });
  });

const accountTransferValidate = Yup.mixed()
  .test('contas-iguais', 'As contas devem ser diferentes', function accountDif(value) {
    const { idTransferIn, idTransferOut } = this.parent;
    if (idTransferIn && idTransferOut) {
      return value !== idTransferIn || value !== idTransferOut;
    }
    return true;
  })
  .required('Campo obrigatório');

const priceValidationCurrency = Yup.string()
  .required('O preço é obrigatório')
  .test('price-greater-tha-zero', 'O preço deve ser maior que zero', value => {
    if (!value) return false;
    const numericValue = formatNumber(value);
    return numericValue > 0;
  });

export {
  name,
  email,
  date,
  link,
  phone,
  isWpp,
  text,
  website,
  idReference,
  isActive,
  password,
  passwordConditional,
  confirmNewPassword,
  number,
  price,
  idReferenceNull,
  address,
  installments,
  simpleName,
  code,
  requiredPhone,
  document,
  validateDocument,
  requiredEmail,
  requiredAddress,
  requiredDate,
  requiredCpf,
  requiredCnpj,
  requiredArrayNumber,
  requiredNumber,
  todayDateValidate,
  idReferenceNotNull,
  unavailableBalance,
  accountTransferValidate,
  priceValidationCurrency,
  validateDocumentNotRequired
};
