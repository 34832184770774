import { store } from '../../config/redux-store';

const getOptions = user => {
  const { setup } = store.getState();

  const _plan = user?.company?.plan || user?.company?.idPlan;

  if (!user || user.userType !== setup.enums?.userType.provider.value || !!user.accessedBy?.id) return null;

  if (!_plan || _plan === setup.enums?.planType.free.value)
    return {
      integrations: {
        All: true,
        Userpilot: false,
        Intercom: false,
        'Customer.io': false,
        'Customer.io Actions': false,
        'Customer IO - Custom (Vobi)': false,
        Mixpanel: false,
        Webhooks: false
      }
    };

  return {
    integrations: {
      All: true,
      'Customer.io': user?.isOwner,
      'Customer.io Actions': user?.isOwner,
      'Customer IO - Custom (Vobi)': user?.isOwner
    }
  };
};

const SegmentPlugin = (_segment = window.analytics) => {
  let segment = _segment;
  if (typeof _segment?.ready === 'function') {
    _segment.ready(() => {
      segment = window.analytics;
    });
  }

  const identify = user => {
    const options = getOptions(user);
    if (!user || !options) return;

    segment?.identify && segment.identify(user.userId, {}, options);
    segment?.group && user?.companyId && segment.group(user?.companyId, {}, options);
  };

  const track = ({ event, user, eventProperties = {} }) => {
    const options = getOptions(user);
    if (!user || !options) return;

    const _eventProperties = { ...eventProperties, $groups: { segment_group: user?.idCompany } };

    segment?.track && segment.track(event, _eventProperties, options);
  };

  const pageview = ({ user, eventProperties }) => {
    const options = getOptions(user);
    if (!options) return;

    const _eventProperties = { ...eventProperties, $groups: { segment_group: user?.idCompany } };

    segment?.page && segment.page(null, null, _eventProperties, options);
  };

  return { identify, track, pageview };
};

export default SegmentPlugin;
