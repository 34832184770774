import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors, fonts, radius, spaces } from '../../styles/style';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.space3} ${spaces.space4};
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  height: ${spaces.space5};
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.space1} ${spaces.space2};
  border-radius: ${radius.radius1};
  background-color: ${colors.neutral75};
  margin-bottom: ${spaces.space1};
  span {
    color: ${colors.neutral600};
  }
  :hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);
  }
`;

const StyledStrashIcon = styled(FontAwesomeIcon)`
  color: ${colors.red500};
  padding-left: ${spaces.space2};
  height: ${fonts.sizeMd};
  :hover {
    cursor: pointer;
  }
`;

const IconCheck = styled(FontAwesomeIcon)`
  color: ${colors.primary600};
  padding-left: ${spaces.space2};
  height: ${fonts.sizeMd};
  :hover {
    cursor: pointer;
  }
`;

const EmptyText = styled.span`
  color: ${colors.neutral500};
  font-size: ${fonts.sizeMd};
`;

export { Content, ListItems, Card, StyledStrashIcon, IconCheck, EmptyText };
