import { createRef, useLayoutEffect, useState } from 'react';

const useSeeMore = () => {
  const ref = createRef();
  const [hasOverflow, setHasOverflow] = useState(false);

  useLayoutEffect(() => {
    if (ref.current?.offsetHeight < ref.current?.scrollHeight) {
      setHasOverflow(true);
    }
  }, [ref]);

  return { ref, hasOverflow };
};

export default useSeeMore;
