import styled from 'styled-components';
import { breakpoints, radius } from '../../styles/style';

const ContentTable = styled.div`
  font-size: 12px;
  box-shadow: 0 8px 16px 0 #00000014;
  border-radius: ${radius.radius1};
  margin: ${props => props.margin};
  @media (min-width: ${breakpoints.tablet}) {
    background-color: #fff;
  }
  ${props => props.$fullWidth && 'width: 100%;'}
`;

export default ContentTable;
