import styled from 'styled-components';
import { spaces } from '../../styles/style';

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  gap: ${spaces.space2};
  justify-content: space-between;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${spaces.space2} 0 0;
`;

export { StyledFooter, TabContent };
