import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button/Button';
import { Div, fonts, spaces } from '../../styles/style';

const GanttLabel = ({ text, icon, customStyle, textAlign }) => (
  <div className="gantt_grid_head_cell_aux" style={customStyle}>
    {icon && (
      <Button
        id="icon-grid-header"
        $textAlign="center"
        width={spaces.space2}
        type="text"
        margin={`0 ${spaces.space0} 0 0`}
      >
        <FontAwesomeIcon icon={icon} style={{ fontSize: fonts.sizeSm }} />
      </Button>
    )}
    <Div display="block" $textAlign={textAlign}>
      {text}
    </Div>
  </div>
);

GanttLabel.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  customStyle: PropTypes.string,
  textAlign: PropTypes.string
};

export default GanttLabel;
