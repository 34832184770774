import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styled, { css } from 'styled-components';
import { Descriptions as AntDescriptions } from 'antd';
import { colors, fonts, spaces, breakpoints, radius } from '../../styles/style';
import { getProperty } from '../../lib/helpers/helper';

const StyledDescriptions = styled(AntDescriptions)`
  border-radius: ${radius.radius1};
  background: ${props => (props.background ? colors[props.background] : null)};
  padding: ${props => !props?.$noPadding && `${spaces.space2} ${spaces.space3}`};

  &.form-component {
    .ant-descriptions-title {
      font-size: ${fonts.sizeMd};
      color: ${colors.neutral700};
      font-weight: ${fonts.weight600};
    }

    .ant-descriptions-item-label {
      font-size: ${fonts.sizeSm};
      color: ${colors.neutral600};
    }
  }

  .ant-descriptions-title {
    max-width: fit-content;
    font-size: ${fonts.sizeLg};
    font-weight: ${fonts.weight500};
    color: ${colors.neutral600};

    ${props =>
      props.centeredTitle &&
      css`
        display: flex;
        justify-content: center;
      `}
  }

  .ant-descriptions-item {
    padding-bottom: 0;
  }

  .ant-descriptions-item-label {
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral500};
    ::after {
    content: '${props => (props.$customAfter ? props.$customAfter : ':')}';
  }
  }



  .ant-descriptions-item-content {
    padding-bottom: ${spaces.space2};
    color: ${colors.neutral600};
    display: inline;

    ${props =>
      props.ellipsis &&
      css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }

  &.ant-descriptions-middle {
    .ant-descriptions-title {
      font-size: 16px;
    }
  }

  &.ant-descriptions-small {
    .ant-descriptions-title {
      font-size: 20px;
    }

    .ant-descriptions-item-label {
      font-size: ${fonts.sizeXs};
    }
    .ant-descriptions-item-content {
      font-size: ${fonts.sizeSm};
      padding-bottom: ${spaces.space1};
    }
    .ant-descriptions-item {
      padding-bottom: 0;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: ${spaces.space2};

    .ant-descriptions-item {
      padding-bottom: 0 !important;
    }
  }

  ${props =>
    props?.elevation &&
    css`
      box-shadow: 0 calc(8px * ${props.elevation}) calc(8px * ${props.elevation + 1}) 0 rgba(0, 0, 0, 0.08);
    `}

  .ant-descriptions-extra {
    margin-left: ${spaces.space0};
  }
`;

const Description = ({
  icon,
  data,
  mapping,
  layout = 'vertical',
  column = 3,
  noPadding,
  background = 'white',
  centeredTitle,
  customAfter,
  ...props
}) =>
  data ? (
    <StyledDescriptions
      layout={layout}
      column={column}
      $noPadding={noPadding}
      background={background}
      centeredTitle={centeredTitle}
      {...props}
    >
      {Object.keys(mapping).map(field => {
        const { otherProps, label, format, nestedKey, hideField } = mapping[field] || {};
        const property = otherProps?.html ? parse(data[field] || '-') : data[field];
        const value = nestedKey && property?.constructor === Object ? getProperty(property, nestedKey) : property;

        return hideField ? null : (
          <StyledDescriptions.Item
            key={field}
            contentStyle={otherProps?.type && { color: colors[`${otherProps?.type}300`] }}
            span={otherProps?.span || 1}
            label={label}
          >
            {icon || null}
            {(format ? format(value, data) : value) || '-'}
          </StyledDescriptions.Item>
        );
      })}
    </StyledDescriptions>
  ) : null;

Description.propTypes = {
  icon: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  mapping: PropTypes.instanceOf(Object),
  layout: PropTypes.string,
  column: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Object)]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  noPadding: PropTypes.bool,
  background: PropTypes.string,
  centeredTitle: PropTypes.bool,
  customAfter: PropTypes.string
};

export default Description;
