import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Paragraph, Subtitle } from '../Text/Text';
import { CardInfoGrid } from './VobiPayPayment.styles';
import { Div, colors, fonts, spaces } from '../../styles/style';
import { renderPriceOrPercentage } from '../../lib/helpers/helper';

const ExtraInfoInstallmentToPay = ({ installmentToPay, installments, typePayment }) => {
  const {
    fineInterestDiscountType: { fixed },
    vobiPayPaymentType
  } = useSelector(state => state.setup.enums);
  const { dueDateDiscountType, fineType, fine, interest, dueDateLimitDays, dueDateDiscount } =
    installmentToPay?.extraValues || {};
  const { discount } = installmentToPay || {};
  const discountPrice = dueDateDiscountType === fixed?.value;
  const finePrice = fineType === fixed?.value;
  const today = dayjs().startOf('day');
  const dueDateDay = dayjs(installmentToPay?.dueDate).startOf('day');
  const isOverdue = dueDateDay.isBefore(today);

  const getStatusText = () => {
    const diffDays = Math.abs(dueDateDay.diff(today, 'day'));
    if (isOverdue) {
      return `vencido há ${diffDays} dia(s)`;
    }
    if (dueDateDay.isSame(today, 'day')) {
      return 'Vence hoje';
    }
    return `daqui a ${diffDays} dia(s)`;
  };

  const renderFineInterest = fine || interest;
  const renderDiscount = dueDateDiscount && !isOverdue && dueDateDay.diff(today, 'day') >= dueDateLimitDays;
  const notRenderCredit = !(typePayment === vobiPayPaymentType?.creditCard && installments.length > 1);

  return (
    <Div direction="column" $fullWidth align="baseline" gap={spaces.space3} className="hide-on-print">
      <Paragraph type="small">
        <strong>Dados da parcela selecionada</strong>
      </Paragraph>
      <CardInfoGrid>
        {notRenderCredit && renderDiscount ? (
          <Div>
            <Paragraph type="small">
              {`Valor com ${renderPriceOrPercentage(dueDateDiscount, discountPrice)} de desconto`}
            </Paragraph>
            <Subtitle color={colors.primary500}>
              {renderPriceOrPercentage(installmentToPay.price - discount, true)}
            </Subtitle>
            <Paragraph type="small">
              {`até ${dayjs(installmentToPay.dueDate)
                .subtract(dueDateLimitDays, 'day')
                .format('DD/MM/YYYY')}`}
            </Paragraph>
          </Div>
        ) : null}
        <Div>
          <Paragraph type="small">
            Valor{notRenderCredit && renderFineInterest && renderDiscount ? ' original' : ''}
          </Paragraph>
          <Subtitle weight={fonts.weight600} color={isOverdue ? colors.red500 : colors.orange400}>
            {renderPriceOrPercentage(installmentToPay.price, true)}
          </Subtitle>
        </Div>
        <Div>
          <Paragraph type="small">Data de vencimento</Paragraph>
          <Subtitle weight={fonts.weight600} color={isOverdue ? colors.red500 : colors.neutral500}>
            {dayjs(installmentToPay?.dueDate).format('DD/MM/YYYY')}
          </Subtitle>
          <Paragraph type="small">{getStatusText()}</Paragraph>
        </Div>
        {notRenderCredit && renderFineInterest ? (
          <Div>
            <Paragraph type="small">{isOverdue ? 'Cobrança vencida' : 'Após o vencimento'}</Paragraph>
            {interest ? (
              <Subtitle weight={fonts.weight600} type="secondary">
                {`${renderPriceOrPercentage(interest)} de juros ao mês`}
              </Subtitle>
            ) : null}
            {fine ? (
              <Subtitle weight={fonts.weight600} type="secondary">
                {`${renderPriceOrPercentage(fine, finePrice)} de multa`}
              </Subtitle>
            ) : null}
          </Div>
        ) : null}
      </CardInfoGrid>
    </Div>
  );
};

ExtraInfoInstallmentToPay.propTypes = {
  installmentToPay: PropTypes.instanceOf(Object),
  installments: PropTypes.instanceOf(Array),
  typePayment: PropTypes.string
};

export default ExtraInfoInstallmentToPay;
