import React, { useState, useEffect } from 'react';
import { Col, Row, Space } from 'antd';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useContextHook } from '../../contexts/GeneralContext';
import Select from '../Select/Select';
import Label from '../Label/Label';
import { colors, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import { FlexGapContainer } from './PaymentBasicForm.styled';
import TooltipIcon from '../Tooltip/TooltipIcon';
import Input from '../Input/Input';
import NewMaskedInput from '../Input/NewMaskedInput';
import { Error, Subtitle } from '../Text/Text';
import CardPaymentType from '../Card/CardPaymentType';
import DatePicker from '../Datepicker/Datepicker';
import { calculateInstallments } from '../../lib/helpers/payment';
import CenteredLoader from '../Loader/CenteredLoader';
import Segment from '../Select/Segmented';
import BillingManagerContent from '../Content/BillingManagerContent';
import { Content } from '../../_Pages/Payments/Payment.style';
import { Line } from '../Line/Line';

const typeItems = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' }
];

const selectOpts = { where: { isActive: true }, order: ['name'] };

const TooltipDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const PaymentVobiPay = () => {
  const [showCustomerDrawer, setCustomerDrawer] = useState(false);
  const { maxLimitPayment } = useSelector(state => state.setup.enums);

  const {
    setField,
    isView,
    touched,
    errors,
    handleBlur,
    values,
    isPublic,
    isCustomerView,
    handleChange,
    hasPermissionVP = false,
    setNoCredit
  } = useContextHook();
  const {
    id,
    idCompanyCustomer,
    companyCustomer,
    billType,
    dueDate,
    customerDoc,
    customerPhone,
    customerEmail,
    total,
    paymentTypes,
    installmentCount,
    customerPersonType,
    installmentPrice,
    billingChannels
  } = values || {};
  const billTypeIncome = billType === 'income';
  const [_companyCustomer, setCompanyCustomer] = useState(companyCustomer);
  const showEditCustomer = _companyCustomer && billTypeIncome && !isPublic && !isCustomerView && id;

  const { handleGet: getCustomer } = useCRUD({
    model: 'companyCustomer',
    immediatelyLoadData: false
  });

  const { data: fees, loading } = useCRUD({
    model: 'pay',
    pathOptions: `/accounts/fees`,
    immediatelyLoadData: !isCustomerView && !isPublic && hasPermissionVP
  });

  const handleChangeCompanyCustomer = val => {
    setField('idCompanyCustomer')(val);

    if (val && !isView) {
      getCustomer({
        refetchPathOptions: `/${val}`,
        refetchOptions: {
          attributes: ['id', 'name', 'doc', 'phone', 'email', 'personType']
        }
      }).then(res => {
        if (setCompanyCustomer) setCompanyCustomer(res);
        setField('companyCustomer')(res);
        setField('customerDoc')(res?.doc);
        setField('customerEmail')(res?.email);
        setField('customerPhone')(res?.phone);
        setField('customerPersonType')(res?.personType);
      });
    }
  };

  useEffect(() => {
    if (idCompanyCustomer && !customerDoc) {
      handleChangeCompanyCustomer(idCompanyCustomer);
    }
    if (!billingChannels) {
      setField('billingChannels')({ email: true });
    }
  }, []);

  const changePaymentType = value => {
    setField('paymentTypes')(value);
  };

  const installmentCountAndPrice = calculateInstallments({ totalValue: total, maxLimitPayment });

  useEffect(() => {
    setField('installmentPrice')(installmentPrice || installmentCountAndPrice?.[0]?.price);
  }, []);

  const PaymentCondition = () => {
    return (
      <>
        <Col md={8} xs={24} key="paymentCondition">
          <FlexGapContainer>
            <Label style={{ transition: 'visibility 0.3s' }}>Condições de pagamento*</Label>
          </FlexGapContainer>
          <Select
            id="select-installments"
            value={installmentCount || 1}
            onChange={val => {
              setField('installmentCount')(val);
              setField('installmentPrice')(installmentCountAndPrice.find(i => i.value === val)?.price);
            }}
            options={installmentCountAndPrice}
          />
          {touched.installmentPrice && errors?.installmentPrice && <Error>{errors?.installmentPrice}</Error>}
        </Col>

        <Col md={8} xs={24} key="dueDate">
          <FlexGapContainer>
            <Label style={{ transition: 'visibility 0.3s' }}>
              {`Vencimento${installmentCount > 1 ? ' da 1ª parcela' : ''}*`}
            </Label>
          </FlexGapContainer>
          <DatePicker
            id="vpDueDate"
            name="billingDate"
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            value={dueDate ? dayjs(dueDate) : dayjs()}
            allowClear={false}
            onChange={date => setField('dueDate')(date?.format('YYYY-MM-DD'))}
            onBlur={handleBlur('dueDate')}
          />

          {touched.dueDate && errors?.dueDate && <Error>{errors?.dueDate}</Error>}
        </Col>
      </>
    );
  };

  const RenderCards = () => {
    return (
      <Space style={{ width: '100%' }} size="middle" direction="vertical">
        <CardPaymentType
          id="pixCard"
          value={installmentCountAndPrice?.find(data => data?.value === installmentCount)?.price || total}
          paymentTypes={paymentTypes}
          installmentCount={installmentCount}
          onChange={changePaymentType}
          type="pix"
          fees={fees}
        />
        <CardPaymentType
          id="bankSlipCard"
          value={installmentCountAndPrice?.find(data => data?.value === installmentCount)?.price || total}
          paymentTypes={paymentTypes}
          installmentCount={installmentCount}
          onChange={changePaymentType}
          type="bankSlip"
          fees={fees}
        />
        <CardPaymentType
          id="creditCard"
          value={installmentCountAndPrice?.find(data => data?.value === installmentCount)?.price || total}
          paymentTypes={paymentTypes}
          onChange={changePaymentType}
          installmentCount={installmentCount}
          type="creditCard"
          fees={fees}
          total={total}
        />

        {touched.paymentTypes && errors?.paymentTypes && <Error>{errors?.paymentTypes}</Error>}
      </Space>
    );
  };

  const RenderClientInput = () => {
    return (
      <>
        <Col md={8} xs={24} key="customerVobiPay">
          <FlexGapContainer>
            <Label style={{ transition: 'visibility 0.3s' }}>Cliente*</Label>
            {showEditCustomer && (
              <TooltipIcon
                style={{ color: colors.primary600 }}
                text="Editar informações do cliente"
                icon={faPenToSquare}
                onClick={() => setCustomerDrawer(true)}
              />
            )}
          </FlexGapContainer>
          {!showCustomerDrawer && (
            <Select
              name="paymentCompanyCustomer"
              id="paymentCompanyCustomer"
              model="companyCustomer"
              modelOptions={selectOpts}
              placeholder="Selecione o cliente"
              value={idCompanyCustomer}
              onChange={handleChangeCompanyCustomer}
              onBlur={handleBlur('idCompanyCustomer')}
              allowClear
              showSearch
              allowCreate
            />
          )}
          {touched.idCompanyCustomer && errors?.idCompanyCustomer && <Error>{errors?.idCompanyCustomer}</Error>}
        </Col>
        <Col md={8} xs={24} key="personType">
          <FlexGapContainer>
            <Label style={{ transition: 'visibility 0.3s' }}>Tipo*</Label>
            {showEditCustomer && (
              <TooltipIcon
                style={{ color: colors.primary600 }}
                text="Editar informações do cliente"
                icon={faPenToSquare}
                onClick={() => setCustomerDrawer(true)}
              />
            )}
          </FlexGapContainer>
          <Segment options={typeItems} value={customerPersonType} onChange={setField('customerPersonType')} />
        </Col>
      </>
    );
  };

  const RenderCustomerInputs = () => {
    return (
      <>
        <Col md={8} xs={24} key="customerDocs">
          <FlexGapContainer>
            <Label style={{ transition: 'visibility 0.3s' }}>CPF/CNPJ*</Label>
          </FlexGapContainer>
          <NewMaskedInput
            type={customerPersonType === 'pj' ? 'cnpj' : 'cpf'}
            id="customerDocument"
            size="middle"
            name="customerDocument"
            value={customerDoc || ''}
            placeholder="Informe o seu CPF ou CNPJ"
            onChange={handleChange('customerDoc')}
            onBlur={handleBlur('customerDoc')}
          />
          {touched.customerDoc && errors?.customerDoc && <Error>{errors?.customerDoc}</Error>}
        </Col>
        <Col md={8} xs={24} key="emailInput">
          <TooltipDiv>
            <Label style={{ transition: 'visibility 0.3s' }}>Email{billingChannels?.email && '*'}</Label>
            <TooltipIcon
              icon={faInfoCircle}
              text={`O email será utilizado para notificar seu cliente na
            criação, alterações, vencimento e pagamento da cobrança`}
              iconColor={colors.primary500}
              style={{ padding: `0 ${spaces.space1}` }}
            />
          </TooltipDiv>
          <Input
            type="text"
            id="customerEmail"
            size="middle"
            value={customerEmail}
            name="customerEmail"
            placeholder="Ex: jose@vobi.com.br"
            onChange={handleChange('customerEmail')}
            onBlur={handleBlur('customerEmail')}
          />
          {touched.customerEmail && errors?.customerEmail && <Error>{errors?.customerEmail}</Error>}
        </Col>
        <Col md={8} xs={24} key="phoneInput">
          <FlexGapContainer>
            <Label style={{ transition: 'visibility 0.3s' }}>
              Celular{(billingChannels?.sms || billingChannels?.whatsapp) && '*'}
            </Label>
          </FlexGapContainer>
          <NewMaskedInput
            type="phone"
            id="customerPhone"
            value={customerPhone}
            size="middle"
            name="customerPhone"
            placeholder="(19) 99999-9999"
            onChange={handleChange('customerPhone')}
            onBlur={handleBlur('customerPhone')}
          />
          {touched.customerPhone && errors?.customerPhone && <Error>{errors?.customerPhone}</Error>}
        </Col>
      </>
    );
  };

  return loading ? (
    <CenteredLoader />
  ) : (
    <>
      <Content>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Subtitle type="secondary">Como seu cliente poderá pagar?</Subtitle>
          <Row gutter={[16, 16]}>{PaymentCondition()}</Row>
        </Space>
        {RenderCards()}
      </Content>
      <Line />
      <Content>
        <BillingManagerContent billingChannels={billingChannels} setField={setField} setNoCredit={setNoCredit} />
      </Content>
      <Line />
      <Content>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Subtitle type="secondary">Quem você vai cobrar?</Subtitle>
          <Row gutter={[16, 16]}>{RenderClientInput()}</Row>
          <Row gutter={[16, 16]}>{RenderCustomerInputs()}</Row>
        </Space>
      </Content>
    </>
  );
};

export default PaymentVobiPay;
