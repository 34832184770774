import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import { compare } from 'compare-versions';
import { useLocation } from 'react-router-dom';
import Modal from '../../../components/Modal/Modal';
import packageInfo from '../../../../package.json';

const currentVersion = packageInfo.version;

function CacheBuster({ children = null, isEnabled = false, isVerboseMode = false, loadingComponent = null }) {
  const location = useLocation();
  const [lastCheck, setLastCheck] = useState(null);
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false
  });

  const log = (message, isError) => {
    if (!isVerboseMode) return;
    // eslint-disable-next-line no-console
    if (isError) console.error(message);
    else console.log(message); // eslint-disable-line no-console
  };

  const isThereNewVersion = (liveVersion, localVersion) => {
    return compare(liveVersion, localVersion, '>');
  };

  const checkCacheStatus = async () => {
    try {
      const res = await fetch(`${window.location.origin}/meta.json?d=${new Date().getTime()}`, { cache: 'no-cache' });
      const { version: liveVersion } = await res.json();
      const shouldForceRefresh = isThereNewVersion(liveVersion, currentVersion);
      if (shouldForceRefresh) {
        log(`There is a new version (v${liveVersion}). Should force refresh.`);
        setCacheStatus({
          loading: false,
          isLatestVersion: false
        });
      } else {
        log('There is no new version. No cache refresh needed.');
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
      }
    } catch (error) {
      log('An error occurred while checking cache status.', true);
      log(error, true);

      // Since there is an error,
      // if isVerboseMode is false, the component is configured as if it has the latest version.
      !isVerboseMode &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
    }
  };

  useEffect(() => {
    if (!isEnabled) {
      log('CacheBuster disabled');
      return;
    }

    const now = dayjs();
    const diff = now.diff(lastCheck, 'minute');
    if (!lastCheck || diff > 5) {
      setLastCheck(now);
      checkCacheStatus();
    }
  }, [location]);

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map(n => caches.delete(n));

        await Promise.all(cacheDeletionPromises);

        log('The cache has been deleted.');
        window.location.reload(true);
      }
    } catch (error) {
      log('An error occurred while deleting the cache.', true);
      log(error, true);
    }
  };

  if (!isEnabled) {
    return children;
  }

  if (cacheStatus.loading) {
    return loadingComponent;
  }

  if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
    return (
      <>
        {children}
        <Modal
          title="Nova atualização"
          submitText="Atualizar"
          onSubmit={refreshCacheAndReload}
          closable={false}
          open
          hideCancel
          width={832}
        >
          <span>Uma nova atualização do site está disponível. Clique no botão para recarregar a página.</span>
        </Modal>
      </>
    );
  }

  return children;
}

CacheBuster.propTypes = {
  children: PropTypes.element.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isVerboseMode: PropTypes.bool,
  loadingComponent: PropTypes.element
};

export default CacheBuster;
