import React, { useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { useContextHook } from '../../contexts/GeneralContext';
import { columns } from '../../lib/mapping/TableOrList/paymentInvoiceColumns';
import { TableWrapper } from './PaymentInvoiceList.styles';
import { Div, colors, fonts, spaces } from '../../styles/style';
import { Paragraph, Subtitle } from '../Text/Text';
import Button from '../Button/Button';
import TooltipIcon from '../Tooltip/TooltipIcon';
import SimpleTable from '../Table/SimpleTable';
import Drawer from '../Drawer/Drawer';
import InvoicePage from '../../_Pages/Payments/invoicePage';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import useCRUD from '../../_Hooks/useCRUD';
import ImportInvoiceXMLModal from '../Modal/ImportInvoiceXMLModal';
import { Content } from '../../_Pages/Payments/Payment.style';

const PaymentInvoiceList = () => {
  const { handleCreate } = useCRUD({ model: 'payment', immediatelyLoadData: false });
  const { values, isView, setField, isCreate, installmentView, billType, isCharge } = useContextHook();
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState(null);
  const [invoicesToCreate, setInvoicesToCreate] = useState(null);

  const handleChange = ({ id, field, value }) => {
    const _invoices = [...values?.invoices];
    const index = _invoices.findIndex(item => item.id === id);
    _invoices[index][field] = value;
    setField('invoices')(_invoices);
  };

  const getTooltipText = () => {
    if (isCharge) return 'Adicione a nota fiscal no pagamento para visualizar nas parcelas.';
    if (billType === 'income') return 'Adicione a nota fiscal na receita para visualizar nas parcelas.';
    return 'Adicione a nota fiscal na despesa para visualizar nas parcelas.';
  };
  const handleNewInvoice = () => {
    const _invoices = [...(values?.invoices ?? []), { id: uuidV4(), number: null, date: null, new: true }];
    setField('invoices')(_invoices);
  };

  const handleRemove = id => {
    const _invoices = [...values?.invoices];
    const index = _invoices.findIndex(item => item.id === id);
    if (_invoices[index].new) _invoices.splice(index, 1);
    else _invoices[index].deleted = true;
    setField('invoices')(_invoices);
  };

  const handleOpenXML = row => {
    setInvoiceSelected(row);
  };
  const _columns = columns({ isView, handleChange, handleRemove, handleOpenXML });

  const attachFile = e => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file?.name);
    formData.append('type', 'invoiceListImport');
    setIsImporting(true);
    handleCreate({ values: formData, postPathOptions: '/nfe', refresh: false, customCatch: true })
      .then(resp => {
        const { invoices } = resp.payment;
        const invoicesFromField = values?.invoices ?? [];
        const _invoices = [...invoicesFromField, ...invoices];
        const alreadyImported = invoicesFromField.find(item => item.accessKey === invoices[0].accessKey);
        if (resp?.error || alreadyImported) {
          setError(
            resp?.error || {
              code: 'API-B-00222',
              extraInfo: {
                invoiceKey: alreadyImported.accessKey,
                serie: alreadyImported?.number
              }
            }
          );
          setInvoicesToCreate(_invoices);
        } else {
          setField('invoices')(_invoices);
        }
      })
      .catch(err => {
        const { data } = err?.response || {};
        setError(data);
      })
      .finally(() => {
        setIsImporting(false);
      });
  };

  const handleImportClick = () => {
    document.getElementById('fileInput').value = null;
    document.getElementById('fileInput').click();
  };

  const Table = useMemo(() => {
    const _invoices = values?.invoices?.filter(item => !item.deleted);
    return _invoices?.length > 0 ? (
      <TableWrapper>
        <SimpleTable columns={_columns} pagination={false} data={_invoices} isExpandable={false} />
      </TableWrapper>
    ) : (
      <Div>
        <Paragraph>
          {!isView
            ? 'Adicione a nota fiscal manualmente ou importe um XML da NF-e para extrair os dados automaticamente.'
            : 'Nenhuma nota fiscal adicionada.'}
        </Paragraph>
      </Div>
    );
  }, [values?.invoices, isView]);

  return (
    <>
      {isView && !installmentView ? (
        <SimpleAccordion
          title="Notas Fiscais"
          initOpen={isCreate || values?.invoices?.length > 0}
          id="payment-attachments"
          withPaddingContainer
        >
          {Table}
        </SimpleAccordion>
      ) : (
        <Content padding={installmentView ? 0 : null}>
          {installmentView ? (
            <Div>
              <Subtitle>Notas Fiscais</Subtitle>
              <TooltipIcon
                text={getTooltipText()}
                icon={faInfoCircle}
                iconColor={colors.primary500}
                style={{ marginLeft: spaces.space1 }}
              />
            </Div>
          ) : (
            <Subtitle>Notas Fiscais</Subtitle>
          )}

          {Table}
          {!isView && (
            <Div gap={spaces.space2}>
              <Button type="primary" minWidth="120px" onClick={handleNewInvoice}>
                Adicionar
              </Button>
              <Button type="light" minWidth="120px" onClick={handleImportClick} loading={isImporting}>
                Importar XML
              </Button>
              <HowDoesItWorksButton id="howXMLWorks" style={{ padding: '0px' }} />
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={attachFile} accept=".xml" />
            </Div>
          )}
        </Content>
      )}
      {invoiceSelected && (
        <Drawer
          padding="0px"
          width={992}
          onClose={() => setInvoiceSelected(null)}
          headerStyle={{ fontSize: fonts.sizeLg, fontWeight: fonts.weight600, color: colors.neutral600 }}
          open
          title="Nota fiscal"
          subtitle={`Chave de acesso: ${invoiceSelected?.accessKey || ''}`}
          showSubmit={false}
          cancelText="Fechar"
        >
          <InvoicePage id={!invoiceSelected.new ? invoiceSelected.id : null} initialData={invoiceSelected} />
        </Drawer>
      )}
      {error && (
        <ImportInvoiceXMLModal
          onClose={() => setError(null)}
          initialError={error}
          onImportSuccess={() => {
            setField('invoices')(invoicesToCreate);
            setError(null);
          }}
        />
      )}
    </>
  );
};

export default PaymentInvoiceList;
