import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faCalendarClock } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Div, colors, spaces } from '../../styles/style';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Input from '../Input/Input';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { bankAccount as paymentBankAccount } from '../../lib/mapping/Form/extraFormSchema';
import Select from '../Select/Select';
import AddSelectButton from '../Button/AddSelectButton';
import DatePicker from '../Datepicker/Datepicker';
import ListDrawer from '../Drawer/ListDrawer';
import { Paragraph, Subtitle } from '../Text/Text';
import Label from '../Label/Label';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import useFormState from '../../_Hooks/useFormState';
import NewMaskedInput from '../Input/NewMaskedInput';
import FileUpload from '../File/FileUpload';
import AttachmentList from '../List/AttachmentList';
import Button from '../Button/Button';
import FinanceEdit from '../Drawer/FinanceEdit';

const RegisterPaymentContent = ({ installment, paymentValidOrBill, changeValues, countRowKeys, isBulk }) => {
  const { formState, handleChange, setField } = useFormState({
    bankAccount: installment?.idPaymentBankAccount,
    paymentType: installment?.payment?.paymentTypes?.[0]?.id || 1,
    date: dayjs(),
    price: (installment?.splitInstallmentId ? installment?.totalSplitPrice : installment?.price) || 0,
    interest: (installment?.splitInstallmentId ? installment?.totalSplitInterest : installment?.interest) || 0,
    fine: (installment?.splitInstallmentId ? installment?.totalSplitFine : installment?.fine) || 0,
    discount: (installment?.splitInstallmentId ? installment?.totalSplitDiscount : installment?.discount) || 0,
    files: installment?.files
  });

  const { values } = formState || {};
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [total, setTotal] = useState(values?.price);
  const [files, setFiles] = useState(installment?.files || []);
  const [createBankAccount, setCreateBankAccount] = useState(false);
  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
  };

  useEffect(() => {
    setField('files')(files);
  }, [files]);

  useEffect(() => {
    changeValues(values);
    setTotal(
      Number(values?.price) + Number(values?.interest || 0) + Number(values?.fine || 0) - Number(values?.discount || 0)
    );
  }, [values]);

  return (
    <Div direction="column" gap={spaces.space2} $maxHeight="500px">
      <Div direction={countRowKeys ? 'column' : 'row'} $fullWidth gap={spaces.space1} wrap="wrap">
        {countRowKeys ? (
          <Paragraph color={colors.neutral600}>
            {`Confirme para marcar as
      ${countRowKeys} parcelas como pagas com as informações a seguir:`}
          </Paragraph>
        ) : (
          <Div direction="column" align="start" {...(countRowKeys ? { width: '100%' } : { flex: '45%' })}>
            <Label>Valor da parcela</Label>
            <Input disabled value={formatCurrency(values?.price, { currencySymbol: 'R$' })} />
          </Div>
        )}
        {paymentValidOrBill && !createBankAccount && (
          <Div direction="column" align="start" {...(countRowKeys ? { width: '100%' } : { flex: '45%' })}>
            <Div gap={spaces.space0}>
              <Label>
                Conta{' '}
                <TooltipIcon
                  tooltipProps={{ overlayStyle: { minWidth: '270px' } }}
                  icon={faInfoCircle}
                  iconColor={colors.primary600}
                  text="Você pode adicionar uma conta bancária ou informar que a transação saiu do caixa do negócio."
                  size="sm"
                  trigger={['hover', 'click']}
                />
              </Label>
            </Div>
            {!showBankAccount && (
              <Select
                name="bankAccount"
                id="paymentBankAccount"
                model="bankAccount"
                modelOptions={{
                  where: { isVobiPay: false, isFinancialEntry: true },
                  order: [['name', 'asc']]
                }}
                placeholder="Selecione a conta"
                value={values?.bankAccount}
                onChange={setField('bankAccount')}
                allowClear
                openCustomCreate={setCreateBankAccount}
                showSearch
                allowCreate
                dropdownRender={menu => (
                  <>
                    {menu}
                    <AddSelectButton
                      onClick={() => window.open('/profissional/cadastro-financeiro/contas', '_blank')}
                    />
                  </>
                )}
                createParams={{ isActive: true, isFinancialEntry: true }}
              />
            )}
          </Div>
        )}
        <Div direction="column" align="start" {...(countRowKeys ? { width: '100%' } : { flex: '45%' })}>
          <Label>Forma de pagamento</Label>
          <Select
            value={values?.paymentType}
            model="paymentType"
            onChange={setField('paymentType')}
            modelOptions={{
              where: { id: { ne: '0' }, isActive: true },
              order: [['name', 'asc']]
            }}
          />
        </Div>
        <Div direction="column" align="start" {...(countRowKeys ? { width: '100%' } : { flex: '45%' })}>
          <Label>Data de pagamento</Label>
          <Div gap={spaces.space0} $fullWidth>
            <DatePicker
              $width="100%"
              value={values?.date}
              format="DD/MM/YYYY"
              placeholderText={dayjs().format('DD/MM/YYYY')}
              onChange={setField('date')}
              id="dateOfPayment"
              allowClear={false}
            />
            {!isBulk && (
              <Tooltip placement="top" title="Preencher com data de vencimento">
                <Button type="outline" onClick={() => setField('date')(dayjs(installment?.dueDate))}>
                  <FontAwesomeIcon icon={faCalendarClock} size="lg" color={colors.primary600} />
                </Button>
              </Tooltip>
            )}
          </Div>
        </Div>
      </Div>
      {!countRowKeys && (
        <>
          <SimpleAccordion title="Juros, multa e desconto" size="md">
            <Div $fullWidth gap={spaces.space1} wrap="wrap">
              <Div direction="column" align="start" flex="45%">
                <Label>Juros</Label>
                <NewMaskedInput
                  id="interest"
                  name="interest"
                  type="currency"
                  onChange={handleChange('interest')}
                  value={values?.interest}
                  placeholder="R$ 0,00"
                />
              </Div>
              <Div direction="column" align="start" flex="45%">
                <Label>Multa</Label>
                <NewMaskedInput
                  id="fine"
                  name="fine"
                  type="currency"
                  onChange={handleChange('fine')}
                  value={values?.fine}
                  placeholder="R$ 0,00"
                />
              </Div>
              <Div direction="column" align="start" flex="45%">
                <Label>Desconto</Label>
                <NewMaskedInput
                  id="discount"
                  name="discount"
                  type="currency"
                  onChange={handleChange('discount')}
                  value={values?.discount}
                  placeholder="R$ 0,00"
                />
              </Div>
              <Div direction="column" align="start" flex="45%">
                <Label>Total</Label>
                <Subtitle id="totalAmount" color={colors.neutral600}>
                  {formatCurrency(total, { currencySymbol: 'R$' })}
                </Subtitle>
              </Div>
            </Div>
          </SimpleAccordion>
          <SimpleAccordion title="Anexo da parcela" size="md">
            <Div direction="column" align="start" $fullWidth gap={spaces.space1}>
              <FileUpload
                style={{ marginTop: 0, marginLeft: `-${spaces.space1}` }}
                id="file-input"
                onChange={handleAddFile}
                multiple
                showGallery={false}
                text="Adicionar anexo"
                listType={null}
                buttonProps={{ type: 'primary' }}
                fullWidth
              />
              <AttachmentList
                preventUpload
                files={files}
                setFiles={setFiles}
                enumsName="systemData"
                style={{ width: '100%' }}
              />
            </Div>
          </SimpleAccordion>
        </>
      )}
      {showBankAccount && (
        <ListDrawer
          onClose={() => setShowBankAccount(false)}
          model="bankAccount"
          where={{ isActive: true, isVobiPay: [null, false], isFinancialEntry: true }}
          tab="bankAccount"
          title="Configuração das contas"
          subtitle="Conta"
          renderTabs={paymentBankAccount}
          typeText="conta"
        />
      )}
      {createBankAccount && (
        <FinanceEdit
          onClose={(_, idBankAccount) => {
            setCreateBankAccount(false);
            setField('bankAccount')(idBankAccount);
          }}
          model="bankAccount"
          name={createBankAccount}
        />
      )}
    </Div>
  );
};

RegisterPaymentContent.propTypes = {
  installment: PropTypes.instanceOf(Object),
  paymentValidOrBill: PropTypes.bool,
  changeValues: PropTypes.func,
  countRowKeys: PropTypes.number,
  isBulk: PropTypes.number
};

export default RegisterPaymentContent;
