import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { name, isActive, date, text } from '../schema';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import { Div, colors, fonts, spaces } from '../../../styles/style';
import formatNumber from '../../helpers/formatNumber';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';

const bankAccountSchema = Yup.object().shape({
  name,
  isFinancialEntry: isActive,
  initialBalance: text,
  initialBalanceDate: date.when('initialBalance', (value, schema) => {
    return formatNumber(value) ? schema.required('Campo obrigatório') : schema;
  }),
  document: text,
  holderName: text,
  accountType: text,
  agency: text,
  account: text
});

const typeItems = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' }
];

const DivTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.spaces2};
`;

const bankAccountMapping = ({ isMobile, isPj, name: _name }) => {
  return {
    name: {
      name: 'Descrição*',
      type: 'text',
      placeholder: 'Informe a descrição',
      defaultValue: _name,
      xs: 24,
      md: 12
    },
    isFinancialEntry: {
      type: 'custom',
      xs: 24,
      md: 12,
      defaultValue: true,
      // eslint-disable-next-line react/prop-types
      Component: ({ setField, value }) => (
        <Div gap={spaces.space1} style={{ paddingTop: '20px' }} align="baseline">
          <Checkbox checked={value} onChange={e => setField(e.target.checked)} />
          <Div gap={spaces.space1}>
            <Paragraph type="small">Utilizar para lançamentos financeiros</Paragraph>
            <TooltipIcon
              text={`Ao ativar essa opção, você poderá selecionar essa conta
      financeira ao lançar despesas e receitas no seu controle financeiro.`}
              icon={faInfoCircle}
              color={colors.primary500}
              tooltipProps={{ placement: 'bottom' }}
            />
          </Div>
        </Div>
      )
    },
    initialBalance: {
      name: 'Saldo inicial',
      type: 'currency',
      placeholder: 'R$ 0,00',
      defaultValue: '0,00',
      parseFunc: val => formatNumber(val || 0),
      xs: 24,
      md: 12,
      tooltipText: (
        <DivTooltip>
          <Subtitle type="secondary" weight={fonts.weight400} color={colors.white}>
            Informe o saldo da conta no dia anterior ao início dos lançamentos na Vobi. A partir desse valor, todos os
            seus relatórios serão calculados.
          </Subtitle>
          <Subtitle type="secondary" weight={fonts.weight400} color={colors.white}>
            Por exemplo: para controle financeiro a partir de janeiro, informe o saldo no último dia de dezembro.
          </Subtitle>
        </DivTooltip>
      ),
      ...(!isMobile && { overlayStyle: { minWidth: '320px' } })
    },
    initialBalanceDate: {
      name: 'Data do saldo inicial',
      type: 'date',
      format: 'DD/MM/YYYY',
      allowClear: true,
      xs: 24,
      md: 12
    },
    personType: {
      name: 'Tipo*',
      type: 'radioSwitch',
      items: typeItems,
      defaultValue: 'pf',
      xs: 24,
      md: 12
    },
    document: {
      name: isPj ? 'CNPJ' : 'CPF',
      type: isPj ? 'cnpj' : 'cpf',
      placeholder: isPj ? 'Ex: 00.000.000/0000-01' : 'Ex: 000.000.000-00',
      xs: 24,
      md: 12
    },
    holderName: {
      name: 'Nome completo do titular',
      type: 'text',
      xs: 24,
      md: 12
    },
    idBankInstitution: {
      name: 'Informe seu banco',
      type: 'select',
      xs: 24,
      md: 12,
      model: 'bankInstitution',
      modelOptions: { order: ['order'] }
    },
    accountType: {
      name: 'Qual o tipo da sua conta?',
      type: 'select',
      xs: 24,
      md: 12,
      dataType: 'accountType'
    },
    agency: {
      name: 'Agência',
      type: 'text',
      xs: 24,
      placeholder: 'Informe a agência',
      md: 12
    },
    account: {
      name: 'Conta',
      type: 'text',
      placeholder: 'Informe a conta',
      xs: 24,
      md: 12
    },
    warningVobiPay: {
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => (
        <Subtitle type="secondary" weightStrong={fonts.weight700} weight={fonts.weight400}>
          <strong>Atenção:</strong> Para utilizar a conta em transferências via Vobi Pay, preencha os dados com mesma
          titularidade da sua Vobi Pay.
        </Subtitle>
      )
    }
  };
};

export { bankAccountSchema, bankAccountMapping };
