import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors, spaces } from '../../styles/style';

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutral75};
`;

const IconCompositionButton = styled(FontAwesomeIcon)`
  padding-right: ${spaces.space1};
`;
export { Line, IconCompositionButton };
