import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { Steps } from 'antd';
import Button from '../../components/Button/Button';

import { Div, breakpoints, colors, fonts, radius, spaces } from '../../styles/style';

export const Container = styled.div`
  margin: ${props => (props?.$noMargin ? 0 : spaces.space2)};
  border-radius: ${radius.radius1};
  @media (max-width: ${breakpoints.tablet}) {
    margin: 0;
    border-radius: 0;
    padding: ${props => (props?.padding ? props?.padding : 0)};
    border-top: 1px solid ${colors.neutral75};
  }
`;

export const Content = styled(Div)`
  flex-direction: column;
  width: 100%;
  padding: ${props => (props?.padding || props?.padding === 0 ? props?.padding : spaces.space2)};
  gap: ${spaces.space2};
  align-items: flex-start;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fonts.sizeLg};
  border-bottom: 1px solid ${colors.neutral75};
  padding: ${spaces.space1};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftHeaderContent = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
  }
`;
export const RightHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const StatusContainer = styled.div`
  color: ${colors.neutral600};
  font-size: ${fonts.sizeMd};
  margin-right: ${props => (props.$withMarginRight ? spaces.space3 : '0px')};
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const Info = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary100};
  font-size: ${fonts.sizeSm};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.primary700};
  span {
    margin-left: ${spaces.space2};
    font-size: ${fonts.sizeSm};
  }
  svg {
    color: ${colors.primary400};
  }
  padding: ${spaces.space2} ${spaces.space1};

  @media (max-width: ${breakpoints.mobile}) {
    svg {
      margin-left: 20px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spaces.space2};
  height: ${spaces.space8};
  font-size: ${fonts.sizeLg};
  border-top: 1px solid ${colors.neutral75};

  .withMarginRight {
    margin-right: ${spaces.space4};
  }

  @media (max-width: ${breakpoints.mobile}) {
    .withMarginRight {
      margin-right: ${spaces.space2};
    }
  }
`;

export const ShowModalEmptyPayment = styled.div`
  background-color: ${colors.black};
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const EmptyText = styled(Text)`
  font-size: ${fonts.sizeH6};
  font-weight: bold;
`;

export const ExitCustomerViewButton = styled(Button)`
  background-color: ${colors.primary100};
  color: ${colors.primary700};
  left: 0px;
`;

export const DrawerTitleContainer = styled.div`
  display: flex;
  gap: ${spaces.space1};
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.space2};
`;

export const BoxIcon = styled(Div)`
  height: ${spaces.space2};
  width: ${spaces.space2};
  background-color: ${colors.neutral100};
  color: ${colors.neutral400};
  margin-left: ${props => (props.$noMargin ? 'none' : spaces.space2)};

  border-radius: 2px;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space1};
`;

export const IconLock = styled.div`
  display: flex;
  height: ${spaces.space4};
  width: ${spaces.space4};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Icon = styled.div`
  display: flex;
  width: ${spaces.space10};
  height: ${spaces.space8};
  background-color: ${props => (props.disabled ? colors.neutral200 : colors.primary50)};
  border-radius: ${spaces.space0};
  align-items: center;
  justify-content: center;
`;

export const OrderExtraValueContainer = styled.div`
  width: 400px;
  margin: 0 0 ${spaces.space3} 0;
  align-self: flex-end;

  @media (max-width: ${breakpoints.tablet}) {
    margin: 0;
    width: 100%;
  }
`;

export const PaymentSteps = styled(Steps)`
  padding: ${spaces.space2};
  border-bottom: 1px solid ${colors.neutral100};
  background-color: ${colors.neutral50};
  .ant-steps-item-finish {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: ${colors.neutral500};
        ::after {
          background-color: ${colors.neutral200};
        }
      }
    }

    .ant-steps-item-icon {
      background-color: ${colors.green200};
      border: none;
      svg {
        color: ${colors.white};
      }
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: ${colors.neutral600};
        ::after {
          background-color: ${colors.neutral100};
        }
      }
    }
    .ant-steps-item-icon {
      background-color: ${colors.primary400};
      border: none;
      svg {
        color: ${colors.white};
      }
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: ${colors.neutral100};
      border: none;
      svg {
        color: ${colors.neutral500};
      }
    }
  }
`;

export const MobilePaymentSteps = styled(Div)`
  position: sticky;
  z-index: 1;
  top: 0;
  padding: ${spaces.space3} ${spaces.space2};
  justify-content: space-between;
  background-color: ${colors.neutral50};
  border-bottom: 1px solid ${colors.neutral100};
`;

export const TableContainer = styled.div`
  .checkCircle {
    display: none;
    &:hover {
      color: ${colors.green300};
    }
  }
`;
