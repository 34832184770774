import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import Modal from './Modal';
import Form from '../Form/Form';
import {
  schema,
  templateMapping,
  applyTemplateMapping,
  schemaApplyTemplate
} from '../../lib/mapping/Form/templateSchema';
import { projectSchema, projectMapping } from '../../lib/mapping/Form/projectSchema';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { colors, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const { Text } = Typography;

const UseProjectAsTemplateModal = ({
  onClose,
  id,
  model,
  pathOptions,
  name,
  necessityDescription,
  modalType,
  title,
  text,
  module,
  category,
  type,
  image,
  successText,
  description,
  showTemplateModule,
  textHeader,
  source,
  template,
  afterSubmit,
  isOpportunity
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { userType, templateType, templateModule } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const isOperator = user?.userType === userType.operator.value;
  const [applyTimeout, setApplyTimeout] = useState(false);

  const { handleCreate, loading } = useCRUD({
    model,
    pathOptions,
    immediatelyLoadData: false
  });

  const itemName = modalType === 'Template' ? `Cópia de ${name}` : name;

  const onSubmit = values => {
    handleCreate({
      values: {
        ...values,
        idReference: id || values.idReference,
        module,
        image: image || values.image,
        source,
        idTemplate: template?.id
      },
      refresh: false,
      displayToast: successText,
      timeout: 8000
    }).then(resp => {
      if (resp?.error?.statusCode === 504) {
        setApplyTimeout(true);
        return;
      }

      if (afterSubmit) {
        afterSubmit(resp);
      }
      onClose();
    });
  };

  const defineSchema = () => {
    if (modalType === 'Projeto') {
      return projectSchema(true);
    }
    if (modalType === 'Template') {
      return schema(true, showTemplateModule);
    }
    return schemaApplyTemplate();
  };

  return (
    <Modal title={title} hideFooter open onClose={() => onClose(false)} width={1000} loading={loading}>
      {applyTimeout ? (
        <div>
          <Paragraph>O template ainda está sendo processado, assim que finalizar você será notificado</Paragraph>
        </div>
      ) : (
        <div>
          <Text>{textHeader}</Text>
          <Form
            style={{ marginTop: `${spaces.space1}`, borderTop: `1px solid ${colors.neutral100}` }}
            customButtonTitle={modalType === 'ApplyProject' ? 'Adicionar' : 'Confirmar'}
            id="templateForm"
            mapping={() => {
              if (modalType === 'Projeto') {
                return projectMapping({ isMobile: isMobile(), isModal: true, isOpportunity });
              }
              if (modalType === 'Template') {
                return templateMapping({
                  isMobile: isMobile(),
                  isModal: true,
                  showTemplateModule,
                  templateModule,
                  templateModuleOptions: templateModule,
                  templateTypeOptions: templateType,
                  isOperator,
                  isOpportunity
                });
              }
              return applyTemplateMapping(template?.version);
            }}
            schema={defineSchema()}
            onSubmit={onSubmit}
            data={{
              fieldsHeader: text,
              name: itemName,
              description: modalType === 'Template' ? description : necessityDescription,
              image,
              module,
              category,
              type
            }}
          />
        </div>
      )}
    </Modal>
  );
};

UseProjectAsTemplateModal.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  model: PropTypes.string,
  pathOptions: PropTypes.string,
  name: PropTypes.string,
  modalType: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  necessityDescription: PropTypes.string,
  module: PropTypes.string,
  category: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.instanceOf(Array),
  successText: PropTypes.string,
  description: PropTypes.string,
  showTemplateModule: PropTypes.bool,
  textHeader: PropTypes.string,
  source: PropTypes.string,
  template: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  isOpportunity: PropTypes.bool
};

export default UseProjectAsTemplateModal;
