import React from 'react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { text } from '../schema';
import Button from '../../../components/Button/Button';
import { Div, colors, spaces } from '../../../styles/style';
import { store } from '../../config/redux-store';
import { capitalize } from '../../helpers/helper';
import { Paragraph } from '../../../components/Text/Text';

const quoteSchema = () =>
  Yup.object().shape({
    priority: text
  });

const quoteMapping = ({ idCompany, canEditRefurbish }) => {
  const { setup } = store.getState();
  const { priority = {} } = setup.enums;

  return {
    idRefurbish: {
      id: 'quote-refurbish',
      name: 'Projeto',
      type: 'select',
      disabled: !canEditRefurbish,
      model: 'refurbish',
      modelOptions: {
        where: { idStatus: 5 },
        order: [['name', 'asc']]
      },
      offset: true,
      md: 5
    },
    name: {
      id: 'quote-name',
      name: 'Nome*',
      placeholder: 'Ex: Marcenaria',
      type: 'text',
      md: 5
    },
    priority: {
      id: 'quote-priority',
      type: 'select',
      name: 'Prioridade',
      allowClear: false,
      options: [
        {
          value: priority?.high?.id,
          label: capitalize(priority?.high?.name)
        },
        {
          value: priority?.average?.id,
          label: capitalize(priority?.average?.name)
        },
        {
          value: priority?.low?.id,
          label: capitalize(priority?.low?.name)
        }
      ],
      md: 4
    },
    idResponsible: {
      id: 'quote-responsible',
      name: 'Responsável',
      type: 'select',
      model: 'user',
      modelOptions: {
        where: { idCompany, isActive: true },
        order: [['name'], ['id']]
      },
      offset: true,
      md: 5
    },
    responseDate: {
      id: 'response-date',
      name: 'Prazo para resposta',
      type: 'date',
      format: 'DD/MM/YYYY',
      md: 5,
      tooltipText: 'Prazo para os fornecedores responderem o pedido de cotação'
    }
  };
};

const selectQuoteSuppliersColumns = ({ selectedQuoteSuppliersMap, onSelect, isMobile }) => [
  {
    title: 'Nome do fornecedor',
    dataIndex: 'name',
    key: 'name',
    width: 260,
    alwaysShow: true,
    mobileWidth: '100%',
    render: val => {
      return <Paragraph id="drawer-supplier-name">{val}</Paragraph>;
    }
  },
  {
    title: 'Categoria',
    dataIndex: 'idConstCenter',
    key: 'idConstCenter',
    width: 210,
    renderCollapseIcon: true,
    mobileWidth: '100%',
    render: (val, row) => {
      const { costCenter } = row || {};

      return <Paragraph id="drawer-supplier-cost-center">{costCenter?.name || '-'}</Paragraph>;
    }
  },
  {
    title: 'Contato',
    dataIndex: 'contact',
    key: 'contact',
    width: 250,
    renderCollapseIcon: true,
    mobileWidth: '100%',
    render: (val, row) => {
      return (
        <Div align="flex-start" direction="column" gap={spaces.space1}>
          <Paragraph>{row?.email || 'Nenhum email cadastrado'}</Paragraph>
          <Paragraph>{row?.phone || 'Nenhum telefone cadastrado'}</Paragraph>
        </Div>
      );
    }
  },
  {
    title: !isMobile && 'Ação',
    key: 'action',
    width: 60,
    style: { textAlign: 'right' },
    inlineMobile: true,
    inlinePadding: '0px',
    render: (val, row, index) => {
      const selected = selectedQuoteSuppliersMap[row?.id];

      return (
        <Button
          id={`add-quote-supplier${selected ? '-selected' : ''}-${index + 1}`}
          shape="circle"
          type="primary"
          roundMaxHeight={spaces.space3}
          text
          onClick={e => {
            e.stopPropagation();
            onSelect({ ...row }, selected);
          }}
          padding={isMobile ? `${spaces.space1} 0 0 0` : undefined}
        >
          <FontAwesomeIcon
            icon={selected ? faCircleCheck : faCirclePlus}
            size={isMobile ? '2x' : 'lg'}
            style={{ color: colors.primary600 }}
          />
        </Button>
      );
    }
  }
];

export { quoteSchema, quoteMapping, selectQuoteSuppliersColumns };
