import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from '../Card/Card';
import { Paragraph } from '../Text/Text';
import { DynamicColumn } from '../List/MobileCardItemV2.style';
import { useContextHook } from '../../contexts/GeneralContext';
import { columns as purchaseOverviewColumns } from '../../lib/mapping/TableOrList/purchaseOverviewColumns';
import { Div, colors, fonts, spaces } from '../../styles/style';

const PurchaseOverviewFooter = ({ item, childrenColumnName = 'children' }) => {
  const { handleExpandRow, seeMoreRows } = useContextHook();

  const [showChildren, setShowChildren] = useState(false);

  const _columns = purchaseOverviewColumns({ isMobile: true, seeMoreRows, isAppropriation: true });

  return (
    <>
      <Div justify="space-between" align="center" padding={`${spaces.space1} ${spaces.space2}`}>
        <Div
          gap={spaces.space0}
          role="presentation"
          align="center"
          onClick={() => {
            handleExpandRow(item);
            setShowChildren(!showChildren);
          }}
        >
          <Paragraph type="small" color={colors.primary600} fontWeight={fonts.weight500}>
            {showChildren ? 'Fechar apropriações' : 'Ver apropriações'}
          </Paragraph>
          <FontAwesomeIcon icon={!showChildren ? faChevronDown : faChevronUp} color={colors.primary500} />
        </Div>
      </Div>
      {showChildren && item?.[childrenColumnName]?.length > 0 && (
        <>
          {item?.[childrenColumnName]?.map((child, index) => (
            <Card
              borderRadius="0px"
              direction="column"
              justify="space-between"
              align="center"
              borderOnly="top"
              bodyBackgroundColor={colors.neutral75}
            >
              <Div wrap="wrap" gap={spaces.space1} justify="space-between">
                {_columns.map(col =>
                  seeMoreRows && !seeMoreRows[item?.id] && col.showOnlyWithSeeMore ? null : (
                    <DynamicColumn $mobileWidth={col.mobileWidth} $minHeight={col.inlineMobile}>
                      <Div direction="column" align="flex-start" gap={spaces.space1}>
                        <Paragraph type="small">{col.title}</Paragraph>
                        {col.render ? (
                          col.render(child[col.dataIndex], child, index)
                        ) : (
                          <div>{child[col.dataIndex]}</div>
                        )}
                      </Div>
                    </DynamicColumn>
                  )
                )}
              </Div>
            </Card>
          ))}
        </>
      )}
    </>
  );
};

PurchaseOverviewFooter.propTypes = {
  item: PropTypes.instanceOf(Object),
  childrenColumnName: PropTypes.string
};

export default PurchaseOverviewFooter;
