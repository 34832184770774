import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { Paragraph, Subtitle } from '../Text/Text';
import { ContentDiv, ContentMethod, Img, Pix, ResponsibleDescription } from './VobiPayPayment.styles';
import vobiPaySecurity from '../Images/vobiPaySecurity.svg';
import { Div, spaces } from '../../styles/style';
import { ShareLink } from '../Drawer/DrawerRegister.styles';
import useViewport from '../../_Hooks/useViewport';
import { formatDocument } from '../../lib/helpers/helper';
import CopyLinkButton from '../Button/CopyLinkButton';

const MethodPix = ({ installmentToPay = {}, company }) => {
  const { pix } = installmentToPay;
  const { isMobile } = useViewport(window.innerWidth);

  const RenderCode = (
    <>
      <Div gap={spaces.space1} style={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Paragraph>Código</Paragraph>
        <ShareLink $pixStyle>{pix?.payload}</ShareLink>
      </Div>
      <div>
        <CopyLinkButton
          type="primary"
          id="copy-code-button"
          text
          customEvent="activity-code-link"
          customUrl={pix?.payload}
        >
          <FontAwesomeIcon icon={faCopy} /> Copiar código
        </CopyLinkButton>
      </div>
    </>
  );

  const RenderDescription = (
    <div style={{ maxWidth: 736, marginTop: 8 }}>
      <ResponsibleDescription>
        Esta cobrança é de responsabilidade única e exclusiva de {company?.legalName} com{' '}
        <strong>CNPJ: {formatDocument(company?.cnpj)}</strong> e a Vobi não se responsabiliza pelo produto ou serviço
        prestado, em caso de dúvida entre em contato com seu fornecedor.
      </ResponsibleDescription>
    </div>
  );

  return (
    <ContentMethod>
      <Subtitle type="secondary">
        <strong>Pague com o PIX:</strong>
      </Subtitle>
      <Div gap={spaces.space3} style={{ alignItems: 'flex-start' }}>
        {pix?.encodedImage && <Pix className="pix" src={`data:image/png;base64,${pix?.encodedImage}`} alt="pix" />}
        <div>
          <ContentDiv>
            <div style={{ maxWidth: 416 }}>
              <Paragraph type="small">
                Acesse o seu APP de pagamentos e faça a leitura do <b>QR Code</b> ao lado para efetuar o pagamento de
                <b style={{ paddingLeft: 2 }}>forma rápida e segura.</b>
              </Paragraph>
            </div>

            <Div gap={spaces.space2} style={{ alignItems: 'flex-end' }}>
              {!isMobile() && pix?.payload && RenderCode}
              <Img src={vobiPaySecurity} alt="vobiPaySecurity" />
            </Div>
          </ContentDiv>
          {!isMobile() && RenderDescription}
        </div>
      </Div>
      {isMobile() && pix?.payload && RenderCode}
      {isMobile() && RenderDescription}
    </ContentMethod>
  );
};

MethodPix.propTypes = {
  installmentToPay: PropTypes.instanceOf(Object),
  company: PropTypes.instanceOf(Object)
};

export default MethodPix;
