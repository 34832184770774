import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { fonts, spaces } from '../../styles/style';

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.space2};
  padding-top: ${props => props.$paddingTop};
  ${props => props.$containerMargin && `margin: ${props.$containerMargin};`}

  span {
    font-size: ${fonts.sizeSm};
  }
`;

const CheckBox = styled(Checkbox)`
  ${props => !props.noMargin && `margin-bottom: ${spaces.space0};`}
`;

const CheckboxAutoCode = ({
  value,
  setCheckedValue = f => f,
  setField = f => f,
  paddingTop,
  noMargin,
  containerMargin
}) => {
  return (
    <Div $paddingTop={paddingTop} $containerMargin={containerMargin}>
      <CheckBox
        id="form-auto-code-checkbox"
        checked={value}
        onChange={e => {
          setCheckedValue(prev => !prev);
          setField(e.target.checked);
        }}
        noMargin={noMargin}
      />
      <span>Gerar código automático</span>
    </Div>
  );
};

CheckboxAutoCode.propTypes = {
  value: PropTypes.instanceOf(Object),
  setCheckedValue: PropTypes.func,
  setField: PropTypes.func,
  paddingTop: PropTypes.string,
  noMargin: PropTypes.bool,
  containerMargin: PropTypes.string
};

export default CheckboxAutoCode;
