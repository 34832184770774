import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';

// style
import css from './NoContent.module.scss';
import { Paragraph, Title } from '../Text/Text';
import { colors } from '../../styles/style';

const NoContent = ({
  icon,
  title,
  description = 'Adicione um novo item para vê-lo na lista',
  renderButton = true,
  buttonTitle = 'Adicionar novo',
  onClick,
  showButtonIcon = true,
  extraButton: ExtraButton,
  buttonId,
  style
}) => {
  const history = useHistory();
  const { pathname } = history.location;

  const handleClick = () => {
    if (onClick) {
      return onClick();
    }
    return history.push(`${pathname}/novo`);
  };

  return (
    <div id="general-no-content-container" className={css['c-no-content']}>
      <div className={css['c-no-content__info']}>
        {icon && (
          <div className={css['c-no-content__icon']}>
            <FontAwesomeIcon icon={icon} size="3x" />
          </div>
        )}
        <div className={css['c-no-content__content']} style={style}>
          {title ? <Title color={colors.neutral600}>{title}</Title> : null}
          {description ? <Paragraph color={colors.neutral500}>{description}</Paragraph> : null}
          {renderButton ? (
            <Button id={buttonId} type="primary" onClick={handleClick} align="auto">
              {showButtonIcon && <FontAwesomeIcon icon={faPlus} />}
              {buttonTitle}
            </Button>
          ) : (
            ExtraButton && <ExtraButton />
          )}
        </div>
      </div>
    </div>
  );
};

NoContent.propTypes = {
  icon: PropTypes.instanceOf(Object),
  buttonTitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  renderButton: PropTypes.bool,
  title: PropTypes.string,
  showButtonIcon: PropTypes.bool,
  extraButton: PropTypes.instanceOf(Object),
  buttonId: PropTypes.string,
  style: PropTypes.instanceOf(Object)
};

export default NoContent;
