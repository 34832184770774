import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { spaces, breakpoints } from '../../styles/style';
import Form from './Form';
import { purchaseMapping, purchaseSchema } from '../../lib/mapping/Form/purchaseSchema';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${spaces.space3} ${spaces.space2} ${spaces.space4};
  width: 100%;

  form {
    width: 100%;

    @media (max-width: ${breakpoints.mobile}) {
      .ant-row {
        margin-top: ${spaces.space2};
        gap: ${spaces.space2};
      }
    }
  }
`;

const PurchaseForm = ({
  canEditRefurbish,
  canEditSupplier,
  forceFormUpdate,
  purchaseFormValues,
  setPurchaseFormValues,
  hasSupplier,
  mapping = purchaseMapping,
  schema = purchaseSchema,
  ...props
}) => {
  const { user } = useSelector(state => state.authReducer);

  const { idCompany } = user;

  return (
    <FormContainer {...props}>
      <Form
        schema={schema({ hasSupplier })}
        forceUpdate={forceFormUpdate}
        mapping={() => mapping({ idCompany, canEditRefurbish, hasSupplier, canEditSupplier })}
        data={purchaseFormValues}
        onFormChange={setPurchaseFormValues}
        displayButtons={false}
        margin="0"
      />
    </FormContainer>
  );
};

PurchaseForm.propTypes = {
  canEditRefurbish: PropTypes.bool,
  canEditSupplier: PropTypes.bool,
  forceFormUpdate: PropTypes.instanceOf(Object),
  purchaseFormValues: PropTypes.instanceOf(Object),
  setPurchaseFormValues: PropTypes.func,
  hasSupplier: PropTypes.bool,
  schema: PropTypes.func,
  mapping: PropTypes.func
};

export default PurchaseForm;
