import styled from 'styled-components';
import { spaces, breakpoints } from '../../styles/style';

const ButtonGroup = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: ${props => props.justify || 'end'};
  width: 100%;

  > * {
    display: flex;
    height: ${spaces.space4};
    align-items: center;
    width: ${spaces.space4};
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: ${props => props.mobileDirection};
    padding-top: 0;
  }
`;

const ButtonContainer = styled.div`
  padding-right: ${props => (props.$paddingRight ? spaces.space1 : 0)};
  margin-left: ${props => (props.$marginLeft ? spaces.space2 : 0)};
  @media (max-width: ${breakpoints.tablet}) {
    padding: ${spaces.space1};
    margin-left: 0;
  }
`;

export { ButtonGroup, ButtonContainer };
