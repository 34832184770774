import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, spaces } from '../../styles/style';

const FooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: sticky;
  border-top: 1px solid ${colors.neutral100};
  padding: ${spaces.space1} ${spaces.space2};
  bottom: 0;
  background-color: ${props => props.$backgroundColor || colors.white};
  z-index: 2;
  margin: 0 auto;
  width: 100%;
`;

const Footer = ({ backgroundColor, children }) => {
  return <FooterDiv $backgroundColor={backgroundColor}>{children}</FooterDiv>;
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.instanceOf(Object)
};

export default Footer;
