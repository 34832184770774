const financialCategories = ({ hasPermission }) => [
  ...[
    {
      key: 'income',
      name: `Pagamentos e receitas`,
      emptyText: 'Você não possui nenhuma categoria financeira cadastrada.',
      description: 'Além das categorias padrão da Vobi, você pode adicionar ou excluir suas categorias livremente.'
    }
  ],
  ...(hasPermission
    ? [
        {
          key: 'expense',
          name: 'Despesas',
          description: 'Além das categorias padrão da Vobi, você pode adicionar ou excluir suas categorias livremente.'
        }
      ]
    : [])
];

const bankAccount = [
  {
    key: 'bankAccount',
    name: 'Conta',
    emptyText: 'Você não possui nenhuma conta cadastrada.',
    description: 'Você pode adicionar ou excluir suas contas livremente.'
  }
];

const paymentCostCenter = [
  {
    key: 'costCenter',
    name: 'Centro de custo',
    emptyText: 'Você não possui nenhum centro de custo financeira cadastrado.',
    description: 'Você pode adicionar ou excluir seus centro de custos livremente.'
  }
];

const units = [
  {
    key: 'units',
    name: 'Unidades',
    emptyText: 'Você não possui nenhuma unidade cadastrada.',
    description: 'Além das unidades padrão da Vobi, você pode adicionar ou excluir unidades personalizadas.'
  }
];

export { financialCategories, bankAccount, paymentCostCenter, units };
