import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';

import { Div, spaces } from '../../styles/style';
import { Subtitle } from '../../components/Text/Text';
import RichTextViewer from '../../components/RichTextEditor/RichTextViewer';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import AttachmentSection from '../../components/Sections/AttachmentSection';
import { useContextHook } from '../../contexts/GeneralContext';
import Button from '../../components/Button/Button';

const ObservationAndFilesComponent = ({ observationWidth, ...props }) => {
  const { files, setFiles, observation, setObservation, readOnly, richTextProps, domainLabel } = useContextHook();
  const [hasObservation, setHasObservation] = useState(!!observation);
  const [hasFiles, setHasFiles] = useState(files?.length);

  useEffect(() => {
    setHasFiles(files?.length);
  }, [files]);

  useEffect(() => {
    setHasObservation(!!observation);
  }, [observation]);

  return (
    <>
      <Div
        align="start"
        direction="column"
        padding={spaces.space2}
        gap={spaces.space2}
        width={observationWidth}
        {...props}
      >
        {hasObservation || readOnly ? (
          <>
            <Subtitle type="secondary">Observações {domainLabel ? `do ${domainLabel}` : ''}</Subtitle>
            {readOnly ? (
              <RichTextViewer value={observation} emptyStateText="Nenhuma observação registrada" />
            ) : (
              <RichTextEditor
                id="rich-text"
                toolbar={
                  ' bold italic underline strikethrough | forecolor backcolor |' +
                  ' alignleft aligncenter alignright alignjustify | numlist bullist | link table |' +
                  '  h1 h2 h3 h4 h5 h6 | emoticons'
                }
                onChange={setObservation}
                value={observation}
                height={richTextProps?.height || '240px'}
                {...richTextProps}
              />
            )}
          </>
        ) : (
          <Button
            id="purchase-request-add-comment"
            text
            type="primary"
            onClick={() => setHasObservation(true)}
            padding="0"
            style={{ alignSelf: 'flex-start' }}
          >
            <FontAwesomeIcon icon={faPlus} /> Adicionar observação
          </Button>
        )}
      </Div>

      <Divider className="hide-on-print" style={{ margin: 0 }} />

      <Div className="hide-on-print" align="start" direction="column" padding={spaces.space2} {...props}>
        {hasFiles || readOnly ? (
          <>
            <Subtitle className="hide-on-print">Anexos {domainLabel ? `do ${domainLabel}` : ''}</Subtitle>
            <AttachmentSection
              fileList={files}
              setFileList={setFiles}
              isView={readOnly}
              emptyStateText="Nenhum arquivo anexado"
            />
          </>
        ) : (
          <Button
            id="purchase-request-add-comment"
            text
            type="primary"
            onClick={() => setHasFiles(true)}
            padding="0"
            style={{ alignSelf: 'flex-start' }}
          >
            <FontAwesomeIcon icon={faPlus} /> Adicionar anexo
          </Button>
        )}
      </Div>
    </>
  );
};

ObservationAndFilesComponent.propTypes = {
  observationWidth: PropTypes.string
};

export default ObservationAndFilesComponent;
