import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import useFormState from '../../_Hooks/useFormState';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import { Error, Paragraph } from '../Text/Text';
import Button from '../Button/Button';
import { FormSubmitData } from '../../lib/helpers/formFunctions';
import useViewport from '../../_Hooks/useViewport';
import { Context } from '../../contexts/GeneralContext';
import FormItemCreatorV2 from './FormItemCreatorV2';

const WarningCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 20px;
  > span {
    font-weight: ${fonts.weight500};
    color: ${colors.red500};
  }
`;

const SubmitDiv = styled(Div)`
  gap: ${spaces.space2};
  margin-top: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  border: none;
  width: 100%;

  .ant-collapse-item {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    order: -1;

    .ant-collapse-header {
      align-items: center;
      padding: ${spaces.space1};

      .ant-collapse-expand-icon {
        width: ${spaces.space3};
        height: ${spaces.space3};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${spaces.space0};

        svg {
          margin: 0;
        }
      }
    }
  }

  .ant-collapse-header-text {
    font-weight: ${fonts.weight500};
    color: ${colors.neutral500};
    font-size: ${fonts.sizeSm};
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content-box {
    padding: 0 ${spaces.space1};
  }
`;

const CollapsibleSection = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCollapse
      expandIcon={({ isActive }) => (
        <FontAwesomeIcon color={colors.neutral500} icon={faChevronDown} rotation={isActive ? 180 : 0} />
      )}
      onChange={() => setIsOpen(!isOpen)}
    >
      <Panel header={isOpen ? 'Esconder propriedades' : 'Exibir todas as propriedades'} key="1">
        {children}
      </Panel>
    </StyledCollapse>
  );
};

CollapsibleSection.propTypes = {
  children: PropTypes.node.isRequired
};

const FormV2 = ({
  data = null,
  schema,
  mapping,
  onSubmit,
  isFormValid = f => f,
  loading = false,
  displayButtons = true,
  create = false,
  customButtonTitle,
  saveWarningMessage = '',
  onValueChanged = f => f,
  onTouchedChanged = f => f,
  onFormChange = f => f,
  submitDescription,
  justify = 'end',
  validateOnRender,
  newValues,
  keepSchema,
  readOnly,
  initialParentValues = {},
  ...props
}) => {
  const [parentValues, setParentValues] = useState(initialParentValues);
  const mappingObj = mapping.constructor === Object ? mapping : mapping(data || {});
  const mappingKeys = Object.keys(mappingObj);
  const _data = Object.fromEntries(mappingKeys.map(e => [e, mappingObj[e]?.defaultValue || null]));
  const initialState = { ..._data, ...data };
  const isSchemaFunction = typeof schema === 'function';
  const [_schema, setSchema] = useState(isSchemaFunction ? schema(initialState) : schema);

  const {
    formState,
    handleSubmit,
    setField,
    setValues,
    isValid,
    valuesChanged,
    handleChange,
    handleBlur,
    validateValues
  } = useFormState(initialState, _schema, false, false, null, true);
  const { values, touched, errors } = formState;

  useEffect(() => {
    if (errors) isFormValid(isValid());
  }, [errors]);

  useEffect(() => {
    onValueChanged(valuesChanged);
  }, [valuesChanged]);

  useEffect(() => {
    if (Object.keys(touched || {})?.length) onTouchedChanged(values, touched);
  }, [touched]);

  useEffect(() => {
    onFormChange(values);
    if (keepSchema) return;
    if (isSchemaFunction) setSchema(schema(values));
    else setSchema(schema);
  }, [values, schema]);

  useEffect(() => {
    if (!newValues) return;
    setValues({ ...values, ...newValues });
  }, [newValues]);

  const handleClear = key => {
    setField(key)(null);
  };

  const handleFormSubmit = () => {
    onSubmit(FormSubmitData(values, mapping));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (validateOnRender) validateValues();
  }, []);

  const handleChangeSelect = (key, selectValue, multiple, hasChildren) => {
    if (hasChildren) setParentValues(prev => ({ ...prev, [key]: selectValue }));
    if (multiple && selectValue) return setField(key)([...selectValue]);
    return setField(key)(selectValue || null);
  };

  const _mapping = useMemo(() => (mapping.constructor === Object ? mapping : mapping(values)), [mapping, values]);

  const buttonTitle = create ? 'Enviar' : 'Salvar';

  const handleFunctions = {
    setField,
    handleChange,
    handleBlur,
    handleChangeSelect,
    handleClear
  };

  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  const renderFormItems = () => {
    const formItems = [];
    let isInCollapsibleSection = false;
    let collapsibleItems = [];

    Object.entries(_mapping).forEach(([key, field]) => {
      if (field.shouldDisplayWhen !== undefined && !field.shouldDisplayWhen) {
        return;
      }

      const formContent = (
        <>
          <Context
            data={{
              type: field.type,
              label: field.name,
              value: values[key],
              allValues: values,
              onChange: value => setField(key)(value),
              tooltip: field.tooltip,
              placeholder: field.placeholder,
              property: key,
              error: errors[key],
              touched: touched[key],
              handleFunctions,
              startHide: field.startHide,
              parentValues,
              readOnly,
              ...field
            }}
          >
            <FormItemCreatorV2 />
          </Context>
          {touched[key] && errors[key] && (
            <Div margin={`0 0 0 ${spaces.space16}`}>
              <Error>{errors[key][0]}</Error>
            </Div>
          )}
        </>
      );

      const formItem = field.fullWidth ? (
        <Div key={key} style={{ width: '100%', padding: `${spaces.space1}` }}>
          {formContent}
        </Div>
      ) : (
        <Col
          style={{ paddingBottom: spaces.space1 }}
          key={key}
          xs={field.xs || 24}
          md={field.md || 12}
          sm={field.sm || 12}
        >
          {formContent}
        </Col>
      );

      if (field.startHide || (isMobile && field.startHideOnMobile)) {
        if (!isInCollapsibleSection) {
          isInCollapsibleSection = true;
        }
        collapsibleItems.push(formItem);
      } else {
        if (isInCollapsibleSection) {
          formItems.push(
            <CollapsibleSection key={`collapsible-${key}`}>
              <Row width="100%" gutter={16}>
                {collapsibleItems}
              </Row>
            </CollapsibleSection>
          );
          isInCollapsibleSection = false;
          collapsibleItems = [];
        }
        formItems.push(formItem);

        if (!isMobile && field.uniqueInLine && field.fullWidth !== true) {
          formItems.push(<Col key={`ghost-${key}`} md={12} sm={12} />);
        }
      }
    });

    // Add any remaining collapsible items
    if (collapsibleItems.length > 0) {
      formItems.push(
        <CollapsibleSection key="collapsible-end">
          <Row gutter={16}>{collapsibleItems}</Row>
        </CollapsibleSection>
      );
    }

    return formItems;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={handleKeyDown} {...props}>
      <Row gutter={16}>{renderFormItems()}</Row>

      {displayButtons && (
        <Div justify={`flex-${justify}`} gap="30px">
          {saveWarningMessage && valuesChanged && (
            <Row justify="start">
              <WarningCol>
                <span>{saveWarningMessage}</span>
              </WarningCol>
            </Row>
          )}

          <Row justify={justify}>
            <Col style={{ justifyContent: justify }}>
              <SubmitDiv>
                <Div>
                  <Button id="custom-button-form" type="primary" loading={loading} htmlType="submit">
                    {customButtonTitle || buttonTitle}
                  </Button>
                </Div>
                {submitDescription && <Paragraph type="small">{submitDescription}</Paragraph>}
              </SubmitDiv>
            </Col>
          </Row>
        </Div>
      )}
    </form>
  );
};

FormV2.propTypes = {
  initialParentValues: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  schema: PropTypes.instanceOf(Object).isRequired,
  mapping: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFormValid: PropTypes.func,
  loading: PropTypes.bool,
  displayButtons: PropTypes.bool,
  create: PropTypes.bool,
  customButtonTitle: PropTypes.string,
  saveWarningMessage: PropTypes.string,
  onValueChanged: PropTypes.func,
  onTouchedChanged: PropTypes.func,
  onFormChange: PropTypes.func,
  submitDescription: PropTypes.string,
  justify: PropTypes.string,
  validateOnRender: PropTypes.bool,
  newValues: PropTypes.instanceOf(Object),
  keepSchema: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default FormV2;
