import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { colors, breakpoints, radius, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const StyledDiv = styled.div`
  background-color: ${props => colors[props.status]};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  ${props =>
    props.$inlineText
      ? css`
          border-radius: ${radius.radius1};
          padding: 0px ${spaces.space1};
          height: ${spaces.space3};
        `
      : css`
          width: 12px;
          height: 12px;
          border-radius: 2px;
          padding: 0;
          margin-right: 12px;

          @media (max-width: ${breakpoints.tablet}) {
            width: ${spaces.space2};
            height: ${spaces.space2};
            border-radius: ${radius.radius1};
            margin: ${spaces.space0};
          }
        `}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StatusSelector = ({ status, text, icon, titleTooltip, inlineText = false }) => {
  const renderIconOrText = () => {
    if (icon) {
      return <FontAwesomeIcon icon={icon} color={colors[status?.style]} style={{ marginRight: 8 }} />;
    }

    return (
      <StyledDiv $inlineText={inlineText} status={status?.style}>
        {inlineText && (
          <Paragraph type="small" color={colors.white}>
            {status.label}
          </Paragraph>
        )}
      </StyledDiv>
    );
  };

  return (
    <Tooltip placement="bottom" title={titleTooltip || status?.label}>
      <Container>
        {renderIconOrText()}
        {text}
      </Container>
    </Tooltip>
  );
};

StatusSelector.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  titleTooltip: PropTypes.string,
  inlineText: PropTypes.bool
};

export default StatusSelector;
