import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'antd/lib/date-picker/style/index';
import ptBR from 'antd/es/locale/pt_BR';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { colors, Div, fonts, spaces } from '../../styles/style';
import Button from '../Button/Button';
import UseOutsideClick from '../../_Hooks/useOutsideClick';
import useViewport from '../../_Hooks/useViewport';
import {
  StyledPicker,
  StyledTimePicker,
  StyledPanel,
  StyledIcon,
  StyledBlockCalendar,
  StyledSpan,
  CalendarIcon
} from './RangePicker.styled';
import useDisabledDate from '../../_Hooks/useDisabledDate';
import { countValidDays } from '../Timeline/GanttHelperFunctions';
import { getBrazilianDate, getDateWithCustomHours } from '../../lib/helpers/helper';

const defaultFormat = 'HH:mm';

const RangePicker = ({
  style,
  styleCalendar,
  onChange,
  value = [],
  children,
  showTime = false,
  disabled,
  isDelayed,
  hideTxtPicker,
  ghost,
  buttonProps,
  isFilter,
  submitOnOk,
  taskCustomCalendar,
  taskBasedDate,
  isParent,
  textUnlocked = 'Para editar a data da etapa, remova o vínculo com as datas das tarefas.',
  format = 'DD MMM',
  planning,
  id,
  workDays = null,
  dayoffs = null,
  duration,
  externalFunctions,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [durationTime, setDurationTime] = useState(duration);
  const [checkTaskBasedDate, setCheckTaskBasedDate] = useState(taskBasedDate);

  const preventClose = useRef(null);
  const wrapperRef = useRef(null);
  const inputNumberRef = useRef(null);
  const initialDate = useRef(0);

  const { verifyDisabledDate, findValidRange, findNextValidDate, changeDate } = useDisabledDate({ workDays, dayoffs });

  const submit = () => {
    setIsOpen(false);
    if (shouldSubmit)
      onChange({ start: dateRange[0], end: dateRange[1], taskBasedDate: checkTaskBasedDate, duration: durationTime });
  };

  const onElementFocus = (element, index) => {
    if (initialDate.current === index) {
      element.focus();
    }
  };

  const resetDateRange = () => {
    setDateRange([value[0] ? getBrazilianDate(value[0]) : null, value[1] ? getBrazilianDate(value[1]) : null]);
  };

  UseOutsideClick(
    wrapperRef,
    () => {
      resetDateRange();
      setIsOpen(false);
      if (!submitOnOk && shouldSubmit)
        onChange({ start: dateRange[0], end: dateRange[1], taskBasedDate: checkTaskBasedDate, duration: durationTime });
    },
    preventClose
  );

  const changeTime = (index, time) => {
    const objValue = getBrazilianDate(time).toObject();
    const newTime = getBrazilianDate(dateRange[index])
      .minute(objValue.minutes)
      .hour(objValue.hours);
    setDateRange(prev => {
      const aux = prev;
      aux[index] = newTime;
      return aux;
    });
  };

  const openPicker = e => {
    e.stopPropagation();
    setIsOpen(disabled ? false : !isOpen);
  };

  useEffect(() => {
    const focusDate = document.querySelectorAll('.vobi-range-picker > .ant-picker-input');
    if (focusDate[initialDate.current]) {
      focusDate[initialDate.current].children[0].focus();
    }
    if (focusDate[0]) {
      const initInput = focusDate[0].children?.[0];
      const endInput = focusDate[1]?.children?.[0];
      initInput.addEventListener('click', () => {
        initialDate.current = 0;
        initInput.focus();
      });
      endInput.addEventListener('click', () => {
        initialDate.current = 1;
        endInput.focus();
      });
      initInput.addEventListener('focus', () => onElementFocus(endInput, 1));
    }
  }, [isOpen]);

  useEffect(() => {
    const focusDate = document.querySelectorAll('.vobi-range-picker > .ant-picker-input');
    if (focusDate[initialDate.current]) {
      focusDate[initialDate.current].children[0].focus();
    }
  }, [initialDate.current]);

  useEffect(() => {
    if (duration) setDurationTime(duration);
  }, [duration]);

  useEffect(() => {
    checkTaskBasedDate && resetDateRange();
  }, [checkTaskBasedDate]);

  useEffect(() => {
    resetDateRange();
  }, [value]);

  useEffect(() => {
    if (duration && (!dateRange?.[0] || !dateRange?.[1])) return;

    const _duration = countValidDays(dateRange?.[0], dateRange?.[1], { workDays, dayoffs });

    setDurationTime(dateRange?.[0] && dateRange?.[1] ? _duration : 0);
  }, [dateRange]);

  const OpenNewCalendarBtn = child => {
    if ((child || isMobile()) && !planning) {
      return (
        <Button
          size={isMobile() ? 'small' : undefined}
          id="open-new-calendar"
          onClick={openPicker}
          ghost={hideTxtPicker ? ghost : true}
          onFocus={openPicker}
          {...buttonProps}
        >
          {child || (
            <>
              <StyledSpan $isDelayed={isDelayed}>
                <div>
                  {dateRange?.[0] && getBrazilianDate(dateRange?.[0]).isValid() ? (
                    `${getBrazilianDate(dateRange?.[0])
                      .format(format)
                      .toString()} `
                  ) : (
                    <StyledIcon style={{ marginBottom: '-1px', width: 28 }} icon={faCalendarWeek} />
                  )}
                </div>
                <div>
                  {dateRange?.[1] && getBrazilianDate(dateRange?.[1]).isValid() ? (
                    `  ${getBrazilianDate(dateRange?.[1])
                      .format(format)
                      .toString()}`
                  ) : (
                    <StyledIcon style={{ marginBottom: '-1px', width: 28 }} icon={faCalendarWeek} />
                  )}
                </div>
              </StyledSpan>
              <div style={{ width: '4px' }} />
            </>
          )}
        </Button>
      );
    }
    return (
      <Div $fullWidth justify="space-between">
        <Button onClick={openPicker} onFocus={openPicker} ghost={hideTxtPicker ? ghost : true} {...buttonProps}>
          <>
            <CalendarIcon $isDelayed={isDelayed} planning={planning} id={`${id}-left`}>
              {dateRange?.[0] && getBrazilianDate(dateRange?.[0]).isValid() ? (
                getBrazilianDate(dateRange?.[0])
                  .format(format)
                  .toString()
              ) : (
                <Tooltip
                  placement="bottom"
                  overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 270 }}
                  title="Data de início"
                >
                  <span>
                    <StyledIcon icon={faCalendarWeek} />
                  </span>
                </Tooltip>
              )}{' '}
              {!taskCustomCalendar && dateRange?.[1] && getBrazilianDate(dateRange?.[1]).isValid()
                ? ` - ${getBrazilianDate(dateRange?.[1])
                    .format(format)
                    .toString()}`
                : null}
            </CalendarIcon>
          </>
        </Button>
        {taskCustomCalendar && (
          <Button
            onClick={e => {
              !disabled && e.stopPropagation();
              if (taskCustomCalendar) initialDate.current = 1;
              setIsOpen(disabled ? false : !isOpen);
            }}
            ghost={hideTxtPicker ? ghost : true}
            {...buttonProps}
          >
            <>
              <CalendarIcon $isDelayed={isDelayed} planning={planning} id={`${id}-right`}>
                {dateRange?.[1] && getBrazilianDate(dateRange?.[1]).isValid() ? (
                  getBrazilianDate(dateRange?.[1])
                    .format(format)
                    .toString()
                ) : (
                  <Tooltip
                    placement="bottom"
                    overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 270 }}
                    title="Data de fim"
                  >
                    <span>
                      <StyledIcon icon={faCalendarWeek} />
                    </span>
                  </Tooltip>
                )}
              </CalendarIcon>
            </>
          </Button>
        )}
        <div />
      </Div>
    );
  };

  const updateDatesBasedOnNewDuration = newDuration => {
    let [start, end] = dateRange;

    if (!start && !end) {
      end = changeDate(
        end,
        getBrazilianDate(getBrazilianDate())
          .add(newDuration - 1, 'day')
          .hour(23)
          .minute(59)
      );

      start = getBrazilianDate();

      setDateRange(findValidRange(start, end, newDuration));
      return;
    }
    if (!start || (end && initialDate.current === 1)) {
      start = changeDate(
        start,
        getBrazilianDate(end)
          .subtract(newDuration - 1, 'day')
          .hour(0)
          .minute(0)
      );
    } else {
      end = changeDate(
        end,
        getBrazilianDate(start)
          .add(newDuration - 1, 'day')
          .hour(23)
          .minute(59)
      );
    }

    setDateRange(findValidRange(start, end, newDuration));
  };

  const { calculateEndDate, convertToUtc } = externalFunctions || {};

  const updateDatesBasedOnNewDurationUsingExternalFunctions = newDuration => {
    let [start, end] = dateRange;

    if (!calculateEndDate || !convertToUtc) return;

    if (!start && !end) {
      start = getDateWithCustomHours();
      end = getDateWithCustomHours(calculateEndDate({ start_date: convertToUtc(start), duration: newDuration - 1 }), {
        hours: 15
      });
      setDateRange([getBrazilianDate(start), getBrazilianDate(end)]);
      return;
    }

    if (end && (!start || initialDate.current === 1)) {
      start = getBrazilianDate(
        getDateWithCustomHours(
          calculateEndDate({ start_date: convertToUtc(getDateWithCustomHours(end)), duration: -newDuration + 1 }),
          { hours: 3 }
        )
      );
      setDateRange([start, end]);
      return;
    }

    end = getBrazilianDate(
      getDateWithCustomHours(
        calculateEndDate({ start_date: convertToUtc(getDateWithCustomHours(start)), duration: newDuration - 1 }),
        { hours: 15 }
      )
    );
    setDateRange([start, end]);
  };

  const onChangeDuration = newDuration => {
    const val = Number(newDuration);
    const [start] = dateRange;

    setShouldSubmit(true);
    if (!val || val < 0) {
      initialDate.current = 0;
      setDurationTime(null);
      setDateRange([start, start]);
      return;
    }
    setDurationTime(val);

    if (externalFunctions) {
      updateDatesBasedOnNewDurationUsingExternalFunctions(val);
      return;
    }

    updateDatesBasedOnNewDuration(val);
  };

  const getRangeDatesBasedOnNewDatesUsingExternalFunctions = (range, start, end) => {
    if (!calculateEndDate || !convertToUtc) return [start, end];

    let _start = start;
    let _end = end;

    if (range === 'start' && durationTime) {
      _end = getBrazilianDate(
        getDateWithCustomHours(
          calculateEndDate({ start_date: convertToUtc(getDateWithCustomHours(start)), duration: durationTime - 1 }),
          { hours: 15 }
        )
      );
    }

    if (range === 'end' && durationTime && _end && !_start) {
      _start = getBrazilianDate(
        getDateWithCustomHours(
          calculateEndDate({ start_date: convertToUtc(getDateWithCustomHours(_end)), duration: -durationTime + 1 }),
          {
            hours: 3
          }
        )
      );
    }
    return [_start, _end];
  };

  const getRangeDatesBasedOnNewDates = (range, start, end) => {
    const [oldStart, oldEnd] = dateRange;
    let _start = start;
    let _end = end;

    let validRange = [findNextValidDate(_start), findNextValidDate(_end)];

    if (range === 'start' && durationTime) {
      _end = _start
        ?.add(durationTime - 1, 'day')
        ?.hour(oldEnd?.hour() || 23)
        ?.minute(oldEnd?.minute() || 59);
      validRange = findValidRange(_start, _end, durationTime);
    }

    if (range === 'end' && durationTime && _end && !_start) {
      _start = _end
        ?.subtract(durationTime - 1, 'day')
        ?.hour(oldStart?.hour() || 0)
        ?.minute(oldStart?.minute() || 0);
      validRange = findValidRange(_start, _end, durationTime, true);
    }

    return validRange;
  };

  return isOpen ? (
    <Div $fullWidth role="presentation" ref={wrapperRef} onClick={e => e.stopPropagation()}>
      {hideTxtPicker ? OpenNewCalendarBtn(children) : null}
      <StyledPicker
        style={styleCalendar}
        format={format}
        className={`${hideTxtPicker ? 'hideTxtPicker' : ''} vobi-range-picker`}
        disabledDate={verifyDisabledDate}
        {...props}
        onBlur={() => setIsOpen(false)}
        renderExtraFooter={() => {
          return showTime ? (
            <Div justify="space-between" style={{ position: 'relative' }}>
              <div style={{ flex: 5 }}>
                <span>Horário de início: </span>
                <StyledTimePicker
                  format={defaultFormat}
                  value={
                    dateRange?.[0]
                      ? getBrazilianDate(dateRange?.[0]?.format(defaultFormat), defaultFormat)
                      : getBrazilianDate('00:00', 'HH:mm')
                  }
                  minuteStep={5}
                  onChange={val => changeTime(0, val)}
                  popupClassName="time-picker-popup"
                />
              </div>
              <Div justify="space-around" flex={6}>
                <span>Horário de fim: </span>
                <StyledTimePicker
                  format={defaultFormat}
                  value={
                    dateRange?.[1]
                      ? getBrazilianDate(dateRange?.[1]?.format(defaultFormat), defaultFormat)
                      : getBrazilianDate('00:00', 'HH:mm')
                  }
                  minuteStep={5}
                  onChange={val => changeTime(1, val)}
                />
                <Button type="primary" onClick={() => submit({ start: dateRange[0], end: dateRange[1] })}>
                  Ok
                </Button>
              </Div>
            </Div>
          ) : (
            <>
              <Div padding={spaces.spaces0} justify="space-between">
                {isFilter ? (
                  <p style={{ color: colors.primary600 }}>Filtre as tarefas pela data de término</p>
                ) : (
                  <>
                    <Div
                      role="presentation"
                      onClick={() => {
                        inputNumberRef.current.focus();

                        setTimeout(() => {
                          inputNumberRef.current.focus();
                        }, 1);
                      }}
                      onDoubleClick={() => {
                        inputNumberRef.current.select();
                      }}
                    >
                      <p>Duração</p>
                      <Input
                        ref={inputNumberRef}
                        disabled={checkTaskBasedDate}
                        size="small"
                        placeholder="Dias"
                        style={{ width: '70px', height: '27px', margin: '0px 8px', alignSelf: 'center' }}
                        value={durationTime}
                        onChange={e => {
                          onChangeDuration(e.target.value);
                        }}
                      />
                      {isParent && (
                        <Checkbox
                          id="linked-date"
                          checked={checkTaskBasedDate}
                          onChange={() => {
                            setCheckTaskBasedDate(!checkTaskBasedDate);
                            setShouldSubmit(true);
                          }}
                        >
                          <p style={{ fontSize: fonts.sizeSm, color: colors.neutral600 }}>
                            Vincular com datas de início e fim dos itens
                          </p>
                        </Checkbox>
                      )}
                    </Div>
                  </>
                )}
                <Button
                  id="handle-submit"
                  type="primary"
                  onClick={() => submit({ start: dateRange?.[0], end: dateRange?.[1] })}
                >
                  Ok
                </Button>
              </Div>
              {checkTaskBasedDate && (
                <StyledBlockCalendar>
                  <Text
                    style={{
                      zIndex: 1002,
                      fontSize: 20,
                      fontWeight: 500
                    }}
                  >
                    {textUnlocked}
                  </Text>
                </StyledBlockCalendar>
              )}
            </>
          );
        }}
        locale={ptBR.DatePicker}
        placeholder={['Início', 'Fim']}
        open
        readOnly
        value={dateRange}
        onCalendarChange={(dates, aux, key) => {
          if (checkTaskBasedDate) return;
          const [start, end] = dateRange;
          const [dateStart, dateEnd] = dates || [];
          let _start = dateStart ? changeDate(start, dateStart) : null;
          let _end = dateEnd ? changeDate(end, dateEnd) : null;

          if (!showTime) {
            _start = !start && dateStart ? getBrazilianDate(_start)?.startOf('day') : _start;

            _end =
              !end && _end
                ? getBrazilianDate(_end)
                    ?.hour(23)
                    ?.minute(59)
                : _end;
          }

          const validRange = externalFunctions
            ? getRangeDatesBasedOnNewDatesUsingExternalFunctions(key?.range, _start, _end)
            : getRangeDatesBasedOnNewDates(key?.range, _start, _end);

          setDateRange(validRange || []);
          setShouldSubmit(true);
        }}
        panelRender={current => {
          return <StyledPanel ref={preventClose}>{current}</StyledPanel>;
        }}
      />
    </Div>
  ) : (
    OpenNewCalendarBtn(children)
  );
};

RangePicker.propTypes = {
  style: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  children: PropTypes.instanceOf(Object),
  isDelayed: PropTypes.bool,
  showTime: PropTypes.bool,
  disabled: PropTypes.bool,
  hideTxtPicker: PropTypes.bool,
  ghost: PropTypes.bool,
  buttonProps: PropTypes.instanceOf(Object),
  styleCalendar: PropTypes.instanceOf(Object),
  isFilter: PropTypes.bool,
  submitOnOk: PropTypes.bool,
  taskCustomCalendar: PropTypes.bool,
  taskBasedDate: PropTypes.bool,
  isParent: PropTypes.bool,
  textUnlocked: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  format: PropTypes.string,
  planning: PropTypes.bool,
  id: PropTypes.string,
  workDays: PropTypes.instanceOf(Object),
  dayoffs: PropTypes.instanceOf(Array),
  duration: PropTypes.number,
  externalFunctions: PropTypes.instanceOf(Object)
};

export default RangePicker;
