import { AUTH } from '../actions/action';

const DEFAULT_STATE = {
  user: null,
  token: null,
  type: null
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case AUTH.LOGIN:
      return { ...action.payload };
    case AUTH.LOGOUT:
      return null;
    case AUTH.UPDATE_USER:
      return { ...state, user: action.payload };
    case AUTH.UPDATE_COMPANY:
      return { ...state, user: { ...state?.user, company: action.payload } };
    case AUTH.UPDATE_PERMISSIONS:
      return { ...state, user: { ...state?.user, permissions: action.payload } };
    case AUTH.LOGIN_ANONYMOUS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default reducer;
