import { toast } from 'react-toastify';
import useCRUD from './useCRUD';

const useUploadAttachment = ({ model, refer = 'idRefurbish', idReference, item }) => {
  const { handleGet, handleUpdate } = useCRUD({
    model,
    [refer]: idReference,
    pathOptions: item?.id ? `/${item?.id}` : '',
    options: {
      include: ['files']
    },
    immediatelyLoadData: false
  });

  const handleUploadFile = (newFiles, onClose) => {
    handleGet().then(data => {
      const files = [...(data?.files || []), ...newFiles];
      handleUpdate({
        values: {
          [refer]: idReference,
          files
        },
        refresh: false
      }).then(resp => {
        if (!resp?.error) {
          onClose(true);
          toast.success('Anexo adicionado');
        }
      });
    });
  };

  return {
    handleUploadFile
  };
};

export default useUploadAttachment;
