import styled, { css } from 'styled-components';
import { Div, breakpoints, colors, spaces } from '../../styles/style';

export const InfoMessage = styled(Div)`
  background-color: ${colors.primary50};
  min-height: ${spaces.space4};
  border-radius: ${spaces.space0};
  padding-left: ${spaces.space1};
  margin-top: ${props => !props.$noMargin && spaces.space1};
  padding-right: ${props => props.$paddingRight && props.$paddingRight};

  @media (max-width: ${breakpoints.mobile}) {
    align-items: start;
    padding: ${spaces.space0} ${spaces.space1};
  }
  svg {
    color: ${props => (props.alert ? colors.orange400 : colors.primary500)};
    padding: 2px;
  }
  gap: ${spaces.space1};
  ${props =>
    props.alert &&
    css`
      background-color: ${colors.yellow100};
      svg {
        color: ${colors.orange400};
      }
    `}
`;
