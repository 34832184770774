import React, { useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { MobileCardFooter, KeyDataContainer, OtherDataContainer, StyledTable } from './PixTable.style';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { colors, spaces } from '../../styles/style';
import Card from '../Card/Card';
import { updateCompany } from '../../store/auth/actions/action';
import ConfirmModal from '../Modal/ConfirmModal';
import { columns as pixColumns } from '../../lib/mapping/TableOrList/pixColumns';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';

const PixTable = ({ data, onDeletePix }) => {
  const dispatch = useDispatch();
  const { asaasPixType } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const { isMobile } = useViewport(window.innerWidth);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { handleDelete } = useCRUD({
    model: 'pay',
    immediatelyLoadData: false
  });
  const pixTextDelete = `Você não poderá criar novas cobranças ou realizar saques via Pix se não tiver uma chave
  Pix cadastrada. Deseja realmente excluir a sua chave Pix da conta Vobi Pay?`;

  const handleCopy = ({ key }) => {
    setTimeout(() => navigator.clipboard.writeText(key), 0);
    toast.success('Chave copiada!');
  };

  const columns = pixColumns({ handleCopy, asaasPixType, setShowConfirmDelete });

  const handleRemove = ({ id }) => {
    handleDelete({ deletePathOptions: `/delete-pix-key/${id}` }).then(resp => {
      if (!resp?.error) onDeletePix(null);
      dispatch(updateCompany({ ...user?.company, pix: null }));
    });
  };

  const MobilePixData = () => (
    <Card
      footer={
        <MobileCardFooter>
          <TooltipIcon
            text="Copiar"
            iconColor={colors.primary500}
            icon={faCopy}
            style={{ height: 18, width: 18 }}
            id="copy-pix"
            onClick={() => handleCopy(data)}
          />

          <TooltipIcon
            text="Excluir"
            iconColor={colors.red500}
            icon={faTrashCan}
            style={{ height: 18, width: 18, marginLeft: spaces.space2 }}
            id="remove-pix"
            onClick={() => setShowConfirmDelete(true)}
          />
        </MobileCardFooter>
      }
    >
      <KeyDataContainer>
        <span>Chave:</span>
        {data?.key}
      </KeyDataContainer>
      <OtherDataContainer>
        <div>
          <span>Tipo: </span>
          {asaasPixType[data?.type]}
        </div>
        <div>
          <span>Criado em: </span>
          {dayjs(data?.dateCreated).format('DD/MM/YY')}
        </div>
      </OtherDataContainer>
    </Card>
  );
  return (
    <>
      {isMobile() ? (
        <MobilePixData />
      ) : (
        <StyledTable
          columns={columns}
          size="small"
          dataSource={[data]}
          pagination={false}
          rowClassName="no-border-row"
        />
      )}

      {showConfirmDelete && (
        <ConfirmModal
          title="Excluir chave Pix"
          alertInfo="Essa ação não poderá ser desfeita"
          description={pixTextDelete}
          onClose={() => setShowConfirmDelete(false)}
          onSubmit={() => handleRemove(data)}
        />
      )}
    </>
  );
};

PixTable.propTypes = {
  data: PropTypes.instanceOf(Object),
  onDeletePix: PropTypes.func
};

export default PixTable;
