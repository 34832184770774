import styled from 'styled-components';
import { colors, spaces } from '../styles/style';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.neutral75};
  overflow: auto;
  align-items: center;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: ${props => (props.padding || props.padding === 0 ? props.padding : `${spaces.space2} 0`)};
  div {
    max-width: ${props => (props.$maxWidth ? props.$maxWidth : '992px')};
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spaces.space2} 0;
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth};`}
`;
