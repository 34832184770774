import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { getObjectWithSelectedFields, padLeft } from '../lib/helpers/helper';
import useCRUD from './useCRUD';
import useUrlParams from './useUrlParams';
import formatNumber from '../lib/helpers/formatNumber';
import { saveQuoteToPurchaseOrderIds } from '../store/quoteToPurchaseOrderIds/actions/action';

const _formatNumber = number => (number ? formatNumber(number) : null);

const usePurchase = ({
  model,
  modelOptions,
  list,
  setList,
  baseUrl,
  isReview,
  setModalProps,
  itemObjectsMap,
  setAddressFormValues,
  forceAddressFormUpdate,
  setLinkedSolicitation,
  setLinkedQuote,
  setExtraValues
}) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user = {} } = useSelector(state => state.authReducer) || {};
  const { purchaseSolicitationStatus, purchaseOrderStatus, purchaseQuoteStatus } = useSelector(
    state => state.setup.enums
  );

  const quoteToPurchaseOrderIds = useSelector(state => state.quoteToPurchaseOrderIds);

  const { foundParams } = useUrlParams({
    urlParams: ['projeto', 'solicitacao', 'cotacao', 'fornecedor']
  });

  const [idRefurbish, setIdRefurbish] = useState(null);
  const [canEditRefurbish, setCanEditRefurbish] = useState(false);
  const [canEditSupplier, setCanEditSupplier] = useState(true);

  const [purchaseFormValues, setPurchaseFormValues] = useState({ priority: 2 });
  const [hasObservation, setHasObservation] = useState(false);
  const [observation, setObservation] = useState('');
  const [idPurchase, setIdPurchase] = useState(null);
  const [idQuote, setIdQuote] = useState(null);
  const [loadingParams, setLoadingParams] = useState(false);

  const forceFormUpdate = useRef(false);
  const preventUpdateWithRefurbishAddress = useRef(false);

  const { idCompany } = user || {};

  const stringsMap = {
    purchase: {
      childrenColumnName: 'purchaseItems',
      levelsColumnName: 'purchaseItemLevels',
      statusColumnName: 'idPurchaseStatus',
      dateColumnName: 'purchaseDate',
      baseName: 'solicitação',
      statusEnum: purchaseSolicitationStatus,
      codePrefix: 'SC',
      shortPath: 's',
      modalConfirmDelete: {
        text: 'Deseja realmente apagar essa solicitação?'
      }
    },
    order: {
      childrenColumnName: 'orderItems',
      levelsColumnName: 'orderItemLevels',
      statusColumnName: 'idOrderStatus',
      dateColumnName: 'orderDate',
      baseName: 'ordem',
      statusEnum: purchaseOrderStatus,
      codePrefix: 'OC',
      shortPath: 'oc',
      modalConfirmDelete: {
        text: 'Ao excluir, a ordem de compra será removida permanente da sua conta e ninguém mais poderá acessá-la.',
        title: 'Apagar ordens de compras?',
        alertInfo: 'Os lançamentos financeiros vinculados também serão excluídos'
      }
    },
    quote: {
      childrenColumnName: 'quoteItems',
      levelsColumnName: 'quoteItemLevels',
      statusColumnName: 'idQuoteStatus',
      dateColumnName: 'responseDate',
      baseName: 'cotação',
      statusEnum: purchaseQuoteStatus,
      codePrefix: 'QT',
      shortPath: 'qt',
      modalConfirmDelete: {
        text: 'Ao excluir, a ordem de compra será removida permanente da sua conta e ninguém mais poderá acessá-la.',
        title: 'Apagar cotação?'
      }
    }
  };

  const {
    childrenColumnName,
    levelsColumnName,
    statusColumnName,
    dateColumnName,
    baseName,
    statusEnum,
    codePrefix,
    shortPath,
    modalConfirmDelete
  } = stringsMap[model];

  const { data, loading, handleGet, handleCreate, handleUpdate } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    immediatelyLoadData: false,
    options: modelOptions
  });

  const { data: refurbish, handleGet: getRefurbish } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const { handleGet: getPurchase } = useCRUD({
    model: 'purchase',
    immediatelyLoadData: false,
    options: {
      where: { idCompany },
      include: [
        'refurbish',
        {
          model: 'purchaseItems',
          include: [
            {
              model: 'unit',
              as: 'units'
            },
            'item',
            {
              model: 'purchaseItemLevel',
              include: ['refurbishItem'],
              attributes: ['id', 'name', 'price', 'quantity', 'percentage', 'idPurchaseItem', 'idRefurbishItem', 'type']
            }
          ]
        }
      ]
    }
  });

  const { handleGet: getQuote } = useCRUD({
    model: 'quote',
    immediatelyLoadData: false
  });

  const applyValuesInForm = ({ values, childrenName, levelsOriginName, levelsDestinationName }) => {
    setPurchaseFormValues({
      priority: values?.priority,
      idResponsible: values?.idResponsible,
      idRefurbish: values?.idRefurbish,
      idSupplier: values?.idSupplier,
      name: values?.name,
      [dateColumnName]: values?.[dateColumnName]
    });
    if (values?.observation) {
      setHasObservation(true);
      setObservation(values?.observation);
    }
    if (values?.idPurchase) {
      setIdPurchase(values?.idPurchase);
    }
    if (values?.idQuote) {
      setIdQuote(values?.idQuote);
    }
    if (values?.[childrenName]?.length) {
      setList(
        values?.[childrenName].map(item => ({
          ...item,
          [levelsDestinationName || levelsOriginName]: item?.[levelsOriginName]?.length
            ? item?.[levelsOriginName]?.map(childItem => ({
                ...childItem,
                idParent: item?.id
              }))
            : null
        }))
      );
    }
    forceFormUpdate.current = true;
  };

  useEffect(() => {
    if (foundParams?.projeto) {
      setIdRefurbish(Number(foundParams?.projeto));
      setPurchaseFormValues(prev => ({ ...prev, idRefurbish: Number(foundParams?.projeto) }));
      forceFormUpdate.current = true;
    }
    if (foundParams?.solicitacao || foundParams?.cotacao || foundParams.fornecedor) {
      setLoadingParams(true);
      if (foundParams?.solicitacao) setIdPurchase(Number(foundParams?.solicitacao));
      if (foundParams?.cotacao) setIdQuote(foundParams?.cotacao);
      if (foundParams?.fornecedor) setCanEditSupplier(false);

      const getFn = foundParams?.solicitacao ? getPurchase : getQuote;
      getFn({
        refetchPathOptions: `/${foundParams?.solicitacao || foundParams?.cotacao}`,
        ...(foundParams?.cotacao && {
          refetchOptions: {
            where: { idCompany },
            include: [
              {
                model: 'purchase',
                attributes: ['id', 'code']
              },
              'refurbish',
              { model: 'supplier', where: { id: foundParams?.fornecedor }, attributes: ['id'] },
              {
                model: 'quoteItems',
                ...(quoteToPurchaseOrderIds?.length && { where: { id: quoteToPurchaseOrderIds } }),
                include: [
                  {
                    model: 'unit',
                    as: 'units'
                  },
                  'item',
                  {
                    model: 'quoteItemSuppliers',
                    required: false,
                    as: 'quoteItemSuppliers',
                    include: [{ model: 'quoteSuppliers', where: { idSupplier: foundParams?.fornecedor } }]
                  },
                  {
                    model: 'quoteItemLevel',
                    include: ['refurbishItem'],
                    attributes: [
                      'id',
                      'name',
                      'price',
                      'quantity',
                      'percentage',
                      'idQuoteItem',
                      'idRefurbishItem',
                      'type'
                    ]
                  }
                ]
              }
            ]
          }
        })
      }).then(response => {
        dispatch(saveQuoteToPurchaseOrderIds(null));
        if (!response?.id) {
          toast.error(`${foundParams?.solicitacao ? 'Solicitação' : 'Cotação'} não encontrada`);
          history.goBack();
          return;
        }
        if (foundParams?.cotacao) {
          setIdPurchase(response?.idPurchase);
          setLinkedSolicitation && setLinkedSolicitation(response?.purchase?.code);
          setLinkedQuote && setLinkedQuote(response?.code);
          setExtraValues(response?.suppliers?.[0]?.quoteSuppliers?.extraValues || {});
        }
        if (foundParams?.solicitacao && setLinkedSolicitation) setLinkedSolicitation(response?.code);
        setIdRefurbish(response?.refurbish?.id);
        setPurchaseFormValues(prev => ({ ...prev, idRefurbish: response?.refurbish?.id }));
        applyValuesInForm({
          values: {
            ...response,
            idSupplier: foundParams.fornecedor || response?.idSupplier,
            ...(foundParams?.cotacao && {
              quoteItems: response?.quoteItems?.reduce((acc, curr) => {
                if (curr?.quoteItemSuppliers?.[0]?.isUnavailable) return acc;

                const _curr = {
                  ...curr,
                  price: curr?.quoteItemSuppliers?.[0]?.price || 0,
                  quantity: curr?.quoteItemSuppliers?.[0]?.quantity || 0
                };

                if (curr?.quoteItemSuppliers?.[0]?.quantity !== curr.quantity) {
                  _curr.quoteItemLevels = curr.quoteItemLevels.map(itemLevel => {
                    return {
                      ...itemLevel,
                      quantity: (itemLevel.percentage / 100) * curr?.quoteItemSuppliers?.[0]?.quantity
                    };
                  });
                }

                acc.push(_curr);
                return acc;
              }, [])
            })
          },
          childrenName: foundParams?.solicitacao ? 'purchaseItems' : 'quoteItems',
          dateName: foundParams?.solicitacao ? 'purchaseDate' : 'responseDate',
          levelsOriginName: foundParams?.solicitacao ? 'purchaseItemLevels' : 'quoteItemLevels',
          levelsDestinationName: stringsMap[model]?.levelsColumnName
        });

        if ((response?.refurbish || foundParams?.cotacao) && setAddressFormValues) {
          setAddressFormValues(
            getObjectWithSelectedFields(foundParams?.cotacao ? response : response?.refurbish, [
              'zipcode',
              'street',
              'number',
              'complement',
              'state',
              'city'
            ])
          );
          // eslint-disable-next-line no-param-reassign
          forceAddressFormUpdate.current = true;
          preventUpdateWithRefurbishAddress.current = true;
        }
        setLoadingParams(false);
      });
    }
  }, [foundParams]);

  useEffect(() => {
    if (refurbish?.id && !preventUpdateWithRefurbishAddress.current && setAddressFormValues) {
      setAddressFormValues(prev => ({
        ...prev,
        ...getObjectWithSelectedFields(refurbish, ['zipcode', 'street', 'number', 'complement', 'state', 'city'])
      }));
      // eslint-disable-next-line no-param-reassign
      forceAddressFormUpdate.current = true;
    }
  }, [refurbish]);

  useEffect(() => {
    if (purchaseFormValues?.idRefurbish) setIdRefurbish(purchaseFormValues?.idRefurbish);
  }, [purchaseFormValues]);

  useEffect(() => {
    if (idRefurbish && !id) getRefurbish({ refetchPathOptions: `/${idRefurbish}` });
  }, [idRefurbish]);

  useEffect(() => {
    if (!list?.length && !foundParams?.projeto) {
      setCanEditRefurbish(true);
      return;
    }
    setCanEditRefurbish(false);
  }, [list, foundParams]);

  useEffect(() => {
    if (data?.id) {
      applyValuesInForm({
        values: data,
        childrenName: childrenColumnName,
        dateName: dateColumnName,
        levelsOriginName: levelsColumnName
      });
    }
  }, [data]);

  const formatListToUpdate = (array, childrenName, cleanIds) =>
    array.map(item => ({
      ...item,
      idCompany,
      idUnit: item?.units?.id || 1,
      [dateColumnName]: item?.purchaseDate,
      ...(cleanIds && { id: undefined }),
      total: Number(item.price) * Number(item.quantity) || 0,
      [childrenName]: item?.[childrenName]?.map(level => ({
        ...level,
        ...(cleanIds && { id: undefined, idParent: undefined }),
        name: level.refurbishItem?.name,
        idRefurbishItem: level.refurbishItem?.id,
        price: item.price
      }))
    }));

  const handleSave = ({ extraFields = {}, status, extraQuery, returning } = {}) => {
    if (!idRefurbish) {
      toast.error(`Não é possível criar uma ${baseName} sem projeto.`);
      return null;
    }
    if (!purchaseFormValues?.name) {
      toast.error(`Informe o nome.`);
      return null;
    }

    const { extraValues } = extraFields;

    const values = {
      ...purchaseFormValues,
      ...extraFields,
      discount: _formatNumber(extraValues?.discount),
      taxes: _formatNumber(extraValues?.taxes),
      shipping: _formatNumber(extraValues?.shipping),
      observation,
      idPurchase,
      idQuote,
      idCompany,
      [statusColumnName]: status || (isReview ? statusEnum.open : data?.[statusColumnName] || statusEnum.draft),
      [childrenColumnName]: formatListToUpdate(list, levelsColumnName, !id),
      ...itemObjectsMap
    };

    const saveFn = id
      ? handleUpdate({
          values,
          refresh: false,
          displayToast: true,
          updateOptions: {}
        })
      : handleCreate({
          id,
          values,
          refresh: false,
          displayToast: true,
          noLoading: returning,
          postOptions: {
            include: [
              {
                model: childrenColumnName,
                include: [
                  {
                    model: levelsColumnName?.slice(0, -1)
                  }
                ]
              }
            ]
          }
        });

    return saveFn.then(response => {
      const _id = response?.id || response?.new?.id;
      if (response?.error || !_id) return null;
      if (returning) return response?.new?.id ? response.new : response;
      let query = '';
      if (foundParams?.projeto && extraQuery) query = `?projeto=${idRefurbish}&${extraQuery}`;
      else if (foundParams?.projeto) query = `?projeto=${idRefurbish}`;
      else if (extraQuery) query = `?${extraQuery}`;

      history.push(`${baseUrl}/visualizar/${_id}${query}`);
      return null;
    });
  };

  const validateSubmit = ({ extraFields, callback }) => {
    let everyItemLinked = true;
    let everyItemWithAppropriation = true;
    let validAppropriations = true;

    if (!list?.length) {
      toast.error(`Não é possível criar uma ${baseName} sem itens.`);
      return false;
    }

    if (model === 'order' && !purchaseFormValues?.idSupplier) {
      toast.error('Selecione um fornecedor.');
      return false;
    }

    if (model === 'order' && !extraFields?.isPaymentResponsibleValid) {
      toast.error('Selecione uma opção de faturamento.');
      return false;
    }

    if (model === 'order' && !extraFields?.paymentInfo?.list?.length) {
      toast.error('Selecione uma condição de pagamento.');
      return false;
    }

    if (model === 'order' && !extraFields?.paymentTypes?.length) {
      toast.error('Selecione uma forma de pagamento.');
      return false;
    }

    if (model === 'order' && extraFields?.paymentInfo?.list?.find(i => i.price < 0 || i.percentage < 0)) {
      toast.error('O valor deve ser maior que 0');
      return false;
    }

    if (!purchaseFormValues?.name) {
      toast.error('Informe um nome.');
      return false;
    }

    list?.forEach(item => {
      if (!item?.idItem && !item.externalBaseCode) {
        everyItemLinked = false;
      }
      if (!item?.[levelsColumnName]?.length) {
        everyItemWithAppropriation = false;
        return;
      }

      const totalPercentage = item?.[levelsColumnName]?.reduce((acc, cur) => acc + (Number(cur?.percentage) || 0), 0);
      const totalQuantity = item?.[levelsColumnName]?.reduce((acc, cur) => acc + (Number(cur?.quantity) || 0), 0);

      if (Math.abs(totalPercentage - 100) >= 0.01) validAppropriations = false;
      if (Math.abs(Number(item.quantity) - totalQuantity) >= 0.1) validAppropriations = false;
    });

    if (!validAppropriations) {
      toast.error('A soma das apropriações deve ser igual a 100%.');
      return false;
    }

    if (!everyItemLinked && !everyItemWithAppropriation && isReview) {
      setModalProps({
        show: true,
        text: `Existem insumos não vinculados com a biblioteca e não apropriados.
          Caso prossiga com a ${id ? 'edição' : 'criação'} da ${baseName}, esses itens:`,
        list: [
          'Os valores de consumo e custo desse item não serão agrupados corretamente.',
          'Não serão contabilizados em nenhum nível existente no seu Orçamento'
        ],
        callback
      });

      return false;
    }

    if ((!everyItemLinked || !everyItemWithAppropriation) && isReview) {
      setModalProps({
        show: true,
        text: !everyItemLinked
          ? `Existem insumos não vinculados à biblioteca. Caso prossiga com a ${id ? 'edição' : 'criação'}
               da ${baseName}, os valores de consumo e custo desse item não serão agrupados corretamente.`
          : `Existem insumos não apropriados. Caso prossiga com a ${id ? 'edição' : 'criação'} da ${baseName},
                esse item não será contabilizado em nenhum nível existente no seu Orçamento`,
        list: null,
        callback
      });
      return false;
    }

    if (callback) return callback();

    return true;
  };

  const getCode = () =>
    handleGet({
      refetchPathOptions: '',
      refetchOptions: { where: { idCompany }, attributes: ['code'], order: [['code', 'desc']], limit: 1 },
      generateLoading: false
    }).then(response => {
      if (response?.error) return `${codePrefix}${padLeft('0', 5)}`;

      const code = response?.[0]?.code ? Number(response[0].code.replace(codePrefix, '')) : 0;

      return `${codePrefix}${padLeft(String(code + 1), 5)}`;
    });

  return {
    id,
    data,
    loading: loading || loadingParams,
    modelGet: handleGet,
    modelCreate: handleCreate,
    modelUpdate: handleUpdate,
    idRefurbish,
    refurbish,
    forceFormUpdate,
    canEditRefurbish,
    canEditSupplier,
    purchaseFormValues,
    setPurchaseFormValues,
    handleSave,
    validateSubmit,
    itemObjectsMap,
    hasObservation,
    setHasObservation,
    observation,
    setObservation,
    shortPath,
    getCode,
    modalConfirmDelete
  };
};

export default usePurchase;
