// @Todo fix to use toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

export default (amount, props = {}) => {
  const defaultProps = {
    decimalCount: 2,
    decimal: ',',
    thousands: '.',
    ...props
  };
  const { decimal, thousands, currencySymbol } = defaultProps;
  let { decimalCount } = defaultProps;

  try {
    decimalCount = Math.abs(decimalCount);
    // eslint-disable-next-line no-restricted-globals
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    // eslint-disable-next-line no-param-reassign
    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 10).toString();
    const j = i?.length > 3 ? i?.length % 3 : 0;

    return (
      (currencySymbol ? `${negativeSign}${currencySymbol}` : negativeSign) +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            ?.toFixed(decimalCount)
            ?.slice(2)
        : '')
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return '';
  }
};
