import styled from 'styled-components';
import { borderSolid, breakpoints, colors, spaces } from '../../styles/style';

const StepBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StepHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spaces.space2} 0 0;
  gap: ${spaces.space1};

  @media (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  margin-left: ${spaces.space1};

  @media (min-width: ${breakpoints.tablet}) {
    min-width: 80%;
    margin-left: -${spaces.space3};
  }
`;

const CompletitionBar = styled.div`
  width: 100%;
  height: ${spaces.space0};
  background: linear-gradient(
    to right,
    ${colors.primary300} 0%,
    ${colors.primary300} ${props => props.$progression},
    ${colors.primary100} ${props => props.$progression},
    ${colors.primary100} 100%
  );
`;

const StepContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  @media (min-width: ${breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: ${borderSolid};
  padding: ${spaces.space1} ${spaces.space2};

  @media (min-width: ${breakpoints.tablet}) {
    padding: ${spaces.space1} 0;
  }
`;

const StepFooter = styled.div`
  display: flex;
  justify-content: ${props => (props.$flexEnd ? 'flex-end' : 'space-between')};
  align-self: center;
  width: 100%;

  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: row;
    width: 80%;
  }
`;

const ForwardButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: ${spaces.space2};

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export { StepBox, StepHeader, LogoContainer, CompletitionBar, FooterContainer, StepContent, StepFooter, ForwardButton };
