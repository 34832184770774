import { SETUP } from '../action/action';

const DEFAULT_STATE = {
  enums: null,
  refurbishFlow: null,
  systemData: null,
  categories: null
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SETUP.ENUMS:
      return {
        ...state,
        enums: action.payload.enumData,
        refurbishFlow: action?.payload?.systemData?.refurbishFlowData,
        systemData: action.payload.systemData
      };
    case SETUP.PLANS:
      return {
        ...state,
        plans: action.payload
      };
    case SETUP.PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
