import { useState } from 'react';

const useItemWithAppropriation = () => {
  const [createdItems, setCreatedItems] = useState({});
  const [updatedItems, setUpdatedItems] = useState({});
  const [deletedItems, setDeletedItems] = useState({});
  const [createdLevels, setCreatedLevels] = useState({});
  const [updatedLevels, setUpdatedLevels] = useState({});
  const [deletedLevels, setDeletedLevels] = useState({});

  const formatListToUpdate = (list, childrenName, cleanIds) =>
    list.map(item => ({
      ...item,
      idUnit: item?.units?.id || 1,
      ...(cleanIds && { id: undefined }),
      total: Number(item.price) * Number(item.quantity) || 0,
      [childrenName]: item?.[childrenName]?.map(level => ({
        ...level,
        ...(cleanIds && { id: undefined, idParent: undefined }),
        name: level.refurbishItem?.label,
        price: item.price,
        idRefurbishItem: level.refurbishItem?.value
      }))
    }));

  const clearStates = () => {
    setCreatedItems({});
    setUpdatedItems({});
    setDeletedItems({});
    setCreatedLevels({});
    setUpdatedLevels({});
    setDeletedLevels({});
  };

  return {
    itemObjectsMap: { createdItems, updatedItems, deletedItems, createdLevels, updatedLevels, deletedLevels },
    itemSettersMap: {
      setCreatedItems,
      setUpdatedItems,
      setDeletedItems,
      setCreatedLevels,
      setUpdatedLevels,
      setDeletedLevels
    },
    formatListToUpdate,
    clearStates
  };
};

export default useItemWithAppropriation;
