import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { faArrowLeft, faInfoCircle, faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollarPen, faReceipt } from '@fortawesome/pro-duotone-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Context } from '../../contexts/GeneralContext';
import {
  Info,
  ExitCustomerViewButton,
  TextBox,
  Icon,
  IconLock,
  PaymentSteps,
  MobilePaymentSteps,
  Container
} from './Payment.style';
import PaymentBasicForm from '../../components/Form/PaymentBasicForm';
import PaymentFormItem from '../../components/Form/PaymentFormItem';
import PaymentAttachmentContent from '../../components/Content/PaymentAttachmentContent';
import vobiPayIcon from '../../components/Images/vobiPayIcon.svg';

import CenteredLoader from '../../components/Loader/CenteredLoader';
import PaymentNotesForm from '../../components/Form/PaymentNotesForm';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import Card from '../../components/Card/Card';
import { Div, colors, fonts, spaces } from '../../styles/style';
import PaymentVobiPay from '../../components/Form/PaymentVobiPay';
import { HelperLink, Paragraph, Subtitle, Title } from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import BubbleModalButton from '../../components/Button/BubbleModalButton';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import PaymentConfiguration from '../../components/Payments/PaymentConfiguration';
import BillingManagerAccordion from '../../components/Accordion/BillingManagerAccordion';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import Label from '../../components/Label/Label';
import MaskedInput from '../../components/Input/NewMaskedInput';
import formatNumber from '../../lib/helpers/formatNumber';
import useCRUD from '../../_Hooks/useCRUD';
import FineInterestDiscount from '../../components/Form/FineInterestDiscount';
import PaymentInvoiceList from '../../components/List/PaymentInvoiceList';
import ImportInvoiceXMLModal from '../../components/Modal/ImportInvoiceXMLModal';
import ProductsConciliation from './ProductsConciliation';
import { Line } from '../../components/Line/Line';
import { ComponentToPdf } from '../../lib/helpers/exportToPdf';
import FinancialEventService from '../../lib/gtm/financial';
import CreatedByContent from '../../components/Content/CreatedByContent';

const PaymentContent = ({
  contextData,
  isRevision,
  handleBackFromCustomerView,
  isMobile,
  _isView,
  id,
  setSelectedTab,
  selectedTab,
  props,
  idRefurbish,
  currentStep,
  setCurrentStep,
  closeDrawer,
  withoutImportXML
}) => {
  const {
    values,
    isCustomerView,
    onSubmitDropdown,
    setField,
    company,
    isPublic,
    isCancelled,
    _user,
    isCustomer,
    isConciliation,
    isIdOrderNullOrArray,
    setUser,
    showProductsConciliation,
    setShowProductsConciliation,
    paymentToCreate,
    setPaymentToCreate,
    hasPermissionVP,
    isPrinting,
    setIsPrinting,
    handleBack
  } = contextData;
  const vobiPayAccount = company?.idAsaas && company.accountStatus?.general === 'APPROVED';
  const { plans, permissions, enums } = useSelector(state => state.setup);
  const { extraValuesPaymentType, paymentPermissions } = enums || {};
  const { financialOwnBusiness, projectSupplier, projectOwnBusiness, financialSupplier } = paymentPermissions || {};
  const { user } = useSelector(state => state.authReducer) || {};

  const {
    isVobiPay,
    idOrder,
    total,
    dueDateDiscount,
    dueDateDiscountType,
    dueDateLimitDays,
    fine,
    fineType,
    interest,
    dueDate,
    installmentCount,
    paymentTypes
  } = values || {};
  const history = useHistory();
  const hasPaymentPermission = hasPermission(
    _user,
    [projectSupplier, financialSupplier, projectOwnBusiness, financialOwnBusiness],
    plans,
    permissions
  );
  const [incomeValue, setIncomeValue] = useState(null);
  const [showIncomeValueModal, setShowIncomeValueModal] = useState(false);
  const gtmEventService = FinancialEventService();

  const printComponentRef = useRef();

  const [importXMLType, setImportXMLType] = useState(null);

  const { loading, handleUpdate: handleUpdateCompany } = useCRUD({
    model: 'company',
    immediatelyLoadData: false
  });

  const handleSelectPaymentType = type => {
    setField('isVobiPay')(type === 'vobi');
    type === 'vobi' && setField('ownBusiness')(true);
  };

  const handleImportXML = type => {
    setImportXMLType(type);
  };

  const handleImportSuccess = data => {
    setPaymentToCreate(data);
    setImportXMLType(null);
    setShowProductsConciliation(true);
  };

  useEffect(() => {
    if (!isVobiPay && currentStep === 1) return;
    if (!id && isVobiPay) {
      extraValuesPaymentType?.asaas?.forEach(field => setField(field)(user?.company?.fineInterestDiscount?.[field]));
      setField('dueDateLimitDays')(0);
    }
    if (isVobiPay !== undefined && !isPublic && currentStep < 3 && !isConciliation && isIdOrderNullOrArray) {
      setCurrentStep(prev => prev + 1);
    }

    if (!id && isVobiPay && !_user.company?.incomeValue && (_user.isOwner || _user.groupPermission === 1)) {
      setShowIncomeValueModal(true);
    }
  }, [isVobiPay]);

  const RenderTabs = (
    <Tabs activeKey={selectedTab} onChange={setSelectedTab}>
      <TabPane key="0" tab="Detalhes">
        <Div direction="column" gap={spaces.space2} $fullWidth align="start">
          <PaymentBasicForm />
          <Line />
          <PaymentFormItem />
          <>
            {(values?.installments?.length > 0 ||
              (idOrder && !values?.isVobiPay) ||
              (!isRevision && !values?.isVobiPay && !id && !isConciliation && isIdOrderNullOrArray)) && (
              <>
                <Line />
                <PaymentConfiguration
                  isVobiPay={isVobiPay}
                  onSubmitDropdown={onSubmitDropdown}
                  idRefurbish={idRefurbish}
                />
              </>
            )}
          </>
          <CreatedByContent data={values} showLine />
        </Div>
      </TabPane>
      <TabPane key="1" tab="Anexos, notas fiscais e anotações">
        <Div direction="column" gap={spaces.space2} $fullWidth>
          <PaymentAttachmentContent />
          <Line />
          <PaymentInvoiceList />
          <Line />
          <PaymentNotesForm />
        </Div>
      </TabPane>
    </Tabs>
  );

  const CommonPayment = (
    <Card
      id="common-vobipay"
      bodyBackgroundColor={hasPaymentPermission ? colors.neutral50 : colors.neutral100}
      onClick={() => handleSelectPaymentType('common')}
      bodyPadding={spaces.space1}
      hover={hasPaymentPermission}
    >
      <Div gap={spaces.space2} style={{ position: 'relative' }}>
        <Icon disabled={!hasPaymentPermission} style={{ minWidth: spaces.space10, minHeight: spaces.space8 }}>
          <FontAwesomeIcon
            icon={faMoneyCheckDollarPen}
            size="2x"
            color={!hasPaymentPermission ? colors.neutral400 : colors.primary300}
          />
        </Icon>
        <TextBox>
          <Title disabled={!hasPaymentPermission}>Lançamento manual</Title>
          <Paragraph>Registre pagamentos para seu negócio ou fornecedores.</Paragraph>
        </TextBox>
        {!hasPaymentPermission && (
          <IconLock>
            <FontAwesomeIcon icon={faLock} color={colors.neutral500} />
          </IconLock>
        )}
      </Div>
    </Card>
  );

  const ImportXML = (
    <Card
      id="import-xml-payment"
      bodyBackgroundColor={hasPaymentPermission ? colors.neutral50 : colors.neutral100}
      bodyPadding={spaces.space1}
    >
      <Div gap={spaces.space2} justify="space-between" style={{ position: 'relative' }}>
        <Div gap={spaces.space2}>
          <Icon disabled={!hasPaymentPermission} style={{ minWidth: spaces.space10, minHeight: spaces.space8 }}>
            <FontAwesomeIcon
              icon={faReceipt}
              size="2x"
              color={!hasPaymentPermission ? colors.neutral400 : colors.primary300}
            />
          </Icon>
          <TextBox>
            <Title disabled={!hasPaymentPermission}>Importação de XML</Title>
            <Paragraph>Selecione o tipo do pagamento para importar XML de uma NF-e.</Paragraph>
          </TextBox>
        </Div>

        {hasPaymentPermission ? (
          <Div gap={spaces.space1}>
            <Button onClick={() => handleImportXML('charge')} type="primary">
              Lançamento manual
            </Button>

            {vobiPayAccount && hasPermissionVP && (
              <Button onClick={() => handleImportXML('vobipay')} type="primary">
                Vobi Pay
              </Button>
            )}
          </Div>
        ) : (
          <IconLock>
            <FontAwesomeIcon icon={faLock} color={colors.neutral500} />
          </IconLock>
        )}
      </Div>
    </Card>
  );

  const RenderPaymentType = (
    <Div gap={spaces.space1} direction="column" align="flex-start" margin={spaces.space2}>
      <Subtitle>Selecione o tipo do pagamento</Subtitle>
      {hasPermissionVP && (
        <Card
          id="payment-vobipay"
          bodyBackgroundColor={colors.neutral50}
          onClick={() => handleSelectPaymentType('vobi')}
          bodyPadding={spaces.space1}
          hover
          disabled={!vobiPayAccount}
        >
          <Div style={{ justifyContent: 'space-between' }}>
            <Div gap={spaces.space2}>
              <div id="vobi-pay-icon">
                <img
                  style={{ minWidth: spaces.space10, minHeight: spaces.space8 }}
                  src={vobiPayIcon}
                  alt="Icon Vobi Pay"
                />
              </div>
              <TextBox>
                <Title>Cobrança via Vobi Pay</Title>
                <Paragraph>Automatize sua gestão de cobranças e receba via cartão, boleto ou pix.</Paragraph>
              </TextBox>
            </Div>

            {!vobiPayAccount && (
              <div>
                <Button
                  onClick={() => {
                    closeDrawer();
                    history.push(`/profissional/vobipay/abertura`);
                  }}
                  type="primary"
                >
                  Crie sua conta
                </Button>
              </div>
            )}
          </Div>
        </Card>
      )}
      {hasPaymentPermission ? (
        CommonPayment
      ) : (
        <>{hasPaymentPermission === null && <BubbleModalButton feature="payment">{CommonPayment}</BubbleModalButton>}</>
      )}
      {hasPaymentPermission ? (
        <>{!isMobile() && !withoutImportXML && ImportXML}</>
      ) : (
        <>
          {hasPaymentPermission === null && !isMobile() && !withoutImportXML && (
            <BubbleModalButton feature="payment">{ImportXML}</BubbleModalButton>
          )}
        </>
      )}
    </Div>
  );

  const RenderFine = (
    <Container $noMargin>
      <FineInterestDiscount
        idButton="HowJMDNewInvoice"
        isPayment
        values={{
          dueDateDiscount,
          dueDateDiscountType,
          dueDateLimitDays,
          fine,
          fineType,
          interest,
          dueDate,
          installmentCount,
          paymentTypes
        }}
        onChangeValues={({ field, value }) => setField(field)(value)}
        total={total / installmentCount}
      />
      <Div padding={spaces.space2}>
        <Paragraph type="small">
          Para configurar juros, multa e desconto padrão para todas as cobranças, acesse{' '}
          <HelperLink
            size={fonts.sizeSm}
            onClick={() => window.open(`/profissional/configuracoes/vobipay?tab=2`, '_blank')}
            target="_blank"
          >
            Configurações &gt; Vobi Pay &gt; Juros, multa e desconto.
          </HelperLink>
        </Paragraph>
      </Div>
    </Container>
  );

  const renderPayment = {
    0: RenderPaymentType,
    1: RenderTabs,
    2: <PaymentVobiPay />,
    3: RenderFine
  };

  const renderLabelStep = {
    1: 'Dados da cobrança',
    2: 'Forma de pagamento',
    3: 'Juros, multa e descontos',
    4: 'Revisão'
  };

  const RenderSteps = isMobile() ? (
    <MobilePaymentSteps>
      <Paragraph type="small">{renderLabelStep[isRevision ? currentStep + 1 : currentStep]}</Paragraph>
      <Paragraph type="small">{`Passo ${isRevision ? currentStep + 1 : currentStep} de 4`}</Paragraph>
    </MobilePaymentSteps>
  ) : (
    <PaymentSteps
      size="small"
      current={isRevision ? currentStep : currentStep - 1}
      items={Array(4)
        .fill()
        .map((_, index) => ({ title: renderLabelStep[index + 1] }))}
    />
  );

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
      gtmEventService.onExport({ entity: 'invoice', fileType: 'pdf' });
    }
  });

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting]);

  return (id && !values?.id) || isCancelled ? (
    <CenteredLoader />
  ) : (
    <>
      {currentStep > 0 && isVobiPay && !id && RenderSteps}
      {(isRevision || (isCustomerView && !isCustomer)) && (
        <Info id="info-customer-view">
          {isCustomerView && !isCustomer && (
            <ExitCustomerViewButton
              type="text"
              primaryBorderColor="primary700"
              size={1}
              onClick={() => handleBackFromCustomerView()}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <div id="exit-view-as-client" className="hideMobile">
                Sair deste modo
              </div>
            </ExitCustomerViewButton>
          )}
          <FontAwesomeIcon icon={faInfoCircle} size="lg" />
          {isVobiPay ? (
            <span>Revise as informações. Ao criar o pagamento seu cliente será notificado.</span>
          ) : (
            <span>
              Você está vendo o que seu cliente verá se você compartilhar o pagamento
              {!isCustomerView && ' após a criação'}.
            </span>
          )}
        </Info>
      )}
      <Context data={contextData}>
        <Container {...props} $noMargin>
          {showProductsConciliation ? (
            <ProductsConciliation
              idRefurbish={paymentToCreate?.idRefurbish ? Number(paymentToCreate?.idRefurbish) : null}
              products={paymentToCreate?.products}
              onProductsChange={products => {
                setPaymentToCreate(prev => {
                  const _payment = { ...prev };
                  _payment.products = products;
                  return _payment;
                });
              }}
            />
          ) : (
            <>
              {_isView || isRevision ? (
                <>
                  <ComponentToPdf style={{ height: '100%', overflow: 'auto' }} ref={printComponentRef}>
                    <Div
                      $backgroundColor={colors.white}
                      direction="column"
                      gap={spaces.space2}
                      $fullWidth
                      padding={`0 0 ${spaces.space2} 0`}
                    >
                      <PaymentBasicForm />
                      <Line />
                      <PaymentFormItem />
                      <Line />
                      <PaymentConfiguration onSubmitDropdown={onSubmitDropdown} idRefurbish={idRefurbish} />
                      <Line />
                      <PaymentNotesForm />
                      <Line />
                      <PaymentAttachmentContent />
                      <Line />
                      <PaymentInvoiceList />
                      {isVobiPay && !isCustomerView && !isCustomer && !isPublic && (
                        <>
                          <Line />
                          <BillingManagerAccordion isView={_isView} />
                        </>
                      )}
                    </Div>
                  </ComponentToPdf>
                  <CreatedByContent data={values} showLine />
                </>
              ) : (
                <>{id ? RenderTabs : renderPayment[currentStep]}</>
              )}
            </>
          )}
        </Container>
      </Context>
      {importXMLType && (
        <ImportInvoiceXMLModal
          idRefurbish={idRefurbish}
          type={importXMLType}
          onClose={() => setImportXMLType(null)}
          onImportSuccess={handleImportSuccess}
        />
      )}

      {showIncomeValueModal && (
        <ConfirmModal
          title="Atualize seus dados"
          loading={loading}
          disableButton={!incomeValue}
          onlySubmit
          text={
            <Div direction="column" gap={spaces.space2}>
              <Paragraph>Para continuar utilizando a Vobi Pay, informe o faturamento mensal do seu negócio.</Paragraph>
              <Paragraph type="small">
                Esta informação é exigida pelo Banco Central do Brasil pela{' '}
                <HelperLink
                  href="https://normativos.bcb.gov.br/Lists/Normativos/Attachments/50905/Circ_3978_v5_P.pdf"
                  target="_blank"
                >
                  Circular Nº 3.978.
                </HelperLink>
              </Paragraph>
              <Div direction="column" key="valueInput" $fullWidth align="start">
                <Label>Informe sua média de faturamento ou renda mensal*</Label>
                <MaskedInput
                  id="valueInput"
                  type="currency"
                  placeholder="R$10.000,00"
                  value={incomeValue}
                  onChange={e => {
                    setIncomeValue(e.target.value ? formatNumber(e.target.value) : null);
                  }}
                  maskOptions={{
                    prefix: 'R$',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true
                  }}
                />
              </Div>
            </Div>
          }
          submitText="Salvar"
          cancelText="Atualizar depois"
          onSubmit={() => {
            const data = { ..._user.company, incomeValue };
            handleUpdateCompany({ id: company.id, values: data }).then(() => {
              setUser({ ..._user, company: data });
              setShowIncomeValueModal(false);
            });
          }}
          onClose={() => {
            setShowIncomeValueModal(false);
            return handleBack();
          }}
          modalWidth={460}
        />
      )}
    </>
  );
};

PaymentContent.propTypes = {
  contextData: PropTypes.instanceOf(Object),
  isRevision: PropTypes.bool,
  handleBackFromCustomerView: PropTypes.func,
  isMobile: PropTypes.func,
  _isView: PropTypes.bool,
  id: PropTypes.string,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.instanceOf(Object),
  props: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.number,
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  closeDrawer: PropTypes.func,
  withoutImportXML: PropTypes.bool
};

export default PaymentContent;
