import React from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import { NotesListHeader, HeaderFlex } from './NotesHeader.style';
import Button from '../Button/Button';

const NotesHeader = ({ counter, onClick, isApply }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  return (
    <NotesListHeader>
      <HeaderFlex>
        <h3 id="notes-counter">{`${counter} Nota${counter > 1 ? 's' : ''}`}</h3>
        {!isApply && <HowDoesItWorksButton id="howProjectNotesWorks" />}
      </HeaderFlex>
      {!isApply && !isCustomer ? (
        <Button ghost id="new-note-icon" type="primary" onClick={onClick}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      ) : null}
    </NotesListHeader>
  );
};

NotesHeader.propTypes = {
  counter: PropTypes.number,
  onClick: PropTypes.func,
  isApply: PropTypes.bool
};

NotesHeader.defaultProps = {
  counter: 0,
  onClick: f => f
};

export default NotesHeader;
