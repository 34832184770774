/* eslint-disable */
import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faTrashCan } from '@fortawesome/pro-regular-svg-icons';

import { store } from '../../config/redux-store';
import ImageInput from '../../../components/Input/ImageInput';
import formatCurrency from '../../helpers/formatCurrency';
import EditableInput from '../../../components/Input/EditableInput';
import formatNumber from '../../helpers/formatNumber';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';
import { colors, spaces } from '../../../styles/style';
import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import { isImgUrl, replaceDecimalNumber } from '../../helpers/helper';
import SpecificationDropDown from '../../../components/Dropdown/SpecificationDropDown';
import formatBdi from '../../helpers/formatBdi';
import TreeSelectWrapper from '../../../components/Table/TreeSelectWrapper';
import { specificationDropDown } from '../Dropdown/specificationDropdown';
import ExpandAllButton from '../../../components/Button/ExpandAllButton';
import { NameColumnContainer } from './columns.styles';
import SelectDropdown from '../../../components/Dropdown/SelectDropdown';
import Select from '../../../components/Select/Select';
import ItemSelect from '../../../components/Select/ItemSelect';
import Button from '../../../components/Button/Button';

const columns = ({
  statusArray = [],
  serializedStatusArray,
  readOnly,
  isUserAnonymous,
  idReference,
  handleChange = f => f,
  handleAfterChange = f => f,
  forceCellUpdate,
  lastAdd,
  printColumns = [],
  isTemplate,
  supplierList,
  groupList,
  categoryList,
  unitList,
  levelList = [],
  refetchLists = f => f,
  setShowUnitItem = f => f,
  hasPermission,
  refetchOptions,
  groupModel,
  columnsToShow = {},
  referenceKey,
  isApply,
  isMobile,
  productBdi,
  laborBdi,
  handleToggleAllRow,
  idCompany,
  handleRemoveVirtualIRow
}) => {
  const isPrint = !!printColumns?.length;
  const isGrouped = !!groupModel;
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;
  const { refurbishItemTypes = [] } = setup.systemData;

  const allColumns = [
    {
      title: 'Nº',
      dataIndex: 'number',
      width: 60,
      key: 'number',
      render: (val, row) => {
        return (
          <div
            key={`number${row.id}`}
            style={{ display: 'flex', alignItems: 'center', minHeight: spaces.space4, marginLeft: spaces.space0 }}
          >
            {row?.index}
          </div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Código',
      dataIndex: 'code',
      width: 80,
      key: 'code',
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        const libraryCode = row.item?.code;

        return (
          refurbishItemType?.notParent?.includes(row?.type) && (
            <EditableInput
              key={`code${row.id}`}
              noWrap
              value={libraryCode || row?.code}
              row={row}
              width="100%"
              onChange={handleChange(row, 'code')}
              disabled={!!readOnly || row.isDisabled || isApply || libraryCode || row?.externalBaseCode}
              readOnly
              rowId={row.id}
            />
          )
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.code !== nextRecord.code
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      width: 64,
      key: 'type',
      render: (val, row) => {
        const typeName = refurbishItemTypes?.find(r => r.value === row.type)?.label;
        return (
          <div
            key={`type${row.id}`}
            style={{ display: 'flex', alignItems: 'center', minHeight: spaces.space4, marginLeft: spaces.space0 }}
          >
            {typeName}
          </div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: isTemplate ? 'Item' : () => <ExpandAllButton handleToggleAllRow={handleToggleAllRow} />,
      printTitle: 'Item',
      dataIndex: 'name',
      key: 'name',
      width: 450,
      renderCollapseIcon: true,
      sorter: !isPrint && !isTemplate,
      ...(isGrouped && { defaultSortOrder: 'ascend' }),
      alwaysShow: true,
      render: (val, row) => {
        const { onClick = f => f } = row;
        const link = isImgUrl(row.link) ? row.link : `${row.link || ''}`;
        const typeName = refurbishItemTypes?.find(r => r.value === row.type)?.label;
        const linkedItem = row.idItem || row.externalBaseCode;
        const itemName = row.item?.name || row.name;
        const itemImage = row.item?.images?.[0] || row.image;
        const isProduct = row.type === refurbishItemType.product || row?.item?.type === refurbishItemType.product;
        const hasImage = row.images?.length || row.item?.images?.length;

        return (
          <NameColumnContainer className="table-name" padding={isPrint && spaces.space0} gap={isPrint && spaces.space1}>
            {refurbishItemType.notParent?.includes(row.type) && (
              <Tooltip title={typeName}>
                <div className={columnsToShow?.image && isProduct && hasImage && 'table-name-image-margin'}>
                  <ImageInput
                    cors={isPrint}
                    key={`image${row.id}`}
                    id={row.id}
                    value={
                      !columnsToShow?.image && row.type === refurbishItemType.product
                        ? 'boxOpen'
                        : itemImage || 'boxOpen'
                    }
                    images={row.images}
                    onSubmit={({ image }) => {
                      const spliceValue = row.images?.length ? row.images?.slice(1) : [];
                      handleChange(row, 'images')([image, ...spliceValue]);
                    }}
                    disabled={!!readOnly || row.isDisabled || isPrint || !!val || isApply || linkedItem}
                    onClick={onClick}
                    labelMargin={`0 ${spaces.space0} 0 0`}
                    size={isPrint ? spaces.space8 : spaces.space4}
                  />
                </div>
              </Tooltip>
            )}
            {isPrint || (!row.type && row.id === 1) ? (
              <>
                {link ? (
                  <a href={link} target="_blank">
                    {itemName || '-'}
                  </a>
                ) : (
                  <div>{itemName}</div>
                )}
              </>
            ) : (
              <ItemSelect
                onChange={handleChange}
                value={row}
                disabled={readOnly || isApply || !!row?.idItem}
                onClick={onClick}
                initEdit={
                  (!refurbishItemType?.notParent?.includes(row?.type) && lastAdd?.current === row.id) || row?.isVirtual
                }
                idCompany={idCompany}
                handleDelete={handleRemoveVirtualIRow}
                placeholder={`Pesquise na biblioteca ou adicione um ${isProduct ? 'produto' : 'serviço'}`}
                isMobile={isMobile}
              />
            )}
            {!isPrint && !isApply && linkedItem ? (
              <Tooltip title={row.idItem ? 'Vinculado com a biblioteca' : 'SINAPI'}>
                <FontAwesomeIcon className="linked-icon" icon={faBookmark} size="lg" color={colors.neutral500} />
              </Tooltip>
            ) : null}
          </NameColumnContainer>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord?.name !== nextRecord?.name ||
        prevRecord?.link !== nextRecord?.link ||
        prevRecord?.idItem !== nextRecord?.idItem ||
        prevRecord?.externalBaseCode !== nextRecord?.externalBaseCode
    },
    {
      title: 'Nível',
      dataIndex: ['parent', 'name'],
      width: 250,
      key: 'idParent',
      sorter: !isPrint && !isTemplate,
      alwaysShow: isGrouped,
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;
        return (
          <TreeSelectWrapper
            _key={`level${row.id}`}
            disabled={!!readOnly || row.isDisabled || isApply}
            value={row?.parent?.name}
            onChange={handleChange(row, 'idParent')}
            levelList={levelList}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.idParent !== nextRecord.idParent
    },
    {
      title: 'Fornecedor',
      dataIndex: 'idSupplier',
      key: 'idSupplier',
      width: 120,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;
        return isPrint ? (
          val?.name
        ) : (
          <Select
            $light
            allowCreate
            name="Fornecedor"
            allowClear
            options={supplierList}
            id={`supplier${val}`}
            model="supplier"
            onChange={idSupplier => {
              handleChange(row, 'idSupplier')(idSupplier || 1);
            }}
            value={val}
            disabled={readOnly || isApply || !!row?.idItem}
            readOnly
            onClick={e => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
            placeholder="-"
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idSupplier !== nextRecord.idSupplier || forceCellUpdate === 'supplier'
    },
    {
      title: 'Grupo',
      dataIndex: 'idRefurbishGroup',
      key: 'idRefurbishGroup',
      width: 120,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;
        return isPrint ? (
          val?.name
        ) : (
          <Select
            $light
            allowCreate
            name="Grupo"
            allowClear
            options={groupList}
            id={`refurbishGroup${val}`}
            model="refurbishGroup"
            onChange={idRefurbishGroup => {
              handleChange(row, 'idRefurbishGroup')(idRefurbishGroup || 1);
            }}
            value={val}
            disabled={readOnly || isApply || !!row?.idItem}
            readOnly
            onClick={e => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
            placeholder="-"
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idRefurbishGroup !== nextRecord.idRefurbishGroup || forceCellUpdate === 'group'
    },
    {
      title: 'Categoria',
      dataIndex: 'idCostCenter',
      key: 'idCostCenter',
      width: 120,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;
        return isPrint ? (
          val?.name
        ) : (
          <Select
            $light
            allowCreate
            name="Categoria"
            allowClear
            options={categoryList}
            id={`costCenter${val}`}
            model="costCenter"
            onChange={idCostCenter => {
              handleChange(row, 'idCostCenter')(idCostCenter || 1);
            }}
            value={val}
            disabled={readOnly || isApply || !!row?.idItem}
            readOnly
            onClick={e => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
            placeholder="-"
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idCostCenter !== nextRecord.idCostCenter || forceCellUpdate === 'category'
    },
    {
      title: 'Un.',
      dataIndex: 'idUnit',
      key: 'units',
      width: 70,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;

        const unit = row?.item?.units || row?.units;

        return isPrint ? (
          <div>{unit?.name || '-'}</div>
        ) : (
          <Select
            $light
            name="Unidade"
            allowCreate={hasPermission}
            allowClear
            options={unitList}
            id={`unit${val}`}
            model="unit"
            onChange={idUnit => {
              handleChange(row, 'idUnit')(idUnit || 1);
            }}
            value={val}
            disabled={readOnly || isApply || !!row?.idItem}
            readOnly
            onClick={e => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idUnit !== nextRecord.idUnit || forceCellUpdate === 'unit'
    },
    {
      title: 'Altura',
      dataIndex: 'height',
      key: 'height',
      width: 90,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        const isLinked = row?.idItem || row?.externalBaseCode;
        const _height = formatNumber(val) ? formatCurrency(val) : null;

        return (
          refurbishItemType?.notParent?.includes(row?.type) && (
            <EditableInput
              key={`height${row.id}`}
              value={_height}
              row={row}
              width="100%"
              onChange={value => handleChange(row, 'height')(formatNumber(value))}
              disabled={!!readOnly || row.isDisabled || isApply || isLinked}
              readOnly
              rowId={row.id}
              unit="cm"
              showUnitWhenNotEmpty
            />
          )
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.height !== nextRecord.height || forceCellUpdate === 'height'
    },
    {
      title: 'Largura',
      dataIndex: 'width',
      key: 'width',
      width: 90,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        const isLinked = row?.idItem || row?.externalBaseCode;
        const _width = formatNumber(val) ? formatCurrency(val) : null;

        return (
          refurbishItemType?.notParent?.includes(row?.type) && (
            <EditableInput
              key={`width${row.id}`}
              value={_width}
              row={row}
              width="100%"
              onChange={value => handleChange(row, 'width')(formatNumber(value))}
              disabled={!!readOnly || row.isDisabled || isApply || isLinked}
              readOnly
              rowId={row.id}
              unit="cm"
              showUnitWhenNotEmpty
              placeholder="-"
            />
          )
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.width !== nextRecord.width || forceCellUpdate === 'width'
    },
    {
      title: 'Comprimento',
      dataIndex: 'length',
      key: 'length',
      width: 110,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        const isLinked = row?.idItem || row?.externalBaseCode;
        const _length = formatNumber(val) ? formatCurrency(val) : null;

        return (
          refurbishItemType?.notParent?.includes(row?.type) && (
            <EditableInput
              key={`length${row.id}`}
              value={_length}
              row={row}
              width="100%"
              onChange={value => handleChange(row, 'length')(formatNumber(value))}
              disabled={!!readOnly || row.isDisabled || isApply || isLinked}
              readOnly
              rowId={row.id}
              unit="cm"
              showUnitWhenNotEmpty
              placeholder="-"
            />
          )
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.length !== nextRecord.length || forceCellUpdate === 'length'
    },
    {
      title: 'Peso',
      dataIndex: 'weight',
      key: 'weight',
      width: 90,
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        const isLinked = row?.idItem || row?.externalBaseCode;
        const _weight = formatNumber(val) ? formatCurrency(val) : null;

        return (
          refurbishItemType?.notParent?.includes(row?.type) && (
            <EditableInput
              key={`weight${row.id}`}
              noWrap
              value={_weight}
              row={row}
              width="100%"
              onChange={value => handleChange(row, 'weight')(formatNumber(value))}
              disabled={!!readOnly || row.isDisabled || isApply || isLinked}
              readOnly
              rowId={row.id}
              unit="kg"
              showUnitWhenNotEmpty
              placeholder="-"
            />
          )
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.weight !== nextRecord.weight || forceCellUpdate === 'weight'
    },
    {
      title: 'Qtd.',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 70,
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;

        return isPrint ? (
          formatCurrency(val, { decimalCount: replaceDecimalNumber(val) })
        ) : (
          <EditableInput
            key={`qtd${row.id}`}
            value={formatCurrency(val, { decimalCount: replaceDecimalNumber(val) })}
            row={row}
            width="100%"
            onChange={value => handleChange(row, 'quantity')(formatNumber(value))}
            disabled={!!readOnly || row.isDisabled || isApply}
            readOnly
            rowId={row.id}
            placeholder="-"
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.quantity !== nextRecord.quantity
    },
    {
      title: 'Custo un.',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;

        if (isPrint) return val ? formatCurrency(val, { currencySymbol: 'R$' }) : null;

        return (
          <EditableInput
            type="currency"
            key={`price${row.id}`}
            value={val ? formatCurrency(val, { currencySymbol: 'R$' }) : null}
            row={row}
            width="100%"
            onChange={value => handleChange(row, 'price')(formatNumber(value))}
            disabled={!!readOnly || row.isDisabled || isApply}
            readOnly
            rowId={row.id}
            placeholder="-"
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.price !== nextRecord.price
    },
    {
      title: 'Custo de produtos',
      dataIndex: 'totalProductRaw',
      width: 140,
      key: 'totalProductRaw',
      sorter: !isPrint,
      type: 'number',
      render: (val, row) => {
        const isLabor = row?.type === refurbishItemType.labor;

        return !isLabor ? (
          <div key={`totalProductRaw${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Custo de serviços',
      dataIndex: 'totalLaborRaw',
      width: 140,
      key: 'totalLaborRaw',
      sorter: !isPrint,
      type: 'number',
      render: (val, row) => {
        const isProduct = row?.type === refurbishItemType.product;

        return !isProduct ? (
          <div key={`totalLaborRaw${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Custo total',
      dataIndex: 'total',
      width: 140,
      key: 'total',
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => <div key={`total${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>,
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.total !== nextRecord.total
    },
    {
      title: 'BDI',
      dataIndex: 'bdi',
      width: 100,
      key: 'bdi',
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => {
        const _val = row?.calculatedBdi || row?.bdi;
        const bdiByType = {
          [refurbishItemType?.product]: productBdi,
          [refurbishItemType?.labor]: laborBdi
        };
        const bdi = bdiByType[row.type];
        return (
          refurbishItemType?.notParent?.includes(row?.type) && (
            <EditableInput
              key={`bdi${row.id}`}
              value={_val || bdi ? `${formatCurrency(_val || bdi)}%` : null}
              row={row}
              width="100%"
              onChange={value => handleChange(row, 'bdi')(formatBdi(value))}
              disabled={!!readOnly || row.isDisabled || isApply}
              readOnly
              rowId={row.id}
              placeholder="-"
            />
          )
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.bdi !== nextRecord.bdi || prevRecord.calculatedBdi !== nextRecord.calculatedBdi
    },
    {
      title: 'Preço un.',
      dataIndex: 'priceBdi',
      key: 'priceBdi',
      width: 155,
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => {
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;

        if (isPrint) return val ? formatCurrency(val, { currencySymbol: 'R$' }) : null;

        return <div key={`priceBdi${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.priceBdi !== nextRecord.priceBdi
    },
    {
      title: 'Preço de produtos',
      dataIndex: 'totalProduct',
      width: 150,
      key: 'totalProduct',
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => {
        const isLabor = row?.type === refurbishItemType.labor;

        return !isLabor ? (
          <div key={`totalProduct${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
        ) : (
          '-'
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalProduct !== nextRecord.totalProduct
    },
    {
      title: 'Preço de serviços',
      dataIndex: 'totalLabor',
      width: 150,
      key: 'totalLabor',
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => {
        const isProduct = row?.type === refurbishItemType.product;

        return !isProduct ? (
          <div key={`totalLabor${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
        ) : (
          '-'
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalLabor !== nextRecord.totalLabor
    },
    {
      title: 'Preço total',
      dataIndex: 'totalBdi',
      width: 150,
      key: 'totalBdi',
      sorter: !isPrint && !isTemplate,
      type: 'number',
      render: (val, row) => (
        <div id="totalPrice" key={`totalBdi${row.id}`}>
          {formatCurrency(val, { currencySymbol: 'R$' })}
        </div>
      ),
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalBdi !== nextRecord.totalBdi
    },
    {
      title: 'Preço total',
      dataIndex: 'totalSuppliesPrice',
      width: 150,
      key: 'totalSuppliesPrice',
      type: 'number',

      render: (val, row) => (
        <div key={`totalSuppliesPrice${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
      ),
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalSuppliesPrice !== nextRecord.totalSuppliesPrice
    },
    {
      title: 'Peso (%)',
      dataIndex: 'suppliesItemPercentage',
      width: 150,
      key: 'suppliesItemPercentage',
      type: 'number',

      render: (val, row) => (
        <div id={`suppliesItemPercentage${row.name}`} key={`suppliesItemPercentage${row.id}`}>
          {formatCurrency(val)}%
        </div>
      ),
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.suppliesItemPercentage !== nextRecord.suppliesItemPercentage
    },
    {
      title: 'Acumulado',
      dataIndex: 'suppliesAccumulationPercentage',
      width: 150,
      key: 'suppliesAccumulationPercentage',
      type: 'number',

      render: (val, row) => (
        <div id={`suppliesAccumulationPercentage${row.name}`} key={`suppliesAccumulationPercentage${row.id}`}>
          {formatCurrency(val)}%
        </div>
      ),
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.suppliesAccumulationPercentage !== nextRecord.suppliesAccumulationPercentage
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: isPrint ? 120 : 70,
      sorter: !isPrint && !isTemplate,
      onlyRefurbish: true,
      render: (val, row) => {
        if (isTemplate) return <div>-</div>;
        if (!refurbishItemType?.notParent?.includes(row?.type)) return null;

        const status = serializedStatusArray[val] || val;

        return isPrint ? (
          <div>{status?.label || '-'}</div>
        ) : (
          <div key={`status${row.id}`}>
            {row.isDisabled || isUserAnonymous || isApply ? (
              <StatusSelector status={status} />
            ) : (
              <ColumnWrapper
                value={status}
                _key="value"
                onSelect={handleChange(row, 'status')}
                loadList={statusArray}
                showClean={false}
                canCreate={false}
                IconSelector={({ item }) => <StatusSelector status={item} />}
              >
                {row?.status ? <StatusSelector status={status || val} titleTooltip="Alterar status" /> : '-'}
              </ColumnWrapper>
            )}
          </div>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status
    },
    ...(isPrint || isApply
      ? []
      : [
          {
            title: 'Ações',
            key: 'action',
            dataIndex: 'action',
            width: 80,
            renderMobile: true,
            alwaysShow: true,
            render: (val, row) => {
              if (!row.type) return null;
              const isComposition = row?.type === refurbishItemType.composition;
              const catalogFilterType =
                row?.type === refurbishItemType.composition
                  ? refurbishItemType.composition
                  : refurbishItemType.productLabor;
              const isLinked = !!row?.idItem;
              const isExternalBase = !!row?.externalBaseCode;

              let mappingKey = isTemplate
                ? specificationDropDown.templateItem({ isComposition, isLinked, isExternalBase })
                : specificationDropDown.specificationItem({
                    isComposition,
                    isLinked,
                    isExternalBase
                  });

              if (row.type === refurbishItemType.parent) {
                mappingKey = 'specificationParent';
              }

              return (
                <div
                  role="presentation"
                  style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                  onClick={e => e.stopPropagation()}
                >
                  {row?.isVirtual ? (
                    <Tooltip title="Remover">
                      <Button
                        padding={0}
                        id="delete-virtual-row"
                        text
                        onClick={e => {
                          handleRemoveVirtualIRow(row);
                          e.stopPropagation();
                        }}
                      >
                        <FontAwesomeIcon size="lg" icon={faTrashCan}></FontAwesomeIcon>
                      </Button>
                    </Tooltip>
                  ) : (
                    <SpecificationDropDown
                      key={`action${row.id}`}
                      item={row}
                      referenceKey={referenceKey}
                      idReference={idReference}
                      afterSubmit={handleAfterChange}
                      idParent={row?.idParent}
                      model={isTemplate ? 'template-item' : 'refurbish-items'}
                      mappingKey={mappingKey}
                      allowCopy
                      isGetById
                      isCustomer={readOnly}
                      refetchOptions={refetchOptions}
                      noPadding={isMobile}
                      renderColumns={columnsToShow}
                      modalParams={{ newItemTypeRedirect: catalogFilterType }}
                    />
                  )}
                </div>
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => JSON.stringify(prevRecord) !== JSON.stringify(nextRecord)
          }
        ])
  ];

  if (isPrint) {
    return allColumns.filter(c => printColumns.includes(c.key) && c.dataIndex);
  }

  return allColumns.filter(c => {
    const dataIndexStr = Array.isArray(c.dataIndex) ? c.dataIndex.join('.') : c.dataIndex;
    return (
      (columnsToShow?.[c.key] || c.alwaysShow) && dataIndexStr?.toLocaleLowerCase() !== groupModel?.toLocaleLowerCase()
    );
  });
};

export { columns };
