import { createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';

import reducers from '../../store/combinedReducers';

localForage.config({
  name: 'Vobi',
  storeName: 'homeHeroStorage'
});

const persistConfig = {
  key: 'homeHeroStorage',
  storage: localForage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const enhancers = compose(
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

export const store = createStore(persistedReducer, {}, enhancers);

export const persistor = persistStore(store);
