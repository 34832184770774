import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { Checkbox, Row, Table as TableAnt } from 'antd';
import GroupHeader from '../Header/GroupHeader';
import DraggableRow from './DraggableRow';
import { FlexTable } from './Table.styled';
import CommonRow from './CommonRow';
import ParentBadget from './ParentBadget';
import ContentTable from './ContentTable';
import CenteredLoader from '../Loader/CenteredLoader';
import addSortIconToColumns from './addSortIconToColumns';
import { colors } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const StyledCheckbox = styled(Checkbox)`
  left: 12px;
  top: 8px;
  position: absolute;
  z-index: 3;
`;

const Table = ({
  list = [],
  group,
  disabledByParent,
  margin = '1% 0',
  onSelectItem,
  selectedRowKeys,
  selectedItems = [],
  childErr = [],
  isCustomer,
  refetch,
  allowEditGroup,
  AddComponent,
  parentKey = 'idParent',
  addItemText,
  addSubItemText,
  onSort = () => {},
  columns,
  childrenColumns,
  childrenColumnName,
  moveRow,
  expandedRows,
  expandEmpty,
  lazyLoad,
  rowSelection,
  showChildrenHeader = false,
  rowChildrenSelection = true,
  paddingFirstColumn = 4,
  isExportToPdf,
  defaultExpandAllRows,
  defaultExpandAllGroups,
  stickyTop = 0,
  borderRadius = 4,
  onClick = f => f,
  border,
  disableDropOnParent,
  grouped,
  showTotal,
  isCustomSelectAll,
  onSelectAll = f => f,
  checkStrictly,
  noCheckBox = false,
  customBorder,
  customRowKey,
  notExpandableRow,
  transformedList,
  hideSelectAll,
  customEmptyState,
  borderRow,
  cursor = 'pointer',
  fullWidth,
  selectAllOnlyParents
}) => {
  const idReference = group && group.id ? group.id : null;
  const childrenColumnNameOrDefault = childrenColumnName || 'children';
  const { refurbishItemType } = useSelector(state => state.setup.enums) || {};
  const [toggleTable, setToggle] = useState(!!(group && group.name && !defaultExpandAllGroups));
  const [expanded, setExpanded] = useState(defaultExpandAllRows ? null : expandedRows?.current || []);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);

  const handleSorting = (pagination, filters, sorter) => {
    let sortValue = {};

    if (sorter?.order === 'ascend') {
      sortValue = { key: sorter.field, order: sorter?.column?.type === 'number' ? 'asc nulls first' : 'asc' };
    } else if (sorter?.order === 'descend') {
      sortValue = { key: sorter.field, order: sorter?.column?.type === 'number' ? 'desc nulls last' : 'desc' };
    }

    return onSort(sortValue);
  };

  const RenderError = ({ id }) => {
    const { error } = childErr.find(e => e.key === id) || {};
    return <Paragraph color={colors.red500}> {error} </Paragraph>;
  };

  RenderError.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  useEffect(() => {
    if (!defaultExpandAllRows) {
      setExpanded(expandedRows?.current || []);
    }
  }, [expandedRows?.current]);

  useEffect(() => {
    if (!isCustomSelectAll) return;
    const objectIds = transformedList.reduce((acc, curr) => ({ ...acc, [curr?.id]: true }), {});

    const equalKeys = {};
    selectedRowKeys.forEach(item => {
      if (objectIds[item]) {
        equalKeys[item] = true;
      }
    });
    if (Object.keys(equalKeys).length === Object.keys(objectIds).length && Object.keys(objectIds).length > 0) {
      setHasSelected(false);
      setIsSelectedAll(true);
    } else if (!Object.keys(equalKeys).length) {
      setHasSelected(false);
      setIsSelectedAll(false);
    } else {
      setHasSelected(true);
      setIsSelectedAll(false);
    }
  }, [selectedRowKeys, list]);

  const components = {
    body: {
      row: moveRow && list.length ? DraggableRow : CommonRow
    }
  };

  const _rowSelection = {
    checkStrictly,
    selectedRowKeys,
    onSelect: (record, selected) => {
      const _selectedRows = selected
        ? [...selectedItems, record]
        : selectedItems?.filter(s => s.id !== record.id && (checkStrictly || s.id !== record?.idParent));

      onSelectItem(
        _selectedRows?.map(s => s.id),
        _selectedRows
      );
    },
    onSelectAll: (selected, selectedRows, changedRows) => {
      const ids = changedRows.map(s => s.id);
      const _selectedRows = selected
        ? [...selectedItems, ...changedRows]
        : selectedItems?.filter(s => !ids.includes(s.id));
      !isCustomSelectAll && onSelectAll(selected, group?.item);
      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    }
  };

  const handleSelectAllChange = e => {
    setIsSelectedAll(e.target.checked);
    setHasSelected(false);
    onSelectAll(e.target.checked, group?.item);
  };

  const rowParentSelection = {
    checkStrictly: false,
    selectedRowKeys,
    hideSelectAll,
    onSelect: (record, selected) => {
      let _selectedRows = [];
      if (!selected) {
        _selectedRows = selectedItems?.filter(s => s.id !== record.id && s.id !== record?.idParent);
        record.children?.length &&
          record.children.forEach(r => {
            _selectedRows = _selectedRows?.filter(s => s.id !== r.id && s.id !== r?.idParent);
          });
      } else {
        const childrenKeys = record.children?.map(item => item.id);
        if (childrenKeys) _selectedRows = selectedItems?.filter(s => !childrenKeys.includes(s.id));
        else _selectedRows = selectedItems;

        _selectedRows =
          record.type === refurbishItemType.parent
            ? [..._selectedRows, ...record.children]
            : [..._selectedRows, record];
      }

      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    },
    onSelectAll: (selected, selectedRows, changedRows) => {
      const _changedRows = selectAllOnlyParents ? changedRows?.filter(r => r?.[childrenColumnName]) : changedRows;
      const ids = _changedRows?.map(s => s.id);
      const _selectedRows = selected
        ? [...selectedItems, ..._changedRows]
        : selectedItems?.filter(s => !ids?.includes(s.id));
      !isCustomSelectAll && onSelectAll(selected, group?.item);
      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    },
    columnWidth: '100%',
    renderCell: (checked, record, index, originNode) => (
      <>
        {record?.type === refurbishItemType.parent ? <ParentBadget /> : null}
        {originNode}
      </>
    )
  };

  const expandedRowRender = (parent = {}) => {
    if (parent[childrenColumnNameOrDefault] === null) {
      return <CenteredLoader fontSize="sizeMd" />;
    }

    return (
      <TableAnt
        components={components}
        rowSelection={rowChildrenSelection ? _rowSelection : false}
        columns={childrenColumns || columns}
        dataSource={[...(parent[childrenColumnNameOrDefault] || [])]}
        pagination={false}
        showHeader={showChildrenHeader}
        size="small"
        rowKey={row => row.id}
        expandable={{
          expandIconColumnIndex: -1
        }}
        onRow={(record, index) =>
          moveRow
            ? {
                index,
                moveRow,
                record,
                parentKey,
                childrenColumnName: childrenColumnNameOrDefault,
                disableDropOnParent,
                onClick: () => onClick(record)
              }
            : { record, index, childrenColumnName: childrenColumnNameOrDefault, onClick: () => onClick(record) }
        }
        footer={() =>
          AddComponent && addSubItemText && (!disabledByParent || !parent.isDisabled) ? (
            <Row style={{ padding: '8px 16px' }} key={`footer${parent.id}`}>
              <AddComponent parent={parent} createParams={{ [parentKey]: parent.id, idReference }}>
                {addSubItemText}
              </AddComponent>
            </Row>
          ) : null
        }
      />
    );
  };

  return (
    <>
      <ContentTable margin={margin} id={`list-grouped-${grouped?.value}`} $fullWidth={fullWidth}>
        {group && group.name && (
          <div role="presentation" onClick={() => setToggle(prev => !prev)}>
            <GroupHeader
              icon={toggleTable ? faCaretDown : faCaretUp}
              group={group}
              grouped={grouped}
              refetch={refetch}
              // eslint-disable-next-line no-restricted-globals
              allowEdit={!isNaN(group.id) && !isCustomer && allowEditGroup}
              showTotal={showTotal}
            />
          </div>
        )}
        {!toggleTable && (
          <div style={{ position: 'relative' }}>
            {isCustomSelectAll && (
              <StyledCheckbox indeterminate={hasSelected} checked={isSelectedAll} onChange={handleSelectAllChange} />
            )}
            <FlexTable
              cursor={cursor}
              border={border}
              locale={{ emptyText: customEmptyState || 'Nenhum item' }}
              components={components}
              columns={addSortIconToColumns(columns)}
              showSorterTooltip={false}
              dataSource={list}
              rowSelection={rowSelection && !isExportToPdf ? rowParentSelection : false}
              onRow={(record, index) =>
                moveRow
                  ? {
                      index,
                      moveRow,
                      record,
                      isExpanded: expanded?.includes(record.id),
                      parentKey,
                      childrenColumnName: childrenColumnNameOrDefault,
                      disableDropOnParent,
                      onClick: () => onClick(record)
                    }
                  : {
                      record,
                      index,
                      isExpanded: expanded?.includes(record.id),
                      onClick: () => onClick(record)
                    }
              }
              onChange={handleSorting}
              pagination={false}
              size="small"
              rowKey={row => (!customRowKey ? row.id : customRowKey(row))}
              expandable={{
                defaultExpandAllRows,
                childrenColumnName,
                indentSize: 0,
                expandedRowRender,
                rowExpandable: record => {
                  if (notExpandableRow && notExpandableRow(record)) return false;
                  return !expandEmpty
                    ? record[childrenColumnName || 'children']?.length
                    : record[childrenColumnName || 'children'] || lazyLoad;
                },
                expandedRowClassName: () => 'expanded-row',
                expandRowByClick: true,
                expandIconColumnIndex: -1,
                expandedRowKeys: expanded,
                onExpand: (isExpanded, { id }) => {
                  if (defaultExpandAllRows) return;

                  const keys = [...(isExpanded ? expanded?.concat(id) : expanded?.filter?.(k => k !== id))];
                  const _expanded = [...(isExpanded ? keys?.concat(id) : keys?.filter(k => k !== id))];
                  // eslint-disable-next-line no-param-reassign
                  expandedRows.current = _expanded;
                  setExpanded(_expanded);
                }
              }}
              $paddingFirstColumn={paddingFirstColumn}
              $stickyTop={stickyTop}
              $borderRadius={borderRadius}
              $noCheckBox={noCheckBox}
              $customBorder={customBorder}
              $borderRow={borderRow}
            />
            {!isExportToPdf && AddComponent ? (
              <Row style={{ padding: '8px 16px' }}>
                <AddComponent text={addItemText} createParams={{ idReference }} />
              </Row>
            ) : null}
          </div>
        )}
      </ContentTable>
    </>
  );
};

Table.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  transformedList: PropTypes.oneOfType([PropTypes.array]),
  group: PropTypes.instanceOf(Object),
  disabledByParent: PropTypes.bool,
  margin: PropTypes.string,
  onSelectItem: PropTypes.func,
  selectedRowKeys: PropTypes.instanceOf(Array),
  selectedItems: PropTypes.instanceOf(Array),
  refetch: PropTypes.func,
  childErr: PropTypes.oneOfType([PropTypes.array]),
  isCustomer: PropTypes.bool,
  allowEditGroup: PropTypes.bool,
  AddComponent: PropTypes.instanceOf(Object),
  parentKey: PropTypes.string,
  addItemText: PropTypes.string,
  addSubItemText: PropTypes.string,
  onSort: PropTypes.func,
  columns: PropTypes.instanceOf(Array),
  moveRow: PropTypes.func,
  expandedRows: PropTypes.instanceOf(Object),
  expandEmpty: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  childrenColumns: PropTypes.instanceOf(Array),
  childrenColumnName: PropTypes.string,
  rowSelection: PropTypes.bool,
  showChildrenHeader: PropTypes.bool,
  rowChildrenSelection: PropTypes.bool,
  paddingFirstColumn: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isExportToPdf: PropTypes.bool,
  defaultExpandAllRows: PropTypes.bool,
  defaultExpandAllGroups: PropTypes.bool,
  stickyTop: PropTypes.number,
  borderRadius: PropTypes.number,
  onClick: PropTypes.func,
  border: PropTypes.bool,
  disableDropOnParent: PropTypes.bool,
  grouped: PropTypes.instanceOf(Object),
  showTotal: PropTypes.bool,
  isCustomSelectAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
  checkStrictly: PropTypes.bool,
  noCheckBox: PropTypes.bool,
  customBorder: PropTypes.string,
  customRowKey: PropTypes.func,
  notExpandableRow: PropTypes.func,
  hideSelectAll: PropTypes.bool,
  customEmptyState: PropTypes.instanceOf(Object),
  borderRow: PropTypes.bool,
  cursor: PropTypes.string,
  fullWidth: PropTypes.bool,
  selectAllOnlyParents: PropTypes.bool
};

export default React.memo(Table, isEqual);
