import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

// style
import { Title, Paragraph } from '../Text/Text';
import { colors, Div, spaces } from '../../styles/style';

const TableNoContent = ({
  buttonId,
  description = 'Adicione um novo item para vê-lo na lista',
  renderButton = true,
  buttonTitle = 'Adicionar novo',
  handleClick,
  extraButton,
  icon,
  title,
  IconImage,
  isMobile
}) => {
  return (
    <Div id={`div-${buttonId}`} direction="column" gap={spaces.space1} padding={isMobile ? '3rem 0' : '14rem 0'}>
      {IconImage}
      {title ? <Title color={colors.neutral600}>{title}</Title> : null}
      {description ? <Paragraph color={colors.neutral500}>{description}</Paragraph> : null}
      {renderButton ? (
        <Button id={buttonId} align="center" type="primary" onClick={handleClick} icon={icon}>
          {buttonTitle}
        </Button>
      ) : null}
      {extraButton && extraButton}
    </Div>
  );
};

TableNoContent.propTypes = {
  buttonId: PropTypes.string,
  buttonTitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClick: PropTypes.func,
  renderButton: PropTypes.bool,
  extraButton: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  IconImage: PropTypes.instanceOf(Object),
  isMobile: PropTypes.bool
};

export default TableNoContent;
