import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { getViewDate } from '../../helpers/helper';
import { Div, Ellipsis, colors, fonts, spaces } from '../../../styles/style';
import Card from '../../../components/Card/Card';
import { Subtitle } from '../../../components/Text/Text';

const MobileDiv = styled.div`
  font-family: Inter;
  padding-bottom: ${props => props.$padding && props.$padding};
  width: ${props => (props.$width ? props.$width : '100%')};
  display: flex;
  justify-content: ${props => (props.$end ? 'flex-end' : 'flex-start')};
`;

const columns = ({ isMobile }) => {
  const allColumns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      className: ['col-grow-1', 'center'],
      render: val => {
        return <span>{getViewDate({ dateViewedBy: val, list: true })}</span>;
      },
      shouldCellUpdate: false
    },
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
      className: ['col-grow-3', 'center'],
      render: val => {
        return <span>{val}</span>;
      },
      shouldCellUpdate: false
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      className: ['col-grow-1', 'center'],
      render: val => {
        return (
          <Tooltip title={val} overlayStyle={{ maxWidth: 'none' }}>
            <Ellipsis>{val}</Ellipsis>
          </Tooltip>
        );
      },
      shouldCellUpdate: false
    },
    {
      title: 'Card',
      dataIndex: 'name',
      key: 'name',
      onlyMobile: true,
      render: (val, row) => {
        return (
          <Card bodyBackgroundColor={colors.neutral50} bodyPadding={spaces.space1} borderColor="none">
            <MobileDiv $width="100%" $padding={spaces.space2}>
              <Subtitle type="secondary" weight={fonts.weight600} color={colors.neutral600}>
                {val}
              </Subtitle>
            </MobileDiv>
            <Div align="space-between">
              <MobileDiv>
                <Subtitle type="secondary" weight={fonts.weight400} color={colors.neutral600}>
                  {getViewDate({ dateViewedBy: row.date, list: true })}
                </Subtitle>
              </MobileDiv>
              <MobileDiv $end>
                <Subtitle type="secondary" weight={fonts.weight400}>
                  IP: {row.ip}
                </Subtitle>
              </MobileDiv>
            </Div>
          </Card>
        );
      },
      shouldCellUpdate: false
    }
  ];

  return isMobile ? allColumns.filter(c => c.onlyMobile) : allColumns.filter(c => !c.onlyMobile);
};

export { columns };
