import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { faPix } from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import { Paragraph } from '../Text/Text';
import { DivMethods, Content } from './VobiPayPayment.styles';
import { Div } from '../../styles/style';
import MethodCredit from './MethodCredit';
import MethodPix from './MethodPix';
import MethodTicket from './MethodTicket';
import ExtraInfoInstallmentToPay from './ExtraInfoInstallmentToPay';
import Button from '../Button/Button';

const VobiPayPayment = ({ installmentToPay, paymentTypes, handleLoad, company, highlightDiv, ...props }) => {
  const { vobiPayPaymentType } = useSelector(state => state.setup.enums);
  const allowedTypes = paymentTypes.map(type => type?.id);
  const [highlight, setHighlight] = useState(false);
  const [typePayment, setTypePayment] = useState(
    paymentTypes?.some(type => type.id === vobiPayPaymentType?.creditCard)
      ? vobiPayPaymentType?.creditCard
      : vobiPayPaymentType?.[paymentTypes?.some(type => type.id === vobiPayPaymentType?.pix) ? 'pix' : 'bankSlip']
  );

  const RenderPaymentMethod = {
    1: <MethodPix installmentToPay={installmentToPay} company={company} />,
    2: <MethodTicket installmentToPay={installmentToPay} company={company} />,
    3: <MethodCredit installmentToPay={installmentToPay} company={company} afterSubmit={handleLoad} {...props} />
  };

  useEffect(() => {
    if (highlightDiv > 1) {
      setHighlight(true);
      const element = document?.getElementById('MethodCard');
      element?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      setTimeout(() => {
        setHighlight(false);
      }, 1000);
    }
  }, [highlightDiv]);

  return (
    <Content id="MethodCard" highlight={highlight} className="hide-on-print">
      <ExtraInfoInstallmentToPay installmentToPay={installmentToPay} typePayment={typePayment} {...props} />
      <Paragraph type="small">
        <strong>Selecione a forma de pagamento</strong>
      </Paragraph>
      <DivMethods>
        {allowedTypes.includes(vobiPayPaymentType?.creditCard) && (
          <Button
            ghost
            id="creditPay"
            type="primary"
            pressed={typePayment === vobiPayPaymentType?.creditCard}
            onClick={() => setTypePayment(vobiPayPaymentType?.creditCard)}
          >
            <FontAwesomeIcon icon={faCreditCard} />
            Cartão de crédito
          </Button>
        )}
        {allowedTypes.includes(vobiPayPaymentType?.pix) && !!company?.pix && (
          <Button
            ghost
            id="pixPay"
            type="primary"
            pressed={typePayment === vobiPayPaymentType?.pix}
            onClick={() => setTypePayment(vobiPayPaymentType?.pix)}
          >
            <FontAwesomeIcon icon={faPix} />
            Pix
          </Button>
        )}
        {allowedTypes.includes(vobiPayPaymentType?.bankSlip) && (
          <Button
            ghost
            id="bankSlipPay"
            type="primary"
            pressed={typePayment === vobiPayPaymentType?.bankSlip}
            onClick={() => setTypePayment(vobiPayPaymentType?.bankSlip)}
          >
            <FontAwesomeIcon icon={faBarcode} />
            Boleto bancário
          </Button>
        )}
      </DivMethods>
      <Div>{RenderPaymentMethod[typePayment]}</Div>
    </Content>
  );
};

VobiPayPayment.propTypes = {
  installmentToPay: PropTypes.instanceOf(Object),
  paymentTypes: PropTypes.instanceOf(Array),
  handleLoad: PropTypes.func,
  installments: PropTypes.instanceOf(Array),
  company: PropTypes.instanceOf(Object),
  highlightDiv: PropTypes.number
};

export default VobiPayPayment;
