import * as Yup from 'yup';
import React from 'react';
import styled from 'styled-components';
import { name, requiredAddress, requiredPhone, requiredCpf, requiredCnpj, requiredEmail, simpleName } from '../schema';
import creditFlags from '../../../components/Images/creditFlags.svg';
import vobiPaySecurity from '../../../components/Images/vobiPaySecurity.svg';
import { Div, breakpoints, spaces } from '../../../styles/style';
import { formatDocument, getYears } from '../../helpers/helper';
import { ResponsibleDescription } from '../../../components/VobiPay/VobiPayPayment.styles';
import { Subtitle } from '../../../components/Text/Text';

const CustomDiv = styled(Div)`
  min-height: ${spaces.space4};
  padding-top: 13px;
  @media (max-width: ${breakpoints.tablet}) {
    padding-top: unset;
  }
`;

const { zipcode, number } = requiredAddress;
const creditSchema = Yup.object().shape({
  name,
  holderName: name,
  ccv: number,
  phone: requiredPhone,
  doc: Yup.string().when('personType', {
    is: 'pj',
    then: requiredCnpj,
    otherwise: requiredCpf
  }),

  email: requiredEmail,
  creditNumber: number,
  number,
  zipcode,
  expiryMonth: simpleName,
  expiryYear: simpleName
});

const typeItems = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' }
];

const creditMapping = company => ({ personType }) => {
  const isPj = personType === 'pj';
  return {
    holderName: {
      name: 'Nome impresso no cartão*',
      type: 'text',
      placeholder: 'João Silva',
      xs: 24,
      md: 8
    },
    accept: {
      name: 'Aceitamos*',
      type: 'custom',
      xs: 24,
      md: 16,
      Component: () => (
        <Div style={{ minHeight: spaces.space4 }}>
          <img src={creditFlags} alt="flag" />
        </Div>
      )
    },
    creditNumber: {
      name: 'Número do cartão*',
      type: 'creditCard',
      placeholder: '0000 0000 0000 0000',
      xs: 24,
      md: 8
    },
    securityImage: {
      type: 'custom',
      xs: 24,
      md: 16,
      Component: () => (
        <CustomDiv>
          <img src={vobiPaySecurity} alt="flag" />
        </CustomDiv>
      )
    },
    expiryMonth: {
      name: 'Válido até*',
      type: 'select',
      showSearch: false,
      options: [
        { value: 1, label: 'Jan' },
        { value: 2, label: 'Fev' },
        { value: 3, label: 'Mar' },
        { value: 4, label: 'Abr' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Jun' },
        { value: 7, label: 'Jul' },
        { value: 8, label: 'Ago' },
        { value: 9, label: 'Set' },
        { value: 10, label: 'Out' },
        { value: 11, label: 'Nov' },
        { value: 12, label: 'Dez' }
      ],
      xs: 24,
      md: 3
    },
    expiryYear: {
      name: 'Ano*',
      type: 'select',
      showSearch: false,
      options: getYears(),
      xs: 24,
      md: 3
    },
    ccv: {
      name: 'CVC*',
      type: 'text',
      placeholder: '001',
      xs: 24,
      md: 2
    },
    creditDescription: {
      type: 'custom',
      xs: 24,
      md: 16,
      Component: () => (
        <Div style={{ minHeight: spaces.space6 }}>
          <ResponsibleDescription>
            Esta cobrança é de responsabilidade única e exclusiva de <strong>{company?.legalName}</strong> com{' '}
            <strong>CNPJ: {formatDocument(company?.cnpj)}</strong> e a Vobi não se responsabiliza pelo produto ou
            serviço prestado, em caso de dúvida entre em contato com seu fornecedor.
          </ResponsibleDescription>
        </Div>
      )
    },
    titularInfo: {
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <Subtitle>Informações do titular do cartão</Subtitle>
    },
    name: {
      name: 'Nome completo*',
      type: 'text',
      placeholder: 'João Silva',
      xs: 24,
      md: 8
    },
    personType: {
      name: 'Tipo*',
      type: 'radioSwitch',
      items: typeItems,
      defaultValue: 'pf',
      width: '100%',
      overlayStyle: {
        maxWidth: '330px'
      },
      xs: 24,
      md: 8
    },
    doc: {
      name: 'CPF ou CNPJ*',
      type: isPj ? 'cnpj' : 'cpf',
      placeholder: isPj ? 'Ex: 00.000.000/0000-01' : 'Ex: 000.000.000-00',
      xs: 24,
      md: 8
    },
    email: {
      type: 'text',
      name: 'E-mail*',
      placeholder: 'Ex: meu@email.com',
      xs: 24,
      md: 8
    },
    phone: {
      name: 'Celular*',
      type: 'maskedPhone',
      mask: 'phone',
      placeholder: '(19) 99999-9999',
      xs: 24,
      md: 8
    },
    telephone: {
      name: 'Telefone (opcional)',
      type: 'phone',
      xs: 24,
      md: 8,
      placeholder: '(00) 00000-0000'
    },
    addressInfo: {
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <Subtitle>Endereço de faturamento</Subtitle>
    },
    zipcode: {
      name: 'CEP*',
      type: 'zipCode',
      placeholder: '00000-000',
      xs: 24,
      md: 8
    },
    street: {
      name: 'Rua*',
      type: 'text',
      placeholder: 'Ex: Rua das Laranjeiras',
      xs: 24,
      md: 8
    },
    neighborhood: {
      name: 'Bairro',
      type: 'text',
      placeholder: 'Jd América',
      xs: 24,
      md: 8
    },
    city: {
      name: 'Cidade*',
      type: 'text',
      placeholder: 'São Paulo',
      xs: 24,
      md: 8
    },
    number: {
      name: 'Número*',
      type: 'text',
      placeholder: '100',
      xs: 24,
      md: 8
    },
    complement: {
      name: 'Complemento',
      type: 'text',
      placeholder: 'Complemento do endereço',
      xs: 24,
      md: 8
    }
  };
};

export { creditSchema, creditMapping };
