import {
  faBuilding,
  faPeopleGroup,
  faBookSparkles,
  faGift,
  faGear,
  faArrowRightFromBracket,
  faArrowsRepeat,
  faKey,
  faUser
} from '@fortawesome/pro-duotone-svg-icons';
import { faCircleQuestion, faBell } from '@fortawesome/pro-solid-svg-icons';

const menuMapping = ({
  handleChangeRefurbish,
  isCustomer,
  isProvider,
  isMobile,
  hasUserPermission,
  hasCompanyPermission,
  handleChangePassword,
  isFree,
  handleLogout
}) => [
  {
    key: 'change-refurbish-avatar-button',
    title: 'Trocar projeto',
    onClick: handleChangeRefurbish,
    icon: faArrowsRepeat,
    shouldRender: isCustomer && !isMobile()
  },
  {
    key: 'config-avatar-button',
    title: 'Meus dados',
    to: '/configuracoes/dados-pessoais',
    icon: faUser,
    shouldRender: isCustomer
  },
  {
    key: 'office-info-avatar-button',
    title: 'Informações do negócio',
    to: '/configuracoes/meu-negocio',
    icon: faBuilding,
    shouldRender: isProvider && hasCompanyPermission
  },
  {
    key: 'my-team-avatar-button',
    title: 'Minha equipe',
    to: '/configuracoes/equipe',
    icon: faPeopleGroup,
    shouldRender: isProvider && hasUserPermission
  },
  {
    key: 'change-password-avatar-button',
    title: 'Trocar Senha',
    onClick: handleChangePassword,
    icon: faKey,
    shouldRender: !isProvider
  },
  {
    key: 'notification-settings',
    title: 'Notificações',
    icon: faBell,
    to: '/configuracoes/notificacoes',
    shouldRender: !isProvider
  },
  {
    key: 'guide-avatar-button',
    title: 'Guia de implantação',
    to: '/guia-implantacao',
    icon: faBookSparkles,
    shouldRender: isProvider
  },
  {
    key: 'advise-avatar-button',
    title: 'Indique e ganhe!',
    to: 'https://app.vobi.com.br/mgm-meus-convites',
    external: true,
    icon: faGift,
    shouldRender: isProvider
  },
  {
    key: 'config-avatar-button',
    title: 'Configurações',
    to: '/configuracoes/dados-pessoais',
    icon: faGear,
    shouldRender: isProvider
  },
  {
    key: 'help',
    title: 'Ajuda',
    icon: faCircleQuestion,
    shouldRender: true
  },
  {
    key: 'plan-upgrade-avatar-button',
    upgrade: true,
    shouldRender: isFree
  },
  {
    key: 'logout-avatar-button',
    title: 'Sair',
    onClick: handleLogout,
    icon: faArrowRightFromBracket,
    shouldRender: true
  }
];

export default menuMapping;
