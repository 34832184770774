import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Drawer from './Drawer';
import Form from '../Form/Form';
import useCRUD from '../../_Hooks/useCRUD';
import Button from '../Button/Button';
import { Div, spaces } from '../../styles/style';
import ExcludeCategory from '../Modal/ExcludeCategory';
import { financialCategoryMapping, financialCategorySchema } from '../../lib/mapping/Form/financialCategorySchema';
import { buildRecursiveInclude, extractBillTypeDRE } from '../../lib/helpers/helper';
import CenteredLoader from '../Loader/CenteredLoader';
import CreatedByContent from '../Content/CreatedByContent';

const EditOrCreateCategoryDrawer = ({ id, onClose, defaultValues = {}, actualId, disableEditBillType }) => {
  const model = 'financialCategory';
  const { dreList } = useSelector(state => state.setup.systemData);
  const { user } = useSelector(state => state?.authReducer) || {};
  const idCompany = user?.idCompany;
  const [data, setData] = useState({
    name: defaultValues?.name || '',
    billType: defaultValues?.billType || 'income',
    idDRE: -1
  });
  const [deleteModalCategory, setDeleteModalCategory] = useState(false);
  const [levelList, setLevelList] = useState([]);
  const { loading, handleGet, handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    immediatelyLoadData: false
  });
  const { handleGet: handleList } = useCRUD({
    model,
    immediatelyLoadData: false
  });
  const forceUpdate = useRef(false);

  useEffect(() => {
    if (!id) return;
    handleGet({
      refetchPathOptions: `/${id}`,
      refetchOptions: { include: buildRecursiveInclude({ model, id, depth: 1, idCompany }) }
    }).then(resp => {
      setData(prev => ({ ...prev, ...resp }));
    });
  }, [id]);

  useEffect(() => {
    handleList({
      refetchOptions: {
        onlyMine: true,
        where: { billType: data?.billType, idParent: null, id: { ne: id }, isActive: true },
        include: buildRecursiveInclude({ model, id, depth: 1, idCompany }),
        order: [
          ['name', 'asc'],
          ['children', 'name', 'asc']
        ]
      }
    }).then(resp => setLevelList(resp || []));
  }, [data?.billType, id]);

  const handleSubmit = submitData => {
    const updateOrCreate = id
      ? handleUpdate({
          updatePathOptions: `/${id}`,
          values: submitData,
          refresh: false
        })
      : handleCreate({ values: { ...submitData, billType: data?.billType }, refresh: false });
    updateOrCreate.then(resp => {
      if (resp.error) {
        const { idParent, ...values } = submitData;
        setData(values);
        return;
      }
      onClose(true, resp?.id);
    });
  };

  const changeData = values => {
    setData(prev => {
      if (values?.billType === prev?.billType) return prev;
      forceUpdate.current = true;
      return {
        ...values,
        idParent: null,
        idDRE: null
      };
    });
  };

  const handleConfirmDelete = newId => {
    if (!id) return;
    handleDelete({
      refresh: false,
      id,
      values: { newId }
    }).then(() => {
      return onClose(true);
    });
  };

  const { incomeDRE, expenseDRE } = extractBillTypeDRE(dreList);

  return (
    <Drawer
      title={`${id ? 'Editar' : 'Adicionar'} categoria`}
      open
      formId="financeEditForm"
      onClose={() => onClose()}
      extraContentOnRight
      zIndex={1200}
      footer={
        <>
          <Button type="text" className="withMarginRight" onClick={() => onClose()} id="cancel-drawer">
            Cancelar
          </Button>

          <div style={{ display: 'flex', gap: spaces.space2 }}>
            {id && (
              <Button
                loading={loading}
                id={`delete-${model}-button`}
                text
                type="danger"
                className="withMarginRight"
                onClick={() => setDeleteModalCategory(true)}
                disabled={actualId === id}
              >
                Excluir
              </Button>
            )}
            <Button loading={loading} htmlType="submit" form="financeEditForm" id="submit-drawer" type="primary">
              Confirmar
            </Button>
          </div>
        </>
      }
    >
      {loading && !data?.id ? (
        <CenteredLoader />
      ) : (
        <Div direction="column" gap={spaces.space3} $fullWidth align="start">
          <Form
            id="financeEditForm"
            displayButtons={false}
            onSubmit={handleSubmit}
            mapping={() =>
              financialCategoryMapping({
                id,
                levelList,
                billType: data?.billType,
                hasChildren: data?.children?.length > 0,
                disableEditBillType,
                dreList: data?.billType === 'income' ? incomeDRE : expenseDRE
              })
            }
            schema={financialCategorySchema}
            data={data || { isActive: true }}
            onFormChange={changeData}
            forceUpdate={forceUpdate}
          />
          <CreatedByContent data={data} showLine noPadding />
          {deleteModalCategory && (
            <ExcludeCategory
              onClose={() => setDeleteModalCategory(false)}
              onSubmit={handleConfirmDelete}
              data={data}
              loading={loading}
            />
          )}
        </Div>
      )}
    </Drawer>
  );
};

EditOrCreateCategoryDrawer.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  defaultValues: PropTypes.instanceOf(Object),
  actualId: PropTypes.number,
  disableEditBillType: PropTypes.bool
};

export default EditOrCreateCategoryDrawer;
