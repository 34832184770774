import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { breakpoints, colors, radius, spaces, Div, fonts } from '../../styles/style';
import Select from '../Select/Select';
import useViewport from '../../_Hooks/useViewport';

const EditableDiv = styled.div`
  padding: ${props => (props.$showBord ? spaces.space0 : null)};
  ${props =>
    props.$withBorder
      ? css`
          border: 1px solid ${colors.neutral200};
          border-radius: ${radius.radius1};
        `
      : css`
          border: 1px solid transparent;
        `};
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  min-height: ${props => props.$minHeight || '28px'};
  display: flex;
  align-items: center;
  word-break: break-word;
  width: 100%;
  justify-content: ${props => props.align || ''};
  ${props => props.$backgroundColor && `background-color: ${props.$backgroundColor};`}

  > svg {
    display: none;
    margin-left: ${spaces.space1};
  }

  &:hover {
    cursor: pointer;
    border: ${props => (props.$showBord && !props.disabled ? `1px solid ${colors.neutral500}` : null)};
    border-radius: ${radius.radius1};
    > svg {
      display: block;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .form-component & {
      padding: 0;
    }
    padding: 0 8px;
  }

  .form-component {
    width: 100%;
    height: ${spaces.space4};
    padding: 0 ${spaces.space2};

    &:hover {
      background-color: ${colors.neutral100};
    }
  }
`;

const Container = styled(Div)`
  &.form-component {
    width: 100%;
    height: ${spaces.space4};
    padding: 0 11px;
    border-radius: ${radius.radius1};

    @media (max-width: ${breakpoints.tablet}) {
      padding: 0;
    }

    div > div {
      font-size: ${fonts.sizeSm};
      color: ${colors.neutral600};
    }

    &:hover,
    &:focus {
      background-color: ${colors.neutral75};
    }
  }
`;

const ColumnWrapper = ({
  value,
  _key,
  model,
  onSelect,
  loadList,
  children,
  cleanValue = 1,
  showClean = true,
  disabled,
  align,
  id,
  allowPropagation = false,
  withBorder,
  backgroundColor,
  minHeight,
  className
}) => {
  const [isEdit, setEdit] = useState(false);
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  const handleClick = e => {
    if (!allowPropagation) e.stopPropagation();
    setEdit(true);
  };

  const handleRemove = e => {
    e.stopPropagation();
    onSelect(cleanValue);
    setEdit(false);
  };

  return (
    <Container className={className}>
      <EditableDiv
        id={id}
        role="presentation"
        onClick={handleClick}
        align={align}
        $showBord={!children}
        disabled={disabled}
        $withBorder={withBorder}
        $backgroundColor={backgroundColor}
        $minHeight={minHeight}
        tabIndex={0}
        onFocus={handleClick}
      >
        {children || value?.name || '-'}
        {showClean && !disabled && (children || value) ? (
          <FontAwesomeIcon icon={faTimes} onClick={handleRemove} />
        ) : null}
      </EditableDiv>
      {isEdit && !disabled ? (
        <div id="select-wrapper" style={{ height: 1, width: 1, overflow: 'hidden', position: 'absolute' }}>
          <Select
            autoFocus
            $light
            allowClear={showClean}
            options={loadList}
            id={`select${value?.[_key || 'id']}`}
            model={model}
            onChange={val => {
              onSelect(val || cleanValue || null);
              setEdit(false);
            }}
            value={value?.[_key || 'id']}
            onClick={e => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
            onBlur={!isMobile ? () => setEdit(false) : null}
            open
          />
        </div>
      ) : null}
    </Container>
  );
};

ColumnWrapper.propTypes = {
  value: PropTypes.instanceOf(Object),
  _key: PropTypes.string,
  model: PropTypes.string,
  onSelect: PropTypes.func,
  loadList: PropTypes.instanceOf(Array),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cleanValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  showClean: PropTypes.bool,
  id: PropTypes.string,
  align: PropTypes.string,
  allowPropagation: PropTypes.bool,
  withBorder: PropTypes.bool,
  backgroundColor: PropTypes.string,
  minHeight: PropTypes.string,
  className: PropTypes.string
};

export default ColumnWrapper;
