import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import * as dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { colors, fonts } from '../../styles/style';
import {
  StyledNotificationDropdown,
  StyledNotificationDropdownItem,
  ItemText,
  ItemTextTime
} from './NotificationDropdownStyled';

import Avatar from '../AvatarEditor/Avatar';
import NoContent from '../NoContent/NoContent';
import { getInitials } from '../../lib/helpers/helper';
import { Paragraph, Title } from '../Text/Text';
import Button from '../Button/Button';

const NotificationDropdown = ({ items, userType, markRead = () => {}, clearAll = f => f, ...antProps }) => {
  const history = useHistory();
  return (
    <StyledNotificationDropdown {...antProps}>
      <div>
        <Title>Notificações</Title>
      </div>
      {items?.length ? (
        items.map(({ id, producerName, message, createdAt, location, hasSeen }) => {
          const internal = location?.indexOf('plataforma.') >= 0 || location?.[0] === '/';

          return (
            <StyledNotificationDropdownItem
              key={id}
              onClick={() => markRead(id)}
              href={location}
              target={internal ? '' : '_blank'}
            >
              <Avatar size="large">{getInitials(producerName)}</Avatar>
              <ItemText>
                <Paragraph color={colors.neutral700} $ellipsisClamp={2}>
                  {message}
                </Paragraph>
                <ItemTextTime>{dayjs(createdAt).fromNow()}</ItemTextTime>
              </ItemText>
              {!hasSeen && <Badge color={colors.primary600} dot />}
            </StyledNotificationDropdownItem>
          );
        })
      ) : (
        <NoContent description="Você não possui nenhuma notificação" renderTitle={false} renderButton={false} />
      )}
      <Button
        style={{ alignSelf: 'center', fontWeight: fonts.weight500 }}
        onClick={() => {
          clearAll();
          history.push(`/${userType}/notificacoes`);
        }}
        key={(items?.length || 0) + 1}
        text
        type="primary"
      >
        Ver todas
      </Button>
    </StyledNotificationDropdown>
  );
};

NotificationDropdown.propTypes = {
  items: PropTypes.arrayOf(Object),
  userType: PropTypes.string,
  markRead: PropTypes.func,
  clearAll: PropTypes.func
};

export default NotificationDropdown;
