import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from './Modal';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import useViewport from '../../_Hooks/useViewport';
import { colors, Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Card from '../Card/Card';
import { getInitials } from '../../lib/helpers/helper';
import Avatar from '../AvatarEditor/Avatar';

const OrderLinkedModal = ({ idQuote, onClose = f => f }) => {
  const { purchaseOrderStatusColor } = useSelector(state => state.setup.enums);
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { list, loading } = useCRUD({
    model: 'order',
    options: {
      attributes: [
        'idOrderStatus',
        'total',
        'code',
        'id',
        'performedCost',
        'discount',
        'shipping',
        'taxes',
        'extraValues'
      ],
      where: { idQuote },
      include: [
        'supplier',
        'responsible',
        {
          model: 'purchaseStatus',
          attributes: ['id', 'name']
        }
      ]
    }
  });

  return (
    <Modal
      open
      loading={loading}
      title="Ordens de compra vinculadas"
      hideFooter
      onClose={() => onClose()}
      isMobile={_isMobile}
      bodyHeight="500px"
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <Div $fullWidth direction="column" gap={spaces.space1}>
          {list?.map(order => {
            return (
              <React.Fragment key={order?.id}>
                <Card
                  bodyBackgroundColor={colors.neutral75}
                  footerBackgroundColor={colors.neutral75}
                  id={order?.id}
                  footer={
                    <Div align="start" direction="column" gap={spaces.space1}>
                      <Div $fullWidth justify="space-between">
                        <Paragraph type="small">
                          {formatCurrency(order?.performedCost, { currencySymbol: 'R$' })}
                        </Paragraph>
                        <Paragraph type="small" color={colors[purchaseOrderStatusColor[order?.idOrderStatus]]}>
                          <strong>{order?.purchaseStatus?.name?.replace(/s$/, '')}</strong>
                        </Paragraph>
                      </Div>
                      <Div gap={spaces.space1}>
                        {order.responsible && (
                          <Avatar size="small" src={order.responsible?.avatarFullpath}>
                            {getInitials(order.responsible?.name)}
                          </Avatar>
                        )}
                        <Paragraph type="small">
                          {order.responsible ? order.responsible?.name : 'Sem responsável'}
                        </Paragraph>
                      </Div>
                    </Div>
                  }
                >
                  <Div align="start" direction="column" gap={spaces.space1}>
                    <Paragraph type="small">
                      <strong>{`${order.code}${order?.name ? ` - ${order.name}` : ''}`}</strong>
                    </Paragraph>
                    <Paragraph type="small">{order.supplier?.name}</Paragraph>
                  </Div>
                </Card>
              </React.Fragment>
            );
          })}
        </Div>
      )}
    </Modal>
  );
};

OrderLinkedModal.propTypes = {
  onClose: PropTypes.func,
  idQuote: PropTypes.string
};

export default OrderLinkedModal;
