import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Modal from './Modal';
import { Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import useCRUD from '../../_Hooks/useCRUD';
import TreeSelectWrapper from '../Table/TreeSelectWrapper';
import { buildRecursiveInclude } from '../../lib/helpers/helper';
import CenteredLoader from '../Loader/CenteredLoader';

const ExcludeCategory = ({ data, onClose, onSubmit, loading }) => {
  const { name, billType, children, id, idCompany } = data || {};
  const [newId, setNewId] = useState();
  const model = 'financialCategory';
  const { list: levelList } = useCRUD({
    model,
    options: {
      onlyMine: true,
      where: { billType, idParent: null, id: { ne: id }, isActive: true },
      include: buildRecursiveInclude({ model, id, idCompany }),
      order: [
        ['name', 'asc'],
        ['children', 'name', 'asc'],
        ['children', 'children', 'name', 'asc']
      ]
    }
  });

  const { data: verifiedCategory, loading: loadingVerify } = useCRUD({
    model: 'financialCategory',
    pathOptions: `/verify/${id}`
  });

  const subCategoryText = children?.length > 0 ? ' e suas subcategorias' : '';

  return (
    <Modal
      loading={loading}
      open
      width="382px"
      title="Excluir categoria"
      submitText="Excluir categoria"
      onSubmit={() => onSubmit(newId)}
      disableButton={(!newId && verifiedCategory?.isUsed) || loadingVerify}
      onClose={onClose}
      zIndex={1400}
    >
      {loadingVerify ? (
        <CenteredLoader />
      ) : (
        <>
          {!verifiedCategory?.isUsed ? (
            <Paragraph>
              Tem certeza que deseja apagar a categoria selecionada
              {subCategoryText}?
            </Paragraph>
          ) : (
            <Div gap={spaces.space2} direction="column">
              <Paragraph>
                Para excluir a categoria{' '}
                <b>
                  {name}
                  {subCategoryText}
                </b>
                , você precisa enviar os lançamentos existentes para outra categoria:
              </Paragraph>
              <Div direction="column" align="flex-start" $fullWidth gap={spaces.space0}>
                <Paragraph type="small">Enviar os lançamentos existentes para:</Paragraph>
                <TreeSelectWrapper
                  value={newId}
                  onChange={setNewId}
                  levelList={levelList}
                  isParentMap
                  withoutEditableDiv
                  placeholder="Selecione uma categoria"
                  dropdownStyle={{ zIndex: 1500 }}
                />
              </Div>
            </Div>
          )}
        </>
      )}
    </Modal>
  );
};

ExcludeCategory.propTypes = {
  data: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default ExcludeCategory;
