import styled from 'styled-components';
import { colors, fonts, spaces, breakpoints } from '../../styles/style';
import TooltipIcon from '../Tooltip/TooltipIcon';

const StyledStrashIcon = styled(TooltipIcon)`
  color: ${colors.red500};
  :hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  margin-top: ${spaces.space3};
  display: block;
`;

const Totals = styled.div`
  display: flex;
  gap: ${spaces.space2};
  @media (max-width: ${breakpoints.mobile}) {
    gap: 0px;
    flex-direction: column;
  }
`;

const TotalItem = styled.p`
  font-size: ${props => (props.total ? fonts.sizeMd : fonts.sizeSm)};
  font-weight: ${props => (props.total ? '500' : 'normal')};
  color: ${colors.neutral600};
  margin-bottom: ${spaces.space2};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutral75};
`;

const TotalTable = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-end;
  text-align-last: center;
  margin: ${spaces.space2} 0;
`;

export { StyledStrashIcon, Container, Line, TableHeader, Totals, TotalItem, TotalTable };
