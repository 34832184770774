import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import Button from '../Button/Button';
import MobileModal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
// style
import { breakpoints, colors, radius, spaces } from '../../styles/style';

const StyledButton = styled(Button)`
  margin-right: ${spaces.space1};

  button {
    padding: ${spaces.space1} ${spaces.space2};
    font-weight: 600;
    color: ${colors.neutral700};
    svg {
      margin-left: ${spaces.space0};
    }
  }

  ${props =>
    props.outline &&
    css`
      padding: ${spaces.space1} ${spaces.space0};
    `};

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledDiv = styled.div`
  position: relative;
  .options {
    position: absolute;
    top: ${spaces.space4};
    left: 0;
    ${props =>
      props.position === 'bottom-left' &&
      css`
        right: -100%;
        left: auto;
      `}
    ${props =>
      props.position === 'bottom-center' &&
      css`
        left: 0;
      `}
    height: auto;
    background-color: ${colors.white};
    padding: ${spaces.space2};
    border-radius: ${radius.radius1};
    box-shadow: 0 ${spaces.space1} ${spaces.space2} 0 rgb(0 0 0 / 8%);
    z-index: 9999;
  }
`;

const FilterButton = ({ text = 'Button', children, closeOnClick, position = 'bottom-right', buttonId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useViewport(window.innerWidth);

  const handleClick = () => {
    if (closeOnClick && isOpen === true) {
      setIsOpen(false);
    }
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget) && !isMobile()) {
      setIsOpen(false);
    }
  };

  const Modal = () =>
    Array.isArray(children)
      ? children
      : React.Children.map(children, child => {
          return React.cloneElement(child, {
            closeModal: () => setIsOpen(false)
          });
        });

  return (
    <StyledDiv onBlur={handleBlur} role="button" tabIndex={0}>
      <StyledButton id={buttonId} text onClick={() => setIsOpen(!isOpen)} position={position}>
        {text} {!isMobile() && <FontAwesomeIcon icon={!isOpen ? faCaretDown : faCaretUp} />}
      </StyledButton>
      {isOpen && (
        <>
          <MobileModal open={isMobile()} hideFooter title="Filtro" onClose={() => setIsOpen(false)}>
            {children}
          </MobileModal>
          <div className="options hideMobile" role="presentation" onClick={handleClick}>
            <Modal />
          </div>
        </>
      )}
    </StyledDiv>
  );
};

FilterButton.propTypes = {
  text: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  closeOnClick: PropTypes.bool,
  position: PropTypes.string,
  buttonId: PropTypes.string
};

export default FilterButton;
