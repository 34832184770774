import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Modal from './Modal';
import { TabPane } from '../Label/Label';
import pix from '../Images/pix.svg';
import pixSuccess from '../Images/pixSuccess.svg';
import Button from '../Button/Button';
import {
  CreatePixContainer,
  Img,
  CardContainer,
  Number,
  FooterContainer,
  CardsContainer,
  PixTermContainer
} from './AccountDataModal.styles';
import { colors, spaces } from '../../styles/style';

import useCRUD from '../../_Hooks/useCRUD';
import Card from '../Card/Card';
import PixTable from '../Table/PixTable';
import AccountDetails from '../VobiPay/AccountDetails';
import { updateCompany } from '../../store/auth/actions/action';
import { HelperLink, Paragraph, Title } from '../Text/Text';

const CardNumber = ({ number, text }) => (
  <Card>
    <CardContainer>
      <Number>{number}</Number>
      <Paragraph>{text}</Paragraph>
    </CardContainer>
  </Card>
);

CardNumber.propTypes = {
  number: PropTypes.string,
  text: PropTypes.string
};

const PixEmptyState = ({ onClick }) => (
  <CreatePixContainer>
    <Img src={pix} alt="pix" />
    <Title>Você não possui chaves Pix cadastradas na Vobi</Title>
    <Paragraph>Receba cobranças em segundos, aumente suas vendas e faça transferências instantaneamente!</Paragraph>
    <Button id="register-pix" type="primary" onClick={onClick}>
      Cadastrar agora
    </Button>
  </CreatePixContainer>
);

PixEmptyState.propTypes = {
  onClick: PropTypes.func
};

const CreatePixStep1 = () => (
  <CreatePixContainer>
    <Img src={pix} alt="pix" />
    <Title>PIX na Vobi Pay</Title>
    <Paragraph>Ative sua chave Pix na Vobi para aproveitar todas as vantagens em sua conta</Paragraph>
    <CardsContainer>
      <CardNumber number="1" text="Aumente suas vendas" />
      <CardNumber number="2" text="Receba cobranças em segundos" />
      <CardNumber number="3" text="Faça saques via PIX" />
    </CardsContainer>
  </CreatePixContainer>
);

const CreatePixStep2 = () => (
  <CreatePixContainer>
    <Img src={pixSuccess} alt="pix" marginBottom={spaces.space3} />
    <Title>Solicitação enviada ao Banco Central</Title>
    <Paragraph>
      Sua chave PIX foi enviada para o cadastro junto ao Banco Central, em instantes ela estará disponível.
    </Paragraph>
    <Paragraph>
      Ao utilizar sua chave, quem for te pagar vai visualizar{' '}
      <strong>seu nome completo, alguns dígitos do seu CPF e o nome do prestador de serviço de pagamento.</strong>
    </Paragraph>
  </CreatePixContainer>
);

const AccountDataModal = ({ onClose, initialStep, withDispatch = true }) => {
  const { user } = useSelector(state => state.authReducer);
  const { company } = user;
  const dispatch = useDispatch();
  const { pixTerms: pixTermsUrl } = useSelector(state => state.setup.systemData);
  const [createPixStep, setCreatePixStep] = useState(initialStep || 0);
  const [selectedTab, setSelectedTab] = useState('0');
  const [data, setData] = useState();
  const [pixData, setPixData] = useState(company?.pix);
  const [pixTerms, setPixTerms] = useState();
  const [pixTermsErr, setPixTermsErr] = useState();

  const { handleCreate, loading } = useCRUD({
    model: 'pay',
    immediatelyLoadData: false
  });

  const { handleGet } = useCRUD({
    model: 'company',
    immediatelyLoadData: false
  });

  const handleClose = () => {
    onClose(pixData);

    if (pixData && withDispatch) dispatch(updateCompany({ ...user?.company, pix: pixData?.key }));
  };

  useEffect(() => {
    handleGet({
      refetchPathOptions: `/${user?.idCompany}`,
      refetchOptions: {
        attributes: ['pix']
      }
    }).then(resp => {
      setData(resp.pix);
    });
  }, []);

  const handleClick = () => {
    if (!pixTerms) {
      setPixTermsErr('* É necessário aceitar o termo de consentimento.');
      return;
    }

    if (createPixStep === 2) {
      handleClose();
      return;
    }

    handleCreate({ values: { pixTerms: pixTermsUrl }, postPathOptions: '/create-pix-key' }).then(resp => {
      if (!resp?.error) setCreatePixStep(2);
      setPixData(resp);
    });
  };
  const footer = (
    <FooterContainer>
      {createPixStep === 1 ? (
        <Button text onClick={() => handleClose()}>
          Deixar para depois
        </Button>
      ) : (
        <div />
      )}

      <Button id="submit-button" type="primary" onClick={handleClick} loading={loading}>
        {createPixStep === 1 ? 'Cadastrar agora' : 'Entendi'}
      </Button>
    </FooterContainer>
  );

  const getTitle = () => {
    if (createPixStep === 0) return 'Dados da conta';
    if (createPixStep === 1) return 'PIX';
    return 'Chave PIX enviada';
  };

  return (
    <>
      <Modal
        title={getTitle()}
        width={688}
        open
        onSubmit={() => {}}
        onClose={() => handleClose()}
        hideFooter={createPixStep === 0}
        footer={createPixStep > 0 ? footer : null}
      >
        {createPixStep === 1 ? (
          <>
            <CreatePixStep1 />
            <PixTermContainer>
              <Checkbox value={pixTerms} onChange={e => setPixTerms(e.target.checked)}>
                Autorizo a Vobi a cadastrar e gerenciar chaves pix conforme{' '}
                <HelperLink target="_blank" rel="noopener noreferrer" href={pixTermsUrl}>
                  termos de consentimento.
                </HelperLink>
              </Checkbox>
              {pixTermsErr && (
                <div>
                  <small style={{ color: colors.red500 }}>{pixTermsErr}</small>
                </div>
              )}
            </PixTermContainer>
          </>
        ) : (
          <>
            {createPixStep > 0 ? (
              <CreatePixStep2 />
            ) : (
              <Tabs activeKey={selectedTab} onChange={setSelectedTab}>
                <TabPane key="0" tab="Pix">
                  {data ? (
                    <PixTable data={data} onDeletePix={setData} />
                  ) : (
                    <PixEmptyState onClick={() => setCreatePixStep(1)} />
                  )}
                </TabPane>
                <TabPane key="1" tab="Detalhes">
                  <AccountDetails company={company} />
                </TabPane>
              </Tabs>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

AccountDataModal.propTypes = {
  onClose: PropTypes.func,
  initialStep: PropTypes.number,
  withDispatch: PropTypes.bool
};

export default AccountDataModal;
