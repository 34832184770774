import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Card from './Card';

const CardCheckbox = styled(Checkbox)`
  span {
    display: none;
  }
`;

const SelectableCard = ({ checked, label, icon, onClick, disabled }) => {
  return (
    <Div align="baseline" className={!disabled && 'pointer'}>
      <Card
        margin="0"
        bodyPadding={spaces.space1}
        borderColor={checked ? colors.primary100 : colors.neutral100}
        bodyBackgroundColor={checked ? colors.primary50 : colors.white}
        maxWidth="max-content"
        onClick={() => onClick(!checked)}
        disabled={disabled}
        id={`card${label}`}
      >
        <Div gap={spaces.space1}>
          <CardCheckbox
            id={`check${label}`}
            name={`check${label}`}
            checked={checked}
            onChange={e => onClick(e.target.checked)}
            disabled={disabled}
          />
          <FontAwesomeIcon icon={icon} color={checked ? colors.primary500 : colors.neutral500} id={`icon${label}`} />
          <Paragraph type="small" color={checked ? colors.primary500 : colors.neutral500}>
            {label}
          </Paragraph>
        </Div>
      </Card>
    </Div>
  );
};

SelectableCard.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default SelectableCard;
