import React from 'react';

import * as dayjs from 'dayjs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { toast } from 'react-toastify';
import Dropdown from '../Dropdown/Dropdown';

import NotesHeader from '../Header/NotesHeader';

import { NoteDisplay, Container, SharedIcon, EllipsisButton, DropdownButton } from './NotesList.styles';
import useViewport from '../../_Hooks/useViewport';

import { useContextHook } from '../../contexts/GeneralContext';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';

const NotesList = ({ selected, onClick, onDelete, onAdd, list, isApply, handleCreateNote, handleChangeShared }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const { setShowSidebarModal } = useContextHook();
  const { isMobile } = useViewport(window.innerWidth);
  const isCustomer = user.userType === userType.customer.value;

  const analytics = AnalyticsServiceFactory();

  const handleCopyLink = id => {
    analytics.track({ event: 'project-file-copy-link' });
    const urlToCopy = `${window.location.origin}/n/${id}`;
    setTimeout(() => navigator.clipboard.writeText(urlToCopy), 0);
    toast.success('Link copiado');
  };

  return (
    <Container $hasItems={list?.length} width={isMobile() ? '100%' : '270px'}>
      <NotesHeader counter={list?.length || 0} onClick={onAdd} isApply={isApply} />
      <ul id="notes-list">
        {list?.length
          ? list.map(item => {
              const { id, idTemplate, note, date, shared, createdAt, updatedAt } = item;
              const firstLine = (note && note.split('<br>')[0]) || '';

              const classes = classNames({
                'notes-list__item': true,
                'notes-list__item--active': id === selected.id
              });

              return (
                <li key={id} className={classes}>
                  <button onClick={() => onClick({ id, note, date, shared, createdAt, updatedAt })}>
                    <NoteDisplay>
                      <p style={{ maxWidth: isMobile() ? '85%' : '100%' }}>
                        {parse(firstLine?.replace(/(<([^>]+)>)/gi, ''))}
                      </p>
                      {!isMobile() && !isApply && !isCustomer ? (
                        <div>
                          <Dropdown
                            slim
                            buttonPadding="0px"
                            menu={
                              <Menu>
                                {!idTemplate && (
                                  <>
                                    <Menu.Item key={0} style={{ marginBottom: '0px' }}>
                                      <DropdownButton text onClick={() => handleChangeShared(id, shared)}>
                                        {shared ? 'Descompartilhar' : 'Compartilhar'}
                                      </DropdownButton>
                                    </Menu.Item>

                                    <Menu.Item key={0} style={{ marginBottom: '0px' }}>
                                      <DropdownButton
                                        text
                                        onClick={e => {
                                          e.stopPropagation();
                                          handleCopyLink(id);
                                        }}
                                      >
                                        Copiar link
                                      </DropdownButton>
                                    </Menu.Item>
                                  </>
                                )}
                                <Menu.Item key={1} style={{ marginBottom: '0px' }}>
                                  <DropdownButton text onClick={() => handleCreateNote({ id, note })}>
                                    Duplicar
                                  </DropdownButton>
                                </Menu.Item>
                                <Menu.Item key={2} style={{ marginBottom: '0px' }}>
                                  <DropdownButton text onClick={onDelete}>
                                    Excluir
                                  </DropdownButton>
                                </Menu.Item>
                              </Menu>
                            }
                          />
                        </div>
                      ) : null}
                      {isMobile() && !isApply && (
                        <EllipsisButton onClick={() => setShowSidebarModal(true)}>
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </EllipsisButton>
                      )}
                    </NoteDisplay>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <time>{dayjs(item.createdAt).format('DD [de] MMMM [de] YYYY')}</time>
                      {item.shared && !isCustomer ? (
                        <SharedIcon>
                          <FontAwesomeIcon inverse size="xs" icon={faUserFriends} />
                        </SharedIcon>
                      ) : null}
                    </div>
                  </button>
                </li>
              );
            })
          : null}
      </ul>
    </Container>
  );
};

NotesList.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.instanceOf(Object),
  list: PropTypes.instanceOf(Array),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  isApply: PropTypes.bool,
  handleCreateNote: PropTypes.func,
  handleChangeShared: PropTypes.func
};
NotesList.defaultProps = {
  list: [],
  onClick: f => f,
  selected: null,
  onAdd: f => f,
  onDelete: f => f
};
NotesList.displayName = 'NotesList';

export default NotesList;
