import React from 'react';
import { Link } from 'react-router-dom';
import formatCurrency from '../../helpers/formatCurrency';

export const product = () => ({
  brand: { label: 'Marca', otherProps: { span: 4 } },
  costCenter: { label: 'Categoria', nestedKey: 'name', otherProps: { span: 4 } },
  link: {
    label: 'Link',
    format: value =>
      value ? (
        <>
          {value.indexOf('http') >= 0 ? (
            <a href={`${value || '#'}`} target="_blank" rel="noopener noreferrer">
              Ver produto
            </a>
          ) : (
            <Link to={`${value}`}>Ver produto</Link>
          )}
        </>
      ) : (
        '-'
      ),
    otherProps: { span: 4 }
  },
  height: { label: 'Altura', format: value => (Number(value) ? `${formatCurrency(value)}cm` : '-') },
  width: { label: 'Largura', format: value => (Number(value) ? `${formatCurrency(value)}cm` : '-') },
  length: {
    label: 'Comprimento',
    format: value => (Number(value) ? `${formatCurrency(value)}cm` : '-')
  },
  weight: { label: 'Peso', format: value => (Number(value) ? `${formatCurrency(value)}kg` : '-') }
});
