import React from 'react';

import LogoIcon from '../../../components/Icons/Logo';
import PurchaseOrder from './PurchaseOrder';
import { Container, Content, Header } from '../../ViewPublic.style';

const OrderViewPublic = () => {
  return (
    <Container>
      <Header $maxWidth="1280px">
        <div>
          <LogoIcon scale={0.6} />
        </div>
      </Header>
      <Content $maxWidth="1280px">
        <PurchaseOrder isSupplierView isView />
      </Content>
    </Container>
  );
};

export default OrderViewPublic;
