import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from '../Text/Text';
import { spaces } from '../../styles/style';

const ConfirmModalAlertInfoBuilder = ({ alertText, afterAlertList = [], listStyle = {}, style = {} }) => {
  return (
    <div style={style}>
      <Paragraph>{alertText}</Paragraph>
      <ul style={{ padding: `${spaces.space1} 0 0 ${spaces.space2}` }}>
        {afterAlertList?.map(alertItem => (
          <li style={{ listStyle: 'disc', lineHeight: 1.2, ...listStyle }}>{alertItem}</li>
        ))}
      </ul>
    </div>
  );
};

ConfirmModalAlertInfoBuilder.propTypes = {
  alertText: PropTypes.string,
  afterAlertList: PropTypes.instanceOf(Array),
  listStyle: PropTypes.PropTypes.instanceOf(Object),
  style: PropTypes.PropTypes.instanceOf(Object)
};

export default ConfirmModalAlertInfoBuilder;
