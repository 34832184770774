import React from 'react';
import PropTypes from 'prop-types';
import NoNewCatalogContent from '../../../components/NoContent/NoNewCatalogContent';
import { NoDrawerProduct, NoDrawerProductKeyword } from '../../../components/Images/NoDrawerProduct';

const ITEMS_PAGE = 18;

const redirectType = {
  1: 'produtos',
  2: 'servicos',
  4: 'composicoes'
};

const images = {
  initialState: () => (
    <NoNewCatalogContent
      title="Comece a pesquisar"
      image={<NoDrawerProduct />}
      description="Defina os valores do filtro para ver os resultados."
    />
  ),
  notFound: ({ keyword, suggestion, onClick }) => {
    const Description = () => (
      <>
        Não foram encontrados resultados para sua busca
        {keyword && (
          <>
            {' '}
            de <b>{keyword}</b>
          </>
        )}
        .
        {suggestion ? (
          <span style={{ marginLeft: '2px' }}>
            Talvez você queira pesquisar por {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a aria-hidden="true" onClick={() => onClick(suggestion, true)}>
              {suggestion}
            </a>
          </span>
        ) : (
          ''
        )}
      </>
    );
    return <NoNewCatalogContent title="Oh-Oh..." image={<NoDrawerProductKeyword />} description={<Description />} />;
  }
};

images.notFound.propTypes = {
  keyword: PropTypes.string,
  suggestion: PropTypes.string,
  onClick: PropTypes.func
};

export { ITEMS_PAGE, redirectType, images };
