import React from 'react';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { faBookmark, faBookmarkSlash, faCartShopping } from '@fortawesome/pro-solid-svg-icons';

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { store } from '../../config/redux-store';
import EditableInput from '../../../components/Input/EditableInput';
import { formatDecimalsDinamically, validDate } from '../../helpers/helper';
import { Div, colors, spaces, fonts } from '../../../styles/style';
import formatCurrency from '../../helpers/formatCurrency';
import DatePicker from '../../../components/Datepicker/Datepicker';
import Select from '../../../components/Select/Select';
import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import { ButtonGroup } from '../../../components/Button/ItemDetailButton.styled';
import ItemDetailButton from '../../../components/Button/ItemDetailButton';
import formatNumber from '../../helpers/formatNumber';
import ImageInput from '../../../components/Input/ImageInput';
import AddAppropriation from '../../../components/Button/AddAppropriation';
import { Paragraph } from '../../../components/Text/Text';

const columns = ({
  readOnly,
  addAppropriation,
  editRow,
  deleteRow,
  levelsList,
  itemAppropriationMap,
  childrenColumnName,
  linkItem,
  paymentView,
  limitedTable,
  redirectToOrder,
  isCreatePayment,
  isSupplierView,
  isMobile,
  isMobileChildren,
  splitView
}) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;
  const { refurbishItemTypes = [] } = setup.systemData;

  const widthColumnName = limitedTable ? 280 : 330;
  const mobileEdit = isMobile && !readOnly;

  let totalValueTitle = 'Valor de compra total';

  if (isMobileChildren) {
    totalValueTitle = 'Subtotal';
  } else if (isMobile) {
    totalValueTitle = 'Total';
  } else if (paymentView) {
    totalValueTitle = 'Valor total';
  }

  const libraryIcon = ({ isLinked, type, index }) => {
    return refurbishItemType?.parent !== type && !paymentView ? (
      <ItemDetailButton
        show={refurbishItemType?.parent !== type && !paymentView}
        titleTooltip={`${isLinked ? 'V' : 'Desv'}inculado com a biblioteca`}
        iconColor={colors.primary500}
        onClick={() => {
          if (readOnly || isLinked) {
            return null;
          }
          return linkItem({ index });
        }}
        id="item-library"
        icon={isLinked ? faBookmark : faBookmarkSlash}
        iconId="item-library"
      />
    ) : null;
  };

  const codeColumn = {
    title: 'Código',
    dataIndex: 'code',
    width: paymentView ? 70 : 80,
    key: 'code',
    paymentView,
    render: (val, row, index) => {
      const code = row.code || row.item?.code || '-';

      if ((readOnly && isMobile) || splitView) {
        return (
          <Div id={`purchase-item-code-${index + 1}`} $fullWidth height={isMobile && spaces.space4}>
            <Paragraph type="small">{code}</Paragraph>
          </Div>
        );
      }

      return (
        refurbishItemType?.notParent?.includes(row?.type) && (
          <>
            {!!readOnly || row.idItem ? (
              <Tooltip title={code}>
                <Paragraph key={`code${row.id}`} ellipsis>
                  {code}
                </Paragraph>
              </Tooltip>
            ) : (
              <EditableInput
                key={`code${row.id}`}
                noWrap
                value={code}
                row={row}
                width="100%"
                onChange={value => editRow({ value, key: 'code', index, row })}
                rowId={row.id}
                align={isMobile && 'flex-start'}
                noPadding={isMobile}
                forceShow={isMobile}
              />
            )}
          </>
        )
      );
    }
  };

  const itemColumn = {
    title: isMobileChildren ? 'Nível' : 'Item',
    dataIndex: 'name',
    key: 'name',
    width: paymentView ? widthColumnName : 350,
    renderCollapseIcon: true,
    alwaysShow: true,
    paymentView,
    mobileWidth: '100%',
    mobileChildrenRender: true,
    render: (val, row, index) => {
      const { type, id, refurbishItem, idItem, externalBaseCode } = row || {};
      const typeName = refurbishItemTypes?.find(r => r.value === row.type)?.label;
      const isLinked = idItem || externalBaseCode;
      const itemName = row.name || row.item?.name || refurbishItem?.name || refurbishItem?.label || '-';
      const isLevel = refurbishItemType?.parent === type;
      const itemImage = refurbishItemType?.product === type ? 'boxOpen' : 'screwdriverWrench';

      if (readOnly) {
        return (
          <Div>
            {!isLevel && (
              <Tooltip title={typeName}>
                <ImageInput size="18px" key={`image${id}`} id={id} value={itemImage} disabled noMarginMobile />
              </Tooltip>
            )}
            <Div id={`purchase-item-name-${index + 1}`} $fullWidth height={isMobile && spaces.space4}>
              <Paragraph type="small">{itemName}</Paragraph>
            </Div>
            {isMobile && libraryIcon({ isLinked, type, index })}
          </Div>
        );
      }

      return (
        <Div gap={spaces.space1} $fullWidth justify="space-between">
          {refurbishItemType?.parent !== type ? (
            <>
              <Div $fullWidth>
                <Tooltip title={typeName}>
                  <ImageInput size="12px" key={`image${id}`} id={id} value={itemImage || 'boxOpen'} disabled />
                </Tooltip>
                <EditableInput
                  id={`purchase-item-name-${index + 1}`}
                  key={`name-${id}`}
                  value={itemName}
                  row={row}
                  onChange={value => editRow({ value, key: 'name', index, row })}
                  rowId={id}
                  disabled={readOnly || splitView}
                  align={isMobile && 'flex-start'}
                  noPadding={isMobile}
                  forceShow={isMobile}
                  propsParagraph={splitView ? { $ellipsisClamp: 1 } : {}}
                />
              </Div>
              {!limitedTable && !isMobile ? (
                <AddAppropriation
                  index={index}
                  item={row}
                  childrenColumnName={childrenColumnName}
                  levelsList={levelsList}
                  itemAppropriationMap={itemAppropriationMap}
                  addAppropriation={addAppropriation}
                />
              ) : null}
              {isMobile && libraryIcon({ idItem, type, index })}
            </>
          ) : (
            <Select
              id="appropriation-level-name"
              options={levelsList.filter(
                i => !itemAppropriationMap[row.idParent]?.children?.[i.id] || refurbishItem?.id === i?.id
              )}
              customLabel="name"
              customValue="id"
              value={refurbishItem?.id || row?.idRefurbishItem}
              onChange={(_, object) =>
                editRow({
                  index,
                  row,
                  multipleEdit: [
                    { key: 'refurbishItem', value: { id: object?.value, name: object?.label } },
                    { key: 'name', value: object?.label },
                    { key: 'idRefurbishItem', value: object?.value }
                  ]
                })
              }
              style={{ width: !isMobile && '80%' }}
              sendFullObject
              dropdownMatchSelectWidth={false}
            />
          )}
        </Div>
      );
    }
  };

  const percentageColumn = isSupplierView
    ? {}
    : {
        title: '%',
        dataIndex: 'percentage',
        key: 'percentage',
        width: 90,
        paymentView: paymentView && !limitedTable,
        mobileChildrenRender: true,
        render: (val, row, index) => {
          const { type, [childrenColumnName]: children } = row;
          const isAppropriation = refurbishItemType?.parent === type;
          const isRenderMobile = !isAppropriation && isMobile;

          let renderValue;

          if (children?.length) {
            renderValue = children?.reduce((acc, cur) => acc + Number(cur?.percentage || 0), 0);
          } else if (isAppropriation) {
            renderValue = val;
          } else {
            renderValue = 100;
          }

          renderValue = formatCurrency(renderValue);

          if ((!isAppropriation && !isMobile) || readOnly) {
            return (
              <Div id="purchase-item-percentage" height={isMobile && spaces.space4}>
                <Paragraph type="small">{renderValue}%</Paragraph>
              </Div>
            );
          }

          return (
            <EditableInput
              id="appropriation-level-percentage"
              key={`percentage${row.id}`}
              noWrap
              value={isAppropriation && !val ? '' : `${renderValue}%`}
              row={row}
              width="100%"
              onChange={_value => editRow({ value: formatNumber(_value), key: 'percentage', index, row })}
              disabled={!!readOnly || row.isDisabled || isRenderMobile}
              rowId={row.id}
              align={isMobile && 'flex-start'}
              noPadding={isMobile}
              forceShow={isMobile}
            />
          );
        }
      };

  const quantityColumn = {
    title: isMobile ? 'Quantidade' : 'Qtd.',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 90,
    paymentView,
    mobileDivWidth: paymentView && limitedTable && '50%',
    mobileChildrenRender: true,
    render: (val, row, index) => {
      const { type } = row;
      return readOnly ? (
        <Div
          id={
            refurbishItemType?.parent === type
              ? `appropriation-level-quantity-${index + 1}`
              : `purchase-item-quantity-${index + 1}`
          }
          height={spaces.space4}
        >
          <Paragraph type="small">{formatDecimalsDinamically(val)}</Paragraph>
        </Div>
      ) : (
        <EditableInput
          id={
            refurbishItemType?.parent === type
              ? `appropriation-level-quantity-${index + 1}`
              : `purchase-item-quantity-${index + 1}`
          }
          key={`qtd${row.id}`}
          noWrap
          value={formatDecimalsDinamically(val)}
          row={row}
          width="100%"
          onChange={_value => editRow({ value: formatNumber(_value), key: 'quantity', index, row })}
          disabled={!!readOnly || row.isDisabled}
          rowId={row.id}
          align={isMobile && 'flex-start'}
          noPadding={isMobile}
          forceShow={isMobile}
        />
      );
    }
  };

  const unitColumn = {
    title: 'Un.',
    dataIndex: 'idUnit',
    key: 'idUnit',
    width: 80,
    paymentView,
    render: (val, row, index) => {
      const { units, type, idItem } = row;

      if (refurbishItemType?.parent === type) return null;

      const hasLibraryLink = idItem;

      if ((readOnly && isMobile) || splitView) {
        return (
          <Div id={`purchase-item-unit-${index + 1}`} $fullWidth height={isMobile && spaces.space4}>
            <Paragraph type="small">{units?.name || units?.label || '-'}</Paragraph>
          </Div>
        );
      }

      return (
        <ColumnWrapper
          id="purchase-item-unit"
          value={units}
          model="unit"
          withBorder={mobileEdit}
          minHeight={mobileEdit && spaces.space4}
          backgroundColor={mobileEdit && colors.white}
          onSelect={(value, _, object) =>
            editRow({
              value,
              key: 'idUnit',
              index,
              object,
              objectKey: 'units',
              row
            })
          }
          disabled={readOnly || (hasLibraryLink && !paymentView)}
        />
      );
    }
  };

  const purchaseDateColumn = {
    title: 'Necessidade',
    dataIndex: 'purchaseDate',
    key: 'purchaseDate',
    width: 100,
    render: (val, row, index) => {
      const { type, longPressEvent = () => {} } = row;

      if (!refurbishItemType?.notParent?.includes(type)) return null;

      if (readOnly) {
        return (
          <Div
            id={`item-purchase-date-${index + 1}`}
            $fullWidth
            height={isMobile && spaces.space4}
            margin={val || isMobile ? '0' : '0 0 0 60px'}
            {...(longPressEvent(row) || {})}
          >
            <Paragraph type="small">{validDate(val, 'DD/MM/YY') || '-'}</Paragraph>
          </Div>
        );
      }
      return (
        <DatePicker
          id="purchase-date-picker"
          name="purchaseDate"
          format="DD/MM/YY"
          value={val ? dayjs(val) : dayjs()}
          allowClear={false}
          onChange={value => editRow({ value, key: 'purchaseDate', index, row })}
          onClick={e => e.stopPropagation()}
          suffixIcon={null}
          padding={`${spaces.space0} ${spaces.space1}`}
          $fontSize={fonts.sizeSm}
          $height={isMobile && spaces.space4}
        />
      );
    }
  };

  const valueColumn = {
    title: `${paymentView ? 'Valor' : 'Valor de compra un.'}`,
    dataIndex: 'price',
    key: 'price',
    width: paymentView ? 100 : 140,
    type: 'number',
    paymentView,
    render: (val, row, index) => {
      const { id, type, isDisabled } = row;

      if (refurbishItemType?.parent === type) return null;

      const valFormatted = val ? formatCurrency(val, { currencySymbol: 'R$' }) : null;

      if (readOnly && isMobile) {
        return (
          <Div id={`purchase-item-price-${index + 1}`} $fullWidth height={isMobile && spaces.space4}>
            <Paragraph type="small">{valFormatted || '-'}</Paragraph>
          </Div>
        );
      }

      return (
        <EditableInput
          type="currency"
          id={`purchase-item-price-${index + 1}`}
          key={`price${id}`}
          noWrap
          value={valFormatted}
          row={row}
          width="100%"
          onChange={value => {
            editRow({ value: formatNumber(value), key: 'price', index, row });
          }}
          disabled={!!readOnly || isDisabled || refurbishItemType?.parent === type}
          rowId={id}
          align={isMobile && 'flex-start'}
          noPadding={isMobile}
          forceShow={isMobile}
          placeholder="R$ 0,00"
        />
      );
    }
  };

  const totalValueColumn = {
    title: totalValueTitle,
    dataIndex: 'total',
    key: 'total',
    width: paymentView ? 100 : 150,
    type: 'number',
    paymentView,
    inlineMobile: true,
    mobileWidth: '100%',
    mobileChildrenRender: true,
    render: (val, row) => {
      const { price, quantity, type } = row;

      const _price = row.idParent ? itemAppropriationMap[row.idParent]?.data?.price : price;

      const total = formatCurrency(Number(_price) * Number(quantity) || 0, { currencySymbol: 'R$' });

      return (
        <Div
          id={refurbishItemType?.parent === type ? 'appropriation-level-total' : 'purchase-item-total'}
          $fullWidth
          justify={isMobile ? 'flex-end' : 'flex-start'}
        >
          {isMobile ? (
            <Paragraph type="small">
              <strong>{total}</strong>
            </Paragraph>
          ) : (
            total
          )}
        </Div>
      );
    }
  };

  if (isMobile || isMobileChildren) {
    const mobileColumns = [
      itemColumn,
      codeColumn,
      valueColumn,
      quantityColumn,
      ...(!limitedTable ? [percentageColumn] : []),
      totalValueColumn
    ];

    if (!paymentView && !isMobileChildren)
      return [
        itemColumn,
        codeColumn,
        unitColumn,
        quantityColumn,
        percentageColumn,
        purchaseDateColumn,
        valueColumn,
        totalValueColumn
      ];

    return isMobileChildren ? mobileColumns.filter(c => c.mobileChildrenRender) : mobileColumns;
  }

  const allColumns = [
    codeColumn,
    itemColumn,
    percentageColumn,
    quantityColumn,
    unitColumn,
    purchaseDateColumn,
    valueColumn,
    totalValueColumn,
    ...(isSupplierView || readOnly
      ? []
      : [
          {
            title: 'Ações',
            dataIndex: 'actions',
            key: 'actions',
            paymentView,
            width: 80,
            render: (_, row, index) => {
              const { id, type, idParent, idItem, externalBaseCode } = row;

              return (
                <ButtonGroup>
                  {libraryIcon({ isLinked: idItem || externalBaseCode, type, index })}
                  {refurbishItemType?.parent !== type && row?.idOrder && row?.id && paymentView && !isCreatePayment ? (
                    <ItemDetailButton
                      show
                      titleTooltip="Abrir ordem de compra vinculada."
                      iconColor={colors.primary500}
                      onClick={() => redirectToOrder(row?.idOrder)}
                      icon={faCartShopping}
                      iconId="item-order"
                    />
                  ) : null}
                  <ItemDetailButton
                    show={!readOnly}
                    titleTooltip="Excluir"
                    onClick={() => {
                      deleteRow(id, idParent);
                    }}
                    iconColor={colors.red500}
                    icon={faTrashCan}
                    iconId={`cancel-item-button-${index + 1}`}
                  />
                </ButtonGroup>
              );
            }
          }
        ])
  ];

  return paymentView ? allColumns.filter(c => c.paymentView) : allColumns;
};

export { columns };
