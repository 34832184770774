import { editItem, deleteAction } from './_dropdown';

const instalmentItem = [
  { ...editItem, label: 'Anexo', modalParams: { hideDetails: true } },
  {
    label: 'Ver detalhes',
    modal: 'itemDetailModal',
    modalParams: {
      isInstalment: true,
      model: 'refurbish-item-instalment',
      options: {
        include: [
          {
            model: 'RefurbishItems',
            include: ['supplier', 'refurbish']
          },
          'files'
        ]
      }
    }
  }
];
const instalmentParent = ({ idRefurbishItem, idRefurbish, isMobile }) => [
  {
    ...editItem,
    label: 'Anexo',
    modalParams: { hideDetails: true, tab: '1', model: 'refurbish-items' }
  },
  { ...editItem, label: 'Ver detalhes', modalParams: { model: 'refurbish-items' } },
  ...(isMobile
    ? [
        {
          label: 'Adicionar parcela',
          verb: 'post',
          model: 'refurbish-item-instalment',
          values: { idRefurbishItem, idRefurbish, id: undefined }
        }
      ]
    : [])
];
const instalmentParentGrouped = ({ idRefurbishItem, idRefurbish, isMobile }) => [
  { ...editItem, label: 'Anexo', modalParams: { hideDetails: true, tab: '1', model: 'refurbish-items' } },
  { ...editItem, label: 'Ver detalhes', modalParams: { model: 'refurbish-items' } },
  {
    label: 'Ver itens desse agrupamento',
    modal: 'modal'
  },
  ...(isMobile
    ? [
        {
          label: 'Adicionar parcela',
          verb: 'post',
          model: 'refurbish-item-instalment',
          values: { idRefurbishItem, idRefurbish, id: undefined }
        }
      ]
    : [])
];
const instalmentChildren = ({ allowSetPaid, isPaid, allowClone, allowDelete }) => [
  {
    ...editItem,
    label: 'Anexos',
    modalParams: { hideDetails: true, isInstalment: true, model: 'refurbish-item-instalment' }
  },
  {
    ...editItem,
    label: 'Ver detalhes',
    modalParams: { tab: '1', isInstalment: true, model: 'refurbish-item-instalment' }
  },
  ...(allowSetPaid
    ? [
        {
          label: isPaid ? 'Marcar como aguardando solicitação' : 'Marcar como pago',
          verb: 'put',
          values: { status: isPaid ? 1 : 6 }
        }
      ]
    : []),
  ...(allowClone
    ? [
        {
          label: 'Duplicar',
          verb: 'post',
          pathOptions: '/clone'
        }
      ]
    : []),
  ...(allowDelete ? [deleteAction] : [])
];

export { instalmentItem, instalmentParent, instalmentParentGrouped, instalmentChildren };
