import * as Yup from 'yup';
import { name, isActive } from '../schema';

const refurbishGroupSchema = Yup.object().shape({
  name,
  isActive
});

const refurbishGroupMapping = ({ largeDescription }) => ({
  name: {
    name: 'Nome',
    type: 'text',
    placeholder: 'Informe o nome do grupo',
    xs: 24,
    md: 12
  },
  isActive: {
    type: 'checkbox',
    name: 'Ativo?',
    xs: 24,
    md: 6,
    defaultValue: true
  },
  description: {
    name: 'Descrição',
    type: 'textarea',
    xs: 24,
    md: largeDescription ? 24 : 12,
    height: 80,
    plugins: undefined,
    toolbar: false,
    statusbar: false
  },
  image: {
    name: 'Imagem',
    type: 'file',
    xs: 24,
    md: 24,
    textArray: true,
    text: 'Enviar'
  }
});

export { refurbishGroupSchema, refurbishGroupMapping };
