import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Drawer from './Drawer';
import useCRUD from '../../_Hooks/useCRUD';
import Button from '../Button/Button';
import TableOrListV2 from '../List/TableOrListV2';
import { columns } from '../../lib/mapping/TableOrList/apportionmentColumns';
import { sumByField, tableDynamicHeight } from '../../lib/helpers/helper';
import { colors, Div, fonts, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import formatCurrency from '../../lib/helpers/formatCurrency';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import TooltipIcon from '../Tooltip/TooltipIcon';
import ConfirmModal from '../Modal/ConfirmModal';
import WarningBar from '../Alert/WarningBar';
import useViewport from '../../_Hooks/useViewport';

const ApportionmentDrawer = ({
  apportionmentList,
  originalRefurbish,
  onClose = f => f,
  total = 0,
  changeApportionment = f => f,
  hasInstallmentPaid
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [refurbishList, setRefurbishList] = useState(apportionmentList ? Object.values(apportionmentList) : []);
  const totalValueList = sumByField(refurbishList, 'value') || 0;
  const [indexError, setIndexError] = useState({});
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const [touched, setTouched] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [forceRender, setForceRender] = useState(null);

  const listPercentage = total > 0 ? (totalValueList / total) * 100 : 0;

  const { list, loading, totalItems } = useCRUD({
    model: 'refurbish',
    options: {
      attributes: ['id', 'name'],
      where: { isActive: true },
      order: ['name']
    }
  });
  useEffect(() => {
    if (!list.length) return;
    const firstRefurbish = list?.find(i => i?.id === originalRefurbish);
    setRefurbishList(prev =>
      prev?.length > 0
        ? prev
        : [
            {
              refurbish: { id: firstRefurbish?.id, name: firstRefurbish?.name },
              value: 0
            }
          ]
    );
    forceRender === null && setForceRender(true);
  }, [list]);

  const handleAdd = () => {
    setRefurbishList(prev => [...prev, { value: 0 }]);
    setTouched(true);
  };

  const changeRow = (value, field, index, customField = field) => {
    setTouched(true);

    const mapped = refurbishList?.map(i => i?.refurbish?.id);
    if (customField === 'refurbish' && mapped?.includes(value?.id)) {
      toast.error('Essa reforma já está no rateio');
      return;
    }
    const revertField = {
      value: 'percentageSplitValue',
      percentageSplitValue: 'value'
    };
    setRefurbishList(prev => {
      const newValue = {
        ...prev[index],
        [field]: value,
        ...(revertField[field]
          ? {
              [revertField[field]]:
                field === 'value' && total > 0 ? (value / total) * 100 : Number((value * total) / 100).toFixed(2)
            }
          : {})
      };
      return [...prev.slice(0, index), newValue, ...prev.slice(index + 1)];
    });
  };

  const removeLine = idx => {
    setRefurbishList(prev => {
      const newList = [...prev];
      newList.splice(idx, 1);
      return newList;
    });
  };

  const _columns = columns({
    changeRow,
    removeLine,
    renderTrashIcon: refurbishList?.length > 1,
    refurbishesIds: refurbishList?.map(i => i?.refurbish?.id),
    indexError,
    refurbishItemType,
    hasInstallmentPaid,
    isMobile: isMobile()
  });

  const handleConfirm = () => {
    if (total - parseFloat(totalValueList.toFixed(2)) !== 0) {
      toast.error(
        `Seu rateio tem um valor que difere em ${formatCurrency(total - totalValueList, {
          currencySymbol: 'R$'
        })} do total de ${formatCurrency(total, { currencySymbol: 'R$' })}.`
      );
      return;
    }
    const refurbishErro = !refurbishList.every(i => !!i?.refurbish?.id);
    const valueError = !refurbishList.every(i => i?.value > 0);
    if (refurbishErro || valueError) {
      setIndexError({
        refurbishError: refurbishList?.map((i, idx) => (!i?.refurbish?.id ? idx : null)),
        valueError: refurbishList?.map((i, idx) => (i?.value === 0 ? idx : null))
      });
      valueError && toast.error('O valor deve ser maior que zero.');
      refurbishErro && toast.error('Selecione projeto em todas as linhas.');
      return;
    }

    changeApportionment(refurbishList, refurbishList?.length <= 1);
  };

  const handleCancel = () => {
    if (!touched) {
      onClose();
    }
    setShowConfirmModal(true);
  };

  useEffect(() => {
    if (forceRender) setForceRender(false);
  }, [forceRender]);

  return loading ? null : (
    <Drawer
      width={800}
      open
      onClose={() => handleCancel()}
      extraContentOnRight
      headerExtraContent={() => <HowDoesItWorksButton id="howFinancialDistributionWorks" />}
      title="Rateio por projeto"
      padding={`${spaces.space3} ${spaces.space2}`}
      footer={
        <Div $fullWidth justify="space-between">
          <Button text onClick={() => handleCancel()}>
            Voltar
          </Button>
          {!hasInstallmentPaid ? (
            <Div gap={spaces.space1}>
              <Button
                id="delete-payment"
                text
                style={{ color: colors.red600 }}
                onClick={() => changeApportionment(refurbishList, true)}
              >
                Desabilitar rateio
              </Button>
              <Button type="primary" onClick={handleConfirm}>
                Confirmar
              </Button>
            </Div>
          ) : null}
        </Div>
      }
    >
      <Div direction="column" $fullHeight $fullWidth>
        <Div direction="column" align="start" gap={spaces.space3} $fullWidth>
          {hasInstallmentPaid ? (
            <WarningBar
              warning="A edição dos itens está desabilitada porque alguma parcela já foi paga."
              width="100%"
              type="light"
            />
          ) : (
            <Paragraph weight={fonts.weight500}>Divida o valor para mais de um projeto ou oportunidade.</Paragraph>
          )}

          <Div direction="column" gap={spaces.space1} align="start" $fullWidth>
            <TableOrListV2
              list={refurbishList}
              delayLoad={forceRender}
              columns={_columns}
              $customHover="inherit"
              rowSelection={false}
              refreshColumns
              scroll={{ y: tableDynamicHeight({ list: refurbishList, lineHeight: 50 }) }}
              withCommonRow
            />
            <Tooltip title={totalItems === refurbishList?.length ? 'Você já adicionou todos os projetos' : ''}>
              <Div height={spaces.space4} $fullWidth>
                {!hasInstallmentPaid ? (
                  <Button
                    type="primary"
                    text
                    onClick={() => handleAdd()}
                    disabled={totalItems === refurbishList?.length}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Projeto
                  </Button>
                ) : null}
              </Div>
            </Tooltip>
          </Div>
        </Div>

        <Div gap={spaces.space2} $fullWidth justify="end">
          <Div direction="column" align="baseline">
            <Paragraph type="small">Total rateado</Paragraph>
            <Div gap={spaces.space1}>
              <Paragraph>{`(${formatCurrency(listPercentage)}%)`}</Paragraph>
              <Paragraph weight={fonts.weight700}>
                {formatCurrency(totalValueList, {
                  currencySymbol: 'R$'
                })}
              </Paragraph>
            </Div>
          </Div>
          <Div direction="column" align="baseline">
            <Div gap={spaces.space1}>
              <Paragraph type="small">Restante do rateio</Paragraph>
              <TooltipIcon
                color={colors.primary500}
                icon={faInfoCircle}
                iconProps={{ fontSize: fonts.sizeSm }}
                tooltipProps={{ placement: 'topRight' }}
                text="Restante não rateado do valor total do lançamento. Para salvar, este valor precisa estar zerado."
              />
            </Div>

            <Div gap={spaces.space1}>
              <Paragraph>{`(${formatCurrency(100 - Number(listPercentage.toFixed(2)))}%)`}</Paragraph>
              <Paragraph weight={fonts.weight700}>
                {formatCurrency(total - Number(totalValueList.toFixed(2)), {
                  currencySymbol: 'R$'
                })}
              </Paragraph>
            </Div>
          </Div>
        </Div>
      </Div>
      {showConfirmModal ? (
        <ConfirmModal
          title="Alterações não salvas"
          alertInfo="Essa operação não pode ser desfeita."
          description="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar"
          showDiscardButton
          onSubmit={() => handleConfirm()}
          onClose={(_, opts) => {
            setShowConfirmModal(false);
            if (opts?.discard) onClose();
          }}
        />
      ) : null}
    </Drawer>
  );
};

ApportionmentDrawer.propTypes = {
  originalRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  total: PropTypes.number,
  changeApportionment: PropTypes.func,
  apportionmentList: PropTypes.instanceOf(Array),
  hasInstallmentPaid: PropTypes.bool
};

export default ApportionmentDrawer;
