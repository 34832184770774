import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FooterContainer, ForwardButton, StepFooter } from '../Steps/Steps.styled';
import Button from './Button';
import { colors, spaces } from '../../styles/style';

const NavigationButton = ({ canSubmit, onReturn, onSubmit, isFirstStep, ...props }) => {
  return (
    <FooterContainer>
      <StepFooter $flexEnd={isFirstStep} {...props}>
        {!isFirstStep && (
          <Button id="onboarding-back" className="back" text size={4} onClick={onReturn}>
            Voltar
          </Button>
        )}
        <ForwardButton>
          <Button
            id="onboarding-forward"
            type="primary"
            size={4}
            onClick={onSubmit}
            disabled={!canSubmit}
            padding={`0 ${spaces.space4}`}
          >
            Avançar
          </Button>
          <FontAwesomeIcon id="onboard-info" icon={faQuestionCircle} color={colors.primary600} />
        </ForwardButton>
      </StepFooter>
    </FooterContainer>
  );
};

NavigationButton.propTypes = {
  canSubmit: PropTypes.bool,
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func,
  isFirstStep: PropTypes.bool
};

NavigationButton.defaultProps = {
  canSubmit: true
};

export default NavigationButton;
