import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

import { Space, Tooltip } from 'antd';
import Button from '../Button/Button';
import FlagTag from '../Tag/FlagTag';
import { Paragraph, Title } from '../Text/Text';
import { Div, spaces } from '../../styles/style';

const ItemModalHeader = ({ title, subtitle, linkedItem, onEditItem, showLinkInfo, isSinapi }) => {
  let linkText = 'DESVINCULADO COM A BIBLIOTECA';
  let linkTooltipText = 'Este item não está unificado com um item da sua biblioteca.';

  if (isSinapi) {
    linkText = 'SINAPI';
    linkTooltipText = null;
  } else if (linkedItem) {
    linkText = 'VINCULADO COM A BIBLIOTECA';
    linkTooltipText = 'Este item está unificado com um item da sua biblioteca.';
  }

  return (
    <Div align="stretch" direction="column" gap={spaces.space1}>
      <Div justify="space-between">
        <Space size={8}>
          <Title>{title}</Title>
          {onEditItem && !isSinapi && (
            <Tooltip title="Editar informações do item na biblioteca.">
              <Button id="edit-drawer-linked-item" type="light" onClick={onEditItem}>
                <FontAwesomeIcon icon={faPenToSquare} />
                Editar
              </Button>
            </Tooltip>
          )}
        </Space>
        {showLinkInfo && (
          <Tooltip title={linkTooltipText}>
            <span>
              <FlagTag value={linkText} className={linkedItem || isSinapi ? 'success' : 'default'} />
            </span>
          </Tooltip>
        )}
      </Div>
      {subtitle && <Paragraph>{subtitle}</Paragraph>}
    </Div>
  );
};

ItemModalHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  subtitle: PropTypes.string,
  linkedItem: PropTypes.bool,
  onEditItem: PropTypes.func,
  showLinkInfo: PropTypes.bool,
  isSinapi: PropTypes.bool
};

export default ItemModalHeader;
