import styled from 'styled-components';
import { colors, spaces, fonts } from '../../styles/style';

export const NotesListHeader = styled.div`
  padding: ${spaces.space1} ${spaces.space3};
  border-bottom: solid 1px ${colors.neutral100};
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 18px;
    font-family: ${fonts.fontFamily};
  }
`;

export const HeaderFlex = styled.div`
  display: flex;
  align-items: center;
`;
