import React from 'react';
import { Tooltip } from 'antd';
import { faPaperclip, faFileXml } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import FileUpload from '../File/FileUpload';
import TooltipIcon from '../Tooltip/TooltipIcon';

import { colors, spaces } from '../../styles/style';
import { StyledItemMenu, StyledMenu } from './DropdownV2.styled';
import Dropdown from './DropdownV2';
import { downloadFile } from '../../lib/helpers/helper';

const ButtonAttachmentDropDown = ({ file, isView, onFileChange, type, cannotUpdate }) => {
  const config = {
    xml: {
      icon: faFileXml,
      mainText: 'XML',
      seeText: 'Baixar XML',
      updateText: 'Alterar XML',
      addText: 'Adicionar XML',
      downloadFile: true,
      accept: '.xml'
    },
    paperclip: {
      icon: faPaperclip,
      mainText: 'Anexo',
      seeText: 'Ver Anexo',
      updateText: 'Alterar anexo',
      addText: 'Adicionar anexo'
    }
  };

  const handleViewOrDownloadFile = () => {
    if (config[type].downloadFile) {
      downloadFile(file.fullpath, file.filename);
    } else {
      window.open(file.fullpath, '_blank');
    }
  };
  const menu = (
    <StyledMenu $alignLeftOptions={false}>
      <StyledItemMenu>
        <Button text onClick={handleViewOrDownloadFile} id="new-payment">
          {config[type].seeText}
        </Button>
      </StyledItemMenu>
      <StyledItemMenu>
        <FileUpload
          id="add-attachment"
          buttonProps={{ padding: 0, text: true, $noIcon: true }}
          multiple={false}
          onChange={_file => onFileChange(_file)}
          showGallery={false}
          listType={null}
          type="button"
          noMobileMargin
          text={config[type].updateText}
          accept={config[type].accept}
        />
      </StyledItemMenu>
    </StyledMenu>
  );

  if (!file && !isView) {
    return (
      <Tooltip title={config[type].addText}>
        <FileUpload
          id="add-attachment"
          buttonProps={{ padding: 0, text: true }}
          multiple={false}
          onChange={_file => onFileChange(_file)}
          showGallery={false}
          listType={null}
          type="button"
          noMobileMargin
          tooltipText={config[type].addText}
          onlyIcon
          icon={config[type].icon}
          iconColor={colors.neutral300}
          accept={config[type].accept}
        />
      </Tooltip>
    );
  }

  if (file && (isView || cannotUpdate)) {
    return (
      <TooltipIcon
        text={config[type].seeText}
        iconColor={colors.primary500}
        icon={config[type].icon}
        style={{ height: spaces.space2, width: spaces.space2, padding: spaces.space0 }}
        id="invoice-attachment"
        onClick={handleViewOrDownloadFile}
      />
    );
  }

  if (!file && isView) return <></>;
  return (
    <Dropdown slim trigger={['click']} menu={menu}>
      <TooltipIcon
        text={config[type].mainText}
        iconColor={colors.primary500}
        icon={config[type].icon}
        style={{ height: spaces.space2, width: spaces.space2, padding: spaces.space0 }}
        id={`${type}-attachment-dropdown`}
        onClick={() => {}}
      />
    </Dropdown>
  );
};

ButtonAttachmentDropDown.propTypes = {
  onFileChange: PropTypes.func,
  file: PropTypes.instanceOf(Object),
  isView: PropTypes.bool,
  type: PropTypes.string,
  cannotUpdate: PropTypes.bool
};

export default ButtonAttachmentDropDown;
