import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DndContext = ({ children }) => {
  return (
    <DndProvider backend={HTML5Backend} key={1}>
      {children}
    </DndProvider>
  );
};

DndContext.propTypes = {
  children: PropTypes.instanceOf(Object)
};

export default DndContext;
