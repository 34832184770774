import { useCallback, useRef, useState } from 'react';

const useLongPress = (onLongPress, onClick, { shouldPreventDefault = true, delay = 300, distance = 5 } = {}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);
  const isScrolling = useRef();
  const timeout = useRef();
  const target = useRef();

  const isTouchEvent = event => {
    return 'touches' in event;
  };

  const preventDefault = event => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  };

  const move = useCallback(
    (value, event) => {
      const _mousePosition = event.touches[0].clientY;
      if (mousePosition !== 0 && Math.abs(mousePosition - _mousePosition) > distance) {
        isScrolling.current = true;
      }
    },
    [mousePosition]
  );

  const start = useCallback(
    (value, event) => {
      setMousePosition(event.touches[0].clientY);
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        if (!isScrolling.current) {
          onLongPress(event, value);
          setLongPressTriggered(true);
        }
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault, isScrolling.current]
  );

  const clear = useCallback(
    (value, event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && !isScrolling.current && onClick(event, value);
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
      isScrolling.current = false;
    },
    [shouldPreventDefault, onClick, longPressTriggered, isScrolling.current]
  );

  return value => ({
    onMouseDown: e => start(value, e),
    onTouchStart: e => start(value, e),
    onMouseUp: e => clear(value, e),
    onMouseLeave: e => clear(value, e, false),
    onTouchEnd: e => clear(value, e),
    onTouchMove: e => move(value, e)
  });
};

export default useLongPress;
