import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Drawer from './Drawer';
import Form from '../Form/Form';
import { companyCustomerMapping, companyCustomerSchema } from '../../lib/mapping/Form/companyCustomerSchema';
import useCRUD from '../../_Hooks/useCRUD';
import { supplierMapping, supplierSchema } from '../../lib/mapping/Form/supplierSchema';
import CenteredLoader from '../Loader/CenteredLoader';
import ConfirmModal from '../Modal/ConfirmModal';
import { spaces } from '../../styles/style';

const EditOrCreateCustomerOrSupplier = ({ onClose, id, setData, model = 'company-customer' }) => {
  const isCompanyCustomer = model === 'company-customer';
  const name = isCompanyCustomer ? 'Cliente' : 'Fornecedor';
  const action = id ? 'atualizado' : 'criado';
  const [confirmUpdate, setConfirmUpdate] = useState({ open: false });
  const { billingManagerStatus } = useSelector(state => state.setup.enums);

  const { data, loading, handleCreate, handleUpdate } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    ...(model === 'company-customer' && {
      options: {
        include: [
          {
            model: 'billingManager',
            where: {
              notificationDate: { gte: dayjs().format('YYYY-MM-DD') },
              idStatus: billingManagerStatus.pending
            },
            limit: 1
          }
        ]
      }
    }),
    immediatelyLoadData: !!id
  });

  const handleSubmit = submitData => {
    const updateOrCreate = id
      ? handleUpdate({
          updatePathOptions: `/${id}`,
          values: submitData,
          refresh: false
        })
      : handleCreate({ values: submitData, refresh: false });
    updateOrCreate.then(resp => {
      if (resp?.error) return;
      setData && setData(resp);
      toast.success(`${name} ${action} com sucesso`);
      onClose(true);
    });
  };

  const handleConfirm = submitData => {
    const { email, phone } = submitData || {};
    if (id && data?.billingManagers?.length > 0 && (data?.phone !== phone || data?.email !== email)) {
      setConfirmUpdate({ open: true, data: submitData });
    } else {
      handleSubmit(submitData);
    }
  };

  return (
    <>
      <Drawer
        title={`${id ? 'Editar' : 'Cadastrar'} ${isCompanyCustomer ? 'cliente' : 'fornecedor'}`}
        subtitle="Preencha todas as informações corretamente"
        subtitleStyle={{ margin: `${spaces.space2} 0` }}
        open
        formId="customerForm"
        onClose={() => onClose()}
        loading={loading}
        padding={`${spaces.space1} ${spaces.space3} ${spaces.space7} ${spaces.space3}`}
      >
        {loading ? (
          <CenteredLoader />
        ) : (
          <Form
            id="customerForm"
            displayButtons={false}
            onSubmit={handleConfirm}
            mapping={isCompanyCustomer ? companyCustomerMapping : supplierMapping}
            schema={isCompanyCustomer ? companyCustomerSchema : supplierSchema}
            data={data || { isActive: true, observation: '' }}
          />
        )}
      </Drawer>
      {confirmUpdate?.open && (
        <ConfirmModal
          open={confirmUpdate?.open}
          text="O cliente possui lembretes de cobranças em andamento que serão afetados."
          onSubmit={() => handleSubmit(confirmUpdate?.data)}
          onClose={() => setConfirmUpdate({ open: false })}
        />
      )}
    </>
  );
};

EditOrCreateCustomerOrSupplier.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.number,
  setData: PropTypes.func,
  model: PropTypes.string
};

export default EditOrCreateCustomerOrSupplier;
