import * as Yup from 'yup';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { idReference as idReferenceYup, name, code } from '../schema';
import formatNumber from '../../helpers/formatNumber';

import formatCurrency from '../../helpers/formatCurrency';
import CheckboxAutoCode from '../../../components/Checkbox/CheckboxAutoCode';
import { colors, spaces, Div } from '../../../styles/style';
import formatBdi from '../../helpers/formatBdi';
import ItemModalHeader from '../../../components/Header/ItemModalHeader';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';

const productMapping = {
  brand: {
    name: 'Qual a marca?',
    type: 'text',
    placeholder: `Ex: "Deca"`
  },
  images: {
    name: 'Imagens',
    tooltipText: 'Tamanho máximo 50mb',
    type: 'file',
    xs: 24,
    textArray: true,
    multiple: true,
    text: 'Enviar'
  }
};

const laborMapping = {
  description: {
    name: 'Qual descrição?',
    type: 'text',
    xs: 24,
    placeholder: `Ex: "Orçamento de Marcenaria de todos os cômodos"`
  }
};

const _subtitle = isEditing => (
  <Paragraph>
    <Subtitle color={colors.primary500}>Atenção:</Subtitle>{' '}
    {isEditing
      ? `Ao editar essas informações, todas as aparições desse item nos seus orçamentos
        também serão alteradas.`
      : `Essas informações são unificadas em todas as aparições desse item nos seus orçamentos. `}
  </Paragraph>
);

const commonLaborProductMapping = ({ data, checkedValue, setCheckedValue }) => {
  const { type } = data;
  const isEditing = !!data?.id;

  return {
    code: {
      name: 'Código',
      type: 'text',
      placeholder: 'Ex: 123456',
      md: isEditing ? 12 : 6,
      disabled: isEditing ? false : checkedValue,
      xs: 12
    },
    ...(!isEditing
      ? {
          autoCode: {
            type: 'custom',
            md: 6,
            // eslint-disable-next-line react/prop-types
            Component: ({ setField, value }) => (
              <CheckboxAutoCode setField={setField} value={value} setCheckedValue={setCheckedValue} paddingTop="16px" />
            )
          }
        }
      : {}),
    name: {
      name: 'Item*',
      type: 'text',
      placeholder: `Informe o nome`
    },
    ...(type !== 3
      ? {
          idSupplier: {
            name: 'Qual a fornecedor?',
            type: 'select',
            placeholder: 'Nome do Fornecedor',
            model: 'supplier',
            modelOptions: { onlyMine: true, order: [['name']] },
            allowCustomOptions: true,
            allowClear: true
          }
        }
      : {}),
    idRefurbishGroup: {
      name: 'Qual o grupo?',
      type: 'select',
      placeholder: 'Selecione o grupo',
      model: 'refurbishGroup',
      allowCustomOptions: true,
      allowClear: true,
      modelOptions: {
        onlyMine: true,
        order: [['name', 'asc']]
      }
    },
    idCostCenter: {
      name: 'Qual a categoria?',
      type: 'select',
      placeholder: 'Selecione a categoria',
      model: 'costCenter',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true
    },
    price: {
      name: 'Valor',
      type: 'currency',
      placeholder: 'R$ 0,00',
      defaultValue: '0,00',
      maskOptions: { allowNegative: true },
      parseFunc: val => formatNumber(val)
    },
    link: {
      name: 'Qual o link do item?',
      type: 'text'
    },
    description: {
      name: 'Descrição',
      type: 'textarea',
      placeholder: 'Informe a descrição (opcional)',
      md: 24
    },
    ...(type === 1 ? productMapping : laborMapping)
  };
};

const _compositionName = ({ readonly }) => ({
  name: {
    name: 'Nome do item',
    type: readonly ? 'string' : 'text',
    placeholder: `Ex: Composição de ferramentas`,
    md: 24,
    id: 'composition-name-field'
  }
});

const _compositionUnit = ({ readonly, idCompany }) => ({
  idUnit: {
    name: 'Unidade',
    type: readonly ? 'string' : 'select',
    displayStringAs: readonly ? 'name' : undefined,
    placeholder: 'Selecione',
    model: 'unit',
    propertyAlias: 'units',
    modelOptions: { onlyMine: true, where: { idCompany }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 6,
    xs: 12
  }
});

const compositionMapping = ({ data, checkedValue, setCheckedValue }) => {
  const { isMobile, isCustomer, isEditing, onlyView, linkedItem, isLibrary, headerProps } = data;
  const readOnly = isMobile || isCustomer || onlyView || linkedItem;
  let subtitle;

  if (isLibrary) {
    subtitle = _subtitle(isEditing);
  }

  return {
    itemHeader: {
      awaysShow: true,
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => (
        <ItemModalHeader
          title="Informações da composição"
          subtitle={subtitle}
          linkedItem={linkedItem}
          isSinapi={!!data?.externalBaseCode}
          {...headerProps}
        />
      )
    },
    ..._compositionName({ ...data, readonly: readOnly }),
    code: {
      name: 'Código',
      type: readOnly ? 'string' : 'text',
      placeholder: `Ex: 0123456`,
      md: 6,
      disabled: isEditing ? false : readOnly || checkedValue,
      xs: 12
    },
    ...(!isEditing
      ? {
          autoCode: {
            type: 'custom',
            xs: 12,
            md: 6,
            // eslint-disable-next-line react/prop-types
            Component: ({ setField, value }) => (
              <CheckboxAutoCode
                setField={setField}
                value={value}
                setCheckedValue={setCheckedValue}
                paddingTop={spaces.space4}
              />
            )
          }
        }
      : {}),
    ..._compositionUnit({ ...data, readonly: readOnly }),
    idClass: {
      name: 'Classe',
      id: 'composition-class-field',
      type: readOnly ? 'string' : 'select',
      displayStringAs: readOnly ? 'name' : undefined,
      placeholder: 'Selecione',
      model: 'itemClass',
      propertyAlias: 'class',
      allowCustomOptions: true,
      allowClear: true,
      allowCreate: false,
      md: !isEditing ? 6 : 12,
      extraPropsOnOptions: ['code']
    },
    ...(linkedItem?.externalBaseCode && {
      state: {
        name: 'Estado',
        id: 'composition-sinapi-state-field',
        type: 'string',
        md: 6
      },
      monthReference: {
        name: 'Data',
        id: 'composition-sinapi-month-field',
        type: 'string',
        md: 6,
        format: val => (val ? dayjs(val).format('MM-YYYY') : null)
      },
      socialCharges: {
        name: 'Desoneração',
        id: 'composition-sinapi-with-social-charges-field',
        type: 'string',
        md: 6
      },
      originPrice: {
        name: 'Origem do preço',
        id: 'composition-sinapi-origin-price-field',
        type: 'string',
        md: 6
      }
    })
  };
};

const budgetCompositionMapping = ({ data }) => {
  const {
    isMobile,
    isCustomer,
    isEditing,
    onlyView,
    isTemplate,
    isLibrary,
    itemTypeEnum,
    idReference,
    parentIsComposition
  } = data;
  const readOnly = isMobile || isCustomer || onlyView;
  const model = isTemplate ? 'templateItem' : 'refurbishItems';
  const showLevel = !isLibrary && !parentIsComposition && !isCustomer;
  let subtitle;

  if (isLibrary) {
    subtitle = isEditing
      ? 'Ao editar essas informações as aparições já existentes desse item em seus orçamentos, não serão atualizadas.'
      : `Essas informações sempre serão adicionadas em seus orçamentos,
      mas cada aparição poderá ser editada individualmente.`;
  }

  return {
    itemHeader: {
      awaysShow: true,
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <ItemModalHeader title="Informações do orçamento" subtitle={subtitle} />
    },
    ...(showLevel
      ? {
          idParent: {
            name: 'Nível/Subnível',
            awaysShow: true,
            type: readOnly ? 'string' : 'treeSelect',
            displayStringAs: 'name',
            placeholder: 'Nível',
            model,
            modelOptions: {
              attributes: ['name', 'id', 'type'],
              where: {
                isActive: true,
                type: itemTypeEnum?.parent,
                [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference
              },
              include: [
                {
                  model,
                  as: 'children',
                  attributes: ['name', 'id', 'type'],
                  required: false,
                  where: {
                    isActive: true,
                    type: itemTypeEnum?.parent
                  }
                }
              ]
            },
            md: 12,
            disabled: readOnly
          }
        }
      : {}),
    idSupplier: {
      dataIndex: 'idSupplier',
      name: 'Fornecedor',
      type: readOnly ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Nome do fornecedor',
      model: 'supplier',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true,
      md: 12,
      disabled: readOnly
    },
    ...(!isLibrary && {
      quantity: {
        dataIndex: 'quantity',
        awaysShow: isCustomer,
        name: 'Quantidade prevista',
        type: readOnly ? 'string' : 'text',
        placeholder: '1',
        md: showLevel ? 8 : 12,
        withoutArrows: true,
        disabled: readOnly
      }
    }),
    idRefurbishGroup: {
      dataIndex: 'idRefurbishGroup',
      name: 'Grupo',
      type: readOnly ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Grupo',
      model: 'refurbishGroup',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true,
      md: isLibrary || !showLevel ? 12 : 8,
      disabled: readOnly
    },
    idCostCenter: {
      dataIndex: 'idCostCenter',
      name: 'Categoria',
      type: readOnly ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Categoria',
      model: 'costCenter',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true,
      md: isLibrary || !showLevel ? 12 : 8,
      disabled: readOnly
    },
    description: {
      name: 'Descrição',
      dataIndex: 'description',
      awaysShow: true,
      type: readOnly ? 'string' : 'textarea',
      placeholder: 'Informe a descrição (opcional)',
      md: 24,
      disabled: readOnly
    }
  };
};

const itemMapping = ({ data, checkedValue, setCheckedValue }) => {
  if (!data) return null;

  const { itemTypeEnum, type } = data;
  const defaultType = itemTypeEnum.labor;

  return {
    [itemTypeEnum.product]: {
      ...commonLaborProductMapping({ data, checkedValue, setCheckedValue }),
      ...productMapping
    },
    [itemTypeEnum.labor]: { ...commonLaborProductMapping({ data, checkedValue, setCheckedValue }), ...laborMapping },
    [itemTypeEnum.composition]: compositionMapping({ data, checkedValue, setCheckedValue })
  }[type || defaultType];
};

const linkedItemMapping = ({
  id,
  isLibrary,
  type,
  isCustomer,
  refurbishItemTypeEnum,
  columnsToShow,
  linkedItem,
  onSeeMoreClick,
  showAllLaborFields,
  headerProps,
  libraryProps: { checkedValue, setCheckedValue },
  data,
  isMobile
}) => {
  const isProduct = type === refurbishItemTypeEnum?.product;
  const isEditing = Boolean(id);
  const disabled = isCustomer || linkedItem;
  const isLinkedToSinapi = !data?.idItem && data?.externalBaseCode;
  const hasDimensions = Number(data?.height) || Number(data?.width) || Number(data?.length) || Number(data?.weight);

  const hasImage = data?.images?.length;

  const sectionTitle = {
    [refurbishItemTypeEnum.labor]: 'Informações do serviço',
    [refurbishItemTypeEnum.product]: 'Informações do produto',
    [refurbishItemTypeEnum.parent]: 'Informações do agrupamento'
  };
  let subtitle;

  if (linkedItem) {
    subtitle = 'Essas informações são unificadas com todas as ocorrências desse item. ';
  }

  if (isLibrary) {
    subtitle = _subtitle(isEditing);
  }

  const autoCodeField =
    isLibrary && !isEditing
      ? {
          ...(!isMobile && {
            autoCodeSpace: {
              type: 'custom',
              md: 12,
              Component: () => <Div />
            }
          }),
          autoCode: {
            type: 'custom',
            md: 6,
            style: { padding: '0' },
            // eslint-disable-next-line react/prop-types
            Component: () => (
              <CheckboxAutoCode
                value={checkedValue}
                setCheckedValue={setCheckedValue}
                paddingTop="0"
                containerMargin={`0 0 0 ${spaces.space2}`}
                noMargin
              />
            )
          }
        }
      : {};

  const allFields = {
    itemHeader: {
      awaysShow: true,
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => (
        <ItemModalHeader
          title={sectionTitle[type]}
          subtitle={subtitle}
          linkedItem={linkedItem}
          isLibrary={isLibrary}
          isSinapi={!!isLinkedToSinapi}
          {...headerProps}
        />
      )
    },
    name: {
      dataIndex: 'name',
      awaysShow: true,
      name: 'Nome',
      type: disabled ? 'string' : 'text',
      placeholder: `Informe o nome`,
      md: 18,
      disabled
    },
    code: {
      dataIndex: 'code',
      name: 'Código',
      type: disabled ? 'string' : 'text',
      placeholder: 'ABC123',
      md: 6,
      disabled: isCustomer || (!isEditing && isLibrary && checkedValue)
    },
    ...(isMobile && autoCodeField),
    idUnit: {
      dataIndex: 'idUnit',
      name: 'Unidade',
      type: disabled ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Unidade',
      model: 'unit',
      modelOptions: { onlyMine: true, order: [['name']] },
      propertyAlias: 'units',
      allowCustomOptions: true,
      allowClear: true,
      md: 6,
      disabled
    },
    ...(!isMobile && autoCodeField),
    ...(isProduct && !isLinkedToSinapi && (!disabled || hasImage)
      ? {
          images: {
            dataIndex: 'image',
            name: 'Imagens',
            tooltipText: disabled ? null : 'Tamanho máximo 50mb',
            type: 'file',
            md: 24,
            textArray: true,
            text: 'Adicionar imagem',
            galleryType: 'new',
            buttonProps: { type: 'primary', $noIcon: true, ...(disabled && { style: { display: 'none' } }) },
            galleryProps: { disabled },
            buttonObrigatory: true,
            listType: null,
            fullWidth: true,
            noPadding: true,
            onlyButtonOnEmpty: true,
            disabled,
            displayLabelOnlyWhenNotEmpty: true
          }
        }
      : {}),
    ...(isProduct && {
      ...(showAllLaborFields || ((disabled || hasDimensions) && !isLinkedToSinapi)
        ? {
            ...(isLinkedToSinapi && {
              subtype: {
                name: 'Subtipo',
                id: 'composition-sinapi-subtype-field',
                type: 'string',
                md: 6
              }
            }),
            ...(!isCustomer &&
              isLinkedToSinapi && {
                customSpace: {
                  awaysShow: true,
                  type: 'custom',
                  xs: 12,
                  md: 12,
                  Component: () => <div />
                }
              }),
            ...(isLinkedToSinapi && {
              state: {
                name: 'Estado',
                id: 'composition-sinapi-state-field',
                type: 'string',
                md: 6
              },
              monthReference: {
                name: 'Data',
                id: 'composition-sinapi-month-field',
                type: 'string',
                md: 6,
                format: val => (val ? dayjs(val).format('MM-YYYY') : null)
              },
              socialCharges: {
                name: 'Desoneração',
                id: 'composition-sinapi-with-social-charges-field',
                type: 'string',
                md: 6
              },
              originPrice: {
                name: 'Origem do preço',
                id: 'composition-sinapi-origin-price-field',
                type: 'string',
                md: 6
              }
            }),
            ...(disabled && !hasDimensions && !isLinkedToSinapi
              ? {}
              : {
                  height: {
                    dataIndex: 'height',
                    awaysShow: isCustomer,
                    name: 'Altura',
                    type: disabled ? 'string' : 'text',
                    placeholder: '0',
                    md: 6,
                    suffix: 'cm',
                    withoutArrows: true,
                    disabled
                  },
                  width: {
                    dataIndex: 'width',
                    awaysShow: isCustomer,
                    name: 'Largura',
                    type: disabled ? 'string' : 'text',
                    placeholder: '0',
                    suffix: 'cm',
                    withoutArrows: true,
                    md: 6,
                    disabled
                  },
                  length: {
                    dataIndex: 'length',
                    awaysShow: isCustomer,
                    name: 'Comprimento',
                    type: disabled ? 'string' : 'text',
                    placeholder: '0',
                    md: 6,
                    suffix: 'cm',
                    withoutArrows: true,
                    disabled
                  },
                  weight: {
                    dataIndex: 'weight',
                    awaysShow: isCustomer,
                    name: 'Peso',
                    type: disabled ? 'string' : 'text',
                    placeholder: '0',
                    md: 6,
                    suffix: 'kg',
                    withoutArrows: true,
                    disabled
                  }
                })
          }
        : {
            seeMoreButton: {
              awaysShow: true,
              type: 'custom',
              xs: 24,
              md: 24,
              Component: () => (
                <Button
                  id="see-more-product-modal"
                  {...(disabled ? { type: 'light' } : { type: 'primary', text: true })}
                  onClick={onSeeMoreClick}
                >
                  {!disabled && <FontAwesomeIcon icon={faPlus} />}
                  {disabled ? 'Ver mais' : 'Adicionar dimensões'}
                </Button>
              )
            }
          })
    })
  };

  if (isCustomer)
    return Object.entries(allFields).reduce((acc, [key, value]) => {
      if (columnsToShow?.[value?.dataIndex] === true || value?.awaysShow) {
        acc[key] = value;
      }
      return acc;
    }, {});

  return allFields;
};

const newBudgetItemMapping = ({
  id,
  type,
  isCustomer,
  refurbishItemTypeEnum,
  isTemplate,
  idReference,
  columnsToShow,
  parentType,
  bdiLabel,
  isLibrary,
  linkedItem
}) => {
  const isProduct = type === refurbishItemTypeEnum?.product;
  const model = isTemplate ? 'templateItem' : 'refurbishItems';
  const showLevel = !isLibrary && parentType !== refurbishItemTypeEnum.composition && !isCustomer;

  let subtitle;
  if (linkedItem) {
    subtitle = 'Essas informações não são unificadas com a sua biblioteca ou outros orçamentos. ';
  }

  if (isLibrary) {
    subtitle = id
      ? 'Ao editar essas informações as aparições já existentes desse item em seus orçamentos, não serão atualizadas.'
      : `Essas informações sempre serão adicionadas em seus orçamentos,
      mas cada aparição poderá ser editada individualmente.`;
  }

  const allFields = {
    itemHeader: {
      awaysShow: true,
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <ItemModalHeader title="Informações do orçamento" subtitle={subtitle} />
    },
    ...(showLevel
      ? {
          idParent: {
            name: 'Nível/Subnível',
            awaysShow: true,
            type: isCustomer ? 'string' : 'treeSelect',
            displayStringAs: 'name',
            placeholder: 'Nível',
            model,
            modelOptions: {
              attributes: ['name', 'id', 'type'],
              where: {
                isActive: true,
                type: refurbishItemTypeEnum?.parent,
                idParent: null,
                [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference
              },
              include: [
                {
                  model,
                  as: 'children',
                  attributes: ['name', 'id', 'type'],
                  required: false,
                  where: {
                    isActive: true,
                    type: refurbishItemTypeEnum?.parent
                  }
                }
              ],
              order: [['order']]
            },
            md: isProduct ? 8 : 12,
            disabled: isCustomer
          }
        }
      : {}),
    idSupplier: {
      dataIndex: 'idSupplier',
      name: 'Fornecedor',
      type: isCustomer ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Nome do fornecedor',
      model: 'supplier',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true,
      md: !isLibrary && isProduct ? 8 : 12,
      disabled: isCustomer
    },
    ...(isProduct
      ? {
          brand: {
            dataIndex: 'brand',
            awaysShow: true,
            name: 'Marca',
            type: isCustomer ? 'string' : 'text',
            placeholder: 'Marca',
            md: isLibrary ? 12 : 8,
            disabled: isCustomer
          }
        }
      : {}),
    ...(!isLibrary && {
      quantity: {
        dataIndex: 'quantity',
        awaysShow: isCustomer,
        name: 'Quantidade prevista',
        type: isCustomer ? 'string' : 'text',
        placeholder: '1',
        md: 8,
        withoutArrows: true,
        disabled: isCustomer
      }
    }),
    idRefurbishGroup: {
      dataIndex: 'idRefurbishGroup',
      name: 'Grupo',
      type: isCustomer ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Grupo',
      model: 'refurbishGroup',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true,
      md: isLibrary && !isProduct ? 12 : 8,
      disabled: isCustomer
    },
    idCostCenter: {
      dataIndex: 'idCostCenter',
      name: 'Categoria',
      type: isCustomer ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Categoria',
      model: 'costCenter',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      allowClear: true,
      md: isLibrary && !isProduct ? 12 : 8,
      disabled: isCustomer
    },
    ...(!isLibrary && {
      bdi: {
        dataIndex: 'bdi',
        name: bdiLabel || 'BDI',
        type: isCustomer ? 'string' : 'currency',
        mask: 'number',
        placeholder: '0,00 %',
        maskOptions: { prefix: '', suffix: ' %', decimalLimit: 2 },
        format: val => (typeof val === 'number' ? formatBdi(val) : val),
        parseFunc: val => formatBdi(val),
        md: 8,
        disabled: isCustomer
      }
    }),
    price: {
      dataIndex: 'price',
      name: 'Custo unitário',
      type: isCustomer ? 'string' : 'currency',
      prefix: 'R$',
      placeholder: 'R$ 0,00',
      defaultValue: '0,00',
      maskOptions: { allowNegative: true },
      parseFunc: val => formatNumber(val),
      md: isLibrary && !isProduct ? 12 : 8,
      disabled: isCustomer
    },
    ...(!isLibrary && {
      totalPrice: {
        name: 'Preço unitário (Custo unitário x BDI)',
        type: 'custom',
        prefix: 'R$',
        placeholder: 'R$ 0,00',
        defaultValue: '0,00',
        maskOptions: { allowNegative: true },
        md: 8,
        // eslint-disable-next-line react/prop-types
        Component: ({ formState: { values } = {} }) => (
          <p>
            {formatCurrency((1 + formatNumber(values?.bdi || 0) / 100) * formatNumber(values?.price || 0), {
              currencySymbol: 'R$'
            })}
          </p>
        )
      }
    }),
    link: {
      dataIndex: 'link',
      awaysShow: true,
      name: 'Link',
      type: isCustomer ? 'string' : 'text',
      placeholder: `Informe o link do ${isProduct ? 'produto' : 'serviço'}`,
      md: 24,
      disabled: isCustomer
    },
    description: {
      name: 'Descrição',
      dataIndex: 'description',
      awaysShow: true,
      type: isCustomer ? 'string' : 'textarea',
      placeholder: 'Informe a descrição (opcional)',
      md: 24,
      disabled: isCustomer
    }
  };

  if (isCustomer)
    return Object.entries(allFields).reduce((acc, [key, value]) => {
      if (columnsToShow?.[value?.dataIndex] === true || value?.awaysShow) {
        acc[key] = value;
      }
      return acc;
    }, {});

  return allFields;
};

const compositionInlineFieldsMapping = ({ idCompany, refurbishItemTypeEnum }) => ({ quantity, price, type }) => ({
  ...(type === refurbishItemTypeEnum.product
    ? {
        images: {
          name: '',
          type: 'image',
          md: 2,
          style: { paddingTop: '14px', display: 'flex', placeContent: 'center' }
        }
      }
    : {
        icon: {
          type: 'icon',
          icon: faScrewdriverWrench,
          md: 2,
          style: { paddingTop: '26px', display: 'flex', placeContent: 'center', color: colors.neutral300 }
        }
      }),
  code: {
    name: 'Código',
    type: 'text',
    placeholder: `123`,
    md: 3,
    id: 'add-item-code-input'
  },
  name: {
    name: 'Item',
    type: 'text',
    placeholder: `Nome`,
    md: 8,
    id: 'add-item-name-input'
  },
  quantity: {
    name: 'Quantidade',
    type: 'number',
    placeholder: 'Quantidade',
    defaultValue: '1',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val),
    md: 3
  },
  idUnit: {
    name: 'Unidade',
    type: 'select',
    placeholder: 'Un.',
    model: 'unit',
    modelOptions: { onlyMine: true, where: { idCompany }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  price: {
    name: 'Preço unit.',
    type: 'currency',
    placeholder: 'R$ 0,00',
    defaultValue: '0,00',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val),
    md: 3
  },
  total: {
    name: 'Total',
    type: 'custom',
    placeholder: 'R$ 0,00',
    defaultValue: 'R$ 0,00',
    md: 2,
    style: { paddingRight: '4px', paddingLeft: '4px' },
    Component: () => (
      <p>{formatCurrency(formatNumber(quantity || 0) * formatNumber(price || 0), { currencySymbol: 'R$' })}</p>
    )
  }
});

const inlineFieldsMapping = idCompany => ({ type = 1, quantity, price }) => ({
  ...(type === 1
    ? {
        images: {
          name: '',
          type: 'image',
          md: 1,
          style: { paddingTop: '14px', display: 'flex', placeContent: 'center' }
        }
      }
    : {
        icon: {
          type: 'icon',
          icon: faScrewdriverWrench,
          md: 1,
          style: { paddingTop: '26px', display: 'flex', placeContent: 'center', color: colors.neutral300 }
        }
      }),
  name: {
    name: 'Item',
    type: 'text',
    placeholder: `Nome`,
    md: 6,
    id: 'add-item-name-input'
  },
  idSupplier: {
    name: 'Fornecedor',
    type: 'select',
    placeholder: 'Fornecedor',
    model: 'supplier',
    modelOptions: { onlyMine: true, where: { idCompany: idCompany || null }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  idRefurbishGroup: {
    name: 'Grupo',
    type: 'select',
    placeholder: 'Grupo',
    model: 'refurbishGroup',
    modelOptions: { onlyMine: true, where: { idCompany: idCompany || null }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  idCostCenter: {
    name: 'Categoria',
    type: 'select',
    placeholder: 'Categoria',
    model: 'costCenter',
    modelOptions: { onlyMine: true, where: { idCompany: idCompany || null }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  price: {
    name: 'Valor unit.',
    type: 'currency',
    placeholder: 'R$ 0,00',
    defaultValue: '0,00',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val),
    md: 2
  },
  quantity: {
    name: 'Quantidade',
    type: 'number',
    placeholder: 'Quantidade',
    defaultValue: '1',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val),
    md: 2
  },
  idUnit: {
    name: 'Unidade',
    type: 'select',
    placeholder: 'Un.',
    model: 'unit',
    modelOptions: { onlyMine: true, where: { idCompany }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 2
  },
  total: {
    name: 'Total',
    type: 'custom',
    placeholder: 'R$ 0,00',
    defaultValue: 'R$ 0,00',
    md: 2,
    style: { paddingRight: '4px', paddingLeft: '4px' },
    Component: () => (
      <p>{formatCurrency(formatNumber(quantity || 0) * formatNumber(price || 0), { currencySymbol: 'R$' })}</p>
    )
  }
});

const inlineFieldsMappingTemplate = idCompany => props => inlineFieldsMapping(idCompany)(props);
const inlineFieldsMappingSpecification = idCompany => props => inlineFieldsMapping(idCompany)(props);

const itemSchema = schemaParams =>
  Yup.object().shape({
    code,
    type: Yup.string().nullable(),
    name: name.min(3, 'Nome deve ter pelo menos 3 caracteres'),
    idCostCenter: Yup.object().nullable(),
    idRefurbishGroup: Yup.object().nullable(),
    idSupplier: Yup.object().nullable(),
    ...(schemaParams?.hasParent ? { idParent: idReferenceYup } : {}),
    link: Yup.string().nullable()
  });

export {
  itemSchema,
  commonLaborProductMapping,
  itemMapping,
  productMapping,
  laborMapping,
  inlineFieldsMappingTemplate,
  inlineFieldsMappingSpecification,
  linkedItemMapping,
  compositionInlineFieldsMapping,
  newBudgetItemMapping,
  budgetCompositionMapping
};
