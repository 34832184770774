import styled, { css } from 'styled-components';
import { breakpoints, colors, spaces } from '../../styles/style';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  padding: ${props => (props.$isGeneral ? spaces.space2 : 0)};
  @media (max-width: ${breakpoints.tablet}) {
    padding-bottom: ${props => (props.$isGeneral ? spaces.space11 : 0)};
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  gap: ${spaces.space1};
  margin-bottom: ${spaces.space1};

  @media (max-width: ${breakpoints.tablet}) {
    margin: ${spaces.space3} 0 0 0;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space1};
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-collapse: collapse;
  width: 100%;
  border-radius: ${spaces.space0};
  color: ${colors.neutral600};
`;

export const GridItem = styled.span`
  border: 1px solid ${colors.neutral100};
  padding: ${spaces.space1};
  text-align: center;
  color: ${colors.neutral600};
  ${props =>
    props.header &&
    css`
      background-color: ${colors.neutral75};
    `}
  ${props =>
    props.negative &&
    css`
      color: ${colors.red500};
    `};
`;
