import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCopy,
  faEllipsisVertical,
  faEye,
  faLink,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons';
import { Menu } from 'antd';
import { spaces, colors, Div } from '../../styles/style';
import Button from '../Button/Button';
import { StyledMenu } from './DropdownV2.styled';
import DropdownV2 from './DropdownV2';
import { Paragraph } from '../Text/Text';
import CopyLinkButton from '../Button/CopyLinkButton';
import ConfirmModal from '../Modal/ConfirmModal';

const TaskActionDropdown = ({
  task,
  generalTask,
  ganttInstance,
  onTaskClick,
  isParent,
  handleDuplicateTask,
  handleDeleteRefurbishStep,
  onLoadData,
  disabled,
  isGantt
}) => {
  const [open, setOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleMenuItemClick = action => {
    action();
    setOpen(false);
  };

  const handleDelete = () => {
    if (!isParent) {
      ganttInstance.deleteTask(task.id);
    } else {
      handleDeleteRefurbishStep({
        id: task?.id,
        displayToast: 'Operação realizada com sucesso!',
        refresh: false
      }).then(() => {
        if (isGantt) {
          ganttInstance.silent(() => {
            ganttInstance.deleteTask(task.id);
          });
          ganttInstance.render();
        } else {
          onLoadData();
        }
      });
    }
  };

  const viewDetailsItem = {
    id: 'view-details',
    icon: faEye,
    text: 'Ver detalhes',
    onClick: () =>
      handleMenuItemClick(() => {
        onTaskClick({ id: task.id, idReference: task.idRefurbish });
      })
  };

  const viewTimelineItem = {
    id: 'view-timeline',
    icon: faArrowRight,
    text: 'Ver na linha do tempo',
    onClick: () => handleMenuItemClick(() => ganttInstance.showTask(task.id))
  };

  const duplicateTaskItem = {
    id: 'duplicate-task',
    icon: faCopy,
    text: 'Duplicar',
    onClick: () =>
      handleMenuItemClick(() => {
        handleDuplicateTask({
          id: task.id,
          displayToast: 'Operação realizada com sucesso!',
          refresh: false,
          values: {
            ...task
          },
          postPathOptions: '/duplicate'
        }).then(resp => {
          if (resp?.error) return;

          onLoadData({ item: resp, id: resp?.id });
        });
      })
  };

  const copyLinkItem = {
    id: 'copy-link',
    customComponent: (
      <CopyLinkButton
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: `${spaces.space1} ${spaces.space0}`,
          gap: spaces.space1
        }}
        onClose={() => setOpen(false)}
        eventButton="item"
        urlContext="t"
        idData={task?.id}
        text
      >
        <FontAwesomeIcon icon={faLink} color={colors.neutral400} />
        Copiar link
      </CopyLinkButton>
    )
  };

  const deleteTaskItem = {
    id: 'delete-task',
    icon: faTrashCan,
    text: 'Excluir',
    onClick: () => handleMenuItemClick(() => setShowConfirmModal(true)),
    color: colors.red500
  };

  const menuItems = [
    ...(!isParent ? [viewDetailsItem] : []),
    ...(!task.unscheduled && isGantt ? [viewTimelineItem] : []),
    ...(!isParent ? [copyLinkItem] : []),
    ...((!isParent && !generalTask) || (!isGantt && generalTask) ? [duplicateTaskItem] : []),
    ...(!generalTask && !disabled && task.id !== 1 ? [deleteTaskItem] : [])
  ];

  const menu = (
    <StyledMenu $alignLeftOptions>
      {menuItems.map(item => (
        <React.Fragment key={item.id}>
          <Menu.Item key={item.id} style={{ width: '192px' }}>
            {item.customComponent || (
              <Button
                onClick={e => {
                  e?.stopPropagation();
                  item.onClick();
                }}
                id={item.id}
                text
              >
                <Div gap={spaces.space1}>
                  <Div width={spaces.space2} height={spaces.space2} justify="center" align="center">
                    <FontAwesomeIcon icon={item.icon} color={item.color || colors.neutral400} />
                  </Div>
                  <Paragraph type="small" color={item.color}>
                    {item.text}
                  </Paragraph>
                </Div>
              </Button>
            )}
          </Menu.Item>
        </React.Fragment>
      ))}
    </StyledMenu>
  );

  const button = (
    <Div style={{ paddingRight: spaces.space1 }}>
      <Button
        className="show-on-hover-button"
        type={isGantt ? 'outline' : 'text'}
        padding={0}
        width={spaces.space3}
        onClick={e => {
          e.stopPropagation();
          setOpen(prev => !prev);
        }}
        style={{
          height: spaces.space3,
          flexShrink: 0
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          style={{ cursor: 'pointer' }}
          color={colors.primary600}
          className={`go-to-task-${task.id}`}
          size={isGantt ? 'md' : 'lg'}
        />
      </Button>
    </Div>
  );

  return (
    <>
      <DropdownV2
        open={open}
        ghost={false}
        trigger={['click']}
        menu={menu}
        padding={0}
        onOpenChange={() => {
          setOpen(prev => !prev);
        }}
      >
        {button}
      </DropdownV2>

      {showConfirmModal && (
        <ConfirmModal
          onSubmit={handleDelete}
          onClose={(_, e) => {
            e?.stopPropagation();
            setShowConfirmModal(false);
          }}
          text={`Deseja realmente apagar essa ${isParent ? 'etapa' : 'tarefa'}?`}
        />
      )}
    </>
  );
};

TaskActionDropdown.propTypes = {
  task: PropTypes.instanceOf(Object),
  generalTask: PropTypes.bool,
  ganttInstance: PropTypes.instanceOf(Object),
  onTaskClick: PropTypes.func,
  isParent: PropTypes.bool,
  handleDeleteRefurbishStep: PropTypes.func,
  handleDuplicateTask: PropTypes.func,
  onLoadData: PropTypes.func,
  disabled: PropTypes.bool,
  isGantt: PropTypes.bool
};

export default TaskActionDropdown;
