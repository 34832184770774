import React from 'react';

import * as dayjs from 'dayjs';

import MaskedInput from '../../../components/Input/NewMaskedInput';
import formatCurrency from '../../helpers/formatCurrency';
import formatNumber from '../../helpers/formatNumber';
import DatePicker from '../../../components/Datepicker/Datepicker';
import Input from '../../../components/Input/Input';
import { Paragraph } from '../../../components/Text/Text';

const columns = ({ numberOfInstalments, handleChange, isView, installmentHandleBlur }) => {
  return [
    {
      title: 'Parcela',
      width: 67,
      dataIndex: 'number',
      key: 'number',
      className: 'col-grow-1',
      render: (_, row, index) => (
        <Paragraph type="small" id={`installment-${index + 1}`}>{`${index + 1}/${numberOfInstalments}`}</Paragraph>
      )
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      width: 120,
      key: 'price',
      className: 'col-grow-1',
      render: (val, row, index) => {
        return (
          <>
            {!isView ? (
              <MaskedInput
                type="currency"
                id={`installmentValue-${index + 1}`}
                value={formatCurrency(val, {
                  currencySymbol: 'R$ '
                })}
                onChange={e => handleChange(index, 'price', formatNumber(e.target.value))}
                onBlur={installmentHandleBlur}
                maskOptions={{
                  prefix: 'R$',
                  thousandsSeparatorSymbol: '.',
                  allowDecimal: true
                }}
              />
            ) : (
              <Paragraph type="small" id="installmentValue">
                {formatCurrency(val, {
                  currencySymbol: 'R$ '
                })}
              </Paragraph>
            )}
          </>
        );
      }
    },
    {
      title: 'Percentual',
      dataIndex: 'percentage',
      width: 100,
      key: 'percentage',
      className: 'col-grow-1',
      render: (val, row, index) => {
        return !isView ? (
          <>
            <MaskedInput
              type="number"
              id="installmentPercent"
              value={formatCurrency(val, {
                currencySymbol: ''
              })}
              onChange={e => handleChange(index, 'percentage', formatNumber(e.target.value))}
              onBlur={installmentHandleBlur}
              maskOptions={{
                suffix: '%',
                prefix: '',
                includeThousandsSeparator: false,
                allowDecimal: true
              }}
            />
          </>
        ) : (
          <Paragraph type="small" id={`installmentPercentage-${index + 1}`}>
            {formatCurrency(val, {
              currencySymbol: ''
            })}
            %
          </Paragraph>
        );
      }
    },
    {
      title: 'Vencimento',
      dataIndex: 'dueDate',
      width: 135,
      key: 'childDueDate',
      className: 'col-grow-1',
      render: (val, row, index) => {
        return (
          <>
            {!isView ? (
              <DatePicker
                id={`installmentDueDate-${index + 1}`}
                name="installmentDueDate"
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                allowClear={false}
                value={val ? dayjs(val) : null}
                onChange={value => handleChange(index, 'dueDate', value)}
              />
            ) : (
              <Paragraph type="small" id={`installmentDueDate-${index + 1}`}>
                {val ? dayjs(val).format('DD/MM/YYYY') : ''}
              </Paragraph>
            )}
          </>
        );
      }
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      className: 'col-grow-3',
      renderMobile: true,
      render: (val, row, index) => {
        return (
          <>
            {!isView ? (
              <Input
                type="text"
                id={`installmentDescription-${index + 1}`}
                size="middle"
                name="installmentDescription"
                placeholder="Descrição do item"
                value={val}
                onChange={e => handleChange(index, 'description', e.target.value)}
              />
            ) : (
              <Paragraph type="small" id={`installmentDescription-${index + 1}`}>
                {val}
              </Paragraph>
            )}
          </>
        );
      }
    }
  ];
};

export { columns };
