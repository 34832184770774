import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, breakpoints, spaces } from '../../styles/style';
import { mappingMobileSubHeader } from '../../lib/mapping/Header/mappingMobileSubHeader';
import Button from '../Button/Button';
import useBackToContext from '../../_Hooks/useBackToContext';
import { Subtitle } from '../Text/Text';

const Header = styled.header`
  display: ${props => (props.hide ? 'none' : 'flex')};
  align-items: center;
  background-color: ${colors.white};
  transition: padding-left 0.4s;
  padding: ${spaces.space1} ${spaces.space2};
  position: relative;
  justify-content: space-between;
  width: 100%;

  border-bottom: 1px solid ${colors.neutral100};

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }

  ${props =>
    props.fixed &&
    css`
      position: absolute;
      top: ${props.$isCustomerView ? props.top + 35 : props.top}px;
      left: 0;
    `}
`;

const MobileSubHeader = ({ goBack = false, children, fixed = false, top = 49 }) => {
  const params = useParams();
  const backToContext = useBackToContext();
  const { user } = useSelector(state => state.authReducer);
  const { rootPage, page, subpage, action, subaction } = params;

  const { title, EndContent } =
    mappingMobileSubHeader[rootPage]?.[page]?.[subpage]?.[action]?.[subaction] ||
    mappingMobileSubHeader[rootPage]?.[page]?.[subpage]?.[action] ||
    mappingMobileSubHeader[rootPage]?.[page]?.[subpage] ||
    mappingMobileSubHeader[rootPage]?.[page] ||
    {};
  const CenterContent = () => {
    if (typeof title === 'string') return <Subtitle>{title || 'Vobi'}</Subtitle>;
    if (!title) return <div />;
    const CenterRender = title;
    return <CenterRender />;
  };

  return (
    <Header
      hide={!children && !title}
      fixed={fixed}
      top={top}
      $isCustomerView={user.isCustomerView || user?.anonymous}
      className="hide-on-print"
    >
      {children ? (
        <>
          {goBack && (
            <Button text onClick={backToContext}>
              <FontAwesomeIcon icon={faLongArrowLeft} />
            </Button>
          )}
          {children}
        </>
      ) : (
        <>
          <Button text onClick={backToContext}>
            <FontAwesomeIcon icon={faLongArrowLeft} />
          </Button>
          <CenterContent />
          {EndContent && <EndContent />}
        </>
      )}
    </Header>
  );
};

MobileSubHeader.propTypes = {
  goBack: PropTypes.bool,
  fixed: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  top: PropTypes.number
};

export default MobileSubHeader;
