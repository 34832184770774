import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'antd';
import styled from 'styled-components';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../lib/helpers/eventBus';
import useCRUD from '../../_Hooks/useCRUD';
import { fonts, colors, Div, spaces } from '../../styles/style';
import { updateCompany } from '../../store/auth/actions/action';
import { Paragraph } from '../Text/Text';

const ProgressStyled = styled(Progress)`
  .ant-progress-text {
    color: ${colors.neutral500};
    font-family: ${fonts.family};
    font-weight: ${fonts.weight500};
    font-size: ${fonts.sizeXs};
  }
`;

const Card = styled(Div)`
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  align-items: flex-start;
  gap: ${spaces.space1};
  background-color: ${colors.neutral50};
  padding: ${spaces.space1} ${props => (props.$guideIsCompleted ? spaces.space1 : spaces.space2)} ${spaces.space1}
    ${spaces.space2};
  border: 1px solid ${colors.primary50};
  border-radius: ${spaces.space0};
  text-align: left;
  font-size: ${fonts.sizeSm};
  color: ${colors.neutral600};
  p:first-of-type {
    font-weight: ${fonts.weight500};
  }
`;

const GuideProgressCard = () => {
  const [guideInfo, setGuideInfo] = useState();
  const [configuration, setConfiguration] = useState();
  const { user } = useSelector(state => state.authReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleUpdate, handleGet } = useCRUD({
    model: 'company',
    pathOptions: `/${user?.idCompany}`,
    immediatelyLoadData: false
  });
  const percent = guideInfo?.percent || 0;
  const missingQtt = guideInfo?.missingQtt || 0;
  const guideIsCompleted = percent >= 100;
  const showGuide = guideInfo && configuration && !(guideIsCompleted && configuration?.guidePercentageClosed);
  const getGuide = () => {
    handleGet().then(_company => {
      if (!_company?.error) {
        setGuideInfo(_company?.guideInfo);
        setConfiguration(_company?.configuration);
      }
    });
  };

  useEffect(() => {
    getGuide();
    eventBus.on('updateGuide', getGuide);
    return () => eventBus.remove('updateGuide', getGuide);
  }, []);

  useEffect(() => {
    getGuide();
  }, [user]);

  const ParagraphText = () => {
    if (guideIsCompleted) return <Paragraph type="small">Completado</Paragraph>;
    if (missingQtt <= 1) return <Paragraph type="small">{missingQtt} item restante</Paragraph>;
    return <Paragraph type="small">{missingQtt} itens restantes</Paragraph>;
  };

  const handleCloseGuide = e => {
    e.stopPropagation();
    handleUpdate({
      values: { configuration: { ...configuration, guidePercentageClosed: true } },
      refresh: false
    }).then(_company => {
      if (!_company.error) {
        setConfiguration(_company.configuration);
        dispatch(updateCompany({ ..._company, configuration: _company.configuration }));
      }
    });
  };

  return (
    showGuide && (
      <Card
        onClick={() => {
          history.push(`/profissional/guia-implantacao`);
        }}
        $guideIsCompleted={guideIsCompleted}
      >
        <ProgressStyled
          type="circle"
          strokeColor={guideIsCompleted ? colors.green400 : colors.primary500}
          trailColor={colors.primary200}
          percent={percent}
          status="normal"
          width={32}
        />
        <div>
          <Paragraph type="small" weight={fonts.weight600}>
            Guia de Implantação
          </Paragraph>
          <ParagraphText />
        </div>
        {guideIsCompleted && (
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faXmark}
            onClick={handleCloseGuide}
            color={colors.neutral300}
            size="lg"
          />
        )}
      </Card>
    )
  );
};

export default GuideProgressCard;
