import * as Yup from 'yup';
import { text, date, idReference } from '../schema';

const workReportSchema = Yup.object().shape({
  code: text,
  description: text,
  date,
  idRefurbish: idReference
});

const labelClassName = 'info-form-label';

const workReportMapping = ({ idStatus }) => ({
  code: {
    type: 'text',
    name: 'Código',
    labelClassName,
    md: 3
  },
  idRefurbish: {
    name: 'Projeto*',
    type: 'select',
    model: 'refurbish',
    modelOptions: {
      where: { idStatus, or: [{ '$activeStep.isActive$': true }, { idStep: null }] },
      include: ['activeStep'],
      order: [['name'], ['id']]
    },
    labelClassName,
    offset: true,
    md: 6
  },
  date: {
    name: 'Data',
    type: 'date',
    allowClear: true,
    hasChildren: true,
    tooltipText: 'Data em que o relatório foi coletado',
    format: 'DD/MM/YYYY',
    labelClassName,
    offset: true,
    md: 6
  }
});

export { workReportSchema, workReportMapping };
