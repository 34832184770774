import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileUpload from '../File/FileUpload';
import { Paragraph } from '../Text/Text';
import FileCard from './FileCard';
import ViewFileListModal from '../Modal/ViewFileListModal';
import { AddFileContainer, FilesContainer, FilesList, FilesPrintList } from './AttachmentSection.style';

const AttachmentSection = ({
  fileList,
  setFileList = f => f,
  isView,
  limitFiles = 20,
  emptyStateText = 'Nenhum item registrado'
}) => {
  const removeFile = index => {
    setFileList(prev => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const [showPreviewFileIndex, setShowPreviewFileIndex] = useState(null);

  return (
    <>
      {showPreviewFileIndex !== null && (
        <ViewFileListModal
          startIndex={showPreviewFileIndex}
          fileList={fileList}
          onClose={() => setShowPreviewFileIndex(null)}
        />
      )}
      <FilesContainer>
        {!isView && (
          <AddFileContainer>
            <FileUpload
              multiple
              fullWidth
              noPadding
              id="file-input"
              limitNumFiles={limitFiles}
              onChange={newFiles => setFileList(prev => [...prev, ...newFiles])}
              showGallery={false}
              text="Adicionar anexo"
              listType={null}
              buttonProps={{ type: 'primary', $noIcon: true }}
            />
            <Paragraph>(Limite de {limitFiles} arquivos por upload e tamanho máximo 50mb)</Paragraph>
          </AddFileContainer>
        )}
        <FilesList className="hide-on-print">
          {fileList?.length ? (
            fileList?.map((file, index) => (
              <React.Fragment key={file.id || index}>
                <FileCard
                  id={file.id}
                  data={file}
                  onDelete={!isView ? () => removeFile(index) : null}
                  viewFileFunction={() => setShowPreviewFileIndex(index)}
                  onEdit={text => {
                    setFileList(prev => {
                      const _prev = [...prev];
                      _prev[index].description = text;
                      return _prev;
                    });
                  }}
                  isView={isView}
                />
              </React.Fragment>
            ))
          ) : (
            <Paragraph type="small">{emptyStateText}</Paragraph>
          )}
        </FilesList>
        <FilesPrintList id="files-preview-container">
          {fileList?.length ? (
            fileList?.map((file, index) => (
              <React.Fragment key={file.id || index}>
                <FileCard
                  id={file.id}
                  data={file}
                  isPrint
                  title={index === 0 && 'Documentos e imagens'}
                  offsetTop={index === 1}
                />
              </React.Fragment>
            ))
          ) : (
            <Paragraph type="small">{emptyStateText}</Paragraph>
          )}
        </FilesPrintList>
      </FilesContainer>
    </>
  );
};

AttachmentSection.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setFileList: PropTypes.func,
  isView: PropTypes.bool,
  limitFiles: PropTypes.number,
  emptyStateText: PropTypes.string
};

export default AttachmentSection;
