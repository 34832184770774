import React from 'react';
import * as PropTypes from 'prop-types';
import { Body, Container, Footer, Header } from './Card.styles';

const Card = ({
  children,
  footer,
  header,
  withShadow,
  borderColor,
  footerPadding,
  headerPadding,
  maxWidth,
  bodyPadding,
  footerBackgroundColor,
  headerBackgroundColor,
  bodyBackgroundColor,
  onClick = f => f,
  hover = false,
  disabled = false,
  id = 'CARD',
  borderOnly,
  bodyJustify,
  noBorder = false,
  footerHover,
  borderRadius,
  ...props
}) => {
  return (
    <Container
      id={id}
      key={id}
      onClick={disabled ? () => {} : onClick}
      $withShadow={withShadow}
      $borderColor={borderColor}
      $maxWidth={maxWidth}
      $borderOnly={borderOnly}
      $borderRadius={borderRadius}
      $noBorder={noBorder}
      hover={hover}
      {...props}
    >
      {header && (
        <Header padding={headerPadding} $backgroundColor={headerBackgroundColor}>
          {header}
        </Header>
      )}
      <Body padding={bodyPadding} justify={bodyJustify} $backgroundColor={bodyBackgroundColor}>
        {children}
      </Body>

      {footer && (
        <Footer padding={footerPadding} $backgroundColor={footerBackgroundColor} $footerHover={footerHover}>
          {footer}
        </Footer>
      )}
    </Container>
  );
};

Card.propTypes = {
  children: PropTypes.instanceOf(Object),
  footer: PropTypes.instanceOf(Object),
  header: PropTypes.instanceOf(Object),
  withShadow: PropTypes.bool,
  borderColor: PropTypes.string,
  footerPadding: PropTypes.string,
  headerPadding: PropTypes.string,
  maxWidth: PropTypes.string,
  bodyPadding: PropTypes.string,
  footerBackgroundColor: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  bodyBackgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  hover: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  borderOnly: PropTypes.string,
  noBorder: PropTypes.bool,
  bodyJustify: PropTypes.string,
  footerHover: PropTypes.bool,
  borderRadius: PropTypes.string
};

export default Card;
