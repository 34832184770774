import React from 'react';

import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import useViewport from '../../_Hooks/useViewport';

const TooltipText = ({ tooltipText, text }) => {
  const { isMobile } = useViewport(window.innerWidth);

  return isMobile() ? text : <Tooltip title={tooltipText}>{text}</Tooltip>;
};

TooltipText.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  text: PropTypes.string
};

export default TooltipText;
