import styled from 'styled-components';

import Button from './Button';
import { fonts, colors, spaces } from '../../styles/style';

const OptionButton = styled(Button)`
  padding: ${spaces.space1} ${props => (props.tab ? spaces.space5 : spaces.space3)};
  gap: ${spaces.space1};
  span {
    font-weight: ${fonts.weight400};
  }

  &:hover {
    width: 100%;
    color: ${colors.primary500};
    background-color: ${colors.primary50} !important;
    justify-content: flex-start;
  }
`;

export default OptionButton;
