import { combineReducers } from 'redux';

import authReducer from './auth/reducer/reducer';
import refurbishReducer from './refurbish/reducer/reducer';
import headerNameReducer from './headerName/reducer/reducer';
import quoteToPurchaseOrderIds from './quoteToPurchaseOrderIds/reducer/reducer';
import setup from './setup/reducer/reducer';
import { AUTH } from './auth/actions/action';

const reduceStore = combineReducers({
  authReducer,
  refurbishReducer,
  headerNameReducer,
  setup,
  quoteToPurchaseOrderIds
});

const store = (state, action) => {
  const newState = { ...state };
  if (action.type === AUTH.LOGOUT) {
    delete newState.authReducer;
    delete newState.refurbishReducer;
    delete newState.headerNameReducer;
    delete newState.quoteToPurchaseOrderIds;
  }

  return reduceStore(newState, action);
};

export default store;
