/**
 * Cria um processador de dados com operações para tarefas e links.
 * @param {Object} options - Objeto contendo as funções para atualizar, criar e deletar tarefas e links.
 * @param {Function} options.middleware - Função intermediária para processar operações.
 * @param {Function} options.taskUpdate - Função para atualizar uma tarefa.
 * @param {Function} options.taskCreate - Função para criar uma nova tarefa.
 * @param {Function} options.taskDelete - Função para deletar uma tarefa existente.
 * @param {Function} options.linkUpdate - Função para atualizar um link.
 * @param {Function} options.linkCreate - Função para criar um novo link.
 * @param {Function} options.linkDelete - Função para deletar um link existente.
 * @returns {Object} - Objeto contendo métodos para manipular tarefas e links.
 */
const createDataProcessor = ({
  middleware = () => {},
  taskUpdate = () => {},
  taskCreate = () => {},
  taskDelete = () => {},
  linkUpdate = () => {},
  linkCreate = () => {},
  linkDelete = () => {}
}) => {
  return {
    task: {
      create: task => {
        middleware('task create', task);
        return taskCreate(task);
      },

      update: task => {
        middleware('task update', task);
        return taskUpdate(task);
      },

      delete: id => {
        middleware('task delete', { id });
        return taskDelete({ id });
      }
    },
    link: {
      create: link => {
        middleware('link create', link);
        return linkCreate(link);
      },

      update: link => {
        middleware('link update', link);
        return linkUpdate(link);
      },

      delete: id => {
        middleware('link delete', { id });
        return linkDelete({ id });
      }
    }
  };
};

export default createDataProcessor;
