import styled from 'styled-components';
import { fonts, breakpoints, spaces, colors } from '../../styles/style';
import Tabs from '../Tabs/Tabs';

const TemplatesDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spaces.space2} ${spaces.space4} 0 ${spaces.space3};
  border-left: 1px solid ${colors.neutral75};
`;

const StyledListHeader = styled.div`
  display: flex;
  padding: ${spaces.space3} ${spaces.space3} 0 ${spaces.space3};
  border-left: 1px solid ${colors.neutral75};
  align-items: center;

  p {
    flex: 1;
    font-size: ${fonts.sizeLg};
    color: ${colors.neutral700} !important;
  }

  .newTemplateBtn {
    flex: 2;
    font-size: ${fonts.sizeMd};

    button {
      float: right;
    }
  }
`;

const StyledTemplateSection = styled.section`
  display: flex;
  height: 100%;
`;

const StyledTemplateList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${spaces.space1};
  width: 100%;
  border-left: 1px solid ${colors.neutral75};
`;

const StyledTabs = styled(Tabs)`
  background-color: transparent;
  margin-right: ${spaces.space1};

  .ant-tabs-extra-content {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .ant-tabs-nav {
    padding-top: 0;
    border-top: 1px solid ${colors.neutral75};
    border-bottom: 1px solid ${colors.neutral75};
  }
  @media (max-width: ${breakpoints.tablet}) {
    .ant-tabs-nav {
      display: block;
    }
    .ant-tabs-extra-content {
      margin-top: 10px;
    }
  }
`;

const StyledFooter = styled.div`
  width: 100%;

  .pagination {
    margin-top: ${spaces.space2};
  }
`;

const NewTemplateButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeMd};

  svg {
    margin-right: ${spaces.space2};
  }
`;

const NoContentContainer = styled.div`
  border-left: 1px solid ${colors.neutral75};
  padding: ${spaces.space1};
`;

export {
  TemplatesDescription,
  StyledTemplateList,
  StyledTabs,
  StyledFooter,
  StyledListHeader,
  StyledTemplateSection,
  NewTemplateButtonContainer,
  NoContentContainer
};
