import styled, { css } from 'styled-components';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.space2};
  gap: ${spaces.space2};
  background-color: ${colors.white};
  transition: background-color 0.5s ease-in-out;
  scroll-margin: 50px;
  ${props =>
    props.highlight &&
    css`
      background-color: ${colors.primary50};
      transition: background-color 0.3s ease-in-out;
    `}
`;

export const ContentMethod = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spaces.space2};
  gap: ${spaces.space2};
`;

export const Img = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const Pix = styled.img`
  height: 160px;
  width: 160px;
  @media (max-width: ${breakpoints.tablet}) {
    height: 112px;
    width: 112px;
  }
`;

export const ContentDiv = styled(Div)`
  padding-top: ${spaces.space0};
  flex-direction: column;
  gap: ${spaces.space2};
  align-items: self-start;
  justify-content: flex-start;
`;

export const DivMethods = styled(Div)`
  gap: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const BankSlipContent = styled(Div)`
  gap: ${spaces.space3};
  align-items: flex-start;
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

export const Description = styled.span`
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeXs};
  line-height: 18px;
  color: ${props => (props.color ? props.color : colors.neutral600)};
  strong {
    font-weight: ${fonts.weight600};
    color: ${colors.neutral500};
  }
`;

export const ResponsibleDescription = styled.span`
  color: ${colors.neutral500};
  font-size: ${fonts.sizeSm};
  font-style: normal;
  line-height: 18px;
  strong {
    font-weight: 400;
    color: ${colors.neutral600};
  }
`;

export const CardInfoGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  justify-items: start;
  padding-bottom: ${spaces.space1};
  div {
    align-items: start;
    flex-direction: column;
    gap: ${spaces.space0};
  }
  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${spaces.space2};
  }
`;
