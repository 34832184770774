import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { useContextHook } from '../../contexts/GeneralContext';
import { colors, Div, spaces } from '../../styles/style';
import { Paragraph, Subtitle } from '../Text/Text';
import Button from '../Button/Button';
import { Content } from '../../_Pages/Payments/Payment.style';
import TooltipIcon from '../Tooltip/TooltipIcon';
import Label from '../Label/Label';
import { stripHTMLTags } from '../../lib/helpers/helper';

const PaymentNotesForm = () => {
  const { values, isView, setField, isCustomerView, isPublic, isCharge, isCreate, isPrinting } = useContextHook();
  const { information, observation, annotation } = values || {};

  const [notesVisible, setNotesVisible] = useState({});

  const handleNotesClick = type => {
    setNotesVisible(prev => ({ ...prev, [type]: true }));
  };

  const handleTextEditorChange = field => content => {
    setField(field)(content);
  };

  return isView ? (
    <SimpleAccordion
      title="Anotações"
      initOpen={!!observation || !!annotation || !!information || isCreate}
      withPaddingContainer
    >
      <Content>
        {isCharge && (notesVisible.information || information) ? (
          <Div $fullWidth direction="column" align="flex-start">
            <Label>Informações de pagamento</Label>
            {isPrinting ? (
              stripHTMLTags(information)
            ) : (
              <RichTextEditor
                onChange={handleTextEditorChange('information')}
                value={information}
                height={100}
                toolbar={false}
                statusbar={false}
                outputFormat="html"
                disabled
                placeholder="Insira aqui as informações de pagamento como conta bancária,
              pix ou outra informação relacionada ao pagamento."
                id="information"
              />
            )}
          </Div>
        ) : null}
        {isCharge && (notesVisible.observation || observation) ? (
          <Div $fullWidth direction="column" align="flex-start">
            <Label>Observações</Label>
            {isPrinting ? (
              stripHTMLTags(observation)
            ) : (
              <RichTextEditor
                onChange={handleTextEditorChange('observation')}
                value={observation}
                height={100}
                toolbar={false}
                statusbar={false}
                outputFormat="html"
                disabled
                placeholder="Insira aqui observações relevantes para o seu cliente."
                id="observation"
              />
            )}
          </Div>
        ) : null}

        {(notesVisible.annotation || annotation) && !isCustomerView && !isPublic && (
          <Div $fullWidth direction="column" align="flex-start">
            <Label>Notas Internas</Label>
            {isPrinting ? (
              stripHTMLTags(annotation)
            ) : (
              <RichTextEditor
                onChange={handleTextEditorChange('annotation')}
                value={annotation}
                height={100}
                toolbar={false}
                statusbar={false}
                outputFormat="html"
                disabled
                placeholder={
                  isCharge
                    ? 'Insira aqui as informações que não serão compartilhadas com o cliente.'
                    : 'Insira aqui as observações que desejar.'
                }
                id="annotation"
              />
            )}
          </Div>
        )}
        {!annotation && !observation && !information && <Paragraph>Nenhuma anotação adicionada.</Paragraph>}
      </Content>
    </SimpleAccordion>
  ) : (
    <Content>
      <Subtitle>Anotações</Subtitle>
      <Div $fullWidth gap={spaces.space2}>
        {isCharge && (notesVisible.information || information) ? (
          <div style={{ width: '100%' }}>
            <Div gap={spaces.space1}>
              <Label>Informações de pagamento</Label>
              <TooltipIcon
                icon={faInfoCircle}
                color={colors.primary600}
                text="Insira aqui as informações de pagamento como conta bancária,
               pix ou outra informação relacionada ao pagamento."
              />
            </Div>
            <RichTextEditor
              onChange={handleTextEditorChange('information')}
              value={information}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
              placeholder="Insira aqui as informações de pagamento como conta bancária,
              pix ou outra informação relacionada ao pagamento."
              id="information"
            />
          </div>
        ) : null}
        {isCharge && (notesVisible.observation || observation) ? (
          <div style={{ width: '100%' }}>
            <Label>Observações</Label>
            <RichTextEditor
              onChange={handleTextEditorChange('observation')}
              value={observation}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
              placeholder="Insira aqui observações relevantes para o seu cliente."
              id="observation"
            />
          </div>
        ) : null}

        {(notesVisible.annotation || annotation) && !isCustomerView && !isPublic && (
          <div style={{ width: '100%' }}>
            <Div gap={spaces.space1}>
              <Label>Notas Internas</Label>
              <TooltipIcon
                icon={faInfoCircle}
                color={colors.primary600}
                text={
                  isCharge
                    ? 'As notas internas não são visíveis pelo seu cliente.'
                    : 'Registre informações adicionais utilizando as notas.'
                }
              />
            </Div>
            <RichTextEditor
              onChange={handleTextEditorChange('annotation')}
              value={annotation}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
              placeholder={
                isCharge
                  ? 'Insira aqui as informações que não serão compartilhadas com o cliente.'
                  : 'Insira aqui as observações que desejar.'
              }
              id="annotation"
            />
          </div>
        )}
      </Div>

      <Div direction="column" align="start">
        {!notesVisible.information && !information && isCharge ? (
          <Button
            text
            type="primary"
            onClick={() => handleNotesClick('information')}
            id="information-button"
            align="none"
          >
            <FontAwesomeIcon icon={faPlus} />
            Informações de pagamento
          </Button>
        ) : null}

        {!notesVisible.observation && !observation && isCharge ? (
          <Button
            text
            type="primary"
            onClick={() => handleNotesClick('observation')}
            id="observation-button"
            align="none"
          >
            <FontAwesomeIcon icon={faPlus} />
            Observações
          </Button>
        ) : null}
        {!notesVisible.annotation && !annotation && (
          <Button
            text
            type="primary"
            onClick={() => handleNotesClick('annotation')}
            id="annotation-button"
            align="none"
          >
            <FontAwesomeIcon icon={faPlus} />
            Notas internas
          </Button>
        )}
      </Div>
    </Content>
  );
};

export default PaymentNotesForm;
