import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCRUD from '../_Hooks/useCRUD';

import CenteredLoader from '../components/Loader/CenteredLoader';
import { login, updateCompany } from '../store/auth/actions/action';
import { SETUP } from '../store/setup/action/action';
import eventBus from '../lib/helpers/eventBus';

const Authentication = ({ children }) => {
  const { user = null } = useSelector(state => state?.authReducer) || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, loading } = useCRUD({ model: 'setup' });
  const { handleUpdate } = useCRUD({ model: 'company', immediatelyLoadData: false });
  const { list, loading: loadingPlan } = useCRUD({ model: 'plan', options: { include: ['permissions'] } });
  const { list: permissionList, loading: loadingPermissions } = useCRUD({ model: 'permission' });
  const { userType } = useSelector(state => state.setup.enums) || {};

  useEffect(() => {
    if (data) {
      dispatch({ type: SETUP.ENUMS, payload: data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      dispatch({ type: SETUP.PLANS, payload: list });
    }
  }, [list, dispatch]);

  useEffect(() => {
    if (data) {
      dispatch({ type: SETUP.PERMISSIONS, payload: permissionList });
    }
  }, [permissionList, dispatch]);

  window.addEventListener('message', event => {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' && event.data.fromPlaywright) {
      dispatch(
        login({
          user: event.data.user
        })
      );
      history.push('/profissional/dashboard');
    }
  });

  useEffect(() => {
    if (user?.idCompany && user?.userType === userType?.provider?.value) {
      handleUpdate({
        updatePathOptions: `/${user.idCompany}/upgrade-guide`,
        values: { updateAllGuide: true },
        refresh: false
      }).then(company => {
        dispatch(
          updateCompany({
            ...user.company,
            guideAchievements: company?.guideAchievements,
            guideInfo: company?.guideInfo
          })
        );
        eventBus.dispatch('updateGuide');
      });
    }
  }, [dispatch]);

  return loading || loadingPlan || loadingPermissions ? <CenteredLoader /> : children;
};

Authentication.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

export default Authentication;
