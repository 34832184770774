import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';

// components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { columns as scheduleColumns } from '../../lib/mapping/TableOrList/scheduleColumns';
import useViewport from '../../_Hooks/useViewport';

import TaskBulkActions from '../BulkActions/TaskBulkActions';

import { useContextHook } from '../../contexts/GeneralContext';

import Button from '../Button/Button';
import VirtualizedTable from './VirtualizedTable';
import CenteredLoader from '../Loader/CenteredLoader';
import Content from '../Content/Content';
import { colors, spaces } from '../../styles/style';
import PdfTable from './PdfTableV2';
import ProgressModal from '../Modal/ProgressModal';

const childrenColumnName = 'tasks';

const ScheduleTable = ({
  referKey = 'idRefurbish',
  template,
  isApply,
  idCompany,
  handleCloseBulkAction,
  selectedRowKeys,
  selectedItems,
  onSelectItem,
  onSelectAll = f => f,
  disableSelection,
  workDays,
  dayoffs,
  list,
  loadData,
  handleAdd,
  handleChange,
  handleBulkChange,
  handleBulkDelete,
  isDefaultOrder,
  handleSort,
  expandItem,
  loading,
  progress,
  expandedRowKeys,
  stepColumns,
  serializedPrioritiesArray,
  serializedStatusArray,
  handleRowClick
}) => {
  const { isMobile } = useViewport(window.innerWidth);

  const {
    refurbish,
    isPrintList,
    setIsPrintList,
    ganttInstance,
    getFormattedLinks,
    handleChangePredecessor,
    isCustomer
  } = useContextHook();

  let tablePadding = spaces.space1;
  if (!isMobile()) {
    tablePadding = isApply ? `${spaces.space2} 0 0` : `0 ${spaces.space2} ${spaces.space2}`;
  }

  const getHeight = () => {
    if (isMobile()) {
      return 'calc(100vh - 324px)';
    }
    if (isCustomer) {
      return `calc(100vh - 210px)`;
    }
    return 'calc(100vh - 278px)';
  };

  const AddButtonStep = ({ data, index }) => {
    const hasParent = data?.idRefurbishStep || data?.idTemplateStep;

    return (
      <div id={`inlineButton${data?.id}`} className="inlineButtons">
        <div className="buttonContainer">
          {!hasParent && (
            <Button
              size="small"
              type="primary"
              roundPadding={`0 ${spaces.space2} 0 ${spaces.space0}`}
              shape="round"
              onClick={() => {
                const afterBoundary = list?.[index + 1]?.order || Number(data.order) + 1;
                const order = (Number(data?.order || 0) + Number(afterBoundary)) / 2;
                handleAdd(null, false, { order });
              }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  margin: `0 ${spaces.space1} 0 2px`,
                  color: colors.white
                }}
                size="lg"
              />
              ETAPA
            </Button>
          )}
          <Button
            size="small"
            type="primary"
            roundPadding={`0 ${spaces.space2} 0 ${spaces.space0}`}
            shape="round"
            onClick={() => {
              const parentKey = template ? 'idTemplateStep' : 'idRefurbishStep';
              let order = null;

              if (hasParent) {
                const step = list.find(s => s.id === data[parentKey]);
                const taskIndex = step?.tasks?.findIndex(task => task.id === data.id);
                const afterBoundary = step?.tasks?.[taskIndex + 1]?.order || Number(data.order) + 1;
                order = (Number(data?.order || 0) + Number(afterBoundary)) / 2;
              }

              handleAdd(!hasParent ? data?.id : null, true, { order, [parentKey]: data?.[parentKey] || data?.id });
            }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                margin: `0 ${spaces.space1} 0 2px`,
                color: colors.white
              }}
              size="lg"
            />
            TAREFA
          </Button>
        </div>
      </div>
    );
  };

  AddButtonStep.propTypes = {
    data: PropTypes.instanceOf(Object),
    index: PropTypes.number
  };

  const handleSubmitMoveRow = ({ values, dropRow, dragIsLevel, dropIsLevel }) => {
    if (!isApply) {
      const { id, order, [`${referKey}Step`]: referValue } = values;
      const isChildren = !!referValue;
      let _order = order;
      const idReferStep = isChildren ? { [`${referKey}Step`]: referValue || dropRow[`${referKey}Step`] } : {};
      if (!dragIsLevel && dropIsLevel && dropRow?.tasks?.length) {
        _order = Number(dropRow.tasks[dropRow.tasks.length - 1].order) + 1;
      }
      handleChange({ id, order: _order, ...idReferStep }, isChildren, false);
    }
  };

  return (
    <Content padding={tablePadding} bgColor="white">
      {loading ? (
        <CenteredLoader />
      ) : (
        <VirtualizedTable
          AddComponent={!isApply ? AddButtonStep : null}
          columns={stepColumns}
          list={list}
          expandItem={expandItem}
          childrenColumnName={childrenColumnName}
          onClick={handleRowClick}
          onSort={handleSort}
          onSelect={onSelectItem}
          onSelectAll={onSelectAll}
          moveRowProps={{
            isDefaultOrder,
            submitMove: handleSubmitMoveRow,
            idParentPropName: `${referKey}Step`
          }}
          rowSelection={!isApply && !disableSelection}
          selectedRowKeys={selectedRowKeys}
          scroll={{ y: getHeight(), x: '100%' }}
          expandedRowKeys={expandedRowKeys}
          initialExpandedRowKeys={[]}
          refreshColumns
        />
      )}
      <PdfTable
        title="Exportar para PDF"
        list={list}
        columns={stepColumns}
        childrenColumnName={childrenColumnName}
        serializedPrioritiesArray={serializedPrioritiesArray}
        columnsMapping={scheduleColumns}
        serializedStatusArray={serializedStatusArray}
        paddingFirstColumn={8}
        pdfProps={{
          onClose: () => setIsPrintList(false),
          open: isPrintList,
          refurbish,
          name: 'etapas',
          orientationPdf: 'portrait',
          customTip: 'Você pode expandir e recolher as tarefas clicando nas etapas'
        }}
        ganttInstance={ganttInstance}
        getFormattedLinks={getFormattedLinks}
        handleChangePredecessor={handleChangePredecessor}
      />
      <ProgressModal progress={progress} open={!!progress} />
      {!template && (
        <TaskBulkActions
          selectedRowKeys={selectedRowKeys}
          handleChange={values => handleBulkChange(values, selectedRowKeys)}
          handleDelete={handleBulkDelete}
          handleClose={handleCloseBulkAction}
          idCompany={idCompany}
          selectedTasks={selectedItems}
          isDefaultOrder={isDefaultOrder}
          handleRefresh={() => loadData({})}
          workDays={workDays}
          dayoffs={dayoffs}
        />
      )}
    </Content>
  );
};

ScheduleTable.propTypes = {
  referKey: PropTypes.string,
  onSelectItem: PropTypes.func,
  onSelectAll: PropTypes.func,
  isApply: PropTypes.bool,
  idCompany: PropTypes.number,
  handleCloseBulkAction: PropTypes.func,
  selectedRowKeys: PropTypes.instanceOf(Array),
  template: PropTypes.bool,
  selectedItems: PropTypes.instanceOf(Array),
  disableSelection: PropTypes.bool,
  workDays: PropTypes.instanceOf(Object),
  dayoffs: PropTypes.instanceOf(Object),
  list: PropTypes.instanceOf(Array),
  loadData: PropTypes.func,
  handleAdd: PropTypes.func,
  handleChange: PropTypes.func,
  handleBulkChange: PropTypes.func,
  handleBulkDelete: PropTypes.func,
  isDefaultOrder: PropTypes.bool,
  handleSort: PropTypes.func,
  expandItem: PropTypes.number,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  expandedRowKeys: PropTypes.instanceOf(Array),
  stepColumns: PropTypes.instanceOf(Array),
  serializedPrioritiesArray: PropTypes.instanceOf(Array),
  serializedStatusArray: PropTypes.instanceOf(Array),
  handleRowClick: PropTypes.func
};

export default ScheduleTable;
