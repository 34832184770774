import React, { memo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faBars, faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { Title } from '../Text/Text';
import { capitalize } from '../../lib/helpers/helper';
import { useContextHook } from '../../contexts/GeneralContext';
import Notification from '../Notification/Notification';
import Button from '../Button/Button';
import { breakpoints, colors, Div, spaces } from '../../styles/style';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  transition: padding-left 0.4s;
  padding: ${spaces.space1} ${spaces.space2};

  border-bottom: 1px solid ${colors.neutral100};

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const MobileMainHeader = ({ title = '', user }) => {
  const { handleOpenCloseNavBar } = useContextHook();

  return (
    <Header>
      <Button padding={0} text onClick={handleOpenCloseNavBar}>
        <FontAwesomeIcon icon={faBars} size="lg" />
      </Button>
      <Title>{capitalize(title)}</Title>
      <Div>
        <FontAwesomeIcon
          id="menu-suporte-mobile"
          style={{ marginLeft: '-32px', marginRight: '32px', height: 18 }}
          icon={faCircleQuestion}
          color={colors.primary600}
        />
        {!user?.anonymous && <Notification notificationUniqueKey="MobileMainHeader" />}
      </Div>
    </Header>
  );
};

MobileMainHeader.propTypes = {
  title: PropTypes.string,
  user: PropTypes.instanceOf(Object)
};

export default memo(MobileMainHeader);
