import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faCircleDollar, faUserCheck, faFileInvoice, faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import ItemDetailButton from '../Button/ItemDetailButton';
import Dropdown from './Dropdown';
import ConfirmModal from '../Modal/ConfirmModal';
import { spaces } from '../../styles/style';
import FileUpload from '../File/FileUpload';
import CopyLinkButton from '../Button/CopyLinkButton';
import RedirectLinkButton from '../Button/EditpaymentButton';
import EditInstallmentDrawer from '../Drawer/EditInstallmentDrawer';
import RegisterInstallmentModal from '../Modal/RegisterInstallmentModal';
import ShareModuleModal from '../Modal/ShareModuleModal';
import useUploadAttachment from '../../_Hooks/useUploadAttachment';
import ExportSlipBank from '../Button/ExportSlipBank';
import { getViewDate } from '../../lib/helpers/helper';
import { columns } from '../../lib/mapping/TableOrList/lastViewColumns';
import ListModal from '../Modal/ListModal';
import useViewport from '../../_Hooks/useViewport';
import TransferDrawer from '../Drawer/TransferDrawer';
import UndoReconciliationModal from '../Modal/UndoReconciliationModal';
import { ButtonGroup } from '../Button/ItemDetailButton.styled';

const ITEMS_PAGE = 6;

const PaymentDropDown = ({
  model = 'installment',
  item,
  mappingObj,
  idRefurbish,
  afterSubmit,
  isCustomerView,
  mobileDirection,
  id,
  onItemClick = f => f,
  openPaymentDrawer,
  countFiles,
  justify,
  isVobiPay = false,
  handleViewedModal,
  openDrawer = 'viewPaymentDetails',
  openTransferDrawer,
  isDrawer = false,
  showConciliation = false,
  isBankStatement,
  showReceipt,
  hasUnsavedChanges = () => false,
  validateKeys = []
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const [showModal, setShowModal] = useState();
  const [offset, setOffset] = useState(1);
  const { user } = useSelector(state => state.authReducer);
  const { userType, installmentStatuses } = useSelector(state => state.setup.enums) || {};
  const isCustomer = user?.userType === userType?.customer?.value;
  const showPaperClip = !!item?.files?.length || item?.countFile > 0;

  const lastView = getViewDate({ viewedBy: item?.payment?.viewedBy || item?.viewedBy });
  const viewedBy = item?.payment?.viewedBy || item?.viewedBy;
  const isReconciled = (model !== 'payment' || !!item?.idPayment) && item?.isReconciled;
  const isReconciledTransfer = !!item?.transferId && !!(item?.idReconciliationIn || item?.idReconciliationOut);
  const isReconciledVobiPay =
    model !== 'payment' &&
    !item?.idReconciliation &&
    item?.idPaymentAsaas &&
    installmentStatuses?.paidVobiPay?.includes(item?.idInstallmentStatus);
  const transferId = item?.transferId || item?.payment?.transferId;
  const showInvoice = item?.tags?.length > 0 || item?.payment?.tags?.length > 0;

  const [viewedByFiltered, setViewedByFiltered] = useState(viewedBy?.slice(0, ITEMS_PAGE));

  const { handleUploadFile } = useUploadAttachment({ model, idReference: idRefurbish, item });

  const _columns = columns({ isMobile: _isMobile });

  const handlePagination = _offset => {
    setOffset(_offset);
    if (showModal === 'viewedBy') {
      const start = (_offset - 1) * ITEMS_PAGE;
      const end = start + ITEMS_PAGE;
      const _viewedByFiltered = viewedBy?.slice(start, end);
      setViewedByFiltered(_viewedByFiltered);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handlePagination(1);
  };

  const modal = {
    viewedBy: (
      <ListModal
        showModal={showModal === 'viewedBy'}
        list={viewedByFiltered}
        columns={_columns}
        title="Visualizações da cobrança"
        handlePagination={handlePagination}
        length={viewedBy?.length}
        offset={offset}
        handleCloseModal={handleCloseModal}
        itensPage={ITEMS_PAGE}
      />
    ),
    undoReconciliationModal: (
      <UndoReconciliationModal
        installment={item}
        onClose={(reload, _id) => {
          if (reload) afterSubmit(null, _id);
          handleCloseModal();
        }}
      />
    )
  };

  return (
    <ButtonGroup
      justify={justify}
      onClick={e => e.stopPropagation()}
      mobileDirection={mobileDirection}
      className="hide-on-print"
    >
      {!isDrawer && !isCustomer && (
        <>
          <ItemDetailButton
            show={lastView && !isCustomer && isVobiPay}
            icon={faUserCheck}
            tooltipProps={{ overlayStyle: { minWidth: _isMobile ? '320px' : '420px' }, id: 'viewed-by' }}
            titleTooltip={`
              Fatura visualizada pela última vez em: ${lastView}. Clique para acessar o histórico de
              visualização.
            `}
            onClick={() => setShowModal('viewedBy')}
            onCloseModal={handleViewedModal}
          />
          <ItemDetailButton show={isVobiPay} icon={faCircleDollar} titleTooltip="Cobrado via Vobi Pay" />
        </>
      )}
      {!isCustomer && !isCustomerView && showConciliation && (
        <ItemDetailButton
          show={isReconciled || isReconciledVobiPay || isReconciledTransfer}
          icon={faBadgeCheck}
          titleTooltip={
            isReconciledVobiPay
              ? 'Lançamento Vobi Pay conciliado automaticamente'
              : 'Lançamento conciliado. Clique para ver detalhes.'
          }
          tooltipProps={{
            overlayStyle: { minWidth: isReconciledVobiPay && '208px', width: !isReconciledVobiPay && '184px' },
            id: 'reconciled'
          }}
          onClick={() => !isReconciledVobiPay && !isReconciledTransfer && setShowModal('undoReconciliationModal')}
        />
      )}
      {showInvoice ? (
        <ItemDetailButton
          show={showInvoice && !isBankStatement}
          icon={faFileInvoice}
          titleTooltip={!_isMobile ? 'Ver notas fiscais' : null}
          preventOpenDrawer={openPaymentDrawer}
          tooltipProps={{
            id: 'view-invoice'
          }}
          onClick={() => {
            if (openPaymentDrawer || openTransferDrawer)
              onItemClick(
                {
                  modal: openDrawer,
                  idPayment: transferId ? null : item?.payment?.id || item?.id,
                  transferId,
                  isCharge: item?.payment?.isCharge || item?.isCharge
                },
                !!transferId
              );
          }}
        >
          {!transferId && (
            <EditInstallmentDrawer
              eventButton="editpayment"
              idRefurbish={idRefurbish}
              id={item?.id}
              handleRedirect={afterSubmit}
            />
          )}
        </ItemDetailButton>
      ) : null}
      {showReceipt ? (
        <ItemDetailButton
          show={item?.idReceipt}
          icon={faReceipt}
          tooltipProps={{ id: `show-receipt-installment-${item?.id}` }}
          titleTooltip="Ver recibo"
          onClick={() => window.open(`${window.location.origin}/rc/${item?.idReceipt}`, '_blank')}
        />
      ) : null}
      <ItemDetailButton
        show={openPaymentDrawer ? countFiles : showPaperClip}
        icon="faPaperclip"
        titleTooltip={!_isMobile ? 'Ver anexos' : null}
        preventOpenDrawer={openPaymentDrawer}
        onClick={() => {
          if (hasUnsavedChanges()) return false;
          if (openPaymentDrawer || openTransferDrawer)
            onItemClick(
              {
                modal: openDrawer,
                idPayment: transferId ? null : item?.payment?.id || item?.id,
                transferId,
                isCharge: item?.payment?.isCharge || item?.isCharge
              },
              !!transferId
            );
          return true;
        }}
      >
        {!transferId && (
          <EditInstallmentDrawer
            eventButton="editpayment"
            idRefurbish={idRefurbish}
            id={item?.id}
            handleRedirect={afterSubmit}
            afterSubmit={() => afterSubmit(item)}
          />
        )}
      </ItemDetailButton>
      {isCustomer && (
        <ItemDetailButton
          titleTooltip="Ver detalhes"
          preventOpenDrawer={openPaymentDrawer}
          onClick={() => {
            if (openPaymentDrawer) onItemClick({ modal: openDrawer, idPayment: item?.id });
          }}
        >
          <EditInstallmentDrawer
            eventButton="editpayment"
            idRefurbish={idRefurbish}
            id={item?.id}
            handleRedirect={onItemClick}
          />
        </ItemDetailButton>
      )}
      <>
        {!isCustomer && !isCustomerView && (
          <Dropdown
            slim
            alignLeftOptions
            buttonPadding={spaces.space2}
            data={{ id: item.id, order: item.order, newOrder: item.newOrder }}
            mappingKey={mappingObj}
            trigger={['click']}
            model={model}
            onItemClick={onItemClick}
            button={{
              uploadFile: ({ onClose, ...params }) => (
                <FileUpload onChange={newFiles => handleUploadFile(newFiles, onClose)} {...params} />
              ),
              copyLink: ({ onClose, ...params }) => (
                <CopyLinkButton onClose={onClose} eventButton="item" urlContext="o" idData={item?.id} {...params} />
              ),
              exportSlipBank: ({ onClose, ...params }) => (
                <ExportSlipBank onClose={onClose} customUrl={item?.bankSlipUrl} idData={item?.id} {...params} />
              ),
              redirectLink: ({ onClose, ...params }) => (
                <RedirectLinkButton onClose={onClose} idData={item?.id} {...params} />
              )
            }}
            modal={{
              editItem: () => (
                <EditInstallmentDrawer
                  eventButton="editpayment"
                  idRefurbish={idRefurbish}
                  id={item?.id}
                  handleRedirect={onItemClick}
                />
              ),
              confirmModal: ConfirmModal,
              editPaymentModal: props => <EditInstallmentDrawer handleRedirect={onItemClick} {...props} />,
              registerPayment: RegisterInstallmentModal,
              sharePayment: ShareModuleModal,
              editTransference: TransferDrawer,
              undoReconciliationModal: UndoReconciliationModal
            }}
            afterSubmit={afterSubmit}
            id={id}
            validateBeforeOpen={hasUnsavedChanges}
            validateKeys={validateKeys}
          />
        )}
      </>
      {showModal && modal[showModal] ? modal[showModal] : null}
    </ButtonGroup>
  );
};

PaymentDropDown.propTypes = {
  model: PropTypes.string,
  item: PropTypes.instanceOf(Object),
  mappingObj: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  afterSubmit: PropTypes.func,
  isCustomerView: PropTypes.bool,
  mobileDirection: PropTypes.string,
  redirectUrl: PropTypes.string,
  id: PropTypes.string,
  onItemClick: PropTypes.func,
  openPaymentDrawer: PropTypes.bool,
  countFiles: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  justify: PropTypes.string,
  isVobiPay: PropTypes.bool,
  handleViewedModal: PropTypes.func,
  openDrawer: PropTypes.string,
  openTransferDrawer: PropTypes.bool,
  isDrawer: PropTypes.bool,
  showConciliation: PropTypes.bool,
  isBankStatement: PropTypes.bool,
  showReceipt: PropTypes.bool,
  hasUnsavedChanges: PropTypes.func,
  validateKeys: PropTypes.instanceOf(Array)
};

export default PaymentDropDown;
