import React from 'react';

import { faCircleInfo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Tooltip } from 'antd';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import Select from '../../../components/Select/Select';
import MaskedInput from '../../../components/Input/NewMaskedInput';
import formatCurrency from '../../helpers/formatCurrency';
import formatNumber from '../../helpers/formatNumber';
import { colors, Div, fonts, Grid, spaces } from '../../../styles/style';
import { Paragraph } from '../../../components/Text/Text';
import { formatDecimalsDinamically } from '../../helpers/helper';

const TooltipGrid = ({ icon, data, iconColor, ...props }) => {
  return (
    <Tooltip
      color={colors.white}
      style={{ height: spaces.space2, width: spaces.space2 }}
      overlayInnerStyle={{ padding: spaces.space1, width: 'fit-content' }}
      placement="top"
      title={
        <Grid $gridTemplateColumns="180px repeat(3, 100px)" gap={spaces.space1}>
          <Div>
            <Paragraph strong type="small">
              <b>Projeto</b>
            </Paragraph>
          </Div>
          <Div justify="flex-end">
            <Paragraph strong type="small">
              <b>Qtd.</b>
            </Paragraph>
          </Div>
          <Div justify="flex-end">
            <Paragraph strong type="small">
              <b>Valor</b>
            </Paragraph>
          </Div>
          <Div justify="flex-end">
            <Paragraph strong type="small">
              <b>Valor Total</b>
            </Paragraph>
          </Div>
          <Divider style={{ gridColumn: '1 / -1', margin: '0px' }} />

          {data?.map((row, idx) => (
            <React.Fragment key={row?.id}>
              <Paragraph type="small" weight={fonts.weight500} $ellipsisClamp={2}>
                {row?.refurbishName}
              </Paragraph>
              <Div justify="flex-end">
                <Paragraph type="small" weight={fonts.weight500}>
                  {formatDecimalsDinamically(row?.quantity)}
                </Paragraph>
              </Div>
              <Div justify="flex-end">
                <Paragraph type="small" weight={fonts.weight500}>
                  {formatCurrency(row?.price, { currencySymbol: 'R$' })}
                </Paragraph>
              </Div>
              <Div justify="flex-end">
                <Paragraph type="small" weight={fonts.weight500}>
                  {formatCurrency(row?.totalValue, { currencySymbol: 'R$' })}
                </Paragraph>
              </Div>

              {idx !== data.length - 1 && <Divider style={{ gridColumn: '1 / -1', margin: '0px' }} />}
            </React.Fragment>
          ))}
        </Grid>
      }
    >
      <FontAwesomeIcon icon={icon} color={iconColor} {...props} />
    </Tooltip>
  );
};

TooltipGrid.propTypes = {
  icon: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Array),
  iconColor: PropTypes.string,
  className: PropTypes.string
};

const columns = ({
  changeRow = f => f,
  removeLine = f => f,
  renderTrashIcon,
  refurbishesIds,
  indexError,
  refurbishItemType,
  hasInstallmentPaid,
  isMobile
}) => {
  return [
    {
      title: 'Projeto*',
      dataIndex: ['refurbish', 'id'],
      key: 'refurbish',
      width: 80,
      render: (val, row, index) => {
        return (
          <Select
            disabled={hasInstallmentPaid}
            id="select-refurbish"
            model="refurbish"
            error={indexError?.refurbishError?.includes(index)}
            modelOptions={{
              where: { id: { notIn: refurbishesIds } },
              order: [['name', 'asc']]
            }}
            placeholder="Selecione um projeto"
            value={val}
            sendFullObject
            onChange={(id, data) => {
              return changeRow({ id, name: data?.label }, 'refurbish', index);
            }}
          />
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Apropriação',
      dataIndex: ['refurbish', 'idRefurbishItemLevel'],
      key: 'refurbish',
      width: 80,
      render: (val, row, index) => {
        return (
          <Select
            disabled={!row?.refurbish?.id || hasInstallmentPaid}
            name="idRefurbishItemLevel"
            id="idRefurbishItemLevel"
            model="refurbishItems"
            disabledText={!row?.refurbish?.id ? 'Adicione um projeto para habilitar a apropriação.' : null}
            modelOptions={{
              where: { type: refurbishItemType.parent, idRefurbish: row?.refurbish?.id, idParent: { eq: null } },
              order: [['name', 'asc']]
            }}
            prevValidateFields={['idRefurbish']}
            placeholder="Selecione"
            value={val}
            showArrow
            onChange={id => {
              return changeRow(
                { ...row?.refurbish, idRefurbishItemLevel: id },
                'refurbish',
                index,
                'idRefurbishItemLevel'
              );
            }}
            allowClear
            showSearch
            noHeight
            forceGet={row?.refurbish?.id}
          />
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Percentual*',
      dataIndex: 'percentageSplitValue',
      key: 'percentageSplitValue',
      width: 32,
      render: (val, row, index) => {
        return (
          <MaskedInput
            disabled={hasInstallmentPaid}
            type="number"
            id="installmentPercent"
            placeholder="0%"
            value={val ? formatCurrency(val) : null}
            onChange={e => {
              return changeRow(formatNumber(e.target.value), 'percentageSplitValue', index);
            }}
            maskOptions={{
              suffix: '%'
            }}
          />
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Valor*',
      dataIndex: 'value',
      key: 'value',
      placeholder: 'R$ 0,00',
      width: 52,
      render: (val, row, index) => {
        return (
          <>
            <MaskedInput
              disabled={hasInstallmentPaid}
              type="currency"
              error={indexError?.valueError?.includes(index)}
              id={`installmentValue-${index + 1}`}
              value={formatCurrency(val, {
                currencySymbol: 'R$ '
              })}
              onChange={e => {
                return changeRow(e.target.value, 'value', index);
              }}
              maskOptions={{
                prefix: 'R$',
                thousandsSeparatorSymbol: '.',
                allowDecimal: true
              }}
            />
          </>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      width: 12,
      render: (val, row, index) => {
        return renderTrashIcon && !hasInstallmentPaid ? (
          <TooltipIcon
            tooltipProps={{ placement: 'left' }}
            text="Excluir"
            icon={faTrashCan}
            iconProps={{
              size: 'lg',
              color: colors.neutral500
            }}
            style={isMobile ? { marginTop: spaces.space3 } : null}
            onClick={() => removeLine(index)}
          />
        ) : null;
      },
      shouldCellUpdate: () => true
    }
  ];
};

const apportionmentTotalsColumns = () => {
  return [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      width: 30,
      render: (val, row, index) => {
        return (
          <Div id={`apportionment-totals-code-${index + 1}`} $fullWidth>
            <Paragraph type="small">{row.code || '-'}</Paragraph>
          </Div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      render: (val, row, index) => {
        return (
          <Div id={`apportionment-totals-name-${index + 1}`} $fullWidth gap={spaces.space1}>
            <Paragraph type="small">{row.name || '-'}</Paragraph>
            <TooltipGrid
              className="checkCircle showHover"
              data={row?.parentList}
              icon={faCircleInfo}
              iconColor={colors.primary600}
            />
          </Div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Qtd.',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 32,
      render: (val, row, index) => {
        return (
          <Div id={`apportionment-totals-quantity-${index + 1}`}>
            <Paragraph type="small">{formatDecimalsDinamically(val || 0)}</Paragraph>
          </Div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Un.',
      dataIndex: 'idUnit',
      key: 'idUnit',
      width: 52,
      render: (val, row, index) => {
        const { units } = row;

        return (
          <Div id={`apportionment-totals-unit-${index + 1}`} $fullWidth>
            <Paragraph type="small">{units?.name || units?.label || '-'}</Paragraph>
          </Div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      width: 12,
      render: (val, row, index) => {
        const valFormatted = formatCurrency(val || 0, { currencySymbol: 'R$' });

        return (
          <Div id={`apportionment-totals-price-${index + 1}`} $fullWidth justify="end">
            <Paragraph type="small">{valFormatted}</Paragraph>
          </Div>
        );
      },
      shouldCellUpdate: () => true
    },
    {
      title: 'Valor total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 12,
      render: (val, row) => {
        const { price, quantity } = row;

        const total = formatCurrency(Number(price) * Number(quantity) || 0, { currencySymbol: 'R$' });

        return (
          <Div id="apportionment-totals-total" $fullWidth justify="end">
            <Paragraph type="small">
              <strong>{total}</strong>
            </Paragraph>
          </Div>
        );
      },
      shouldCellUpdate: () => true
    }
  ];
};

export { columns, apportionmentTotalsColumns };
