import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faXmark } from '@fortawesome/pro-solid-svg-icons';
import Text from 'antd/lib/typography/Text';
import Button from '../Button/Button';
import useViewport from '../../_Hooks/useViewport';
import InfoSidebar from '../Sidebar/InfoSidebar';
import PreviewFiles from '../Content/PreviewFiles';
import { getFileName, getFileType, isExtensionSupported } from '../../lib/helpers/helper';
import Modal from './Modal';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';
import { colors, fonts } from '../../styles/style';
import { ModalContainer } from './PreviewModal.style';

const checkFile = file => {
  let _file = file;

  if (typeof file === 'string')
    _file = {
      src: file,
      filename: getFileName(file)
    };
  else if (file?.fullpath && !file?.filename)
    _file = {
      src: file.fullpath,
      filename: getFileName(file.fullpath)
    };

  if (!_file?.filename) return [_file, null];

  const _fileType = getFileType(_file.filename).toLowerCase();
  return [_file, _fileType];
};

const PreviewModal = ({
  directionFile,
  file,
  onToggleModal = f => f,
  copyLinkParam,
  sideBarTab,
  size,
  hideSideBar,
  ...otherProps
}) => {
  const [_file, _fileType] = checkFile(file);

  const { isMobile } = useViewport(window.innerWidth);

  const { returnToOriginalUrl } = useReplaceUrl({
    preventTrigger: !copyLinkParam,
    urlToReplace: `/${copyLinkParam}/${file?.id}`
  });

  const CloseModalButton = () => <FontAwesomeIcon icon={faXmark} onClick={onToggleModal} color={colors.neutral300} />;

  return (
    <Modal
      open
      noPadding
      hideFooter
      onClose={e => {
        e.stopPropagation();
        returnToOriginalUrl();
        onToggleModal(false, e);
      }}
      style={{ display: 'flex' }}
      height={size || '100%'}
      width={size || '100%'}
      noBorderTop={isMobile()}
      closable={!isMobile()}
    >
      <ModalContainer $noSide={hideSideBar}>
        {isMobile() ? (
          <div className="preview-modal-content">
            <div className="preview-modal-file">
              {isExtensionSupported(_fileType) ? (
                <PreviewFiles fileType={_fileType} path={_file.src || file.fullpath || file.fullPath} />
              ) : (
                <Text style={{ fontSize: fonts.sizeLg }}> Pré-visualização não suportada </Text>
              )}
            </div>
            {!hideSideBar && (
              <InfoSidebar
                folder={{ shared: true }}
                data={_file}
                hasPadding={false}
                isModal
                model="file"
                isSupported={isExtensionSupported(_fileType)}
                selectedTab={sideBarTab}
                CloseModalButton={CloseModalButton}
                {...otherProps}
              />
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', height: '90vh' }}>
            <div className="preview-modal-content">
              {!!directionFile && (
                <div className="preview-modal-direction-button-container">
                  <Button type="primary" onClick={() => directionFile()}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </div>
              )}
              <div className="preview-modal-file">
                {isExtensionSupported(_fileType) ? (
                  <PreviewFiles fileType={_fileType} path={_file.src || file.fullpath || file.fullPath} />
                ) : (
                  <Text style={{ fontSize: fonts.sizeLg }}> Pré-visualização não suportada </Text>
                )}
              </div>
              {!!directionFile && (
                <div className="preview-modal-direction-button-container">
                  <Button type="primary" onClick={() => directionFile(true)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              )}
            </div>
            {!hideSideBar && (
              <InfoSidebar
                folder={{ shared: true }}
                data={_file}
                hasPadding={false}
                isModal
                model="file"
                isSupported={isExtensionSupported(_fileType)}
                selectedTab={sideBarTab}
                {...otherProps}
              />
            )}
          </div>
        )}
      </ModalContainer>
    </Modal>
  );
};

PreviewModal.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  directionFile: PropTypes.func,
  onToggleModal: PropTypes.func,
  fileType: PropTypes.string,
  showTabs: PropTypes.bool,
  copyLinkParam: PropTypes.string,
  sideBarTab: PropTypes.string,
  size: PropTypes.string,
  hideSideBar: PropTypes.bool
};

export default PreviewModal;
