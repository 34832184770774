import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Checkbox } from 'antd';
import Modal from './Modal';

import useCRUD from '../../_Hooks/useCRUD';
import { spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const fields = {
  description: 'Descrição',
  comments: 'Comentários',
  files: 'Galeria de fotos'
};

const CopyLastReportModal = ({ idRefurbish, currentReportId, onSubmit, onClose, modalWidth = 500 }) => {
  const [checkFields, setCheckFields] = useState({
    description: true,
    comments: true,
    files: true
  });

  const { list } = useCRUD({
    model: 'workReport',
    options: {
      where: {
        idRefurbish,
        id: { ne: currentReportId }
      },
      include: ['files'],
      limit: 1,
      order: [['createdAt', 'DESC']]
    }
  });

  const data = list[0];

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const values = {};

    Object.entries(checkFields).forEach(([key, checked]) => {
      if (checked) {
        values[key] = data?.[key];
      }
    });

    if (onSubmit) {
      onSubmit(values);
      onClose(false);
    } else {
      onClose(true);
    }
  };

  return (
    <Modal
      open
      center
      title="Copiar relatório"
      submitText="Copiar"
      onSubmit={handleSubmit}
      onClose={onClose}
      width={modalWidth}
    >
      <Space size={12} direction="vertical">
        <Paragraph>Copiar informações do último relatório # {data?.code}</Paragraph>
        {Object.entries(fields).map(([key, value]) => (
          <div style={{ gap: spaces.space2, display: 'flex' }}>
            <Checkbox
              name={value}
              checked={checkFields[key]}
              onChange={e => setCheckFields(prev => ({ ...prev, [key]: e.target.checked }))}
            />
            <Paragraph>{value}</Paragraph>
          </div>
        ))}
      </Space>
    </Modal>
  );
};

CopyLastReportModal.propTypes = {
  idRefurbish: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  modalWidth: PropTypes.number,
  currentReportId: PropTypes.string
};

export default CopyLastReportModal;
