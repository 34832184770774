import styled, { css } from 'styled-components';
import Text from 'antd/es/typography/Text';
import { fonts, spaces, colors, breakpoints } from '../../../styles/style';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';

const NameAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 ${spaces.space4} 0 ${spaces.space2};

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 ${spaces.space1};
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: ${spaces.space4};
`;

const TemplateInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 200px;
  padding: 0 ${spaces.space3} 0 ${spaces.space4};
  border-left: 1px solid ${colors.neutral100};
`;

const StyledTitle = styled(Text)`
  color: ${colors.neutral600};
  font-size: ${fonts.sizeSm};

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

const StyledSubTitle = styled.span`
  color: ${colors.neutral400};
  font-size: ${fonts.sizeSm};
`;

const ConfigButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${spaces.space1};
`;

const StyledTabs = styled(Tabs)`
  margin-top: ${spaces.space1};

  .ant-tabs-extra-content {
    display: flex;
    justify-content: flex-end;
    margin-left: ${spaces.space1};
    padding-bottom: 0;
    padding: 0;
  }
`;
const StyledTabPane = styled(TabPane)`
  background: ${colors.neutral100};
  padding: 0 3px 0 3px;
`;

const StyledDescription = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;

  ${props =>
    props.showAll === false &&
    css`
      -webkit-line-clamp: 6;
    `}

  p {
    color: ${colors.neutral500} !important;
    font-size: ${fonts.sizeSm};
    line-height: 18px;
  }
  -webkit-box-orient: vertical;
`;

const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.neutral400};
  font-size: ${fonts.sizeSm};

  svg {
    margin-right: ${spaces.space1};
  }
`;

export {
  NameAndDescriptionContainer,
  SubHeaderContainer,
  TemplateInfosContainer,
  StyledTitle,
  StyledSubTitle,
  ConfigButtonContainer,
  StyledTabPane,
  StyledTabs,
  StyledDescription,
  TypeContainer
};
