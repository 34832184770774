import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

import { Tooltip } from 'antd';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import useViewport from '../../_Hooks/useViewport';
import Tabs, { TabPane } from '../Tabs/Tabs';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';

import { Container, TabContent } from './EditItemModal.styles';
import { colors, Div, spaces } from '../../styles/style';
import CommentList from '../List/CommentList';
import ShareLinkDropdown from '../Dropdown/ShareLinkDropdown';
import Composition from '../Composition/Composition';
import Button from '../Button/Button';
import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import CompositionBreadcrumb from '../Breadcrumb/CompositionBreadcrumb';
import ConfirmModal from './ConfirmModal';
import Drawer from '../Drawer/Drawer';
import { getLinkedItemText } from '../../lib/helpers/helper';

const EditCompositionModal = ({
  id,
  idReference,
  initialValues,
  onClose = f => f,
  model = 'refurbish-items',
  readOnly,
  tab = '0',
  isTemplate,
  width = 960,
  groupedReference,
  renderColumns,
  idCompany,
  libraryProps,
  onlyLabor,
  parentIsComposition,
  hideAttachments = false,
  hideComments = false,
  ...props
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const [data, onChangeData] = useState({});
  const [extraDrawerStyle, setExtraDrawerStyle] = useState(0);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [editSupplierId, setEditSupplierId] = useState(null);
  const [showConfirmContextChangeModal, setShowConfirmContextChangeModal] = useState(false);
  const [reload, setReload] = useState();

  const { supplier } = data?.refurbishItem || data || {};
  const hideSupplier = !renderColumns?.idSupplier && isCustomer;
  const hasSupplier = supplier?.id && supplier?.id !== 1;

  const [files, setFiles] = useState([]);
  const [observation, setObservation] = useState('');
  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [triggerLink, setTriggerLink] = useState(false);
  const [editOrCreateComposition, setEditOrCreateComposition] = useState(false);
  const [_id, setId] = useState(id);
  const [touched, setTouched] = useState(false);
  const [preventSave, setPreventSave] = useState(false);

  const [breadcrumb, setBreadcrumb] = useState({ map: {}, list: [] });

  const [refreshComposition, setRefreshComposition] = useState(false);

  const { isMobile } = useViewport(window.innerWidth);

  const handleSaveBreadcrumb = dataToSave => {
    if (dataToSave?.id && dataToSave?.name) {
      const newList = breadcrumb.map[dataToSave?.id]
        ? breadcrumb.list?.map(breadcrumbItem =>
            breadcrumbItem?.id === dataToSave?.id ? { id: dataToSave?.id, name: dataToSave?.name } : breadcrumbItem
          )
        : [...breadcrumb.list, { id: dataToSave?.id, name: dataToSave?.name }];

      setBreadcrumb(prev => ({
        map: { ...prev.map, [dataToSave?.id]: true },
        list: newList
      }));
      setId(dataToSave?.id);
    }
  };

  const handleRemoveBreadcrumb = ({ indexToGo }) => {
    const newList = breadcrumb?.list?.slice(0, indexToGo);
    const last = breadcrumb?.list?.[indexToGo];
    const newMap = {};
    newList.forEach(el => {
      newMap[el] = true;
    });

    setBreadcrumb({ map: newMap, list: newList });
    setId(last?.id);
  };

  useEffect(() => {
    if (data?.id) {
      setFiles(data.files || []);
      setObservation(data.observation || '');
      handleSaveBreadcrumb(data);
    }
  }, [data]);

  useEffect(() => {
    if (typeof editOrCreateComposition === 'object') {
      if (touched) {
        setShowConfirmContextChangeModal({ onSubmit: handleSaveBreadcrumb, args: { ...editOrCreateComposition } });
      } else {
        setId(editOrCreateComposition?.id);
      }
    }
  }, [editOrCreateComposition]);

  useEffect(() => {
    if (showConfirmContextChangeModal && !touched) {
      const { onSubmit, args } = showConfirmContextChangeModal;
      onSubmit(args);
      setShowConfirmContextChangeModal(null);
    }
  }, [touched]);

  const CompositionComponent = () => (
    <>
      <CompositionBreadcrumb
        list={breadcrumb.list}
        onSelectItem={args => {
          return touched
            ? setShowConfirmContextChangeModal({ onSubmit: handleRemoveBreadcrumb, args })
            : handleRemoveBreadcrumb(args);
        }}
      />
      <Composition
        onChangeData={(resp, goBack) => {
          if (goBack) {
            handleRemoveBreadcrumb({ indexToGo: breadcrumb?.list?.length - 2 });
            return;
          }

          onChangeData(resp);
        }}
        id={_id || data?.id}
        isLibrary={libraryProps?.isLibrary}
        idReference={idReference}
        selectedTab={selectedTab}
        onClose={onClose}
        observation={observation}
        files={files}
        model={model}
        setExtraDrawerStyle={setExtraDrawerStyle}
        setSecondDrawer={setSecondDrawer}
        secondDrawer={secondDrawer}
        noPadding
        displayButtons={false}
        includeFiles={!isTemplate && !onlyLabor && model !== 'item'}
        groupedReference={groupedReference}
        initialValues={initialValues}
        renderColumns={renderColumns}
        readOnly={readOnly}
        isTemplate={isTemplate}
        idCompany={idCompany}
        setReload={setReload}
        externalTriggerProps={{
          triggerSubmit,
          triggerLink,
          setTriggerLink,
          setTriggerSubmit,
          refreshComposition,
          setRefreshComposition,
          setEditOrCreateComposition,
          setTouched,
          touched,
          setPreventSave
        }}
        onlyLabor={onlyLabor}
        parentIsComposition={parentIsComposition || breadcrumb.list?.length > 1}
        {...props}
      />
    </>
  );

  const drawerFooter = (
    <>
      <Button onClick={() => onClose(reload || libraryProps?.isLibrary)}>Cancelar</Button>
      <Div gap={spaces.space1}>
        {!libraryProps?.isLibrary && (_id || data?.id) && (
          <Tooltip title={getLinkedItemText(data?.item)}>
            <Button id="link-item" type="primary" ghost onClick={() => setTriggerLink(true)} loading={preventSave}>
              {`${data?.externalBaseCode || data?.idItem ? 'Desv' : 'V'}incular e Salvar`}
            </Button>
          </Tooltip>
        )}
        <Button id="submit-drawer" type="primary" onClick={() => setTriggerSubmit(true)} loading={preventSave}>
          Salvar
        </Button>
        {breadcrumb?.list?.length > 1 && (
          <Button
            id="submit-drawer-and-return"
            type="primary"
            onClick={() => setTriggerSubmit({ goBack: true })}
            loading={preventSave}
          >
            Salvar e voltar
          </Button>
        )}
      </Div>
    </>
  );

  return (
    <Drawer
      open
      title={id || data?.id ? 'Detalhes da composição' : 'Criar nova composição'}
      onClose={() => onClose(reload || libraryProps?.isLibrary)}
      width={isMobile() ? '100%' : width}
      hideFooter={isCustomer}
      footer={readOnly ? [] : drawerFooter}
      headerExtraContent={() =>
        isTemplate || readOnly || libraryProps?.isLibrary ? null : (
          <ShareLinkDropdown id={id || data?.id} whatsapp urlContext="o" style={{ marginLeft: spaces.space1 }} />
        )
      }
      style={{ transform: `translateX(-${extraDrawerStyle}px)` }}
      HowDoesItWorks={`${libraryProps?.isLibrary ? 'library-' : ''}edit-item-drawer`}
      padding={`0 ${spaces.space3}`}
    >
      <ConfirmModal
        open={!!showConfirmContextChangeModal && touched}
        onClose={() => {
          setShowConfirmContextChangeModal(null);
          setEditOrCreateComposition(null);
        }}
        alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
        title="Todos os dados não salvos serão perdidos"
        alertInfo="Ao trocar de composição, todos os dados não salvos serão perdidos"
        preInfoDescription="Deseja continuar?"
        onlySubmit
        onSubmit={() => {
          setTouched(false);
        }}
      />
      <Container>
        {libraryProps?.isLibrary ? (
          CompositionComponent()
        ) : (
          <Tabs
            activeKey={selectedTab}
            onChange={key => {
              setSelectedTab(key);
              setExtraDrawerStyle(0);
              setSecondDrawer(false);
            }}
          >
            <TabPane key="0" tab="Detalhes" forceRender>
              <TabContent>{CompositionComponent()}</TabContent>
            </TabPane>

            {idReference && data?.id && !isTemplate && !hideAttachments && (
              <TabPane key="1" tab="Anexos">
                <TabContent>
                  <ItemAttachmentContent
                    data={{ files, observation: observation || data.observation }}
                    setFiles={setFiles}
                    setObservation={setObservation}
                    readOnly={isCustomer}
                    idRefurbish={idReference}
                  />
                </TabContent>
              </TabPane>
            )}
            {data?.id && !hideSupplier && (
              <TabPane key="2" tab="Dados do fornecedor">
                <TabContent>
                  <Description
                    title="Dados bancários"
                    mapping={mappingDescriptionConf.bank}
                    column={3}
                    data={supplier || {}}
                    background={null}
                    noPadding
                  />
                  <Description
                    style={{ paddingTop: spaces.space2, borderTop: `1px solid ${colors.neutral100}` }}
                    title="Dados da empresa"
                    mapping={mappingDescriptionConf.supplier}
                    data={supplier || {}}
                    background={null}
                    noPadding
                    extra={
                      hasSupplier && !isCustomer ? (
                        <Button text onClick={() => setEditSupplierId(data?.supplier?.id)}>
                          <FontAwesomeIcon icon={faPenToSquare} color={colors.primary600} />
                        </Button>
                      ) : null
                    }
                  />
                </TabContent>
              </TabPane>
            )}
            {idReference && data?.id && !isTemplate && !hideComments && (
              <TabPane key="3" tab="Comentários">
                <TabContent>
                  <CommentList data={data} idRefurbish={idReference} model={model} isWithinDrawer />
                </TabContent>
              </TabPane>
            )}
          </Tabs>
        )}
      </Container>
      {editOrCreateComposition === true ? (
        <EditCompositionModal
          idReference={idReference}
          idCompany={idCompany}
          model={model}
          initialValues={{ idParent: _id }}
          parentIsComposition
          isTemplate={isTemplate}
          libraryProps={libraryProps}
          onClose={item => {
            if (item) setRefreshComposition(item);
            setEditOrCreateComposition(null);
          }}
        />
      ) : null}
      {hasSupplier && !hideSupplier && editSupplierId && (
        <EditOrCreateCustomerOrSupplier
          id={editSupplierId}
          model="supplier"
          onClose={() => {
            setEditSupplierId(null);
          }}
          setData={newSupplier => onChangeData(prev => ({ ...prev, supplier: newSupplier }))}
        />
      )}
    </Drawer>
  );
};

EditCompositionModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialValues: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  model: PropTypes.string,
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  isTemplate: PropTypes.bool,
  width: PropTypes.number,
  groupedReference: PropTypes.instanceOf(Object),
  renderColumns: PropTypes.instanceOf(Object),
  idCompany: PropTypes.number,
  libraryProps: PropTypes.instanceOf(Object),
  onlyLabor: PropTypes.bool,
  parentIsComposition: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  hideComments: PropTypes.bool
};

export default memo(EditCompositionModal);
