import { useCallback } from 'react';

const useTable = ({ list, ...props }) => {
  const moveRow = useCallback(
    (dragRow, dropRow) => {
      const { idParentPropName = 'idParent', isDefaultOrder = true, submitMove = f => f } = props;
      const _dragRow = dragRow?.record || {};
      const dragIsLevel = _dragRow.type === 3 || (idParentPropName.includes('Step') && !_dragRow?.[idParentPropName]);
      const dropIsLevel = dropRow?.type === 3 || (idParentPropName.includes('Step') && !dropRow?.[idParentPropName]);

      if (!isDefaultOrder) return;
      if (Object.keys(_dragRow)?.includes('tasks') && (dropRow?.id === 1 || _dragRow?.id === 1)) return;
      if (dragIsLevel && !dropIsLevel) return;

      // check if the item can move and if it is dropping at the same place
      if (_dragRow?.id === dropRow?.id) return;

      let dropRowIdParent = dropIsLevel ? dropRow.id : dropRow[idParentPropName] || null;

      // Does not allow change sub-level parent
      if (dragIsLevel) {
        dropRowIdParent = _dragRow?.[idParentPropName];
      }

      let beforeBoundary = 0;
      let afterBoundary = 0;

      // moving downwards
      if (_dragRow.order < dropRow.order) {
        beforeBoundary = dropRow.order || 0;
        afterBoundary = dropRow.nextOrder || Number(beforeBoundary) + 1;
      }
      // moving upwards
      else {
        afterBoundary = dropRow.order;
        beforeBoundary = dropRow.lastOrder || 0;
      }

      const order = (Number(beforeBoundary) + Number(afterBoundary)) / 2;

      submitMove({
        values: {
          id: dragRow.record.id,
          order,
          [idParentPropName]: dropRowIdParent
        },
        dragRow: dragRow.record,
        dropRow,
        dragIsLevel,
        dropIsLevel
      });
    },
    [list, props]
  );

  return {
    moveRow
  };
};

export default useTable;
