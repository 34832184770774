import React, { useEffect, useState } from 'react';
import { Input, Menu, Tooltip } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getInitials } from '../../lib/helpers/helper';
// hooks
import useCRUD from '../../_Hooks/useCRUD';
import { useContextHook as useRefurbish } from '../../contexts/Context';

import Avatar from '../AvatarEditor/Avatar';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import { colors, spaces } from '../../styles/style';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import { StyledMenu } from '../Dropdown/DropdownV2.styled';
import Label from '../Label/Label';

const MainContainer = styled.div`
  width: fit-content;
  margin: ${props => props.margin || '0'};
  margin-left: ${props => props.$marginLeft};
`;

const AddButton = styled(Button)`
  border: 1px dashed ${colors.neutral100} !important;
  border-radius: 20px;
  width: 32px;
  right: 12px;
  padding: 0;
  ${props => props.$addButtonTop && `top: ${props.$addButtonTop};`}
`;

const RemoveDiv = styled.div`
  position: relative;

  button {
    display: none;
  }

  &:hover {
    button {
      font-weight: 400;
      display: block;
      color: #fff;
      background-color: ${colors.neutral500};
      padding: 0px 4px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      z-index: 3;
      font-size: 5px;
      cursor: pointer;
      border: 1px solid white;
      height: ${spaces.space2};

      span {
        line-height: 1;
      }
    }
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  margin: ${props => (props.noMargin ? '0' : `${spaces.space1} ${spaces.space5} 0 0`)};
  position: relative;
  min-height: 30px;
  cursor: default;
`;

const ProjectResponsibleActions = ({ shouldUpdate = false, hideText, refurbish, margin, ...props }) => {
  const { data, handleGet, handleUpdate, handleDelete } = useRefurbish();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [search, setSearch] = useState();
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const [reload, setReload] = useState(0);
  const [list, setList] = useState([]);
  const history = useHistory();
  const _refurbish = data || refurbish;

  const hasEditPermission = hasPermission(user, ['manageProject'], plans, permissions);

  const { handleGet: getUsers } = useCRUD({
    model: 'user',
    immediatelyLoadData: false
  });

  const handleFetchUsers = () => {
    getUsers({
      refetchOptions: {
        where: { userType: 'provider', idCompany: _refurbish?.idCompany, isActive: true },
        order: [['name']],
        include: [{ model: 'refurbish', where: { id: _refurbish?.id }, required: false }]
      }
    }).then(_users => {
      setList(_users?.filter(u => u?.refurbishes?.length));
      setUsersDropdown(_users?.filter(u => !u?.refurbishes?.length));
    });
  };

  const deleteResponsible = (e, responsible) => {
    e.stopPropagation();
    handleDelete({
      id: _refurbish.id,
      deletePathOptions: `/user/${responsible?.id}`,
      refresh: false,
      noLoading: true
    }).then(() => {
      if (refurbish) {
        return handleFetchUsers();
      }
      if (shouldUpdate) {
        if (responsible.id === user.id && user.limitAccess) return history.push(`/profissional/projetos`);
        return handleGet({ generateLoading: false });
      }
      return setReload(prev => prev + 1);
    });
  };

  const addResponsible = (e, responsible) => {
    e.stopPropagation();
    handleUpdate({
      id: _refurbish.id,
      updatePathOptions: `/responsible/${responsible.id}`,
      refresh: false,
      noLoading: true
    }).then(() => {
      if (refurbish) {
        handleFetchUsers();
      } else if (shouldUpdate) {
        handleGet({ generateLoading: false });
      } else {
        setReload(prev => prev + 1);
      }
    });
  };

  const findUsersBySearch = () => {
    return usersDropdown?.filter(u => u.name?.toLowerCase()?.includes(search?.toLowerCase()));
  };

  useEffect(() => {
    if (_refurbish) {
      if (refurbish) {
        setList(_refurbish.users);
      } else {
        handleFetchUsers();
      }
    }
  }, [_refurbish, reload]);

  return (
    <MainContainer margin={margin} $marginLeft={list?.length > 1 ? spaces.space1 : '0'}>
      {hideText ? null : <Label>Colaboradores</Label>}
      <AvatarContainer noMargin={hideText} onClick={e => e.stopPropagation()}>
        <Avatar.Group style={{ zIndex: 1 }}>
          {list?.map(refurbishUser => (
            <div key={refurbishUser.id}>
              <Tooltip title={refurbishUser?.name}>
                <RemoveDiv key={`remove_${refurbishUser.id}`} style={{ position: 'relative' }}>
                  {list?.length > 1 && hasEditPermission && (
                    <Button
                      id={`delete-responsible${getInitials(refurbishUser?.name)}`}
                      onClick={e => deleteResponsible(e, refurbishUser)}
                    >
                      x
                    </Button>
                  )}
                  <Avatar
                    key={`avatar${refurbishUser.id}`}
                    src={refurbishUser.avatarFullpath}
                    $offset={!hasEditPermission}
                  >
                    {getInitials(refurbishUser?.name)}
                  </Avatar>
                </RemoveDiv>
              </Tooltip>
            </div>
          ))}
        </Avatar.Group>
        {hasEditPermission && (
          <div id="add-collaborator">
            <Dropdown
              trigger={['click']}
              slim
              menu={
                <StyledMenu>
                  <Menu.Item>
                    <div>
                      <Input.Search
                        value={search}
                        allowClear
                        type="text"
                        onChange={e => setSearch(e.target.value)}
                        id="search-responsible"
                      />
                    </div>
                  </Menu.Item>
                  {(search ? findUsersBySearch() : usersDropdown)?.map(refurbishUser => (
                    <Menu.Item key={refurbishUser.id}>
                      <Button text onClick={e => addResponsible(e, refurbishUser)}>
                        {refurbishUser.name}
                      </Button>
                    </Menu.Item>
                  ))}
                  <Menu.Item>
                    <Link to="/profissional/configuracoes/equipe">
                      <Button id="addCollaborator" text onClick={e => e.stopPropagation()}>
                        + Adicionar colaborador
                      </Button>
                    </Link>
                  </Menu.Item>
                </StyledMenu>
              }
              id="add-collaborator"
            >
              <Tooltip title="Adicionar membros da equipe" placement="topRight" align={{ offset: [26, -4] }}>
                <span style={{ padding: 0 }}>
                  <AddButton onClick={handleFetchUsers} {...props}>
                    {' '}
                    +{' '}
                  </AddButton>
                </span>
              </Tooltip>
            </Dropdown>
          </div>
        )}
      </AvatarContainer>
    </MainContainer>
  );
};

ProjectResponsibleActions.propTypes = {
  shouldUpdate: PropTypes.bool,
  hideText: PropTypes.bool,
  refurbish: PropTypes.instanceOf(Object),
  margin: PropTypes.string
};

export default ProjectResponsibleActions;
