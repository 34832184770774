import { HEADERNAME } from '../actions/action';

const DEFAULT_STATE = {};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case HEADERNAME:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
