import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';

import { EditContainer, StyledDiv } from './EditableInput.style';
import UseOutsideClick from '../../_Hooks/useOutsideClick';

const EditableInputBuilder = ({
  icon = false,
  value,
  noWrap = false,
  disabled,
  initEdit,
  unit,
  placeholder,
  id,
  forceShow,
  InputComponent,
  ...props
}) => {
  const [_value, setValue] = useState(value || '-');
  const [edit, setEdit] = useState(initEdit);
  const ref = useRef();

  useEffect(() => {
    setValue(value);
  }, [value]);

  UseOutsideClick(ref, () => {
    setTimeout(() => {
      setEdit(false);
    }, 500);
  });

  if ((!disabled && edit) || forceShow) {
    return (
      <EditContainer ref={ref}>
        <InputComponent
          handleBlur={() => {
            setEdit(false);
          }}
        />
      </EditContainer>
    );
  }

  return (
    <StyledDiv
      id={id}
      role="presentation"
      disabled={disabled}
      noWrap={noWrap}
      onClick={e => {
        e.stopPropagation();
        setEdit(true);
      }}
      {...props}
    >
      {_value || placeholder || '-'}
      {unit || ''}
      {icon && <FontAwesomeIcon icon={faPencilAlt} size="xs" />}
    </StyledDiv>
  );
};

EditableInputBuilder.propTypes = {
  icon: PropTypes.bool,
  value: PropTypes.string,
  noWrap: PropTypes.bool,
  disabled: PropTypes.bool,
  initEdit: PropTypes.bool,
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  forceShow: PropTypes.bool,
  withError: PropTypes.bool,
  InputComponent: PropTypes.func
};

export default EditableInputBuilder;
