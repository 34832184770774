import * as Yup from 'yup';
import { name, isActive } from '../schema';

const financialCategorySchema = Yup.object().shape({
  name,
  isActive
});

const typeItems = [
  { label: 'Receitas', value: 'income' },
  { label: 'Despesas', value: 'expense' }
];

const financialCategoryMapping = ({
  id,
  billType = 'income',
  levelList,
  hasChildren,
  disableEditBillType,
  dreList
}) => {
  return {
    name: {
      name: 'Nome da categoria*',
      type: 'text',
      placeholder: 'Informe a descrição',

      xs: 24,
      md: 12
    },
    ...(!id && {
      billType: {
        name: 'Tipo*',
        type: 'select',
        dropdownStyle: { zIndex: 1500 },
        xs: 24,
        md: 12,
        options: typeItems,
        defaultValue: billType,
        disabled: disableEditBillType
      }
    }),
    idParent: {
      name: 'Categoria mãe',
      type: 'treeSelect',
      xs: 24,
      md: 12,
      placeholder: 'Selecione a categoria mãe',
      dropdownStyle: { zIndex: 1500 },
      isParentMap: true,
      parentMapOptions: { selectable: true },
      withoutEditableDiv: true,
      levelList,
      disabled: hasChildren,
      disabledText: 'Para mover uma categoria ela não pode ter nenhuma categoria filha.',
      allowClear: true
    },
    idDRE: {
      name: 'Grupo do DRE',
      type: 'treeSelect',
      xs: 24,
      md: 12,
      placeholder: 'Selecione o Grupo DRE',
      dropdownStyle: { zIndex: 1500 },
      isParentMap: true,
      withoutEditableDiv: true,
      levelList: dreList,
      disabled: hasChildren,
      tooltipText: 'Selecione em qual grupo os lançamentos feitos nessa categoria devem aparecer no DRE',
      disabledText: 'Somente categorias que não tenham subcategorias podem ser relacionadas ao DRE.'
    }
  };
};

export { financialCategorySchema, financialCategoryMapping };
