import * as Yup from 'yup';

import { name } from '../schema';
import { spaces } from '../../../styles/style';

const projectSchema = () =>
  Yup.object().shape({
    name: name.min(3, 'Nome deve ter pelo menos 3 caracteres')
  });

const projectMapping = ({ isModal, type, isOpportunity }) => ({
  name: {
    name: `Nome ${isOpportunity ? 'da oportunidade' : 'do Projeto'}`,
    type: 'text',
    placeholder: `Escreva aqui`,
    md: 24
  },
  description: {
    name: `Descrição ${isOpportunity ? 'da oportunidade' : 'do Projeto'}`,
    type: 'textarea',
    placeholder: `Escreva aqui`,
    style: { heigth: '160px' },
    md: 24,
    rows: !isModal ? 7 : null
  },
  ...(isModal &&
    type !== 1 && {
      fieldsHeader: {
        type: 'fixed',
        md: 24
      },
      hasSchedule: {
        type: 'checkbox',
        name: 'Cronograma',
        md: 4,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1 }
      },
      hasBudget: {
        type: 'checkbox',
        name: 'Orçamento',
        md: 4,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1 }
      },
      hasPlanning: {
        type: 'checkbox',
        name: 'Planejamento',
        md: 4,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1 }
      },
      hasFolders: {
        type: 'checkbox',
        name: 'Pastas',
        md: 4,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1 }
      },
      hasNotes: {
        type: 'checkbox',
        name: 'Anotações',
        md: 4,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1 }
      }
    })
});

export { projectSchema, projectMapping };
