import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, fonts, radius } from '../../styles/style';
import { getMinutesFromHHMM, toHHMM } from '../../lib/helpers/helper';
import Input from './Input';

const StyledMaskInput = styled(Input)`
  ${props =>
    props.$noBorder &&
    css`
      border: none;
    `}

  &:focus {
    box-shadow: none;
    border: 1px solid ${colors.neutral200};
  }

  &:hover {
    border: 1px solid ${colors.neutral500};
    cursor: text;
    border-radius: ${radius.radius1};
  }

  &:focus {
    background-color: white;
  }

  border-radius: ${radius.radius1};
  transition: all 0.3s;
  width: 100%;
  padding: 4px 11px;
  background-color: transparent;
  font-size: ${fonts.sizeSm};
  font-weight: ${fonts.weight500};
  color: ${colors.neutral600};
`;

const MaskedInput = ({
  value,
  onBlur = () => {},
  style,
  type = 'tel,',
  readOnly,
  id,
  placeholder = '',
  alwaysTriggerBlur = false,
  formatValue,
  onlyPlaceholder,
  onChange = f => f,
  ...props
}) => {
  const [_value, setValue] = useState();
  const [focus, setFocus] = useState(false);

  const handleBlur = e => {
    if (formatValue) {
      const formattedValue = formatValue(e.target.value);
      onBlur(formattedValue);
      onChange(formattedValue);
      setValue(formattedValue);
      return;
    }
    const newValue = getMinutesFromHHMM(e.target.value);
    const minutes = Math.max(0, newValue);
    if (minutes) setValue(toHHMM(minutes));
    setFocus(false);

    if (newValue !== (value || 0) || alwaysTriggerBlur) {
      onBlur(minutes || null);
    }
  };

  const handleKey = e => {
    if (e.key === 'Enter') {
      e.target.blur();
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    setValue(formatValue ? formatValue(value) : toHHMM(value));
  }, [value]);

  return !readOnly ? (
    <StyledMaskInput
      id={id}
      style={style}
      placeholder={focus && !onlyPlaceholder ? '0:00' : placeholder}
      onFocus={() => setFocus(true)}
      type={type}
      onKeyDown={handleKey}
      onChange={e => setValue(e.target?.value)}
      value={_value}
      onBlur={handleBlur}
      onClick={e => e.stopPropagation()}
      {...props}
    />
  ) : (
    <span>{_value || placeholder}</span>
  );
};

MaskedInput.propTypes = {
  style: PropTypes.instanceOf(Object),
  onBlur: PropTypes.func,
  value: PropTypes.number,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  alwaysTriggerBlur: PropTypes.bool,
  formatValue: PropTypes.func,
  onlyPlaceholder: PropTypes.bool,
  onChange: PropTypes.func
};

export default MaskedInput;
