import { Divider } from 'antd';
import styled from 'styled-components';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';

const StyledDivider = styled(Divider)`
  margin: 0 -${spaces.space1} 0 -${spaces.space1};
`;

const StyledCardItem = styled.div`
  position: relative;
  display: flex;
  margin-left: -${spaces.space2};
  margin-right: -${spaces.space2};
  padding: ${spaces.space1} ${spaces.space2};
  border-top: 1px solid ${colors.neutral100};
  background-color: ${colors.neutral75};
  :hover {
    cursor: ${props => (props.$hasClick ? 'pointer' : 'auto')};
    background-color: ${colors.neutral100};
  }
`;

const Name = styled.p`
  font-weight: 400;
  height: ${spaces.space5};
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${colors.neutral700};
  font-size: ${fonts.sizeSm};
  line-height: 18px;
`;

const Price = styled.p`
  display: flex;
  font-weight: 400;
  font-size: ${fonts.sizeSm};
  color: ${colors.primary600};
  line-height: 18px;
  flex: auto;
  span {
    color: ${colors.neutral600};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spaces.space1};
  line-height: 16px;
  flex: auto;
`;

const Type = styled.p`
  font-size: ${fonts.sizeSm};
  font-weight: 400;
  color: ${colors.neutral400};
`;

const StyledText = styled.span`
  font-size: ${fonts.sizeLg};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 ${spaces.space2};
    font-size: ${fonts.sizeSm};
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const ImageBox = styled.div`
  min-width: ${spaces.space9};
  min-height: ${spaces.space9};
  display: flex;
  background-color: ${colors.white};
  border-radius: ${radius.radius1};
  justify-content: center;
`;

export { StyledDivider, StyledCardItem, Name, Price, Content, Type, StyledText, ImageBox };
