import React from 'react';
import PropTypes from 'prop-types';

const Document = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 39" version="1.1">
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="06-06" transform="translate(-875.000000, -455.000000)">
          <g id="icon-2" transform="translate(876.000000, 456.000000)">
            <g id="icon">
              <path
                d="M2.86363636,1.63043478 C2.41281818,1.63043478 2.04545455,1.99565217 2.04545455,2.44565217 L2.04545455,17.1195652 L0.409090909,17.1195652 L0.409090909,2.44565217 C0.409090909,1.09728261 1.51036364,0 2.86363636,0 L20.8636364,0 C21.0804545,0 21.2890909,0.0855978261 21.4420909,0.238858696 L31.2602727,10.0214674 C31.4140909,10.173913 31.5,10.3809783 31.5,10.5978261 L31.5,35.0543478 C31.5,36.4027174 30.3987273,37.5 29.0454545,37.5 L2.86363636,37.5 C1.51036364,37.5 0.409090909,36.4027174 0.409090909,35.0543478 L0.409090909,27.9021739 L2.04545455,27.9021739 L2.04545455,35.0543478 C2.04545455,35.5043478 2.41281818,35.8695652 2.86363636,35.8695652 L29.0454545,35.8695652 C29.4962727,35.8695652 29.8636364,35.5043478 29.8636364,35.0543478 L29.8630909,11.413 L20.8636364,11.4130435 C20.412,11.4130435 20.0454545,11.0486413 20.0454545,10.5978261 L20.0454545,2.08745745 C20.0454545,1.8350508 20.2461048,1.63043478 20.4925981,1.63043478 L2.86363636,1.63043478 Z M28.7060909,9.782 L21.6810909,2.783 L21.6818182,9.7826087 L28.7060909,9.782 Z"
                id="Combined-Shape"
                stroke="#FFFFFF"
                strokeWidth="0.9"
                fill="#2E92FF"
                opacity="0.900000036"
              />
              <path
                d="M25.2777778,16.3043478 C25.6764444,16.3043478 26,16.66875 26,17.1195652 L26,27.7173913 C26,28.1682065 25.6764444,28.5326087 25.2777778,28.5326087 L0.722222222,28.5326087 C0.323555556,28.5326087 0,28.1682065 0,27.7173913 L0,17.1195652 C0,16.66875 0.323555556,16.3043478 0.722222222,16.3043478 L25.2777778,16.3043478 Z"
                id="base"
                stroke="#FFFFFF"
                strokeWidth="0.9"
                fillOpacity="0.1"
                fill="#2E92FF"
              />
              <text
                id=".doc"
                fontFamily="Muli-Regular, Muli"
                fontSize="4"
                fontWeight="normal"
                letterSpacing="0.0363636389"
                fill="#278CFD"
              >
                <tspan x="6.5" y="24">
                  .DOC
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Document.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Document.defaultProps = {
  width: 33,
  height: 39
};

export default Document;
