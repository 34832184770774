import styled, { css } from 'styled-components';
import { Drawer, Space } from 'antd';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';

const StepContent = styled.div`
  max-width: 980px;
  width: 100%;
  margin: auto;

  &.footer {
    display: flex;
    justify-content: space-between;
  }
`;

const RegisterDrawer = styled(Drawer)`
  .ant-drawer-header {
    width: 100%;

    .ant-drawer-header-title {
      margin: auto;
      flex-direction: row-reverse;
      align-items: center;
      max-width: 980px;
    }

    .empty-span {
      width: 200px;
    }
  }
  .ant-drawer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${fonts.family};
    font-size: ${fonts.sizeLg};
    font-weight: ${fonts.weight600};
    color: ${colors.neutral700};
  }

  .ant-drawer-body {
    padding: 0;
  }
`;

const Line = styled.hr`
  width: ${spaces.space7};
  border-width: 1px;
  border-color: ${colors.neutral100};
  margin-right: 12px;
`;

const ContentBox = styled(Space)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: ${spaces.space2};
  padding-top: ${spaces.space2};
`;

const Register = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space2};
  padding-top: ${spaces.space4};
  @media (max-width: ${breakpoints.tablet}) {
    padding-top: ${spaces.space0};
  }
`;

const ShareLink = styled(Div)`
  margin-right: auto;
  color: ${colors.primary500};
  border: 1px solid ${colors.neutral100};
  border-radius: ${spaces.space0};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  box-sizing: border-box;
  padding: 5px;
  ${props =>
    props.$pixStyle &&
    css`
      max-width: 296px;
    `}
`;

const WhatsappSend = styled.a``;

const ExtraDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.space1};
`;

const ApprovedDocs = styled.div`
  display: flex;
  padding-top: ${spaces.space4};
  flex-direction: column;
`;

const Approved = styled(Div)`
  gap: ${spaces.space2};
  padding-top: ${spaces.space2};
  padding-left: ${spaces.space1};
  span {
    line-height: ${spaces.space2};
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral500};
  }
`;

const HowDoesButton = styled(HowDoesItWorksButton)`
  padding-right: ${spaces.space4};
`;

const DivCheckbox = styled.div`
  padding-left: ${spaces.space1};
  .ant-checkbox-wrapper {
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral500};
    line-height: ${spaces.space2};
  }
  @media (max-width: ${breakpoints.tablet}) {
    padding-left: 0px;
  }
`;

export {
  RegisterDrawer,
  StepContent,
  Line,
  ContentBox,
  WhatsappSend,
  ShareLink,
  ExtraDocuments,
  ApprovedDocs,
  Approved,
  Register,
  HowDoesButton,
  DivCheckbox
};
