import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

// utils
import { sumByField } from '../../lib/helpers/helper';
import formatCurrency from '../../lib/helpers/formatCurrency';

// style
import style from './GroupHeader.module.scss';

// components
import GroupCostCenterUnitDrawer from '../Drawer/GroupCostCenterUnitDrawer';

const GroupHeader = ({ group, icon, refetch = () => {}, allowEdit, grouped, showTotal = true }) => {
  const [showGroupModal, setShowGroupModal] = useState(false);

  const handleGroupModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowGroupModal(true);
  };

  const groupItems = group?.item ? group.item : null;
  const sum = groupItems ? sumByField(groupItems, 'total') : 0;
  const greaterThanOne = groupItems && groupItems.length > 1;

  return (
    <div className={style['table-heading']}>
      <div className={style['table-heading-actions']}>
        <h5>{group.name || ''}</h5>
        {allowEdit ? <FontAwesomeIcon icon={faEdit} size="sm" color="#4E5772" onClick={handleGroupModal} /> : <div />}
      </div>

      <div className={style['table-heading-actions']}>
        {groupItems && showTotal && (
          <div>
            <div className="hideMobile">
              {`Total do${greaterThanOne ? 's' : ''} ite${greaterThanOne ? 'ns' : 'm'}:`}{' '}
            </div>
            <b>{formatCurrency(sum, { currencySymbol: `R$` })}</b>
          </div>
        )}
        <FontAwesomeIcon icon={icon} size="lg" color="#4E5772" />
      </div>
      {showGroupModal && (
        <GroupCostCenterUnitDrawer
          id={group.id}
          onClose={() => setShowGroupModal(false)}
          setData={() => refetch(grouped.model)}
          model={grouped.model}
        />
      )}
    </div>
  );
};

GroupHeader.propTypes = {
  group: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func,
  allowEdit: PropTypes.bool,
  grouped: PropTypes.instanceOf(Object),
  showTotal: PropTypes.bool
};

export default GroupHeader;
