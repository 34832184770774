import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
// context
import { toast } from 'react-toastify';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { ComponentToPdf } from '../../../lib/helpers/exportToPdf';
import { workReportSchema, workReportMapping } from '../../../lib/mapping/Form/workReportSchema';
import useCRUD from '../../../_Hooks/useCRUD';
import useUrlParams from '../../../_Hooks/useUrlParams';
import useViewport from '../../../_Hooks/useViewport';

// components
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import CopyLastReportModal from '../../../components/Modal/CopyLastReportModal';
import Button from '../../../components/Button/Button';
import Form from '../../../components/Form/Form';
import { addressConcat, formatPhoneNumber } from '../../../lib/helpers/helper';
import WorkDiaryEventService from '../../../lib/gtm/workReport';
import ShareModuleModal from '../../../components/Modal/ShareModuleModal';
import WeatherSelect from '../../../components/Select/WeatherSelect';
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import RichTextViewer from '../../../components/RichTextEditor/RichTextViewer';
import WorkReportComments from './WorkReportComments';
import {
  Header,
  ButtonsContainer,
  ReportInfo,
  SideContainer,
  TopInfo,
  CompanyInfo,
  StatusContainer,
  HideOnPrint,
  MainContainer,
  CustomerInfo
} from './WorkReport.style';
import Modal from '../../../components/Modal/Modal';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import EditOrCreateCustomerOrSupplier from '../../../components/Drawer/EditOrCreateCustomerOrSupplier';
import { Line } from '../../../components/Line/Line';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import AttachmentSection from '../../../components/Sections/AttachmentSection';
import Footer from '../../../components/Footer/Footer';
import { Div, colors, spaces } from '../../../styles/style';
import { saveHeaderName } from '../../../store/headerName/actions/action';
import eventBus from '../../../lib/helpers/eventBus';

const gtmEventService = WorkDiaryEventService();

const defaultWeather = {
  morning: { checked: false, weather: 'clean', status: 'practicable' },
  afternoon: { checked: false, weather: 'clean', status: 'practicable' },
  night: { checked: false, weather: 'clean', status: 'practicable' }
};

const WorkReport = ({ isView, isPublic, _idRefurbish }) => {
  const { userType, refurbishStatus, workReportStatus } = useSelector(state => state.setup.enums) || {};
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authReducer) || {};
  const headerName = useSelector(state => state.headerNameReducer);
  const history = useHistory();
  const [showCopyReportModal, setShowCopyReportModal] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [_data, setData] = useState({ date: dayjs() });
  const [isValid, setIsValid] = useState(false);
  const [showCustomerDrawer, setCustomerDrawer] = useState(false);
  const [reload, setReload] = useState(false);
  const [weather, setWeather] = useState(defaultWeather);
  const [images, setImages] = useState([]);
  const [formDescription, setFormDescription] = useState('');
  const [showExportNotAllowedModal, setShowExportNotAllowedModal] = useState(false);

  const { id } = useParams();
  const forceFormUpdate = useRef(false);
  const { finished } = workReportStatus;

  const printComponentRef = useRef();

  const { foundParams } = useUrlParams({
    urlParams: ['projeto', 'print', 'version']
  });

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => {
      if (foundParams.print) foundParams.print = false;
      setReload(true);
    }
  });

  const idRefurbish = useMemo(
    () => _data?.idRefurbish || _idRefurbish || (foundParams?.projeto && Number(foundParams?.projeto)),
    [_data, foundParams]
  );

  const { data, loading: loadingData, handleCreate, handleUpdate, handleGet: getWorkReport } = useCRUD({
    model: `work-report${isPublic ? '/public' : ''}`,
    pathOptions: id ? `/${id}` : '',
    options: {
      where: { idRefurbish },
      include: [
        'files',
        ...(isView
          ? [
              { model: 'company', attributes: ['logo', 'name', 'city', 'street', 'state', 'complement', 'number'] },
              {
                model: 'refurbish',
                include: ['companyCustomer'],
                attributes: [
                  'idCompanyCustomer',
                  'name',
                  'id',
                  'version',
                  'street',
                  'number',
                  'complement',
                  'state',
                  'city'
                ]
              }
            ]
          : [])
      ],
      limit: 1
    },
    immediatelyLoadData: !!id
  });

  const { handleGet: getTotalItems } = useCRUD({
    model: 'work-report',
    immediatelyLoadData: false
  });

  const { refurbish, company, createdBy } = _data || { _data };

  const { companyCustomer } = refurbish || {};

  const isCustomer = user?.userType === userType?.customer?.value;
  const isLoading = loadingData || (id && !_data?.id);
  const showEdit = !isCustomer && !isPublic;

  const redirectToProject = foundParams?.projeto ? `?projeto=${idRefurbish}` : '';

  const baseUrl = '/profissional/diario-de-obra';
  const title = { name: '', pathName: '' };

  if (isView) {
    title.name = 'Relatório';
    title.pathName = `${baseUrl}/visualizar/`;
  } else {
    title.name = id ? 'Editar relatório' : 'Novo relatório';
    title.pathName = id ? `${baseUrl}/editar/` : `${baseUrl}/novo/`;
  }

  const clientInfoList = useMemo(
    () => [
      {
        key: 'customerPhone',
        label: `${companyCustomer?.name} ${
          companyCustomer?.phone ? `- ${formatPhoneNumber(companyCustomer?.phone)}` : ''
        }`,
        condition: companyCustomer?.name
      },
      {
        key: 'customerEmail',
        label: companyCustomer?.email,
        condition: companyCustomer?.email
      },
      {
        key: 'customerDoc',
        label: `CPF/CNPJ: ${companyCustomer?.doc}`,
        condition: companyCustomer?.doc
      },
      {
        key: 'customerAddress',
        label: addressConcat(companyCustomer),
        condition: addressConcat(companyCustomer)
      }
    ],
    [_data]
  );

  const companyInfoList = useMemo(
    () => [
      {
        key: 'phone',
        label: formatPhoneNumber(company?.phone),
        condition: company?.phone
      },
      {
        key: 'email',
        label: company?.email,
        condition: company?.email
      },
      {
        key: 'cnpj',
        label: `CPF/CNPJ: ${company?.cnpj}`,
        condition: company?.cnpj
      },
      {
        key: 'address',
        label: addressConcat(company),
        condition: addressConcat(company)
      }
    ],
    [_data]
  );

  const workReportName = `Relatório Diário de Obra ${_data?.code ? `#${_data?.code}` : ''}`;

  const printWorkReport = () => {
    if (_isMobile) {
      setShowExportNotAllowedModal(true);
      return;
    }
    if (!isLoading) {
      handlePrint();
    }
  };

  useEffect(() => {
    if (reload) {
      getWorkReport();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (headerName?.name !== title.name) {
      dispatch(saveHeaderName({ name: title.name, pathName: [title.pathName] }));
    }
  }, []);

  useEffect(() => {
    if (isCustomer && data) {
      gtmEventService.onAccessed(data, user?.id);
    }

    if (data?.id) {
      setData(data);
      setWeather(data?.weather || defaultWeather);
      setImages(data?.images?.length ? data?.images : data?.imagesUpdateUrl || []);
      setFormDescription(data?.description);
      setCommentList(data?.comments);
      setFileList(data?.files);
      forceFormUpdate.current = true;
    }
  }, [data]);

  useEffect(() => {
    if (!id && idRefurbish) {
      getTotalItems({
        refetchOptions: {
          where: {
            id: { ne: id },
            idRefurbish
          },
          attributes: ['id']
        }
      }).then(resp => {
        if (resp?.error) return;
        setData(prev => ({ ...prev, code: idRefurbish ? (resp?.length || 0) + 1 : null, idRefurbish }));
        forceFormUpdate.current = true;
      });
    }
  }, [idRefurbish]);

  useEffect(() => {
    if (foundParams?.print && !isLoading) {
      printWorkReport();
    }
  }, [foundParams?.print, isLoading]);

  const copyLastReport = newData => {
    const { description, comments = [], files = [] } = newData || {};
    setFormDescription(description);
    setCommentList(prev => [...prev, ...comments]);
    setFileList(prev => [...prev, ...files]);

    forceFormUpdate.current = true;
  };

  const notifyCustomer = reportId => handleCreate({ postPathOptions: `/notify/${reportId}` });

  const createOrUpdateReport = isFinished => {
    if (!isValid) {
      toast.error('Projeto não informado');
      return;
    }

    const values = {
      ..._data,
      weather,
      description: formDescription,
      images,
      idRefurbish,
      comments: commentList,
      files: fileList,
      ...(isFinished && { status: finished.value })
    };

    const func = id
      ? handleUpdate({ values, refresh: false, displayToast: true })
      : handleCreate({
          values,
          postOptions: {
            include: ['files']
          },
          displayToast: isFinished ? 'Relatório criado.' : true,
          refresh: false
        });

    func.then(resp => {
      if (resp?.error || !resp?.id) return;
      if (isFinished) {
        eventBus.dispatch('updateGuide');
        notifyCustomer(resp?.id);
        gtmEventService.onReleased(resp);
        history.push(`/profissional/diario-de-obra/visualizar/${resp?.id}${redirectToProject}`);
        return;
      }
      history.push(`/profissional/diario-de-obra/editar/${resp?.id}${redirectToProject}`);
    });
  };

  const handleEditorChange = (content, imagesArray) => {
    setImages(imagesArray?.filter(image => image && content?.includes(image)));
    setFormDescription(content);
  };

  return (
    <>
      <Div direction="column" justify="space-between" $fullWidth $fullHeight align="flex-start">
        <ComponentToPdf ref={printComponentRef} style={{ width: '100%' }}>
          <MainContainer id="work-report-body" padding={`0 0 ${spaces.space2}`}>
            {isLoading ? (
              <CenteredLoader />
            ) : (
              <>
                {!isView && (
                  <>
                    <Header>
                      {id && showEdit && !_isMobile ? (
                        <>
                          <Paragraph>
                            Status:{' '}
                            <Paragraph color={colors[workReportStatus[_data?.status]?.color]}>
                              {workReportStatus[_data?.status]?.label}
                            </Paragraph>
                          </Paragraph>
                          <Paragraph>
                            Criado por: {createdBy?.name} ({dayjs(_data?.createdAt).format('DD/MM/YYYY [às] HH[h]mm')})
                          </Paragraph>
                        </>
                      ) : (
                        <>
                          <Paragraph>
                            Criado em: ({dayjs(_data?.createdAt).format('DD/MM/YYYY [às] HH[h]mm')})
                          </Paragraph>
                          {!_isMobile && (
                            <Button type="primary" ghost onClick={() => setShowCopyReportModal(true)}>
                              Copiar informações do último relatório
                            </Button>
                          )}
                        </>
                      )}
                    </Header>
                    {!_isMobile && <Line />}
                  </>
                )}
                {isView ? (
                  <ReportInfo>
                    <TopInfo>
                      <CompanyInfo>
                        {company?.logo && (
                          <img
                            crossOrigin="anonymous"
                            alt={company?.name}
                            src={`${company?.logo}&t=${new Date().getTime()}`}
                          />
                        )}
                        <SideContainer>
                          <Div gap={spaces.space1} style={{ marginBottom: spaces.space2 }}>
                            <Subtitle>{company?.name}</Subtitle>
                            {showEdit && (
                              <TooltipIcon
                                style={{ color: colors.primary600 }}
                                text="Editar informações da empresa"
                                icon={faPenToSquare}
                                onClick={() => history.push('/profissional/configuracoes/meu-negocio')}
                              />
                            )}
                          </Div>
                          {companyInfoList.map(({ key, label, condition }) => (
                            <React.Fragment key={key}>{!!condition && <Paragraph>{label}</Paragraph>}</React.Fragment>
                          ))}
                        </SideContainer>
                      </CompanyInfo>
                      {companyCustomer && (
                        <CustomerInfo>
                          <Div gap={spaces.space1} style={{ marginBottom: spaces.space2 }}>
                            <Subtitle>Informações do cliente</Subtitle>
                            {showEdit && (
                              <TooltipIcon
                                style={{ color: colors.primary600 }}
                                text="Editar informações do cliente"
                                icon={faPenToSquare}
                                onClick={() => setCustomerDrawer(true)}
                              />
                            )}
                          </Div>
                          {clientInfoList.map(({ key, label, condition }) => (
                            <React.Fragment key={key}>{!!condition && <Paragraph>{label}</Paragraph>}</React.Fragment>
                          ))}
                        </CustomerInfo>
                      )}
                    </TopInfo>
                    <Line />
                    <SideContainer>
                      <Subtitle>{workReportName}</Subtitle>
                      <Div>
                        <Paragraph>
                          <strong>{refurbish?.name}</strong> - {addressConcat(refurbish)}
                        </Paragraph>
                      </Div>
                      <StatusContainer>
                        <Paragraph>
                          <strong>Data:</strong> {dayjs(_data?.date).format('DD/MM/YYYY')}
                        </Paragraph>
                        {!isCustomer && (
                          <HideOnPrint>
                            <Paragraph>
                              Status:{' '}
                              <Paragraph color={colors[workReportStatus[_data?.status]?.color]}>
                                {workReportStatus[_data?.status]?.label}
                              </Paragraph>
                            </Paragraph>
                            <Paragraph>
                              Criado por: {createdBy?.name} ({dayjs(_data?.createdAt).format('DD/MM/YYYY [às] HH[h]mm')}
                              )
                            </Paragraph>
                          </HideOnPrint>
                        )}
                      </StatusContainer>
                    </SideContainer>
                    <Line />
                  </ReportInfo>
                ) : (
                  <>
                    <SideContainer>
                      {!_isMobile && <Subtitle>Informações do relatório</Subtitle>}
                      <Form
                        loading={isLoading}
                        schema={workReportSchema}
                        forceUpdate={forceFormUpdate}
                        mapping={() =>
                          workReportMapping({ idStatus: refurbishStatus?.execution?.id, weather: _data.weather })
                        }
                        data={_data}
                        onFormChange={setData}
                        displayButtons={false}
                        isFormValid={setIsValid}
                        margin="0"
                      />
                    </SideContainer>
                    <Line />
                  </>
                )}

                {(!isView || Object.values(weather).find(w => w.checked)) && (
                  <>
                    <SideContainer $printSpacing>
                      <Subtitle>Condições climáticas</Subtitle>
                      <WeatherSelect weather={weather} setWeather={setWeather} isView={isView} />
                    </SideContainer>
                    <Line />
                  </>
                )}

                {isView ? (
                  <>
                    {_data?.description && (
                      <>
                        <SideContainer $printSpacing>
                          <Subtitle>Descrição</Subtitle>
                          <RichTextViewer value={_data?.description} height="240px" />
                        </SideContainer>
                        <Line />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <SideContainer $printSpacing>
                      <Subtitle>Descrição</Subtitle>
                      <RichTextEditor
                        key={`wr-${data?.id}`}
                        onChange={handleEditorChange}
                        value={formDescription}
                        images={images}
                        height="240px"
                        toolbar={
                          ' bold italic underline strikethrough | forecolor backcolor |' +
                          ' alignleft aligncenter alignright alignjustify | numlist bullist | link table |' +
                          '  h1 h2 h3 h4 h5 h6 | emoticons'
                        }
                      />
                    </SideContainer>
                    <Line />
                  </>
                )}

                <WorkReportComments commentList={commentList} setCommentList={setCommentList} isView={isView} />
                {(fileList?.length > 0 || !isView) && (
                  <SideContainer>
                    <Subtitle className="hide-on-print">Documentos e imagens</Subtitle>
                    <AttachmentSection isView={isView} fileList={fileList} setFileList={setFileList} />
                  </SideContainer>
                )}
              </>
            )}
            {showCopyReportModal && (
              <CopyLastReportModal
                onSubmit={copyLastReport}
                idRefurbish={_data?.idRefurbish}
                currentReportId={id}
                onClose={() => setShowCopyReportModal(false)}
              />
            )}
            {showShareModal && (
              <ShareModuleModal
                model="work-report"
                itemId={id}
                idRefurbish={idRefurbish}
                onClose={() => setShowShareModal(false)}
                onShare={notifyCustomer}
              />
            )}
            {showCustomerDrawer && (
              <EditOrCreateCustomerOrSupplier
                id={companyCustomer?.id}
                onClose={() => setCustomerDrawer(false)}
                setData={setReload}
              />
            )}
          </MainContainer>
        </ComponentToPdf>
        <Footer>
          <div />
          {isView ? (
            <ButtonsContainer>
              {showEdit && (
                <Button
                  text
                  type="primary"
                  onClick={() => history.push(`/profissional/diario-de-obra/editar/${id}${redirectToProject}`)}
                >
                  Editar
                </Button>
              )}
              <Button
                ghost
                type="primary"
                onClick={() => {
                  printWorkReport();
                  return null;
                }}
              >
                Exportar PDF
              </Button>
              {showEdit && (
                <Button type="primary" onClick={() => setShowShareModal(true)}>
                  Compartilhar
                </Button>
              )}
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              {data?.status !== finished.value ? (
                <>
                  <Button id="save-sketch" text type="primary" onClick={() => createOrUpdateReport()}>
                    Salvar rascunho
                  </Button>
                  <Button id="generate-work-diary" type="primary" onClick={() => createOrUpdateReport(true)}>
                    Gerar relatório
                  </Button>
                </>
              ) : (
                <Button id="save-work-diary" type="primary" onClick={() => createOrUpdateReport()}>
                  Salvar
                </Button>
              )}
            </ButtonsContainer>
          )}
        </Footer>
      </Div>

      <Modal
        open={showExportNotAllowedModal}
        height={500}
        title="Exportação não disponível"
        {...(showEdit
          ? {
              cancelText: 'Fechar',
              submitText: 'Compartilhar',
              onSubmit: () => {
                setShowShareModal(true);
                setShowExportNotAllowedModal(false);
              },
              onClose: () => {
                setShowExportNotAllowedModal(false);
              }
            }
          : { hideFooter: true })}
      >
        <Paragraph>A exportação pelo celular não está disponível. Somente pelo computador.</Paragraph>
        {showEdit && (
          <div style={{ marginTop: spaces.space1 }}>
            <Paragraph>Caso prefira, você pode compartilhar seu arquivo pelo e-mail ou link do WhatsApp.</Paragraph>
          </div>
        )}
      </Modal>
    </>
  );
};

WorkReport.propTypes = {
  isView: PropTypes.bool,
  isPublic: PropTypes.bool,
  _idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default WorkReport;
