import styled from 'styled-components';
import { colors, spaces, fonts, breakpoints, radius, Div } from '../../styles/style';

const DataContainer = styled(Div)`
  justify-content: space-between;
  background-color: ${colors.neutral75};
  border-radius: ${radius.radius1};
  align-items: flex-start;
  padding: ${spaces.space2};
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    .customerData {
      margin-left: 0px;
      border-bottom: 1px solid ${colors.neutral100};
      margin-bottom: ${spaces.space2};
      padding-bottom: ${spaces.space2};
    }
    .supplierData {
      margin-left: 0px;
      margin-bottom: ${spaces.space2};
      padding-bottom: ${spaces.space2};
    }
    .companyData {
      flex-direction: column;
      border-right: 0px;
      margin-bottom: ${spaces.space2};
      border-bottom: 1px solid ${colors.neutral100};
      padding-bottom: ${spaces.space2};

      .logoContainer {
        margin-bottom: ${spaces.space1};
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .companyData {
      flex-basis: 40%;
      flex-grow: 0;
      display: flex;
      > div {
        margin-right: ${spaces.space2};
      }
      flex: 1;
    }

    .customerData,
    .supplierData {
      flex-basis: 30%;
      flex-grow: 0;
      flex: 1;
      display: flex;
      padding-left: ${spaces.space2};
      border-left: 1px solid ${colors.neutral100};
    }
  }

  @media print {
    flex-direction: row;
    .companyData {
      flex-basis: 40%;
      flex-grow: 0;
      display: flex;
      > div {
        margin-right: ${spaces.space2};
      }
      flex: 1;
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .customerData,
    .supplierData {
      flex-basis: 30%;
      flex-grow: 0;
      flex: 1;
      display: flex;
      padding-left: ${spaces.space2};
      border-left: 1px solid ${colors.neutral100};
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

const FlexGapContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${spaces.space1};
`;

const InstallmentStatus = styled(Div)`
  height: ${spaces.space4};
  background-color: ${colors.neutral200};
  color: ${colors.neutral500};
  padding: ${spaces.space1};
  border-radius: ${spaces.space0};
  span {
    font-size: ${fonts.sizeMd};
  }
`;

export { DataContainer, FlexGapContainer, InstallmentStatus };
