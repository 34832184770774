import * as dayjs from 'dayjs';
import { street, addressNumber, complement, city, state } from '../mapping';
import formatCurrency from '../../helpers/formatCurrency';

export const project = {
  id: { label: 'Código do projeto' },
  name: { label: 'Projeto' },
  street,
  number: addressNumber,
  complement,
  city,
  state
};

export const opportunity = (isProject, refurbishFlow, isArchived, hideField) => ({
  id: { label: `Código ${isProject ? ' do projeto' : ' da oportunidade'}` },
  name: { label: isProject ? 'Projeto' : 'Oportunidade' },
  budget: {
    label: 'Valor estimado',
    hideField,
    format: value => formatCurrency(value || 0, { currencySymbol: 'R$' })
  },
  m2: { label: 'Metragem Quadrada', format: value => formatCurrency(value || 0, { currencySymbol: '' }) },
  companyCustomer: { label: 'Cliente', nestedKey: 'name' },
  idOrigin: {
    label: 'Origem',
    format: value => refurbishFlow.originsOfOpportunity.find(l => l.value === value)?.label
  },
  predictionDate: {
    label: 'Expectativa de início',
    format: value => (value ? dayjs(value).format('DD/MM/YYYY') : '-')
  },
  ...(isArchived && { archiveReason: { label: 'Motivo do arquivamento', otherProps: { html: true, span: 3 } } }),
  necessityDescription: { label: 'Descrição', otherProps: { html: true, span: 2 } },
  street,
  number: addressNumber,
  complement,
  city,
  state
});
