import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import BubbleModalButton from './BubbleModalButton';
import Button from './Button';
import { colors, Div, spaces } from '../../styles/style';
import { BubbleModalButtonContentDiv, DropdownBar } from '../Dropdown/DropdownV2.styled';
import useViewport from '../../_Hooks/useViewport';

const DropDownButton = ({ optionData = {}, onClick, ...props }) => {
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const optionContent = ({ iconProps, label }) => {
    const Content = (
      <>
        {iconProps && <FontAwesomeIcon icon={iconProps.icon} color={iconProps.color || colors.primary600} />}
        {label}
      </>
    );

    return _isMobile ? (
      <Div gap={spaces.space1} $fullWidth>
        {Content}
      </Div>
    ) : (
      Content
    );
  };

  if (optionData?.roles) {
    const _hasPermission = hasPermission(user, optionData.roles, plans, permissions);
    if (!_hasPermission) {
      return _hasPermission === null ? (
        <BubbleModalButton feature={optionData.roles[0]}>
          <Button {...props} style={{ justifyContent: 'space-between', ...(props?.style || {}) }} type="link">
            <BubbleModalButtonContentDiv>{optionContent(optionData)}</BubbleModalButtonContentDiv>
            <FontAwesomeIcon icon={faLock} color={colors.neutral200} />
          </Button>
        </BubbleModalButton>
      ) : null;
    }
  }

  if (optionData?.redirectTo) {
    return (
      <Link to={optionData.redirectTo} target={optionData?.target}>
        <Button {...props} text>
          {optionContent(optionData)}
        </Button>
      </Link>
    );
  }

  if (optionData?.bar) {
    return <DropdownBar />;
  }

  return (
    <Button {...props} text onClick={onClick}>
      {optionContent(optionData)}
    </Button>
  );
};

DropDownButton.propTypes = {
  optionData: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  style: PropTypes.instanceOf(Object)
};

export default DropDownButton;
