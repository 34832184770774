import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from 'antd';
import Button from './Button';
import { toKebabCase } from '../../lib/helpers/helper';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import { spaces } from '../../styles/style';

const analytics = AnalyticsServiceFactory();

const HowDoesItWorksButton = ({
  id,
  children,
  icon,
  label = 'Como funciona',
  tooltipText = 'Clique para entender como funciona',
  withoutIcon = false,
  onlyIcon,
  iconStyle,
  placement = 'top',
  ...props
}) => {
  return (
    <Tooltip title={tooltipText} placement={placement}>
      <Button id={id} type="primary" text {...props} onClick={() => analytics.track({ event: toKebabCase(id) })}>
        {!withoutIcon && <FontAwesomeIcon icon={icon || faCircleQuestion} style={{ ...iconStyle }} />}
        {!onlyIcon && (
          <span style={{ marginLeft: `${spaces.space0}` }} className="hideMobile">
            {label}
          </span>
        )}
        {children}
      </Button>
    </Tooltip>
  );
};

HowDoesItWorksButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  iconStyle: PropTypes.instanceOf(Object),
  withoutIcon: PropTypes.bool,
  tooltipText: PropTypes.string,
  onlyIcon: PropTypes.bool,
  placement: PropTypes.string
};

export default HowDoesItWorksButton;
