import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { faSunBright, faSunHaze } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/pro-solid-svg-icons';

import Select from './Select';
import { Div, breakpoints, colors, fonts, radius, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: ${spaces.space2};

  @media print {
    gap: ${spaces.space1};
  }
`;

const SeasonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${props => (props.isView ? '320px' : '430px')};
  height: 100%;
  padding: ${props => (props.isView ? spaces.space1 : `0 ${spaces.space1}`)};
  border: 1px solid ${colors.primary50};
  border-radius: ${radius.radius1};
  flex-wrap: wrap;
  gap: 0 ${spaces.space2};

  ${props => props.selected && `background-color: ${colors.neutral50};`};

  @media (max-width: ${breakpoints.mobile}) {
    ${props =>
      props.isView &&
      `
      width: 320px;
      justify-content: space-between;
    `}
  }

  @media print {
    width: 240px;
    justify-content: space-between;
    gap: 0 ${spaces.space0};
    border-color: ${colors.neutral200};
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding: ${spaces.space2} 0;

    @media (min-width: ${breakpoints.mobile}) {
      border-right: 1px solid ${colors.primary50};

      .ant-checkbox {
        margin: -2px ${spaces.space2} 0 ${spaces.space1};
      }
    }
  }

  .ant-typography {
    color: ${colors.neutral600};
    white-space: nowrap;
  }
`;

const SelectsContainer = styled(Div)`
  justify-content: space-between;
  margin-left: auto;
  gap: ${spaces.space0};
  .ant-select {
    width: 120px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin-left: 0;
    gap: 0;
    width: 100%;
    padding-bottom: ${spaces.space1};

    .ant-select {
      width: 48%;
    }
  }

  .ant-select-selector {
    color: ${colors.neutral500};
    font-size: ${fonts.sizeSm};
  }
`;

const IconContainer = styled(Div)`
  width: 28px;
  justify-content: center;

  @media print {
    width: 20px;
    margin-right: ${spaces.space0};
  }
`;

const WeatherSelect = ({ weather, setWeather, isView = false }) => {
  const mapping = {
    morning: { icon: faSunBright, color: colors.orange300, title: 'Manhã' },
    afternoon: { icon: faSunHaze, color: colors.orange400, title: 'Tarde' },
    night: { icon: faMoon, color: colors.primary300, title: 'Noite' }
  };

  const weatherOptions = {
    clean: 'Limpo',
    partlyCloudy: 'Parc. nublado',
    cloudy: 'Nublado',
    rainy: 'Chuvoso'
  };

  const statusOptions = {
    practicable: 'Praticável',
    impractical: 'Impraticável'
  };

  return (
    <MainContainer>
      {Object.entries(mapping).map(([key, prop]) => (
        <React.Fragment key={key}>
          {(!isView || weather?.[key]?.checked) && (
            <SeasonContainer selected={!isView && weather?.[key]?.checked} isView={isView}>
              {!isView && (
                <Checkbox
                  checked={weather?.[key]?.checked}
                  onChange={() => {
                    const values = {
                      ...weather,
                      [key]: { ...weather?.[key], checked: !weather?.[key]?.checked }
                    };
                    setWeather(values);
                  }}
                />
              )}
              <IconContainer>
                <FontAwesomeIcon color={prop.color} icon={prop.icon} size="xl" />
              </IconContainer>
              <Paragraph>{prop.title}</Paragraph>
              {isView ? (
                <Div gap={spaces.space1} style={{ marginLeft: 'auto' }}>
                  <Paragraph type="small">{weatherOptions[weather?.[key]?.weather]}</Paragraph>
                  <Paragraph
                    type="small"
                    color={weather?.[key]?.status === 'practicable' ? colors.primary500 : colors.red500}
                  >
                    {statusOptions[weather?.[key]?.status]}
                  </Paragraph>
                </Div>
              ) : (
                <SelectsContainer>
                  <Select
                    id={`${key}-weather`}
                    value={weather?.[key]?.weather || 'clean'}
                    options={Object.entries(weatherOptions).map(([value, label]) => ({ value, label }))}
                    onChange={value => {
                      const values = {
                        ...weather,
                        [key]: { ...weather?.[key], weather: value }
                      };
                      setWeather(values);
                    }}
                  />
                  <Select
                    id={`${key}-status`}
                    value={weather?.[key]?.status || 'practicable'}
                    options={Object.entries(statusOptions).map(([value, label]) => ({ value, label }))}
                    onChange={value => {
                      const values = {
                        ...weather,
                        [key]: { ...weather?.[key], status: value }
                      };
                      setWeather(values);
                    }}
                  />
                </SelectsContainer>
              )}
            </SeasonContainer>
          )}
        </React.Fragment>
      ))}
    </MainContainer>
  );
};

WeatherSelect.propTypes = {
  weather: PropTypes.instanceOf(Object),
  setWeather: PropTypes.func,
  isView: PropTypes.bool
};

export default WeatherSelect;
