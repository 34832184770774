import React from 'react';
import PropTypes from 'prop-types';
import { faEye, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Button from '../Button/Button';
import { colors, spaces } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';
import { Paragraph } from '../Text/Text';

const mapTypeColor = {
  error: colors.red500,
  primary: colors.primary600
};

const Container = styled.div`
  background-color: ${props => mapTypeColor[props.type]};
  width: 100%;
  display: flex;
  gap: ${spaces.space2};
  justify-content: center;
  align-items: center;
  padding: ${spaces.space2};

  p {
    color: ${colors.white};
  }
`;

const AlertBar = ({ showAlert, type = 'error', message, messageMobile, onExit, icon }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  return showAlert ? (
    <Container type={type}>
      {_isMobile ? (
        <Paragraph>{messageMobile || message}</Paragraph>
      ) : (
        <>
          <FontAwesomeIcon icon={icon || faEye} color={colors.white} size="lg" />
          <Paragraph>{message}</Paragraph>
        </>
      )}

      {onExit && (
        <Button type="primary" primaryBorderColor={!isMobile() ? 'white' : 'primary600'} size={2} onClick={onExit}>
          <FontAwesomeIcon icon={faArrowLeft} />
          <div id="close-alert-bar" className="hideMobile">
            Sair deste modo
          </div>
        </Button>
      )}
    </Container>
  ) : null;
};

AlertBar.propTypes = {
  showAlert: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  messageMobile: PropTypes.string,
  onExit: PropTypes.func,
  icon: PropTypes.instanceOf(Object)
};

export default AlertBar;
