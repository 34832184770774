import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { toast } from 'react-toastify';

import { init as initApm } from '@elastic/apm-rum';
import * as Sentry from '@sentry/react';

import App from './App';

import * as serviceWorker from './serviceWorker';

import './styles/fixSpreadsheetImport.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import 'moment/locale/pt-br';

if (process.env.REACT_APP_APM_SERVER_URL) {
  initApm({
    serviceName: `vobi-app`,
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,
    environment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    secretToken: process.env.REACT_APP_APM_SECRET_TOKEN,
    sanitizeFieldNames: [
      'password',
      'passwd',
      'pwd',
      'secret',
      '*key',
      '*token*',
      '*session*',
      '*credit*',
      '*card*',
      '*auth*',
      'set-cookie',
      '*principal*',
      'pw',
      'pass',
      'connect.sid',
      'ccv',
      'cvv',
      'creditNumber',
      'expiryMonth',
      'expiryYear'
    ]
  });
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/vobi\.com.br/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

// @Todo remove GTM TAG ID
const tagManagerArgs = {
  gtmId: 'GTM-KNSPN5G',
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};

TagManager.initialize(tagManagerArgs);

window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);

window.addEventListener('error', e => {
  if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
    const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
});

toast.configure();
const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
