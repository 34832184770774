import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToLine } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

const ExpandAllButton = ({
  handleToggleAllRow = f => f,
  title = 'Item',
  tooltip = 'Abrir/fechar todos os níveis',
  zIndex
}) => (
  <div style={{ display: 'flex' }}>
    <div
      id="expand-all"
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        handleToggleAllRow();
      }}
      style={{ marginRight: 8, cursor: 'pointer' }}
    >
      <Tooltip title={tooltip} zIndex={zIndex}>
        <FontAwesomeIcon icon={faArrowsToLine} />
      </Tooltip>
    </div>
    <span>{title}</span>
  </div>
);

ExpandAllButton.propTypes = {
  handleToggleAllRow: PropTypes.func,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  zIndex: PropTypes.number
};

export default ExpandAllButton;
