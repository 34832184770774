import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import Button from './Button';

const StyledEditButton = styled(Button)`
  ${props =>
    props.$height &&
    css`
      height: ${props.$height};
    `}
`;

const RedirectLinkButton = ({ onClose = () => {}, idData, height, path, ...params }) => {
  const history = useHistory();

  const handleRedirectLink = () => {
    history.push(path ? `${path}` : `/profissional/financeiro/cobrancas/editar/${idData}`);
  };
  return (
    <StyledEditButton
      padding="8px 24px"
      {...params}
      $height={height}
      onClick={() => {
        handleRedirectLink();
        onClose();
      }}
    />
  );
};

RedirectLinkButton.propTypes = {
  onClose: PropTypes.func,
  idData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string,
  path: PropTypes.string
};

export default RedirectLinkButton;
