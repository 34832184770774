import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Radio } from 'antd';
import { AboutContent, Group, Table, TableHead, TableHeader, ServiceName, TableCell } from './Onboarding.styled';
import { Subtitle, Title } from '../../../components/Text/Text';
import Input from '../../../components/Input/Input';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import { fonts, spaces, colors } from '../../../styles/style';

const Services = ({ data, onChange }) => {
  const { servicesOfferedList, serviceClassificationList } = useSelector(state => state.setup.systemData);
  const [_data, setData] = useState(data || {});
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [isOtherInputVisible, setIsOtherInputVisible] = useState(false);
  const [isFirstEffectDone, setIsFirstEffectDone] = useState(false);

  const filteredServices = servicesOfferedList.filter(s => s.service !== data?.onboarding?.mainActivity);

  const tooltipText = text => {
    return (
      <ul style={{ listStyleType: 'disc', paddingLeft: spaces.space2 }}>
        {text.map(val => (
          <li>{val}</li>
        ))}
      </ul>
    );
  };

  const handleChange = ({ onboarding, currentService }) => {
    const previousMainService = Object.keys(_data?.onboarding?.servicesOffered || {}).find(
      key => (_data?.onboarding?.servicesOffered || {})[key] === 1
    );

    const isNewMainService = onboarding.servicesOffered[currentService] === 1;

    const updatedServicesOffered = {
      ..._data?.onboarding?.servicesOffered,
      ...onboarding.servicesOffered
    };

    if (previousMainService && previousMainService !== currentService && isNewMainService) {
      updatedServicesOffered[previousMainService] = 2;
    }

    const newData = { onboarding: { ..._data?.onboarding, servicesOffered: updatedServicesOffered } };
    setData(newData);
    onChange(newData);
  };

  useEffect(() => {
    const servicesNotVisible = servicesOfferedList.filter(s => s.service === data?.onboarding?.mainActivity);
    const updatedServicesOffered = { ..._data?.onboarding?.servicesOffered };

    servicesNotVisible.forEach(s => {
      updatedServicesOffered[s.key] = undefined;
    });

    handleChange({ onboarding: { servicesOffered: updatedServicesOffered } });

    setIsFirstEffectDone(true);
  }, []);

  useEffect(() => {
    const servicesOffered = _data?.onboarding?.servicesOffered || {};

    setIsMessageVisible(
      Object.keys(servicesOffered).length >= Object.keys(servicesOfferedList).length &&
        Object.values(servicesOffered).every(val => val !== 1)
    );
  }, [_data?.onboarding?.servicesOffered]);

  useEffect(() => {
    if (!isFirstEffectDone) return;

    const isOther = _data?.onboarding?.servicesOffered?.other && _data?.onboarding?.servicesOffered?.other !== 3;
    setIsOtherInputVisible(isOther);

    if (!isOther) {
      handleChange({
        onboarding: {
          servicesOffered: { otherServiceOffered: undefined }
        }
      });
    }
  }, [_data?.onboarding?.servicesOffered?.other]);

  return (
    <AboutContent>
      <Group gap={spaces.space1}>
        <Title size={fonts.sizeH5} id="onboarding-page-title">
          Queremos te conhecer melhor!
        </Title>
        <Subtitle weight={fonts.weight500} color={colors.neutral600}>
          Isso nos ajudará a oferecer soluções que realmente se encaixem na sua rotina.
        </Subtitle>
      </Group>

      <Group width="100%" maxWidth="814px" gap={spaces.space2}>
        <Group gap="0px">
          <Subtitle color={colors.neutral600}>
            Dentro das opções apresentadas abaixo, qual é a representatividade dos serviços realizados em sua empresa?
          </Subtitle>
          <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral500}>
            (Marque uma opção como &quot;Principal serviço&quot; e as demais como &quot;Demais serviços&quot; ou
            &quot;Não realizo&quot;)
          </Subtitle>
        </Group>

        <Table>
          <TableHead>
            <tr>
              <TableHeader> </TableHeader>
              {serviceClassificationList.map(c => (
                <TableHeader key={c.value}>{c.label}</TableHeader>
              ))}
            </tr>
          </TableHead>
          <tbody>
            {filteredServices.map(s => (
              <tr key={s.key}>
                <ServiceName>
                  {s.label}
                  {s.tooltip && (
                    <TooltipIcon
                      style={{ color: colors.primary600, marginLeft: spaces.space1 }}
                      text={tooltipText(s.tooltip)}
                      icon={faInfoCircle}
                      tooltipColor={colors.neutral700}
                    />
                  )}
                </ServiceName>
                {serviceClassificationList.map(c => (
                  <TableCell key={`${s.key}-${c.value}`}>
                    <Radio
                      name={s.key}
                      value={c.value}
                      checked={_data?.onboarding?.servicesOffered?.[s.key] === c.value}
                      onChange={e =>
                        handleChange({
                          currentService: s.key,
                          onboarding: {
                            servicesOffered: { ..._data?.onboarding?.servicesOffered, [s.key]: e?.target?.value }
                          }
                        })
                      }
                      style={{ marginRight: '0px' }}
                    >
                      {}
                    </Radio>
                  </TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        {isOtherInputVisible && (
          <Input
            type="text"
            placeholder='Informe o valor de "Outro"'
            value={_data?.onboarding?.servicesOffered?.otherServiceOffered}
            onChange={e =>
              handleChange({
                onboarding: {
                  servicesOffered: { ..._data?.onboarding?.servicesOffered, otherServiceOffered: e?.target?.value }
                }
              })
            }
            style={{ maxWidth: '306px' }}
          />
        )}
        {isMessageVisible && (
          <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.red600}>
            Marque uma opção como &quot;Principal serviço&quot;
          </Subtitle>
        )}
      </Group>
    </AboutContent>
  );
};

Services.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default Services;
