import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

import { Div, spaces, colors } from '../../styles/style';
import { Subtitle, Paragraph } from '../Text/Text';
import { addressConcat } from '../../lib/helpers/helper';
import {
  purchaseOrderAddressMapping,
  purchaseOrderAddressSchema
} from '../../lib/mapping/Form/purchaseOrderAddressSchema';
import Form from './Form';
import Input from '../Input/Input';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { useContextHook } from '../../contexts/GeneralContext';

const TitleText = ({ ...props }) => <Subtitle type="secondary" {...props} />;

const DeliveryAddressForm = ({ tooltipText, ...props }) => {
  const {
    addressFormValues,
    saveAddressToProject,
    setSaveAddressToProject,
    setAddressFormValues,
    forceAddressFormUpdate,
    referObj,
    refurbish,
    isPrinting,
    readOnly
  } = useContextHook();

  return (
    <Div align="start" direction="column" padding={`${spaces.space3} ${spaces.space2}`} $avoidPageBreak {...props}>
      <TitleText>Endereço de entrega</TitleText>
      <br />
      {isPrinting ? (
        <Div direction="column" align="flex-start" gap={spaces.space1}>
          <Paragraph type="small">{refurbish?.name || referObj?.refurbish?.name}</Paragraph>
          <Paragraph type="small">{addressConcat(refurbish || referObj)}</Paragraph>
        </Div>
      ) : (
        <Div direction="column" align="flex-start" gap={spaces.space1}>
          <Form
            schema={purchaseOrderAddressSchema}
            mapping={() => purchaseOrderAddressMapping(readOnly)}
            data={addressFormValues}
            onFormChange={setAddressFormValues}
            displayButtons={false}
            forceUpdate={forceAddressFormUpdate}
          />
          {!readOnly && (
            <Div>
              <Input
                style={{ width: 'unset' }}
                type="checkbox"
                checked={saveAddressToProject}
                onChange={e => setSaveAddressToProject(e.target.checked)}
              />
              <Paragraph>Atualizar endereço do projeto</Paragraph>
              <TooltipIcon
                style={{ color: colors.primary600 }}
                icon={faInfoCircle}
                color={colors.primary600}
                text={tooltipText}
              />
            </Div>
          )}
        </Div>
      )}
    </Div>
  );
};

DeliveryAddressForm.propTypes = {
  tooltipText: PropTypes.string
};

export default DeliveryAddressForm;
