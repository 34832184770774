import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBar from '../Alert/AlertBar';
import { updateUser } from '../../store/auth/actions/action';

const AccessAsUserAlert = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authReducer);

  const onExit = () => {
    dispatch(updateUser({ ...user.accessedBy }));
  };

  return (
    <AlertBar
      showAlert
      message={`Atenção! Acessando como ${user?.type} "${user?.name}" (${user?.id})`}
      messageMobile="Acesso como usuário"
      onExit={onExit}
    />
  );
};

export default AccessAsUserAlert;
