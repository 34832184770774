import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Tabs, { TabPane } from '../Tabs/Tabs';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';

import { Container, TabContent } from './EditItemModal.styles';
import { colors, spaces } from '../../styles/style';
import ShareLinkDropdown from '../Dropdown/ShareLinkDropdown';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import Drawer from '../Drawer/Drawer';

const formId = 'modal-customer-form';

const EditInstalmentModal = ({ id, idRefurbish, onClose = f => f, readOnly, tab = '0', hideAttachments = false }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  const [files, setFiles] = useState([]);
  const [observation, setObservation] = useState('');
  const [selectedTab, setSelectedTab] = useState(tab || '0');

  const { data, loading, handleUpdate } = useCRUD({
    model: 'refurbish-item-instalment',
    pathOptions: `/${id}`,
    options: {
      include: [
        {
          model: 'RefurbishItems',
          include: ['supplier', 'refurbish']
        },
        'files'
      ],
      where: { idRefurbish }
    }
  });

  useEffect(() => {
    if (data?.id) {
      setFiles(data.files || []);
      setObservation(data.observation || '');
    }
  }, [data]);

  const handleSubmit = () => {
    handleUpdate({
      values: {
        observation,
        files
      },
      refresh: false
    }).then(resp => {
      if (!resp?.error) {
        onClose(true);
      }
    });
  };

  return loading ? (
    <CenteredLoader />
  ) : (
    <Drawer
      open
      title="Detalhe da parcela"
      onClose={() => onClose(false)}
      formId={formId}
      hideFooter={isCustomer}
      footer={readOnly ? [] : null}
      headerExtraContent={() => (
        <ShareLinkDropdown id={id} whatsapp urlContext="o" style={{ marginLeft: spaces.space1 }} />
      )}
      HowDoesItWorks="edit-item-drawer"
      onSubmit={handleSubmit}
    >
      <Container>
        <Tabs activeKey={selectedTab} onChange={setSelectedTab}>
          {!hideAttachments && (
            <TabPane key="0" tab="Anexos">
              <TabContent>
                <ItemAttachmentContent
                  data={{ files, observation }}
                  setFiles={setFiles}
                  setObservation={setObservation}
                  readOnly={isCustomer}
                  idRefurbish={idRefurbish}
                />
              </TabContent>
            </TabPane>
          )}
          <TabPane key="1" tab="Dados do fornecedor">
            <TabContent>
              <Description
                title="Dados bancários"
                mapping={mappingDescriptionConf.bank}
                column={3}
                data={data?.refurbishItem?.supplier || {}}
                background={null}
                noPadding
              />
              <Description
                style={{ paddingTop: 16, borderTop: `1px solid ${colors.neutral100}` }}
                title="Dados da empresa"
                mapping={mappingDescriptionConf.supplier}
                data={data?.refurbishItem?.supplier || {}}
                background={null}
                noPadding
              />
            </TabContent>
          </TabPane>
        </Tabs>
      </Container>
    </Drawer>
  );
};

EditInstalmentModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  hideAttachments: PropTypes.bool
};

export default memo(EditInstalmentModal);
