import styled, { css } from 'styled-components';
import { Select as AntSelect } from 'antd';

import { breakpoints, colors, fonts, spaces } from '../../styles/style';

const SelectStyled = styled(AntSelect)`
  text-align: initial;
  width: 100%;

  &.form-component {
    max-width: 238px;

    @media (max-width: ${breakpoints.tablet}) {
      max-width: 100%;

      &.ant-select-disabled {
        .ant-select-selection-item {
          background-color: ${colors.white};
          color: ${colors.neutral300} !important;
        }
      }
    }

    /* Selector styles */
    .ant-select-selector {
      border-color: ${colors.white};
      font-size: ${fonts.sizeSm};
      color: ${colors.neutral600};
      padding: 0 ${spaces.space2} 0 11px;

      .ant-select-selection-item {
        font-size: ${fonts.sizeSm};
        color: ${colors.neutral600};
      }

      @media (max-width: ${breakpoints.tablet}) {
        padding: 0;
        border: none;
        width: 100%;

        .ant-select-selection-item {
          font-size: ${fonts.sizeMd};
        }

        .ant-select-selection-search {
          left: 0;
        }
      }
    }

    /* Hover and focus states */
    &:hover,
    &:focus {
      .ant-select-selector {
        border-color: ${colors.neutral75} !important;
        background-color: ${colors.neutral75};
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${colors.neutral100} !important;
        background-color: ${colors.white} !important;
      }
    }

    /* Clear button */
    .ant-select-clear {
      color: ${colors.neutral600};
      background-color: ${colors.neutral75};
      font-size: ${fonts.sizeMd};

      &:hover {
        color: ${colors.neutral700};
      }
    }

    /* Arrow and clear button positioning */
    .ant-select-arrow,
    .ant-select-clear {
      right: ${spaces.space2};
      margin-top: -6px;
    }
  }

  .ant-select-selection-overflow-item-rest > span {
    background-color: ${colors.primary50};
    color: ${colors.primary500};
  }
  ${props =>
    props.mode === 'multiple' &&
    css`
      .ant-select-selection-item {
        background-color: ${colors.primary50};
        color: ${colors.primary500};
        svg {
          color: ${colors.primary500};
        }
      }
    `}

  .ant-select-selector {
    box-shadow: none !important;
    border-color: ${props => props.error && colors.red500} !important;

    &:hover,
    &:focus {
      border-color: ${colors.neutral600} !important;
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    ${props =>
      !props.noHeight &&
      css`
        height: ${props.height ? `${props.height}px` : '32px'};
      `}

    align-items: center;
  }

  ${props =>
    props.$itemHeight &&
    css`
      .ant-select-selection-item {
        height: ${props.$itemHeight};
      }
    `}

  ${props =>
    props.$dark &&
    css`
      .ant-select-selection-search-input {
        color: #fff !important;
      }

      .ant-select-selector {
        background-color: ${colors.neutral800} !important;
        color: #fff !important;
        border: 1px solid #fff !important;
        box-shadow: none !important;
      }

      .ant-select-arrow {
        color: #fff;
      }

      .ant-select-clear {
        background-color: ${colors.neutral800};
        color: #fff;
      }
    `}

  ${props =>
    props.$light &&
    css`
      .ant-select-selector {
        background-color: transparent !important;
        border: 1px solid transparent !important;
        padding: 0 ${spaces.space0} !important;

        &:focus-within {
          background-color: white !important;
          border: 1px solid ${colors.neutral500} !important;
        }

        .ant-select-selection-item {
          padding-right: ${spaces.space1} !important;
        }

        .ant-select-selection-search {
          left: ${spaces.space0} !important;
        }
      }
      .ant-select-arrow {
        display: none;
        right: ${spaces.space0};
      }
      .ant-select-clear {
        right: ${spaces.space0};
      }

      &:focus-within {
        .ant-select-arrow {
          display: flex;
        }
      }
    `}

  &.ant-select-disabled {
    .ant-select-selector {
      background-color: ${colors.neutral75} !important;
      border-color: transparent !important;
      color: ${colors.neutral400} !important;

      &:hover {
        border-color: transparent !important;
        cursor: default !important;
      }

      ${props =>
        props.readOnly &&
        css`
          background-color: transparent !important;
          cursor: default !important;
          color: inherit !important;
          font: inherit !important;

          &:hover {
            background-color: transparent !important;
          }

          ::placeholder {
            color: currentColor !important;
          }
        `};
    }
  }
`;

const SelectDebouceStyled = styled(AntSelect)`
  width: 100%;

  .ant-select-selector {
    box-shadow: none !important;
    border-color: ${colors.neutral200} !important;

    &:hover,
    &:focus {
      border-color: ${colors.neutral600} !important;
    }

    &.ant-select-item-option-content {
      font-size: ${fonts.sizeXs};
    }
  }
`;
export { SelectStyled, SelectDebouceStyled };
