import React from 'react';
import PropTypes from 'prop-types';
// components
import SimpleAccordion from './SimpleAccordion';
import BillingManagerTable from '../Table/BillingManagerTable';
import { useContextHook } from '../../contexts/GeneralContext';

const BillingManagerAccordion = ({ isView }) => {
  const { isRevision } = useContextHook();
  return (
    <SimpleAccordion title="Notificações" id="payment-billing-managers" withPaddingContainer initOpen={isRevision}>
      <BillingManagerTable isView={isView} />
    </SimpleAccordion>
  );
};

BillingManagerAccordion.propTypes = {
  isView: PropTypes.bool
};

export default BillingManagerAccordion;
