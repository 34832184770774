import styled from 'styled-components';
import colors from './settings/_colors.module.scss';
import fonts from './settings/_fonts.module.scss';
import spaces from './settings/_spaces.module.scss';
import opacities from './settings/_opacities.module.scss';
import radius from './settings/_radius.module.scss';
import shadows from './settings/_shadows.module.scss';
import borders from './settings/_borders.module.scss';

export const getAbsoluteValue = size => Number(size?.replace('px', ''));

export { colors, fonts, spaces, opacities, radius };

export const breakpoints = {
  mobile: '450px',
  tablet: '768px',
  desktop: '980px',
  wide: '1280px',
  ultraWide: '1440px'
};

export const { boxShadow } = shadows;

export const { borderSolid } = borders;

export const Div = styled.div`
  display: ${props => props.display || 'flex'};
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify && props.justify};
  gap: ${props => props.gap && props.gap};
  flex-direction: ${props => props.direction || 'row'};
  width: ${props => props.$fullWidth && '100%'};
  height: ${props => props.$fullHeight && '100%'};
  max-height: ${props => props.$maxHeight};
  max-width: ${props => props.$maxWidth};
  overflow: ${props => props.overflow};
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.$minWidth && `min-width: ${props.$minWidth};`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.wrap && `flex-wrap: ${props.wrap};`}
  ${props => props.$avoidPageBreak && 'break-inside: avoid !important;'}
  ${props => props.$alignSelf && `align-self: ${props.$alignSelf};`}
  ${props => props.$backgroundColor && `background-color: ${props.$backgroundColor};`}
  ${props => props.$borderRadius && `border-radius: ${props.$borderRadius};`}
  ${props => props.borderTop && `border-top: ${props.borderTop};`}
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.$whiteSpace && `white-space: ${props.$whiteSpace};`}
  ${props => props.$flexWrap && `flex-wrap: ${props.$flexWrap};`}
  ${props => props.$textAlign && `text-align: ${props.$textAlign};`}
`;

export const Grid = styled.div`
  display: ${props => props.display || 'grid'};
  grid-template-columns: ${props => props.$gridTemplateColumns || '1fr 1fr'};
  grid-gap: ${props => props.$gridGap || spaces.space1};
  width: ${props => props.width && props.width};
  height: ${props => props.height && props.height};
  margin: ${props => props.margin && props.margin};
`;

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ShowHideElement = styled.div`
  display: flex;
  .elementHover {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  ${({ idHover, currentId }) =>
    idHover === currentId &&
    `
    :hover .elementHover {
      opacity: 1;
    }
  `}
`;
