import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FileUpload from '../File/FileUpload';
import useCRUD from '../../_Hooks/useCRUD';

const FileUploadButton = ({
  onClose = () => {},
  model,
  referenceKey,
  idReference,
  item,
  isGetById,
  afterSubmit,
  uploadProps
}) => {
  const { handleGet, handleUpdate } = useCRUD({
    model,
    [referenceKey]: idReference,
    pathOptions: item?.id ? `/${item?.id}` : '',
    options: {
      include: ['files']
    },
    immediatelyLoadData: false
  });

  const handleUploadFile = newFiles => {
    const itemPromisse = isGetById ? handleGet() : Promise.resolve(item);
    itemPromisse.then(data => {
      const files = [...(data?.files || []), ...newFiles];

      handleUpdate({
        values: {
          [referenceKey]: idReference,
          files
        },
        refresh: false
      }).then(resp => {
        if (!resp?.error) {
          if (afterSubmit) {
            afterSubmit();
            onClose(true);
          }
          toast.success('Anexo adicionado');
        }
      });
    });
  };

  return (
    <FileUpload
      id="add-attachment"
      buttonProps={{ $noIcon: true, text: true }}
      multiple
      onChange={newFiles => handleUploadFile(newFiles, onClose)}
      showGallery={false}
      listType={null}
      type="button"
      noMobileMargin
      text="Adicionar anexo"
      {...uploadProps}
    />
  );
};

FileUploadButton.propTypes = {
  onClose: PropTypes.func,
  model: PropTypes.string,
  referenceKey: PropTypes.string,
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  item: PropTypes.instanceOf(Object),
  isGetById: PropTypes.bool,
  afterSubmit: PropTypes.func,
  uploadProps: PropTypes.instanceOf(Object)
};

export default FileUploadButton;
