import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { StyledMenu, StyledColor, StyledBoxColor, StyledColorInline, IconXmark } from './ColorPickerDropDown.styled';
import { Paragraph } from '../Text/Text';
import { Div, spaces, colors, fonts } from '../../styles/style';

const colorItems = [
  colors.primary400,
  colors.teal500,
  colors.green500,
  colors.yellow400,
  colors.orange500,
  colors.red500,
  colors.pink500,
  colors.violet500,
  colors.neutral500
];

const ColorPickerDropDown = ({ color, onSelect, disabled = false, icon, _visible, id }) => {
  const [visible, setVisible] = useState();
  const [selectedColor, setSelectedColor] = useState();

  const handleSelect = _color => {
    onSelect(_color);
    setVisible(false);
  };

  useEffect(() => {
    setVisible(_visible);
  }, [_visible]);

  useEffect(() => {
    if (!visible) setSelectedColor(null);
  }, [visible]);

  const menu = (
    <StyledMenu>
      <Div $fullWidth justify="space-between" margin={`0px 0px ${spaces.space1} 0px`}>
        <Paragraph type="small" color={colors.neutral600} weight={fonts.weight600}>
          Cores
        </Paragraph>
        <IconXmark
          onClick={e => {
            setVisible(false);
            e.stopPropagation();
          }}
          icon={faXmark}
        />
      </Div>
      {colorItems.map((_color, index) => (
        <Menu.Item key={_color} style={{ display: 'contents' }} id={`color-${index + 1}`}>
          <StyledColor
            open
            role="presentation"
            color={_color}
            onClick={e => {
              e.stopPropagation();
              handleSelect(_color);
            }}
          >
            <StyledColorInline open role="presentation" color={_color} />
          </StyledColor>
        </Menu.Item>
      ))}
    </StyledMenu>
  );

  return disabled ? (
    <>
      {icon ? (
        <FontAwesomeIcon icon={icon} color={color} />
      ) : (
        <StyledColor disabled={disabled} role="presentation" color={color} />
      )}
    </>
  ) : (
    <Dropdown
      onClick={e => e.stopPropagation()}
      onOpenChange={v => setVisible(v)}
      overlay={menu}
      trigger={['click']}
      open={visible}
      id={`id-${id}`}
    >
      <div>
        {icon ? (
          <FontAwesomeIcon icon={icon} color={color} />
        ) : (
          <StyledBoxColor onClick={() => setSelectedColor(color)}>
            <StyledColor
              id="color-picker"
              role="presentation"
              color={color}
              className={selectedColor === color ? 'selected' : ''}
            >
              <StyledColorInline role="presentation" color={color} />
            </StyledColor>
          </StyledBoxColor>
        )}
      </div>
    </Dropdown>
  );
};

ColorPickerDropDown.propTypes = {
  color: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  _visible: PropTypes.bool,
  id: PropTypes.number
};

export default ColorPickerDropDown;
