import React from 'react';
import { Tooltip } from 'antd';
import { faCircleXmark, faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark as solidCircleXMark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import formatCurrency from '../../helpers/formatCurrency';
import { Div, colors, spaces } from '../../../styles/style';
import { Paragraph } from '../../../components/Text/Text';
import { formatDecimalsDinamically } from '../../helpers/helper';
import EditableInput from '../../../components/Input/EditableInput';
import ImageInput from '../../../components/Input/ImageInput';
import { store } from '../../config/redux-store';
import formatNumber from '../../helpers/formatNumber';
import Button from '../../../components/Button/Button';

const columns = ({ editRow, setUnavailable, isPrint, readOnly = false, isMobile }) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;
  const { refurbishItemTypes = [] } = setup.systemData;

  const itemColumn = {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    ...(isPrint ? { className: 'col-grow-1' } : { width: 250 }),
    alwaysShow: true,
    mobileWidth: '100%',
    render: (val, row) => {
      const { id, item, quoteItemSuppliers } = row || {};
      const type = row?.type || item?.type;
      const code = row?.code || item?.code;
      const name = row?.name || item?.name;
      const typeName = refurbishItemTypes?.find(r => r.value === row.type)?.label;
      const itemImage = refurbishItemType?.product === type ? 'boxOpen' : 'screwdriverWrench';
      const isUnavailable = quoteItemSuppliers?.[0]?.isUnavailable || false;

      return (
        <Div gap={spaces.space0}>
          <Tooltip title={typeName}>
            <ImageInput size="12px" key={`image${id}`} id={id} value={itemImage || 'boxOpen'} disabled />
          </Tooltip>
          <Paragraph type="small" id="quote-item-supplier-item">
            {isUnavailable ? (
              <s>
                {code && `${code} - `}
                {name}
              </s>
            ) : (
              <>
                {code && `${code} - `}
                {name}
              </>
            )}
          </Paragraph>
        </Div>
      );
    }
  };

  const quoteItemQuantityColumn = {
    title: 'Qtd. Solicitada',
    dataIndex: 'quoteItemQuantity',
    key: 'quoteItemQuantity',
    ...(isPrint ? { className: 'width-90' } : { width: 90 }),
    renderCollapseIcon: true,
    alwaysShow: true,
    render: (val, row) => {
      const { quantity } = row || {};

      return (
        <Paragraph type="small" id="quote-item-quantity">
          {formatDecimalsDinamically(quantity)}
        </Paragraph>
      );
    }
  };

  const unitColumn = {
    title: 'Un.',
    dataIndex: 'idUnit',
    key: 'idUnit',
    ...(isPrint ? { className: 'width-90' } : { width: 60 }),
    render: (val, row) => {
      const { units } = row;

      return (
        <Paragraph type="small" id="quote-item-supplier-unit">
          {units?.name || '-'}
        </Paragraph>
      );
    }
  };

  const quoteItemSupplierQuantityColumn = {
    title: 'Qtd. Disponível',
    dataIndex: 'quoteItemSupplierQuantity',
    key: 'quoteItemSupplierQuantity',
    ...(isPrint ? { className: 'width-90' } : { width: 90 }),
    render: (val, row) => {
      const { id, quoteItemSuppliers } = row;
      const isUnavailable = quoteItemSuppliers?.[0]?.isUnavailable || false;
      const _quantity = quoteItemSuppliers?.[0]?.quantity || 0;

      const quantityDiff = _quantity - row?.quantity;

      const content = (
        <EditableInput
          id="quote-item-supplier-quantity"
          key={`qtd${row.id}`}
          noWrap
          value={_quantity ? formatDecimalsDinamically(_quantity) : 0}
          row={row}
          width={isMobile ? '90%' : '100%'}
          onChange={value => editRow({ id, value: formatNumber(value), key: 'quantity' })}
          rowId={row.id}
          disabled={readOnly || isUnavailable || isPrint}
          placeholder="0"
          $fixedHoverStyle
          size={isMobile ? 'small' : ''}
        />
      );

      return quantityDiff !== 0 && !isUnavailable ? (
        <Tooltip title="Quantidade diferente da solicitada">
          <Div style={{ color: colors.orange500 }} gap={spaces.space0}>
            <FontAwesomeIcon icon={quantityDiff > 0 ? faArrowUp : faArrowDown} />
            {content}
          </Div>
        </Tooltip>
      ) : (
        content
      );
    }
  };

  const valueColumn = {
    title: 'Valor unit.',
    dataIndex: 'price',
    key: 'price',
    ...(isPrint ? { className: 'width-120' } : { width: 120 }),
    type: 'number',
    render: (val, row) => {
      const { id, quoteItemSuppliers } = row;
      const isUnavailable = quoteItemSuppliers?.[0]?.isUnavailable || false;
      const price = quoteItemSuppliers?.[0]?.price || 0;

      const valFormatted = formatCurrency(price, { currencySymbol: 'R$' });

      return (
        <EditableInput
          type="currency"
          id="quote-item-supplier-price"
          key={`price${id}`}
          value={valFormatted}
          row={row}
          width={isMobile ? '90%' : '100%'}
          onChange={value => editRow({ id, value: formatNumber(value), key: 'price' })}
          rowId={id}
          placeholder="R$ 0,00"
          disabled={readOnly || isUnavailable || isPrint}
          $fixedHoverStyle
          size={isMobile ? 'small' : ''}
          allowQuitOnClickOutside
        />
      );
    }
  };

  const totalValueColumn = {
    title: 'Valor total',
    dataIndex: 'total',
    key: 'total',
    ...(isPrint ? { className: 'width-120' } : { width: 120 }),
    type: 'number',
    inlineMobile: true,
    render: (val, row) => {
      const { price = 0, quantity = 0 } = row?.quoteItemSuppliers?.[0] || {};

      const total = formatCurrency(Number(price) * Number(quantity) || 0, { currencySymbol: 'R$' });

      return (
        <Div id="quote-item-supplier-total" $fullWidth={!isMobile} justify="flex-start">
          {total}
        </Div>
      );
    }
  };

  const actionsColumn = {
    title: !isMobile && 'Ações',
    dataIndex: 'actions',
    key: 'actions',
    width: 60,
    inlinePadding: '0px',
    render: (_, row) => {
      const { id, quoteItemSuppliers } = row;
      const isUnavailable = quoteItemSuppliers?.[0]?.isUnavailable || false;
      return (
        <Tooltip title={isUnavailable ? 'Marcar que o item está disponível' : 'Marcar que o item está indisponível'}>
          <Button text type={isUnavailable ? 'danger' : 'default'}>
            <FontAwesomeIcon
              icon={isUnavailable ? solidCircleXMark : faCircleXmark}
              onClick={() => setUnavailable({ id, isUnavailable: !isUnavailable })}
              size="lg"
            />
          </Button>
        </Tooltip>
      );
    }
  };

  const allColumns = [
    itemColumn,
    quoteItemQuantityColumn,
    unitColumn,
    quoteItemSupplierQuantityColumn,
    valueColumn,
    totalValueColumn
  ];

  if (!isPrint && !isMobile && !readOnly) allColumns.push(actionsColumn);

  return allColumns;
};

export { columns };
