import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faChartSimpleHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { faCircleQuestion, faList } from '@fortawesome/pro-regular-svg-icons';
import { Switch, Divider } from 'antd';

import ConfigModal from './ConfigModal';
import { Paragraph } from '../Text/Text';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { Div, colors, spaces } from '../../styles/style';
import WarningBar from '../Alert/WarningBar';

const PlanningConfigModal = ({ onClose, onSubmit, data, isTemplate, view }) => {
  const { systemData } = useSelector(state => state.setup);

  const {
    planningListColumns,
    templatePlanningListColumns,
    templatePlanningGanttColumns,
    planningGanttColumns
  } = systemData;

  const {
    myPlanningListColumns,
    myPlanningGanttColumns,
    clientPlanningListColumns,
    clientPlanningGanttColumns,
    plBasedDate
  } = data || {};

  const [columns, setColumn] = useState({
    myPlanningListColumns,
    myPlanningGanttColumns,
    clientPlanningListColumns,
    clientPlanningGanttColumns
  });

  const [_plBasedDate, setPlBasedDate] = useState(plBasedDate || false);

  const listColumns = isTemplate ? templatePlanningListColumns : planningListColumns;

  const handleChangeCheckbox = (columnType, key, tab) => e => {
    const columnsKeyPrefix = columnType === 'myColumns' ? 'myPlanning' : 'clientPlanning';
    const columnsKeySuffix = tab === 'list' ? 'ListColumns' : 'GanttColumns';

    const columnKey = columnsKeyPrefix + columnsKeySuffix;

    setColumn(prev => ({ ...prev, [columnKey]: { ...prev?.[columnKey], [key]: e.target.checked } }));
  };

  const tabs = [
    {
      key: 'list',
      title: 'Lista',
      icon: faList,
      myColumnTooltip: 'Alterar a visibilidade das colunas atualizará a visão do planejamento para todos',
      clientColumnTooltip: 'Permitir cliente visualizar as informações selecionadas',
      columns: listColumns,
      checkedMap: { myColumns: columns?.myPlanningListColumns, clientColumns: columns?.clientPlanningListColumns }
    },
    {
      key: 'gantt',
      title: 'Linha do Tempo',
      icon: faChartSimpleHorizontal,
      myColumnTooltip: 'Alterar a visibilidade das colunas atualizará a visão do planejamento para todos',
      clientColumnTooltip: 'Permitir cliente visualizar as informações selecionadas',
      columns: isTemplate ? templatePlanningGanttColumns : planningGanttColumns,
      checkedMap: {
        myColumns: columns?.myPlanningGanttColumns,
        clientColumns: columns?.clientPlanningGanttColumns
      }
    }
  ];

  const extraContentTop = () => {
    return (
      <>
        <Div direction="column" gap={spaces.space2} margin={spaces.space2}>
          <Div $fullWidth gap={spaces.space1}>
            <Switch size="small" checked={_plBasedDate} onChange={setPlBasedDate} />
            <Paragraph type="small">Vincular datas dos níveis aos itens</Paragraph>
            <TooltipIcon
              text={
                <Paragraph type="small" color={colors.white}>
                  Quando o vínculo está ativo, preencha datas nos itens e os níveis serão automaticamente calculados.
                  <br />
                  <br />
                  Desative o vínculo para preencher manualmente as datas nos níveis do planejamento.
                </Paragraph>
              }
              icon={faCircleQuestion}
              iconColor={colors.primary500}
            />
          </Div>
          {_plBasedDate !== (plBasedDate || false) && (
            <WarningBar
              width="100%"
              padding={spaces.space1}
              align="flex-start"
              warning="Ao alterar o vínculo, todas as datas já preenchidas nos níveis e subníveis do seu
              planejamento serão perdidas"
            />
          )}
        </Div>
        <Divider style={{ margin: 0 }} />
      </>
    );
  };

  return (
    <ConfigModal
      bodyHeight="340px"
      modalTitle="Configurar"
      tabs={tabs}
      handleChangeCheckbox={handleChangeCheckbox}
      onSubmit={() => onSubmit({ ...columns, plBasedDate: _plBasedDate })}
      onClose={onClose}
      extraContentTop={isTemplate ? undefined : extraContentTop}
      initialTab={view}
    />
  );
};

PlanningConfigModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  isTemplate: PropTypes.bool,
  view: PropTypes.string
};

export default PlanningConfigModal;
