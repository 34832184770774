import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faFolderOpen,
  faLayerGroup,
  faWrench,
  faScrewdriverWrench,
  faBoxOpen
} from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import emptyImg from '../Images/empty-state-image.svg';
import wrenchImg from '../Images/Medium_Copy.svg';
import { isImgUrl } from '../../lib/helpers/helper';
import useCRUD from '../../_Hooks/useCRUD';
import { colors } from '../../styles/style';
import { uploadMultipart } from '../../lib/helpers/uploadMultipart';
import useViewport from '../../_Hooks/useViewport';
import CenteredLoader from '../Loader/CenteredLoader';

import { FlexDiv, StyleLabel, StyledImg, HoveringShow, IconGlass, ImageDiv } from './ImageInputs.style';
import ImageModal from '../Modal/ImageModal';

const icons = bigSize => ({
  folder: {
    icon: faFolder,
    color: colors.primary600,
    iconSize: '2x'
  },
  wrench: {
    icon: faWrench,
    modalIcon: wrenchImg,
    iconSize: bigSize ? '2x' : ''
  },
  screwdriverWrench: {
    icon: faScrewdriverWrench,
    modalIcon: wrenchImg,
    iconSize: bigSize ? '2x' : '',
    color: colors.neutral300
  },
  boxOpen: {
    icon: faBoxOpen,
    modalIcon: emptyImg,
    iconSize: bigSize ? '2x' : '',
    color: colors.neutral300
  },
  folderOpen: {
    icon: faFolderOpen,
    color: colors.primary600,
    iconSize: '2x'
  },
  layer: {
    icon: faLayerGroup,
    color: colors.neutral300,
    iconSize: bigSize ? '2x' : ''
  }
});

const preventDefault = evt => {
  if (!evt?.preventDefault) return;
  evt.preventDefault();
  evt.stopPropagation();
  evt.nativeEvent.stopImmediatePropagation();
};

const ImageInput = ({
  id,
  value,
  size = '32px',
  disabled,
  onSubmit = f => f,
  onClick = f => f,
  cors,
  isModal,
  loading,
  images = [],
  hoveredShow = false,
  bigSize = false,
  clickImage,
  hideSideBar,
  labelMargin,
  noMarginMobile,
  justify,
  mobileWidth
}) => {
  const { handleCreate: uploadFile } = useCRUD({
    model: 'media',
    pathOptions: '/start',
    immediatelyLoadData: false
  });

  const { isMobile } = useViewport(window.innerWidth);
  const [showImageModal, setShowImageModal] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  let _images = images;
  if (!images?.length) _images = value == null || Array.isArray(value) ? value : [value];

  const handleModalClick = (e, evt) => {
    if (!clickImage) return;
    clickImage(e);
    if (!isMobile() && disabled) {
      setShowImageModal(!showImageModal);
      preventDefault(e);
      preventDefault(evt);
    }
  };

  const handleImageUpload = e => {
    const {
      files: [fileObject = null]
    } = e.target || { files: [] };

    if (!fileObject) {
      return null;
    }

    const file = {
      lastModified: fileObject.lastModified,
      lastModifiedDate: fileObject.lastModifiedDate,
      name: fileObject.name,
      size: fileObject.size,
      type: fileObject.type
    };

    setLoadingFile(!disabled);

    return uploadFile({ values: { file }, refresh: false })
      .then(data => {
        const { signUrls, uploadId } = data;
        return uploadMultipart(fileObject, signUrls).then(parts => {
          uploadFile({ values: { file, uploadId, parts }, postPathOptions: '/complete', refresh: false }).then(f => {
            onSubmit({ image: f.location });
            setLoadingFile(disabled);
          });
        });
      })
      .catch(() => {
        toast.error('Erro ao fazer upload da imagem');
      });
  };

  const { icon, color, iconSize, modalIcon } = icons(bigSize)[value] || {};

  if (!value || isImgUrl(value)) {
    let _val = value;
    if (isImgUrl(value) && cors) {
      _val = _val.indexOf('?') >= 0 ? `${_val}&t=${new Date().getTime()}` : `${_val}?t=${new Date().getTime()}`;
    }
    return (
      <>
        {value || disabled ? (
          <label
            htmlFor={`image${id}`}
            style={{
              display: 'flex',
              width: size,
              minWidth: size,
              ...(labelMargin && { margin: labelMargin })
            }}
          >
            <ImageDiv onClick={handleModalClick} aria-hidden="true">
              <StyledImg crossOrigin={cors ? 'anonymous' : null} size={size} alt={_val} src={_val || emptyImg} />
              {hoveredShow && !!_val && (
                <HoveringShow>
                  <IconGlass icon={faMagnifyingGlass} />
                </HoveringShow>
              )}
            </ImageDiv>
          </label>
        ) : (
          <StyleLabel htmlFor={`image${id}`} onClick={e => e.stopPropagation()} disabled={disabled} size={size}>
            <FontAwesomeIcon icon={faUpload} size="lg" />
          </StyleLabel>
        )}
        {!disabled && (
          <input
            id={`image${id}`}
            style={{ display: 'none' }}
            onClick={e => e.stopPropagation()}
            type="file"
            onChange={handleImageUpload}
          />
        )}
        {showImageModal && !!_images && (
          <ImageModal
            fileList={_images}
            startIndex={0}
            cors={cors}
            changeImage={0}
            onClose={handleModalClick}
            hideSideBar={hideSideBar}
          />
        )}
        {loadingFile && loading ? <CenteredLoader className="" /> : null}
      </>
    );
  }

  return isModal && modalIcon ? (
    <label htmlFor={`image${id}`} style={{ display: 'flex', alignSelf: 'center' }}>
      <StyledImg size={size} disabled={disabled} src={modalIcon} />
      <hoveringShow />
    </label>
  ) : (
    <FlexDiv size={size} $noMarginMobile={noMarginMobile} justify={justify} $mobileWidth={mobileWidth}>
      <FontAwesomeIcon icon={icon} size={isMobile() ? 'xs' : iconSize || '1x'} color={color} onClick={onClick} />
    </FlexDiv>
  );
};

ImageInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  images: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  cors: PropTypes.bool,
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  size: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  hoveredShow: PropTypes.bool,
  bigSize: PropTypes.bool,
  clickImage: PropTypes.func,
  hideSideBar: PropTypes.bool,
  labelMargin: PropTypes.string,
  noMarginMobile: PropTypes.bool,
  justify: PropTypes.string,
  mobileWidth: PropTypes.string
};

export default React.memo(ImageInput, isEqual);
