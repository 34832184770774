import React from 'react';
import PropTypes from 'prop-types';

import { Content, Description } from './NoNewCatalogContent.styled';
import { Title } from '../Text/Text';

const NoNewCatalogContent = ({ title, image, description }) => {
  return (
    <Content>
      <Title>{title}</Title>
      <div>{image}</div>
      <Description>{description}</Description>
    </Content>
  );
};

NoNewCatalogContent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default NoNewCatalogContent;
