import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

export const Description = styled.div`
  font-weight: ${fonts.weight500};
  font-size: ${fonts.sizeSm};
  color: ${colors.neutral600};
  line-height: ${spaces.space2};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Número de linhas antes de exibir o elipsis */
  overflow: hidden;
  height: ${spaces.space4};
`;

export const InfoDescription = styled.div`
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeMd};
  color: ${colors.neutral600};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Número de linhas antes de exibir o elipsis */
  overflow: hidden;
  height: ${spaces.space5};
  max-width: ${props => props.$maxWidth};
`;
