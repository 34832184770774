import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { faCalendar, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

import useViewport from '../../_Hooks/useViewport';

import BulkAction from './BulkAction';
import ConfirmModal from '../Modal/ConfirmModal';
import TooltipIcon from '../Tooltip/TooltipIcon';

import { BulkActionsChildrenContainer } from './ItemBulkActions.styles';
import { spaces } from '../../styles/style';
import DatePicker from '../Datepicker/Datepicker';

const PurchaseBulkActions = ({
  selectedItems,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedItems,
  bulkEdit,
  bulkDelete
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { isMobile } = useViewport(window.innerWidth);

  const { refurbishItemType } = useSelector(state => state.setup.enums) || {};
  const counter = selectedItems.length;

  const plural = useMemo(() => (selectedItems.length > 1 ? 's' : ''), [selectedItems]);

  const handleClose = () => {
    setSelectedRowKeys([]);
    setSelectedItems([]);
  };

  if (counter <= 0) return null;

  const allChildSelected = selectedItems.every(value => value.type === refurbishItemType.parent);

  return (
    <BulkAction
      text={`${selectedItems.length} ite${plural ? 'n' : 'm'}${plural} ${!isMobile() ? `selecionado${plural}` : ''}`}
      counter={counter}
      handleClose={handleClose}
    >
      <BulkActionsChildrenContainer>
        <DatePicker
          id="bulk-purchase-date-picker"
          name="purchaseDate"
          format="DD/MM/YY"
          allowClear={false}
          onChange={value => bulkEdit({ ids: selectedRowKeys, key: 'purchaseDate', value })}
          suffixIcon={null}
          size="small"
          disabled={allChildSelected}
          $textAlign="center"
          $width="100px"
          $height="28px"
        >
          <TooltipIcon text="Alterar datas" icon={faCalendar} style={{ height: 18, width: 18, color: '#FFF' }} />
        </DatePicker>
        <TooltipIcon
          id="delete-purchase-item"
          text="Excluir itens selecionados"
          icon={faTrashAlt}
          onClick={() => setShowConfirmModal(true)}
          style={{ height: 18, width: 18, marginLeft: spaces.space2, cursor: 'pointer' }}
        />
      </BulkActionsChildrenContainer>

      {showConfirmModal && (
        <ConfirmModal
          text={`Tem certeza que deseja apagar ${counter} ite${counter > 1 ? 'ns' : 'm'} selecionado${
            counter > 1 ? 's' : ''
          }?`}
          onSubmit={() => {
            bulkDelete(selectedRowKeys);
            handleClose();
            setShowConfirmModal(false);
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </BulkAction>
  );
};

PurchaseBulkActions.propTypes = {
  selectedItems: PropTypes.instanceOf(Array),
  setSelectedItems: PropTypes.func,
  selectedRowKeys: PropTypes.instanceOf(Array),
  setSelectedRowKeys: PropTypes.func,
  bulkEdit: PropTypes.func,
  bulkDelete: PropTypes.func
};

export default PurchaseBulkActions;
