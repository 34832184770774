import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { faFilter, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faFilter as faFilterHover, faSort as faSortMobile } from '@fortawesome/pro-solid-svg-icons';
import useViewport from '../../_Hooks/useViewport';

// components
import FilterButton from './FilterButton';
import FilterDefault from './FilterDefault';
import FilterRange from './FilterRange';
import Tag from '../Tag/Tag';
import Dropdown from '../Dropdown/Dropdown';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

import { colors } from '../../styles/style';

const Filters = ({
  filters = null,
  onFilter = () => {},
  displaySorting = true,
  children,
  position,
  clearFilter = true
}) => {
  const location = useLocation();
  const { catalogFilterType } = useSelector(state => state.setup.enums);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [hasFilter, setHasFilter] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [sorted, setSorted] = useState({ label: 'Recomendado' });
  const { isMobile } = useViewport(window.innerWidth);

  useEffect(() => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (search && search.search && Object.keys(search).length) {
      const newObj = { ...appliedFilters };
      newObj.Pesquisa = [{ label: search.search }];
      setAppliedFilters(newObj);
    }
  }, [location]);

  useEffect(() => {
    if (!filters) {
      setAppliedFilters({});
    }
  }, [filters]);

  const handleDefaultFilter = filter => values => {
    const newObj = { ...appliedFilters };
    newObj[filter.name] = Array.isArray(values) ? values?.filter(v => v.checked) : values;
    setAppliedFilters(newObj);
    setHasFilter(Object.keys(newObj).length !== 0);
    onFilter({ ...newObj, order: sorted.order });
  };

  const clearFilterObj = title => {
    const filter = filters.find(f => f.name === title);
    if (filter && filter.items) {
      filter.items = filter.items.map(item => ({ ...item, checked: false }));
    }
  };

  const handleClear = (title, value) => {
    clearFilterObj(title);
    let newObj;
    if (typeof value === 'object') {
      newObj = { ...appliedFilters };
      delete newObj[title];
    } else {
      const newObjArray = appliedFilters?.[title]?.filter(obj => obj.label !== value);
      if (newObjArray.length === 0) {
        newObj = { ...appliedFilters };
        delete newObj[title];
      } else {
        newObj = { ...appliedFilters, [title]: newObjArray };
      }
    }
    setAppliedFilters(newObj);
    setHasFilter(Object.keys(newObj).length !== 0);
    onFilter({ ...newObj, order: sorted.order });
  };

  const handleSort = sortField => {
    setSorted(sortField);
    const newObj = { ...appliedFilters };
    newObj.order = sortField.order;
    onFilter(newObj);
  };

  const handleClearAll = () => {
    filters.forEach(filter => clearFilterObj(filter.name));
    setAppliedFilters({});
    setHasFilter(false);
    onFilter({ order: sorted.order });
  };

  useEffect(() => {
    if (Object.keys(appliedFilters).length > 0) {
      handleClearAll();
    }
  }, [clearFilter]);

  const parseValue = value =>
    `R$ ${value
      ?.toFixed(2)
      ?.toLocaleString()
      ?.replace('.', ',')}`;

  const RenderData = ({ items, title }) => {
    if (!items) {
      return null;
    }
    if (items.length) {
      return items.map(item =>
        item.checked || title === 'Pesquisa' ? (
          <Tag key={item.label} title={title} value={item.label} onClick={() => handleClear(title, item.label)} />
        ) : null
      );
    }
    return items ? (
      <Tag
        value={`${parseValue(items.min || 0)} até ${parseValue(items.max || 0)}`}
        onClick={() => handleClear(title, items)}
      />
    ) : null;
  };

  RenderData.propTypes = {
    items: PropTypes.instanceOf(Object),
    title: PropTypes.string
  };

  const appliedFilter = (
    <div style={{ display: 'flex', position: 'relative' }}>
      {Object.keys(appliedFilters).map(key => (
        <RenderData key={key} items={appliedFilters[key]} title={key} />
      ))}
      <Button text type="primary" onClick={handleClearAll} icon={<FontAwesomeIcon icon={faTimes} />}>
        Limpar filtros
      </Button>
    </div>
  );

  return filters?.length ? (
    <>
      <div className="showMobile">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {children}
          {displaySorting && (
            <Dropdown mappingKey="sortCatalog" slim ghost forceSubmit={handleSort} trigger={['click']}>
              <div style={{ display: 'flex', color: colors.neutral500, alignItems: 'center' }}>
                <FontAwesomeIcon icon={faSortMobile} /> Ordenar
              </div>
            </Dropdown>
          )}
          <Button shape="text" onClick={() => setShowFilterModal(true)}>
            <FontAwesomeIcon color={hasFilter ? colors.primary600 : null} icon={hasFilter ? faFilterHover : faFilter} />
          </Button>
          {showFilterModal ? (
            <Modal
              centered
              title="Filtros"
              open
              hideFooter
              onClose={() => {
                setShowFilterModal(false);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                {filters.map(
                  filter =>
                    filter.showScreen &&
                    (filter.type === catalogFilterType.range ? (
                      <FilterButton
                        text="Valor"
                        data={appliedFilters[filter.name]}
                        key={filter.name}
                        buttonId={filter.key}
                      >
                        <FilterRange
                          data={appliedFilters[filter.name]}
                          closeModal={() => setShowFilterModal(false)}
                          onClick={handleDefaultFilter(filter)}
                        />
                      </FilterButton>
                    ) : (
                      <FilterButton text={filter.name} data={filter.items} key={filter.name} buttonId={filter.key}>
                        <FilterDefault
                          options={filter.items}
                          name={filter.name}
                          onClick={handleDefaultFilter(filter)}
                          closeModal={() => setShowFilterModal(false)}
                        />
                      </FilterButton>
                    ))
                )}
              </div>
            </Modal>
          ) : null}
        </div>
        {!isMobile() && Object.keys(appliedFilters || {}).length ? appliedFilter : <div> </div>}
      </div>
      <div>
        <div
          className="hideMobile"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
        >
          <div style={{ display: 'flex' }}>
            {filters.map(
              filter =>
                filter.showScreen &&
                (filter.type === catalogFilterType.range ? (
                  <FilterButton text="Valor" data={appliedFilters[filter.name]} key={filter.name} buttonId={filter.key}>
                    <FilterRange data={appliedFilters[filter.name]} onClick={handleDefaultFilter(filter)} />
                  </FilterButton>
                ) : (
                  <FilterButton
                    text={filter.name}
                    data={filter.items}
                    key={filter.name}
                    position={position}
                    buttonId={filter.key}
                  >
                    <FilterDefault options={filter.items} name={filter.name} onClick={handleDefaultFilter(filter)} />
                  </FilterButton>
                ))
            )}
          </div>
          {displaySorting && (
            <div>
              <Dropdown placement="bottomRight" mappingKey="sortCatalog" forceSubmit={handleSort} trigger={['click']}>
                <span role="button" tabIndex={0}>
                  Ordenar por: {sorted.label} <FontAwesomeIcon icon={faSort} />
                </span>
              </Dropdown>
            </div>
          )}
          {children}
        </div>
        {Object.keys(appliedFilters || {}).length ? appliedFilter : <div> </div>}
      </div>
    </>
  ) : null;
};

Filters.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onFilter: PropTypes.func,
  displaySorting: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  position: PropTypes.string,
  clearFilter: PropTypes.bool
};

export default Filters;
