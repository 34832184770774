import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EditCompositionModal from './Modal/EditCompositionModal';
import EditInstalmentModal from './Modal/EditInstalmentModal';
import EditItemModal from './Modal/EditItemModal';

const EditModalFactory = ({ type, ...props }) => {
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  if (type === 'instalment') {
    return <EditInstalmentModal {...props} />;
  }

  if (Number(type) === Number(refurbishItemType.composition)) {
    return <EditCompositionModal {...props} />;
  }

  return <EditItemModal type={type} {...props} />;
};

EditModalFactory.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EditModalFactory;
