import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Divider, Space } from 'antd';
import dayjs from 'dayjs';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { colors, Div, fonts, spaces } from '../../styles/style';
import vobiLogo from '../../components/Images/vobi-logo.svg';
import vobiPayLogo from '../../components/Images/vobipayLogo.svg';

import CenteredLoader from '../../components/Loader/CenteredLoader';
import useCRUD from '../../_Hooks/useCRUD';
import ViewEntityInfo from '../../components/Description/ViewEntityInfo';
import useViewport from '../../_Hooks/useViewport';
import Content from '../../components/Content/Content';
import { Description, Paragraph, Title } from '../../components/Text/Text';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { addressConcat, formatDocument, transformHtmlToWhatsappMessage } from '../../lib/helpers/helper';
import Button from '../../components/Button/Button';
import FinancialEventService from '../../lib/gtm/financial';
import { ComponentToPdf } from '../../lib/helpers/exportToPdf';

const Receipt = ({ id }) => {
  const gtmEventService = FinancialEventService();
  const baseUrl = window.location.origin;
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const printComponentRef = useRef();
  const history = useHistory();
  const [isPrinting, setIsPrinting] = useState(false);
  const whatsappMessage = 'Gostaria de compartilhar um recibo com você, acesse agora através desse link: ';

  const { data, handleGet, loading } = useCRUD({
    model: 'receipt',
    options: {
      include: ['company']
    },
    pathOptions: `/${id}`,
    immediatelyLoadData: false
  });

  const { installmentInfo, dest, additionalInfo, emit, company } = data || {};

  const link = `${baseUrl}/rc/${data?.id}`;
  const destDocumentLabel = dest?.personType === 'pj' ? 'CNPJ' : 'CPF';
  const emitDocumentLabel = emit?.personType === 'pj' ? 'CNPJ' : 'CPF';
  const isExpense = installmentInfo?.billType === 'expense';

  const payer = isExpense
    ? {
        name: emit?.name,
        document: emit?.cnpj,
        documentLabel: emitDocumentLabel,
        address: emit?.city || '' - emit?.state || ''
      }
    : {
        name: dest?.name,
        document: dest?.document || dest?.doc,
        documentLabel: destDocumentLabel,
        address: dest?.city || '' - dest?.state || ''
      };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
      gtmEventService.onExport({ entity: 'receipt', fileType: 'pdf' });
    }
  });

  const getReceipt = () => {
    return handleGet().then(receipt => {
      if (!receipt?.id) {
        toast.error('Desculpe. Página não encontrada.');
        history.push('/profissional/dashboard');
      }
    });
  };

  useEffect(() => {
    getReceipt();
  }, []);

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting]);

  const signature =
    installmentInfo?.billType === 'income' ? (
      <Div gap={spaces.space2}>
        <Div height={spaces.space9} width={spaces.space9} id="qr-code">
          <QRCode value={link} size={72} />
        </Div>

        <Space direction="vertical" size={10}>
          <Div direction="column" align="start">
            <Paragraph type="small" color={colors.neutral800} weight={fonts.weight600}>
              {emit?.name}
            </Paragraph>
            <Paragraph type="small" color={colors.neutral800} weight={fonts.weight600}>
              {`${emitDocumentLabel} ${formatDocument(emit?.cnpj)}`}
            </Paragraph>
          </Div>
          <Div direction="column" align="start">
            <Description>Comprovante disponível em:</Description>
            <Description id="link">{link}</Description>
          </Div>
        </Space>
      </Div>
    ) : (
      <Div gap={spaces.space2} direction="column">
        <Divider style={{ margin: '0px', borderColor: colors.neutral800, width: '296px' }} />
        <Div direction="column">
          <Paragraph type="small" color={colors.neutral800} weight={fonts.weight600}>
            {dest?.name ? dest?.name : null}
          </Paragraph>
          <Paragraph type="small" color={colors.neutral800} weight={fonts.weight600}>
            {dest?.document ? `${destDocumentLabel} ${formatDocument(dest?.document)} ` : null}
          </Paragraph>
        </Div>
      </Div>
    );

  const line = (label, value, _id, style, isVobiPay) => {
    return !value ? null : (
      <Div
        borderBottom={`1px solid ${colors.neutral100}`}
        padding={`${parseInt(spaces.space1, 10) + parseInt(spaces.space0, 10)}px 0px`}
        $fullWidth
        align="baseline"
        justify="space-between"
      >
        <Paragraph type="small" color={colors.neutral800}>
          {label}
        </Paragraph>
        <Div gap={spaces.space0}>
          <Paragraph style={style} type="small" color={colors.neutral800} weight={fonts.weight500} id={_id}>
            {`${value}${isVobiPay ? ' via' : ''}`}
          </Paragraph>
          {isVobiPay ? (
            <img style={{ width: spaces.space7 }} className="vobi-logo" src={vobiPayLogo} alt="Template" />
          ) : null}
        </Div>
      </Div>
    );
  };

  return loading ? (
    <CenteredLoader />
  ) : (
    <ComponentToPdf style={{ height: _isMobile ? '100%' : '1122px' }} ref={printComponentRef}>
      <Div direction="column">
        <Content
          padding={spaces.space4}
          bgColor={colors.white}
          style={{ width: _isMobile ? '100%' : '794px', maxHeight: _isMobile ? '100%' : '1122px' }}
        >
          <Div align="start" direction={_isMobile ? 'column' : 'row'} gap={spaces.space2}>
            {company?.logo ? (
              <img
                style={{ width: '96px', height: '96px', borderRadius: spaces.space0 }}
                crossOrigin="anonymous"
                alt={company?.name}
                src={`${company?.logo}&t=${new Date().getTime()}`}
              />
            ) : null}
            <Div
              align="baseline"
              direction={_isMobile ? 'column' : 'row'}
              justify="flex-start"
              gap={_isMobile ? spaces.space1 : spaces.space6}
              $fullWidth
            >
              <ViewEntityInfo data={emit} />

              <ViewEntityInfo data={{ ...dest, name: dest?.legalName || dest?.name }} />
            </Div>
          </Div>
          <Divider />
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Div gap={spaces.space0}>
              <Title type="large" color={colors.neutral800} weight={fonts.weight700}>
                {` Recibo de pagamento`}
              </Title>
              <Title type="large" color={colors.neutral500} id="code">
                {`#${data?.code}`}
              </Title>
            </Div>

            <div>
              <Paragraph color={colors.neutral800} weight={fonts.weight600}>
                {installmentInfo?.paymentName}
              </Paragraph>
              <Content padding={0}>
                {line('Data de competência', dayjs(installmentInfo?.billingDate).format('DD/MM/YYYY'), 'billingDate')}
                {line('Parcela', installmentInfo?.description)}
                {line('Data de vencimento', dayjs(installmentInfo?.dueDate).format('DD/MM/YYYY'), 'dueDate')}
                {line('Data de pagamento', dayjs(installmentInfo?.paidDate).format('DD/MM/YYYY'), 'paidDate')}
                {line(
                  'Forma de pagamento',
                  installmentInfo?.paymentType,
                  'paymentType',
                  {},
                  data?.createdBy?.isVobiPay
                )}
                {line('Valor pago', formatCurrency(installmentInfo?.price, { currencySymbol: 'R$' }))}
              </Content>
            </div>
            <div>
              <Paragraph color={colors.neutral800} weight={fonts.weight600}>
                Dados do pagador
              </Paragraph>
              <Content padding={0}>
                {line('Nome', payer?.name)}
                {line(payer?.documentLabel, formatDocument(isExpense ? emit?.cnpj : dest?.document || dest?.doc))}
                {line('Cidade', payer?.address)}
              </Content>
            </div>
            {additionalInfo?.name ? (
              <div>
                <Paragraph color={colors.neutral800} weight={fonts.weight600}>
                  Informações adicionais
                </Paragraph>
                <Content padding={0}>
                  {line('Projeto', additionalInfo?.name)}
                  {line('Endereço', addressConcat(additionalInfo), 'address', { maxWidth: '400px', textAlign: 'end' })}
                </Content>
              </div>
            ) : null}
          </Space>
          <Div style={{ paddingTop: '140px' }} justify="center" direction="column" gap={spaces.space3}>
            {signature}
            <Paragraph type="small" color={colors.neutral800} id="receive-createdAt">
              {`Recibo gerado em ${dayjs(data?.createdAt).format('DD/MM/YYYY')} através da  `}
              <img style={{ scale: '1.1' }} className="vobi-logo" src={vobiLogo} alt="Template" />
            </Paragraph>
          </Div>
        </Content>
        {!isPrinting ? (
          <Div gap={spaces.space1} padding={`${spaces.space5} ${spaces.space5} ${spaces.space10} ${spaces.space5} `}>
            <Button className="hide-on-print hideMobile" type="primary" onClick={() => setIsPrinting(true)}>
              Exportar PDF
            </Button>
            <a
              href={`https://wa.me/?text=${encodeURI(transformHtmlToWhatsappMessage(whatsappMessage))}${link}`}
              target="_blank"
            >
              <Button className="hide-on-print" type="primary">
                Enviar por Whatsapp
              </Button>
            </a>
          </Div>
        ) : null}
      </Div>
    </ComponentToPdf>
  );
};
Receipt.propTypes = {
  id: PropTypes.number
};

export default Receipt;
