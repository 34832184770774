import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownWideShort, faPlusCircle, faPenCircle } from '@fortawesome/pro-solid-svg-icons';
import { faChevronsUp } from '@fortawesome/pro-regular-svg-icons';

import { scrollTo } from 'virtuallist-antd';

import SpecificationBulkActions from '../BulkActions/SpecificationBulkActions';
import EditModalFactory from '../EditModalFactory';
import ListDrawer from '../Drawer/ListDrawer';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import Button from '../Button/Button';

import { units } from '../../lib/mapping/Form/extraFormSchema';

import VirtualizedTable from './VirtualizedTable';
import AddTableButtonV2 from '../Button/AddTableButtonV2';
import { spaces, colors } from '../../styles/style';
import Draggable from '../Draggable/Draggable';
import TableNoContent from '../NoContent/TableNoContent';
import useViewport from '../../_Hooks/useViewport';
import { store } from '../../lib/config/redux-store';

const SpecificationTable = ({
  grouped,
  data,
  model = 'refurbish-items',
  handleClickExportPdf,
  isDefaultOrder,
  list,
  handleSubmitRefurbishItems,
  levelList,
  selectedItems,
  selectedRowKeys,
  setSelectedItems,
  setSelectedRowKeys,
  setList,
  handleLoad,
  supplierList = [],
  groupList = [],
  categoryList = [],
  refetchLists,
  refetchOptions,
  openEditModal,
  showUnitItem,
  setShowUnitItem,
  expandItem,
  setExpandedItem,
  isTemplate,
  isCustomer,
  isApply,
  disableEditAndCreate,
  noContentText = 'Adicione o primeiro nível para iniciar a construção do seu orçamento',
  ...props
}) => {
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();
  const { user } = useSelector(state => state.authReducer);
  const { id, users: refurbishUser, clientColumns = {} } = data || {};
  const { refurbishItemType, userType } = useSelector(state => state.setup.enums);
  const [searchDrawer, setSearchDrawer] = useState({ show: false });
  const [editItemModalParams, setEditItemModalParams] = useState({});
  const [isScrolling, setScrolling] = useState(false);
  const [sideBarTab, setSideBarTab] = useState(null);
  const { setup } = store.getState();
  const { refurbishItemType: refurbishEnumItem = {} } = setup.enums;

  const _list = useRef([]);

  const isGrouped = !!grouped?.model;
  const isProviderOrOperator = user.userType === userType.provider.value || user.userType === userType.operator.value;
  const idCompany =
    user.userType === userType.provider.value ? user.idCompany : refurbishUser?.[0]?.idCompany || data.idCompany;
  const childrenColumn = isGrouped ? 'item' : 'children';

  const handleRowClick = ({ id: _id, type }) => {
    if (type && type !== refurbishItemType.parent) {
      setEditItemModalParams({ id: _id, type, show: true });
    }
  };

  const handleItemClick = _data => {
    const { modal, values } = _data;
    if (modal === 'catalogDrawer') {
      setSearchDrawer({ show: true, values });
    }
    if (modal === 'createComposition') {
      setEditItemModalParams({ type: refurbishItemType.composition, show: true, values });
    }

    if (modal === 'createProduct') {
      setEditItemModalParams({ type: refurbishItemType.product, show: true });
    }
    if (modal === 'createLabor') {
      setEditItemModalParams({ type: refurbishItemType.labor, show: true });
    }
  };

  const handleSubmitCatalogDrawer = newData => {
    const { create } = newData;
    const { values } = searchDrawer;
    return handleSubmitRefurbishItems({ values: { ...values, ...newData }, create });
  };

  useEffect(() => {
    if (openEditModal) {
      setEditItemModalParams(openEditModal.params);
      if (openEditModal?.tab) setSideBarTab(openEditModal?.tab);
    }
  }, [openEditModal]);

  const AddComponent = ({ data: _data, index }) => (
    <div id={`inlineButton${_data?.id}`} className="inlineButtons">
      <div className="buttonContainer">
        {!_data.idParent && !isGrouped ? (
          <>
            <Button
              size="small"
              type="primary"
              roundPadding={`0 ${spaces.space2} 0 ${spaces.space0}`}
              shape="round"
              onClick={() => {
                const afterBoundary = list?.[index + 1]?.order || Number(_data.order) + 1;
                const order = (Number(_data?.order || 0) + Number(afterBoundary)) / 2;
                handleSubmitRefurbishItems({
                  values: { type: refurbishItemType?.parent, order },
                  dragIsLevel: true
                });
              }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  margin: `0 ${spaces.space1} 0 2px`,
                  color: colors.white
                }}
                size="lg"
              />
              NÍVEL
            </Button>
            <Button
              size="small"
              type="primary"
              roundPadding={`0 ${spaces.space2} 0 ${spaces.space0}`}
              shape="round"
              onClick={() => {
                setExpandedItem(_data?.id);
                handleSubmitRefurbishItems({
                  values: { type: refurbishItemType?.parent, idParent: _data?.id }
                });
              }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  margin: `0 ${spaces.space1} 0 2px`,
                  color: colors.white
                }}
                size="lg"
              />
              SUBNÍVEL
            </Button>
          </>
        ) : null}
        <AddTableButtonV2
          getPopupContainer={() => document.getElementById(`inlineButton${_data?.id}`)}
          model={model}
          defaultValues={{ idParent: _data?.id }}
          onSubmit={options => {
            const values = {
              idParent: _data?.type === refurbishItemType?.parent ? _data?.id : _data?.idParent,
              idParentLevel: _data?.idParentLevel,
              ...options?.values,
              ...(grouped?.key && {
                [grouped?.key]: !_data?.type ? _data?.id : _data?.[grouped?.key],
                idParent: levelList?.[0]?.value
              })
            };

            if (_data?.type === refurbishItemType?.parent) {
              setExpandedItem(_data?.id);
            } else {
              const afterBoundary = Number(_data?.nextOrder) || Number(_data.order) + 1;
              values.order = (Number(_data?.order || 0) + Number(afterBoundary)) / 2;
            }

            if (options?.externalAction) return handleItemClick({ modal: options?.modal, values });

            return handleSubmitRefurbishItems({ values, isVirtual: true });
          }}
        />
        {isMobile && refurbishEnumItem?.notParent?.includes(_data?.type) && !isCustomer && (
          <Button
            size="small"
            type="primary"
            roundPadding={`0 ${spaces.space1} 0 ${spaces.space0}`}
            shape="round"
            onClick={() => handleRowClick(_data)}
          >
            <FontAwesomeIcon
              icon={faPenCircle}
              style={{
                margin: `0 ${spaces.space1} 0 2px`,
                color: colors.white
              }}
              size="lg"
            />
            EDITAR
          </Button>
        )}
      </div>
    </div>
  );

  AddComponent.propTypes = {
    data: PropTypes.instanceOf(Object),
    index: PropTypes.number
  };

  return (
    <>
      <VirtualizedTable
        list={list}
        checkStrictly={false}
        AddComponent={!isCustomer && !isApply && !disableEditAndCreate && AddComponent}
        expandItem={expandItem}
        childrenColumnName={childrenColumn}
        isGrouped={isGrouped}
        onClick={(isCustomer || !isMobile) && !disableEditAndCreate ? handleRowClick : () => null}
        moveRowProps={{
          isDefaultOrder,
          submitMove: handleSubmitRefurbishItems
        }}
        withCommonRow={isGrouped}
        selectedRowKeys={selectedRowKeys}
        onScroll={setScrolling}
        customEmptyState={
          isApply || isCustomer ? null : (
            <TableNoContent
              description={noContentText}
              buttonTitle="Novo Nível"
              buttonId="newLevel"
              handleClick={() => handleSubmitRefurbishItems({ values: { type: refurbishItemType?.parent, order: 1 } })}
              icon={<FontAwesomeIcon icon={faArrowDownWideShort} />}
              isMobile={isMobile}
              renderButton={!disableEditAndCreate}
            />
          )
        }
        $paddingBottom="200px"
        {...props}
      />
      {isScrolling && (
        <Draggable
          onClick={() => {
            scrollTo({ row: 1 });
            setScrolling(false);
          }}
          defaultPosition={
            isMobile
              ? {
                  x: window.innerWidth - 75,
                  y: -24
                }
              : { x: window.innerWidth - 175, y: -24 }
          }
        >
          <div
            style={{
              backgroundColor: colors.primary600,
              ...(isMobile
                ? {
                    width: 34,
                    height: 34,
                    padding: '6px 11px'
                  }
                : {
                    width: 48,
                    height: 48,
                    padding: `${spaces.space1} 12px`
                  }),
              borderRadius: 24,
              zIndex: 5,
              cursor: 'pointer',
              position: 'fixed'
            }}
          >
            <Tooltip placement="top" title="Voltar ao topo">
              <FontAwesomeIcon icon={faChevronsUp} size={isMobile ? '1x' : '2x'} color={colors.white} />
            </Tooltip>
          </div>
        </Draggable>
      )}
      {isProviderOrOperator && (
        <CatalogDrawer
          onSubmit={handleSubmitCatalogDrawer}
          onClose={() => setSearchDrawer({ show: false })}
          open={searchDrawer.show}
          idParent={searchDrawer?.values?.idParent}
          idParentLevel={searchDrawer?.values?.idParentLevel}
          referModel={isTemplate ? 'templateItem' : 'refurbishItems'}
          idReference={id}
          referKey={isTemplate ? 'idTemplate' : 'idRefurbish'}
        />
      )}
      {selectedItems?.length ? (
        <SpecificationBulkActions
          selectedItems={selectedItems}
          selectedRowKeys={selectedRowKeys}
          setSelectedItems={setSelectedItems}
          setSelectedRowKeys={setSelectedRowKeys}
          setList={setList}
          handleLoad={handleLoad}
          idRefurbish={id}
          items={_list.current}
          showStatus
          showAll
          isGrouped={isGrouped}
          idCompany={idCompany}
          supplierList={supplierList}
          groupList={groupList}
          categoryList={categoryList}
          list={list}
          isDefaultOrder={isDefaultOrder}
          refetchOptions={refetchOptions}
          handleClickExportPdf={handleClickExportPdf}
          isTemplate={isTemplate}
          levelList={levelList}
        />
      ) : null}
      {editItemModalParams?.show ? (
        <EditModalFactory
          {...editItemModalParams}
          model={model}
          idReference={id}
          tab={sideBarTab}
          onClose={() => {
            handleLoad();
            setEditItemModalParams({});
          }}
          groupedReference={editItemModalParams?.values?.groupedReference}
          initialValues={editItemModalParams?.values || {}}
          isTemplate={isTemplate}
          renderColumns={isCustomer && clientColumns}
          readOnly={isApply}
          idCompany={idCompany}
          parentData={data}
        />
      ) : null}
      {showUnitItem && (
        <ListDrawer
          onClose={() => {
            setShowUnitItem(false);
            refetchLists('unit')();
          }}
          model="unit"
          where={{ isActive: true }}
          tab="units"
          title="Configuração das unidades"
          subtitle="Unidades"
          renderTabs={units}
          textToDelete="Ao excluir esse item, ele será removido permanentemente da
          sua lista e de todos os produtos e serviços em que estiver sendo utilizado."
        />
      )}
    </>
  );
};

SpecificationTable.propTypes = {
  grouped: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  model: PropTypes.string,
  handleClickExportPdf: PropTypes.func,
  isDefaultOrder: PropTypes.bool,
  list: PropTypes.instanceOf(Array),
  handleSubmitRefurbishItems: PropTypes.func,
  levelList: PropTypes.instanceOf(Array),
  selectedItems: PropTypes.instanceOf(Array),
  selectedRowKeys: PropTypes.instanceOf(Array),
  setSelectedItems: PropTypes.func,
  setSelectedRowKeys: PropTypes.func,
  setList: PropTypes.func,
  handleLoad: PropTypes.func,
  supplierList: PropTypes.instanceOf(Array),
  groupList: PropTypes.instanceOf(Array),
  categoryList: PropTypes.instanceOf(Array),
  refetchLists: PropTypes.func,
  refetchOptions: PropTypes.instanceOf(Object),
  openEditModal: PropTypes.instanceOf(Object),
  showUnitItem: PropTypes.bool,
  setShowUnitItem: PropTypes.func,
  expandItem: PropTypes.number,
  setExpandedItem: PropTypes.func,
  isTemplate: PropTypes.bool,
  isCustomer: PropTypes.bool,
  isApply: PropTypes.bool,
  disableEditAndCreate: PropTypes.bool,
  noContentText: PropTypes.string
};

export default SpecificationTable;
