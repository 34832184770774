import React from 'react';

import LogoIcon from '../../../components/Icons/Logo';
import { Header } from '../../ViewPublic.style';
import Quotation from './Quotation';
import { Div, spaces } from '../../../styles/style';

const QuotationViewPublic = () => {
  return (
    <>
      <Header $maxWidth="none">
        <div>
          <LogoIcon scale={0.6} />
        </div>
      </Header>
      <Div margin={spaces.space2}>
        <Quotation isView />
      </Div>
    </>
  );
};

export default QuotationViewPublic;
