import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { Context as AuthContext } from '../../contexts/Context';
import CenteredLoader from '../../components/Loader/CenteredLoader';

const Login = lazy(() => import('../../_Pages/Login/Login'));
const RecoveryPassword = lazy(() => import('../../_Pages/Login/RecoverPassword'));
const ResetPassword = lazy(() => import('../../_Pages/Login/ResetPassword'));
const CreateUser = lazy(() => import('../../_Pages/Login/CreateUser'));

const baseUrl = process.env.REACT_APP_AUTH_API_URL || process.env.REACT_APP_API_URL;
const ScreenLogin = ({ match }) => {
  const { url } = match;

  return (
    <Suspense fallback={<CenteredLoader />}>
      <AuthContext model="auth" immediatelyLoadData={false} baseUrl={baseUrl}>
        <Switch>
          <Route exact path={`${url}/recuperar-senha`} component={RecoveryPassword} />
          <Route exact path={`${url}/criar-senha`} component={ResetPassword} />
          <Route exact path={`${url}/alterar-senha`} component={ResetPassword} />
          <Route exact path={`${url}/cadastrar`} component={CreateUser} />
          <Route path={`${url}`} component={Login} />
        </Switch>
      </AuthContext>
    </Suspense>
  );
};

ScreenLogin.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired
};

export default ScreenLogin;
