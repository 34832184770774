import * as Yup from 'yup';

import MainChallenges from './MainChallenges';
import AboutYou from './AboutYou';
import Services from './Services';
import CompanyDetails from './CompanyDetails';
import ProjectDetails from './ProjectDetails';
import FirstProject from './FirstProject';

export default {
  'principais-desafios': {
    Content: MainChallenges,
    nextStep: 'sobre-voce',
    progressionBar: '16%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        mainChallenge: Yup.number().required(),
        otherChallenges: Yup.string().when('mainChallenge', {
          is: 11,
          then: Yup.string().required(),
          otherwise: Yup.string().notRequired()
        }),
        tools: Yup.array(Yup.number()).required(),
        otherTools: Yup.string().when('tools', {
          is: tools => Array.isArray(tools) && tools.includes(6),
          then: Yup.string().required(),
          otherwise: Yup.string().notRequired()
        })
      })
    })
  },
  'sobre-voce': {
    Content: AboutYou,
    previousStep: 'principais-desafios',
    nextStep: 'servicos-realizados',
    progressionBar: '32%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        configDescription: Yup.number().required(),
        otherDescription: Yup.string().when('configDescription', {
          is: 13,
          then: Yup.string().required(),
          otherwise: Yup.string().notRequired()
        }),
        mainActivity: Yup.number().required(),
        otherMainActivity: Yup.string().when('mainActivity', {
          is: 5,
          then: Yup.string().required(),
          otherwise: Yup.string().notRequired()
        })
      })
    })
  },
  'servicos-realizados': {
    Content: Services,
    progressionBar: '48%',
    previousStep: 'sobre-voce',
    nextStep: 'tamanho-empresa',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        servicesOffered: Yup.object()
          .default({})
          .when('mainActivity', {
            is: 4,
            then: Yup.object().shape({
              managementAdmin: Yup.number().notRequired(),
              managementContract: Yup.number().notRequired(),
              constructionSupervision: Yup.number().required(),
              engineeringProjects: Yup.number().required(),
              architectureProjects: Yup.number().required(),
              specializedServices: Yup.number().required(),
              buildToSell: Yup.number().required(),
              productSales: Yup.number().required(),
              other: Yup.number().required(),
              otherServiceOffered: Yup.string().when('other', {
                is: 3,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required()
              })
            })
          })
          .when('mainActivity', {
            is: 1,
            then: Yup.object().shape({
              managementAdmin: Yup.number().required(),
              managementContract: Yup.number().required(),
              constructionSupervision: Yup.number().notRequired(),
              engineeringProjects: Yup.number().notRequired(),
              architectureProjects: Yup.number().notRequired(),
              specializedServices: Yup.number().required(),
              buildToSell: Yup.number().required(),
              productSales: Yup.number().required(),
              other: Yup.number().required(),
              otherServiceOffered: Yup.string().when('other', {
                is: 3,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required()
              })
            })
          })
          .when('mainActivity', {
            is: value => value !== 4 && value !== 1,
            then: Yup.object().shape({
              managementAdmin: Yup.number().required(),
              managementContract: Yup.number().required(),
              constructionSupervision: Yup.number().required(),
              engineeringProjects: Yup.number().required(),
              architectureProjects: Yup.number().required(),
              specializedServices: Yup.number().required(),
              buildToSell: Yup.number().required(),
              productSales: Yup.number().required(),
              other: Yup.number().required(),
              otherServiceOffered: Yup.string().when('other', {
                is: 3,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required()
              })
            })
          })
          .test('at-least-one-selected', 'Pelo menos um serviço deve ser Principal Serviço', value => {
            return Object.values(value || {}).some(val => val === 1);
          })
      })
    })
  },
  'tamanho-empresa': {
    Content: CompanyDetails,
    previousStep: 'servicos-realizados',
    nextStep: 'detalhes-obras',
    progressionBar: '64%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        teamSize: Yup.number()
          .required()
          .moreThan(0),
        adminSize: Yup.number()
          .required()
          .moreThan(0)
      })
    })
  },
  'detalhes-obras': {
    Content: ProjectDetails,
    previousStep: 'tamanho-empresa',
    nextStep: 'primeiro-projeto',
    progressionBar: '80%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        projectQuantity: Yup.number().required(),
        projectSize: Yup.number().when('mainActivity', {
          is: value => value !== 4,
          then: Yup.number().required(),
          otherwise: Yup.number().notRequired()
        }),
        revenue: Yup.number().required()
      })
    })
  },
  'primeiro-projeto': {
    Content: FirstProject,
    progressionBar: '90%',
    hideFooter: true,
    previousStep: 'detalhes-obras'
  }
};
