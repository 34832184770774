import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';
import Modal from './Modal';

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    background-color: #ffff !important;
  }
  .ant-modal-header {
    border-bottom: 1px solid black;
  }
`;

export const ShareText = styled.p`
  color: ${colors.neutral400};
  font-weight: 400;
  font-size: ${fonts.sizeSm};
  line-height: 15px;
`;

export const EnableShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${spaces.space4};
  p {
    font-size: ${fonts.sizeSm};
    line-height: 18px;
    margin-bottom: ${spaces.space1};
  }

  .enableShareText {
    margin-bottom: ${spaces.space1};
    display: flex;
    align-items: center;
    span {
      color: #000;
      margin-left: ${spaces.space2};
      font-size: ${fonts.sizeSm};
    }
  }
`;

export const FormContainer = styled.div`
  margin-bottom: ${spaces.space3};

  .info-form-label {
    font-weight: ${fonts.weight600};
    font-size: ${fonts.sizeMd};
  }
`;
