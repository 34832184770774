import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';

import { colors } from '../../styles/style';

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    width: 90%;
  }

  svg {
    margin-left: auto;
    --fa-secondary-opacity: 1;
    --fa-primary-color: ${props => props.primaryColor};
    --fa-secondary-color: ${props => props.secondaryColor};
  }
`;

export default function addSortIconToColumns(columns) {
  return columns.map(column => {
    if (!column?.sorter) return column;

    const titleFn = titleProps => {
      const sorterMap = {
        ascend: {
          text: 'Clique organiza por descendente',
          colors: [colors.primary500, colors.neutral300]
        },
        descend: {
          text: 'Clique para cancelar organização',
          colors: [colors.neutral300, colors.primary500]
        }
      };

      const sortedColumn = titleProps.sortColumns?.find(({ column: sortColumn }) => sortColumn?.key === column?.key);

      return (
        <TitleContainer
          primaryColor={sortedColumn?.order ? sorterMap[sortedColumn?.order]?.colors[0] : colors.neutral300}
          secondaryColor={sortedColumn?.order ? sorterMap[sortedColumn?.order]?.colors[1] : colors.neutral300}
        >
          <span>{typeof column?.title === 'function' ? column?.title() : column?.title}</span>
          <Tooltip
            title={sortedColumn?.order ? sorterMap[sortedColumn?.order]?.text : 'Clique organiza por ascendente'}
          >
            <FontAwesomeIcon icon={faSort} />
          </Tooltip>
        </TitleContainer>
      );
    };

    return {
      ...column,
      title: titleFn
    };
  });
}
