import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, colors, radius, spaces } from '../../styles/style';
import Content from '../Content/Content';
import Button from '../Button/Button';

const StyledContent = styled(Content)`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  border-radius: ${radius.radius1};
  overflow-y: auto;
  @media (max-width: ${breakpoints.tablet}) {
    overflow-y: inherit;
  }

  .flex-between-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 0 ${spaces.space2} 0;
  }

  ::-webkit-scrollbar {
    display: block;
    width: ${spaces.space0};
    height: ${spaces.space0};
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: ${radius.radius1};
    border-radius: ${radius.radius1};
    background: ${colors.neutral200};
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: ${radius.radius1};
    border-radius: ${radius.radius1};
    background: ${colors.primary600};
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  align-self: center;
  gap: ${spaces.space4};
  color: ${colors.neutral500};

  @media (max-width: ${breakpoints.tablet}) {
    width: auto;
  }
`;

const DoubtsContainer = styled.div`
  background-color: ${colors.neutral75};
  border-radius: ${spaces.space1};
  display: flex;
  width: 432px;
  flex-direction: column;
  margin-bottom: ${spaces.space3};
  @media (max-width: ${breakpoints.mobile}) {
    width: auto;
  }
`;

const DoubtsTopContainer = styled.div`
  padding: ${spaces.space2};
  display: flex;
  border-bottom: 1px solid ${colors.neutral100};
  flex-direction: column;
`;

const KnowMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Buttons = styled(Button)`
  align-self: auto;
  margin-left: ${spaces.space1};
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: ${spaces.space4};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.$multipleButtons ? 'flex-start' : 'center')};
  text-align: ${props => (props.$multipleButtons ? 'left' : 'center')};
  width: ${props => (props.$multipleButtons ? '120px' : 'auto')};
  gap: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    ${props =>
      props.$multipleButtons &&
      css`
        flex-direction: row;
        width: 100%;
        height: ${spaces.space11};
      `}

    border-bottom: 1px solid ${colors.primary100};
    padding: ${spaces.space2} 0px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  background-color: ${colors.primary50};
  width: 120px;
  height: 120px;
  border-radius: ${spaces.space1};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.tablet}) {
    ${props =>
      props.$multipleButtons &&
      css`
        width: ${spaces.space7};
        height: ${spaces.space7};
      `}

    margin: 0px;
  }

  &:hover {
    background-color: ${colors.primary100};
    border: 1px solid ${colors.primary200};

    .svg-inline--fa {
      color: ${colors.primary500};
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  @media (max-width: ${breakpoints.tablet}) {
    width: ${spaces.space7};
  }
`;

export {
  StyledContent,
  MainContainer,
  ActionsContainer,
  ActionContainer,
  IconContainer,
  DoubtsContainer,
  DoubtsTopContainer,
  KnowMore,
  Buttons,
  Icon
};
