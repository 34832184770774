import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromLine, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { Div, colors, spaces, fonts } from '../../../styles/style';
import Button from '../../Button/Button';
import CommentListV2 from '../../List/CommentListV2';
import Modal from '../../Modal/Modal';
import { Paragraph } from '../../Text/Text';
import TooltipIcon from '../../Tooltip/TooltipIcon';

const ChatContainer = styled(Div)`
  min-width: ${props => (props.$show ? props.$width : '0')};
  height: 100%;
  position: relative;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
`;
const ChatContent = styled(Div)`
  position: absolute;
  background-color: ${colors.white};
  height: calc(100% - (${spaces.space8} + ${spaces.space7} + 1px));
  right: 0;
  top: ${spaces.space8};
  bottom: calc(${spaces.space7} + 1px);
  border-left: ${props => (props.$show ? `1px solid ${colors.neutral100}` : 'none')};
  border-top: ${props => (props.$show ? `1px solid ${colors.neutral100}` : 'none')};
  width: ${props => (props.$show ? props.$width : '0')};
  transition: width 0.3s ease-in-out;
  overflow: hidden;
`;

export const calcDrawerWidth = (showComments, isMobile, desktopWidth = '780px', chatWidth = '360px') => {
  if (isMobile) return '100%';
  if (showComments) return `calc(${desktopWidth} + ${chatWidth})`;
  return desktopWidth;
};

const DrawerComments = ({ show, onToggle, width = '360px', data, model, tooltipText, isMobile }) => {
  if (isMobile) {
    return (
      <Modal
        title={
          <Div $fullWidth direction="row" align="center" gap={spaces.space1}>
            <Paragraph type="large" weight={fonts.weight600} ellipsis>
              Comentários
            </Paragraph>
            <TooltipIcon icon={faCircleInfo} iconColor={colors.primary500} text={tooltipText} />
          </Div>
        }
        open={show}
        hideFooter
        onClose={() => onToggle(false)}
      >
        <CommentListV2 data={data} idRefurbish={data?.idRefurbish} isMobile={isMobile} model={model} />
      </Modal>
    );
  }

  return (
    <>
      <Button
        style={{
          position: 'absolute',
          right: show ? '346px' : `-${spaces.space3}`,
          top: '109px',
          zIndex: 1000,
          transition: 'right 0.3s ease-in-out'
        }}
        type="outline"
        size="small"
        width={spaces.space3}
        shape="circle"
        onClick={() => onToggle(false)}
      >
        <FontAwesomeIcon style={{ fontSize: fonts.sizeSm }} icon={faArrowRightFromLine} />
      </Button>
      <ChatContainer $show={show} $width={width} />
      <ChatContent $show={show} $width={width} direction="column">
        {show && (
          <Div $fullWidth direction="column" $fullHeight>
            <CommentListV2 data={data} idRefurbish={data?.idRefurbish} model={model} tooltipText={tooltipText} />
          </Div>
        )}
      </ChatContent>
    </>
  );
};

DrawerComments.propTypes = {
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  width: PropTypes.string,
  data: PropTypes.instanceOf(Object).isRequired,
  model: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  isMobile: PropTypes.bool
};

export default DrawerComments;
