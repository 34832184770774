import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, List } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faCheck, faGripDotsVertical, faAdd } from '@fortawesome/pro-regular-svg-icons';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import Input from '../Input/Input';
import EditableInput from '../Input/EditableInput';
import Button from '../Button/Button';
import { colors, Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${props =>
    props.$isOver &&
    css`
      opacity: 0.5 !important;
    `}

  #drag-icon {
    cursor: ${props => (props.$readOnly ? 'default' : 'move')};
    opacity: 0;
    transition: opacity 0.3s;
  }

  #delete-icon {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    #drag-icon {
      opacity: ${props => (props.$readOnly ? 0 : 1)};
    }

    #delete-icon {
      opacity: ${props => (props.$readOnly ? 0 : 1)};
    }
  }
`;

const StyledList = styled(List)`
  width: 100%;

  .ant-list-empty-text {
    display: none;
  }

  .ant-list-footer {
    padding: 0 ${spaces.space2};
  }
`;

const Checklist = ({ list = [], onChange, readOnly = false }) => {
  const [isCreating, setIsCreating] = useState();
  const [itemName, setItemName] = useState('');

  const handleChange = (item, key, value) => {
    if (readOnly) return;
    const itemIndex = list.findIndex(_item => _item === item);
    const newList = list;
    newList[itemIndex][key] = value;
    onChange(newList);
  };

  const createItem = () => {
    if (readOnly) return;
    const newItem = { title: itemName || '-', checked: false };
    list.push(newItem);
    onChange(list);
    setItemName('');
  };

  const removeItem = item => {
    if (readOnly) return;
    const itemIndex = list.findIndex(_item => _item === item);
    list.splice(itemIndex, 1);
    onChange(list);
  };

  const moveRow = useCallback(
    (dragRow, dropRow, dragIndex, dropIndex) => {
      if (readOnly || dragRow?.order === dropRow?.order) return;

      const newList = [...list];

      let beforeBoundary = 0;
      let afterBoundary = 0;

      if (dragIndex < dropIndex) {
        beforeBoundary = dropRow?.order;
        afterBoundary = newList?.[dropIndex + 1]?.order || Number(dropRow?.order) + 1;
      } else {
        beforeBoundary = newList?.[dropIndex - 1]?.order || 0;
        afterBoundary = dropRow?.order;
      }

      const order = (Number(beforeBoundary || 0) + Number(afterBoundary)) / 2;

      const itemIndex = newList.findIndex(_item => _item === dragRow);
      newList[itemIndex].order = order;

      onChange(newList);
    },
    [list, readOnly]
  );

  const RenderList = ({ item, index }) => {
    const ref = useRef();

    const [, dragRef] = useDrag({
      item: { type: 'row', dragItem: item, dragIndex: index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      canDrag: !readOnly
    });

    const [{ isOver }, dropRef] = useDrop({
      accept: 'row',
      collect: monitor => {
        return { isOver: monitor.isOver() };
      },
      drop(_item) {
        if (!readOnly) {
          const { dragItem, dragIndex } = _item;
          moveRow(dragItem, item, dragIndex, index);
        }
      },
      canDrop: () => !readOnly
    });

    dragRef(dropRef(ref));

    return (
      <ListItem
        key={index}
        ref={ref}
        $isOver={isOver}
        $readOnly={readOnly}
        id={`item-${index}`}
        style={{ padding: `0 ${spaces.space2} 0 0` }}
      >
        <Div id="drag-icon" width={spaces.space3} height={spaces.space3} justify="center" align="center">
          <FontAwesomeIcon icon={faGripDotsVertical} color={colors.neutral500} />
        </Div>
        <Div flex={1}>
          <Checkbox
            checked={item.checked}
            style={{ marginRight: spaces.space1 }}
            onChange={e => handleChange(item, 'checked', e.target.checked)}
            disabled={readOnly}
          />
          <EditableInput
            element={document.getElementById(`item-${index}`)}
            value={item.title}
            onChange={value => handleChange(item, 'title', value)}
            initEdit={false}
            style={{ width: '100%' }}
            disabled={readOnly}
          />
        </Div>
        {!readOnly && (
          <Div id="delete-icon" width={spaces.space4} height={spaces.space4} justify="center" align="center">
            <FontAwesomeIcon icon={faTrashCan} color={colors.neutral500} onClick={() => removeItem(item)} />
          </Div>
        )}
      </ListItem>
    );
  };

  RenderList.propTypes = {
    item: PropTypes.instanceOf(Object),
    index: PropTypes.number
  };

  if (readOnly && list.length === 0) {
    return <Paragraph style={{ padding: `0 ${spaces.space2}` }}>Nenhum item adicionado</Paragraph>;
  }

  return (
    <StyledList
      style={{ width: '100%' }}
      dataSource={list}
      renderItem={(item, index) => (item ? <RenderList item={item} index={index} /> : null)}
      footer={
        !readOnly &&
        (isCreating ? (
          <Div gap={spaces.space1} $fullWidth style={{ padding: `0 0 0 ${spaces.space4}` }}>
            <Div flex={20}>
              <Input
                autoFocus
                placeholder=""
                value={itemName}
                type="text"
                onChange={e => setItemName(e.target.value)}
                onPressEnter={createItem}
              />
            </Div>
            <Div>
              <Div width={spaces.space4} height={spaces.space4} justify="center" align="center">
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={faCheck}
                  color={colors.primary600}
                  onClick={createItem}
                />
              </Div>
              <Div width={spaces.space4} height={spaces.space4} justify="center" align="center">
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={faTrashCan}
                  color={colors.neutral500}
                  onClick={() => setIsCreating(false)}
                />
              </Div>
            </Div>
          </Div>
        ) : (
          <Button id="add-item" text onClick={() => setIsCreating(true)}>
            <FontAwesomeIcon icon={faAdd} />
            Adicionar item
          </Button>
        ))
      }
    />
  );
};

Checklist.propTypes = {
  list: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool
};

export default Checklist;
