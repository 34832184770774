import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Text from 'antd/lib/typography/Text';
import { faChevronDown, faChevronUp, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContextHook } from '../../contexts/GeneralContext';
import { Div, colors, fonts, spaces } from '../../styles/style';
import { HelperLink, Paragraph } from '../Text/Text';
import { CustomBadge } from '../Button/AddAppropriation.styled';
import Card from '../Card/Card';
import { DynamicColumn, InlineDivider } from '../List/MobileCardItemV2.style';
import { columns } from '../../lib/mapping/TableOrList/shoppingCatalogColumns';
import Button from '../Button/Button';

const BudgetListFooter = ({ item, index: _index, childrenColumnName }) => {
  const { onSelect, itemsOnListMap } = useContextHook();

  const [showChildren, setShowChildren] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const appropCount = item?.[childrenColumnName]?.length || 0;
  const selected = itemsOnListMap[item?.key];

  useEffect(() => {
    if (appropCount === 0) setShowChildren(false);
    else if (appropCount > 0 && !firstRender) setShowChildren(true);

    setFirstRender(false);
  }, [appropCount]);

  const AddButton = () => (
    <Button
      id={`add-shopping-item${selected ? '-selected' : ''}-${_index + 1}`}
      shape="circle"
      type="primary"
      roundMaxHeight={spaces.space3}
      text
      onClick={e => {
        e.stopPropagation();
        !selected && onSelect({ ...item, purchaseDate: dayjs() });
      }}
    >
      <FontAwesomeIcon icon={selected ? faCircleCheck : faCirclePlus} size="2x" style={{ color: colors.primary600 }} />
    </Button>
  );

  const _columns = columns({
    onSelect,
    itemsOnListMap,
    isMobile: true,
    isAppropriation: true
  });

  return (
    <>
      <Div justify="space-between" align="center" padding={`${spaces.space1} ${spaces.space2}`}>
        <>
          {appropCount === 0 ? (
            <CustomBadge>O APROPRIAÇÕES</CustomBadge>
          ) : (
            <Div
              gap={spaces.space0}
              height={spaces.space4}
              role="presentation"
              onClick={() => setShowChildren(!showChildren)}
            >
              <HelperLink color={colors.primary600} weight={fonts.weight500} lineHeight="18px">
                {showChildren ? 'Fechar apropriações' : 'Ver apropriações'}
              </HelperLink>
              <Text>
                <CustomBadge>{appropCount}</CustomBadge>
              </Text>
              <FontAwesomeIcon
                icon={!showChildren ? faChevronDown : faChevronUp}
                style={{ marginRight: spaces.space0 }}
                color={colors.neutral500}
              />
            </Div>
          )}
        </>
        <AddButton />
      </Div>
      {showChildren && (
        <>
          {item?.[childrenColumnName]?.map((child, index) => (
            <Card
              borderRadius="0px"
              direction="column"
              justify="space-between"
              align="center"
              borderOnly="top"
              bodyBackgroundColor={colors.neutral75}
            >
              <Div wrap="wrap" gap={spaces.space1} $fullWidth>
                {_columns.map(col => (
                  <DynamicColumn $mobileWidth={col.mobileWidth} $minHeight={col.inlineMobile}>
                    <InlineDivider inline={col.inlineMobile}>
                      <Paragraph type="small">{col.title}</Paragraph>
                      {col.render ? col.render(child[col.dataIndex], child, index) : <div>{child[col.dataIndex]}</div>}
                    </InlineDivider>
                  </DynamicColumn>
                ))}
                <Div width="100%" justify="flex-end">
                  {AddButton(child)}
                </Div>
              </Div>
            </Card>
          ))}
        </>
      )}
    </>
  );
};

BudgetListFooter.propTypes = {
  item: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  childrenColumnName: PropTypes.string
};

export default BudgetListFooter;
