import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import { faEnvelope, faMobilePhone, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { Div, colors, spaces } from '../../styles/style';
import { Error, Paragraph, Subtitle } from '../Text/Text';
import SelectableCard from '../Card/SelectableCard';
import Card from '../Card/Card';
import Button from '../Button/Button';
import WarningBar from '../Alert/WarningBar';
import TooltipIcon from '../Tooltip/TooltipIcon';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import Loader from '../Loader/Loader';

const UlTooltip = styled.ul`
  list-style: inside;
  padding: ${spaces.space2} 0;
  span {
    color: ${colors.white};
  }
`;

const TitleDiv = styled(Div)`
  justify-content: space-between;
`;

const BillingManagerContent = ({
  billingChannels = { email: true },
  warning,
  setField = f => f,
  disabled,
  setNoCredit = f => f,
  isRevision,
  isView,
  isGeneral
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { user } = useSelector(state => state.authReducer);
  const { billingManagerType } = useSelector(state => state.setup.enums);
  const [loading, setLoading] = useState(false);
  const title = isGeneral ? 'Selecione as notificações para reenviar' : 'Como seu cliente será notificado?';
  const subTitle = isGeneral
    ? 'Você está vendo as notificações não enviadas desde ontem.'
    : 'Aumente em até 50% as chances do seu cliente te pagar em dia!';
  const { data: company, handleGet } = useCRUD({
    model: 'company',
    pathOptions: `/${user?.idCompany}`,
    options: {
      attributes: ['id', 'creditSms', 'creditWpp']
    }
  });

  const handleLoad = () => {
    setLoading(true);
    setTimeout(() => {
      handleGet();
      setLoading(false);
    }, 500);
  };

  const { creditSms = 0, creditWpp = 0 } = company || {};
  const noCredit = (billingChannels?.sms && creditSms <= 0) || (billingChannels?.whatsapp && creditWpp <= 0);

  useEffect(() => {
    setNoCredit(noCredit);
  }, [billingChannels, company]);

  const Tooltip = (
    <div>
      <Paragraph color={colors.white}>
        Com notificações ativadas, seu cliente será cobrado nos seguintes momentos:
      </Paragraph>

      <UlTooltip>
        {Object.keys(billingManagerType).map(key =>
          billingManagerType[key]?.tooltip ? (
            <li key={billingManagerType[key].key}>
              <span>{billingManagerType[key].tooltip} </span>
            </li>
          ) : null
        )}
      </UlTooltip>

      <Paragraph color={colors.white}>
        Caso o pagamento seja realizado ou a cobrança excluída, a comunicação é desativada.
      </Paragraph>
    </div>
  );

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <TitleDiv>
        <div>
          <div>
            <Subtitle>{title}</Subtitle>
            {!isGeneral && (
              <TooltipIcon
                iconColor={colors.primary500}
                icon={faInfoCircle}
                text={Tooltip}
                style={{ padding: `0 ${spaces.space1}`, height: spaces.space2 }}
                tooltipProps={{ overlayStyle: { minWidth: '320px' } }}
                id="billing-manager-tooltip"
              />
            )}
          </div>
          <Paragraph type="small">{subTitle}</Paragraph>
        </div>
        {isGeneral ? <HowDoesItWorksButton id="how-resend-notifications" /> : null}
      </TitleDiv>

      {warning && <WarningBar warning={warning} margin="none" />}
      {!isGeneral && (
        <Space direction="vertical" size={16}>
          <Space direction="horizontal" size={8}>
            <SelectableCard
              checked={billingChannels?.email}
              label="E-mail"
              icon={faEnvelope}
              onClick={email => setField('billingChannels')({ ...billingChannels, email })}
              disabled={disabled}
            />
            <SelectableCard
              checked={billingChannels?.whatsapp}
              label="Whatsapp"
              icon={faWhatsapp}
              onClick={whatsapp => setField('billingChannels')({ ...billingChannels, whatsapp })}
              disabled={disabled}
            />
            <SelectableCard
              checked={billingChannels?.sms}
              label="SMS"
              icon={faMobilePhone}
              onClick={sms => setField('billingChannels')({ ...billingChannels, sms })}
              disabled={disabled}
            />
          </Space>
          {noCredit && !isView && <Error>Você não possui créditos suficientes.</Error>}
        </Space>
      )}
      {!isRevision && (
        <Card
          bodyPadding={`${spaces.space1} ${spaces.space2}`}
          borderColor="transparent"
          bodyBackgroundColor={colors.neutral75}
          maxWidth="max-content"
        >
          <Space direction="horizontal" size={16} wrap>
            <Space direction="horizontal" size={8}>
              <FontAwesomeIcon icon={faCoins} color={colors.orange400} />
              <Paragraph type="small">
                <Paragraph
                  type="small"
                  color={creditWpp <= 0 ? colors.red500 : colors.neutral600}
                  id="credits-whatsapp"
                >
                  {loading ? <Loader /> : creditWpp}{' '}
                </Paragraph>
                créditos Whatsapp
              </Paragraph>
            </Space>
            <Space direction="horizontal" size={8}>
              <FontAwesomeIcon icon={faCoins} color={colors.orange400} />
              <Paragraph type="small">
                <Paragraph type="small" color={creditSms <= 0 ? colors.red500 : colors.neutral600} id="credits-sms">
                  {loading ? <Loader /> : creditSms}{' '}
                </Paragraph>
                créditos SMS
              </Paragraph>
            </Space>
            <Button
              type="light"
              onClick={() => window.open('/profissional/vobipay/comprar-creditos', '_blank', 'noreferrer')}
            >
              Adicionar{_isMobile && ' créditos'}
            </Button>
            <Button type="light" onClick={handleLoad} disabled={loading}>
              Atualizar{_isMobile && ' créditos'}
            </Button>
          </Space>
        </Card>
      )}
    </Space>
  );
};

BillingManagerContent.propTypes = {
  billingChannels: PropTypes.instanceOf(Object),
  warning: PropTypes.string,
  setField: PropTypes.func,
  setNoCredit: PropTypes.func,
  disabled: PropTypes.bool,
  isRevision: PropTypes.bool,
  isView: PropTypes.bool,
  isGeneral: PropTypes.bool
};

export default BillingManagerContent;
