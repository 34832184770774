import React from 'react';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Paragraph } from '../Text/Text';
import { colors, Div, spaces } from '../../styles/style';
import TooltipIcon from '../Tooltip/TooltipIcon';

const AccountDetails = ({ company }) => {
  return (
    <>
      <Div direction="column" align="start" gap={spaces.space1}>
        <Paragraph type="large">{company?.name}</Paragraph>
        <Paragraph type="small">
          <b>CPF/CNPJ: </b>
          {company?.cnpj}
        </Paragraph>
        <Paragraph type="small">
          <b>Tipo: </b> Conta de Pagamento
        </Paragraph>
        <Paragraph type="small">
          <b>Banco: </b>461 - Asaas I.P S.A
          <TooltipIcon
            iconColor={colors.primary500}
            icon={faInfoCircle}
            text={`O Asaas é uma instituição de pagamento, regulamentada pelo Banco Central do Brasil,
                      que atua como parceira da Vobi para fornecimento de serviços financeiros da Vobi Pay`}
            style={{ padding: `0 ${spaces.space1}`, height: spaces.space2 }}
          />
        </Paragraph>
        <Paragraph type="small">
          <b>Agência: </b>
          {company?.accountNumber?.agency}
        </Paragraph>
        <Paragraph type="small">
          <b>Conta: </b>
          {company?.accountNumber?.account}-{company?.accountNumber?.accountDigit}
        </Paragraph>
      </Div>
    </>
  );
};

AccountDetails.propTypes = {
  company: PropTypes.instanceOf(Object)
};

export default AccountDetails;
