import React from 'react';
import PropTypes from 'prop-types';

import { Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import Label from '../Label/Label';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { Content } from '../../_Pages/Payments/Payment.style';

const BillTotals = ({ data }) => {
  const subTotal = Number(data?.totalSplitValue || data?.subTotal);
  const allInterest = Number(data?.allSplitInterest || data?.allInterest);
  const allDueDateDiscount = Number(data?.allSplitDueDateDiscount || data?.allDueDateDiscount);
  return (
    <Content>
      <SimpleAccordion title="Totais" initOpen>
        <Div gap={spaces.space2} width="70%" justify="space-between">
          <div>
            <Label>Subtotal</Label>
            <Paragraph type="small">{formatCurrency(subTotal, { currencySymbol: 'R$' })}</Paragraph>
          </div>
          {data?.allInterest ? (
            <div>
              <Label>Juros e multa</Label>
              <Paragraph type="small">{formatCurrency(allInterest, { currencySymbol: 'R$' })}</Paragraph>
            </div>
          ) : null}
          {allDueDateDiscount ? (
            <div>
              <Label>Desconto no pagamento</Label>
              <Paragraph type="small">{formatCurrency(allDueDateDiscount * -1, { currencySymbol: 'R$' })}</Paragraph>
            </div>
          ) : null}
          <div>
            <Label>Valor total</Label>
            <Paragraph type="small">
              {formatCurrency(subTotal + allInterest - allDueDateDiscount, { currencySymbol: 'R$' })}
            </Paragraph>
          </div>
          {!allInterest ? <div /> : null}
        </Div>
      </SimpleAccordion>
    </Content>
  );
};

BillTotals.propTypes = {
  data: PropTypes.instanceOf(Object)
};

export default BillTotals;
