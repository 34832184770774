import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { spaces } from '../../styles/style';
import TableOrList from '../List/TableOrList';
import Pagination from '../Pagination/Pagination';
import Button from '../Button/Button';

const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const ListModal = ({
  showModal,
  title,
  list,
  columns,
  offset,
  handlePagination,
  length,
  handleCloseModal,
  itensPage
}) => {
  const footer = (
    <FooterDiv>
      <Button onClick={handleCloseModal} id="cancel-submit-button" type="text">
        Fechar
      </Button>
    </FooterDiv>
  );

  return (
    <Modal open={showModal} width={648} title={title || 'Lista'} footer={footer} onClose={handleCloseModal}>
      <TableOrList
        margin={`${spaces.space2} 0`}
        list={list}
        columns={columns}
        border
        borderRow
        paddingFirstColumn={16}
        variantStyle
        cursor="inherit"
      />
      {length > 0 ? (
        <Pagination
          activePage={Number(offset || 1)}
          itemsCountPerPage={itensPage}
          totalItemsCount={length}
          pageRangeDisplayed={5}
          onChange={handlePagination}
        />
      ) : null}
    </Modal>
  );
};

ListModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  list: PropTypes.instanceOf(Object),
  columns: PropTypes.instanceOf(Object),
  offset: PropTypes.number,
  handlePagination: PropTypes.func,
  length: PropTypes.number,
  handleCloseModal: PropTypes.func,
  itensPage: PropTypes.number
};

export default ListModal;
