import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { getBrazilianDate } from '../lib/helpers/helper';
import { countValidDays } from '../components/Timeline/GanttHelperFunctions';

const daysMapping = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
};

const useDisabledDate = ({ workDays, dayoffs, minDate, maxDate }) => {
  const [mappedHolidays, setMappedHolidays] = useState(null);

  const formatHolidays = holidays => {
    const objMap = {};

    holidays.forEach(({ date }) => {
      const _dayoff = getBrazilianDate(date);

      const key = _dayoff.year() === 1900 ? _dayoff.format('DD/MM') : _dayoff.format('DD/MM/YYYY');

      objMap[key] = true;
    });
    return { ...objMap };
  };

  useEffect(() => {
    if (dayoffs) {
      const holidays = formatHolidays(dayoffs);

      setMappedHolidays(holidays);
    }
  }, [dayoffs]);

  const changeDate = (date, targetDate) => {
    if (!getBrazilianDate(targetDate).isValid()) return date;
    const objTargetDate = getBrazilianDate(targetDate).toObject();
    const _date =
      date && getBrazilianDate(date).isValid()
        ? getBrazilianDate(date)
        : getBrazilianDate()
            .hour(targetDate?.hour())
            .minute(targetDate?.minute());
    return _date
      .year(objTargetDate.years)
      .month(objTargetDate.months)
      .date(objTargetDate.date);
  };

  const verifyDisabledDate = useCallback(
    currentDate => {
      const _currentDate = dayjs(currentDate);

      if (minDate && _currentDate.isBefore(dayjs(minDate))) {
        return true;
      }

      if (maxDate && _currentDate.isAfter(dayjs(maxDate))) {
        return true;
      }

      const currentDateDay = daysMapping[_currentDate.day()];

      if (workDays && !workDays[currentDateDay]?.active) {
        return true;
      }

      const formattedFullDate = _currentDate.format('DD/MM/YYYY');
      const formattedDate = _currentDate.format('DD/MM');

      if (mappedHolidays && (mappedHolidays[formattedFullDate] || mappedHolidays[formattedDate])) {
        return true;
      }

      return false;
    },
    [mappedHolidays, workDays, minDate, maxDate]
  );

  const findNextValidDate = useCallback(
    (date, reverse) => {
      if (!date) return date;
      let newDate = date;

      while (verifyDisabledDate(newDate)) {
        newDate = reverse ? newDate.subtract(1, 'day') : newDate.add(1, 'day');
      }
      return newDate;
    },
    [verifyDisabledDate]
  );

  const findValidRange = useCallback(
    (start, end, val, reverse) => {
      if (!start || !end) return [start, end];
      let newStart = findNextValidDate(start);
      let newEnd = findNextValidDate(end);
      const _val = val || 0;
      let currentValidDays = countValidDays(newStart, newEnd, { workDays, dayoffs });
      const valIsGreater = currentValidDays < _val;

      while (currentValidDays !== _val) {
        currentValidDays += valIsGreater ? 1 : -1;

        if (reverse) {
          newStart = findNextValidDate(
            valIsGreater ? newStart.subtract(1, 'day') : newStart.add(1, 'day'),
            valIsGreater
          );
          // eslint-disable-next-line no-continue
          continue;
        }
        newEnd = findNextValidDate(valIsGreater ? newEnd.add(1, 'day') : newEnd.subtract(1, 'day'), !valIsGreater);
      }

      return [newStart, newEnd];
    },
    [findNextValidDate, dayoffs]
  );

  return { verifyDisabledDate, findValidRange, findNextValidDate, changeDate };
};

export default useDisabledDate;
