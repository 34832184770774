const mappingRoutes = {
  o: props => ({
    model: 'refurbish-items',
    childrenPath: 'orcamento',
    queryName: 'item',
    options: {
      where: {
        idRefurbish: props.idRefurbish,
        or: [{ '$refurbish.users.id$': props?.id }, { '$refurbish.users.isOwner$': true }]
      },
      include: {
        model: 'refurbish',
        include: ['users']
      }
    },
    loadData: true
  }),
  o2: props => ({
    model: 'refurbish-items',
    childrenPath: 'orcamento-v2',
    queryName: 'item',
    options: {
      where: {
        idRefurbish: props.idRefurbish,
        or: [{ '$refurbish.users.id$': props?.id }, { '$refurbish.users.isOwner$': true }]
      },
      include: [
        'item',
        {
          model: 'refurbish',
          include: ['users']
        }
      ]
    },
    loadData: true
  }),
  a: props => ({
    model: 'file',
    childrenPath: 'arquivos',
    queryName: 'arquivo',
    options: {
      where: {
        or: [
          { '$refurbishes.users.id$': props?.id },
          { '$refurbishes.users.isOwner$': true },
          { shared: { ne: null } },
          { '$folder.shared$': { ne: null } },
          { '$refurbishes.users.userType$': 'provider' }
        ]
      },
      include: [
        'folder',
        {
          model: 'refurbish',
          as: 'refurbishes',
          include: ['users']
        }
      ]
    },
    loadData: true
  }),
  n: props => ({
    model: 'refurbish-notes',
    childrenPath: 'anotacoes',
    queryName: 'nota',
    options: {
      where: {
        or: [{ '$refurbish.users.id$': props?.id }, { '$refurbish.users.isOwner$': true }]
      },
      include: {
        model: 'refurbish',
        include: ['users']
      }
    },
    loadData: true
  }),
  t: props => ({
    model: 'task',
    childrenPath: 'gestao-tarefas/tarefas',
    queryName: 'tarefa',
    openRoot: true,
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    },
    loadData: true
  }),
  p: props => ({
    model: 'folder',
    childrenPath: 'arquivos',
    queryName: 'pasta',
    isFolder: true,
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    },
    loadData: true
  }),
  pg: props => ({
    childrenPath: 'financeiro/cobrancas',
    queryName: 'pagamento',
    model: 'payment',
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    }
  }),
  r: props => ({
    childrenPath: 'financeiro/receitas',
    queryName: 'bill',
    model: 'payment',
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    }
  }),
  d: props => ({
    childrenPath: 'financeiro/despesas',
    queryName: 'bill',
    loadData: false,
    model: 'payment',
    alternativeId: 'splitId',
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    }
  }),
  s: props => ({
    model: 'purchase',
    openRoot: true,
    childrenPath: `gestao-de-compras/solicitacoes/visualizar/${props?.idModel}`
  }),
  oc: props => ({
    model: 'order',
    openRoot: true,
    childrenPath: `gestao-de-compras/ordem-de-compras/visualizar/${props?.idModel}`
  }),
  ct: props => ({
    model: 'quote',
    openRoot: true,
    childrenPath: `gestao-de-compras/cotacoes/visualizar/${props?.idModel}`
  })
};

export { mappingRoutes };
