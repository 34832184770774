import { Header } from 'antd/lib/layout/layout';
import { Layout, Menu } from 'antd';
import styled, { css } from 'styled-components';

import { colors, spaces, fonts, opacities, radius } from '../../styles/style';

const { Sider } = Layout;

const StyledHeader = styled(Header)`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: ${props => (props?.$collapsed ? 'center' : 'flex-start')};

  .menu-icon {
    z-index: 10;
    border-radius: 100%;
    height: ${spaces.space3};
    width: ${spaces.space3};
    position: absolute;
    display: flex;
    background: ${colors.white};
    align-items: center;
    cursor: pointer;
    border: 1px solid ${colors.neutral100};
    transition: 0.2s;

    svg {
      padding: ${spaces.space0};
    }
  }

  .open-menu-icon {
    top: 55px;
    left: 52px;

    svg {
      margin-left: 1.5px;
    }
  }

  .close-menu-icon {
    top: 55px;
    left: 244px;

    svg {
      margin-left: 0.5px;
    }
  }
`;

const StyledCompanyName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

const StyledCompanyDiv = styled.div`
  min-height: ${spaces.space7};
  background-color: ${colors.neutral75};
  display: flex;
  align-items: center;
  padding: ${props => (props?.$myArea ? `0 ${spaces.space3}` : `${spaces.space1} ${spaces.space2}`)};
  justify-content: ${props => (props?.$isMobile ? 'flex-start' : 'center')};
  .company-name {
    margin-left: ${spaces.space2};
    color: ${colors.neutral600};
    font-size: ${fonts.sizeLg};
    font-weight: ${fonts.weight500};
  }
  .my-area {
    color: ${colors.neutral400};
    font-size: ${fonts.sizeLg};
    font-weight: ${fonts.weight500};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.neutral75};
  position: absolute;
  width: 100%;
  bottom: 1px;
  z-index: 1;
  height: ${spaces.space6};
  line-height: ${spaces.space6};
  text-align: center;
  cursor: pointer;
  span {
    font-size: ${fonts.sizeSm};
  }
  svg {
    padding: 0 ${spaces.space2};
  }
`;

const StyledLogoDiv = styled.div`
  height: auto;
  width: auto;
  display: flex;
  img {
    border-radius: ${radius.radius1};
    max-height: ${spaces.space5};
  }
  svg {
    width: ${spaces.space5};
    height: ${spaces.space4};
  }
`;

const StyledLogo = styled.div`
  ${props =>
    props.$url &&
    css`
      background-image: url(${props.$url});
    `}
  height: ${spaces.space5};
  width: ${spaces.space5};
  border-radius: ${radius.radius1};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledSider = styled(Sider)`
  .upgrade-div {
    width: auto;
    margin: 0 ${spaces.space1};
    font-size: ${fonts.sizeMd};
  }
  .social-media-footer {
    width: 100%;
    align-items: center;
    background-color: ${colors.neutral75};
    padding-top: ${spaces.space4};
    .social-icon-box {
      width: ${spaces.space4};
      height: ${spaces.space4};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0 ${spaces.space1};
      font-size: ${fonts.sizeLg};
    }
    .facebook {
      svg {
        color: ${colors.primary600};
      }
    }
    .instagram {
      svg {
        color: ${colors.neutral500};
      }
    }
    .youtube {
      svg {
        color: ${colors.red500};
      }
    }
  }
  svg {
    color: ${colors.neutral400};
  }

  span {
    white-space: ${props => (props?.$isMobile ? 'normal' : 'nowrap')};
    overflow: hidden;
  }

  background-color: ${colors.white};

  .ant-menu {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 175px);
    padding: ${spaces.space1} 0;
  }

  .ant-menu-item-icon {
    font-size: ${fonts.sizeMd} !important;
  }

  .ant-menu-item-selected {
    background-color: ${colors.white} !important;
    ::after {
      opacity: 0;
    }
  }
  .ant-layout-sider-children {
    position: relative;
    border-right: 1px solid ${colors.neutral75};
  }
  .ant-menu-item {
    background-color: ${colors.white};
    margin-top: ${spaces.space1};
    height: ${spaces.space4};
    line-height: ${spaces.space4};
    padding: 0;
    span {
      color: ${colors.neutral600};
    }
    &:hover {
      svg {
        color: ${colors.primary400};
      }
    }
  }
  .ant-menu-sub {
    background-color: ${colors.white};
    .ant-menu-item {
      height: ${spaces.space4};
      line-height: ${spaces.space4};
    }
  }
  .ant-menu-inline {
    border: 0;
  }

  .list-item {
    display: flex;
    svg {
      margin-right: ${spaces.space1};
      padding: ${spaces.space1};
      border-radius: ${radius.radius1};
    }
    &:hover,
    &:focus {
      svg {
        background-color: ${`${colors.primary300}${opacities.opacity02}`};
      }
    }
  }
  .list-item.collapsed {
    justify-content: center;
    svg {
      margin-right: 0;
    }
  }
  .list-item.selected {
    svg {
      color: ${colors.primary400};
      padding: 7px;
      background-color: ${`${colors.primary300}${opacities.opacity02}`};
      border: 1px solid ${`${colors.primary300}${opacities.opacity02}`};
    }
  }
  .list-item > .fa-lock {
    position: absolute;
    right: 0;
    background-color: transparent !important;
    color: ${colors.neutral200} !important;
  }
  .list-item.collapsed > .fa-lock {
    height: ${spaces.space1};
    left: 50%;
    top: 50%;
    border-radius: ${radius.radius1};
    background-color: ${colors.neutral400} !important;
    padding: ${spaces.space0};
    color: ${colors.white} !important;
  }
  .submenu-item-selected {
    background-color: ${`${colors.primary300}${opacities.opacity02}`} !important;
    border-radius: ${radius.radius1};
  }
  .item-icon {
    height: ${spaces.space4};
    width: ${spaces.space4};
    margin-right: ${props => (props.collapsed ? 0 : spaces.space2)};
  }

  .ant-menu-submenu {
    margin: ${spaces.space1} 0;

    .ant-menu-sub {
      padding: 0;
    }

    .ant-menu-submenu-title {
      background-color: ${colors.white};
      display: flex;
      align-items: center;
      margin: 0;
      white-space: normal;
      height: ${spaces.space4};
      line-height: ${spaces.space4};
      padding: 0;
      width: 100%;
      span {
        color: ${colors.neutral600};
      }
      &:hover {
        svg {
          color: ${colors.primary400};
        }
      }
    }
    svg {
      margin-right: ${props => (props?.$collapsed ? 0 : spaces.space1)};
    }
    .ant-menu-item {
      margin: 0 ${spaces.space2} ${spaces.space0} ${spaces.space7} !important;
      width: auto;
      padding: 0 ${spaces.space2} !important;
      span {
        color: ${colors.primary800};
        font-size: ${fonts.sizeSm};
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        background-color: ${`${colors.primary300}${opacities.opacity02}`} !important;
        border-radius: ${radius.radius1};
      }
    }
  }
`;

const SubMenu = styled(Menu.SubMenu)`
  ${props =>
    props.$isChildren &&
    css`
      margin: ${spaces.space0};

      .submenu-item-selected {
        background-color: ${colors.primary300} ${opacities.opacity02} !important;
        border-radius: ${radius.radius1};
      }
      .ant-dropdown-menu-submenu-title {
        display: flex;
        margin: 0 ${spaces.space0} ${spaces.space0} ${spaces.space0};
        &:hover {
          background-color: ${`${colors.primary300}${opacities.opacity02}`} !important;
          border-radius: ${radius.radius1};
        }
        .ant-dropdown-menu-title-content {
          padding-left: ${spaces.space0};
          span {
            color: ${colors.primary800};
            font-size: ${fonts.sizeSm};
          }
        }
      }
      .ant-menu-submenu-title {
        padding: 0 ${props.$collapsed ? '0' : props.customPadding || spaces.space9} !important;
        span {
          color: ${colors.primary800};
          font-size: ${fonts.sizeSm};
        }
        &:hover {
          background-color: ${`${colors.primary300}${opacities.opacity02}`} !important;
          border-radius: ${radius.radius1};
        }
      }
    `}
`;

export {
  StyledHeader,
  StyledFooter,
  StyledLogoDiv,
  StyledSider,
  StyledCompanyDiv,
  StyledLogo,
  StyledCompanyName,
  SubMenu
};
