import { name, phone, email } from '../mapping';
import { store } from '../../config/redux-store';
import { addressConcat } from '../../helpers/helper';

export const provider = {
  name: { ...name, otherProps: { ellipsis: true } },
  email: { ...email, otherProps: { ellipsis: true } },
  observation: {
    label: 'Observação',
    otherProps: { ellipsis: true, span: 2, html: true }
  }
};

export const supplier = {
  responsibleName: { label: 'Nome do responsável', otherProps: { span: 1 } },
  contactName: { label: 'Nome do contato', otherProps: { span: 1 } },
  name: { label: 'Nome fantasia', otherProps: { span: 1 } },
  legalName: { label: 'Razão social', otherProps: { span: 1 } },
  phone: { label: 'Telefone', otherProps: { span: 1 } },
  email: { label: 'E-mail', otherProps: { span: 1 } },
  document: { label: 'CNPJ/CPF', otherProps: { span: 1 } },
  website: { label: 'Site', otherProps: { span: 1 } },
  type: {
    label: 'Tipo da empresa',
    nestedKey: 'label',
    format: val => {
      const { setup } = store.getState();
      const { supplierType } = setup.systemData;
      return supplierType.find(s => s.value === val)?.label || val;
    },
    otherProps: { span: 1 }
  },
  address: {
    label: 'Endereço',
    nestedKey: 'label',
    format: (val, data) => addressConcat(data),
    otherProps: { span: 1 }
  },
  paymentOptions: { label: 'Outros meios de pagamento', otherProps: { html: true, span: 1 } }
};

export const customer = {
  name,
  phone,
  doc: {
    label: 'CPF/CNPJ'
  },
  email: { ...email, otherProps: { span: 3 } }
};
