import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useContextHook } from '../../contexts/GeneralContext';
import useViewport from '../../_Hooks/useViewport';

// components
import BillingManagerContent from '../Content/BillingManagerContent';
import Button from '../Button/Button';
import useCRUD from '../../_Hooks/useCRUD';
import { columns } from '../../lib/mapping/TableOrList/BillingManagerColumns';
import TableOrList from '../List/TableOrList';
import Pagination from '../Pagination/Pagination';
import ConfirmModal from '../Modal/ConfirmModal';
import CenteredLoader from '../Loader/CenteredLoader';
import { ButtonDiv, Container, Content } from './BillingManagerTable.style';
import BillingManagerBulkActions from '../BulkActions/BillingManagerBulkActions';
import { spaces, fonts } from '../../styles/style';

const ITEMS_PAGE = 12;

const BillingManagerTable = ({ isView, isGeneral }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const {
    user: { company }
  } = useSelector(state => state.authReducer) || {};
  const { billingManagerType, billingManagerChannels, billingManagerStatusDesc, billingManagerStatus } = useSelector(
    state => state.setup.enums
  );
  const history = useHistory();
  const { isRevision, values, handleLoad } = useContextHook();
  const { billingChannels, id, billingManagerStoppedAt, openAmount } = values || {};
  const [offset, setOffset] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noCredit, setNoCredit] = useState(false);
  const [selectedRowKeys, setSelectRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');

  let warning = null;
  if (billingManagerStoppedAt) warning = 'As notificações de cobrança foram interrompidas';
  else if (noCredit) warning = 'Adicione mais créditos para enviar as cobranças não enviadas.';

  const { handleUpdate } = useCRUD({
    model: 'payment',
    immediatelyLoadData: false
  });

  const { handleGet, list, totalItems } = useCRUD({
    model: 'billingManager',
    immediatelyLoadData: false
  });
  const canCancel = !billingManagerStoppedAt && totalItems > 0;

  const handleSelectItem = (key, items) => {
    setSelectRowKeys(key);
    setSelectedItems(items);
  };

  const getList = () =>
    handleGet({
      refetchOptions: {
        offset: offset || 1,
        limit: ITEMS_PAGE,
        include: ['payment', { model: 'companyCustomer', attributes: ['name', 'email', 'phone'] }],
        where: {
          ...(id && { idPayment: id }),
          idCompany: company?.id,
          ...(isGeneral && { idStatus: [3, 5] }),
          ...(isGeneral && { notificationDate: [today, yesterday] })
        },
        order: [
          ['notificationDate', 'asc'],
          ['channel', 'asc']
        ]
      },
      immediatelyLoadData: false
    });

  useEffect(() => {
    getList();
  }, [id, offset, openAmount]);

  const handleConfirmCancel = () => {
    handleUpdate({
      updatePathOptions: `/cancel-billing-manager/${id}`,
      refresh: false,
      verb: 'patch'
    });
    setLoading(true);
    setTimeout(() => {
      handleLoad();
      setLoading(false);
    }, 1000);
  };

  const _columns = columns({
    isMobile: _isMobile,
    billingManagerType,
    billingManagerChannels,
    billingManagerStatusDesc,
    billingManagerStatus,
    selectedItems,
    isGeneral,
    setSelectedItems
  });

  const handlePagination = page => {
    setOffset(page);
  };

  return (
    <Content $isGeneral={isGeneral}>
      <Container>
        <BillingManagerContent
          warning={warning}
          billingChannels={billingChannels}
          isRevision={isRevision}
          disabled
          setNoCredit={setNoCredit}
          isView={isView}
          isGeneral={isGeneral}
        />
        {!isRevision && !isGeneral && (
          <ButtonDiv>
            {canCancel && (
              <Button type="danger" text onClick={() => setShowConfirmModal(true)} textFontWeight={fonts.weight400}>
                Parar próximos envios
              </Button>
            )}
            <Button type="primary" text onClick={() => history.push('/profissional/vobipay/reenviar-cobrancas')}>
              Ver não enviados
            </Button>
          </ButtonDiv>
        )}
      </Container>
      {!isRevision && (
        <>
          {loading ? (
            <CenteredLoader />
          ) : (
            <TableOrList
              margin={`${spaces.space2} 0`}
              list={list}
              columns={_columns}
              border
              borderRow
              paddingFirstColumn={16}
              variantStyle
              selectedItems={selectedItems}
              selectedRowKeys={selectedRowKeys}
              rowSelection={isGeneral && !_isMobile}
              onSelectItem={handleSelectItem}
              cursor="inherit"
            />
          )}
          {totalItems > 0 ? (
            <Pagination
              activePage={Number(offset || 1)}
              itemsCountPerPage={ITEMS_PAGE}
              totalItemsCount={totalItems}
              pageRangeDisplayed={5}
              onChange={handlePagination}
            />
          ) : null}
        </>
      )}
      {showConfirmModal && canCancel && (
        <ConfirmModal
          confirmButtonText="Confirmar"
          cancelButtonText="Cancelar"
          title="Confirmar alteração"
          text={`Ao cancelar o envio das cobranças, nenhuma notificação das próximas parcelas será enviada.
            Essa ação não poderá ser desfeita.`}
          alertInfo="Você não poderá ativar as notificações novamente."
          onSubmit={handleConfirmCancel}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      {selectedItems.length ? (
        <BillingManagerBulkActions
          selectedItems={selectedItems}
          setSelectRowKeys={setSelectRowKeys}
          setSelectedItems={setSelectedItems}
          setLoading={setLoading}
          getList={getList}
        />
      ) : null}
    </Content>
  );
};

BillingManagerTable.propTypes = {
  isView: PropTypes.bool,
  isGeneral: PropTypes.bool
};

export default BillingManagerTable;
