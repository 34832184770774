import React from 'react';
import PropTypes from 'prop-types';
import { faCircleQuestion, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, Div, fonts, spaces } from '../../styles/style';
import Button from '../Button/Button';
import { HelperLink, Paragraph, Subtitle } from '../Text/Text';
import Modal from './Modal';
import Card from '../Card/Card';
import { Line } from '../Line/Line';

const importInstructionConfig = {
  opportunity: {
    id: 'importOpportunity',
    title: 'Importar oportunidades',
    infos: [
      `Se o nome do cliente da oportunidade não estiver cadastrado na plataforma, ele será criado automaticamente.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_oportunidades.xlsx'
  },
  refurbish: {
    id: 'importRefurbish',
    title: 'Importar projetos',
    infos: [
      `Os status dos projetos devem ser exatamente iguais aos cadastrados na plataforma.`,
      `Se o nome do cliente do projeto não estiver cadastrado na plataforma, ele será criado automaticamente.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_projetos.xlsx'
  },
  payment: {
    id: 'importPayment',
    title: 'Importar receitas e despesas',
    infos: [
      `Para cada linha da importação será criado um lançamento financeiro,
        <b>não será possível importar lançamentos parcelados.</b>`,
      `Se o lançamento ainda não foi pago, basta deixar a "Data de pagamento" em branco.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_receitas_e_despesas.xlsx'
  },
  clientPayment: {
    id: 'importClientPayment',
    title: 'Importar pagamentos de clientes',
    infos: [
      'Um item será adicionado ao pagamento do cliente com mesmo nome do pagamento.',
      `Caso informe a data de pagamento, será considerado como pago,
        para importar pagamentos em aberto deixe a data de pagamento em branco.`,
      'Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.'
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_pagamentos.xlsx'
  },
  specification: {
    id: 'importSpecification',
    title: 'Importar orçamento',
    infos: [
      `É possível importar o seu orçamento com as composições detalhadas ou com as composições
      tendo somente valores de mão de obra e material, confira a opção que melhor te atende
      nas abas do modelo de importação.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_orcamento_v2.xlsx'
  },
  item: {
    id: 'importItem',
    title: 'Importar itens para biblioteca',
    infos: [
      `Importe sua biblioteca de itens, produtos e serviços para utilizá-los em orçamentos, compras e no financeiro.`,
      `Para atualizar itens existentes, é essencial que os códigos da planilha de importação
      sejam idênticos aos da biblioteca.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_catalogo_Vobi.xlsx'
  },
  composition: {
    id: 'importComposition',
    title: 'Importar composições para biblioteca',
    infos: [
      `Ao importar composições, caso os itens que as compõem não existam na sua biblioteca eles serão criados.`,
      `Sugestão: Para atualizar composições existentes, é essencial que os códigos da planilha
      de importação sejam idênticos aos da biblioteca.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_composicao_Vobi.xlsx'
  },
  generalTask: {
    id: 'importGeneralTask',
    title: 'Importar tarefas',
    infos: [
      `Os status das tarefas devem ser exatamente iguais aos da plataforma.`,
      `Se o código do projeto não existir, será adidionada uma tarefa não vinculada a um projeto.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_tarefas_geral.xlsx'
  },
  projectTask: {
    id: 'importProjectTask',
    title: 'Importar tarefas',
    infos: [
      `Os status das tarefas devem ser exatamente iguais aos da plataforma.`,
      `Se o campo "Etapa" não for preenchido, a tarefa será criada na etapa Geral.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_tarefas_projeto.xlsx'
  },
  conciliation: {
    id: 'importConciliation',
    title: 'Importar extrato para conciliação',
    infos: [
      `<b>Recomendamos fortemente o uso do arquivo OFX exportado diretamente do banco</b>
        para evitar erros na conciliação, utilize planilhas apenas se não for possível obter o OFX.`,
      `Essa importação não é recomendada para importação de histórico,
        <b>para importar histórico utilize a importação de receitas e despesas.</b>`,
      `Cada linha na planilha deve corresponder a uma movimentação no banco.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_conciliacao.xlsx'
  },
  supplier: {
    id: 'importSupplier',
    title: 'Importar fornecedores',
    infos: [
      `Evite duplicar contatos na sua conta, confira se os contatos já existem antes de importar.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_fornecedores_Vobi.xlsx'
  },
  companyCustomer: {
    id: 'importCompanyCustomer',
    title: 'Importar clientes',
    infos: [
      `Evite duplicar contatos na sua conta, confira se os contatos já existem antes de importar.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_clientes_Vobi.xlsx'
  },
  quoteSuppliers: {
    id: 'importQuoteSuppliers',
    title: 'Importar dados da cotação',
    infos: [
      `Você irá importar os dados básico da cotação,
        para importar valores dos itens acesse a opção "importar itens da cotação"`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_resposta_cotacao.xlsx'
  },
  quoteItemSuppliers: {
    id: 'importQuoteSuppliers',
    title: 'Importar itens da cotação',
    infos: [
      `Recomendamos que exporte a cotação para facilitar o preenchimento dos itens.`,
      `Você irá importar os valores dos itens da cotação,
        para importar os outros dados acesse "importar dados da cotação"`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_resposta_cotacao.xlsx'
  },
  financialCategory: ({ billType }) => ({
    id: 'importFinancialCategory',
    title: `Importar categorias financeiras de ${billType === 'income' ? 'Receitas' : 'Despesas'}`,
    infos: [
      `Atenção para a estrutura de numeração, ela definirá categorias mães e filhas, até um limite de 3 níveis.`,
      `Não podem ser criadas categorias filhas em categorias mãe que estão em uso.`,
      `Apenas os <b>campos com * são obrigatórios</b> na planilha modelo de importação.`
    ],
    link: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_categorias_financeiras.xlsx'
  })
};

const ImportInstructionModal = ({ model, onClose, goNext, ...params }) => {
  const { modelProps } = params || {};
  const { id, title, infos, link } = modelProps
    ? importInstructionConfig[model](modelProps)
    : importInstructionConfig[model] || {};

  return (
    <Modal title={title} open width="600px" onClose={() => onClose(false)} onSubmit={goNext} submitText="Avançar">
      <Div direction="column" $fullWidth align="start" gap={spaces.space2}>
        <Subtitle>Passo a passo para importar</Subtitle>
        <Div direction="column" $fullWidth align="start" gap={spaces.space1}>
          <Card bodyBackgroundColor={colors.neutral50} bodyPadding={`${spaces.space1} ${spaces.space2}`}>
            <Div $fullWidth justify="space-between">
              <Paragraph weight={fonts.weight500}>
                <Subtitle color={colors.primary600}>1.</Subtitle> Baixe a <b>planilha modelo</b>
              </Paragraph>
              <Button type="primary" onClick={() => window.open(link)}>
                <FontAwesomeIcon icon={faDownload} />
                Baixar modelo
              </Button>
            </Div>
          </Card>
          <Card bodyBackgroundColor={colors.neutral50}>
            <Paragraph weight={fonts.weight500}>
              <Subtitle color={colors.primary600}>2.</Subtitle> Preencha com seus dados e salve a planilha
            </Paragraph>
          </Card>
          <Card bodyBackgroundColor={colors.neutral50}>
            <Paragraph weight={fonts.weight500}>
              <Subtitle color={colors.primary600}>3.</Subtitle> Importe a planilha clicando em <b>Avançar</b>
            </Paragraph>
          </Card>
        </Div>
        <Line />
        <Subtitle>Informações importantes</Subtitle>
        <ul style={{ listStyle: 'disc', padding: `0 ${spaces.space3}` }}>
          <Div direction="column" $fullWidth align="start" gap={spaces.space1}>
            {infos?.map(info => (
              <li>
                <Paragraph type="small">
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: info }} />
                </Paragraph>
              </li>
            ))}
          </Div>
        </ul>
        <Card bodyBackgroundColor={colors.neutral50} bodyPadding={`${spaces.space1}`}>
          <Div gap={spaces.space0}>
            <FontAwesomeIcon icon={faCircleQuestion} color={colors.primary600} size="sm" />
            <Paragraph type="small" weight={fonts.weight500}>
              Precisa de ajuda com a importação? <HelperLink id={id}>Veja o tutorial de passo a passo</HelperLink>
            </Paragraph>
          </Div>
        </Card>
      </Div>
    </Modal>
  );
};

ImportInstructionModal.propTypes = {
  model: PropTypes.string,
  onClose: PropTypes.func,
  goNext: PropTypes.func,
  modelProps: PropTypes.instanceOf(Object)
};

export default ImportInstructionModal;
