import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Subtitle } from '../Text/Text';
import { ContentMethod } from './VobiPayPayment.styles';
import Form from '../Form/Form';
import { creditMapping, creditSchema } from '../../lib/mapping/Form/creditSchema';
import useCRUD from '../../_Hooks/useCRUD';
import { sumByField } from '../../lib/helpers/helper';
import formatCurrency from '../../lib/helpers/formatCurrency';
import WarningBar from '../Alert/WarningBar';

const submitDescription = `Ao clicar em realizar pagamento, confirmo que as informações acima são
  minhas e me responsabilizo por quaisquer divergências.`;

const MethodCredit = ({ installmentToPay, afterSubmit, installments, companyCustomer, company, setSuccessModal }) => {
  const { installmentStatuses } = useSelector(state => state.setup.enums);
  const openInstallments = installments?.filter(i => i.idInstallmentStatus === installmentStatuses.pendingPayment);
  const totalInstallments = sumByField(openInstallments, 'price');
  const countInstallments = openInstallments.length;
  const countAllInstallments = installments.length;
  const [processing, setProcessing] = useState(false);
  const { handleCreate } = useCRUD({
    model: 'pay',
    pathOptions: `/pay-with-creditCard`,
    immediatelyLoadData: false
  });

  const { fine, interest, dueDateDiscount, dueDateLimitDays } = installmentToPay?.extraValues || {};

  const dueDateDay = dayjs(installmentToPay?.dueDate).startOf('day');
  const today = dayjs().startOf('day');
  const isOverdue = dueDateDay.isBefore(today);

  const diff = dayjs(dueDateDay).diff(today, 'days');
  const limitDaysAlertDueDate = diff >= dueDateLimitDays;

  const withDiscount = countAllInstallments === 1 && !isOverdue && dueDateDiscount && limitDaysAlertDueDate;
  const withFineInterest = countAllInstallments === 1 && isOverdue && (fine || interest);

  const fineInterestDiscountText = withFineInterest ? ' + os valores de juros e multa.' : null;

  const pluralText = countInstallments > 1 ? 's' : '';
  const extendPluralText = `ao mês, totalizando ${formatCurrency(totalInstallments, {
    currencySymbol: 'R$'
  })}`;

  const textAlert = `Ser${countInstallments > 1 ? 'ão' : 'á'} cobrada${pluralText} no seu cartão a${pluralText} ${
    countInstallments > 1 ? countInstallments : ''
  } parcela${pluralText} restante${pluralText} no
  valor de ${formatCurrency(
    withDiscount ? openInstallments[0]?.price - installmentToPay?.discount : openInstallments[0]?.price,
    {
      currencySymbol: 'R$'
    }
  )} `;

  const handleSubmit = values => {
    setProcessing(true);
    handleCreate({
      values,
      postPathOptions: `/pay-with-creditCard/${installmentToPay?.id}`,
      refresh: false
    }).then(resp => {
      setTimeout(() => {
        setSuccessModal({ open: true, type: resp?.status, value: resp?.value });
        afterSubmit();
        setProcessing(false);
      }, 2000);
    });
  };

  return (
    <ContentMethod>
      <Subtitle type="secondary" id="title-method">
        Informe os dados do cartão para efetuar o pagamento:
      </Subtitle>
      <WarningBar
        warning={`${textAlert}${fineInterestDiscountText || (pluralText && extendPluralText)}`}
        width="100%"
      />
      <Form
        id="creditForm"
        schema={creditSchema}
        mapping={creditMapping(company)}
        customButtonTitle="Realizar pagamento"
        justify="start"
        submitDescription={submitDescription}
        onSubmit={handleSubmit}
        loading={processing}
        data={companyCustomer}
      />
    </ContentMethod>
  );
};

MethodCredit.propTypes = {
  installmentToPay: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  installments: PropTypes.instanceOf(Array),
  companyCustomer: PropTypes.instanceOf(Object),
  company: PropTypes.instanceOf(Object),
  setSuccessModal: PropTypes.func
};

export default MethodCredit;
