import * as Yup from 'yup';
import { capitalize } from '../../helpers/helper';
import { simpleName, isActive } from '../schema';

const simpleFormSchema = Yup.object().shape({
  name: simpleName,
  isActive
});

const simpleFormMapping = (label, name) => ({
  name: {
    name: `${name || capitalize(label)}*`,
    type: 'text',
    placeholder: `Informe o nome da ${label}`
  },
  isActive: {
    type: 'checkbox',
    name: 'Ativo?',
    xs: 6,
    defaultValue: true
  }
});

export { simpleFormSchema, simpleFormMapping };
