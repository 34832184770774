import { product } from './product';
import { withdrawInfo, withdrawValues } from './withdraw';
import { companyCustomer, companyInfo, companyOperator, companyProject, companyNetwork } from './company';
import { opportunity, project } from './projectAndOpportunity';
import { customer, supplier, provider } from './customerSupplierAndProvider';
import { labor } from './labor';
import { instalment } from './instalment';
import { bank } from './bank';
import { address } from './address';
import { invoiceInfo, emit, dest, shippingCompany, invoiceTotals, location } from './invoice';

const mappingDescriptionConf = {
  provider,
  customer,
  companyCustomer,
  product,
  labor,
  instalment,
  bank,
  supplier,
  opportunity,
  project,
  companyProject,
  companyInfo,
  companyOperator,
  address,
  companyNetwork,
  withdrawInfo,
  withdrawValues,
  invoiceInfo,
  emit,
  dest,
  shippingCompany,
  invoiceTotals,
  location
};

export { mappingDescriptionConf };
