import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { List, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { saveRefurbish } from '../../store/refurbish/actions/action';

// hooks
import useCRUD from '../../_Hooks/useCRUD';
import Drawer from '../Drawer/Drawer';
import CenteredLoader from '../Loader/CenteredLoader';
import Input from '../Input/Input';
import { Paragraph } from '../Text/Text';
import { Ellipsis, spaces } from '../../styles/style';
import { addressConcat } from '../../lib/helpers/helper';

const RefurbishListModal = ({ open, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const refurbish = useSelector(state => state.refurbishReducer);
  const { user } = useSelector(state => state.authReducer);
  const [refurbishes, setRefurbishes] = useState([]);
  const [_open, setOpen] = useState(open);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  // hook
  const { loading, handleGet } = useCRUD({
    model: 'user',
    options: {
      include: {
        model: 'refurbish',
        where: { isShared: true },
        required: false,
        include: [{ model: 'user', where: { userType: 'provider' }, required: false }]
      }
    },
    pathOptions: `/${user.id}`,
    showToast: false,
    immediatelyLoadData: false
  });

  const [_refurbish, setRefurbish] = useState(refurbish || {});

  useEffect(() => {
    if ((isCustomer && !refurbish?.id) || open) {
      handleGet().then(resp => {
        if (resp?.refurbishes?.length) {
          setRefurbishes(resp.refurbishes);

          if (resp.refurbishes.length === 1) {
            dispatch(saveRefurbish(resp.refurbishes[0]));
            if (onSubmit) onSubmit();
          } else {
            setOpen(true);
          }
        }
      });
    } else {
      setOpen(false);
    }
  }, [_open, open]);

  const handleChange = item => {
    setRefurbish(item);
  };

  const handleSelect = e => {
    e.preventDefault();

    dispatch(saveRefurbish(_refurbish));
    setOpen(false);
    toast.success('Projeto alterado com sucesso');

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Drawer
      title="Selecione um projeto"
      open={_open}
      onClose={onCancel}
      showCancel={!!onCancel}
      loading={loading}
      onSubmit={handleSelect}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <List>
          {refurbishes && refurbishes.length
            ? refurbishes.map(item => (
                <Row gutter={8} key={item.id} style={{ paddingBottom: spaces.space2 }}>
                  <Col md={1} xs={2} style={{ display: 'flex', alignItems: 'center', marginRight: spaces.space2 }}>
                    <Input
                      id="refurbish"
                      name="refurbish"
                      type="radio"
                      value={item.id}
                      defaultChecked={refurbish && refurbish.id === item.id}
                      onChange={() => handleChange(item)}
                      style={{ transform: 'scale(1.75)' }}
                    />
                  </Col>
                  <Col md={3} xs={4}>
                    <Paragraph type="small">#</Paragraph>
                    <Paragraph>{item.id}</Paragraph>
                  </Col>
                  <Col md={10} xs={16}>
                    <Paragraph type="small">Nome</Paragraph>
                    <Paragraph>{item.name}</Paragraph>
                  </Col>
                  <Col md={9} xs={24}>
                    <Paragraph type="small">Endereço</Paragraph>
                    <Ellipsis>
                      <Paragraph>{addressConcat(item)}</Paragraph>
                    </Ellipsis>
                  </Col>
                </Row>
              ))
            : null}
        </List>
      )}
    </Drawer>
  );
};

RefurbishListModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

RefurbishListModal.defaultProps = {
  open: false,
  onSubmit: null,
  onCancel: null
};

export default RefurbishListModal;
