import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AboutContent, Group } from './Onboarding.styled';
import Select from '../../../components/Select/Select';
import { Subtitle, Title } from '../../../components/Text/Text';
import Input from '../../../components/Input/Input';
import { fonts, spaces, colors } from '../../../styles/style';

const AboutYou = ({ data, onChange }) => {
  const { onboardingConfigDescriptionList, mainActivityList } = useSelector(state => state.setup.systemData);

  const [_data, setData] = useState(data);
  const [isDescriptionInputVisible, setIsDescriptionInputVisible] = useState(false);
  const [isServiceInputVisible, setIsServiceInputVisible] = useState(false);

  const handleChange = ({ onboarding, idType }) => {
    const newData = { ..._data, idType: idType || _data?.idType, onboarding: { ..._data?.onboarding, ...onboarding } };
    setData(newData);
    onChange(newData);
  };

  useEffect(() => {
    const isOther = _data?.onboarding?.configDescription === 13;
    setIsDescriptionInputVisible(isOther);

    if (!isOther) {
      handleChange({ onboarding: { otherDescription: undefined } });
    }
  }, [_data?.onboarding?.configDescription]);

  useEffect(() => {
    const isOther = _data?.onboarding?.mainActivity === 5;
    setIsServiceInputVisible(isOther);

    if (!isOther) {
      handleChange({ onboarding: { otherMainActivity: undefined } });
    }
  }, [_data?.onboarding?.mainActivity]);

  return (
    <AboutContent>
      <Group gap={spaces.space1}>
        <Title size={fonts.sizeH5} id="onboarding-page-title">
          Conte-nos um pouco sobre você!
        </Title>
        <Subtitle weight={fonts.weight500} color={colors.neutral600}>
          Isso nos permitirá oferecer uma experiência ainda mais personalizada.
        </Subtitle>
      </Group>

      <Group>
        <Group gap={spaces.space2} width="100%">
          <Subtitle color={colors.neutral600}>
            Qual é o principal segmento da sua empresa?{' '}
            <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral500}>
              (selecione o que aplicar)
            </Subtitle>
          </Subtitle>
          <Select
            id="configOption"
            name="configOption"
            options={onboardingConfigDescriptionList}
            type="select"
            value={_data?.onboarding?.configDescription}
            placeholder="Escolha uma opção"
            onChange={e => handleChange({ idType: e, onboarding: { configDescription: e } })}
          />
          {isDescriptionInputVisible && (
            <Input
              type="text"
              placeholder="Informe o valor aqui"
              value={_data?.onboarding?.otherDescription}
              onChange={e => handleChange({ onboarding: { otherDescription: e?.target?.value } })}
              style={{ maxWidth: '304px' }}
            />
          )}
        </Group>

        <Group gap={spaces.space2}>
          <Subtitle color={colors.neutral600}>
            Levando em consideração os serviços que realiza, qual a principal atuação da sua empresa?
          </Subtitle>
          <Select
            id="serviceOption"
            name="serviceOption"
            options={mainActivityList}
            type="select"
            value={_data?.onboarding?.mainActivity}
            placeholder="Escolha uma opção"
            onChange={e => handleChange({ onboarding: { mainActivity: e } })}
          />
          {isServiceInputVisible && (
            <Input
              type="text"
              placeholder="Informe o valor aqui"
              value={_data?.onboarding?.otherMainActivity}
              onChange={e => handleChange({ onboarding: { otherMainActivity: e?.target?.value } })}
              style={{ maxWidth: '304px' }}
            />
          )}
        </Group>
      </Group>
    </AboutContent>
  );
};

AboutYou.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default AboutYou;
