import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { faFolder, faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Meta from 'antd/lib/card/Meta';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { faImageSlash } from '@fortawesome/pro-duotone-svg-icons';
import { StyledDiv, StyledCard, StyleTag, StyledAuthor, StyledFooter, StyledIconBox } from './TemplateCard.styled';
import StyledAvatar from '../AvatarEditor/Avatar';
import vobiLogo from '../Images/vobi-logo.svg';
import { getInitials } from '../../lib/helpers/helper';

const TemplateCard = ({ data = {}, onSelect = null }) => {
  const _image = data.image || (data.images && data.images[0]);
  const { templateType, templateCategory, templateModule } = useSelector(state => state.setup.enums);

  return (
    <StyledDiv role="presentation" onClick={onSelect} id={`template-${data?.id}`}>
      <StyledCard
        cover={
          <Tooltip
            title={
              data?.description
                ? parse(data.description?.length > 110 ? `${data.description.substring(0, 110)}...` : data.description)
                : null
            }
          >
            {_image ? (
              <img
                style={{
                  objectPosition: _image ? 'top' : 'center',
                  objectFit: 'cover',
                  height: 128
                }}
                src={_image}
                alt={data.name}
              />
            ) : (
              <FontAwesomeIcon icon={faImageSlash} />
            )}
          </Tooltip>
        }
      >
        {data?.type && (
          <StyleTag>
            <span>{templateType?.[data?.type]?.label || 'Projeto'}</span>
          </StyleTag>
        )}
        <StyledAuthor>
          {data?.company?.logo ? (
            <div>
              <StyledAvatar size={24} src={data?.company?.logo}>
                {getInitials(data?.company?.name)}
              </StyledAvatar>
            </div>
          ) : (
            <img className="vobi-logo" src={vobiLogo} alt="Template" />
          )}
          <span className="authorName" id={`author-name-${data?.id}`}>
            {data?.company?.name || 'Vobi'}
          </span>
        </StyledAuthor>
        <Tooltip title={data?.name}>
          <Meta
            title={data?.name}
            description={
              data?.description
                ? parse(data.description?.length > 110 ? `${data.description.substring(0, 110)}...` : data.description)
                : null
            }
          />
        </Tooltip>
        <StyledFooter>
          <StyledIconBox>
            <Tooltip title={data?.module === templateModule.all.value ? 'Completo' : 'Orçamento'}>
              <FontAwesomeIcon icon={data?.module === templateModule.all.value ? faFolder : faReceipt} />
            </Tooltip>
          </StyledIconBox>
          <span>
            {templateCategory?.[data.category]?.label
              ? templateCategory?.[data.category]?.label
              : templateCategory?.others?.label}
          </span>
        </StyledFooter>
      </StyledCard>
    </StyledDiv>
  );
};

TemplateCard.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func
};

export default TemplateCard;
