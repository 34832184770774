export const AUTH = {
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  UPDATE_USER: 'AUTH_UPDATE_USER',
  UPDATE_COMPANY: 'AUTH_UPDATE_COMPANY',
  UPDATE_PERMISSIONS: 'AUTH_UPDATE_PERMISSIONS',
  LOGIN_ANONYMOUS: 'AUTH_LOGIN_ANONYMOUS',
  REHYDRATE: 'persist/REHYDRATE'
};

export const login = ({ user }) => ({
  type: AUTH.LOGIN,
  payload: { user }
});

export const logout = () => ({ type: AUTH.LOGOUT });

export const updateUser = user => ({
  type: AUTH.UPDATE_USER,
  payload: user
});

export const updateCompany = company => ({
  type: AUTH.UPDATE_COMPANY,
  payload: company
});

export const updatePermissions = permissions => ({
  type: AUTH.UPDATE_PERMISSIONS,
  payload: permissions
});

export const loginAnonymous = payload => ({
  type: AUTH.LOGIN_ANONYMOUS,
  payload
});
