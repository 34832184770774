import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import useViewport from '../../_Hooks/useViewport';

import { Configuration, ContainerCheckboxes, StyledTable } from './PaymentConfiguration.styles';
import Select from '../Select/Select';
import { useContextHook } from '../../contexts/GeneralContext';
import Label from '../Label/Label';
import { useIsMount } from '../../_Hooks/useIsMount';
import { Error, Paragraph, Subtitle } from '../Text/Text';
import PaymentConfigurationMobileCard from '../List/PaymentConfigurationMobileCard';
import { Div } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';

const InstallmentConfiguration = ({
  typeLabel,
  columns,
  installments,
  total,
  disable,
  generateInstallments,
  installmentToPay,
  defaultTypesNameList,
  onlyTable,
  mobileParams,
  showExtraComponents,
  isCustomerView,
  isOrder
}) => {
  const { errors, touched, setTouched, paymentTypes, setPaymentTypes, title } = useContextHook();

  const _paymentTypes = useRef(paymentTypes);

  const [options, setOptions] = useState([]);
  const [numberOfInstalments, setNumberOfInstalments] = useState(installments?.length || 0);
  const isMount = useIsMount();
  const { maxInstallmentLength = 60 } = useSelector(state => state.setup.enums) || {};

  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const { list, handleGet } = useCRUD({
    model: 'paymentType',
    options: {
      where: { id: { ne: 0 } },
      order: [['name', 'asc']]
    }
  });

  useEffect(() => {
    const array = [
      {
        label: 'Selecione',
        value: 0
      }
    ];

    for (let i = 1; i <= maxInstallmentLength; i++) {
      array.push({ label: `${i}x`, value: i });
    }

    handleGet();

    setOptions(array);
  }, []);

  useEffect(() => {
    if (isMount) return;
    generateInstallments(numberOfInstalments || installments?.length);
  }, [total]);

  useEffect(() => {
    if (options.length > 0) {
      setNumberOfInstalments(installments?.length || 0);
    }
  }, [installments, options]);

  const hasValueError = (touched?.total && errors?.total) || (touched?.value && errors?.value);

  const paymentCaptionReadOnly =
    paymentTypes?.reduce((acc, type, index) => {
      let text = type.name || type.label || '-';

      if (index === paymentTypes?.length - 2) {
        text += ' e ';
      } else if (index !== paymentTypes?.length - 1) {
        text += ', ';
      }

      return acc + text;
    }, '') || '-';

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <>
        {onlyTable ? null : (
          <div>
            {disable ? (
              <Configuration>
                {title && <Subtitle>{title}</Subtitle>}
                <Space direction={_isMobile ? 'vertical' : 'horizontal'} size={_isMobile ? 32 : 16}>
                  <div>
                    <Label>Condições de pagamento</Label>
                    <div>
                      <Paragraph type="small" id="paymentInstallments">
                        {`${numberOfInstalments || 1}x`}
                      </Paragraph>
                    </div>
                  </div>
                  {(isOrder || (defaultTypesNameList?.length > 0 && !onlyTable && !isCustomerView)) && (
                    <div>
                      <Label>Forma de pagamento</Label>
                      <div>
                        {paymentTypes ? (
                          <Paragraph type="small">{paymentCaptionReadOnly}</Paragraph>
                        ) : (
                          <ContainerCheckboxes
                            disabled
                            defaultValue={defaultTypesNameList}
                            options={defaultTypesNameList}
                          >
                            <Checkbox />
                          </ContainerCheckboxes>
                        )}
                      </div>
                    </div>
                  )}
                </Space>
              </Configuration>
            ) : (
              <Space direction={_isMobile ? 'vertical' : 'horizontal'} size={_isMobile ? 16 : 32}>
                <div>
                  <Label>Condições de pagamento</Label>

                  <Tooltip title={total ? '' : typeLabel} id="paymentConditionTooltip">
                    <div style={{ width: isMobile() ? '100%' : '184px' }} id="paymentInstallments">
                      <Select
                        id="select-paymentInstallments"
                        value={numberOfInstalments}
                        onChange={val => {
                          if (!val) return;
                          if (setTouched) {
                            setTouched('total')(true);
                            setTouched('value')(true);
                          }
                          generateInstallments(val);
                        }}
                        disabled={disable}
                        options={options}
                      />
                      {hasValueError && <Error>{errors?.total || errors?.value}</Error>}
                    </div>
                  </Tooltip>
                </div>
                {showExtraComponents && list.length && (
                  <Div direction="column" align="flex-start">
                    <Label>Forma de pagamento</Label>
                    <Select
                      multiple
                      name="paymentTypes"
                      id="paymentTypes"
                      style={{ width: '250px' }}
                      placeholder="Selecione"
                      options={list}
                      defaultValue={paymentTypes?.map(item => item?.id || item?.value)}
                      onBlur={() => {
                        setPaymentTypes(_paymentTypes.current);
                      }}
                      onChange={value => {
                        _paymentTypes.current = value;
                      }}
                      showArrow
                      allowClear
                      showSearch
                      noHeight
                    />
                  </Div>
                )}
              </Space>
            )}
          </div>
        )}
        {installments?.length > 0 && (
          <>
            {!_isMobile && !showExtraComponents ? <Subtitle type="secondary">Parcelas</Subtitle> : null}

            {_isMobile ? (
              <PaymentConfigurationMobileCard
                {...mobileParams}
                list={installments}
                numberOfInstalments={numberOfInstalments}
                installmentToPay={installmentToPay}
                isView={disable}
                isCustomerView={isCustomerView}
              />
            ) : (
              <StyledTable
                rowKey={row => {
                  return row.id || row?.number;
                }}
                columns={columns}
                dataSource={installments}
                size="small"
                pagination={false}
                rowClassName={_row => {
                  const selectedRow = _row?.id && _row?.id === installmentToPay && isCustomerView && onlyTable;
                  return selectedRow ? 'selected-row' : '';
                }}
              />
            )}
          </>
        )}
      </>
    </Space>
  );
};

InstallmentConfiguration.propTypes = {
  typeLabel: PropTypes.string,
  columns: PropTypes.instanceOf(Array),
  installments: PropTypes.instanceOf(Array),
  total: PropTypes.number,
  disable: PropTypes.bool,
  generateInstallments: PropTypes.func,
  installmentToPay: PropTypes.number,
  defaultTypesNameList: PropTypes.instanceOf(Array),
  onlyTable: PropTypes.bool,
  mobileParams: PropTypes.instanceOf(Object),
  showExtraComponents: PropTypes.bool,
  isCustomerView: PropTypes.bool,
  isOrder: PropTypes.bool
};

export default InstallmentConfiguration;
