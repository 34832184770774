import React from 'react';
import PropTypes from 'prop-types';
import { Div, spaces } from '../../styles/style';
import Button from '../Button/Button';
import PurchaseFooterHelper from './PurchaseFooterHelper';
import Footer from './Footer';
import useBackToContext from '../../_Hooks/useBackToContext';

const PurchaseOrderFooter = ({
  isView,
  isReview,
  isDraft,
  isEditable,
  data,
  getPurchase,
  baseUrl,
  idRefurbish,
  id,
  handleSave,
  setIsReview,
  validateSubmit,
  handlePrint
} = {}) => {
  const backToContext = useBackToContext();

  const isReadOnly = isView || isReview;

  const {
    EditButton,
    EditTextButton,
    SaveDraftButton,
    SaveButton,
    ActionsButton,
    ReviewButton,
    SaveOrderButton,
    SaveAndSendOrderButton
  } = PurchaseFooterHelper({
    model: 'order',
    isReview,
    data,
    getPurchase,
    baseUrl,
    idRefurbish,
    id,
    handleSave,
    setIsReview,
    validateSubmit,
    handlePrint
  });

  return (
    <Footer>
      <Button id="purchase-go-back" text onClick={backToContext}>
        Voltar
      </Button>
      <Div gap={spaces.space3}>
        {isDraft || !data?.idOrderStatus ? (
          <>
            {isReview ? (
              <>
                <EditTextButton />
                <SaveOrderButton />
                <SaveAndSendOrderButton />
              </>
            ) : (
              <>
                {isView ? <EditTextButton /> : <SaveDraftButton />}
                <ReviewButton />
              </>
            )}
          </>
        ) : (
          <>
            {isReadOnly && <ActionsButton />}
            {isEditable && isReadOnly && <EditButton />}
            {!isReadOnly && <SaveButton />}
          </>
        )}
      </Div>
    </Footer>
  );
};

PurchaseOrderFooter.propTypes = {
  isView: PropTypes.bool,
  isReview: PropTypes.bool,
  isDraft: PropTypes.bool,
  isEditable: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  getPurchase: PropTypes.func,
  baseUrl: PropTypes.string,
  idRefurbish: PropTypes.number,
  id: PropTypes.number,
  handleSave: PropTypes.func,
  setIsReview: PropTypes.func,
  validateSubmit: PropTypes.func,
  handlePrint: PropTypes.func
};

export default PurchaseOrderFooter;
