import React from 'react';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobilePhone } from '@fortawesome/pro-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import { getBillingManagerMessage, getColorByStatusBillingManager } from '../../helpers/helper';
import { Div, colors, fonts, spaces } from '../../../styles/style';
import { Paragraph, Subtitle } from '../../../components/Text/Text';

const CustomDiv = styled.div`
  padding-top: ${spaces.space2};
  width: 44%;

  ${props =>
    props.$alignLeft &&
    css`
      display: flex;
      justify-content: right;
      padding-right: ${spaces.space1};
    `}
`;

const CheckboxStyle = styled(Checkbox)`
  position: absolute;
  top: ${spaces.space2};
  right: ${spaces.space2};
`;

const DivSvg = styled.div`
  padding: ${spaces.space2} ${spaces.space1} 0 ${spaces.space1};
`;

const columns = ({
  isMobile,
  billingManagerType,
  billingManagerChannels,
  billingManagerStatusDesc,
  billingManagerStatus,
  selectedItems,
  isGeneral,
  setSelectedItems
}) => {
  const allColumns = [
    {
      title: 'Data',
      dataIndex: 'notificationDate',
      key: 'notificationDate',
      className: 'col-grow-1',
      render: (val, row) => {
        const formattedDate = row.sentDate
          ? dayjs(row.sentDate).format('DD/MM/YYYY HH:mm')
          : dayjs(val).format('DD/MM/YYYY');
        return <div>{formattedDate}</div>;
      },
      shouldCellUpdate: false
    },
    {
      title: 'Descrição',
      dataIndex: 'idCompanyCustomer',
      key: 'idCompanyCustomer',
      className: 'col-grow-4',
      render: (val, row) => {
        const description = getBillingManagerMessage(row, billingManagerChannels, billingManagerType);
        return <div>{description}</div>;
      },
      shouldCellUpdate: false
    },
    ...(isGeneral
      ? [
          {
            title: 'Pagamento',
            dataIndex: 'payment',
            key: 'idPayment',
            className: 'col-grow-1',
            render: (val, row) => {
              return <div>{row?.payment?.name}</div>;
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'idStatus',
      key: 'idStatus',
      className: ['width-90', 'center'],
      render: val => {
        const color = getColorByStatusBillingManager(val, billingManagerStatus);
        return (
          <Paragraph type="small" color={color}>
            {billingManagerStatusDesc[val]}
          </Paragraph>
        );
      },
      shouldCellUpdate: false
    },
    {
      title: 'Canal',
      dataIndex: 'channel',
      key: 'channel',
      className: ['width-60', 'center'],
      render: val => {
        const iconMapping = {
          [billingManagerChannels?.email?.value]: faEnvelope,
          [billingManagerChannels?.sms?.value]: faMobilePhone,
          [billingManagerChannels?.whatsapp?.value]: faWhatsapp
        };

        const icon = iconMapping[val] || faEnvelope;
        return <TooltipIcon text={billingManagerChannels?.[val]?.label} icon={icon} color={colors.neutral500} />;
      },
      shouldCellUpdate: false
    },
    {
      title: 'Descrição',
      dataIndex: 'idCompanyCustomer',
      key: 'idCompanyCustomer',
      onlyMobile: true,
      render: (val, row) => {
        const description = getBillingManagerMessage(row, billingManagerChannels, billingManagerType);
        return (
          <Div>
            <Paragraph
              type="small"
              style={{ paddingRight: isGeneral && spaces.space1 }}
              size={fonts.sizeMd}
              lineHeight={fonts.sizeH5}
            >
              {description}
            </Paragraph>

            {isGeneral ? (
              <CheckboxStyle
                checked={selectedItems?.some(item => item?.id === row?.id)}
                onChange={e =>
                  e?.target?.checked
                    ? setSelectedItems(prev => [...prev, row])
                    : setSelectedItems(selectedItems?.filter(item => item?.id !== row?.id))
                }
              />
            ) : null}
          </Div>
        );
      },
      shouldCellUpdate: false
    },
    ...(isGeneral
      ? [
          {
            title: 'Pagamento',
            dataIndex: 'payment',
            key: 'idPayment',
            className: 'col-grow-1',
            onlyMobile: true,
            render: (val, row) => {
              return (
                <Div style={{ width: '100%', paddingTop: spaces.space2 }}>
                  <div>{`Projeto: ${row?.payment?.name}`}</div>
                </Div>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    {
      title: 'Data',
      dataIndex: 'notificationDate',
      key: 'notificationDate',
      onlyMobile: true,
      render: (val, row) => {
        const formattedDate = row.sentDate
          ? dayjs(row.sentDate).format('DD/MM/YYYY HH:mm')
          : dayjs(val).format('DD/MM/YYYY');
        return (
          <CustomDiv>
            <Subtitle type="secondary" weight={fonts.weight400}>
              {formattedDate}
            </Subtitle>
          </CustomDiv>
        );
      },
      shouldCellUpdate: false
    },
    {
      title: 'Status',
      dataIndex: 'idStatus',
      key: 'idStatus',
      onlyMobile: true,
      render: val => {
        const color = getColorByStatusBillingManager(val, billingManagerStatus);
        return (
          <CustomDiv $alignLeft>
            <Paragraph type="small" color={color}>
              {billingManagerStatusDesc[val]}
            </Paragraph>
          </CustomDiv>
        );
      },
      shouldCellUpdate: false
    },
    {
      title: 'Canal',
      dataIndex: 'channel',
      key: 'channel',
      onlyMobile: true,
      render: val => {
        const iconMapping = {
          [billingManagerChannels?.email?.value]: faEnvelope,
          [billingManagerChannels?.sms?.value]: faMobilePhone,
          [billingManagerChannels?.whatsapp?.value]: faWhatsapp
        };

        const icon = iconMapping[val] || faEnvelope;
        return (
          <DivSvg>
            <FontAwesomeIcon icon={icon} color={colors.neutral500} />
          </DivSvg>
        );
      },
      shouldCellUpdate: false
    }
  ];

  return isMobile ? allColumns.filter(c => c.onlyMobile) : allColumns.filter(c => !c.onlyMobile);
};

export { columns };
