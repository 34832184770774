import styled, { css } from 'styled-components';
import { InputNumber } from 'antd';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';

export const StyledDiv = styled.div`
  ${props =>
    props.withError &&
    css`
      border: 1px solid ${colors.red500};
      border-radius: ${radius.radius1};
    `};

  width: 100%;
  min-height: ${spaces.space3};
  min-width: ${props => props.width && props.width};
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  align-items: ${props => (props.align ? props.align : 'center')};

  @media (max-width: ${breakpoints.tablet}) {
    flex-basis: 100%;
    font-size: ${fonts.sizeSm};
    padding: ${props => (props.noPadding ? 0 : `0 ${spaces.space0}`)};
  }

  svg {
    display: none;
  }

  &.form-component {
    font-size: ${fonts.sizeSm};

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fonts.sizeMd};
    }

    svg {
      display: block;
    }

    @media (max-width: ${breakpoints.tablet}) {
      padding: 0;
    }

    .ant-input-affix-wrapper-readonly {
      background-color: transparent !important;
      border-color: transparent !important;
      pointer-events: none;

      input {
        font-weight: ${fonts.weight600};
      }
    }

    .ant-input-prefix {
      font-size: ${fonts.sizeSm};
      margin-right: 0;
    }

    .ant-input-suffix {
      font-size: ${fonts.sizeSm};

      @media (max-width: ${breakpoints.tablet}) {
        font-size: ${fonts.sizeMd};
      }
    }

    .ant-input-affix-wrapper {
      font-size: ${fonts.sizeSm};
      height: ${spaces.space4};
      padding: ${spaces.space0} ${spaces.space1} ${spaces.space0} ${spaces.space1};
      border-color: transparent;
      color: ${colors.neutral400};
      gap: ${spaces.space0};

      @media (max-width: ${breakpoints.tablet}) {
        font-size: ${fonts.sizeMd};
        padding: 0;
        background-color: ${colors.white};
        border: 0;
        gap: 0;
      }

      input {
        border: none !important;
        background-color: transparent;
        color: ${colors.neutral600};
      }

      &.ant-input-affix-wrapper-disabled {
        background-color: ${colors.neutral75};
      }

      .ant-input-suffix {
        flex-direction: row-reverse;
        color: ${colors.neutral400};
        gap: ${spaces.space1};

        .ant-input-clear-icon {
          cursor: pointer;
          font-size: ${fonts.sizeMd};
          color: transparent;
          transition: color 0.3s ease;
        }
      }

      &:has(input:not([value=''])) {
        .ant-input-suffix {
          color: ${colors.neutral600};
        }
      }
    }

    .ant-input-affix-wrapper-focused {
      border-color: ${colors.neutral100};
      background-color: ${colors.white};

      &:hover {
        background-color: ${colors.white} !important;
        border-color: ${colors.neutral100} !important;
      }
    }

    .ant-input-affix-wrapper:hover {
      background-color: ${colors.neutral75};
      border-color: ${colors.neutral75};

      .ant-input-clear-icon {
        color: ${colors.neutral600};

        &:hover {
          color: ${colors.neutral700};
        }
      }
    }

    > input {
      padding: ${spaces.space0} ${spaces.space1};
      border: 1px solid transparent;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      color: ${colors.neutral600};
      background-color: transparent;
      min-height: ${spaces.space4};

      &[readonly] {
        font-weight: ${fonts.weight600};
        background-color: transparent;
        border-color: transparent;
        pointer-events: none;
      }

      &:not([readonly]):hover {
        background-color: ${colors.neutral75};
        border-color: ${colors.neutral75};
      }

      &:not([readonly]):focus {
        border-color: ${colors.neutral100};
        background-color: ${colors.white};
      }
    }
  }

  input {
    font-size: inherit;
    border: 1px solid transparent;
    background-color: transparent;
    width: 100%;

    ${props =>
      props.padding &&
      css`
        padding: ${props.padding};
      `};

    ${props =>
      props.withError &&
      css`
        border: 1px solid ${colors.red500};
        border-radius: ${radius.radius1};
      `};

    ${props =>
      props.marginRight &&
      css`
        margin-right: ${spaces.space1};
      `};

    &:hover {
      border: ${props => !props.disabled && `1px solid ${colors.neutral500}`};
      border-radius: ${radius.radius1};
      cursor: ${props => props.cursor || 'text'};

      svg {
        display: ${props => !props.disabled && 'flex'};
      }
    }

    &:focus {
      border: ${props => !props.disabled && `1px solid ${colors.neutral500}`};
      border-radius: ${radius.radius1};
      cursor: ${props => props.cursor || 'text'};
      background-color: white;

      svg {
        display: ${props => !props.disabled && 'flex'};
      }
    }

    &[disabled] {
      background-color: ${colors.neutral75};
      border-color: transparent;
      color: ${colors.neutral400};

      &:hover {
        border-color: transparent;
        cursor: default;
      }

      ${props =>
        props.readOnly &&
        css`
          background-color: transparent;
          cursor: default;
          color: inherit;
          font: inherit;

          &:hover {
            background-color: transparent;
          }

          ::placeholder {
            color: currentColor;
          }
        `};
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  &.form-component {
    width: 100%;
    height: ${spaces.space4};
  }
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeSm};
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  svg {
    margin-left: ${spaces.space1};
    cursor: pointer;
  }
  .renameItem {
    display: none;
  }
  &:hover {
    .renameItem {
      display: block;
    }
  }
`;
