import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import Button from './Button';
import { spaces } from '../../styles/style';

const ExportSlipBank = ({ onClose = () => {}, idData, customUrl, ...params }) => {
  const analytics = AnalyticsServiceFactory();

  const handleCopyLink = () => {
    analytics.track({ event: `installment-copy-link`, eventProperties: { idInstallment: idData } });
    window.open(customUrl, '_blank');
  };

  return (
    <Button
      padding={spaces.space1}
      {...params}
      onClick={() => {
        handleCopyLink();
        onClose();
      }}
    />
  );
};

ExportSlipBank.propTypes = {
  onClose: PropTypes.func,
  idData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customUrl: PropTypes.string
};

export default ExportSlipBank;
