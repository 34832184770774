import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Box.module.scss';

const Box = ({ className, elevation, mb, noPageBreak, children, ...props }) => {
  const classes = classNames({
    [css['c-box']]: true,
    [css[`c-box--elevation-${elevation}`]]: !!elevation,
    [css[`c-box--margin-bottom-${mb}`]]: !!mb,
    [css['c-page-break']]: !noPageBreak
  });

  return (
    <div className={`${classes} ${className || ''}`} {...props}>
      {children}
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  elevation: PropTypes.number,
  mb: PropTypes.number,
  noPageBreak: PropTypes.bool
};

Box.defaultProps = {
  className: null,
  elevation: 0,
  mb: 0
};

Box.displayName = 'Box';

export default Box;
