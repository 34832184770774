import styled from 'styled-components';

import { breakpoints, spaces, fonts } from '../../styles/style';

import TooltipIcon from '../Tooltip/TooltipIcon';

export const BulkActionsChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${spaces.space2};
  }
`;

export const LeftTextContainer = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    @media (max-width: ${breakpoints.tablet}) {
      flex-direction: row;
      align-items: center;
    }

    p {
      margin-bottom: ${spaces.space0};
      width: auto;

      @media (max-width: ${breakpoints.tablet}) {
        margin-right: ${spaces.space2};
        margin-bottom: 0px;
      }
    }
  }

  div + div {
    margin-left: ${spaces.space2};
  }
`;

export const StyledIcon = styled(TooltipIcon)`
  svg {
    height: 18px;
    width: 18px;
    margin-left: ${spaces.space2};
  }
`;

export const IconDropdown = styled(TooltipIcon)`
  height: ${fonts.sizeLg};
  width: ${fonts.sizeLg};
  margin-left: ${spaces.space1};
  padding: ${spaces.space1};
  cursor: pointer;
`;

export const ModalItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spaces.space2};
  gap: ${spaces.space1};

  svg {
    margin: ${spaces.space0};
  }
`;
