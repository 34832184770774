import * as Yup from 'yup';
import { password, confirmNewPassword } from '../schema';

const changePasswordSchema = Yup.object().shape({
  password,
  newPassword: password,
  confirmNewPassword
});

const changePasswordMapping = {
  password: {
    name: 'Senha',
    type: 'password',
    placeholder: '*****',
    xs: 24
  },
  newPassword: {
    name: 'Nova Senha',
    type: 'password',
    placeholder: '*****',
    xs: 24
  },
  confirmNewPassword: {
    name: 'Confirmar Senha',
    type: 'password',
    placeholder: '*****',
    xs: 24
  }
};

export { changePasswordSchema, changePasswordMapping };
