const dependencies = ({ withParentInclude } = {}) => [
  { attributes: ['id', 'name'], model: 'RefurbishGroup', as: 'refurbishGroup' },
  { attributes: ['id', 'name'], model: 'Supplier', as: 'supplier' },
  { attributes: ['id', 'name'], model: 'CostCenter', as: 'costCenter' },
  { attributes: ['id', 'name'], model: 'Unit', as: 'units' },
  {
    model: 'Item',
    as: 'item',
    include: ['units']
  },
  { attributes: ['id', 'name', 'avatarFullpath'], model: 'User', as: 'user' },
  ...(withParentInclude
    ? [
        {
          attributes: ['quantity', 'type'],
          model: 'RefurbishItems',
          as: 'parent'
        }
      ]
    : [])
];

const attributes = {
  exclude: ['idOrigin', 'source', 'lastModifiedBy', 'observation']
};

const getRefetchOptions = ({
  isTemplate,
  idReference,
  filter,
  sorted,
  defaultSort,
  isGrouped,
  refurbishItemType,
  refurbishItemStatus,
  withParentInclude,
  withCompositionChildren = false,
  requiredChildren,
  extraInclude = []
}) => {
  const _sort = Array.isArray(sorted?.key) ? sorted.key : [sorted?.key];
  let order = sorted?.key ? [[..._sort, sorted.order], [['children', ..._sort, sorted.order]]] : defaultSort;

  let referenceKey;
  let columnsModel;
  let includeModel;

  if (isTemplate) {
    referenceKey = 'idTemplate';
    columnsModel = 'templateItem';
    includeModel = 'TemplateItem';
  } else {
    referenceKey = 'idRefurbish';
    columnsModel = 'refurbishItems';
    includeModel = 'RefurbishItems';
  }

  const filters = isTemplate ? {} : { status: refurbishItemStatus?.activeStatus };

  const hasFilter = Object.keys(filter || {})?.length > 0;

  let fatherFilters = {};
  let subFilters = {};
  let itemFilters = {};

  if (isGrouped) {
    order = sorted?.key
      ? [
          ['name', 'asc'],
          ['item', ..._sort, sorted.order],
          ['item', 'type', 'asc']
        ]
      : defaultSort;
  }

  if (hasFilter) {
    const and = [];
    const subLevelAnd = [];
    const itemAnd = [];

    const filtersByLevelMap = {
      name: true,
      plIdResponsible: true,
      plEndDate: true
    };

    Object.keys(filter).forEach(key => {
      if (isGrouped) {
        filters[key] = filter[key];

        return;
      }
      if (filtersByLevelMap[key]) {
        and.push({
          or: [
            {
              [`$children.${key}$`]: filter[key]
            },
            { [`$children->children.${key}$`]: filter[key] },
            { [key]: filter[key] }
          ]
        });

        subLevelAnd.push({
          or: [
            {
              [`$refurbishItems.${key}$`]: filter[key]
            },
            { [key]: filter[key] }
          ]
        });

        itemAnd.push({
          or: [
            {
              [`$children.${key}$`]: filter[key]
            },
            { [`$refurbishItems.${key}$`]: filter[key] },
            { [key]: filter[key] }
          ]
        });

        return;
      }

      filters[key] = filter[key];

      and.push({
        or: [
          {
            [`$children.${key}$`]: filter[key]
          },
          { [`$children->children.${key}$`]: filter[key] }
        ]
      });
    });
    fatherFilters = and?.length ? { and } : {};
    subFilters = subLevelAnd?.length ? { and: subLevelAnd } : {};
    itemFilters = itemAnd?.length ? { and: itemAnd } : {};
  }

  const hasFilterAndRequiredChildren = requiredChildren && hasFilter;

  return isGrouped
    ? {
        order,
        where: {
          or: [{ id: 1 }, { '$item.id$': { gte: 0 } }]
        },
        attributes: { exclude: ['type'] },
        include: [
          {
            attributes,
            where: {
              [referenceKey]: idReference,
              type: refurbishItemType.notParent,
              status: refurbishItemStatus.activeStatus,
              ...filters
            },
            model: includeModel,
            as: 'item',
            required: false,
            include: [
              {
                attributes: ['name'],
                model: includeModel,
                as: 'parent',
                where: { type: refurbishItemType.parent }
              },
              ...dependencies()
            ]
          }
        ]
      }
    : {
        attributes,
        order,
        where: {
          [referenceKey]: idReference,
          idParent: null,
          type: refurbishItemType.parent,
          ...(hasFilterAndRequiredChildren && {
            ...fatherFilters
          })
        },
        include: [
          {
            attributes,
            model: includeModel,
            as: 'children',
            where: {
              ...(!isTemplate && {
                or: [{ type: refurbishItemType.parent }, { ...filters, ...subFilters }]
              }),
              [`$${columnsModel}.type$`]: refurbishItemType.parent
            },
            required: false,
            include: [
              {
                attributes,
                model: includeModel,
                as: 'children',
                where: {
                  ...filters,
                  ...itemFilters,
                  ...(!withCompositionChildren ? { '$children.type$': refurbishItemType.parent } : {})
                },
                include: [
                  ...dependencies({ withParentInclude }),
                  ...(withCompositionChildren
                    ? [
                        {
                          attributes,
                          model: includeModel,
                          as: 'children',
                          include: dependencies({ withParentInclude }),
                          required: false
                        }
                      ]
                    : []),
                  ...extraInclude
                ],
                required: false
              },
              ...extraInclude,
              ...dependencies({ withParentInclude })
            ]
          },
          ...extraInclude,
          ...dependencies()
        ]
      };
};

const getRefetchOneOptions = ({ withIncludeOnChildren } = {}) => ({
  include: [
    {
      attributes,
      model: 'RefurbishItems',
      as: 'children',
      ...(withIncludeOnChildren && { include: dependencies() })
    },
    ...dependencies()
  ],
  order: [
    ['order', 'asc'],
    ['createdAt', 'asc'],
    ['children', 'order', 'asc'],
    ['children', 'createdAt', 'desc']
  ]
});

export { getRefetchOptions, getRefetchOneOptions };
