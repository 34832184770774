import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from './Modal';
import useViewport from '../../_Hooks/useViewport';
import { getPaymentLabel } from '../../lib/helpers/payment';
import { Div, colors } from '../../styles/style';
import RegisterPaymentContent from '../Content/RegisterPaymentContent';
import { Paragraph } from '../Text/Text';

const formId = 'modal-installemt-paid';

const RegisterInstallmentModal = ({
  idRefurbish,
  onClose = f => f,
  installment,
  onSubmit,
  valuesChanged,
  handleSave,
  billType,
  isCharge,
  touched = {},
  afterSubmit = f => f
}) => {
  const [values, setValues] = useState(true);
  const [open, setOpen] = useState(true);

  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { billType: billTypeEnum, installmentStatuses } = useSelector(state => state.setup.enums);
  const { idRefurbish: paymentRefurbish } = installment?.payment || {};

  const [paymentValidOrBill, setPaymentValidOrBill] = useState(false);

  const { data, handleUpdate, loading } = useCRUD({
    model: 'installment',
    options: {
      include: [{ model: 'payment', include: 'paymentTypes' }, 'files']
    },
    pathOptions: `/${installment?.id}`,
    immediatelyLoadData: installment.id
  });

  useEffect(() => {
    if (data) {
      setPaymentValidOrBill(data?.payment?.ownBusiness);
    }
  }, [data]);

  const updateInstallment = () => {
    const updateData = {
      updatePathOptions: `/${installment.id}`,
      where: { id: installment?.id, idRefurbish },
      values: {
        idInstallmentStatus: installment?.idPaymentAsaas ? installmentStatuses.paidManually : installmentStatuses.paid,
        paidDate: dayjs(values?.date).format('YYYY-MM-DD'),
        idPaymentType: values?.paymentType,
        idRefurbish: idRefurbish || paymentRefurbish,
        interest: values?.interest,
        fine: values?.fine,
        discount: values?.discount,
        files: values?.files,
        ...(paymentValidOrBill && { idPaymentBankAccount: values?.bankAccount || null })
      },
      displayToast: true,
      refresh: true
    };

    return handleUpdate(updateData).then(resp => {
      if (resp.error) return;
      if (afterSubmit) afterSubmit();
      onSubmit();
    });
  };

  const paymentLabel = getPaymentLabel({
    billType,
    isCharge,
    billTypeEnum
  });

  if (valuesChanged && Object?.values(touched)?.includes(true)) {
    return (
      <Modal
        loading={loading}
        title={`Primeiro você deve salvar a ${paymentLabel}`}
        open={open}
        onClose={e => {
          e.stopPropagation();
          onClose(null, false);
        }}
        width={426}
        submitText="Salvar"
        onSubmit={() => {
          handleSave();
          setOpen(false);
        }}
        headerLine
        zIndex={!_isMobile && 1001}
      >
        <Div direction="column" align="start" $fullWidth>
          <Paragraph color={colors.neutral600}>
            {`Para registrar o pagamento primeiro é necessário salvar as alterações da ${paymentLabel}. Deseja
            salvar?`}
          </Paragraph>
        </Div>
      </Modal>
    );
  }

  return (
    installment && (
      <Modal
        title="Registrar pagamento"
        open
        onClose={e => {
          e.stopPropagation();
          onClose(null, false);
        }}
        loading={loading}
        onSubmit={updateInstallment}
        width={430}
        formId={formId}
        headerLine
        zIndex={!_isMobile && 1001}
      >
        <RegisterPaymentContent
          installment={data}
          billType={billType}
          paymentValidOrBill={paymentValidOrBill}
          changeValues={setValues}
        />
      </Modal>
    )
  );
};

RegisterInstallmentModal.propTypes = {
  installment: PropTypes.instanceOf(Object).isRequired,
  idRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  valuesChanged: PropTypes.bool,
  handleSave: PropTypes.func,
  billType: PropTypes.string,
  isCharge: PropTypes.bool,
  touched: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func
};

export default memo(RegisterInstallmentModal);
