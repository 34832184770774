import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, spaces } from '../../styles/style';
import { Description } from '../Text/Text';
import Label from '../Label/Label';

const FlatTagStyle = styled.div`
  border-radius: ${spaces.space2};
  background-color: ${colors.neutral100};
  display: flex;
  align-items: center;
  padding: ${spaces.space0} ${spaces.space1};
  gap: ${spaces.space1};
  justify-content: center;

  &.success {
    background-color: ${colors.green100};
    span {
      color: ${colors.green500};
    }
  }

  &.primary {
    background-color: ${colors.primary50};
    span {
      color: ${colors.primary400};
    }
  }

  &.neutral {
    background-color: ${colors.neutral75};
    span {
      color: ${colors.neutral700};
    }
  }

  ${props =>
    props.$size === 'medium' &&
    `
    border-radius: ${spaces.space0};
  `}
`;

const FlatTag = ({ className, value, icon, size }) => {
  return (
    <FlatTagStyle className={className} $size={size}>
      {icon && <FontAwesomeIcon icon={icon} size="sm" />}
      {size === 'medium' ? (
        <Label fontWeight={500} color={colors.neutral700} marginBottom={0}>
          {value}
        </Label>
      ) : (
        <Description>{value}</Description>
      )}
    </FlatTagStyle>
  );
};

FlatTag.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.oneOf(['medium', 'small'])
};

export default FlatTag;
