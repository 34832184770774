import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import SpecificationTable from '../../../components/Table/SpecificationTable';
import useSpecification from '../../../_Hooks/useSpecification';

const SpecificationTemplate = ({ isApply, data, scroll }) => {
  const { user } = useSelector(state => state.authReducer);

  const [showUnitItem, setShowUnitItem] = useState(false);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const [expandItem, setExpandedItem] = useState(null);
  const [_isLoading, setIsLoading] = useState(false);
  const [forceRender, setForceRender] = useState(true);

  const {
    isLoading,
    selectedItems,
    setSelectedItems,
    selectedRowKeys,
    setSelectedRowKeys,
    columns,
    handleLoad,
    scrollIndex,
    setList,
    list,
    handleSort,
    levelList,
    supplierList,
    groupList,
    categoryList,
    handleSubmitRefurbishItems,
    refetchOptions,
    refetchLists,
    handleSelectItem,
    handleSelectAll
  } = useSpecification({
    data,
    isTemplate: true,
    setIsDefaultOrder,
    setShowUnitItem,
    setExpandedItem,
    idCompany: user?.idCompany,
    isApply
  });

  useEffect(() => {
    if (!list?.length || !forceRender) return;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setForceRender(false);
    }, 100);
  }, [list]);

  return !isLoading && !_isLoading ? (
    <SpecificationTable
      model="template-item"
      data={data}
      list={list}
      onSelect={handleSelectItem}
      onSelectAll={handleSelectAll}
      levelList={levelList}
      onSort={handleSort}
      selectedItems={selectedItems}
      selectedRowKeys={selectedRowKeys}
      setSelectedItems={setSelectedItems}
      setSelectedRowKeys={setSelectedRowKeys}
      scrollIndex={scrollIndex}
      setList={setList}
      columns={columns}
      handleLoad={handleLoad}
      supplierList={supplierList}
      groupList={groupList}
      categoryList={categoryList}
      refetchLists={refetchLists}
      refetchOptions={refetchOptions}
      handleSubmitRefurbishItems={handleSubmitRefurbishItems}
      isDefaultOrder={isDefaultOrder}
      showUnitItem={showUnitItem}
      setShowUnitItem={setShowUnitItem}
      expandItem={expandItem}
      setExpandedItem={setExpandedItem}
      isTemplate
      rowSelection={!isApply}
      isApply={isApply}
      scroll={scroll}
    />
  ) : null;
};

SpecificationTemplate.propTypes = {
  isApply: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  scroll: PropTypes.instanceOf(Object)
};

export default SpecificationTemplate;
