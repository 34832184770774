import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import { Input, Tooltip } from 'antd';
import isEqual from 'react-fast-compare';
import useCRUD from '../../_Hooks/useCRUD';
import { parseSearchableOptions, removeAccents, stripNonDigits } from '../../lib/helpers/helper';
import Button from '../Button/Button';
import { SelectStyled } from './Select.style';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';
import { BadgeText } from '../Text/Text';
import { colors, Div, fonts, spaces } from '../../styles/style';
import Modal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';

const SelectWithBadge = ({ badgeLabel = 'Novo', createOption, value, badgeTooltip, ...props }) => {
  const selectedNew = value === -1;
  const firstLoad = value === undefined;

  return (
    <div style={{ position: 'relative' }}>
      {!!createOption && (firstLoad || selectedNew) ? (
        <Tooltip title={badgeTooltip || ''} placement="bottomRight">
          <BadgeText
            count={badgeLabel}
            position="absolute"
            top="6px"
            right={spaces.space3}
            $zIndex={1}
            background={colors.primary50}
            $textColor={colors.primary600}
            weight={fonts.weight600}
          />
        </Tooltip>
      ) : null}
      <Select
        {...props}
        value={!!createOption && firstLoad ? -1 : value}
        createOption={createOption ? { ...createOption, id: -1 } : null}
      />
    </div>
  );
};

SelectWithBadge.propTypes = {
  badgeLabel: PropTypes.string,
  createOption: PropTypes.instanceOf(Object),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.number, PropTypes.string]),
  badgeTooltip: PropTypes.string
};

export { SelectWithBadge };

const Select = ({
  id,
  value,
  color,
  dataType,
  model,
  options,
  modelOptions = null,
  customLabel = null,
  customValue = null,
  setupName = 'systemData',
  dark,
  onChange,
  allowCreate,
  children,
  lazyLoad,
  disabled,
  createParams,
  keepDisabled,
  isChangeableOptions,
  aliasOptions,
  multiple,
  openCustomCreate,
  allowSearch = true,
  firstAsDefault,
  saveFullObject,
  extraPropsOnOptions = [],
  sendFullObject = false,
  disabledText = '',
  createOption,
  allowSearchByExtraPropsOnOption = false,
  prevValidateFields = [],
  itemsToRemove = [],
  multipleSelectPropsInitialValue,
  footerOptions = () => null,
  onKeyDown = f => f,
  onBlur = f => f,
  ...props
}) => {
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const { [dataType]: systemData = [] } = useSelector(state => state.setup[setupName]);
  const [_options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedObj, setSelectedObj] = useState('');
  const [_disabled, setDisabled] = useState(disabled);
  const [_value, setValue] = useState(value);
  const [offset, setOffset] = useState(null);
  const [hasFindMultiple, setHasFindMultiple] = useState(Array.isArray(value) ? value.length > 0 : !!value);
  const selectRef = useRef(null);
  const prevModelOptions = useRef(null);
  const changedStatus = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = isMobileFn();

  const _modelOptions = modelOptions || { where: { isActive: true } };
  const { loading, totalItems, list, handleGet, handleCreate } = useCRUD({
    model,
    options: { attributes: ['id', 'name', ...extraPropsOnOptions], ..._modelOptions, limit: 10, offset },
    immediatelyLoadData: false
  });

  const { handleGet: handleGetOne } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const handleCreateOption = () => {
    if (openCustomCreate) return openCustomCreate(searchValue);
    return handleCreate({ values: { ...createParams, name: searchValue }, refresh: false }).then(newItem => {
      onChange(newItem?.id);

      handleGet().then(() => {
        setSearchValue('');
        if (selectRef?.current?.blur) selectRef.current.blur();
      });
    });
  };

  const filterFunction = (input, option) => {
    if (!input) {
      return true;
    }

    if (customLabel) {
      const _customLabel = option?.label?.props?.children
        ? option.label.props.children.toString().toLowerCase()
        : option.label.toString().toLowerCase();
      return !_customLabel || removeAccents(_customLabel).indexOf(removeAccents(input.toLowerCase())) >= 0;
    }

    return option.label && removeAccents(option.label).indexOf(removeAccents(input.toLowerCase())) >= 0;
  };

  const getOptions = () => {
    const opt = _modelOptions;
    const sanitizedSearchValue = removeAccents(searchValue);
    const strippedSearchValue = stripNonDigits(sanitizedSearchValue);

    let searchConditions = {};

    if (sanitizedSearchValue.length > 0) {
      if (allowSearchByExtraPropsOnOption) {
        searchConditions = {
          or: [
            { ulike: { name: `%${sanitizedSearchValue}%` } },
            ...extraPropsOnOptions.reduce((acc, prop) => {
              if (strippedSearchValue.length > 0) {
                acc.push({ [prop]: { like: `%${strippedSearchValue}%` } });
              }
              return acc;
            }, [])
          ]
        };
      } else {
        searchConditions = {
          ulike: {
            [`${model}.name`]: `%${sanitizedSearchValue}%`
          }
        };
      }
    }

    changedStatus.current = false;

    handleGet({
      refetchOptions: {
        attributes: ['id', 'name', ...extraPropsOnOptions],
        ...opt,
        where: {
          ...(allowSearch && searchConditions),
          ...(value &&
            !multiple && { id: opt?.where?.id?.ne ? { notIn: [opt?.where?.id?.ne, value] } : { ne: value } }),
          ...(value && multiple && itemsToRemove?.length && { id: { notIn: itemsToRemove } }),
          ...opt?.where
        },
        limit: 10,
        offset
      }
    });
  };

  const insertItemInOptions = (item, _selectProps) => {
    if (!item) return;

    const parsedOption = parseSearchableOptions([item], customLabel, customValue, extraPropsOnOptions, _selectProps);
    setOptions(prev => {
      const object = parseArrayAsObject(prev);
      return [...(prev || []), ...parsedOption.filter(p => !object[p?.[customValue] || p?.value])];
    });
  };

  useEffect(() => {
    setValue(value);

    const valueArr = Array.isArray(value) && value?.includes(-1);

    if ((value === -1 || valueArr) && !!createOption) {
      insertItemInOptions(createOption);
      return;
    }

    if (model && !options && multiple && !offset && hasFindMultiple) {
      handleGetOne({
        refetchOptions: {
          where: { id: value }
        }
      }).then(items => {
        items?.length && setHasFindMultiple(false);
        items?.forEach(item => {
          insertItemInOptions(item, multipleSelectPropsInitialValue);
        });
      });
    }

    if (model && value && !options && !multiple) {
      handleGetOne({ refetchPathOptions: `/${value}`, refetchOptions: {} }).then(item => {
        insertItemInOptions(item);
      });
    }
  }, [value]);

  useEffect(() => {
    if (model && !options) {
      getOptions();
    }
  }, [offset]);

  const validateFieldsHasChanged = () => {
    if (!prevValidateFields?.length) return false;
    let hasChanged = false;

    prevValidateFields?.forEach(field => {
      if (!isEqual(modelOptions?.where?.[field], prevModelOptions.current?.where?.[field])) {
        hasChanged = true;
      }
    });

    if (hasChanged) {
      prevModelOptions.current = modelOptions;
      changedStatus.current = true;
      setOptions([]);
      setOffset(1);
    }
    return hasChanged;
  };

  useEffect(() => {
    const hasChanged = validateFieldsHasChanged();

    if (!hasChanged && !changedStatus.current && !searchValue) return () => {};
    const timer = setTimeout(() => {
      if (offset === 1 && model && !options) {
        getOptions();
      } else {
        setOffset(1);
      }
    }, 400);
    return () => clearTimeout(timer);
  }, [searchValue, modelOptions]);

  useEffect(() => {
    if (list?.length || isChangeableOptions) {
      const parsedOption = parseSearchableOptions(list, customLabel, customValue, extraPropsOnOptions);
      if (!keepDisabled) setDisabled((!allowCreate && parsedOption.length === 0) || disabled);
      setOptions(prev => {
        if (isChangeableOptions) {
          const foundValue = prev?.find(val => val.value === value);
          const isInParsedOptions = parsedOption?.find(val => val.value === value);
          return [
            ...(offset !== 1 ? prev : []),
            ...(foundValue && !isInParsedOptions ? [foundValue] : []),
            ...parsedOption
          ];
        }
        const object = parseArrayAsObject(prev, isChangeableOptions);
        return [...(prev || []), ...parsedOption.filter(p => !object[p?.[customValue] || p?.value])];
      });
    }
    if (firstAsDefault && list?.length) {
      onChange(list[0]?.[customValue || 'id']);
    }
  }, [list]);

  useEffect(() => {
    if (options?.length) {
      setOptions(parseSearchableOptions(options, customLabel, customValue, extraPropsOnOptions));
    } else if (systemData?.length) {
      const parsedOption = systemData
        .filter(item => !item._type)
        .map(item => ({ value: item.value, label: item.label }));

      setOptions(parsedOption);
    }
  }, [options, lazyLoad]);

  useEffect(() => {
    if (_options?.length && !Array.isArray(_value)) {
      setSelectedObj(_options.find(o => o.value === _value || Number(o.value) === Number(_value)));
    }
  }, [_value, _options]);

  useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);

  const onPopupScroll = e => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && totalItems > _options?.length) {
      setOffset(prev => (prev || 1) + 1);
    }
  };
  const handleChange = (valueSelected, object) => {
    if (saveFullObject) saveFullObject(object);
    let _valueSelected = valueSelected;
    if (Array.isArray(valueSelected)) _valueSelected = _options.filter(item => valueSelected.includes(item.value));
    onChange(_valueSelected !== undefined ? _valueSelected : null, sendFullObject ? object : undefined);
    setIsOpen(false);
  };

  return (
    <Div
      $fullWidth
      onClick={e => {
        e.stopPropagation();
        setIsOpen(true);
      }}
    >
      <Tooltip title={_disabled ? disabledText : ''}>
        <SelectStyled
          ref={selectRef}
          id={id}
          {...(children ? { value: children.value } : selectedObj || { value: undefined })}
          {...(multiple ? { mode: 'multiple', value: _value } : {})}
          {...(children
            ? { value: children.value }
            : {
                ...(aliasOptions && _options.length
                  ? {
                      children: _options?.map(opt => (
                        <Option
                          {...((saveFullObject || sendFullObject) && opt)}
                          label={opt?.label}
                          title={opt?.label}
                          key={opt?.value}
                          value={opt?.value}
                          style={{ height: '100%' }}
                        >
                          {aliasOptions(opt, { searchValue })}
                        </Option>
                      ))
                    }
                  : { options: _options })
              })}
          maxTagCount="responsive"
          loading={loading}
          $dark={dark}
          $itemHeight={aliasOptions ? '100%' : null}
          searchValue={searchValue}
          showSearch
          onSearch={search => setSearchValue(search)}
          notFoundContent={
            allowCreate && model && searchValue ? (
              <Button type="link" onClick={handleCreateOption}>
                {`+ criar "${searchValue}"`}
              </Button>
            ) : null
          }
          onChange={handleChange}
          filterOption={filterFunction}
          disabled={_disabled}
          onPopupScroll={onPopupScroll}
          onFocus={e => {
            e.stopPropagation();
            setIsOpen(true);
          }}
          dropdownRender={menu => {
            const _menu = isMobile ? (
              <Modal
                title="Opções"
                hideFooter
                open={isOpen}
                onClose={e => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              >
                <Input.Search
                  value={searchValue}
                  allowClear
                  type="text"
                  onChange={e => setSearchValue(e.target.value)}
                />
                {menu}
                {footerOptions(searchValue)}
              </Modal>
            ) : (
              <>
                {menu}
                {footerOptions(searchValue)}
              </>
            );
            return <>{_menu}</>;
          }}
          onInputKeyDown={e => onKeyDown(e, searchValue, list)}
          onBlur={e => {
            onBlur(e, searchValue);
          }}
          {...props}
        />
      </Tooltip>
    </Div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.number, PropTypes.string]),
  color: PropTypes.instanceOf(Object),
  dataType: PropTypes.string,
  model: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
  modelOptions: PropTypes.instanceOf(Object),
  customLabel: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  customValue: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  setupName: PropTypes.string,
  dark: PropTypes.bool,
  allowCreate: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  refresh: PropTypes.number,
  lazyLoad: PropTypes.bool,
  refetchOptions: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  createParams: PropTypes.instanceOf(Object),
  keepDisabled: PropTypes.bool,
  isChangeableOptions: PropTypes.bool,
  aliasOptions: PropTypes.func,
  allowSearch: PropTypes.bool,
  firstAsDefault: PropTypes.bool,
  openCustomCreate: PropTypes.func,
  saveFullObject: PropTypes.func,
  extraPropsOnOptions: PropTypes.instanceOf(Array),
  sendFullObject: PropTypes.bool,
  disabledText: PropTypes.string,
  createOption: PropTypes.instanceOf(Object),
  allowSearchByExtraPropsOnOption: PropTypes.bool,
  prevValidateFields: PropTypes.instanceOf(Array),
  itemsToRemove: PropTypes.instanceOf(Array),
  multipleSelectPropsInitialValue: PropTypes.instanceOf(Object),
  footerOptions: PropTypes.instanceOf(Object),
  allowClear: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func
};

export default Select;
