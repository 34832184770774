const getNameWithoutExtension = name => {
  const dotIndex = name.lastIndexOf('.');
  if (dotIndex === -1) return name;
  return name.substring(0, dotIndex);
};

const getExtension = name => {
  const dotIndex = name.lastIndexOf('.');
  if (dotIndex === -1 || dotIndex === name.length - 1) return '';
  return name.substr(dotIndex + 1);
};

export { getNameWithoutExtension, getExtension };
