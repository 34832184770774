import styled, { css } from 'styled-components';
import { Div, breakpoints, colors, radius, spaces } from '../../../styles/style';
import Content from '../../../components/Content/Content';

const Bar = styled.div`
  width: 100%;
  height: 0;
  border: 1px solid ${props => props.$color || colors.neutral75};
  margin: ${props => props.$margin || `${spaces.space2} 0`};

  @media (max-width: ${breakpoints.mobile}) {
    margin: ${spaces.space4} 0;
  }

  ${props =>
    props.$vertical &&
    css`
      width: 0;
      height: auto;
      margin: ${props.$margin || `0 ${spaces.space2}`};
    `}
`;

const MainContainer = styled(Content)`
  .ant-divider {
    margin: ${spaces.space3} 0 0;
  }

  @media print {
    .ant-divider {
      border-color: ${colors.neutral200};
    }
  }

  @page {
    margin: 8mm 0;
  }

  @page :first {
    margin: 0 0 8mm;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.space2};
  color: ${colors.neutral600};
`;

const ReportInfo = styled.div`
  img {
    width: 144px;
    height: 144px;
    border-radius: ${radius.radius1};
  }
`;

const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    gap: ${spaces.space2};
  }

  @media print {
    flex-direction: row;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  padding: ${spaces.space4} 0 ${spaces.space4} ${spaces.space2};

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${spaces.space2};
    padding: ${spaces.space4} 0 0 ${spaces.space2};
  }

  @media print {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${spaces.space4} 0 ${spaces.space4} ${spaces.space2};
  }
`;

const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  gap: ${spaces.space1};
  padding: ${spaces.space1} ${spaces.space2};
  border: 1px solid ${colors.neutral100};
  margin: ${spaces.space4} ${spaces.space2} ${spaces.space4};

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 ${spaces.space2} ${spaces.space3};
  }

  @media print {
    color: ${colors.neutral900};
    margin: 0;
    padding: ${spaces.space5} ${spaces.space4};
    border: 0;
    border-left: 1px solid ${colors.neutral200};
    min-width: 200px;

    .ant-typography {
      color: ${colors.neutral900};
    }

    svg {
      display: none;
    }
  }
`;

const StatusContainer = styled(Div)`
  gap: ${spaces.space2};

  @media (max-width: ${breakpoints.mobile}) {
    gap: ${spaces.space1};
    flex-wrap: wrap;

    strong {
      margin-right: ${spaces.space1};
    }
  }
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  gap: ${spaces.space1};
  padding: ${spaces.space2};
  ${props => props.border && `border: 1px solid ${colors.neutral100};`}

  form {
    width: 100%;
  }

  ul {
    list-style-type: disc;
    margin-left: ${spaces.space3};

    li {
      line-height: ${spaces.space3};
      margin: ${spaces.space1} 0;
    }
  }

  ol {
    list-style-type: decimal;
    margin-left: ${spaces.space3};

    li {
      line-height: ${spaces.space3};
      margin: ${spaces.space1} 0;
    }
  }

  @media print {
    color: ${colors.neutral900};

    .ant-typography {
      color: ${colors.neutral900};
    }

    svg {
      display: none;
    }

    ul {
      li {
        padding: 0 0 ${spaces.space2};
        margin: 0;
      }
    }

    ${props =>
      props.$printSpacing &&
      `
      p, strong, span {
        line-height: ${spaces.space3};
      }
    `}
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${spaces.space1} ${spaces.space2};
  gap: ${spaces.space1};

  button {
    align-self: flex-start;
  }

  svg:hover {
    cursor: pointer;
  }
`;

const NewComment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spaces.space1};
  min-width: 100%;
`;

const Comment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  gap: ${spaces.space1};

  div {
    border: 1px solid ${colors.neutral100};
    padding: 0 ${spaces.space1};
    width: 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${spaces.space2};
  gap: ${spaces.space2};
`;

const HideOnPrint = styled.div`
  display: flex;
  gap: ${spaces.space2};

  @media print {
    display: none;
  }
`;

export {
  Comment,
  Bar,
  MainContainer,
  ReportInfo,
  TopInfo,
  CompanyInfo,
  CustomerInfo,
  StatusContainer,
  SideContainer,
  Header,
  CommentsContainer,
  NewComment,
  ButtonsContainer,
  HideOnPrint
};
