import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Div, colors, spaces } from '../../styles/style';
import Tabs, { TabPane } from '../Tabs/Tabs';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import VirtualizedTable from '../Table/VirtualizedTable';
import { columns } from '../../lib/mapping/TableOrList/financialCategoryColumns';
import FirstEmptyState from '../EmptyState/FirstEmptyState';
import emptyStateMapping from '../../lib/mapping/EmptyState/emptyStateMapping';
import useCRUD from '../../_Hooks/useCRUD';

import { handleFilter as handleFilterHelper } from '../../lib/helpers/filterFunctions';
import SearchInput from '../Filters/SearchInput';
import ActionButtonDropdown from '../Dropdown/ActionButtonDropdown';
import { buildRecursiveInclude, exportToModel, indexList } from '../../lib/helpers/helper';
import ProjectOrOpportunityEventService from '../../lib/gtm/opportunity';
import Button from '../Button/Button';
import FilterTags from '../Tag/FilterTags';
import useViewport from '../../_Hooks/useViewport';
import EditOrCreateCategoryDrawer from '../Drawer/EditOrCreateCategoryDrawer';
import ExcludeCategory from '../Modal/ExcludeCategory';
import SpreadsheetImport from '../DataImport/SpreadsheetImport';

const FinancialCategoryList = ({ isDrawer, billTypeDefault = 'income', actualId, shouldForceRender }) => {
  const gtmEventService = ProjectOrOpportunityEventService();
  const { user } = useSelector(state => state?.authReducer) || {};
  const idCompany = user?.idCompany;

  const defaultOptions = {
    onlyMine: true,
    where: { idParent: null },
    order: [
      ['name', 'asc'],
      ['children', 'name', 'asc'],
      ['children', 'children', 'name', 'asc']
    ],
    include: buildRecursiveInclude({ model: 'financialCategory', onlyActive: false, idCompany })
  };

  const [filter, setFilter] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [list, setList] = useState([]);
  const [id, setId] = useState();
  const [data, setData] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deleteModalCategory, setDeleteModalCategory] = useState(false);
  const [billType, setBillType] = useState(billTypeDefault);
  const [order, setOrder] = useState();
  const [preventLoading, setPreventLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRender, setForceRender] = useState(true);
  const _expandedRowKeys = useRef(null);
  const [levels, setLevels] = useState(null);
  const expandedRows = useRef([]);
  const updateKey = useRef(0);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [forceReload, setForceReload] = useState(false);

  const { dreList } = useSelector(state => state.setup.systemData);

  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { handleGet, handleDelete, loading } = useCRUD({
    model: 'financialCategory',
    options: defaultOptions,
    immediatelyLoadData: false
  });
  const { handleGet: handleGetExport } = useCRUD({
    model: 'financialCategory',
    immediatelyLoadData: false
  });

  const hashChildrenDREList = dreList
    ?.filter(item => item.children && item.children.length > 0)
    ?.reduce((acc, item) => {
      item?.children.forEach(child => {
        acc[child?.id] = child?.name;
      });
      return acc;
    }, {});

  const handleLoad = (generateLoading = true) => {
    const { name: filterName, isActive: filterIsActive, ..._filter } = filter || {};

    handleGet({
      refetchOptions: {
        ...defaultOptions,
        ...(order?.key ? { order: [[order.key, order.order]] } : {}),
        where: {
          ...(_filter || {}),
          ...(filterName && {
            or: [
              { name: filterName },
              {
                '$children.name$': filterName
              },
              {
                '$children->children.name$': filterName
              }
            ]
          }),
          ...(filterIsActive && {
            or: [
              { isActive: filterIsActive },
              {
                '$children.isActive$': filterIsActive
              },
              {
                '$children->children.isActive$': filterIsActive
              }
            ]
          }),
          billType,
          ...defaultOptions.where
        }
      },
      generateLoading
    }).then(resp => {
      setLevels(null);
      setList(indexList({ currentList: resp, childrenColumn: 'children', setLevels }));
      setPreventLoading(false);
    });
  };

  const handleForceRender = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setForceRender(false);
    }, 300);
  };

  useEffect(() => {
    setForceRender(shouldForceRender && forceRender);
    if (!list?.length || !forceRender) return;
    handleForceRender();
  }, [list]);

  useEffect(() => {
    handleLoad();
  }, [appliedFilters, billType, order, forceReload]);

  const handleDrawerClose = refresh => {
    if (refresh) handleLoad(false);
    setOpenDrawer(false);
    setId(null);
  };

  const handleFilter = (selectedFilters = {}) => {
    handleFilterHelper({
      selectedFilters,
      setFilter
    });
  };

  const handleExport = typeExport => {
    const { name: filterName, ..._filter } = filter || {};
    return exportToModel({
      handleGet: handleGetExport,
      refetchOptions: {
        ...defaultOptions,
        ...(order?.key ? { order: [[order.key, order.order]] } : {}),
        where: {
          ...(_filter || {}),
          ...(filterName && {
            or: [
              { name: filterName },
              {
                '$children.name$': filterName
              }
            ]
          }),
          billType,
          ...defaultOptions.where
        }
      },
      model: 'financialCategory',
      exportType: typeExport
    }).then(() => gtmEventService.onExport({ entity: 'financialCategory', fileType: typeExport }));
  };

  const handleNewButtonClick = () => {
    setOpenDrawer(true);
  };

  const handleEdit = _id => {
    setOpenDrawer(true);
    setId(_id);
  };

  const openDeleteCategory = row => {
    setData(row);
    setDeleteModalCategory(true);
  };

  const handleConfirmDelete = newId => {
    handleDelete({ refresh: false, id: data?.id, values: { newId } }).then(() => {
      handleLoad();
      setDeleteModalCategory(false);
    });
  };

  const handleSort = _order => {
    setOrder(_order);
    setPreventLoading(true);
  };

  const handleToggleAllRow = () => {
    _expandedRowKeys.current = !_expandedRowKeys?.current || _expandedRowKeys?.current?.length ? [] : levels;
    setExpandedRowKeys(_expandedRowKeys.current);
    updateKey.current += 1;
  };

  const _columns = columns({
    onEdit: handleEdit,
    onDelete: openDeleteCategory,
    order,
    handleToggleAllRow,
    actualId,
    hashChildrenDREList,
    isDrawer,
    isMobile: _isMobile
  });

  const content = (
    <>
      {!list?.length && !Object.keys(filter).length ? (
        <FirstEmptyState
          mapping={emptyStateMapping.financialCategory({ redirect: handleNewButtonClick })}
          redirect={handleNewButtonClick}
          helpId="howFinancialCategoriesWorks"
          height="calc(100vh - 216px)"
          noMargin
          loading={loading}
        />
      ) : (
        <Div padding={spaces.space2} gap={spaces.space1} direction="column" align="space-between">
          <>
            <Div justify="end" gap={spaces.space2} $fullWidth>
              <SearchInput
                model="financialCategory"
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                onFilter={handleFilter}
                placeholder="Pesquise por nome..."
              />
              {!isDrawer && (
                <ActionButtonDropdown
                  onExportCsvClick={() => handleExport('csv')}
                  onExportXlsxClick={() => handleExport('xlsx')}
                  showPdf={false}
                  showExport
                  showImport
                  onImportClick={() => setIsOpenImport(true)}
                  model="financialCategory"
                  modelProps={{ billType }}
                />
              )}
              <Button id="create-item-button" type="primary" onClick={handleNewButtonClick}>
                <FontAwesomeIcon icon={faPlus} /> Criar novo
              </Button>
            </Div>
            <FilterTags appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} onFilter={handleFilter} />
          </>

          {isLoading ? (
            <Div />
          ) : (
            <VirtualizedTable
              key={updateKey.current}
              list={list}
              columns={_columns}
              expandedRowKeys={expandedRowKeys}
              expandedRows={expandedRows}
              refreshColumns
              checkStrictly={false}
              childrenColumnName="children"
              defaultExpandAllRows
              rowSelection={false}
              onClick={row => {
                if (row?.children?.length > 0) return null;
                return handleEdit(row?.id);
              }}
              scroll={{ y: 'calc(100vh - 280px)', x: '100%' }}
              onSort={handleSort}
              isLoading={loading && !preventLoading}
            />
          )}
        </Div>
      )}
    </>
  );

  return (
    <>
      <Tabs
        background={colors.neutral50}
        activeKey={billType}
        tabBarExtraContent={<HowDoesItWorksButton id="howFinancialCategoriesWorks" margin="0 0 0 auto" />}
        onChange={key => {
          setBillType(key);
          setFilter({});
          setAppliedFilters({});
        }}
      >
        <TabPane key="income" tab="Receitas" forceRender>
          {content}
        </TabPane>

        <TabPane key="expense" tab="Despesas" forceRender>
          {content}
        </TabPane>
      </Tabs>
      {openDrawer && (
        <EditOrCreateCategoryDrawer
          id={id}
          onClose={handleDrawerClose}
          defaultValues={{ billType }}
          actualId={actualId}
        />
      )}
      {deleteModalCategory && (
        <ExcludeCategory
          onClose={() => setDeleteModalCategory(false)}
          onSubmit={handleConfirmDelete}
          data={data}
          loading={loading}
        />
      )}
      <SpreadsheetImport
        model="financialCategory"
        isOpen={isOpenImport}
        onClose={() => {
          setIsOpenImport(false);
        }}
        onLoad={() => {
          setForceReload(prev => !prev);
        }}
        configParams={{ billType }}
        body={{ billType }}
        canNotify={false}
        toKebabCase={false}
      />
    </>
  );
};

FinancialCategoryList.propTypes = {
  isDrawer: PropTypes.bool,
  billTypeDefault: PropTypes.string,
  actualId: PropTypes.number,
  shouldForceRender: PropTypes.bool
};

export default FinancialCategoryList;
