import styled from 'styled-components';
import { colors, spaces } from '../../styles/style';

export const Description = styled.p`
  color: ${colors.neutral500};
`;

export const ListContainer = styled.ul`
  margin-left: ${spaces.space1};
  list-style: outside;
`;
