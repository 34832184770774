import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useCRUD from './useCRUD';

import { loginAnonymous } from '../store/auth/actions/action';
import { saveRefurbish } from '../store/refurbish/actions/action';

const useAnonymousPermissions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { user = null } = useSelector(state => state.authReducer) || {};

  const { refurbishToken } = useSelector(state => state.authReducer) || {};
  const { userType: userTypeEnum } = useSelector(state => state.setup.enums) || {};

  const { handleGet } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const updatePermissions = queryToken => {
    if ((!queryToken && !refurbishToken) || (user && !user?.anonymous)) return;

    const _queryToken = queryToken || refurbishToken;

    handleGet({ refetchPathOptions: `/token/${_queryToken}/anonymous`, displayToast: false }).then(resp => {
      if (resp?.error || !resp) {
        dispatch(saveRefurbish(null));
        dispatch(loginAnonymous(null));
        history.push(`/login/cadastrar?redirect=/convite/${_queryToken ? `${_queryToken}` : location.search}`);
      } else {
        const anonymousUser = {
          id: -1,
          name: 'Visitante',
          userType: userTypeEnum.customer.value,
          type: 'cliente',
          anonymous: true
        };
        const users = [...(resp.users || []), anonymousUser];
        dispatch(saveRefurbish({ ...resp, users }));
        dispatch(
          loginAnonymous({
            user: anonymousUser,
            refurbishToken: _queryToken,
            createdAt: new Date()
          })
        );
        history.push('/customer/dashboard');
      }
    });
  };

  return { updatePermissions };
};

export default useAnonymousPermissions;
