import * as Yup from 'yup';

import { name, email, phone, text, date, isActive, validateDocumentNotRequired } from '../schema';
import { observation } from '../mapping';

const companyCustomerSchema = Yup.object().shape({
  name,
  email,
  phone,
  street: text,
  number: text,
  personType: text,
  complement: text,
  state: text,
  city: text,
  zipcode: text,
  neighborhood: text,
  doc: validateDocumentNotRequired,
  stateDoc: text,
  birthDate: date,
  isActive,
  observation: text
});

const items = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' }
];

const companyCustomerMapping = ({ personType } = {}) => ({
  name: {
    name: personType === 'pj' ? 'Nome Fantasia*' : 'Nome Completo*',
    type: 'text',
    placeholder: personType === 'pj' ? 'Nome Fantasia' : 'Nome Completo',
    xs: 24,
    md: 12
  },
  birthDate: {
    name: 'Data de nascimento',
    type: 'date',
    placeholder: 'DD/MM/AAAA',
    format: 'DD/MM/YYYY',
    xs: 24,
    md: 12
  },
  personType: {
    name: 'Tipo',
    type: 'radioSwitch',
    defaultValue: 'pf',
    items,
    xs: 24,
    md: 12
  },
  doc: {
    name: personType === 'pj' ? 'CNPJ' : 'CPF',
    type: personType === 'pj' ? 'cnpj' : 'cpf',
    placeholder: personType === 'pj' ? 'Ex: 00.000.000/0000-01' : 'Ex: 000.000.000-00',
    xs: 24,
    md: 12
  },
  stateDoc: {
    type: 'text',
    name: personType === 'pj' ? 'Inscrição Estadual' : 'RG',
    placeholder: personType === 'pj' ? 'Ex: 000.000.000.000' : 'Ex: 00.000.000-0',
    xs: 24,
    md: 12
  },
  email: {
    name: 'E-mail',
    type: 'text',
    placeholder: 'nome@email.com.br',
    xs: 24,
    md: 12
  },
  phone: {
    name: 'Telefone',
    type: 'phone',
    placeholder: '(00) 0000-0000',
    xs: 24,
    md: 12
  },
  zipcode: {
    name: 'CEP',
    type: 'zipCode',
    placeholder: '00000-000',
    xs: 24,
    md: 12
  },
  street: {
    name: 'Endereço',
    type: 'text',
    placeholder: 'Ex: Rua das Laranjeiras',
    xs: 24,
    md: 8
  },
  number: {
    name: 'Número',
    type: 'text',
    placeholder: '22',
    xs: 24,
    md: 4
  },
  complement: {
    name: 'Complemento',
    type: 'text',
    placeholder: 'Apto 55',
    xs: 24,
    md: 12
  },
  neighborhood: {
    name: 'Bairro',
    type: 'text',
    placeholder: 'Jd América',
    xs: 24,
    md: 12
  },
  city: {
    name: 'Cidade',
    type: 'text',
    placeholder: 'São Paulo',
    xs: 24,
    md: 8
  },
  state: {
    name: 'Estado',
    type: 'select',
    dataType: 'states',
    placeholder: 'Estado',
    xs: 24,
    md: 4
  },
  observation,
  isActive: {
    type: 'checkbox',
    name: 'Ativo?',
    xs: 24,
    md: 12,
    defaultValue: true
  },
  ...(personType === 'pj' && {
    businessName: {
      name: 'Razão Social*',
      type: 'text',
      placeholder: 'Razão Social',
      xs: 24,
      md: 12
    }
  })
});

export { companyCustomerSchema, companyCustomerMapping };
