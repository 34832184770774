import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Dropdown from '../Dropdown/DropdownV2';

import Avatar from '../AvatarEditor/Avatar';
import UserMenu from './UserMenu';
import UserMenuOptions from './UserMenuOptions';
import { getInitials } from '../../lib/helpers/helper';
import { colors, breakpoints } from '../../styles/style';

const AvatarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  cursor: pointer;
  border: none;
  border-top: ${props => (props.$noBorder ? 'none' : `solid 1px ${colors.neutral100}`)};
  background-color: transparent;
  padding: 0;
  @media (max-width: ${breakpoints.tablet}) {
    border: none;
    height: auto;
  }
`;

const UserProfile = ({ above, before, onClick = f => f, below, size = 'large' }) => {
  const { user } = useSelector(state => state.authReducer);

  const { ChangePasswordDrawer, RefurbishListDrawer, UpgradeOption, options, params, isModalOpen } = UserMenuOptions();

  return (
    <div role="presentation" onClick={onClick} style={{ padding: '0 8px' }}>
      <ChangePasswordDrawer />
      <RefurbishListDrawer />
      <Dropdown
        trigger={['click']}
        {...(isModalOpen && { open: false })}
        menu={
          <UserMenu
            above={above}
            before={before}
            below={below}
            menuMapping={options}
            user={params.user}
            UpgradeOption={UpgradeOption}
          />
        }
      >
        <AvatarButton id="avatar-button" $noBorder={below}>
          <Avatar size={size} src={user.avatarFullpath}>
            {getInitials(user.name)}
          </Avatar>
        </AvatarButton>
      </Dropdown>
    </div>
  );
};

UserProfile.propTypes = {
  above: PropTypes.bool,
  below: PropTypes.bool,
  before: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string
};

export default UserProfile;
