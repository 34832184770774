import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pagination as AntdPagination } from 'antd';
import { colors, spaces } from '../../styles/style';

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spaces.space2};

  .ant-pagination-item-active {
    background-color: ${colors.primary600};
    &:hover,
    a {
      color: ${colors.white};
    }
  }

  a,
  button {
    color: ${colors.primary600};
  }
`;

const Pagination = ({ activePage = 1, itemsCountPerPage = 20, totalItemsCount, ...props }) => {
  return (
    <StyledPagination>
      <AntdPagination
        total={totalItemsCount}
        pageSize={itemsCountPerPage}
        current={activePage}
        defaultPageSize={20}
        showSizeChanger={false}
        {...props}
      />
    </StyledPagination>
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number,
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number
};

export default Pagination;
