import styled from 'styled-components';
import Button from '../Button/Button';
import { breakpoints, colors, spaces, fonts, radius } from '../../styles/style';

const NoteDisplay = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  list-style: none;
  background-color: ${colors.white};
  border-top-left-radius: ${spaces.space2};
  border-bottom-left-radius: ${spaces.space2};
  min-width: ${props => props.width};
  position: relative;
  height: 100%;
  border-right: solid 1px ${colors.neutral100};
  overflow: auto;
  flex: 1;

  ul {
    height: auto;
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.tablet}) {
    max-height: 100%;
    ul {
      overflow: auto;
    }
  }

  .notes-list__item {
    border-left: solid 3px transparent;
    overflow: hidden;
    font-weight: 300;
    position: relative;

    & + .notes-list-item {
      border-top: solid 1px ${colors.neutral100};
    }

    .delete {
      position: absolute;
      top: ${spaces.space1};
      right: ${spaces.space1};
      cursor: pointer;
    }

    &--active {
      background-color: ${`${colors.primary600}10`};
      border-left: solid 3px ${`${colors.primary600}50`};
    }

    &:hover {
      border-left: solid 3px ${`${colors.primary600}50`};
    }

    time {
      display: block;
      margin-top: ${spaces.space3};
      font-size: ${fonts.sizeXs};
    }

    button {
      padding: ${spaces.space2};
      ${spaces.space3};
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: transparent;
      border: none;
      text-align: left;
      color: ${colors.neutral600};

      div p {
        font-weight: 300;
        font-family: ${fonts.family};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
const SharedIcon = styled.div`
  background-color: ${`${colors.primary600}`};
  border-radius: 9px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  right: ${spaces.space1};
  top: ${spaces.space1};
  justify-content: center;
  align-items: center;
  z-index: 2;
  svg {
    font-size: ${spaces.space1};
  }
`;

const EllipsisButton = styled.div`
  width: 32px;
  height: 32px;
  border: solid 1px ${colors.primary600};
  border-radius: ${radius.radius1};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  svg {
    color: ${colors.primary600};
  }
`;

const DropdownButton = styled(Button)`
  &:hover {
    color: ${`${colors.primary600}`};
  }
`;

export { NoteDisplay, Container, SharedIcon, EllipsisButton, DropdownButton };
