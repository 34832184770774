import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPen, faXmark } from '@fortawesome/pro-regular-svg-icons';
import * as PropTypes from 'prop-types';
import { Div, colors, spaces } from '../../styles/style';
import { BadgeText, Paragraph } from '../Text/Text';
import Card from './Card';
import { Description, InfoDescription } from './ProductConciliationCard.styles';
import Button from '../Button/Button';
import formatCurrency from '../../lib/helpers/formatCurrency';

const ProductConciliationCard = ({
  onCreateItem,
  onSearchItem,
  price,
  isSuggestion,
  isLinked,
  data,
  showActionButton,
  onEditItem,
  onRemoveItem
}) => {
  return (
    <Card
      header={
        <Div justify="space-between" height={spaces.space2}>
          {(onCreateItem || onSearchItem) && (
            <Div gap={spaces.space2} height={spaces.space2}>
              {onCreateItem && (
                <Button text type="light" onClick={onCreateItem} padding="0">
                  Criar item
                </Button>
              )}
              {onSearchItem && (
                <Button text type="light" onClick={onSearchItem} padding="0">
                  Buscar
                </Button>
              )}
            </Div>
          )}

          {price && (
            <Paragraph>
              {formatCurrency(price, {
                currencySymbol: 'R$'
              })}
            </Paragraph>
          )}
          {isLinked && (
            <BadgeText background={colors.green100} $textColor={colors.green700} height="18px" count="VINCULADO" />
          )}

          {isSuggestion && (
            <BadgeText
              background={colors.primary100}
              $textColor={colors.primary500}
              height="18px"
              count="SUGESTÃO VOBI"
            />
          )}
        </Div>
      }
      bodyBackgroundColor={colors.neutral50}
      headerBackgroundColor={colors.neutral75}
      headerPadding={spaces.space2}
      bodyPadding="0px"
      noBorder
    >
      {!data ? (
        <Div height="158px" align="center" justify="center" $fullWidth>
          <Div gap={spaces.space1}>
            <FontAwesomeIcon icon={faCircleInfo} color={colors.primary500} />
            <Paragraph type="small" color={colors.neutral600}>
              Ao avançar esse item será criado na sua biblioteca.
            </Paragraph>
          </Div>
        </Div>
      ) : (
        <Div
          height="158px"
          direction="column"
          align="flex-start"
          $fullWidth
          padding={spaces.space2}
          gap={spaces.space3}
        >
          <Div justify="space-between" align="center" $fullWidth>
            <Description>{data.description}</Description>
            {showActionButton && (
              <Div align="flex-start" $fullHeight>
                <div>
                  <Button text icon={<FontAwesomeIcon icon={faPen} color={colors.primary500} onClick={onEditItem} />} />
                </div>
                <div>
                  <Button
                    text
                    icon={<FontAwesomeIcon icon={faXmark} color={colors.primary500} onClick={onRemoveItem} />}
                  />
                </div>
              </Div>
            )}
          </Div>

          <Div direction="row" gap={spaces.space2} justify="space-between" $fullWidth>
            {data.infos?.map(item => (
              <Div
                direction="column"
                align="flex-start"
                key={`${item.label}${Math.random()}`}
                $minWidth={item.minWidth}
                $maxWidth={item.minWidth}
                width={item.width}
                gap={spaces.space1}
              >
                <Paragraph type="small">{item.label}</Paragraph>
                <InfoDescription $maxWidth={item.minWidth}>{item.value || '-'}</InfoDescription>
              </Div>
            ))}
          </Div>
        </Div>
      )}
    </Card>
  );
};

ProductConciliationCard.propTypes = {
  onCreateItem: PropTypes.func,
  onSearchItem: PropTypes.func,
  price: PropTypes.number,
  isSuggestion: PropTypes.bool,
  isLinked: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  showActionButton: PropTypes.bool,
  onEditItem: PropTypes.func,
  onRemoveItem: PropTypes.func
};

export default ProductConciliationCard;
