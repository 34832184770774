import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider } from 'antd';
import * as dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { faArrowDownToLine, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import useViewport from '../../../_Hooks/useViewport';
import LogoIcon from '../../../components/Icons/Logo';
import { Header } from '../../ViewPublic.style';
import { Div, colors, spaces } from '../../../styles/style';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import { Context } from '../../../contexts/GeneralContext';
import ObservationAndFilesComponent from '../ObservationAndFilesComponent';
import useCRUD from '../../../_Hooks/useCRUD';
import { ComponentToPdf } from '../../../lib/helpers/exportToPdf';
import ViewEntityInfo from '../../../components/Description/ViewEntityInfo';
import { addressConcat, exportToModel, ExportTypes, hasAddress } from '../../../lib/helpers/helper';
import Button from '../../../components/Button/Button';
import AlertBar from '../../../components/Alert/AlertBar';
import Card from '../../../components/Card/Card';
import RichTextViewer from '../../../components/RichTextEditor/RichTextViewer';
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import DatePicker from '../../../components/Datepicker/Datepicker';
import QuoteSupplierFooter from '../../../components/Footer/QuoteSupplierFooter';
import QuoteSupplierItemsTable from '../../../components/Table/QuoteSupplierItemsTable';
import WarningBar from '../../../components/Alert/WarningBar';

const CustomDivider = () => <Divider style={{ margin: `${spaces.space1} 0` }} />;

const supplierMock = {
  name: 'Fornecedor Exemplo',
  phone: '(11) 99999-9999',
  email: 'fornecedor@email.com.br',
  cnpj: '57.448.605/0001-67',
  address: 'Rua exemplo, 1100, SP'
};

const QuoteSupplierViewPublic = ({ isViewAsSupplier }) => {
  const { id } = useParams();
  const items = useRef(null);
  const { quoteSupplierStatus = {} } = useSelector(state => state.setup.enums) || {};
  const executeScroll = () => items.current.scrollIntoView({ behavior: 'smooth' });
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const [values, setValues] = useState();
  const [filesSupplier, setFilesSupplier] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const printComponentRef = useRef();

  const setField = key => value => {
    setValues(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const { handleGet: handleExportItem } = useCRUD({
    model: 'quoteItems',
    immediatelyLoadData: false
  });

  const { data: quoteSupplier, handleGet, handleUpdate: updateQuoteSuppliers, loading } = useCRUD({
    model: isViewAsSupplier ? 'quote' : 'quoteSuppliers',
    pathOptions: `/${id}`,
    options: {
      ...(isViewAsSupplier
        ? { include: ['files', 'company'] }
        : {
            where: { status: { gte: quoteSupplierStatus?.pending?.id } },
            include: [{ model: 'quote', include: ['files', 'company'] }, 'supplier', 'files']
          })
    },
    immediatelyLoadData: !!id
  });

  const { quote, supplier, status, idQuote } = isViewAsSupplier
    ? { quote: quoteSupplier, status: quoteSupplierStatus?.pending?.id, idQuote: id, supplier: supplierMock }
    : quoteSupplier || {};

  const { files, observation, company } = quote || {};
  const readOnly = !quoteSupplierStatus?.withoutResponse?.includes(status) || isPrinting;

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => setIsPrinting(false)
  });

  const handleExport = exportType =>
    exportToModel({
      handleGet: handleExportItem,
      refetchOptions: {
        where: { idQuote },
        include: ['units']
      },
      exportType,
      prefixName: 'quoteItems'
    });

  const handleSave = () => {
    if (isViewAsSupplier) return null;

    return updateQuoteSuppliers({
      values: {
        ...values,
        files: filesSupplier
      },
      updatePathOptions: `/${id}/answer`,
      displayToast: 'Sua proposta foi enviada para o processo de cotação da empresa.',
      refresh: false
    }).then(() => handleGet());
  };

  const handleRefuse = () => {
    if (isViewAsSupplier) return null;

    return updateQuoteSuppliers({
      updatePathOptions: `/${id}/refuse`,
      displayToast: true,
      refresh: false
    }).then(() => handleGet());
  };

  useEffect(() => {
    setValues(isViewAsSupplier ? { ...quoteSupplier, idQuote: id } : quoteSupplier);
    setFilesSupplier(isViewAsSupplier ? [] : quoteSupplier?.files || []);
  }, [quoteSupplier]);

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting]);

  return loading ? (
    <CenteredLoader />
  ) : (
    <>
      <ComponentToPdf style={{ height: '100%', overflow: 'auto' }} ref={printComponentRef}>
        <Header $maxWidth="100%" padding={0}>
          <div>
            <AlertBar
              showAlert={isViewAsSupplier}
              type="primary"
              message="Você está visualizando um exemplo de como os fornecedores recebem o pedido de cotação."
            />
            <AlertBar
              showAlert={status === quoteSupplierStatus?.refused?.id}
              message="Você recusou esse pedido de cotação."
              icon={faCircleXmark}
            />
            <AlertBar
              showAlert={quoteSupplierStatus?.answeredOptions?.includes(status) && !!quoteSupplier?.responseDate}
              type="primary"
              message={`Pedido de cotação respondido em ${dayjs(quoteSupplier?.responseDate).format('DD/MM/YYYY')}`}
              icon={faCircleCheck}
            />
            <Div padding={`${spaces.space2} 0 ${spaces.space2} ${spaces.space5}`}>
              <LogoIcon scale={0.6} />
            </Div>
          </div>
        </Header>
        <Divider style={{ margin: 0 }} />
        <Div
          align="start"
          direction="column"
          gap={spaces.space3}
          padding={_isMobile ? spaces.space2 : `${spaces.space3} ${spaces.space6} ${spaces.space6} ${spaces.space6}`}
        >
          {readOnly ? null : (
            <Div gap={spaces.space4} $fullWidth>
              <Card bodyBackgroundColor={colors.primary50}>
                <Div gap={spaces.space2} align="flex-start">
                  <Div height={spaces.space3} align="center">
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.primary600} />
                  </Div>
                  <Div direction="column" align="flex-start" gap={spaces.space0}>
                    <Subtitle type="secondary">{company?.name} solicitou um pedido de Cotação</Subtitle>
                    <Paragraph type="small">Preencha os campos dos itens e envie a sua proposta de cotação.</Paragraph>
                    <Div $fullWidth padding={`${spaces.space4} 0 0 0`}>
                      <Button type="primary" onClick={executeScroll}>
                        Ver itens solicitados
                      </Button>
                    </Div>
                  </Div>
                </Div>
              </Card>
              {!_isMobile && (
                <Card height="150px">
                  <Div gap={spaces.space2} align="flex-start">
                    <Div height={spaces.space3} align="center">
                      <FontAwesomeIcon icon={faArrowDownToLine} color={colors.primary600} />
                    </Div>
                    <Div direction="column" align="flex-start" gap={spaces.space0}>
                      <Subtitle type="secondary">Download do pedido de Cotação</Subtitle>
                      <Paragraph type="small">
                        Caso preferir, você pode fazer download do CSV, Excel ou PDF das informações para enviar sua
                        proposta de cotação.
                      </Paragraph>
                      <Div $fullWidth padding={`${spaces.space2} 0 0 0`} gap={spaces.space1}>
                        <Button ghost onClick={() => handleExport(ExportTypes.csv)}>
                          Exportar planilha (CSV)
                        </Button>
                        <Button ghost onClick={() => handleExport(ExportTypes.xlsx)}>
                          Exportar Excel
                        </Button>
                        <Button ghost onClick={() => setIsPrinting(true)}>
                          Exportar PDF
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                </Card>
              )}
            </Div>
          )}
          <Div align="start" direction="column" gap={spaces.space4} $avoidPageBreak $fullWidth>
            <h5>Informações da cotação</h5>
            <Div align="start" direction={_isMobile ? 'column' : 'row'} gap={spaces.space4} $fullHeight $fullWidth>
              {company && (
                <Div $fullWidth gap={spaces.space2}>
                  {company?.logo && (
                    <img
                      style={{ width: '100px', height: '100px' }}
                      crossOrigin="anonymous"
                      alt={company?.name}
                      src={`${company?.logo}&t=${new Date().getTime()}`}
                    />
                  )}
                  <ViewEntityInfo data={company} />
                </Div>
              )}
              {_isMobile && <Divider />}
              {supplier && (
                <Div $fullWidth>
                  <ViewEntityInfo data={supplier} />
                </Div>
              )}
            </Div>
          </Div>

          <CustomDivider />

          <Div direction={_isMobile ? 'column' : 'row'} $avoidPageBreak gap={spaces.space4} $fullWidth>
            <Div align="start" direction="column" gap={spaces.space1} $fullWidth>
              <Subtitle type="secondary">Data do pedido</Subtitle>
              <Paragraph type="small">
                {quoteSupplier?.sendDate ? dayjs(quoteSupplier?.sendDate).format('DD/MM/YYYY') : 'Não definido'}
              </Paragraph>
            </Div>
            <Div align="start" direction="column" gap={spaces.space1} $fullWidth>
              <Subtitle type="secondary">Prazo para resposta</Subtitle>
              <Paragraph type="small">
                {quote?.responseDate ? dayjs(quote?.responseDate).format('DD/MM/YYYY') : 'Não definido'}
              </Paragraph>
            </Div>
          </Div>

          <CustomDivider />

          <Div align="start" direction="column" gap={spaces.space1} $fullWidth $avoidPageBreak>
            <Subtitle type="secondary">Endereço de entrega</Subtitle>
            <Paragraph type="small">{hasAddress(quote) ? addressConcat(quote) : 'Não informado.'}</Paragraph>
          </Div>

          <CustomDivider />

          <Context
            data={{
              files,
              observation,
              readOnly: true,
              domainLabel: 'pedido'
            }}
          >
            <ObservationAndFilesComponent padding={0} />
          </Context>

          <CustomDivider />

          <Div
            align="start"
            direction="column"
            gap={spaces.space1}
            {...(isPrinting && { padding: `${spaces.space2} 0 0 0` })}
            $avoidPageBreak
            $fullWidth
            ref={items}
          >
            <h5>Cotação</h5>
            <WarningBar
              type="light"
              warning="Preencha os campos abaixo com os dados da cotação e envie a proposta."
              width="100%"
              padding={isMobile() ? spaces.space1 : undefined}
            />
            <Div padding={`${spaces.space2} 0 ${spaces.space1} 0`}>
              <Subtitle type="secondary">Itens{!_isMobile && ' solicitados'}</Subtitle>
            </Div>
            {values?.id && (
              <QuoteSupplierItemsTable
                idQuote={idQuote}
                quoteSupplier={values}
                setField={setField}
                isPrinting={isPrinting}
                readOnly={readOnly}
              />
            )}
          </Div>

          <CustomDivider />

          <Div align="start" direction="column" gap={spaces.space1} $fullWidth $avoidPageBreak>
            <Subtitle type="secondary">Condições de pagamento</Subtitle>
            {readOnly ? (
              <RichTextViewer value={values?.paymentInfo} emptyStateText="Nenhuma condição de pagamento informada" />
            ) : (
              <RichTextEditor
                id="paymentInfo"
                toolbar={
                  ' bold italic underline strikethrough | forecolor backcolor |' +
                  ' alignleft aligncenter alignright alignjustify | numlist bullist | link table |' +
                  '  h1 h2 h3 h4 h5 h6 | emoticons'
                }
                value={values?.paymentInfo}
                onChange={setField('paymentInfo')}
                height="240px"
              />
            )}
          </Div>

          <CustomDivider />

          <Div align="start" direction="column" gap={spaces.space1} $fullWidth $avoidPageBreak>
            <Subtitle type="secondary">Entrega e prazo de validade</Subtitle>
            <Div direction={_isMobile ? 'column' : 'row'} align="start" gap={spaces.space2}>
              <Div align="normal" direction="column" gap={spaces.space1} width="284px">
                <Paragraph type="small">Data prevista da entrega</Paragraph>
                {readOnly ? (
                  <Paragraph type="small">
                    {quoteSupplier?.expectedDate
                      ? dayjs(quoteSupplier?.expectedDate).format('DD/MM/YYYY')
                      : 'Não definido'}
                  </Paragraph>
                ) : (
                  <DatePicker
                    id="expectedDate"
                    allowClear
                    name="expectedDate"
                    placeholder="Selecione a data"
                    format="DD/MM/YYYY"
                    value={values?.expectedDate ? dayjs(values?.expectedDate) : null}
                    onChange={date => setField('expectedDate')(date?.format('YYYY-MM-DD'))}
                  />
                )}
              </Div>
              <Div align="normal" direction="column" gap={spaces.space1} width="284px">
                <Paragraph type="small">Prazo de validade da cotação</Paragraph>
                {readOnly ? (
                  <Paragraph type="small">
                    {quoteSupplier?.dueDate ? dayjs(quoteSupplier?.dueDate).format('DD/MM/YYYY') : 'Não definido'}
                  </Paragraph>
                ) : (
                  <DatePicker
                    id="dueDate"
                    allowClear
                    name="dueDate"
                    placeholder="Selecione a data"
                    format="DD/MM/YYYY"
                    value={values?.dueDate ? dayjs(values?.dueDate) : null}
                    onChange={date => setField('dueDate')(date?.format('YYYY-MM-DD'))}
                  />
                )}
              </Div>
            </Div>
          </Div>

          <CustomDivider />

          <Context
            data={{
              files: filesSupplier,
              setFiles: setFilesSupplier,
              observation: values?.observation,
              setObservation: setField('observation'),
              readOnly,
              domainLabel: 'fornecedor'
            }}
          >
            <ObservationAndFilesComponent padding={0} $fullWidth />
          </Context>
        </Div>

        {quoteSupplierStatus?.withoutResponse?.includes(status) && !isPrinting ? (
          <QuoteSupplierFooter
            handleSave={handleSave}
            handleRefuse={handleRefuse}
            isViewAsSupplier={isViewAsSupplier}
          />
        ) : null}
      </ComponentToPdf>
    </>
  );
};

QuoteSupplierViewPublic.propTypes = {
  isViewAsSupplier: PropTypes.bool
};

export default QuoteSupplierViewPublic;
