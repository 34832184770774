import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip, Checkbox } from 'antd';
import styled from 'styled-components';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Description, Paragraph } from '../Text/Text';
import { colors, spaces } from '../../styles/style';
import { formatSinceTime, getFileIcon } from '../../lib/helpers/helper';
import css from './FileCard.module.scss';
import Thumbnail from './Thumbnail';
import CenteredLoader from '../Loader/CenteredLoader';

const DeleteIconContainer = styled.div`
  position: absolute;
  bottom: ${spaces.space0};
  right: ${spaces.space0};
  width: 100%;
  height: ${spaces.space4};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileCard = ({
  data,
  onSelect,
  isSelected,
  onlyIcon,
  width = '60%',
  height = '100%',
  tooltip = false,
  viewFileFunction,
  onToggleCheckbox,
  checked,
  showCheckbox = true,
  onDelete,
  id
}) => {
  const classes = classNames({
    [css['c-file-card']]: true,
    [css['c-file-card--selected']]: isSelected
  });

  const timeToShow = formatSinceTime(data.createdAt);
  return onlyIcon ? (
    <div className={css['c-file-card__icon']}>
      {getFileIcon({
        filename: data?.filename,
        width,
        height,
        imageElement: <Thumbnail crossOrigin="anonymous" alt={data?.name} file={data} style={{ width, height }} />
      })}
    </div>
  ) : (
    <div role="presentation" className={classes} onClick={onSelect} id={id}>
      {data.loading ? (
        <div className={css.centered}>
          <CenteredLoader />
        </div>
      ) : (
        <>
          {showCheckbox && (
            <Checkbox
              checked={checked}
              onClick={e => {
                e.stopPropagation();
              }}
              style={{ position: 'absolute', top: 4, left: 4 }}
              onChange={e => {
                onToggleCheckbox({ isChecked: e.target.checked, file: data });
              }}
            />
          )}
          {onDelete && (
            <DeleteIconContainer role="presentation" onClick={onDelete}>
              <FontAwesomeIcon icon={faTrashCan} color={colors.red500} />
            </DeleteIconContainer>
          )}
          <div className={css['c-file-card__icon']}>
            {getFileIcon({
              filename: data?.filename,
              width,
              height,
              imageElement: <Thumbnail crossOrigin="anonymous" alt={data?.name} file={data} />
            })}
            <button
              onClick={e => {
                e.stopPropagation();
                viewFileFunction();
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
          <div className={css['c-file-card__text']}>
            {tooltip ? (
              <Tooltip placement="bottom" title={data.filename}>
                <Paragraph $ellipsisClamp={1} type="small">
                  {data.filename}
                </Paragraph>
              </Tooltip>
            ) : (
              <Paragraph $ellipsisClamp={1} type="small">
                {data.filename}
              </Paragraph>
            )}
            <Description>{timeToShow}</Description>
          </div>
        </>
      )}
    </div>
  );
};

FileCard.propTypes = {
  tooltip: PropTypes.bool,
  onSelect: PropTypes.func,
  data: PropTypes.instanceOf(Object).isRequired,
  isSelected: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewFileFunction: PropTypes.func,
  onToggleCheckbox: PropTypes.func,
  checked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  onDelete: PropTypes.func,
  id: PropTypes.string
};

export default FileCard;
