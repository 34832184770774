import styled from 'styled-components';
import { Avatar as AntAvatar } from 'antd';
import { colors, spaces, fonts } from '../../styles/style';

const StyledAvatar = styled(AntAvatar)`
  display: block !important;
  background-color: ${props => (props.dashed ? 'transparent' : colors.primary600)};
  ${props => props.$offset && `margin: 0 -${spaces.space1} 0 0 !important;`}
  ${props =>
    props.dashed &&
    `
    border: 1px dashed ${colors.neutral400};
  `}

  .ant-avatar-string {
    opacity: 1 !important;
  }

  span {
    font-weight: ${fonts.weight500};
    text-transform: uppercase;
  }
`;

export default StyledAvatar;
