import * as React from 'react';

function NoTemplates(props) {
  return (
    <svg width={234} height={177} {...props}>
    <title>EDB677BB-76DE-4877-BCD8-40C3018372CB</title>
    <g id="Templates-de-Orçamento" stroke="none" fill="none">
        <g id="1.1-Modal-templates---Empty-Sttate" transform="translate(-343.000000, -348.000000)">
            <g id="undraw_building_websites_i78t" transform="translate(343.000000, 348.000000)">
                <path d="M135.48834,8.50338483 C137.506612,12.7281728 133.22989,13.9248469 128.330858,16.1892788 C123.431827,18.4537108 119.765642,20.9284081 117.747374,16.7036228 C115.729105,12.4788375 114.091524,4.09340273 121.022054,0.889967847 C128.191351,-2.42382283 133.470071,4.27859688 135.48834,8.50338483 Z" id="Path" fill="#2F2E41"></path>
                <circle id="Oval" fill="#FFB8B8" cx="124.5" cy="11.5" r="6.5"></circle>
                <path d="M159.119418,39.1343791 C158.884122,38.8196938 158.648824,38.5106785 158.410822,38.2101682 C158.103625,37.8189378 157.794536,37.4399924 157.483554,37.0733321 C153.960032,32.9087129 150.212312,30.2863353 146.085476,30.00567 L146.07994,30.00567 L146.018988,30 L137.795592,38.2101682 L135,40.999811 L135.060952,41.1529012 L136.569371,45 L163,45 C161.809042,42.9756268 160.513681,41.0176477 159.119418,39.1343791 L159.119418,39.1343791 Z" id="Path" fill="#2F2E41"></path>
                <path d="M116.686615,13 L116.686615,13 L119.125998,11.9457973 L118.786182,8.70944368 L120.417362,11.3877239 L121.583291,10.883858 L121.385077,8.99598225 L122.336628,10.5582969 L131,6.81435443 L131,6.81435443 C129.305598,3.49821054 125.008931,2.07317859 121.403114,3.63145297 L120.14754,4.1740582 C116.541726,5.73234471 114.992218,9.68385074 116.686615,13 L116.686615,13 Z" id="Path" fill="#2F2E41"></path>
                <path d="M172.586607,132 L35.4133933,132 C32.9770844,131.99725 31.0027521,130.02448 31,127.590098 L31,45.2308224 C31.001984,43.4473614 32.4483763,42.0020547 34.2332493,42 L173.710786,42 C175.526538,42.0020621 176.997972,43.47236 177,45.2866758 L177,127.590098 C176.997246,130.024479 175.022915,131.997249 172.586607,132 L172.586607,132 Z" id="Path" fill="#F2F2F2"></path>
                <path d="M40.2430512,128 L167.756949,128 C170.100321,128 172,126.072164 172,123.694058 L172,53.3059419 C172,50.9278359 170.100321,49 167.756949,49 L40.2430512,49 C37.8996787,49 36,50.9278359 36,53.3059419 L36,123.694058 C36,126.072164 37.8996787,128 40.2430512,128 Z" id="Path" fill="#FFFFFF"></path>
                <path d="M177,45 L31,45 L31,42.6431951 C31.0035329,40.0792421 32.9850368,38.0021167 35.4294432,38 L172.570557,38 C175.014968,38.0021044 176.996479,40.079237 177,42.6431951 L177,45 Z" id="Path" fill="#00B0FF"></path>
                <circle id="Oval" fill="#FFFFFF" cx="39.5" cy="42.5" r="1.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="42.5" cy="42.5" r="1.5"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="47.5" cy="42.5" r="1.5"></circle>
                <path d="M108.241935,115 L95.7580645,115 C94.2355445,114.99827 93.0017295,113.764455 93,112.241935 L93,99.7580645 C93.0017295,98.2355445 94.2355445,97.0017295 95.7580645,97 L108.241935,97 C109.764455,97.0017295 110.99827,98.2355445 111,99.7580645 L111,112.241935 C110.99827,113.764455 109.764455,114.99827 108.241935,115 L108.241935,115 Z M95.7580645,97.5806452 C94.5560628,97.5819812 93.5819812,98.5560628 93.5806452,99.7580645 L93.5806452,112.241935 C93.5819828,113.443937 94.5560635,114.418017 95.7580645,114.419355 L108.241935,114.419355 C109.443937,114.418017 110.418017,113.443937 110.419355,112.241935 L110.419355,99.7580645 C110.418019,98.5560628 109.443937,97.5819812 108.241935,97.5806452 L95.7580645,97.5806452 Z" id="Shape" fill="#CCCCCC"></path>
                <path d="M103.249989,110 L96.7499988,110 C96.3359733,109.999524 96.0004519,109.646343 96,109.210526 L96,100.789474 C96.0004519,100.353657 96.3359733,100.000476 96.7499988,100 L101.459829,100 C101.63069,100.000151 101.796407,100.06156 101.929678,100.174111 L103.719715,101.688295 C103.897343,101.83774 104.00053,102.064296 103.99999,102.303658 L103.99999,109.210526 C103.999536,109.646343 103.664014,109.999524 103.249989,110 L103.249989,110 Z M96.7499988,100.526316 C96.6120021,100.526504 96.5001783,100.644214 96.4999992,100.789474 L96.4999992,109.210526 C96.5001783,109.355786 96.6120021,109.473496 96.7499988,109.473684 L103.249989,109.473684 C103.387985,109.473496 103.499809,109.355786 103.499989,109.210526 L103.499989,102.303658 C103.500174,102.223891 103.465793,102.148389 103.406603,102.098582 L101.616324,100.584266 C101.571924,100.5468 101.516729,100.526316 101.459824,100.526316 L96.7499988,100.526316 Z" id="Shape" fill="#CCCCCC"></path>
                <path d="M103.722222,105 L99.2777778,105 C99.1243653,105 99,104.552285 99,104 C99,103.447715 99.1243653,103 99.2777778,103 L103.722222,103 C103.875635,103 104,103.447715 104,104 C104,104.552285 103.875635,105 103.722222,105 Z" id="Path" fill="#CCCCCC"></path>
                <path d="M103.722222,107 L99.2777778,107 C99.1243653,107 99,106.552285 99,106 C99,105.447715 99.1243653,105 99.2777778,105 L103.722222,105 C103.875635,105 104,105.447715 104,106 C104,106.552285 103.875635,107 103.722222,107 Z" id="Path" fill="#CCCCCC"></path>
                <path d="M103.722222,107 L99.2777778,107 C99.1243653,107 99,106.552285 99,106 C99,105.447715 99.1243653,105 99.2777778,105 L103.722222,105 C103.875635,105 104,105.447715 104,106 C104,106.552285 103.875635,107 103.722222,107 Z" id="Path" fill="#CCCCCC"></path>
                <path d="M134.241935,115 L121.758065,115 C120.235545,114.99827 119.00173,113.764455 119,112.241935 L119,99.7580645 C119.00173,98.2355445 120.235545,97.0017295 121.758065,97 L134.241935,97 C135.764455,97.0017295 136.99827,98.2355445 137,99.7580645 L137,112.241935 C136.99827,113.764455 135.764455,114.99827 134.241935,115 L134.241935,115 Z M121.758065,97.5806452 C120.556063,97.5819812 119.581981,98.5560628 119.580645,99.7580645 L119.580645,112.241935 C119.581983,113.443937 120.556063,114.418017 121.758065,114.419355 L134.241935,114.419355 C135.443937,114.418017 136.418017,113.443937 136.419355,112.241935 L136.419355,99.7580645 C136.418019,98.5560628 135.443937,97.5819812 134.241935,97.5806452 L121.758065,97.5806452 Z" id="Shape" fill="#CCCCCC"></path>
                <path d="M133.117647,108 L124.882353,108 C124.395263,107.999507 124.000532,107.633483 124,107.181818 L124,102.818182 C124.000532,102.366517 124.395263,102.000493 124.882353,102 L133.117647,102 C133.604737,102.000493 133.999468,102.366517 134,102.818182 L134,107.181818 C133.999468,107.633483 133.604737,107.999507 133.117647,108 Z M124.882353,102.545455 C124.719983,102.545605 124.588397,102.667621 124.588235,102.818182 L124.588235,107.181818 C124.588397,107.332379 124.719983,107.454395 124.882353,107.454545 L133.117647,107.454545 C133.280017,107.454395 133.411603,107.332379 133.411765,107.181818 L133.411765,102.818182 C133.411603,102.667621 133.280017,102.545605 133.117647,102.545455 L124.882353,102.545455 Z" id="Shape" fill="#CCCCCC"></path>
                <path d="M129,106 C128.834737,106.000204 128.672722,105.959778 128.532365,105.883315 L124.137794,103.476262 C124.000375,103.400635 123.958762,103.241778 124.044784,103.121194 C124.130807,103.00061 124.311983,102.963828 124.449741,103.038982 L128.844312,105.446034 C128.939773,105.497895 129.060512,105.497895 129.155973,105.446034 L133.550259,103.038982 C133.688017,102.963828 133.869193,103.00061 133.955216,103.121194 C134.041238,103.241778 133.999625,103.400635 133.862206,103.476262 L129.467923,105.883315 C129.327478,105.959812 129.165359,106.000239 129,106 Z" id="Path" fill="#CCCCCC"></path>
                <path d="M80.2419355,115 L67.7580645,115 C66.2355445,114.99827 65.0017295,113.764455 65,112.241935 L65,99.7580645 C65.0017295,98.2355445 66.2355445,97.0017295 67.7580645,97 L80.2419355,97 C81.7644555,97.0017295 82.9982705,98.2355445 83,99.7580645 L83,112.241935 C82.9982705,113.764455 81.7644555,114.99827 80.2419355,115 L80.2419355,115 Z M67.7580645,97.5806452 C66.5560628,97.5819812 65.5819812,98.5560628 65.5806452,99.7580645 L65.5806452,112.241935 C65.5819828,113.443937 66.5560635,114.418017 67.7580645,114.419355 L80.2419355,114.419355 C81.4439365,114.418017 82.4180172,113.443937 82.4193548,112.241935 L82.4193548,99.7580645 C82.4180188,98.5560628 81.4439372,97.5819812 80.2419355,97.5806452 L67.7580645,97.5806452 Z" id="Shape" fill="#CCCCCC"></path>
                <path d="M79.1176471,108 L70.8823529,108 C70.3952633,107.999507 70.0005317,107.633483 70,107.181818 L70,102.818182 C70.0005317,102.366517 70.3952633,102.000493 70.8823529,102 L79.1176471,102 C79.6047367,102.000493 79.9994683,102.366517 80,102.818182 L80,107.181818 C79.9994683,107.633483 79.6047367,107.999507 79.1176471,108 Z M70.8823529,102.545455 C70.7199835,102.545605 70.5883974,102.667621 70.5882353,102.818182 L70.5882353,107.181818 C70.5883974,107.332379 70.7199835,107.454395 70.8823529,107.454545 L79.1176471,107.454545 C79.2800165,107.454395 79.4116026,107.332379 79.4117647,107.181818 L79.4117647,102.818182 C79.4116026,102.667621 79.2800165,102.545605 79.1176471,102.545455 L70.8823529,102.545455 Z" id="Shape" fill="#CCCCCC"></path>
                <path d="M77.5025036,108 L72.4974685,108 C72.3197352,108.000044 72.1554831,107.921182 72.0666207,107.793136 C71.9777583,107.66509 71.9777954,107.507327 72.0667181,107.379309 L73.5742797,105.206896 C73.6631493,105.078867 73.8273699,105 74.0050886,105 C74.1828072,105 74.3470279,105.078867 74.4358974,105.206896 L75.4095961,106.609913 L75.9736872,105.797076 C76.0625567,105.669047 76.2267774,105.590181 76.404496,105.590181 C76.5822147,105.590181 76.7464353,105.669047 76.8353048,105.797076 L77.9333584,107.379309 C78.0222221,107.507349 78.0222133,107.665102 77.9333355,107.793135 C77.8444576,107.921168 77.6802159,108 77.5024887,108 L77.5025036,108 Z M77.5025036,107.586206 L77.5025036,107.586206 L76.40445,106.003973 L75.6250459,107.127154 C75.5806156,107.191185 75.4984849,107.230622 75.409611,107.230602 L75.409611,107.230602 C75.3207371,107.230622 75.2386064,107.191185 75.1941761,107.127154 L74.0051545,105.413792 L72.4974759,107.586206 L77.5025036,107.586206 Z" id="Shape" fill="#CCCCCC"></path>
                <circle id="Oval" fill="#CCCCCC" cx="78" cy="104" r="1"></circle>
                <polygon id="Path" fill="#FFB8B8" points="204.999447 173 201.611584 172.999746 200 161 205 161.000254"></polygon>
                <path d="M205.999808,177 L196,176.999541 L196,176.842186 C196,175.557941 196.410059,174.326292 197.139997,173.4182 C197.869936,172.510109 198.859947,172 199.892228,172 L199.892481,172 L206,172.000315 L205.999808,177 Z" id="Path" fill="#2F2E41"></path>
                <polygon id="Path" fill="#FFB8B8" points="217.999447 173 214.611584 172.999746 213 161 218 161.000254"></polygon>
                <path d="M218.999808,177 L209,176.999541 L209,176.842186 C209,175.557941 209.410059,174.326292 210.139997,173.4182 C210.869936,172.510109 211.859947,172 212.892228,172 L212.892481,172 L219,172.000315 L218.999808,177 Z" id="Path" fill="#2F2E41"></path>
                <path d="M217.829369,166.948218 L214.988476,166.037017 C214.555549,165.898689 214.243429,165.50024 214.196401,165.025861 L211.547799,138.559534 C211.543514,138.493136 211.491196,138.441577 211.428076,138.441546 C211.364812,138.435157 211.308207,138.482994 211.300349,138.549489 L206.864054,164.190914 C206.80963,164.505209 206.637359,164.783111 206.386176,164.961812 C206.134993,165.140513 205.8261,165.204929 205.529309,165.140502 L201.898548,164.351926 C201.348058,164.23048 200.96608,163.70066 201.002384,163.108909 L203.700891,121.081826 L222,119 L219.280847,165.884763 C219.244285,166.510297 218.75326,166.998367 218.158858,167 C218.047208,167 217.936177,166.98251 217.829369,166.948218 Z" id="Path" fill="#2F2E41"></path>
                <circle id="Oval" fill="#FFB8B8" cx="217.5" cy="77.5" r="6.5"></circle>
                <path d="M203,122.41316 L207.350639,95.5781594 C207.818652,92.6918661 209.531074,90.1420924 212.051552,88.5785605 C214.572031,87.0150285 217.657152,86.5887355 220.520779,87.4083077 L220.520779,87.4083077 C225.649412,88.8613627 228.809575,93.8959884 227.818711,99.0350375 L223.266997,123 L203,122.41316 Z" id="Path" fill="#00B0FF"></path>
                <path d="M220.644697,125.544295 C221.377717,125.107293 221.858698,124.399595 221.958221,123.611628 C222.057744,122.82366 221.765585,122.036363 221.160357,121.46158 L226,113 L220.54975,113.525124 L216.789525,121.479878 C215.76493,122.483908 215.73402,124.021155 216.717577,125.0581 C217.701134,126.095044 219.388269,126.303924 220.644692,125.544305 L220.644697,125.544295 Z" id="Path" fill="#FFB8B8"></path>
                <path d="M220.828228,120.987825 C220.575316,120.952713 220.339293,120.842615 220.151622,120.672208 L218.402389,119.082473 C218.015304,118.729003 217.893728,118.175457 218.097957,117.696356 L221.593907,109.546091 L221.097964,93.4420648 C221.291938,92.0336663 222.235565,90.8342335 223.573006,90.2960711 C224.910448,89.7579086 226.438251,89.9628821 227.580282,90.8336963 C228.722314,91.7045104 229.304848,93.1086965 229.108212,94.5167378 L229.956166,104.64 C230.213975,107.712434 229.328331,110.772381 227.463977,113.250644 L222.012073,120.499761 C221.799921,120.781533 221.476037,120.961378 221.121142,120.994476 C221.023579,121.003632 220.925257,121.0014 220.828228,120.987825 L220.828228,120.987825 Z" id="Path" fill="#00B0FF"></path>
                <path d="M180.052298,73.0631188 C180.207679,73.809019 180.726828,74.4554412 181.470051,74.8284449 C182.213274,75.2014486 183.104224,75.2627174 183.903164,74.995765 L190.455586,82 L192,77.4699318 L185.540934,71.5131003 C184.934053,70.3238705 183.422258,69.7260025 182.021958,70.1214542 C180.621658,70.516906 179.775469,71.7806806 180.052298,73.0631188 L180.052298,73.0631188 Z" id="Path" fill="#FFB8B8"></path>
                <path d="M183.179769,77.4889634 C183.311984,77.2837746 183.508172,77.1210122 183.742182,77.0223718 L185.924664,76.1035123 C186.409203,75.9008022 186.978013,76.0015157 187.348999,76.3557054 L193.651579,82.3997401 L209.00741,87.9537253 C210.257681,88.6440961 211.017286,89.9039741 210.999701,91.2581341 C210.982117,92.612294 210.190019,93.8546749 208.922187,94.5166513 C207.654355,95.1786276 206.103713,95.1594672 204.855163,94.4663974 L194.992019,91.4377317 C191.998351,90.5189166 189.464739,88.6180802 187.862473,86.0888067 L183.175135,78.6917566 C182.99303,78.4040302 182.950693,78.0577528 183.058675,77.7392281 C183.088304,77.651647 183.128951,77.5676412 183.179769,77.4889634 L183.179769,77.4889634 Z" id="Path" fill="#00B0FF"></path>
                <polygon id="Path" fill="#000000" opacity="0.1" points="223 100 222.74256 108.998453 218 118 221.959942 107.968693"></polygon>
                <path d="M216.344985,78.5469173 C215.427412,78.659861 214.563171,78.1123722 214.323006,77.266012 C214.094818,76.3062183 213.325912,75.5441141 212.323971,75.2846598 C211.02819,75.0042234 209.444484,75.581928 208.495008,74.6520524 C208.082617,74.221412 207.914022,73.6292132 208.041926,73.0605801 C208.216807,72.1569696 208.899261,71.4743216 209.641919,70.9283438 C211.204799,69.7847381 213.096124,69.1152571 215.067806,69.0077186 C216.088399,68.9797371 217.109735,69.0278622 218.122357,69.1516482 C219.182638,69.2150259 220.231337,69.3965529 221.24675,69.6924722 C223.597732,70.5327764 225.333162,72.4516523 225.848838,74.7810299 C226.318606,77.1081621 225.681253,79.5130929 224.10682,81.3542124 C223.175637,82.4524905 221.97495,83.3183548 220.609437,83.8763176 C220.284169,84.0217568 219.911427,84.0396637 219.572292,83.9261429 C218.721761,83.605843 218.860744,82.6079373 219.248543,81.9423582 C219.66355,81.2300837 220.302122,80.5404622 219.968368,79.6736234 C219.801091,79.2716627 219.479939,78.9442265 219.069003,78.7566596 C218.223483,78.3482509 217.277771,78.4448778 216.368798,78.5443023 L216.344985,78.5469173 Z" id="Path" fill="#2F2E41"></path>
                <path d="M177.593472,83 L96.4065282,83 C93.9740089,82.9972703 92.0027478,81.0389721 92,78.622449 L92,61.377551 C92.0027492,58.9610285 93.9740096,57.0027312 96.4065282,57 L177.593472,57 C180.025971,57.0027778 181.997204,58.9610479 182,61.377551 L182,78.622449 C181.997204,81.0389521 180.025971,82.9972222 177.593472,83 Z" id="Path" fill="#FFFFFF"></path>
                <path d="M178.372781,83 L95.6272189,83 C93.0728685,82.9972255 91.0028755,80.9999595 91,78.5353535 L91,61.4646465 C91.002874,59.0000399 93.0728679,57.0027731 95.6272189,57 L178.372781,57 C180.927132,57.0027731 182.997126,59.0000399 183,61.4646465 L183,78.5353535 C182.997124,80.9999595 180.927131,82.9972255 178.372781,83 Z M95.6272189,57.5252525 C93.3733856,57.5277129 91.5469287,59.2899992 91.5443787,61.4646465 L91.5443787,78.5353535 C91.5469287,80.7100008 93.3733856,82.4722871 95.6272189,82.4747475 L178.372781,82.4747475 C180.626614,82.4722871 182.453071,80.7100008 182.455621,78.5353535 L182.455621,61.4646465 C182.453071,59.2899992 180.626614,57.5277129 178.372781,57.5252525 L95.6272189,57.5252525 Z" id="Shape" fill="#3F3D56"></path>
                <path d="M168.545627,77 L106.454373,77 C103.995443,76.9972343 102.002779,75.0142053 102,72.5671642 L102,63.4328358 C102.002778,60.9857941 103.995442,59.0027642 106.454373,59 L168.545627,59 C171.004559,59.0027627 172.997224,60.9857935 173,63.4328358 L173,72.5671642 C172.997222,75.0142059 171.004558,76.9972358 168.545627,77 L168.545627,77 Z" id="Path" fill="#F2F2F2"></path>
                <circle id="Oval" fill="#FF6584" cx="120" cy="69" r="5"></circle>
                <path d="M164.904451,78.4526786 L157.463484,72.0323486 L150.15101,65.7228698 C149.752592,65.3791329 149.171519,65.380293 148.774401,65.7256183 L141.485535,72.0640357 L139.705193,73.6123294 L137.020721,71.2960949 L128.862557,64.2570411 C128.464196,63.9132956 127.883155,63.914443 127.48608,64.2597593 L119.354393,71.3311049 L111.094739,78.5138073 C111.009006,78.5883331 110.977698,78.7100446 111.016377,78.8184424 C111.055056,78.9268403 111.155472,78.998804 111.267712,78.9985626 L128.201838,78.9649955 L133.562051,78.9543755 L133.509611,79 L149.48756,78.9683267 L164.733311,78.9381155 C164.845555,78.9379208 164.945708,78.8655579 164.983979,78.7570018 C165.022251,78.6484458 164.990475,78.5268553 164.904451,78.4526786 L164.904451,78.4526786 Z" id="Path" fill="#3F3D56"></path>
                <path d="M233.737113,177 L183.262887,177 C183.117698,177 183,176.552285 183,176 C183,175.447715 183.117698,175 183.262887,175 L233.737113,175 C233.882302,175 234,175.447715 234,176 C234,176.552285 233.882302,177 233.737113,177 Z" id="Path" fill="#CCCCCC"></path>
                <path d="M162.53383,36.3783253 C162.092188,35.6765905 161.37501,35.1832294 160.550403,35.013887 C160.400863,34.9831523 160.249013,34.9643628 160.096357,34.9577043 L139.252477,16.7330902 C138.843043,16.3215587 138.296908,16.0663718 137.712277,16.0134159 C137.596965,16.0004574 137.480752,15.9968779 137.364835,16.0027144 C136.382125,16.0463843 135.518954,16.6545589 135.165499,17.5523254 C134.812045,18.4500919 135.03529,19.4673173 135.734321,20.1441857 L143.714514,26.9476493 L156.818782,38.1173152 C156.829571,38.3276863 156.861654,38.5364939 156.914561,38.7406762 C157.235704,39.9516738 158.288436,40.8427732 159.56002,40.9799601 C160.126504,41.0446665 160.700168,40.9519436 161.215234,40.7124232 C161.623585,40.5256481 161.985706,40.2545681 162.276677,39.9178386 C162.306735,39.8830588 162.334128,39.8509543 162.361502,39.8134992 C163.144829,38.8152564 163.213479,37.446753 162.533825,36.3783253 L162.53383,36.3783253 Z" id="Path" fill="#FFB8B8"></path>
                <path d="M128.088471,52.7128918 L127.913086,44.9498394 L127.764849,38.507528 L127.663534,33.999255 L127.463433,25.2113505 C127.382568,24.2499456 126.787371,23.4315377 125.946503,23.1255428 C125.105636,22.819548 124.178168,23.0838522 123.582743,23.7991528 C123.514139,23.8791344 123.452144,23.9655457 123.397477,24.0573833 C123.093376,24.5316782 122.956551,25.1094869 123.012111,25.684773 L123.451874,38.507528 L123.671756,44.9498394 L123.953273,53.167485 C123.872064,53.2882326 123.798635,53.4149612 123.733512,53.546761 C123.378457,54.279779 123.315061,55.1393032 123.558127,55.9246329 C123.811954,56.7489571 124.377494,57.4156621 125.112012,57.7564794 C125.846529,58.0972968 126.679852,58.079666 127.401658,57.7080368 C128.243415,57.2746506 128.831325,56.4127783 128.969191,55.4100412 C129.107058,54.4073042 128.776729,53.3957277 128.088441,52.7128918 L128.088471,52.7128918 Z" id="Path" fill="#FFB8B8"></path>
                <path d="M144.704218,30.575287 L142.885629,25.6622428 L139.943105,17.7038809 C139.51303,16.5309201 138.571131,15.6169469 137.383765,15.2204204 C137.337546,15.2050271 137.288691,15.1896337 137.242348,15.1767931 C136.093301,14.8289188 134.849205,14.9995 133.836887,15.6437247 L123.926325,21.9139493 C122.039615,23.1049165 121.439877,25.5737752 122.570803,27.4940386 C123.810184,29.6316057 125.160689,31.7031244 126.616794,33.7001242 C127.771748,35.2676804 129.922029,35.4344417 130.295042,38 L137.602434,38 L145,31.3757284 L144.704218,30.575287 Z" id="Path" fill="#CCCCCC"></path>
                <circle id="Oval" fill="#A0616A" cx="27" cy="87" r="6"></circle>
                <path d="M40.9766864,83.5591016 C41.0361927,83.6043309 41.0938703,83.6522745 41.1497193,83.7029325 C41.7108148,84.2076744 42.0197741,84.9170268 41.9990176,85.652871 C41.978261,86.3887153 41.6297601,87.0811558 41.0410235,87.5563205 C40.97317,87.61071 40.9023759,87.6616636 40.8289329,87.7089713 L41.2376313,100.885281 C41.2615254,101.771024 40.6741278,102.566846 39.7925418,102.843127 L39.7676456,102.848233 L28.5643085,103.987552 C27.7354767,104.071724 26.9230956,103.724671 26.4364076,103.078503 C25.9497195,102.432335 25.8635958,101.586457 26.2108203,100.862863 C26.5580448,100.139269 27.2852012,99.6492742 28.115489,99.5794019 L36.1017311,98.9077365 L37.4828718,87.4968808 C37.3925301,87.4205228 37.3079225,87.3381933 37.2296749,87.2505013 C36.332559,86.2365802 36.3861423,84.7384164 37.3536108,83.7853093 C38.3210792,82.8322023 39.8875058,82.7344043 40.9766917,83.5591066 L40.9766864,83.5591016 Z" id="Path" fill="#A0616A"></path>
                <polygon id="Path" fill="#A0616A" points="36.0005858 173 39.3884951 173 41 161 36 161"></polygon>
                <path d="M35,174.000186 L41.1075195,174 L41.1077662,174 C42.140049,174 43.1300611,174.306066 43.8600009,174.85092 C44.5899406,175.395775 45,176.134764 45,176.90531 L45,176.999722 L35.0001743,177 L35,174.000186 Z" id="Path" fill="#2F2E41"></path>
                <polygon id="Path" fill="#A0616A" points="5 169.39539 7.65076952 171 15 161.368271 11.0875944 159"></polygon>
                <path d="M7.23351955,169 L12.9346196,171.917089 L12.9348497,171.917206 C13.8984313,172.410258 14.5946259,173.212076 14.8702717,174.146262 C15.1459174,175.080449 14.9784336,176.070476 14.4046664,176.898538 L14.3343656,177 L5,172.223835 L7.23351955,169 Z" id="Path" fill="#2F2E41"></path>
                <path d="M41.1563642,169 L37.6568379,169 C37.0698845,169.000945 36.5772733,168.553874 36.5161979,167.964806 L32.9881657,134.500256 C32.9698662,134.327089 32.8389003,134.187745 32.6685008,134.160141 C32.4981012,134.132537 32.3304926,134.223513 32.2593973,134.382198 L25.8947651,148.603358 L14.6265233,167.144394 C14.3250426,167.635729 13.7117791,167.829168 13.1866601,167.598564 L8.68508942,165.600027 C8.3839996,165.46626 8.15429057,165.208521 8.05429451,164.89226 C7.95429845,164.576 7.99363494,164.231642 8.16231489,163.946632 L18.5209311,146.444557 L21.6978324,131.698139 C20.6986521,125.303095 24.9820943,115.79546 25.0255287,115.699993 L25.0467528,115.653523 L36.8456455,111 L36.9048189,111.073098 C41.5579521,126.48167 44.3933685,143.063914 42.2997954,167.939449 C42.2462938,168.537404 41.7513391,168.996482 41.1563642,169 Z" id="Path" fill="#2F2E41"></path>
                <path d="M24.182922,119 L24.1280541,118.88538 C24.1018354,118.830538 21.4824477,113.339594 19.4632406,107.470944 C18.6786543,105.176441 18.8977014,102.660121 20.067136,100.533651 C21.2517768,98.3851817 23.2883263,96.8286769 25.6816997,96.2425224 L25.6816997,96.2425224 C29.9291655,95.2306601 34.2816835,97.4627691 35.9133605,101.489642 C37.5150274,105.470502 37.0045686,109.719583 36.5241916,113.061136 L36.5146229,113.128079 L36.4533561,113.157229 L24.182922,119 Z" id="Path" fill="#3F3D56"></path>
                <path d="M36,85 L26,85 L26,80.8962446 C28.194888,80.0753119 30.3427001,79.3771539 31.6410254,80.8962446 C34.0484129,80.8962574 36,82.7335606 36,85 L36,85 Z" id="Path" fill="#2F2E41"></path>
                <path d="M23.0000668,77 C16.7495307,77 15,84.8140192 15,89.2223014 C15,91.6807498 16.1147834,92.5600477 17.8665334,92.8575739 L18.4851856,89.5668741 L19.9341625,92.999178 C20.4262301,93.0016267 20.9431707,92.9921267 21.4790908,92.9822045 L21.9703741,91.9732965 L23.0659109,92.9641058 C27.4536817,92.9706242 31,93.6085342 31,89.2223014 C31,84.8140192 29.4656767,77 23.0000668,77 Z" id="Path" fill="#2F2E41"></path>
                <path d="M50.7371134,177 L0.262886598,177 C0.117698339,177 0,176.552285 0,176 C0,175.447715 0.117698339,175 0.262886598,175 L50.7371134,175 C50.8823017,175 51,175.447715 51,176 C51,176.552285 50.8823017,177 50.7371134,177 Z" id="Path" fill="#CCCCCC"></path>
                <path d="M53.2419355,90 L40.7580645,90 C39.2355445,89.9982705 38.0017295,88.7644555 38,87.2419355 L38,74.7580645 C38.0017295,73.2355445 39.2355445,72.0017295 40.7580645,72 L53.2419355,72 C54.7644555,72.0017295 55.9982705,73.2355445 56,74.7580645 L56,87.2419355 C55.9982705,88.7644555 54.7644555,89.9982705 53.2419355,90 L53.2419355,90 Z" id="Path" fill="#FFFFFF"></path>
                <path d="M53.2419355,90 L40.7580645,90 C39.2355445,89.9982705 38.0017295,88.7644555 38,87.2419355 L38,74.7580645 C38.0017295,73.2355445 39.2355445,72.0017295 40.7580645,72 L53.2419355,72 C54.7644555,72.0017295 55.9982705,73.2355445 56,74.7580645 L56,87.2419355 C55.9982705,88.7644555 54.7644555,89.9982705 53.2419355,90 L53.2419355,90 Z M40.7580645,72.5806452 C39.5560635,72.5819828 38.5819828,73.5560635 38.5806452,74.7580645 L38.5806452,87.2419355 C38.5819828,88.4439365 39.5560635,89.4180172 40.7580645,89.4193548 L53.2419355,89.4193548 C54.4439365,89.4180172 55.4180172,88.4439365 55.4193548,87.2419355 L55.4193548,74.7580645 C55.4180172,73.5560635 54.4439365,72.5819828 53.2419355,72.5806452 L40.7580645,72.5806452 Z" id="Shape" fill="#3F3D56"></path>
                <path d="M50.1176471,84 L41.8823529,84 C41.3952633,83.9995313 41.0005317,83.651583 41,83.2222228 L41,77.7777825 C41.0005317,77.3484223 41.3952633,77.000474 41.8823529,77 L44.2006824,77 C44.507364,76.9990058 44.7923496,77.1393232 44.9522059,77.3700302 L45.5987471,78.2963006 L50.1176471,78.2963006 C50.6047367,78.2967693 50.9994683,78.6447176 51,79.0740778 L51,83.2222228 C50.9994683,83.651583 50.6047367,83.9995313 50.1176471,84 L50.1176471,84 Z" id="Path" fill="#00B0FF"></path>
                <path d="M48.6785215,86.4354304 C48.7137676,86.5036632 48.7463214,86.5738489 48.776183,86.6459875 C49.0778109,87.3665958 49.0744313,88.1890655 48.7668927,88.9068742 C48.4593542,89.6246828 47.8768689,90.1696479 47.1656991,90.4049287 C47.0838819,90.4316774 47.0007943,90.4538686 46.9167787,90.471411 L42.1051383,103.634347 C41.7784966,104.517772 40.9425617,105.06997 40.048607,104.99284 L40.0244222,104.988002 L29.5955379,101.667719 C28.8239617,101.422041 28.2364185,100.757326 28.0565926,99.9266368 C27.8767668,99.0959481 28.1323268,98.2270981 28.725978,97.6508678 C29.3196291,97.0746375 30.160031,96.8796871 30.9272331,97.1402363 L38.3062248,99.6456979 L44.0189964,88.9347375 C43.9685023,88.8235581 43.9254621,88.7087615 43.8901943,88.5911971 C43.4892742,87.2349101 44.1255125,85.7780232 45.3618099,85.2214265 C46.5981072,84.6648298 48.0320556,85.1896923 48.678524,86.4354304 L48.6785215,86.4354304 Z" id="Path" fill="#A0616A"></path>
            </g>
        </g>
    </g>
</svg>
  );
}

export default NoTemplates;
