import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { colors, Div } from '../../styles/style';

import { getColorByStatusPayment } from '../../lib/helpers/helper';
import { Paragraph } from '../Text/Text';

const DefaultFooter = ({ item }) => {
  const { paymentStatuses, installmentStatuses } = useSelector(state => state.setup.enums);

  const paymentOpen = paymentStatuses?.realValues.includes(item?.payment?.idPaymentStatus);
  const isDefeated =
    dayjs(item?.dueDate).isBefore(dayjs(new Date()), 'day') &&
    item?.idInstallmentStatus === installmentStatuses?.pendingPayment;

  const installmentStatus = isDefeated ? 'Vencido' : item?.installmentStatus?.name;

  const status = paymentOpen ? installmentStatus : item?.payment?.paymentStatus?.name;
  const color = getColorByStatusPayment(
    paymentOpen ? item?.idInstallmentStatus : item?.payment?.idPaymentStatus,
    paymentOpen ? installmentStatuses : paymentStatuses
  );

  return (
    <Div justify="space-between" $fullWidth>
      <Paragraph color={isDefeated ? colors.red500 : color}>{status}</Paragraph>
    </Div>
  );
};

DefaultFooter.propTypes = {
  item: PropTypes.instanceOf(Object)
};

export default DefaultFooter;
