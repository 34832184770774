import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/pro-solid-svg-icons';
import formatCurrency from '../../helpers/formatCurrency';
import { Div, colors, spaces } from '../../../styles/style';
import { formatDecimalsDinamically } from '../../helpers/helper';

const productColumns = isMobile => {
  const array = [
    {
      title: 'Código',
      key: 'cProd',
      dataIndex: 'cProd',
      mobileWidth: '100%',
      width: 108
    },
    {
      title: 'Item',
      key: 'xProd',
      dataIndex: 'xProd',
      mobileWidth: '100%',
      width: 330,
      render: val => (
        <Div gap={spaces.space2}>
          <FontAwesomeIcon icon={faBoxOpen} color={colors.neutral300} />
          {val}
        </Div>
      )
    }
  ];
  return [
    ...(isMobile ? array.reverse() : array),
    {
      title: 'Un.',
      key: 'uCom',
      dataIndex: 'uCom',
      width: 90
    },
    {
      title: 'Qtd.',
      key: 'qCom',
      dataIndex: 'qCom',
      width: 90,
      render: val => formatDecimalsDinamically(val || 0)
    },
    {
      title: 'Valor',
      key: 'vUnCom',
      dataIndex: 'vUnCom',
      align: 'end',
      width: 130,
      render: val => formatCurrency(val, { currencySymbol: 'R$' })
    },
    {
      title: 'Valor total',
      key: 'vProd',
      dataIndex: 'vProd',
      align: 'end',
      mobileWidth: '100%',
      inlineMobile: true,
      width: 190,
      render: val => formatCurrency(val, { currencySymbol: 'R$' })
    }
  ];
};

const chargeColumns = () => {
  return [
    {
      title: 'Número',
      key: 'nDup',
      dataIndex: 'nDup',
      width: 140
    },
    {
      title: 'Vencimento',
      key: 'dVenc',
      dataIndex: 'dVenc',
      align: 'end',
      width: 140,
      render: val => dayjs(val).format('DD/MM/YYYY')
    },
    {
      title: 'Valor',
      key: 'vDup',
      dataIndex: 'vDup',
      align: 'end',
      width: 175,
      render: val => formatCurrency(val, { currencySymbol: 'R$' })
    }
  ];
};

export { productColumns, chargeColumns };
