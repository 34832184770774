import React from 'react';
import PropTypes from 'prop-types';
import { store } from '../../config/redux-store';
import { Div, colors, spaces, fonts } from '../../../styles/style';
import formatCurrency from '../../helpers/formatCurrency';
import { Paragraph } from '../../../components/Text/Text';
import ImageInput from '../../../components/Input/ImageInput';
import Button from '../../../components/Button/Button';
import TooltipText from '../../../components/Tooltip/TooltipText';
import { formatDecimalsDinamically } from '../../helpers/helper';

const columns = ({
  isAdvanced = false,
  isPrint = false,
  printColumns,
  isMobile = false,
  seeMoreRows = {},
  setSeeMoreRows,
  isAppropriation
}) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;

  const ColParagraph = ({ row = {}, children, ...props }) => {
    const { type } = row;
    const isChildren = refurbishItemType?.parent === type;
    return (
      <Paragraph type="small" weight={isChildren ? fonts.weight500 : fonts.weight600} {...props}>
        {children}
      </Paragraph>
    );
  };

  ColParagraph.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Object)]),
    row: PropTypes.instanceOf(Object)
  };

  const generateCellId = (suffix, type) =>
    `${refurbishItemType?.parent === type ? 'appropriation-level-' : 'purchase-item-'}${suffix}`;

  const formatQuantity = value => (value ? formatDecimalsDinamically(value) : '-');

  const codeColumn = {
    title: 'Código',
    dataIndex: 'code',
    width: 80,
    key: 'code',
    render: (val, row) => {
      const { type } = row || {};

      if (refurbishItemType?.parent === type) return null;

      return (
        <ColParagraph row={row} className="custom-content-pdf" id={generateCellId('code', type)}>
          {val}
        </ColParagraph>
      );
    }
  };

  const itemNameColumn = {
    title: isAppropriation ? 'Nível' : 'Item',
    dataIndex: 'itemName',
    key: 'itemName',
    width: 350,
    renderCollapseIcon: true,
    alwaysShow: true,
    sorter: true,
    mobileWidth: '100%',
    render: (val, row) => {
      const { type } = row || {};
      const icon = refurbishItemType?.product === type ? 'boxOpen' : 'screwdriverWrench';
      const isChildren = refurbishItemType?.parent === type;

      return (
        <Div className="table-name">
          {!isChildren && (
            <ImageInput
              size="18px"
              key={`image${row.id}`}
              id={row.id}
              value={icon}
              disabled
              justify="flex-start"
              mobileWidth={spaces.space3}
            />
          )}
          <ColParagraph
            row={row}
            id={generateCellId('name', type)}
            style={{ marginLeft: !isMobile && spaces.space1 }}
            className="custom-content-pdf"
          >
            {val}
          </ColParagraph>
        </Div>
      );
    }
  };

  const unitColumn = {
    title: 'Unidade',
    dataIndex: 'unit',
    key: 'unit',
    width: 80,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} className="custom-content-pdf" id={generateCellId('unit', type)}>
          {val}
        </ColParagraph>
      );
    }
  };

  const budgetQuantityColumn = {
    title: <TooltipText tooltipText="Quantidade do item no Orçamento." text="Qtd. orçada" />,
    dataIndex: 'budgetQuantity',
    key: 'budgetQuantity',
    width: 110,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} className="custom-content-pdf" id={generateCellId('budget-quantity', type)}>
          {formatQuantity(val)}
        </ColParagraph>
      );
    }
  };

  const purchasedQuantityColumn = {
    title: (
      <TooltipText
        tooltipText="Quantidade do item vinculada em uma ordem de compra nos status: “Aceita” ou “Finalizada”."
        text="Qtd. comprada"
      />
    ),
    dataIndex: 'purchasedQuantity',
    key: 'purchasedQuantity',
    width: 130,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} className="custom-content-pdf" id={generateCellId('purchased-quantity', type)}>
          {formatQuantity(val)}
        </ColParagraph>
      );
    }
  };

  const requestedQuantityColumn = {
    title: (
      <TooltipText
        tooltipText={`Quantidade do item vinculada em uma solicitação nos
    status: “Aberta”, “Cotação” ou “Compra e contratação”.`}
        text="Qtd. em solicitação"
      />
    ),
    dataIndex: 'requestedQuantity',
    key: 'requestedQuantity',
    width: 160,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    showOnlyWithSeeMore: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} id={generateCellId('requested-quantity', type)} className="custom-content-pdf">
          {formatQuantity(val)}
        </ColParagraph>
      );
    }
  };

  const inQuoteQuantity = {
    title: (
      <TooltipText
        tooltipText="Quantidade do item vinculada em uma Cotação nos status: “Aberta”, “Resposta pendente” ou “Cotada”."
        text="Qtd. em cotação"
      />
    ),
    dataIndex: 'inQuoteQuantity',
    key: 'inQuoteQuantity',
    width: 150,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    showOnlyWithSeeMore: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph
          row={row}
          type="small"
          id={generateCellId('in-quote-quantity', type)}
          className="custom-content-pdf"
        >
          {formatQuantity(val)}
        </ColParagraph>
      );
    }
  };

  const inPurchaseQuantity = {
    title: (
      <TooltipText
        tooltipText={`Quantidade do item vinculada em uma ordem de compra
    nos status: “Aberta” ou “Enviada”.`}
        text="Qtd. em compra"
      />
    ),
    dataIndex: 'inPurchaseQuantity',
    key: 'inPurchaseQuantity',
    width: 150,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    showOnlyWithSeeMore: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} id={generateCellId('in-purchase-quantity', type)} className="custom-content-pdf">
          {formatQuantity(val)}
        </ColParagraph>
      );
    }
  };

  const balanceColumn = {
    title: <TooltipText tooltipText="Quantidade comprada dividida pela Quantidade orçada." text="Balanço" />,
    dataIndex: 'balance',
    key: 'balance',
    width: 90,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    showOnlyWithSeeMore: true,
    tooltip: 'Quantidade comprada dividida pela Quantidade orçada.',
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph
          row={row}
          id={generateCellId('balance', type)}
          color={val <= 100 ? colors.green500 : colors.red500}
          className="custom-content-pdf"
        >
          {formatCurrency(val)}%
        </ColParagraph>
      );
    }
  };

  const averageBudgetCostColumn = {
    title: <TooltipText tooltipText="Custo médio unitário dos itens no orçamento." text="Custo médio orc." />,
    dataIndex: 'averageBudgetCost',
    key: 'averageBudgetCost',
    width: 120,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    showOnlyWithSeeMore: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} id={generateCellId('average-budget-cost', type)} className="custom-content-pdf">
          {formatCurrency(val, { currencySymbol: 'R$' })}
        </ColParagraph>
      );
    }
  };

  const averagePurchasedCostColumn = {
    title: <TooltipText tooltipText="Custo médio unitário dos itens comprados." text="Custo médio real" />,
    dataIndex: 'averagePurchasedCost',
    key: 'averagePurchasedCost',
    width: 120,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    showOnlyWithSeeMore: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} id={generateCellId('average-purchased-cost', type)} className="custom-content-pdf">
          {formatCurrency(val, { currencySymbol: 'R$' })}
        </ColParagraph>
      );
    }
  };

  const averageCostColumn = {
    title: <TooltipText tooltipText="Custo médio realizado dividido pelo Custo médio orçado." text="% Custo médio" />,
    dataIndex: 'averageCost',
    key: 'averageCost',
    width: 130,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph
          row={row}
          id={generateCellId('percentage-average-cost', type)}
          color={val <= 100 ? colors.green400 : colors.red500}
          className="custom-content-pdf"
        >
          {formatCurrency(val)}%
        </ColParagraph>
      );
    }
  };

  const budgetPriceColumn = {
    title: <TooltipText tooltipText="Valor total estimado no Orçamento." text="Custo total orc." />,
    dataIndex: 'budgetPrice',
    key: 'budgetPrice',
    width: 130,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} id={generateCellId('budget-price', type)} className="custom-content-pdf">
          {formatCurrency(val, { currencySymbol: 'R$' })}
        </ColParagraph>
      );
    }
  };

  const purchasedPriceColumn = {
    title: (
      <TooltipText
        tooltipText={`Custo total gasto com o item de acordo com
    as ordens de compras “Aceitas” ou “Finalizadas”`}
        text="Custo total real"
      />
    ),
    dataIndex: 'purchasedPrice',
    key: 'purchasedPrice',
    width: 130,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph row={row} id={generateCellId('purchased-price', type)} className="custom-content-pdf">
          {formatCurrency(val, { currencySymbol: 'R$' })}
        </ColParagraph>
      );
    }
  };

  const totalCostColumn = {
    title: <TooltipText tooltipText="Custo total realizado dividido pelo Custo total orçado." text="% Custo total" />,
    dataIndex: 'totalCost',
    key: 'totalCost',
    width: 130,
    renderCollapseIcon: true,
    alwaysShow: true,
    align: 'right',
    sorter: true,
    render: (val, row) => {
      const { type } = row || {};

      return (
        <ColParagraph
          row={row}
          id={generateCellId('percentage-total-cost', type)}
          color={val <= 100 ? colors.green400 : colors.red500}
          className="custom-content-pdf"
        >
          {formatCurrency(val)} %
        </ColParagraph>
      );
    }
  };

  const seeMoreColumn = {
    title: '',
    dataIndex: 'seeMore',
    key: 'seeMore',
    width: 80,
    inlineMobile: true,
    justify: 'flex-start',
    mobileWidth: '100%',
    render: (_, row) => {
      const { id, type } = row;

      if (refurbishItemType?.parent === type) return null;

      return (
        <Button row={row} text padding={0} onClick={() => setSeeMoreRows(prev => ({ ...prev, [id]: !prev?.[id] }))}>
          <ColParagraph>Ver {!seeMoreRows[id] ? 'mais' : 'menos'}</ColParagraph>
        </Button>
      );
    }
  };

  if (isMobile) {
    return [
      itemNameColumn,
      codeColumn,
      unitColumn,
      budgetQuantityColumn,
      purchasedQuantityColumn,
      budgetPriceColumn,
      purchasedPriceColumn,
      averageCostColumn,
      totalCostColumn,
      averageBudgetCostColumn,
      averagePurchasedCostColumn,
      requestedQuantityColumn,
      inQuoteQuantity,
      inPurchaseQuantity,
      balanceColumn,
      seeMoreColumn
    ];
  }

  const allColumns = isAdvanced
    ? [
        codeColumn,
        itemNameColumn,
        unitColumn,
        budgetQuantityColumn,
        purchasedQuantityColumn,
        requestedQuantityColumn,
        inQuoteQuantity,
        inPurchaseQuantity,
        balanceColumn,
        averageBudgetCostColumn,
        averagePurchasedCostColumn,
        averageCostColumn,
        budgetPriceColumn,
        purchasedPriceColumn,
        totalCostColumn
      ]
    : [
        codeColumn,
        itemNameColumn,
        budgetQuantityColumn,
        purchasedQuantityColumn,
        averageCostColumn,
        budgetPriceColumn,
        purchasedPriceColumn,
        totalCostColumn
      ];

  if (isPrint) {
    return allColumns.filter(c => printColumns.includes(c.key) && c.dataIndex);
  }

  return allColumns;
};

export { columns };
