import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMaskInput from 'react-text-mask';

import styled from 'styled-components';
import { CurrencyInput } from 'react-currency-mask';
import { phoneMask, cnpjMask, cpfMask, zipCodeMask, maskCreditCard } from './masks';
import { colors, fonts, radius, spaces } from '../../styles/style';

const StyledInput = styled.input`
  border: 1px solid ${colors.neutral200};
  height: ${spaces.space4};
  border-radius: ${radius.radius1};
  padding: 0 11px;
  width: ${props => props.width || '100%'};
  font-size: ${fonts.sizeMd};
  border-color: ${props => props.error && colors.red500};

  &:focus {
    box-shadow: none;
    border-color: ${colors.neutral600};
  }

  &:hover {
    border-color: ${colors.neutral600};
  }

  ::placeholder {
    color: ${colors.neutral300};
  }
`;

const defaultCurrencyMaskOptions = {
  prefix: 'R$ ',
  allowNegative: false
};

const defaultNumberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  allowLeadingZeroes: false
};

const getMask = (maskType, maskOptions) => {
  switch (maskType) {
    case 'phone': {
      return phoneMask;
    }
    case 'cnpj': {
      return cnpjMask;
    }
    case 'cpf': {
      return cpfMask;
    }
    case 'zipCode': {
      return zipCodeMask;
    }
    case 'currency': {
      return createNumberMask({ ...defaultNumberMaskOptions, ...defaultCurrencyMaskOptions, ...maskOptions });
    }
    case 'number': {
      return createNumberMask({ ...defaultNumberMaskOptions, ...maskOptions });
    }
    case 'creditCard': {
      return maskCreditCard;
    }
    default:
      return null;
  }
};

const MaskedInput = ({ type, maskOptions = {}, ...otherProps }) => {
  const inputRef = useRef(null);
  const handleKeyDown = e => {
    const keys = ['Backspace', 'ArrowLeft', 'ArrowRight', '-'];
    if (keys.includes(e.key)) return;
    const { value } = inputRef.current;
    const endPos = value?.length || 0;
    inputRef.current.setSelectionRange(endPos, endPos);
    inputRef.current.focus();
  };

  return type === 'currency' ? (
    <CurrencyInput
      defaultValue={otherProps.defaultValue}
      value={otherProps.value}
      onChangeValue={(_, originalValue) => {
        otherProps.onChange({ target: { value: originalValue } });
      }}
      onFocus={otherProps.onFocus}
      onKeyPress={otherProps.onKeyPress}
      onBlur={otherProps.onBlur}
      ref={inputRef}
      InputElement={<StyledInput onKeyDown={handleKeyDown} {...otherProps} />}
    />
  ) : (
    <TextMaskInput
      mask={getMask(type, maskOptions)}
      render={(ref, props) => <StyledInput ref={ref} {...props} />}
      {...otherProps}
    />
  );
};

MaskedInput.propTypes = {
  type: PropTypes.oneOf(['phone', 'cnpj', 'cpf', 'zipCode', 'currency', 'number']).isRequired,
  maskOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
};

export default MaskedInput;
