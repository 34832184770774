import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StepsContainer, StepsContent, Group, StyledRadio, StyledRadioGroup } from './Onboarding.styled';

import { fonts, colors, spaces } from '../../../styles/style';
import NavigationButton from '../../../components/Button/NavigationButton';
import useCRUD from '../../../_Hooks/useCRUD';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import Modal from '../../../components/Modal/Modal';
import { Subtitle, Title } from '../../../components/Text/Text';

const FirstProject = ({ onReturn, onSubmit, data }) => {
  const [create, setCreate] = useState(true);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const { companyTypesCategories } = useSelector(state => state.setup.systemData);
  const type = Object.entries(companyTypesCategories).find(([, types]) => types.includes(data?.idType))?.[0];

  const isArchitect = type === 'architecture';

  const { handleCreate } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const handleSubmit = () => {
    if (!create) {
      onSubmit();
      return;
    }
    setShowLoadingModal(true);
    handleCreate({ postPathOptions: `/create-example/${type}`, refresh: false }).then(resp => {
      setShowLoadingModal(false);
      if (!resp?.error) onSubmit();
    });
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <StepsContainer $imageMargin={create && '0'}>
          <div className="stepColumn">
            <StepsContent>
              <Group gap={spaces.space1}>
                <Title size={fonts.sizeH5} id="onboarding-page-title">
                  Como você prefere começar?
                </Title>
                <Group gap="0px">
                  <Subtitle weight={fonts.weight500} color={colors.neutral600}>
                    Escolha a opção que considera melhor.
                  </Subtitle>
                  <Subtitle weight={fonts.weight500} color={colors.neutral600}>
                    Se mudar de ideia, você pode alterar isso depois.
                  </Subtitle>
                </Group>
              </Group>
              <StyledRadioGroup onChange={e => setCreate(e.target.value)} value={create}>
                <StyledRadio value $selected={create}>
                  Gostaria de {isArchitect ? 'um projeto' : 'uma obra'} de exemplo
                  <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral600}>
                    Sua conta virá com {isArchitect ? 'um projeto' : 'uma obra'} de exemplo para você entender
                    exatamente como tudo funciona.
                  </Subtitle>
                </StyledRadio>
                <StyledRadio value={false} $selected={create === false}>
                  Começar do zero
                  <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral600}>
                    Sua conta irá começar vazia. Cadastre {isArchitect ? 'seus projetos' : 'suas obras'} e comece sua
                    jornada.
                  </Subtitle>
                </StyledRadio>
              </StyledRadioGroup>
              <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral500}>
                * Não se preocupe, caso escolha ter {isArchitect ? 'um projeto' : 'uma obra'} de exemplo, você poderá
                excluir as informações {isArchitect ? 'do projeto' : 'da obra'} de exemplo em qualquer momento.
              </Subtitle>
            </StepsContent>
          </div>
        </StepsContainer>
        <NavigationButton onReturn={onReturn} onSubmit={handleSubmit} canSubmit={create !== null} />
      </div>
      <Modal
        open={showLoadingModal}
        closable={false}
        hideCloseBtn
        hideFooter
        title="Estamos criando seu primeiro projeto!"
        width="500px"
        height="240px"
      >
        <CenteredLoader text="Aguarde..." />
      </Modal>
    </>
  );
};

FirstProject.propTypes = {
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default FirstProject;
