import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

const MobileSelectMenu = ({ options, field, onClick, ...props }) => (
  <Menu style={{ maxHeight: '300px', overflow: 'auto' }} {...props}>
    {options.map(option => (
      <Menu.Item key={option.id || option.value} onClick={() => onClick(field, option)}>
        {option.label || option.name}
      </Menu.Item>
    ))}
  </Menu>
);

MobileSelectMenu.propTypes = {
  options: PropTypes.instanceOf(Array),
  field: PropTypes.string,
  onClick: PropTypes.func
};

export default MobileSelectMenu;
