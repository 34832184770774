import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TooltipIcon = ({
  icon,
  text,
  tooltipColor,
  iconColor,
  tooltipProps,
  iconProps,
  onClick,
  trigger,
  id,
  style = {},
  ...props
}) => {
  return (
    <Tooltip trigger={trigger} title={text} color={tooltipColor} {...tooltipProps}>
      <FontAwesomeIcon
        icon={icon}
        id={id}
        color={iconColor}
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : 'auto', ...style }}
        {...iconProps}
        {...props}
      />
    </Tooltip>
  );
};

TooltipIcon.propTypes = {
  icon: PropTypes.instanceOf(Object),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  tooltipColor: PropTypes.string,
  iconColor: PropTypes.string,
  tooltipProps: PropTypes.instanceOf(Object),
  iconProps: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  trigger: PropTypes.instanceOf(Array),
  id: PropTypes.string,
  style: PropTypes.instanceOf(Object)
};

export default TooltipIcon;
