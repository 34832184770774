import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { Paragraph } from '../Text/Text';
import Input from '../Input/Input';
import Button from '../Button/Button';

import { Div, colors, fonts, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import { transformHtmlToWhatsappMessage, checkEmail } from '../../lib/helpers/helper';
import CopyLinkButton from '../Button/CopyLinkButton';

const RenderSupplierData = ({ idQuoteSupplier, name, email, message }) => {
  const { quoteSupplierStatus } = useSelector(state => state.setup.enums);

  const [emailToSend, setEmailToSend] = useState(email);
  const [hasUpdatedQuoteSupplier, setHasUpdatedQuoteSupplier] = useState(false);
  const [hasSendedEmail, setHasSendedEmail] = useState(false);

  const link = `${window.location.origin}/qs/${idQuoteSupplier}`;

  const { handleCreate: sendEmail } = useCRUD({
    model: 'quoteSuppliers/send-email-share',
    immediatelyLoadData: false
  });

  const { handleUpdate: updateQuoteSupplier } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const handleUpdateQuoteSupplier = () => {
    if (!hasUpdatedQuoteSupplier) {
      updateQuoteSupplier({
        id: idQuoteSupplier,
        values: { status: quoteSupplierStatus.waitingResponse.id },
        refresh: false
      });
      setHasUpdatedQuoteSupplier(true);
    }
  };

  return (
    <>
      <Paragraph>{name}</Paragraph>
      <Div $fullWidth gap={spaces.space1}>
        <Input value={emailToSend} onChange={e => setEmailToSend(e.target.value)} />
        <Button
          id="send-email"
          {...(hasSendedEmail ? { ghost: true, disabled: true } : { type: 'primary' })}
          onClick={() => {
            if (!checkEmail(emailToSend)) {
              toast.error('Email inválido');
              return;
            }
            sendEmail({
              displayToast: true,
              refresh: false,
              values: { message: message + link, email: emailToSend, idQuoteSupplier }
            }).then(() => {
              setHasSendedEmail(true);
              handleUpdateQuoteSupplier();
            });
          }}
        >
          {hasSendedEmail ? 'Enviado' : 'Enviar por email'}
        </Button>
        <Tooltip placement="top" title="Copiar link">
          <CopyLinkButton customUrl={link} customEvent="copy-quote-supplier-link" text>
            <Div width={spaces.space4} height={spaces.space4} justify="center">
              <FontAwesomeIcon icon={faCopy} style={{ fontSize: fonts.sizeLg }} color={colors.primary600} />
            </Div>
          </CopyLinkButton>
        </Tooltip>
        <Tooltip placement="bottom" title="Enviar via whatsapp">
          <a
            href={`https://wa.me/?text=${encodeURIComponent(
              parse(`${transformHtmlToWhatsappMessage(message)} ${link}`)
            )}`}
            onClick={handleUpdateQuoteSupplier}
            target="_blank"
          >
            <Div width={spaces.space4} height={spaces.space4} justify="center">
              <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: fonts.sizeLg }} />
            </Div>
          </a>
        </Tooltip>
      </Div>
    </>
  );
};

RenderSupplierData.propTypes = {
  idQuoteSupplier: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string
};

export default RenderSupplierData;
