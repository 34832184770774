import styled, { css } from 'styled-components';
import { colors, spaces, fonts, breakpoints, radius } from '../../styles/style';
import Button from '../Button/Button';
import MaskedInput from '../Input/NewMaskedInput';

const Container = styled.div`
  border-radius: ${radius.radius1};
  border: 1px solid ${colors.neutral100};
  width: 400px;
  padding: ${spaces.space1} ${spaces.space2} 0 ${spaces.space2};
  font-size: ${fonts.sizeSm};

  small {
    color: ${colors.red600};
    flex-basis: 100%;
    text-align: end;
  }
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
  @media print {
    break-inside: avoid;
  }
`;

const RowValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${spaces.space4};
  flex: ${props => props.flex || 1};
  gap: ${props => props.gap || spaces.space4};

  &.totalValue {
    background-color: ${colors.neutral100};
    margin: ${spaces.space1} -${spaces.space2} 0 -${spaces.space2};
    padding: ${spaces.space2};
    flex-wrap: wrap;
  }

  &.paidValue {
    background-color: ${colors.neutral75};
    margin: 0 -${spaces.space2};
    padding: 0 ${spaces.space2};
    padding-top: ${spaces.space1};

    .value {
      font-size: ${fonts.sizeSm};
      color: ${colors.green500};
    }
  }

  &.restValue {
    background-color: ${colors.neutral75};
    margin: 0 -${spaces.space2};
    padding: 0 ${spaces.space2};
    font-weight: 400;
    padding-top: ${spaces.space1};
    ${props =>
      props.$withPaddingBottom &&
      css`
        padding-bottom: ${spaces.space1};
      `}

    .value {
      color: ${colors.orange400};
    }
  }
  &.cancelledValue {
    background-color: ${colors.neutral75};
    margin: 0 -${spaces.space2};
    padding: 0 ${spaces.space2};
    font-weight: 400;
    padding-top: ${spaces.space1};
    padding-bottom: ${spaces.space1};

    .value {
      color: ${colors.red600};
    }
  }
`;

const PlusButton = styled(Button)`
  margin-left: auto;
`;

const StyledMaskedInput = styled(MaskedInput)`
  flex: 4;
  margin-left: ${spaces.space1};
  height: ${spaces.space3};
`;

export { Container, RowValue, PlusButton, StyledMaskedInput };
