import styled, { css } from 'styled-components';
import { colors, radius, spaces } from '../../styles/style';

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${spaces.space1};
  padding-left: ${spaces.space2};
  padding-right: ${spaces.space1};
`;

export const InlineItems = styled.div`
  display: flex;
  padding-right: ${spaces.space2};
  padding-left: ${spaces.space2};
  gap: ${spaces.space2};
  flex-wrap: wrap;

  align-items: start;
  ${props =>
    props.description &&
    css`
      justify-content: flex-start;
    `}

  .field {
    ${props =>
      !props.isView &&
      css`
        height: 32px;
      `}

    display: flex;
    align-items: center;
  }
`;

export const StyledCardMobile = styled.div`
  margin-bottom: ${spaces.space2};
  background-color: ${colors.neutral50};
  border: 1px solid ${colors.white};
  border-radius: ${radius.radius1};
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  padding-top: ${spaces.space2};
  padding-bottom: ${spaces.space1};
`;

export const StyledCardRow = styled.div`
  padding-bottom: ${spaces.space2};
`;
