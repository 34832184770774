import { v4 as uuidV4 } from 'uuid';
import { toast } from 'react-toastify';
import { store } from '../config/redux-store';
import formatNumber from './formatNumber';
import { deepClone } from './helper';

const calculatePercentageOrQuantity = ({ key, newList, parentIndex, _value }) => {
  const parentQuantity = Number(newList[parentIndex]?.quantity || 0);
  if (parentQuantity === 0 || Number.isNaN(parentQuantity)) return 0;
  return key === 'quantity' ? (_value * 100) / parentQuantity : (_value * parentQuantity).toFixed(2);
};

const updateListOnEdit = ({
  originalList,
  type,
  value,
  row,
  multipleEdit,
  childrenColumnName,
  index,
  key,
  object,
  objectKey
}) => {
  const reduxState = store.getState();
  const { refurbishItemType } = reduxState.setup.enums;
  const _value = key === 'percentage' ? value / 100 : value;
  const newList = deepClone(originalList);
  if (type === refurbishItemType?.parent) {
    const parentIndex = newList.findIndex(_item => _item.id === row.idParent);

    if (multipleEdit) {
      multipleEdit.forEach(pair => {
        newList[parentIndex][childrenColumnName][index][pair?.key] = pair?.value;
      });
    } else {
      newList[parentIndex][childrenColumnName][index][key] = value;
    }

    if (['quantity', 'percentage'].includes(key)) {
      const result = calculatePercentageOrQuantity({ key, newList, parentIndex, _value });
      const oppositeKey = key === 'quantity' ? 'percentage' : 'quantity';
      newList[parentIndex][childrenColumnName][index][oppositeKey] = result;
    }
  } else {
    newList[index][key] = value;
    if (key === 'price') {
      const levels = newList[index]?.[childrenColumnName] || [];
      levels.forEach((_, levelIndex) => {
        newList[index][childrenColumnName][levelIndex][key] = value || 0;
      });
    }
    if (object && objectKey) newList[index][objectKey] = object;

    if (key === 'quantity') {
      const levels = newList[index]?.[childrenColumnName] || [];
      levels.forEach((level, levelIndex) => {
        if (!value) {
          newList[index][childrenColumnName][levelIndex][key] = 0;
          newList[index][childrenColumnName][levelIndex].percentage = 0;
        } else {
          newList[index][childrenColumnName][levelIndex][key] = (value * (level?.percentage / 100)).toFixed(2);
        }
      });
    }
  }

  return newList;
};

const updateListOnDelete = ({ originalList, idParent, childrenColumnName, id }) => {
  let newList = [...originalList];

  if (idParent) {
    const parentIndex = newList.findIndex(_item => _item.id === idParent);
    const newChildren = newList[parentIndex][childrenColumnName].filter(_item => _item.id !== id);
    newList[parentIndex][childrenColumnName] = newChildren?.length ? newChildren : null;
    newList[parentIndex].quantity = newChildren?.length
      ? newChildren.reduce((acc, curr) => acc + Number(curr.quantity), 0)
      : 0;
  } else {
    newList = originalList.filter(_item => _item.id !== id);
  }

  return newList;
};

const formatAppropriation = ({ appropriation, idParent, parent }) => {
  const reduxState = store.getState();
  const { refurbishItemType } = reduxState.setup.enums;
  const appropriationQuantity = appropriation.quantity || 0;
  return {
    idUnit: appropriation?.unit?.id,
    name: appropriation?.name,
    unit: appropriation?.unit,
    price: parent.price || appropriation.price,
    type: refurbishItemType?.parent,
    id: uuidV4(),
    idParent,
    percentage: parent.quantity ? (appropriationQuantity * 100) / parent.quantity : 100,
    quantity: appropriationQuantity,
    refurbishItem: {
      name: appropriation?.name,
      id: appropriation?.id
    },
    idRefurbishItem: appropriation?.id
  };
};

const generateChildrenList = (item, childrenColumnName, newQuantity = 0) => {
  const newParentQuantity = formatNumber(item.quantity + (newQuantity || 0));

  return item?.[childrenColumnName]?.map(i => ({
    ...i,
    percentage: newParentQuantity ? (i.quantity / newParentQuantity) * 100 : 0
  }));
};

const formatCountToUseInFilterTab = ({ count, statusField, statusEnum }) => {
  return count?.reduce(
    (acc, { count: numRows, [statusField]: idStatus }) => {
      Object.entries(statusEnum).forEach(([key, value]) => {
        if (value !== idStatus) return;
        acc[key] = Number(numRows);
        acc.all += Number(numRows);
      });

      return acc;
    },
    { all: 0 }
  );
};

const submitCatalog = ({
  newItem,
  childrenColumnName,
  index,
  type,
  prev,
  setCreatedLevels,
  hasSplitAndRefurbish,
  idRefurbish
}) => {
  const reduxState = store.getState();
  const { purchaseTypeMap } = reduxState.setup.enums;

  const _item = prev[index];

  const childIndex = _item?.[childrenColumnName]?.findIndex(i => i.refurbishItem?.id === newItem.id);

  if (childIndex > -1) {
    toast.error(`Apropriação já existe na ${purchaseTypeMap[type].title}`);
    return prev;
  }

  const newList = [...prev];

  const newParentQuantity = formatNumber(_item?.quantity + (newItem?.quantity || 0));

  const newChildrenList = generateChildrenList(_item, childrenColumnName, newItem?.quantity);

  const newAppropriation = formatAppropriation({
    appropriation: newItem,
    idParent: _item?.id,
    parent: { ..._item, quantity: newParentQuantity }
  });

  setCreatedLevels &&
    setCreatedLevels(prevLevels => ({
      ...prevLevels,
      ...(hasSplitAndRefurbish
        ? {
            [idRefurbish]: {
              ...(prevLevels?.[idRefurbish] ? prevLevels[idRefurbish] : {}),
              [newAppropriation.id]: true
            }
          }
        : { [newAppropriation.id]: true })
    }));

  newList[index] = {
    ..._item,
    quantity: newParentQuantity,
    [childrenColumnName]: newChildrenList ? [...newChildrenList, newAppropriation] : [newAppropriation]
  };
  return newList;
};

const generateChildrenListObj = (item, childrenColumnName) => {
  return (item?.[childrenColumnName] || []).reduce((acc, opt) => {
    const newData = deepClone(opt);
    newData.quantity = Math.min(Number(opt?.quantity), Number(item.quantity));

    acc[opt?.refurbishItem?.id] = [...(acc[opt?.refurbishItem?.id] || []), newData];

    return acc;
  }, {});
};

const generateMaps = (list, childrenColumnName) => {
  const newItemAppropriationMap = {};
  const newItemsOnListMap = {};

  list?.forEach(i => {
    const childrenListObj = generateChildrenListObj(i, childrenColumnName);

    newItemAppropriationMap[i.id] = {
      children: childrenListObj,
      data: i
    };

    const parentKey = i.idItem || `ri-${i.idRefurbishItem}`;
    newItemsOnListMap[parentKey] = true;

    i?.[childrenColumnName]?.forEach(child => {
      newItemsOnListMap[`${parentKey}-${child?.refurbishItem?.id}`] = true;
    });
  });

  return { newItemAppropriationMap, newItemsOnListMap };
};

const getUniqueItems = obj => {
  const allItems = Object.values(obj).flat();
  const uniqueItems = new Map();

  allItems.forEach(item => {
    const { idItem } = item;

    if (uniqueItems.has(idItem)) {
      const existingItem = uniqueItems.get(idItem);
      uniqueItems.set(idItem, {
        ...existingItem,
        paymentItemLevels: item.paymentItemLevels || existingItem.paymentItemLevels
      });
    } else {
      uniqueItems.set(idItem, item);
    }
  });

  return Array.from(uniqueItems.values());
};

export {
  updateListOnEdit,
  updateListOnDelete,
  calculatePercentageOrQuantity,
  formatAppropriation,
  generateChildrenList,
  formatCountToUseInFilterTab,
  submitCatalog,
  generateMaps,
  getUniqueItems
};
