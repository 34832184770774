import { store } from '../config/redux-store';
import GoogleTagManagerPlugin from './plugins/googleTagManager';
import SegmentPlugin from './plugins/segment';

const gtmPlugin = GoogleTagManagerPlugin();
const segmentPlugin = SegmentPlugin();

const getState = () => {
  const {
    authReducer,
    refurbishReducer,
    setup: { enums }
  } = store.getState();

  const { user } = authReducer || {};
  const refurbish = refurbishReducer || {};

  return { user, refurbish, enums };
};

const getUserAnonymousId = () => {
  try {
    return window.analytics.user().anonymousId();
  } catch {
    return undefined;
  }
};

const getBaseProperties = (user, refurbish) => {
  return {
    accessedBy: user?.accessedBy,
    companyId: user?.idCompany || refurbish?.idCompany,
    email: user?.email,
    idRefurbish: refurbish?.id
  };
};

const transformUser = (user, refurbish) => {
  if (!user) return user;

  return {
    userId: user.id,
    email: user.email,
    mgm: user.MGMCode,
    name: user.name,
    userType: user.userType,
    ...getBaseProperties(user, refurbish),
    ...(user.idCompany && {
      company: { id: user.idCompany, mgm: user?.company?.MGMCode, plan: user?.company?.idPlan }
    }),
    lastLogin: user.lastLogin
  };
};

const AnalyticsService = (services = [gtmPlugin, segmentPlugin]) => {
  const _services = services || [];
  let lastUserIdentified;

  const identify = () => {
    const { user, refurbish, enums } = getState();
    const _user = transformUser(user, refurbish);

    if (JSON.stringify(lastUserIdentified) === JSON.stringify(_user)) return;

    if (window.flutter_inappwebview && user?.userType === enums?.userType.provider.value) {
      const plan = user?.company?.plan || user?.company?.idPlan;
      if (plan && plan !== enums?.planType.free.value)
        window.flutter_inappwebview.callHandler('vobiIdentifyUser', _user).then(() => {});
    }

    lastUserIdentified = _user;

    _services.forEach(service => service.identify(_user));
  };

  const track = data => {
    const { user, refurbish } = getState();
    const { ..._data } = data;
    _data.eventProperties = {
      type: user?.type,
      ...(user?.accessedBy
        ? {
            accessedBy: user.accessedBy.email,
            accessedByType: user.accessedBy.type
          }
        : {}),
      ...getBaseProperties(user, refurbish),
      ..._data.eventProperties
    };
    _services.forEach(service => {
      service.track({ user, ..._data });
    });
  };

  const pageview = () => {
    const { user, refurbish } = getState();
    const eventProperties = getBaseProperties(user, refurbish);
    _services.forEach(service => {
      service.pageview({ user, eventProperties });
    });
  };

  return { identify, track, pageview };
};

const AnalyticsServiceMock = () => {
  const identify = () => {};
  const track = () => {};
  const pageview = () => {};

  return { identify, track, pageview };
};

const AnalyticsServiceFactory = service => {
  if (!service) return AnalyticsService();
  return typeof service?.track === 'function' ? service : AnalyticsServiceMock();
};

export { getUserAnonymousId };

export default AnalyticsServiceFactory;
