import styled, { css } from 'styled-components';
import { breakpoints, colors, spaces } from '../../styles/style';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${props => (props.$borderRadius ? props.$borderRadius : spaces.space0)};
  width: ${props => (props.width ? props.width : '100%')};

  height: ${props => (props.$fullHeight ? '100%' : props.height)};
    ${props =>
      !props.$noBorder &&
      css`
          border${props.$borderOnly && `-${props.$borderOnly}`}: 1px solid ${
        props.$borderColor ? props.$borderColor : colors.neutral100
      };
      `};
    ${props =>
      props.$maxWidth &&
      css`
        max-width: ${props.$maxWidth};
      `}
    ${props =>
      props.margin &&
      css`
        margin: ${props.margin};
      `}
    ${props =>
      props.$withShadow &&
      css`
        box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
      `}
    ${props =>
      props.onClick &&
      props.hover &&
      css`
        cursor: pointer;
      `};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify || 'center'};
  padding: ${props => (props.padding ? props.padding : spaces.space2)};
  height: 100%;
  border-radius: ${spaces.space0};
  background-color: ${props => (props.$backgroundColor ? props.$backgroundColor : colors.white)};
`;

export const Footer = styled.div`
  padding: ${props => (props.padding ? props.padding : spaces.space2)};
  border-top: 1px solid ${colors.neutral100};
  background-color: ${props => (props.$backgroundColor ? props.$backgroundColor : colors.white)};

  @media (min-width: ${breakpoints.tablet}) {
    ${props =>
      props.$footerHover &&
      css`
        :hover {
          cursor: pointer;

          background-color: ${colors.neutral75};
        }
      `}
  }
`;

export const Header = styled.div`
  padding: ${props => (props.padding ? props.padding : spaces.space2)};
  border-bottom: 1px solid ${colors.neutral100};
  background-color: ${props => (props.$backgroundColor ? props.$backgroundColor : colors.white)};
`;
