import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import useCRUD from './useCRUD';

const useTemplatePlanningDrawer = ({
  data,
  setIsSubmitting,
  setHasChanges,
  setSelectedItems,
  afterSubmit,
  ganttInstance
}) => {
  const [ganttChanges, setGanttChanges] = useState({
    updatedLinks: {},
    deletedLinks: {},
    createdLinks: {}
  });

  const { handleUpdate } = useCRUD({
    model: 'template-item',
    pathOptions: `/bulkUpdateWithPlanningLinks`,
    immediatelyLoadData: false
  });

  const prepareData = parsedData => {
    const { responsible, supplier, quantity, code, parent, type: _type, user, ...rest } = parsedData;
    return { ...rest, plIdResponsible: responsible?.id || null };
  };

  const handleSubmit = submitData => {
    setIsSubmitting(true);
    const preparedData = prepareData({ ...submitData, isCurrentTask: true });

    const payload = {
      ...ganttChanges,
      updatedTasks: {
        [data?.id]: preparedData
      }
    };

    handleUpdate({
      values: { ...payload, idTemplate: data?.idTemplate, from: 'Drawer' },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  const updateLink = (link, updates) => {
    const updatedItem = { ...link, ...updates };
    setGanttChanges(prev => {
      if (prev.createdLinks[link?.id]) {
        return {
          ...prev,
          createdLinks: { ...prev.createdLinks, [link?.id]: updatedItem }
        };
      }
      return {
        ...prev,
        updatedLinks: { ...prev.updatedLinks, [link?.id]: updatedItem }
      };
    });

    setHasChanges(true);
    setSelectedItems(prev =>
      prev?.map(item => {
        if (item?.id === link?.id) {
          return updatedItem;
        }
        return item;
      })
    );
  };

  const removeLink = link => {
    setGanttChanges(prev => {
      const { updatedLinks, createdLinks, deletedLinks, ...rest } = prev;
      const newUpdatedLinks = { ...updatedLinks };
      const newCreatedLinks = { ...createdLinks };
      const newDeletedLinks = { ...deletedLinks };

      if (createdLinks[link?.id]) {
        // If the link was in createdLinks, just remove it from there
        delete newCreatedLinks[link?.id];
      } else {
        // If it wasn't in createdLinks, remove from updatedLinks and add to deletedLinks
        delete newUpdatedLinks[link?.id];
        newDeletedLinks[link?.id] = link;
      }

      return {
        ...rest,
        updatedLinks: newUpdatedLinks,
        createdLinks: newCreatedLinks,
        deletedLinks: newDeletedLinks
      };
    });

    setHasChanges(true);
    setSelectedItems(prev => prev.filter(item => item?.id !== link?.id));
  };

  const createLink = selectedItemId => {
    setHasChanges(true);

    const linkId = uuidV4();

    const newLink = {
      source: Number(selectedItemId),
      target: data.id,
      type: ganttInstance.config.links.finish_to_start,
      lag: null,
      id: linkId
    };

    const newItem = {
      source: Number(selectedItemId),
      lag: null,
      target: data?.id,
      type: ganttInstance.config.links.finish_to_start,
      id: linkId
    };
    setSelectedItems(prev => [...prev, newItem]);
    setGanttChanges(prev => ({
      ...prev,
      createdLinks: { ...prev.createdLinks, [linkId]: newLink }
    }));
  };

  return {
    createLink,
    removeLink,
    updateLink,
    handleSubmit,
    ganttInstance
  };
};

export default useTemplatePlanningDrawer;
