import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faBadgePercent, faTableColumns } from '@fortawesome/pro-solid-svg-icons';

import { colors, fonts, spaces, radius, Div } from '../../styles/style';
import NewMaskedInput from '../Input/NewMaskedInput';
import formatBdi from '../../lib/helpers/formatBdi';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { Paragraph, Subtitle } from '../Text/Text';
import ConfigModal from './ConfigModal';
import TooltipIcon from '../Tooltip/TooltipIcon';

const BdiContent = styled.div`
  margin: ${spaces.space3} ${spaces.space2} ${spaces.space4} ${spaces.space2};

  .bdi-info-container {
    display: flex;
    align-items: center;
  }

  .bdi-input-group-container {
    margin-top: ${spaces.space4};
    display: flex;
    align-items: center;
    gap: ${spaces.space2};
  }

  .bdi-input-container {
    width: 96px;
    display: flex;
    gap: ${spaces.space1};
    flex-direction: column;
  }
`;

const SpecificationConfigModal = ({ onClose, onSubmit, data }) => {
  const { productBdi, laborBdi, clientColumns, myColumns } = data;
  const { columnsOptions } = useSelector(state => state.setup.systemData);
  const [_productBdi, setProductBdi] = useState(productBdi);
  const [_laborBdi, setLaborBdi] = useState(laborBdi);
  const [_clientColumns, setClientColumns] = useState(clientColumns);
  const [_myColumns, setMyColumns] = useState(myColumns);

  const handleChangeCheckbox = (columnType, key) => e => {
    const setColumn = columnType === 'myColumns' ? setMyColumns : setClientColumns;
    setColumn(prev => ({ ...prev, [key]: e.target.checked }));
  };

  const BdiInput = ({ ...props }) => {
    return (
      <NewMaskedInput
        style={{ borderRadius: radius.radius1 }}
        placeholder="0"
        type="number"
        maskOptions={{ suffix: '%' }}
        {...props}
      />
    );
  };

  const bdiTooltip = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>
        Produtos e serviços configurados com uma taxa diferente manualmente não serão afetados pela configuração geral
        do BDI
      </span>
      <br />
      <span>Se a taxa configurada for zero, o BDI não influenciará no seu orçamento</span>
    </div>
  );

  const bdiContent = (
    <BdiContent>
      <div className="bdi-info-container">
        <Subtitle type="secondary">Aplique a taxa de BDI padrão sobre os itens desse orçamento</Subtitle>
        <Div margin={spaces.space1}>
          <TooltipIcon
            text={bdiTooltip}
            tooltipProps={{
              placement: 'right',
              overlayStyle: { maxWidth: '350px' }
            }}
            icon={faInfoCircle}
            color={colors.primary600}
          />
        </Div>
      </div>
      <Paragraph type="small">
        O BDI funciona como uma taxa percentual sobre o custo dos itens. Você pode utilizá-lo para adicionar a sua
        margem de lucro e/ou outros custos para compor o preço final do seu orçamento.
      </Paragraph>
      <div className="bdi-input-group-container">
        <div className="bdi-input-container">
          <Paragraph>BDI Produtos</Paragraph>
          <BdiInput
            value={_productBdi ? formatCurrency(_productBdi) : null}
            onBlur={e => setProductBdi(formatBdi(e?.target?.value))}
            id="product-bdi-input"
          />
        </div>
        <div className="bdi-input-container">
          <Paragraph>BDI Serviços</Paragraph>
          <BdiInput
            value={_laborBdi ? formatCurrency(_laborBdi) : null}
            onBlur={e => setLaborBdi(formatBdi(e?.target?.value))}
            id="labor-bdi-input"
          />
        </div>
        <Div $alignSelf="flex-end">
          <HowDoesItWorksButton
            id="howBdiWorks"
            iconStyle={{
              color: colors.primary600,
              fontSize: fonts.sizeSm
            }}
            padding="0"
          />
        </Div>
      </div>
    </BdiContent>
  );

  const tabs = [
    {
      key: 'columns',
      title: 'Colunas',
      icon: faTableColumns,
      warningText: 'Alterar colunas atualizará a visão do orçamento para todos da sua equipe.',
      myColumnTooltip: 'Informações habilitadas serão exibidas na sua tabela de orçamento',
      clientColumnTooltip: 'Permitir cliente visualizar as informações selecionadas',
      columns: columnsOptions,
      checkedMap: { myColumns: _myColumns, clientColumns: _clientColumns }
    },
    {
      key: 'bdi',
      title: 'BDI',
      icon: faBadgePercent,
      custom: bdiContent
    }
  ];

  return (
    <ConfigModal
      modalTitle="Configurar orçamento"
      tabs={tabs}
      handleChangeCheckbox={handleChangeCheckbox}
      data={data}
      onSubmit={() => {
        onSubmit({
          clientColumns: _clientColumns,
          myColumns: _myColumns,
          laborBdi: _laborBdi,
          productBdi: _productBdi
        });
      }}
      onClose={onClose}
    />
  );
};

SpecificationConfigModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default SpecificationConfigModal;
