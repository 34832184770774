import * as Yup from 'yup';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { colors } from '../../../styles/style';
import { number, date } from '../schema';

const planningSchema = Yup.object().shape({
  plStartDate: date,
  plEndDate: date,
  duration: number,
  responsible: Yup.object().nullable()
});

const planningMapping = ({
  parent,
  supplier,
  onChangeDateRelatedField,
  workDays,
  dayoffs,
  isTemplate,
  isMobile,
  userType
}) => ({ plStartDate }) => {
  return {
    ...(!isTemplate && {
      plStartDate: {
        name: 'Data de início',
        type: 'date',
        placeholder: 'Selecionar data',
        icon: faCalendar,
        iconColor: colors.neutral400,
        md: 12,
        tooltip: false,
        clearable: true,
        externalOnChange: !isTemplate ? onChangeDateRelatedField('plStartDate') : undefined,
        externalOnBlur: !isTemplate ? onChangeDateRelatedField('plStartDate') : undefined,
        workDays,
        dayoffs
      },
      plEndDate: {
        name: 'Data de fim',
        type: 'date',
        icon: faCalendar,
        placeholder: 'Selecionar data',
        iconColor: colors.neutral400,
        md: 12,
        tooltip: false,
        clearable: true,
        externalOnChange: !isTemplate ? onChangeDateRelatedField('plEndDate') : undefined,
        externalOnBlur: !isTemplate ? onChangeDateRelatedField('plEndDate') : undefined,
        workDays,
        dayoffs,
        minDate: plStartDate
      }
    }),
    duration: {
      name: 'Duração',
      type: 'number',
      md: 12,
      tooltip: false,
      unit: 'dias',
      placeholder: '0',
      decimalCount: 0,
      clearable: true,
      externalOnChange: !isTemplate ? onChangeDateRelatedField('duration') : undefined,
      externalOnBlur: !isTemplate ? onChangeDateRelatedField('duration') : undefined
    },
    responsible: {
      name: 'Responsável',
      type: 'select',
      valueField: 'id',
      displayField: 'name',
      model: 'user',
      modelOptions: {
        where: { isActive: true, userType: [userType?.provider?.value, userType?.guest?.value] },
        order: [['name']]
      },
      extraPropsOnOptions: ['id'],
      sendFullObject: true,
      placeholder: 'Selecionar responsável',
      hasChildren: true,
      md: 12,
      clearable: true,
      tooltip: false
    },
    code: {
      name: 'Código',
      type: 'text',
      md: 12,
      readOnly: true,
      startHideOnMobile: true
    },
    quantity: {
      name: 'Quantidade',
      type: 'number',
      md: 12,
      readOnly: true,
      startHideOnMobile: true
    },
    parent: {
      name: 'Nível/Subnível',
      type: 'text',
      md: 12,
      readOnly: true,
      value: parent?.name,
      icon: faCircleSmall,
      iconColor: parent?.color,
      tooltip: true,
      startHideOnMobile: true
    },
    supplier: {
      name: 'Fornecedor',
      type: 'text',
      md: 12,
      readOnly: true,
      value: supplier?.name,
      startHideOnMobile: true
    },
    description: {
      name: isMobile ? 'Descrição' : '',
      type: 'textarea',
      fullWidth: true,
      titleLabel: true,
      placeholder: 'Adicione uma descrição...'
    }
  };
};

export { planningSchema, planningMapping };
