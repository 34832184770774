import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import * as dayjs from 'dayjs';
import useCRUD from '../_Hooks/useCRUD';
import { updatePermissions, updateCompany, updateUser } from '../store/auth/actions/action';

import RefurbishListModal from '../components/Modal/RefurbishListModal';

import css from './Main.module.scss';
import CenteredLoader from '../components/Loader/CenteredLoader';
import { saveRefurbish } from '../store/refurbish/actions/action';
import flowSteps from './Onboard/ProviderSteps/steps';

const stepKeys = Object.keys(flowSteps);
const firstStep = stepKeys[0];

const Main = ({ toggle, noShowCollapse, redirectUrl, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;
  const { user } = useSelector(state => state.authReducer);
  const refurbish = useSelector(state => state.refurbishReducer);
  const { companyStatus, userType, planType } = useSelector(state => state.setup.enums);
  const isProvider = user.userType === userType.provider.value;
  const isCustomer = user.userType === userType.customer.value;

  const classes = classNames({
    [css['c-main']]: true,
    [css['c-main--collapse']]: toggle && !noShowCollapse,
    [css['c-main--background-white']]: true
  });

  const options =
    isProvider || user.isCustomerView
      ? {
          include: ['permissions']
        }
      : {
          include: [
            {
              model: 'refurbish',
              where: { isShared: true },
              required: false,
              include: [
                { model: 'user', where: { userType: userType.provider.value }, required: false },
                'refurbishDayoffs'
              ]
            }
          ]
        };
  const pathOptions = `/${user.id}`;
  const { data: userData, handleGet: getUser } = useCRUD({
    model: 'user',
    options,
    pathOptions,
    immediatelyLoadData: false
  });
  const { data: company, handleGet: getCompany, handleUpdate } = useCRUD({
    model: 'company',
    immediatelyLoadData: false
  });

  const { refurbishes = [] } = userData || {};

  useEffect(() => {
    if (user?.anonymous) return;

    getUser().then(_user => {
      if (!_user || _user?.error) history.push(`/logout`);

      const fetchCompany = _user?.idCompany
        ? getCompany({
            refetchPathOptions: `/${_user.idCompany}`,
            refetchOptions: {
              include: ['permissions', 'companyDayoffs']
            }
          })
        : Promise.resolve();

      return fetchCompany.then(_company => {
        if (_company?.error) history.push(`/logout`);

        const { permissions, refurbishes: resRefurbishes } = _user || {};

        if (isProvider || user.isCustomerView) {
          const permissionMap = permissions?.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {});
          if (
            user?.permissions?.length !== permissions?.length ||
            !user?.permissions?.every(p => permissionMap[p.id])
          ) {
            dispatch(updatePermissions(permissions));
          }

          if (_company) {
            dispatch(updateCompany(_company));
          }
        }

        if (!user.isCustomerView && isCustomer) {
          const selectedRefurbish = resRefurbishes?.find(r => r.id === refurbish?.id);

          if (JSON.stringify(selectedRefurbish?.itemsToShare || {}) !== JSON.stringify(refurbish?.itemsToShare || {})) {
            dispatch(saveRefurbish(selectedRefurbish || {}));
          }
        }

        dispatch(updateUser({ ...user, company: _company, configuration: _user?.configuration }));
      });
    });
  }, []);

  useEffect(() => {
    if (company?.endAccess) {
      if (dayjs(company.endAccess).isBefore(dayjs(new Date(new Date().setHours(0, 0, 0, 0))))) {
        handleUpdate({
          values: { idPlan: planType.free.value, endAccess: null },
          updatePathOptions: `/${company.id}/change-plan`,
          refresh: false
        }).then(() => {
          history.push(`/logout`);
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (!user?.jwt) return;
    const loginIframe = document.getElementById('loginIframe')?.contentWindow;
    if (loginIframe) loginIframe.postMessage(user.jwt, '*');
  }, [location]);

  if (redirectUrl?.startsWith(process.env.REACT_APP_BUBBLE_URL)) {
    window.location.replace(redirectUrl);
    return null;
  }

  if (redirectUrl) {
    history.push(redirectUrl);
    return null;
  }
  if ((!userData || (userData.idCompany && !company)) && !user?.anonymous) return <CenteredLoader />;

  if (isCustomer && userData && !refurbishes?.length && !user.isCustomerView) {
    toast.error('Nenhum projeto compartilhado com você. Contate a empresa que compartilhou o link com você.', {
      autoClose: '5000'
    });
    return <Redirect to="/logout" />;
  }

  if (isProvider && !user.accessedBy?.id) {
    if (!company) {
      return <Redirect to="/logout" />;
    }

    if (company?.idStatus === companyStatus?.onBoarding?.id) {
      return <Redirect to="/informacoes-adicionais" />;
    }

    if (company && !company?.onboarding?.finished) {
      return <Redirect to={`/onboarding/${firstStep}`} />;
    }

    if (company?.idStatus !== companyStatus?.active.id) {
      toast.error('Sua conta está bloqueada, entre em contato conosco para resolvermos a situação.', {
        autoClose: '5000'
      });
      return <Redirect to="/logout" />;
    }
  }

  return (
    <>
      <RefurbishListModal />
      <div id="main" className={classes}>
        {children}
      </div>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  noShowCollapse: PropTypes.bool,
  toggle: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string
};

export default Main;
