import * as Yup from 'yup';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import formatNumber from '../../helpers/formatNumber';
import formatCurrency from '../../helpers/formatCurrency';
// import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
// import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
// import { colors } from '../../../styles/style';
// import { number, date } from '../schema';

const itemSchemaV2 = Yup.object();

const itemMappingV2 = ({
  isTemplate,
  isMobile,
  refurbishItemTypeEnum,
  idReference,
  type,
  idCompany,
  linkedItem
}) => values => {
  const model = isTemplate ? 'templateItem' : 'refurbishItems';
  return {
    idParent: {
      name: 'Nível/Subnível',
      type: 'treeSelect',
      displayStringAs: 'name',
      placeholder: 'Nível',
      model,
      modelOptions: {
        attributes: ['name', 'id', 'type'],
        where: {
          isActive: true,
          type: refurbishItemTypeEnum?.parent,
          idParent: null,
          [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference
        },
        include: [
          {
            model,
            as: 'children',
            attributes: ['name', 'id', 'type'],
            required: false,
            where: {
              isActive: true,
              type: refurbishItemTypeEnum?.parent
            }
          }
        ],
        order: [['order']]
      }
    },
    code: {
      name: 'Código',
      type: 'text',
      placeholder: `123`,
      disabled: !!linkedItem
    },
    quantity: {
      dataIndex: 'quantity',
      name: 'Quantidade prevista',
      type: 'number',
      decimalCount: 0,
      placeholder: '1',
      clearable: true
    },
    idUnit: {
      name: 'Unidade',
      type: 'select',
      placeholder: 'Un.',
      model: 'unit',
      modelOptions: {
        where: { idCompany, [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference },
        order: [['name', 'asc']]
      },
      clearable: true,
      readOnly: !!linkedItem
    },
    price: {
      dataIndex: 'price',
      name: 'Custo unitário',
      type: 'currency',
      prefix: 'R$',
      placeholder: '0,00',
      defaultValue: '0,00'
    },
    bdi: {
      dataIndex: 'bdi',
      name: 'BDI',
      type: 'currency',
      mask: 'number',
      placeholder: '0,00',
      prefix: '%'
    },
    totalPrice: {
      name: 'Preço unitário',
      type: 'currency',
      prefix: 'R$',
      readOnly: true,
      value: formatCurrency((1 + formatNumber(values?.bdi || 0) / 100) * formatNumber(values?.price || 0)),
      placeholder: '0,00',
      defaultValue: '0,00',
      nameTooltip: 'Custo unitário x BDI',
      maskOptions: { allowNegative: true }
    },
    idSupplier: {
      name: 'Fornecedor',
      type: 'select',
      model: 'supplier',
      modelOptions: { onlyMine: true, order: [['name']] },
      aliasOptions: ({ label }) => label,
      placeholder: 'Selecione',
      clearable: true
    },
    idCostCenter: {
      name: 'Categoria',
      type: 'select',
      placeholder: 'Categoria',
      model: 'costCenter',
      clearable: true,
      modelOptions: { onlyMine: true, where: { idCompany: idCompany || null }, order: [['name']] }
    },
    idRefurbishGroup: {
      dataIndex: 'idRefurbishGroup',
      name: 'Grupo',
      type: 'select',
      displayStringAs: 'name',
      placeholder: 'Grupo',
      model: 'refurbishGroup',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      clearable: true
    },
    brand: {
      dataIndex: 'brand',
      name: 'Marca',
      type: 'text',
      placeholder: 'Digite o nome da marca',
      clearable: true,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    link: {
      dataIndex: 'link',
      name: 'Link',
      type: 'text',
      placeholder: `Insira o link do ${type === refurbishItemTypeEnum.product ? 'produto' : 'serviço'}`,
      icon: faLink
    },
    height: {
      dataIndex: 'height',
      name: 'Altura',
      type: 'currency',
      placeholder: '0',
      suffix: 'cm',
      startHide: true,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    width: {
      dataIndex: 'width',
      name: 'Largura',
      type: 'currency',
      placeholder: '0',
      suffix: 'cm',
      startHide: true,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    length: {
      dataIndex: 'length',
      name: 'Comprimento',
      type: 'currency',
      placeholder: '0',
      suffix: 'cm',
      withoutArrows: true,
      startHide: true,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    weight: {
      dataIndex: 'weight',
      name: 'Peso',
      type: 'currency',
      placeholder: '0',
      suffix: 'kg',
      withoutArrows: true,
      startHide: true,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    description: {
      name: isMobile ? 'Descrição' : '',
      type: 'textarea',
      fullWidth: true,
      titleLabel: true,
      placeholder: 'Adicione uma descrição...'
    }
  };
};

export { itemSchemaV2, itemMappingV2 };
