import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import Dropdown from './Dropdown';
import Button from '../Button/Button';
import TemplatesModal from '../Modal/TemplatesModal';
import ScheduleDrawer from '../Drawer/ScheduleDrawer';
import { spaces } from '../../styles/style';
import Bill from '../../_Pages/Payments/Bill';
import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import Payment from '../../_Pages/Payments/Payment';
import TransferDrawer from '../Drawer/TransferDrawer';
import ImportInvoiceXMLModal from '../Modal/ImportInvoiceXMLModal';

const QuickCreateActions = () => {
  const [callbackModal, setCallbackModal] = useState(null);
  return (
    <Dropdown
      alignLeftOptions
      mappingKey="quickCreateActions"
      trigger={['click']}
      withoutZIndex
      callbackModal={callbackModal}
      modal={{
        templateModal: params => {
          return <TemplatesModal {...params} isRefurbish />;
        },
        editTaskModal: params => {
          return (
            <ScheduleDrawer
              {...params}
              open
              generalTask
              dataGantt={{ data: [], links: [] }}
              idReference={null}
              closeAfterSubmit
              afterSubmit={() => {
                toast.success('Tarefa criada com sucesso');
              }}
            />
          );
        },
        newPaymentDrawer: params => <Payment {...params} action="novo" />,
        newBillDrawer: params => <Bill {...params} action="novo" />,
        newTransferDrawer: params => <TransferDrawer {...params} action="novo" />,
        createCustomerDrawer: params => <EditOrCreateCustomerOrSupplier model="company-customer" {...params} />,
        createSupplierDrawer: params => <EditOrCreateCustomerOrSupplier model="supplier" {...params} />,
        importXml: params => (
          <ImportInvoiceXMLModal
            onImportSuccess={(data, type) => {
              setCallbackModal({
                modalParams: { paymentToCreate: data, showProductsConciliation: true, billType: type },
                modal: type === 'expense' ? 'newBillDrawer' : 'newPaymentDrawer'
              });
            }}
            {...params}
          />
        )
      }}
    >
      <Button
        type="primary"
        style={{ width: spaces.space10, height: spaces.space4, justifyContent: 'flex-start', padding: spaces.space1 }}
        id="quick-create-actions-button"
        icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: spaces.space1 }} />}
      >
        Novo
      </Button>
    </Dropdown>
  );
};

export default QuickCreateActions;
