import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { colors } from '../../styles/style';

const type = 'DraggableRow';

const StyledRow = styled.tr`
  min-height: 50px;

  &.ant-table-row.drop-over-downward {
    border-bottom: 2px solid ${colors.primary600};
    position: relative;

    &:before {
      content: ' ';
      border-style: solid;
      border-top: 8px solid transparent;
      border-bottom: 0px solid transparent;
      border-left: 16px solid ${colors.primary600};
      border-right: 0;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &.ant-table-row.drop-over-upward {
    border-top: 2px solid ${colors.primary600};
    position: relative;

    &:before {
      content: ' ';
      border-style: solid;
      border-top: 0px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 16px solid ${colors.primary600};
      border-right: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &.expanded-row.dropping-into-folder,
  &.ant-table-row.dropping-into-folder + .expanded-row {
    opacity: 0.5;
  }

  &.dropping-out-folder {
    opacity: 0.5 !important;
  }
`;

const DraggableRow = ({
  index,
  moveRow,
  record,
  isExpanded,
  className,
  style,
  parentKey = 'idParent',
  childrenColumnName,
  disableDropOnParent,
  ...restProps
}) => {
  const ref = useRef();
  const { refurbishItemType } = useSelector(state => state.setup.enums);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex, record: itemRecord } = monitor.getItem() || {};
      const itemRecordParentKey = itemRecord?.[parentKey];
      const isFolder = itemRecord?.type === refurbishItemType.parent;
      const ownerKey = Number(restProps?.children?._owner?.return?.key);
      const sameParent = itemRecordParentKey === record?.[parentKey] || itemRecordParentKey === ownerKey;

      if (itemRecord?.id === record?.id || !itemRecord) {
        return {};
      }

      if ((!record && !sameParent && !isFolder) || isExpanded) {
        return {
          isOver: monitor.isOver(),
          dropClassName: 'dropping-into-folder'
        };
      }

      // moving out of parent
      if (!ownerKey && !sameParent) {
        return {
          isOver: monitor.isOver(),
          dropClassName: 'dropping-out-folder'
        };
      }

      if (!sameParent) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? 'drop-over-downward' : 'drop-over-upward'
      };
    },
    drop: item => {
      item.record && record && moveRow && moveRow(item, { ...record, isExpanded, index });
    }
  });

  const [, drag] = useDrag({
    item: { type, index, record },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <StyledRow
      key={`row${record?.id}`}
      ref={ref}
      className={`${className} ${isOver ? dropClassName : ''} ${isExpanded ? 'expanded-parent' : ''}`}
      style={{ cursor: record ? 'grab' : '', ...style }}
      {...restProps}
    />
  );
};

DraggableRow.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moveRow: PropTypes.func,
  record: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  parentKey: PropTypes.string,
  childrenColumnName: PropTypes.string,
  disableDropOnParent: PropTypes.bool
};

export default DraggableRow;
