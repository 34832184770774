import React from 'react';
import MobileCardListV2 from './MobileCardListV2';
import useViewport from '../../_Hooks/useViewport';
import VirtualizedTable from '../Table/VirtualizedTable';

const TableOrListV2 = ({ ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const RenderList = isMobile() ? MobileCardListV2 : VirtualizedTable;

  return <RenderList {...props} />;
};

export default TableOrListV2;
