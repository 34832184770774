import dayjs from 'dayjs';
import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { formatDocument, formatPhoneNumber, formatZipCode } from '../../helpers/helper';
import formatCurrency from '../../helpers/formatCurrency';
import { Paragraph } from '../../../components/Text/Text';
import { Div, colors, spaces } from '../../../styles/style';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';

export const invoiceInfo = {
  natOp: {
    label: (
      <Div gap={spaces.space0}>
        <Paragraph type="small">Natureza da operação</Paragraph>
        <TooltipIcon
          iconColor={colors.primary500}
          icon={faInfoCircle}
          text="Indica a finalidade da emissão da nota, ou seja, a que operação se destina "
          style={{ padding: spaces.space0 }}
        />
      </Div>
    )
  },
  serie: { label: 'Série/NFe' },
  dhEmi: { label: 'Data da emissão', format: value => dayjs(value).format('DD/MM/YYYY') }
};

export const emit = {
  xNome: { label: 'Razão social' },
  xFant: { label: 'Nome fantasia' },
  document: { label: 'CNPJ', format: value => formatDocument(value) || '-' },
  IE: { label: 'Inscrição estadual' },
  CEP: {
    label: 'CEP',
    format: value => {
      if (!value) return '-';
      const numeric = value?.replace(/\D/g, '');
      return `${numeric?.substr(0, 5)}-${numeric?.substr(5, 3)}`;
    }
  },
  xLgr: { label: 'Endereço' },
  nro: { label: 'Número' },
  xCpl: { label: 'Complemento' },
  xBairro: { label: 'Bairro' },
  xMun: { label: 'Cidade' },
  UF: { label: 'Estado' },
  fone: { label: 'Telefone', format: value => formatPhoneNumber(value) }
};

export const dest = {
  xNome: { label: 'Nome/Razão social' },
  document: { label: 'CPF/CNPJ', format: value => formatDocument(value) || '-' },
  CEP: {
    label: 'CEP',
    format: value => {
      if (!value) return '-';
      return formatZipCode(value);
    }
  },
  xLgr: { label: 'Endereço' },
  nro: { label: 'Número' },
  xCpl: { label: 'Complemento' },
  xBairro: { label: 'Bairro' },
  xMun: { label: 'Cidade' },
  UF: { label: 'Estado' },
  fone: { label: 'Telefone', format: value => formatPhoneNumber(value) },
  email: { label: 'Email' }
};

export const location = {
  CEP: { label: 'CEP' },
  xLgr: { label: 'Endereço' },
  nro: { label: 'Número' },
  xCpl: { label: 'Complemento' },
  xBairro: { label: 'Bairro' },
  xMun: { label: 'Cidade' },
  UF: { label: 'Estado' }
};

export const shippingCompany = mod => ({
  modFrete: { label: 'Modalidade do frete', format: value => mod?.[Number(value)] || '-' },
  xNome: { label: 'Nome/Razão social' },
  CNPJ: { label: 'CPF/CNPJ', format: value => formatDocument(value) || '-' },
  IE: { label: 'Inscrição estadual' }
});

export const invoiceTotals = {
  vProd: {
    label: 'Produtos',
    format: value => (
      <Paragraph type="small">
        <strong>{formatCurrency(value || 0, { currencySymbol: 'R$' })}</strong>
      </Paragraph>
    )
  },
  vFrete: { label: 'Frete', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vSeg: { label: 'Seguro', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vDesc: { label: 'Desconto', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vII: { label: 'II', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vIPI: { label: 'IPI', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vPIS: { label: 'PIS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vCOFINS: { label: 'COFINS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vOutro: { label: 'Outras despesas', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vNF: {
    label: 'Total',
    format: value => (
      <Paragraph type="small">
        <strong>{formatCurrency(value || 0, { currencySymbol: 'R$' })}</strong>
      </Paragraph>
    )
  }
};
