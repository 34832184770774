import React from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo, faTrashCan, faAdd } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import TooltipIcon from '../Tooltip/TooltipIcon';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import { Paragraph, Subtitle } from '../Text/Text';

import { colors, spaces, Div, fonts } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';

const PredecessorsSection = ({
  showComponents,
  setShowComponents,
  selectedItems,
  list,
  updateLink,
  removeLink,
  createLink,
  ganttInstance,
  stepIdKey,
  refurbishItemType,
  childrenKey,
  id
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const { userType } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const isCustomer = user.userType === userType.customer.value;

  const onEmpty = isCustomer ? <Paragraph>Nenhuma predecessora adicionada.</Paragraph> : null;

  const typeOptions = [
    { name: _isMobile ? 'FI' : 'Fim - Início', value: ganttInstance.config.links.finish_to_start },
    { name: _isMobile ? 'FF' : 'Fim - Fim', value: ganttInstance.config.links.finish_to_finish },
    { name: _isMobile ? 'II' : 'Início - Início', value: ganttInstance.config.links.start_to_start },
    { name: _isMobile ? 'IF' : 'Início - Fim', value: ganttInstance.config.links.start_to_finish }
  ];

  const getLevelListAndColorsFromItems = (listToParse, actualItem, parentColor) => {
    if (!listToParse || listToParse?.length === 0) return [];

    const unscheduledTasks = ganttInstance.getTaskBy(task => task.unscheduled);
    const modifiedTasks = [];

    unscheduledTasks.forEach(task => {
      const originalTask = { ...task };
      // eslint-disable-next-line no-param-reassign
      task.unscheduled = false;
      ganttInstance.refreshTask(task.id);
      modifiedTasks.push({ task, original: originalTask });
    });

    const result = listToParse.map(item => {
      const shouldDisableItem = (_item, targetId) => {
        if (_item?.id === targetId) return true;
        if (stepIdKey && !_item?.[stepIdKey]) return true;
        if (refurbishItemType && _item?.type === refurbishItemType?.parent) return true;

        const potentialLink = {
          source: Number(_item.id),
          target: Number(targetId),
          type: ganttInstance.config.links.finish_to_start
        };

        if (ganttInstance?.isCircularLink(potentialLink)) return true;

        const hasDisabledDescendants = _item?.[childrenKey]?.some(child => {
          return child?.id === targetId || shouldDisableItem(child, targetId);
        });
        return hasDisabledDescendants;
      };

      const isParent = shouldDisableItem(item, id);

      let itemColor = parentColor || colors.neutral300;

      if (stepIdKey && !item?.[stepIdKey]) {
        itemColor = item?.color || colors.neutral300;
      } else if (refurbishItemType && item?.type === refurbishItemType?.parent && !item?.idParent) {
        itemColor = item?.color || colors.neutral300;
      }

      const isSelected = selectedItems.some(selected => Number(selected?.source) === item?.id);

      return {
        value: `${item?.id}`,
        disabled: isParent || (isSelected && item?.id !== Number(actualItem)),
        title: (
          <Div $fullWidth $fullHeight justify="space-between">
            <Paragraph
              color={
                isParent || (isSelected && item?.id !== Number(actualItem)) ? colors.neutral400 : colors.neutral600
              }
              type="small"
              fo
              ellipsis
              weight={
                item?.id !== Number(actualItem) &&
                ((!stepIdKey && item?.type === refurbishItemType?.parent) || (stepIdKey && !item?.[stepIdKey]))
                  ? fonts.weight600
                  : fonts.weight500
              }
            >
              {item.name || '-'}
            </Paragraph>
            <Div padding={spaces.space0}>
              <FontAwesomeIcon color={itemColor} icon={faCircleSmall} />
            </Div>
          </Div>
        ),
        name: item?.name || '-',
        children: getLevelListAndColorsFromItems(item?.[childrenKey], actualItem, itemColor) || []
      };
    });

    modifiedTasks.forEach(({ task, original }) => {
      Object.assign(task, original);
      ganttInstance.refreshTask(task.id);
    });

    return result;
  };

  const renderListPredecessors = () => {
    const dependanceWidth = _isMobile ? '50%' : '65%';
    const typeWidth = '18%';
    let intervalWidth = _isMobile ? '15%' : '10%';
    if (isCustomer) intervalWidth = _isMobile ? '25%' : '15%';
    const deleteWidth = _isMobile ? '10%' : '5%';

    return (
      <Div margin={`${spaces.space1} 0 0 0`} gap={spaces.space1} $fullWidth direction="column">
        <Div $fullWidth gap={spaces.space1}>
          {showComponents?.showSelectPredecessors || selectedItems.length > 0 ? (
            <Div width={dependanceWidth} $minWidth={dependanceWidth}>
              <Paragraph type="small">Depende de</Paragraph>
            </Div>
          ) : null}

          {selectedItems?.length > 0 && (
            <>
              <Div width={typeWidth} $minWidth={typeWidth} gap={spaces.space0}>
                <Paragraph type="small">Tipo</Paragraph>
                <TooltipIcon
                  icon={faCircleInfo}
                  iconColor={colors.primary500}
                  text="Tipo da relação entre dois itens. Indica como o início ou o término de um item impacta o outro."
                />
              </Div>
              <Div width={intervalWidth} $minWidth={intervalWidth} gap={spaces.space0}>
                <Paragraph type="small">Intervalo</Paragraph>
                <TooltipIcon
                  icon={faCircleInfo}
                  iconColor={colors.primary500}
                  tooltipProps={{ placement: 'topRight' }}
                  text="Tempo de espera ou atraso entre dois itens. Ele pode ser positivo ou negativo,
                  determinando se um item deve ser adiado ou antecipado em relação ao outro."
                />
              </Div>
              <Div width={deleteWidth} $minWidth={deleteWidth}>
                <></>
              </Div>
            </>
          )}
        </Div>

        {selectedItems?.length > 0 &&
          selectedItems.map(link => {
            const { source, type: _type, lag } = link;
            return (
              <Div $fullWidth gap={spaces.space1} key={source}>
                <Div width={dependanceWidth}>
                  <TreeSelect
                    getPopupContainer={() => document.getElementById(`containerTreeSelect`)}
                    switcherIcon={({ expanded }) =>
                      expanded ? (
                        <FontAwesomeIcon color={colors.neutral500} icon={faCaretDown} />
                      ) : (
                        <FontAwesomeIcon color={colors.neutral500} icon={faCaretRight} />
                      )
                    }
                    showSearch
                    style={{ width: '100%' }}
                    className="tree-select-level"
                    treeDefaultExpandAll
                    value={source}
                    onChange={newSource => {
                      updateLink(link, { source: Number(newSource) });
                    }}
                    treeData={getLevelListAndColorsFromItems(list, source)}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    disabled={isCustomer}
                    treeNodeFilterProp="name"
                    onDropdownVisibleChange={() =>
                      setShowComponents({ ...showComponents, showSelectPredecessors: false })
                    }
                  />
                </Div>
                <Div width={typeWidth}>
                  <Select
                    name="idType"
                    options={typeOptions}
                    placeholder="Selecione..."
                    onChange={newType => {
                      updateLink(link, { type: newType });
                    }}
                    value={_type}
                    disabled={isCustomer}
                  />
                </Div>
                <Div width={intervalWidth}>
                  <Input
                    onChange={e => {
                      updateLink(link, { lag: Number(e.target.value) });
                    }}
                    value={lag}
                    placeholder={_isMobile ? '0' : '0 dias'}
                    type="number"
                    disabled={isCustomer}
                  />
                </Div>
                {!isCustomer && (
                  <Div width={deleteWidth}>
                    <Button
                      type="iconNeutral"
                      justify="center"
                      width={spaces.space4}
                      height={spaces.space4}
                      onClick={() => {
                        removeLink(link);
                      }}
                    >
                      <FontAwesomeIcon color={colors.neutral500} icon={faTrashCan} />
                    </Button>
                  </Div>
                )}
              </Div>
            );
          })}
      </Div>
    );
  };

  return (
    <Div padding={spaces.space2} $fullWidth>
      <Div direction="column" align="flex-start" $fullWidth gap={spaces.space1}>
        <Div gap={spaces.space0}>
          <Subtitle>{`${isCustomer ? 'P' : 'Configurar p'}redecessoras`}</Subtitle>
          <TooltipIcon
            icon={faCircleInfo}
            iconColor={colors.primary500}
            text="Após escolher o item relacionado e o tipo de dependência, as respectivas conexões
          aparecerão na visualização de Linha do Tempo do projeto e as datas serão sincronizadas
          de acordo com as datas e os intervalos definidos."
          />
        </Div>
        {selectedItems?.length > 0 || showComponents?.showSelectPredecessors ? renderListPredecessors() : onEmpty}
        {showComponents?.showSelectPredecessors && (
          <TreeSelect
            getPopupContainer={() => document.getElementById(`containerTreeSelect`)}
            switcherIcon={({ expanded }) =>
              expanded ? (
                <FontAwesomeIcon color={colors.neutral500} icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon color={colors.neutral500} icon={faCaretRight} />
              )
            }
            open={showComponents?.showSelectPredecessors}
            id="tree-select-level"
            showSearch
            className="tree-select-level"
            style={{ width: '100%' }}
            treeDefaultExpandAll
            onChange={createLink}
            treeData={getLevelListAndColorsFromItems(list)}
            onClick={e => {
              e.stopPropagation();
            }}
            disabled={isCustomer}
            treeNodeFilterProp="name"
            onDropdownVisibleChange={() => setShowComponents({ ...showComponents, showSelectPredecessors: false })}
          />
        )}

        {!isCustomer && (
          <Button
            text
            align="start"
            onClick={() => setShowComponents({ ...showComponents, showSelectPredecessors: true })}
          >
            <FontAwesomeIcon icon={faAdd} />
            Adicionar dependência
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default PredecessorsSection;

PredecessorsSection.propTypes = {
  showComponents: PropTypes.instanceOf(Object),
  selectedItems: PropTypes.instanceOf(Array),
  setShowComponents: PropTypes.func,
  stepIdKey: PropTypes.string,
  refurbishItemType: PropTypes.instanceOf(Object),
  childrenKey: PropTypes.string.isRequired,
  list: PropTypes.instanceOf(Array),
  updateLink: PropTypes.func,
  removeLink: PropTypes.func,
  createLink: PropTypes.func,
  ganttInstance: PropTypes.instanceOf(Object),
  id: PropTypes.number
};
