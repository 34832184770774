import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { faTrash, faDownload, faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paragraph } from '../Text/Text';
import { spaces } from '../../styles/style';
import Button from '../Button/Button';
import UnknownFileIcon from '../Icons/UnknownFile';
import CenteredLoader from '../Loader/CenteredLoader';

import css from './GalleryItem.module.scss';

const StyledGhostButton = styled(Button)`
  border-radius: ${spaces.space2};
  min-width: ${spaces.space11};
`;

const GalleryItem = ({ image, index, onRemove, onSelectImage, noDownload, noPreview, small, unique, className }) => {
  const classes = classNames({
    [css['c-gallery-item']]: true,
    [css['c-gallery-item--small']]: small,
    [css['c-gallery-item--unique']]: unique,
    [className]: true
  });

  return (
    <div className={classes}>
      {image.status === 'uploading' ? (
        <CenteredLoader text="" />
      ) : (
        <>
          <div className={css['c-gallery-item__bg']} />
          {image.fullpath && !image.fullpath.toLowerCase().match(/(jpg|jpeg|gif|png|webp)((\?.*)$|$)/gm) ? (
            <div>
              <UnknownFileIcon />
              <Paragraph type="small" ellipsis>
                {image.filename}
              </Paragraph>
            </div>
          ) : (
            <div
              style={{ backgroundImage: `url("${image.fullpath || image.src}")` }}
              className={css['c-gallery-item__img']}
            />
          )}
          <div className={css['c-gallery-item__hover']}>
            {!noPreview ? (
              <StyledGhostButton ghost type="white" size={1} onClick={() => onSelectImage(image, index)}>
                <FontAwesomeIcon icon={faSearchPlus} />
                Pré-visualizar
              </StyledGhostButton>
            ) : null}
            <span>
              {!noDownload ? (
                <a href={image.fullpath} target="_blank" rel="noopener noreferrer">
                  {small ? (
                    <Button type="primary" size={1}>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  ) : (
                    <StyledGhostButton ghost type="white" size={1}>
                      <FontAwesomeIcon icon={faDownload} />
                      Baixar
                    </StyledGhostButton>
                  )}
                </a>
              ) : null}
              <Button onClick={() => onRemove(index)} type="danger" size={1} id="remove-btn">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

GalleryItem.propTypes = {
  image: PropTypes.instanceOf(Object),
  onRemove: PropTypes.func,
  index: PropTypes.number,
  onSelectImage: PropTypes.func,
  small: PropTypes.bool,
  noDownload: PropTypes.bool,
  noPreview: PropTypes.bool,
  unique: PropTypes.bool,
  className: PropTypes.string
};

export default GalleryItem;
