import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import Button from '../Button/Button';
import { Paragraph } from '../Text/Text';
import { Div, spaces, fonts } from '../../styles/style';

const CompositionBreadcrumb = ({ list = [], maxItems = 3, onSelectItem = f => f }) => {
  return list && list.length ? (
    <Div gap={spaces.space1} padding={`${spaces.space2} 0`}>
      {list.map(({ name }, index) => {
        const last = list.length === index + 1;

        return index === 0 || index > list.length - maxItems ? (
          <>
            <Tooltip title={name} placement="bottom">
              <Button
                text
                $fontSize={fonts.sizeMd}
                type={last ? 'primary' : 'default'}
                padding={0}
                onClick={!last ? () => onSelectItem({ indexToGo: index }) : undefined}
              >
                <u>{name}</u>
              </Button>
            </Tooltip>
            {!last && (
              <Div>
                <FontAwesomeIcon icon={faChevronRight} />
              </Div>
            )}
            {index === 0 && list.length > maxItems && (
              <>
                <Div>
                  <Paragraph>...</Paragraph>
                </Div>
                <Div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Div>
              </>
            )}
          </>
        ) : null;
      })}
    </Div>
  ) : null;
};

CompositionBreadcrumb.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]),
  maxItems: PropTypes.number,
  onSelectItem: PropTypes.func
};

export default CompositionBreadcrumb;
