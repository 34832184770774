import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, spaces } from '../../styles/style';

export const StyledMenu = styled(Menu)`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: ${spaces.space1};
  background-color: ${colors.white};
  max-width: 136px;
  box-shadow: 0 ${spaces.space0} ${spaces.space1} 0 #00000024;
  z-index: 999;
`;

export const StyledColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: ${props => (props.open ? spaces.space2 : '12px')};
  height: ${props => (props.open ? spaces.space2 : '12px')};
  background-color: ${props => props.color || colors.white};
  margin: ${spaces.space0};
  cursor: pointer;
  box-shadow: ${props => (!props.color ? `0 ${spaces.space0} ${spaces.space1} 0 #00000024` : 'none')};

  ${props =>
    !props.disabled &&
    css`
      &:hover,
      &.selected {
        // aplica o estilo de hover quando a classe selected está presente
        margin: 1px;
        width: ${props.open ? '22px' : spaces.space2};
        height: ${props.open ? '22px' : spaces.space2};

        > div {
          display: block;
          border: 1px solid ${colors.white};
        }
      }
    `}
`;

export const StyledColorInline = styled.div`
  border-radius: 100%;
  width: ${props => (props.open ? spaces.space2 : '12px')};
  height: ${props => (props.open ? spaces.space2 : '12px')};
  background-color: ${props => props.color || colors.white};
  cursor: pointer;
  display: none;
`;

export const StyledBoxColor = styled.div`
  width: ${spaces.space3};
  height: ${spaces.space3};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconXmark = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${colors.neutral500};
  &:hover {
    color: ${colors.neutral600};
  }
`;
