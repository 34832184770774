import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';

import PurchaseHeader from '../../../components/Header/PurchaseHeader';
import { Div, colors, spaces } from '../../../styles/style';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import usePurchase from '../../../_Hooks/usePurchase';
import PurchaseForm from '../../../components/Form/PurchaseForm';
import PurchaseTable from '../../../components/Table/PurchaseTable';
import useItemWithAppropriation from '../../../_Hooks/useItemWithAppropriation';
import { Context } from '../../../contexts/GeneralContext';
import curry from '../../../lib/helpers/curry';
import { Subtitle } from '../../../components/Text/Text';
import { getObjectWithSelectedFields } from '../../../lib/helpers/helper';
import { Info } from '../../Payments/Payment.style';
import OrderInstallmentsComponent from './OrderInstallmentsComponent';
import PaymentOrderComponent from './PaymentOrderComponent';
import PaymentExtraValuesForm from '../../../components/Form/PaymentExtraValuesForm';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ConfirmModalAlertInfoBuilder from '../../../components/Modal/ConfirmModalAlertInfoBuilder';
import { ComponentToPdf } from '../../../lib/helpers/exportToPdf';
import useUrlParams from '../../../_Hooks/useUrlParams';
import { columns as purchasePdfColumns } from '../../../lib/mapping/TableOrList/purchasePdfColumns';
import Table from '../../../components/Table/Table';
import useViewport from '../../../_Hooks/useViewport';
import useCRUD from '../../../_Hooks/useCRUD';
import PurchaseOrderFooter from '../../../components/Footer/PurchaseOrderFooter';
import { saveHeaderName } from '../../../store/headerName/actions/action';
import ViewEntityInfo from '../../../components/Description/ViewEntityInfo';
import DeliveryAddressForm from '../../../components/Form/DeliveryAddressForm';
import ObservationAndFilesComponent from '../ObservationAndFilesComponent';
import Button from '../../../components/Button/Button';

const TitleText = ({ ...props }) => <Subtitle type="secondary" {...props} />;

const PurchaseOrder = ({ isView, isSupplierView }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { purchaseOrderStatus = {} } = useSelector(state => state.setup.enums || {});
  const { user = {} } = useSelector(state => state.authReducer) || {};
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const [list, setList] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [extraValueErrors, setExtraValueErrors] = useState();
  const [extraValues, setExtraValues] = useState({});
  const [installments, setInstallments] = useState([]);
  const [addressFormValues, setAddressFormValues] = useState({});
  const [saveAddressToProject, setSaveAddressToProject] = useState(false);
  const [idCompanyCustomer, setIdCompanyCustomer] = useState(null);
  const [companyCustomer, setCompanyCustomer] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isPaymentResponsibleValid, setIsPaymentResponsibleValid] = useState(false);
  const [linkedSolicitation, setLinkedSolicitation] = useState('');
  const [linkedQuote, setLinkedQuote] = useState('');
  const [files, setFiles] = useState([]);
  const [modalProps, setModalProps] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [code, setCode] = useState('');
  const [isDraft, setIsDraft] = useState(false);

  const { company: userCompany } = user || {};

  const baseUrl = '/profissional/gestao-de-compras/ordem-de-compras';

  const { foundParams, loading: loadingUrlParams, clearParams } = useUrlParams({
    urlParams: ['print']
  });

  const { itemObjectsMap, itemSettersMap } = useItemWithAppropriation();

  const forceAddressFormUpdate = useRef(false);
  const printComponentRef = useRef();

  const {
    data: order,
    loading,
    refurbish,
    idRefurbish,
    forceFormUpdate,
    canEditRefurbish,
    canEditSupplier,
    purchaseFormValues,
    modelGet: getOrder,
    setPurchaseFormValues,
    handleSave,
    validateSubmit,
    getCode,
    observation,
    setObservation
  } = usePurchase({
    model: 'order',
    modelOptions: {
      include: [
        'purchaseStatus',
        'refurbish',
        'company',
        'supplier',
        'responsible',
        'paymentTypes',
        'files',
        {
          model: 'companyCustomer',
          attributes: [
            'id',
            'name',
            'zipcode',
            'street',
            'number',
            'complement',
            'state',
            'city',
            'phone',
            'email',
            'doc'
          ]
        },
        {
          model: 'purchase',
          attributes: ['id', 'code']
        },
        {
          model: 'quote',
          attributes: ['id', 'code']
        },
        {
          model: 'orderItems',
          include: [
            {
              model: 'unit',
              as: 'units'
            },
            'item',
            ...(isSupplierView
              ? []
              : [
                  {
                    model: 'orderItemLevel',
                    include: ['refurbishItem'],
                    attributes: [
                      'id',
                      'name',
                      'price',
                      'quantity',
                      'percentage',
                      'idOrderItem',
                      'idRefurbishItem',
                      'type',
                      'idParent'
                    ]
                  }
                ])
          ]
        },
        { model: 'payment', as: 'payment', include: ['installments'] }
      ]
    },
    list,
    setList,
    baseUrl,
    isReview,
    itemObjectsMap,
    setAddressFormValues,
    forceAddressFormUpdate,
    setLinkedSolicitation,
    setLinkedQuote,
    setModalProps,
    setExtraValues
  });

  const { handleGet: getCompanyCustomer } = useCRUD({
    model: 'companyCustomer',
    immediatelyLoadData: false
  });

  const isEditable = purchaseOrderStatus?.editable?.includes(order?.idOrderStatus);
  const readOnly = isSupplierView || isView || isReview || (id && !isEditable);

  const isLoadingForPrint = loading || loadingUrlParams || isOrderLoading || isAddressLoading;

  useEffect(() => {
    if (order) {
      setExtraValues(order?.extraValues || {});
      setAddressFormValues(prev => ({
        ...prev,
        ...getObjectWithSelectedFields(order, ['zipcode', 'street', 'number', 'complement', 'state', 'city'])
      }));
      forceAddressFormUpdate.current = true;

      setIdCompanyCustomer(order.idCompanyCustomer);
      setPaymentTypes(order?.paymentTypes);
      setInstallments(order?.paymentInfo?.list || []);
      setLinkedSolicitation(order?.purchase?.code);
      setLinkedQuote(order?.quote?.code);
      setFiles(order?.files || []);
      setObservation(order?.observation || '');

      forceAddressFormUpdate.current = true;
      setIsOrderLoading(false);
      setIsPaymentResponsibleValid(true);
      setIsDraft(order?.idOrderStatus === purchaseOrderStatus.draft);
    }
  }, [order]);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => setIsPrinting(false)
  });

  useEffect(() => {
    if (order) {
      setIsAddressLoading(false);
    }
  }, [addressFormValues]);

  useEffect(() => {
    if (foundParams?.print && !isLoadingForPrint) {
      setIsPrinting(true);
      clearParams();
    }
  }, [foundParams?.print, isLoadingForPrint]);

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting]);

  useEffect(() => {
    if (isReview) {
      document.getElementById('main').scrollTo({ top: 0, behavior: 'smooth' });
      if (idCompanyCustomer) {
        getCompanyCustomer({ refetchPathOptions: `/${idCompanyCustomer}` }).then(response =>
          setCompanyCustomer(response)
        );
      }
    }
  }, [isReview]);

  useEffect(() => {
    let title = 'Editar ordem';
    let pathName = ['/ordem-de-compras/editar/'];
    if (!id) {
      title = 'Criar ordem';
      pathName = ['/ordem-de-compras/editar/'];
      getCode().then(_code => setCode(_code));
    } else {
      getOrder().then(_order => setCode(_order?.code));
    }

    dispatch(
      saveHeaderName(
        isView
          ? { name: isView ? 'Detalhes' : title, pathName: ['/ordem-de-compras/visualizar/'] }
          : { name: isView ? 'Detalhes' : title, pathName }
      )
    );
  }, []);

  const setField = (key, value) => {
    setExtraValues(prev => ({ ...prev, [key]: value }));
  };

  const addExtraFieldsToCallback = saveData => ({
    ...saveData,
    extraFields: {
      ...addressFormValues,
      paymentInfo: { list: installments },
      paymentTypes,
      saveAddressToProject,
      idCompanyCustomer,
      extraValues,
      isPaymentResponsibleValid,
      files
    }
  });

  const ReviewDetails = useMemo(() => {
    const { company, supplier } = order || {};

    return (
      <Div
        align="start"
        direction={_isMobile ? 'column' : 'row'}
        padding={spaces.space2}
        gap={spaces.space2}
        $fullHeight
      >
        {company?.logo && !_isMobile ? (
          <img
            style={{ width: '144px', height: '144px', borderRadius: spaces.space0 }}
            crossOrigin="anonymous"
            alt={company?.name}
            src={`${company?.logo}&t=${new Date().getTime()}`}
          />
        ) : null}
        <Div
          align="baseline"
          direction={_isMobile ? 'column' : 'row'}
          justify="flex-start"
          gap={_isMobile ? spaces.space1 : spaces.space6}
          $fullWidth
        >
          {company && <ViewEntityInfo data={company} />}
          {_isMobile && <Divider />}
          {supplier && <ViewEntityInfo data={supplier} />}
        </Div>
      </Div>
    );
  }, [order?.company, order?.supplier]);

  return loading ? (
    <CenteredLoader />
  ) : (
    <>
      <ComponentToPdf ref={printComponentRef}>
        <Div direction="column" align="initial" style={{ backgroundColor: colors.white }}>
          {isSupplierView && isView && (
            <Div
              className="hide-on-print"
              $fullWidth
              justify="flex-end"
              padding={`${spaces.space2} ${spaces.space2} 0 0`}
            >
              <Button ghost onClick={() => setIsPrinting(true)}>
                Exportar PDF
              </Button>
            </Div>
          )}

          {isPrinting && (
            <>
              <TitleText margin={`${spaces.space2} 0px 0px ${spaces.space2}`} $maxHeight="120px">
                Ordem de compra {code} - {refurbish?.name || order?.refurbish?.name}
              </TitleText>
              <Divider style={{ margin: `${spaces.space2} 0 0` }} />
            </>
          )}
          {isReview && (
            <Info>
              <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              <span>
                Revise as informações. Após a criação as informações só serão editáveis caso a solicitação volte para
                rascunho.
              </span>
            </Info>
          )}
          {isView && (
            <>
              {ReviewDetails}
              <Divider style={{ margin: '0' }} />
            </>
          )}

          {!isPrinting && (
            <>
              <PurchaseHeader
                readOnly={readOnly}
                code={code}
                data={isReview ? purchaseFormValues : order}
                refurbish={refurbish}
                linkedSolicitation={linkedSolicitation}
                linkedQuote={linkedQuote}
                type="order"
              />
              {(!readOnly || _isMobile) && <Divider style={{ margin: 0 }} />}
            </>
          )}

          {!readOnly && (
            <PurchaseForm
              forceFormUpdate={forceFormUpdate}
              canEditRefurbish={canEditRefurbish}
              canEditSupplier={canEditSupplier}
              purchaseFormValues={purchaseFormValues}
              setPurchaseFormValues={setPurchaseFormValues}
              hasSupplier
            />
          )}

          <Divider style={{ margin: '0' }} />

          <Div align="start" direction="column" gap={spaces.space1} padding={spaces.space2} $fullWidth>
            <Div padding={`${spaces.space1} 0`}>
              <TitleText>
                Itens{!_isMobile && ' solicitados'}
                {!readOnly && '*'}
              </TitleText>
            </Div>
            {isPrinting ? (
              <Table list={list} columns={purchasePdfColumns({})} fullWidth />
            ) : (
              <PurchaseTable
                list={list}
                setList={setList}
                readOnly={readOnly}
                height="375px"
                idRefurbish={idRefurbish}
                itemObjectsMap={itemObjectsMap}
                itemSettersMap={itemSettersMap}
                childrenColumnName="orderItemLevels"
                isSupplierView={isSupplierView}
                type="order"
              />
            )}

            <Context
              data={{
                values: { ...extraValues, list },
                setField: curry(setField),
                extraValueErrors,
                setExtraValueErrors,
                isView: readOnly
              }}
            >
              <div style={{ alignSelf: 'flex-end' }}>
                <PaymentExtraValuesForm childColumnName="list" showTotalDetails={false} />
              </div>
            </Context>
          </Div>

          <Divider style={{ margin: '0' }} />

          <Div align="start" direction="column" padding={spaces.space2}>
            {!isPrinting && <TitleText>Pagamento{!readOnly && '*'}</TitleText>}
            <br />
            <Context
              data={{
                paymentTypes,
                setPaymentTypes,
                title: isPrinting && 'Pagamento'
              }}
            >
              <OrderInstallmentsComponent
                installments={installments}
                setInstallments={setInstallments}
                total={extraValues?.total || 0}
                disable={readOnly}
                orderCode={code}
                s
              />
            </Context>
          </Div>

          <Divider style={{ margin: '0' }} />

          <Div align="start" direction="column" padding={spaces.space2} $avoidPageBreak>
            <TitleText>{isPrinting || readOnly ? 'Dados para faturamento' : 'Faturamento*'}</TitleText>
            <br />
            {isPrinting || readOnly ? (
              <ViewEntityInfo data={order?.companyCustomer || order?.company || companyCustomer || userCompany} />
            ) : (
              <PaymentOrderComponent
                idCompanyCustomer={refurbish?.idCompanyCustomer || idCompanyCustomer}
                setIdCompanyCustomer={setIdCompanyCustomer}
                setIsValid={setIsPaymentResponsibleValid}
                readOnly={readOnly}
                companyCustomerName={order?.companyCustomer?.name}
                initialChecked={order?.id && (idCompanyCustomer ? 'payment' : 'expense')}
              />
            )}
          </Div>

          <Divider style={{ margin: `${isPrinting ? spaces.space2 : 0} 0 0` }} />

          <Context
            data={{
              addressFormValues,
              saveAddressToProject,
              setSaveAddressToProject,
              setAddressFormValues,
              forceAddressFormUpdate,
              referObj: order,
              refurbish,
              isPrinting,
              readOnly
            }}
          >
            <DeliveryAddressForm
              tooltipText={`Ao salvar a ordem de compra, o endereço cadastrado no projeto
                            será atualizado para o endereço de entrega informado`}
            />
          </Context>

          <Divider style={{ margin: `${isPrinting ? spaces.space2 : 0} 0 0` }} />

          <Context
            data={{
              files,
              setFiles,
              observation,
              setObservation,
              readOnly
            }}
          >
            <ObservationAndFilesComponent />
          </Context>
        </Div>
      </ComponentToPdf>
      {!isSupplierView && (
        <PurchaseOrderFooter
          model="order"
          baseUrl={baseUrl}
          data={order}
          getPurchase={getOrder}
          handleSave={saveData => handleSave(addExtraFieldsToCallback(saveData))}
          id={id}
          idRefurbish={idRefurbish}
          isDraft={isDraft}
          isEditable={isEditable}
          isReview={isReview}
          isView={isView}
          setIsReview={setIsReview}
          validateSubmit={saveData => validateSubmit(addExtraFieldsToCallback(saveData))}
          handlePrint={() => setIsPrinting(true)}
        />
      )}
      {modalProps?.show && (
        <ConfirmModal
          open
          title={`${id ? 'Salvar alterações' : 'Criar ordem'} `}
          alertInfo={
            modalProps.text && (
              <ConfirmModalAlertInfoBuilder alertText={modalProps.text} afterAlertList={modalProps.list} />
            )
          }
          alertInfoPadding={spaces.space1}
          onClose={() => setModalProps(null)}
          onSubmit={() => (modalProps?.callback ? modalProps.callback() : setModalProps(null))}
        />
      )}
    </>
  );
};

PurchaseOrder.propTypes = {
  isView: PropTypes.bool,
  isSupplierView: PropTypes.bool
};

export default PurchaseOrder;
