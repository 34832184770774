import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ImportInstructionModal from '../Modal/ImportInstructionsModal';

// context
import { Div, colors, spaces } from '../../styles/style';
import Button from '../Button/Button';
import {
  MainContainer,
  StyledContent,
  ActionsContainer,
  ActionContainer,
  IconContainer,
  DoubtsContainer,
  DoubtsTopContainer,
  Buttons,
  KnowMore,
  Icon
} from './FirstEmptyState.styled';
import { Paragraph, Subtitle, Title } from '../Text/Text';
import CenteredLoader from '../Loader/CenteredLoader';

const FirstEmptyState = ({ mapping, helpId, watchId, height, noMargin, multipleButtons, loading, ...props }) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const { CustomSubtitle, model } = mapping || {};
  const [importModalClick, setImportModalClick] = useState(false);
  const isCustomer = user.userType === userType.customer.value;

  if (isCustomer) {
    return (
      <Div $fullWidth justify="center" height="70vh">
        Nenhuma informação cadastrada
      </Div>
    );
  }

  return loading ? (
    <CenteredLoader />
  ) : (
    <StyledContent noMargin={noMargin} height={height} {...props}>
      {importModalClick?.onClick && (
        <ImportInstructionModal
          model={model}
          onClose={() => setImportModalClick()}
          goNext={() => {
            importModalClick?.onClick();
            setImportModalClick();
          }}
        />
      )}
      <MainContainer>
        <Div direction="column" gap={spaces.space1} align="center" className="title" $maxWidth="70%">
          <Title color={colors.neutral600}>{mapping?.title}</Title>
          {CustomSubtitle || (
            <Paragraph type="small" align="center">
              {mapping?.subTitle}
            </Paragraph>
          )}
        </Div>

        <ActionsContainer>
          {mapping?.actions?.map(action => {
            return (
              !action?.hideMobile && (
                <ActionContainer
                  key={action?.id || action?.actionText}
                  id={action?.id || 'add-new-item'}
                  $multipleButtons={multipleButtons}
                  onClick={() => {
                    if (action.openBeforeModal) {
                      setImportModalClick({ onClick: action.onClick });
                    } else {
                      action.onClick();
                    }
                  }}
                >
                  <IconContainer $multipleButtons={multipleButtons} role="presentation">
                    <Icon size="2x" color={colors.primary300} icon={action.icon} />
                  </IconContainer>
                  <Div
                    className="buttons-text"
                    direction="column"
                    gap={spaces.space0}
                    align={mapping?.actions?.length === 1 ? 'center' : 'baseline'}
                  >
                    <Subtitle color={colors.neutral600}>{action.subtitleAction}</Subtitle>
                    <Paragraph type="small">{action.actionText}</Paragraph>
                  </Div>
                </ActionContainer>
              )
            );
          })}
        </ActionsContainer>
        <DoubtsContainer>
          <DoubtsTopContainer>
            <Subtitle color={colors.neutral600}>Dúvidas?</Subtitle>
            <Paragraph type="small">{mapping?.doubtsText}</Paragraph>
          </DoubtsTopContainer>
          <KnowMore>
            <Buttons type="primary" id={helpId} text>
              Saiba mais
            </Buttons>
            {watchId && (
              <Button type="primary" id={watchId} text>
                Assistir como funciona
              </Button>
            )}
          </KnowMore>
        </DoubtsContainer>
      </MainContainer>
    </StyledContent>
  );
};

FirstEmptyState.propTypes = {
  mapping: PropTypes.instanceOf(Object),
  noMargin: PropTypes.bool,
  height: PropTypes.string,
  helpId: PropTypes.string,
  watchId: PropTypes.string,
  multipleButtons: PropTypes.bool,
  loading: PropTypes.bool
};

export default FirstEmptyState;
