import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: ${props => (props.showGroupButtons ? 'baseline' : 'center')};
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  flex: auto;
  justify-content: space-between;
  ${props => props.margin && `margin: ${props.margin};`}
`;

export const StyledDivFlex = styled.div`
  display: flex;
  align-items: center;
`;
