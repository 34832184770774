import AnalyticsServiceFactory from '../analytics/analytics';

const FinancialEventService = service => {
  const analyticsService = AnalyticsServiceFactory(service);

  return {
    onExport: ({ entity, fileType = 'csv' }) =>
      analyticsService.track({
        event: `${entity}-exported`,
        eventProperties: { fileType }
      }),
    onValidationRangeError: () =>
      analyticsService.track({
        event: `financial-date-filter-error`
      }),
    onFileDownload: (idRefurbish, context) =>
      analyticsService.track({
        event: 'nf-exported',
        eventProperties: { isProject: !!idRefurbish, idRefurbish, context }
      }),
    onAction: ({ entity, data }) =>
      analyticsService.track({
        event: entity,
        eventProperties: data
      })
  };
};

export default FinancialEventService;
