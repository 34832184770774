import React from 'react';
import LogoIcon from '../../../components/Icons/Logo';
import { spaces } from '../../../styles/style';
import WorkReport from './WorkReport';

const WorkReportViewPublic = () => {
  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', margin: spaces.space1 }}>
        <LogoIcon scale={0.6} />
      </div>
      <WorkReport isView isPublic />
    </div>
  );
};

export default WorkReportViewPublic;
