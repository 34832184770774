import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRightFromLine,
  faImageSlash,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faLock
} from '@fortawesome/pro-duotone-svg-icons';
import { Layout, Menu, Space, Tooltip } from 'antd';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useViewport from '../../_Hooks/useViewport';
import LogoIcon from '../Icons/Logo';
import SelectCustomerRefurbish from '../Select/SelectCustomerRefurbish';
import Drawer from '../Drawer/Drawer';

import { Div, colors, spaces } from '../../styles/style';

import { useContextHook } from '../../contexts/GeneralContext';
import {
  StyledCompanyDiv,
  StyledCompanyName,
  StyledHeader,
  StyledLogo,
  StyledLogoDiv,
  StyledSider,
  SubMenu
} from './NavBar.style';
import CompanyProgressDropDown from '../Dropdown/CompanyProgressDropDown';
import BubbleModalButton from '../Button/BubbleModalButton';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import eventBus from '../../lib/helpers/eventBus';
import UserMenuOptions from './UserMenuOptions';
import openNewTab from '../../lib/helpers/openNewTab';
import useCRUD from '../../_Hooks/useCRUD';
import DropdownV2 from '../Dropdown/DropdownV2';
import { StyledItemMenu } from '../Dropdown/DropdownV2.styled';

const NavBar = ({ paths }) => {
  const { isOpenNavBar, handleOpenCloseNavBar, setIsOpenNavBar } = useContextHook();
  const { user } = useSelector(state => state.authReducer);
  const {
    plans,
    permissions,
    enums: { userType }
  } = useSelector(state => state.setup);
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const refurbish = useSelector(state => state.refurbishReducer);
  const isCustomer = user?.userType === userType.customer.value;
  const isProvider = user?.userType === userType.provider.value;
  const [collapsed, setCollapsed] = useState(!!user?.configuration?.collapseMenu);
  const [selectedKey, setSelectedKey] = useState({});

  const [openKeys, setOpenKeys] = useState([]);

  const { handleUpdate: handleUserUpdate } = useCRUD({
    model: 'user',
    immediatelyLoadData: false
  });

  const history = useHistory();
  const location = useLocation();
  const rootSubmenuKeys = useMemo(() => Object.keys(paths), [paths]);

  const { ChangePasswordDrawer, options: userAreaMapping, UpgradeOption } = UserMenuOptions({});

  const listPaths = Object.values(paths);
  const listUserArea = Object.values(userAreaMapping);

  const facebookUrl = 'https://www.facebook.com/vobibr/';
  const instagramUrl = 'https://www.instagram.com/vobibr/';
  const youtubeUrl = 'https://www.youtube.com/channel/UCaOA4b_G2UXA9KNYB4twu9A/';

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const updateUserConfiguration = collapseMenu => {
    if (user.id === -1 || user?.isCustomerView) {
      setCollapsed(collapseMenu);
      return;
    }

    const newUserConfiguration = { ...(user?.configuration ? user.configuration : {}), collapseMenu };

    handleUserUpdate({
      id: user?.id,
      values: {
        ...user,
        permissions: undefined,
        configuration: newUserConfiguration
      },
      refresh: false
    }).then(() => {
      setCollapsed(collapseMenu);
    });
  };

  const findPath = (_paths, pathname) => {
    return _paths.reduce((acc, path) => {
      if (pathname === path?.to || pathname?.includes(path?.to)) {
        return [...acc, path];
      }
      if (path?.children) {
        const childPath = findPath(path?.children, pathname);
        if (childPath.length) return [...acc, path, ...childPath];
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    const pathname = location?.pathname + location?.search;
    const _paths = listPaths?.concat(listUserArea);
    const fullPath = findPath(_paths, pathname);

    if (fullPath.length) {
      setSelectedKey({
        key: fullPath[0]?.key,
        dropdown: fullPath.length > 1 ? fullPath[1]?.key : null,
        childKey: fullPath.length > 1 ? fullPath[fullPath.length - 1]?.key : null
      });
    }
  }, [location]);

  useEffect(() => {
    const isMenuCollapsed = () => eventBus.dispatch('menuCollapsed', { collapsed });
    eventBus.on('isMenuCollapsed', isMenuCollapsed);

    isMenuCollapsed();

    return () => eventBus.remove('isMenuCollapsed', isMenuCollapsed);
  }, [collapsed]);

  useEffect(() => {
    setCollapsed(user?.configuration?.collapseMenu);
  }, [user]);

  const renderMenuItems = (items, idx = 0, parentCanRead, parentKey) => {
    const renderedItems = [];
    items?.forEach(content => {
      const { key, icon, to, label, roles, children, permissionId, external, canRead, tag, onlyMobile } = content;
      const isParent = idx === 0;
      const isCollapsed = !_isMobile && collapsed;
      const isDropdown = idx >= 2;
      const selectedDropdown = selectedKey?.childKey === key ? 'submenu-item-selected' : 'submenu-item-unselected';

      const RenderItem = !isDropdown ? Menu.Item : StyledItemMenu;

      if (isCustomer && permissionId) {
        const itemsToShare = refurbish?.itemsToShare || { [permissionId]: true };
        if (!refurbish?.itemsToShare || !itemsToShare[permissionId]) return;
      }

      const _hasPermission = hasPermission(user, roles, plans, permissions);
      const _canRead = (canRead || parentCanRead) && (!_isMobile ? !onlyMobile : true);
      if (_hasPermission === false || !_canRead) return;

      const withChildren = children?.length > 0;

      if (idx === 1 && withChildren && !isCollapsed) {
        const _menu = <Menu theme="light">{renderMenuItems(children, idx + 1, parentCanRead, parentKey)}</Menu>;
        renderedItems.push(
          <Menu.Item id={key} key={key} className={`${selectedKey?.dropdown === key ? 'submenu-item-selected' : ''}`}>
            <DropdownV2
              menu={_menu}
              trigger={['click']}
              onClick={e => e.stopPropagation()}
              padding={0}
              title={label}
              sectionClass="vobi-submenu-popup"
              isModalClosed={!isOpenNavBar}
            >
              <Div $fullWidth align="center" gap={spaces.space2}>
                <div
                  className={`list-item ${isCollapsed ? 'collapsed' : ''} ${
                    selectedKey?.key === key ? 'selected' : ''
                  }`}
                >
                  {icon ? <div className="item-icon">{icon}</div> : null}
                  <span>{label}</span>
                </div>
                <FontAwesomeIcon style={{ right: 0, position: 'absolute' }} icon={faCaretDown} />
              </Div>
            </DropdownV2>
          </Menu.Item>
        );
        return;
      }

      const renderedItem = withChildren ? (
        <SubMenu
          $isChildren={!isParent}
          popupClassName="vobi-submenu-popup"
          id={key}
          $collapsed={isCollapsed}
          customPadding={_isMobile && spaces.space2}
          title={
            <Tooltip placement="right" title={isCollapsed && idx === 0 && label}>
              <Div
                $fullWidth
                className={selectedKey?.dropdown === key ? 'submenu-item-selected' : ''}
                align="center"
                padding={isCollapsed && !isParent && `0 ${spaces.space2}`}
                justify={isCollapsed && !isParent && 'space-between'}
              >
                <div
                  className={`list-item ${isCollapsed && isParent ? 'collapsed' : ''} ${
                    selectedKey?.key === key ? 'selected' : ''
                  }`}
                >
                  {icon ? <div className="item-icon">{icon}</div> : null}
                  {(!isCollapsed || !isParent) && <span>{label}</span>}
                </div>
                {isCollapsed && !isParent ? <FontAwesomeIcon icon={faCaretRight} /> : null}
              </Div>
            </Tooltip>
          }
          key={key}
        >
          {renderMenuItems(children, idx + 1, canRead || parentCanRead, isParent ? key : parentKey)}
        </SubMenu>
      ) : (
        <RenderItem
          id={key}
          $isNavBar
          className={`${selectedKey?.childKey === key && isCollapsed ? 'submenu-item-selected' : ''}`}
          onClick={() => {
            if (_isMobile && handleOpenCloseNavBar && !isDropdown) handleOpenCloseNavBar();
            if (_isMobile && handleOpenCloseNavBar && isDropdown) setIsOpenNavBar(false);
            if (isCollapsed) setOpenKeys([]);
            if (external) return openNewTab(to);
            if (_hasPermission) return history.push(to);

            return null;
          }}
          key={key}
          title={isParent ? <span>{label}</span> : null}
        >
          {_hasPermission === null ? (
            <BubbleModalButton feature={roles[0]}>
              <Div
                role="presentation"
                className={`list-item ${isCollapsed && isParent ? 'collapsed' : ''} ${
                  selectedKey?.key === key ? 'selected' : ''
                }`}
              >
                {icon ? <div className="item-icon">{icon}</div> : null}
                {(!isCollapsed || !isParent) && <span>{label}</span>}
                <FontAwesomeIcon icon={faLock} color={colors.neutral200} />
                {tag}
              </Div>
            </BubbleModalButton>
          ) : (
            <div
              className={`list-item ${isCollapsed && isParent ? 'collapsed' : ''} ${
                selectedKey?.key === key ? 'selected' : ''
              } ${isDropdown && !isCollapsed && !_isMobile ? selectedDropdown : ''}`}
            >
              {icon ? <div className="item-icon">{icon}</div> : null}
              {(!isCollapsed || !isParent) && <span>{label}</span>}
            </div>
          )}
        </RenderItem>
      );

      renderedItems.push(renderedItem);
    });

    return renderedItems;
  };

  return _isMobile ? (
    <Drawer
      open={isOpenNavBar}
      placement="left"
      title=""
      width="90%"
      onClose={handleOpenCloseNavBar}
      hideFooter
      key="nav-key"
      padding="0"
      headerStyle={{ height: spaces.space8, borderRadius: '0', backgroundColor: colors.neutral75 }}
      bodyStyle={{ backgroundColor: colors.neutral75 }}
      $isMenuMobile
    >
      <ChangePasswordDrawer />
      {isCustomer && !user?.anonymous ? <SelectCustomerRefurbish style={{ margin: `${spaces.space2} 0` }} /> : null}
      <StyledSider $isMobile width="100%" trigger={null}>
        {isProvider && (
          <StyledCompanyDiv $isMobile>
            <StyledLogoDiv>
              {user?.company && user?.company?.logo ? (
                <StyledLogo $url={user?.company?.logo} />
              ) : (
                <FontAwesomeIcon icon={faImageSlash} />
              )}
            </StyledLogoDiv>
            <div className="company-name">
              <StyledCompanyName>{user?.company?.name || user?.name}</StyledCompanyName>
            </div>
          </StyledCompanyDiv>
        )}
        <Menu
          inlineIndent={16}
          triggerSubMenuAction="click"
          theme="light"
          mode="inline"
          expandIcon={item => (
            <FontAwesomeIcon
              style={{ right: spaces.space1, position: 'absolute' }}
              icon={item?.isOpen ? faCaretUp : faCaretDown}
            />
          )}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {renderMenuItems(listPaths)}
        </Menu>
        <StyledCompanyDiv $isMobile $myArea>
          <div className="my-area">
            <span>Minha área</span>
          </div>
        </StyledCompanyDiv>
        <Menu
          inlineIndent={16}
          triggerSubMenuAction="click"
          theme="light"
          mode="inline"
          expandIcon={item => (
            <FontAwesomeIcon
              style={{ right: spaces.space1, position: 'absolute' }}
              icon={item?.isOpen ? faCaretUp : faCaretDown}
            />
          )}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {userAreaMapping
            ? listUserArea.map(content => {
                const { title, icon, onClick, to, shouldRender, key, external, upgrade } = content;
                if (shouldRender && upgrade) return <UpgradeOption />;

                return (
                  shouldRender && (
                    <Menu.Item
                      id={key}
                      onClick={() => {
                        handleOpenCloseNavBar();
                        if (onClick) return onClick();
                        if (to && external) return openNewTab(to);
                        if (to) return history.push(`/${user.type + to}`);
                        return null;
                      }}
                      title={<span>{title}</span>}
                      key={key}
                    >
                      <div className={`list-item ${selectedKey?.key === key ? 'selected' : ''}`}>
                        <div className="item-icon">
                          <FontAwesomeIcon icon={icon} />
                        </div>
                        <span>{title}</span>
                      </div>
                    </Menu.Item>
                  )
                );
              })
            : null}
        </Menu>
        <Space direction="vertical" size={16} className="social-media-footer">
          <div>
            <LogoIcon scale={0.8} />
          </div>
          <div>
            <span>Acesse as nossas mídias sociais:</span>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="social-icon-box facebook">
              <FontAwesomeIcon onClick={() => openNewTab(facebookUrl)} icon={faFacebook} />
            </div>
            <div className="social-icon-box instagram">
              <FontAwesomeIcon onClick={() => openNewTab(instagramUrl)} icon={faInstagram} />
            </div>
            <div className="social-icon-box youtube">
              <FontAwesomeIcon onClick={() => openNewTab(youtubeUrl)} icon={faYoutube} />
            </div>
          </div>
        </Space>
      </StyledSider>
    </Drawer>
  ) : (
    <Layout className="hide-on-print" style={{ minHeight: '100%' }}>
      <StyledSider width={256} trigger={null} collapsible collapsed={collapsed} collapsedWidth={64}>
        <StyledHeader $collapsed={collapsed}>
          <LogoIcon scale={0.6} />
          {!collapsed ? (
            <div id="close-menu-button" className="menu-icon close-menu-icon">
              <FontAwesomeIcon
                id="close-menu-icon"
                className="fa-flip-horizontal"
                icon={faRightFromLine}
                onClick={() => {
                  setTimeout(() => updateUserConfiguration(true), 100);
                }}
              />
            </div>
          ) : (
            <div id="open-menu-button" className="menu-icon open-menu-icon">
              <FontAwesomeIcon
                id="open-menu-icon"
                icon={faRightFromLine}
                onClick={() => {
                  setTimeout(() => updateUserConfiguration(false), 100);
                }}
              />
            </div>
          )}
        </StyledHeader>
        {isProvider && (
          <StyledCompanyDiv>
            <StyledLogoDiv>
              {user?.company && user?.company?.logo ? (
                <StyledLogo $url={user?.company?.logo} />
              ) : (
                <FontAwesomeIcon icon={faImageSlash} />
              )}
            </StyledLogoDiv>
            <div style={{ display: collapsed ? 'none' : 'contents' }}>
              <CompanyProgressDropDown />
            </div>
          </StyledCompanyDiv>
        )}
        <Menu
          triggerSubMenuAction="click"
          theme="light"
          mode="inline"
          expandIcon={item =>
            !collapsed ? (
              <FontAwesomeIcon
                style={{ right: spaces.space1, position: 'absolute' }}
                icon={item?.isOpen ? faCaretUp : faCaretDown}
              />
            ) : null
          }
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          id="menu-items"
        >
          {renderMenuItems(listPaths)}
        </Menu>
      </StyledSider>
    </Layout>
  );
};

NavBar.propTypes = {
  paths: PropTypes.instanceOf(Object)
};

export default NavBar;
