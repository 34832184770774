import styled from 'styled-components';
import { breakpoints, colors, radius, spaces } from '../../styles/style';

export const NotesContainer = styled.div`
  display: flex;
  height: ${props => props.$notesContainerHeight};
  background: ${colors.white};

  .tox-tinymce {
    border: none !important;
    border-radius: 0 ${radius.radius1} ${radius.radius1} 0 !important;
  }

  &__editor {
    width: 100%;

    .trash {
      position: absolute;
      top: ${spaces.space2};
      right: ${spaces.space3};
      cursor: pointer;
    }

    form {
      height: 100%;
      position: relative;
    }

    @media (max-width: ${breakpoints.tablet}) {
      padding-left: 0;
      padding-top: ${spaces.space2};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const ApplyNotes = styled.div`
  flex: 3;
  overflow: auto;
  margin: ${spaces.space2} ${spaces.space2} 0 ${spaces.space2};
`;

export const ApplyNoteText = styled.p`
  padding-bottom: ${spaces.space2};
`;

export const EditSelectedNote = styled.div`
  border-right: 1px solid #cccccc;
  width: 100%;
  flex: 3;
`;

export const ListNotes = styled.p`
  width: 100%;
`;
