import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { Tooltip, Divider } from 'antd';
import styled from 'styled-components';

import gtmIds from '../NavBar/gtm-ids';
import SelectCustomerRefurbish from '../Select/SelectCustomerRefurbish';
import GuideProgressCard from '../Card/GuideProgressCard';
import QuickCreateActions from '../Dropdown/QuickCreateActions';
import Notification from '../Notification/Notification';
import { saveHeaderName } from '../../store/headerName/actions/action';
import useBackToContext from '../../_Hooks/useBackToContext';
import Button from '../Button/Button';
import UserProfile from '../NavBar/UserProfile';
import { spaces, colors, Div, breakpoints } from '../../styles/style';
import { Title } from '../Text/Text';

const Header = styled.header`
  background-color: ${colors.white};
  transition: padding-left 0.4s;
  padding: ${spaces.space0} ${spaces.space3};

  border-bottom: 1px solid ${colors.neutral100};

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .ant-divider {
    background-color: ${colors.neutral600};
  }
`;

const MainHeader = ({ title = '' }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const isProvider = user.userType === userType.provider.value;
  const isAnonymous = !!user?.anonymous;
  const location = useLocation();

  const data = useSelector(state => state.headerNameReducer);

  const dispatch = useDispatch();
  const backToContext = useBackToContext();

  const blueSubTitlePages = ['/profissional/projetos', '/profissional/oportunidades'];
  const isBlueSubTitle = blueSubTitlePages?.some?.(page => location?.pathname?.includes(page));

  const composedTitle = data?.name ? (
    <TitleContainer>
      <Button
        id="back-icon"
        text
        onClick={backToContext}
        padding={`${spaces.space1} ${spaces.space1} ${spaces.space1} 0`}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="lg" color={colors.neutral700} />
      </Button>
      <Div gap={spaces.space0}>
        <Title>{title}</Title> {!isBlueSubTitle && <Divider type="vertical" />}
        <Title color={isBlueSubTitle ? colors.primary400 : null}>{data?.name}</Title>
      </Div>
    </TitleContainer>
  ) : (
    <Title>{title}</Title>
  );

  useEffect(() => {
    if (Array.isArray(data?.pathName) && data?.pathName.every(path => !location?.pathname?.includes(path))) {
      dispatch(saveHeaderName({}));
    }
  }, [location?.pathname]);

  return (
    <div>
      <Helmet>
        <title>Vobi | {title}</title>
      </Helmet>
      <Header>
        <Div justify="space-between">
          {title && composedTitle}
          {!isAnonymous && (
            <Div align="end">
              <Div gap={spaces.space2}>
                {isCustomer && (
                  <Button id="firstStepsTour" type="primary">
                    Primeiros passos
                  </Button>
                )}
                {isCustomer && <SelectCustomerRefurbish />}
                {isProvider && <GuideProgressCard />}
                {isProvider && <QuickCreateActions />}
                <Div id={gtmIds.help} data-gtm-id={gtmIds.help}>
                  <Tooltip placement="bottom" title="Ajuda">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </Tooltip>
                </Div>
                <Notification notificationUniqueKey="MainHeader" />
                {!user.isCustomerView && !user?.anonymous ? <UserProfile below before size="default" /> : null}
              </Div>
            </Div>
          )}
        </Div>
      </Header>
    </div>
  );
};

MainHeader.propTypes = {
  title: PropTypes.string
};

export default memo(MainHeader);
