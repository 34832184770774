import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from './Drawer';
import Form from '../Form/Form';
import useCRUD from '../../_Hooks/useCRUD';
import { simpleFormMapping, simpleFormSchema } from '../../lib/mapping/Form/simpleFormSchema';
import { bankAccountMapping, bankAccountSchema } from '../../lib/mapping/Form/bankAccountSchema';
import useViewport from '../../_Hooks/useViewport';
import Button from '../Button/Button';
import { Div, spaces } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';
import formatCurrency from '../../lib/helpers/formatCurrency';
import CreatedByContent from '../Content/CreatedByContent';

const FinanceEdit = ({ name, onClose, id, setData = () => {}, model }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const isAccount = model === 'bankAccount';
  const [isPj, _setIsPj] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const { data, loading, handleGet, handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const titleMap = {
    bankAccount: 'conta',
    paymentCostCenter: 'centro de custo'
  };

  useEffect(() => {
    if (id) {
      handleGet({ refetchPathOptions: `/${id}` }).then(resp => setData(resp));
    }
  }, [id]);

  const handleSubmit = submitData => {
    const updateOrCreate = id
      ? handleUpdate({
          updatePathOptions: `/${id}`,
          values: submitData,
          refresh: false
        })
      : handleCreate({ values: submitData, refresh: false });
    updateOrCreate.then(resp => {
      if (resp?.error) return;
      setData && setData(resp);
      onClose(true, resp?.id);
    });
  };

  const changeData = values => {
    _setIsPj(values?.personType === 'pj');
  };

  const handleConfirmDelete = newId => {
    if (id) {
      handleDelete({
        refresh: false,
        deletePathOptions: `/${id}`,
        values: { data: { ...data, newId } }
      }).then(() => {
        return onClose(true);
      });
    }
  };

  return (
    !loading && (
      <Drawer
        title={`${id ? 'Editar' : 'Cadastrar'} ${titleMap[model]}`}
        open
        formId="financeEditForm"
        onClose={() => onClose()}
        extraContentOnRight
        zIndex={!_isMobile && 1002}
        footer={
          <>
            <Button type="text" className="withMarginRight" onClick={() => onClose()} id="cancel-drawer">
              Cancelar
            </Button>

            <Div gap={spaces.space2}>
              {id && (
                <Button
                  loading={loading}
                  id={`delete-${model}-button`}
                  text
                  type="danger"
                  className="withMarginRight"
                  onClick={() => setShowConfirmDeleteModal(true)}
                >
                  Excluir
                </Button>
              )}
              <Button loading={loading} htmlType="submit" form="financeEditForm" id="submit-drawer" type="primary">
                Confirmar
              </Button>
            </Div>
          </>
        }
      >
        <Div direction="column" gap={spaces.space3} $fullWidth align="start">
          <Form
            id="financeEditForm"
            displayButtons={false}
            onSubmit={handleSubmit}
            mapping={
              isAccount
                ? bankAccountMapping({ isMobile: _isMobile, isPj, name })
                : simpleFormMapping(titleMap[model], 'Nome')
            }
            schema={isAccount ? bankAccountSchema : simpleFormSchema}
            data={
              data
                ? { ...data, initialBalance: formatCurrency(data?.initialBalance || 0, { currencySymbol: 'R$' }) }
                : { isActive: true, observation: '' }
            }
            onFormChange={changeData}
          />
          <CreatedByContent data={data} showLine noPadding />
        </Div>
        {showConfirmDeleteModal && (
          <ConfirmModal
            title="Excluir conta"
            alertInfo="Essa operação não poderá ser desfeita"
            text="Ao excluir, a conta será removida permanente e ninguém mais poderá acessá-la."
            onSubmit={handleConfirmDelete}
            onClose={() => setShowConfirmDeleteModal(false)}
          />
        )}
      </Drawer>
    )
  );
};

FinanceEdit.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func,
  id: PropTypes.number,
  setData: PropTypes.func,
  model: PropTypes.string
};

export default FinanceEdit;
