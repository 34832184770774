import { Tabs as AntdTabs } from 'antd';
import styled, { css } from 'styled-components';
import { colors, fonts, breakpoints, spaces, radius } from '../../styles/style';

const { TabPane: _TabPane } = AntdTabs;
const StyledTabs = styled(AntdTabs)`
  ${props => props.$width && `width: ${props.$width}`};
  height: ${props => (!props.$fullHeight ? '100%' : null)};
  overflow: unset;
  border-radius: ${radius.radius1};

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${props => props.$marginTop && props.$marginTop};
  }

  .ant-tabs-content {
    height: ${props => (!props.$fullHeight ? '100%' : null)};
    border-radius: ${radius.radius1};
    .ant-tabs-tabpane {
      height: 100%;
      overflow: unset;
      padding: unset;
    }
  }

  .ant-tabs-nav {
    border-radius: ${radius.radius1} ${radius.radius1} 0 0;
    background-color: ${props => props.background || '#ffffff'} !important;
    min-width: 100%;
    margin: 0;
    ${props => props.$tabsNavBorderBottom && `border-bottom: ${props.$tabsNavBorderBottom}`};
    ${props => props.$tabsNavRelative && 'position: relative'};

    :before {
      border-color: ${colors.neutral100};
    }


    .ant-tabs-nav-wrap {
      display: flex;
      align-items: flex-start;
      align-self: ${props => (props.$isProject ? 'flex-start' : 'flex-end')};
      flex: ${props => (props.$fullWidth ? '0 0 100%' : 'auto')};
    }
    .ant-tabs-nav-list {
      padding: ${props => {
        if (props.$noPadding) {
          return `0`;
        }
        if (props.$noHorizontalPadding) {
          return `${spaces.space1} 0 0`;
        }
        return `0 ${spaces.space2}`;
      }};

      height: ${props => (props.tabsNavListHeight ? props.tabsNavListHeight : spaces.space6)};
    }

    @media (max-width: ${breakpoints.tablet}) {
      min-height: ${spaces.space6};
      .ant-tabs-nav-list {
        justify-content: space-between;
      }
    }

    .ant-tabs-nav-list {
      width: ${props => (props.$fullWidth ? '100%' : 'auto')};
    }

    .ant-tabs-nav-operations {
      display: ${props => (props.$showMore ? 'flex' : 'none')};
    }

    @media (min-width: ${breakpoints.desktop}) {
      .ant-tabs-nav-wrap {
        max-width: ${props => (props.isProject ? '128px' : 'none')};
      }
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 ${spaces.space1};
  }

  .ant-tabs-tab {
    padding: ${spaces.space1};
    transition: all 0.3s linear;
    border: none;
    align-self: flex-end;
    height: 100%;

    ${props =>
      props.$fullWidth &&
      css`
        flex: 1;
        justify-content: center;
      `}

    :hover {
      .icon-to-change-when-hover-or-selected {
        color: ${colors.primary600};
      }
      :after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        background-color: ${colors.primary500};
        height: 2px;
        border-top-left-radius: 200px;
        border-top-right-radius: 200px;
      }
    }

    .ant-tabs-tab-btn {
      color: ${colors.neutral600};
      font-size: ${fonts.sizeSm};
      font-weight: ${fonts.weight500};
      flex: ${props => (props.$fullWidth ? '0 0 100%' : 'auto')};
      -webkit-font-smoothing: antialiased;
    }

    :has(#tabs-tab-hide) {
      display: none;
    }
  }

  .ant-tabs-tab-disabled {
    :after {
        display: none;
      }
    .ant-tabs-tab-btn {
      color: ${colors.neutral400};
    }
  }

  .ant-tabs-tab-active {
    .icon-to-change-when-hover-or-selected {
    color: ${colors.primary600};
    }
    :after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      background-color: ${colors.primary500};
      height: 2px;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
    }
    :hover {
      opacity: 1;
      color: ${colors.neutral700};
    }

    .ant-tabs-tab-btn {
      color: ${colors.neutral700};
    }
  }

  .ant-tabs-tab-active + .ant-tabs-tab-disabled {
    :after {
        display: none;
      }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-extra-content {
    flex: 1;
    ${props => props.extraContentMaxWidth && `max-width: ${props.extraContentMaxWidth};`}
    padding: ${props =>
      props.$extraContentPadding != null ? props.$extraContentPadding : `${spaces.space1} ${spaces.space2}`};
    border-left: ${props => (props.$extraContentBorderLeft != null ? props.$extraContentBorderLeft : 0)};
  }
`;

const TabPane = styled(_TabPane)`
  color: ${colors.neutral400};
  ${props =>
    props.disabled &&
    `
    ant-tabs-tab-btn {
  color: ${colors.neutral400};
  :after {
    background-color: ${colors.neutral400};
  }
}
`}
`;

export { StyledTabs, TabPane };
