import styled from 'styled-components';
import { colors, radius, spaces } from '../../styles/style';

const StyledNotificationDropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 460px;
  border-radius: ${radius.radius1};
  background-color: ${colors.white};
  box-shadow: 1px 1px 6px ${colors.neutral500};

  > * {
    padding: ${spaces.space2};
  }
`;

const StyledNotificationDropdownItem = styled.a`
  height: 80px;
  padding: 0 ${spaces.space2};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${colors.neutral100};
  }
`;
const ItemText = styled.div`
  flex: 1;
  margin-left: ${spaces.space3};
`;

const ItemTextDescription = styled.p`
  color: ${colors.neutral700};
`;

const ItemTextTime = styled.span`
  color: ${colors.neutral500};
`;

export { StyledNotificationDropdown, StyledNotificationDropdownItem, ItemText, ItemTextDescription, ItemTextTime };
