import Axios from 'axios';

const FILE_CHUNK_SIZE = 10000000;

const uploadMultipart = async (file, signUrls) => {
  const axios = Axios.create();

  delete axios.defaults.headers.put['Content-Type'];

  const keys = Object.keys(signUrls);
  const promises = keys.map(key => {
    const index = Number(key);
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const blob = index < keys.length ? file?.slice(start, end) : file?.slice(start);

    return axios.put(signUrls[index], blob, { withCredentials: false });
  });

  const resParts = await Promise.all(promises);

  return resParts.map((part, index) => {
    return {
      ETag: part.headers.etag,
      PartNumber: index + 1
    };
  });
};

export { uploadMultipart };
