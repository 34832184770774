import React, { useEffect, useMemo, useState } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCopy } from '@fortawesome/pro-light-svg-icons';
import { Switch, Tooltip } from 'antd';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';

import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import { Paragraph, Description } from '../Text/Text';
import { Div, spaces } from '../../styles/style';
import CopyLinkButton from '../Button/CopyLinkButton';
import WarningBar from '../Alert/WarningBar';

const RenderNoteInfo = ({
  isCustomer,
  data,
  idRefurbish,
  user,
  handleRemove,
  isModuleShared,
  handleCreateNote,
  handleChangeShared = f => f,
  isOpportunity
}) => {
  const [shared, setShared] = useState(data?.shared);
  const { userType } = useSelector(state => state.setup.enums);

  const isWarningMessage = !isModuleShared && data.shared;

  const canDelete = user?.userType === userType.provider.value;

  useEffect(() => {
    setShared(!!data?.shared);
  }, [data]);

  const renderText = useMemo(() => {
    if (isWarningMessage)
      return `Seu cliente não tem acesso aos itens compartilhados pois o
              compartilhamento do módulo de anotações não está ativo.`;
    return 'Esta anotação está compartilhada';
  }, [isWarningMessage]);

  return (
    <>
      <Div direction="column" gap={spaces.space2} align="start" $fullWidth>
        {!isCustomer && data?.id > 1 ? (
          <>
            <div>
              <Paragraph type="small" style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                Informações da anotação
              </Paragraph>
            </div>
            {idRefurbish && (
              <div>
                <Tooltip title="Copie o link dessa anotação para enviar para seu cliente ou equipe">
                  <CopyLinkButton
                    underline
                    height="auto"
                    eventButton="note"
                    customUrl={`${window.location.origin}/n/${data?.id}`}
                    text
                    type="primary"
                  >
                    Copiar Link
                  </CopyLinkButton>
                </Tooltip>
              </div>
            )}
            <div>
              <Div justify="space-between">
                <Paragraph type="small">Compartilhar anotação</Paragraph>
                <Switch
                  id="share-note"
                  disabled={isCustomer}
                  checked={!!data.shared}
                  onChange={() => handleChangeShared(data.id, shared)}
                  size="small"
                />
              </Div>
              <Description>Habilite para compartilhar com seu cliente</Description>
              {data.shared && !isCustomer && (
                <WarningBar
                  icon={faUserFriends}
                  type={!isWarningMessage ? 'light' : null}
                  warning={renderText}
                  margin={`${spaces.space0} 0`}
                />
              )}
            </div>
          </>
        ) : null}
        {isCustomer ? (
          <div>
            <Tooltip title="Copie o link dessa anotação para enviar para seu profissional">
              <CopyLinkButton
                underline
                height="auto"
                eventButton="note"
                customUrl={`${window.location.origin}/n/${data?.id}`}
                text
                type="primary"
              >
                Copiar link
              </CopyLinkButton>
            </Tooltip>
          </div>
        ) : null}
        <div>
          <Paragraph type="small">Data de criação</Paragraph>
          <Description>{data ? dayjs(data.createdAt).format('DD [de] MMMM, YYYY [às] HH:mm') : '-'}</Description>
        </div>
        <div>
          <Paragraph type="small">Última atualização</Paragraph>
          <Description>{data ? dayjs(data.updatedAt).format('DD [de] MMMM, YYYY [às] HH:mm') : '-'}</Description>
        </div>
        {data?.id > 1 && canDelete ? (
          <Div $fullWidth justify="space-between" gap={spaces.space1}>
            {!isOpportunity && idRefurbish ? (
              <Button
                id="delete-note-icon"
                ghost
                type="primary"
                icon={<FontAwesomeIcon icon={faCopy} />}
                onClick={() => handleCreateNote(data, idRefurbish)}
              >
                Criar uma cópia
              </Button>
            ) : null}
            <Button
              id="delete-note-icon"
              ghost
              type="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => handleRemove(true)}
            >
              Excluir nota
            </Button>
          </Div>
        ) : null}
      </Div>
    </>
  );
};

RenderNoteInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isCustomer: PropTypes.bool,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleRemove: PropTypes.func,
  isModuleShared: PropTypes.bool,
  handleChangeShared: PropTypes.func,
  handleCreateNote: PropTypes.func,
  isOpportunity: PropTypes.bool
};

export default RenderNoteInfo;
