import React from 'react';

import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import dayjs from 'dayjs';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import { colors, spaces } from '../../../styles/style';

const columns = ({ handleCopy, setShowConfirmDelete, asaasPixType }) => {
  const _columns = [
    { title: 'Chave', key: 'key', dataIndex: 'key' },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      width: 90,
      render: value => {
        return asaasPixType[value];
      }
    },
    {
      title: 'Criado em',
      key: 'dateCreated',
      dataIndex: 'dateCreated',
      width: 90,
      render: value => {
        return dayjs(value).format('DD/MM/YY');
      }
    },
    {
      title: 'Ações',
      width: 90,
      render: (_, row) => {
        return (
          <div>
            <TooltipIcon
              text="Copiar"
              iconColor={colors.primary500}
              icon={faCopy}
              style={{ height: 18, width: 18 }}
              id="copy-pix"
              onClick={() => handleCopy(row)}
            />

            <TooltipIcon
              text="Excluir"
              iconColor={colors.red500}
              icon={faTrashCan}
              style={{ height: 18, width: 18, marginLeft: spaces.space2 }}
              id="remove-pix"
              onClick={() => setShowConfirmDelete(true)}
            />
          </div>
        );
      }
    }
  ];

  return _columns;
};

export { columns };
