import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from './Drawer';
import Form from '../Form/Form';
import useCRUD from '../../_Hooks/useCRUD';
import { simpleFormMapping, simpleFormSchema } from '../../lib/mapping/Form/simpleFormSchema';
import { refurbishGroupMapping, refurbishGroupSchema } from '../../lib/mapping/Form/refurbishGroupSchema';

const GroupCostCenterUnitDrawer = ({ onClose, id, setData, model }) => {
  const isGroup = model === 'refurbishGroup';
  const { data, loading, handleGet, handleCreate, handleUpdate } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const titleMap = {
    refurbishGroup: 'grupo',
    costCenter: 'categoria',
    unit: 'unidade'
  };

  useEffect(() => {
    if (id) {
      handleGet({ refetchPathOptions: `/${id}` });
    }
  }, [id]);

  const handleSubmit = submitData => {
    const updateOrCreate = id
      ? handleUpdate({
          updatePathOptions: `/${id}`,
          values: submitData,
          refresh: false
        })
      : handleCreate({ values: submitData, refresh: false });
    updateOrCreate.then(resp => {
      if (resp?.error) return;
      setData && setData(resp);
      onClose(true);
    });
  };

  return (
    !loading && (
      <Drawer
        title={`${id ? 'Editar' : 'Cadastrar'} ${titleMap[model]}`}
        open
        formId="groupCostCenterUnitForm"
        onClose={() => onClose()}
      >
        <Form
          id="groupCostCenterUnitForm"
          displayButtons={false}
          onSubmit={handleSubmit}
          mapping={
            isGroup ? refurbishGroupMapping({ largeDescription: true }) : simpleFormMapping(titleMap[model], 'Nome')
          }
          schema={isGroup ? refurbishGroupSchema : simpleFormSchema}
          data={data || { isActive: true, observation: '' }}
        />
      </Drawer>
    )
  );
};

GroupCostCenterUnitDrawer.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.number,
  setData: PropTypes.func,
  model: PropTypes.string
};

export default GroupCostCenterUnitDrawer;
