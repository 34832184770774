import styled, { css } from 'styled-components';
import { breakpoints, colors, radius, spaces, fonts } from '../../styles/style';
import { Paragraph } from '../Text/Text';

const StyledFolder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => (props.selected ? colors.primary400 : colors.neutral100)};
  min-width: 168px;
  border-radius: ${radius.radius1};
  height: 40px;
  position: relative;
  padding: ${spaces.space1} ${spaces.space2};
  cursor: pointer;
  margin: ${spaces.space2} ${spaces.space2} 0 0;
  transition: all 0.3s ease-in-out;
  :hover {
    border: 1px solid ${colors.primary400};
    button {
      display: flex;
      position: absolute;
      right: 0;
      top: 4px;
    }
  }
  ${props =>
    props.selected === true &&
    css`
      color: ${colors.primary600};
      background-color: ${colors.primary50};
    `}
  button {
    display: none;
  }
  p {
    padding-left: 8px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin: 8px 8px 0 0;
    button {
      display: flex;
      position: absolute;
      right: 0;
    }
  }
`;

const SharedIcon = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: ${colors.primary600};
  border-radius: 9px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  > svg {
    font-size: ${spaces.space1};
  }
`;

const StyledDropdownFolder = styled.div`
  display: flex;
  padding: 8px;
  background-color: ${props => (props.selected ? colors.primary200 : null)};

  &:hover {
    background-color: ${colors.neutral100};

    .ant-typography {
      font-weight: 400;
    }
  }
`;

const StyledFolderName = styled(Paragraph)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 16px 0 8px;
  flex: 2;
  font-size: ${fonts.sizeSm};
`;

const StyledFolderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const StyledFolderDiv = styled.div`
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export { StyledFolder, SharedIcon, StyledDropdownFolder, StyledFolderName, StyledFolderGrid, StyledFolderDiv };
