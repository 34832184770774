import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Modal from './Modal';
import useCRUD from '../../_Hooks/useCRUD';
import { Subtitle } from '../Text/Text';
import { colors, fonts } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';
import useViewport from '../../_Hooks/useViewport';
import RenderCard from '../Card/RenderCard';
import { hasPermission } from '../../routes/Common/PrivateRoute';

const OverflowSpace = styled(Space)`
  height: 300px;
  overflow: auto;
`;

const detailTypeTranslate = {
  refund: 'reembolso',
  fee: 'taxa de administração'
};

const OrderDetailModal = ({ idPayment, detailType = 'refund', onClose = f => f }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { plans } = useSelector(state => state.setup);
  const { user } = useSelector(state => state.authReducer) || {};
  const hasAdministrativePermission = hasPermission(user, ['financial'], plans);
  const _isMobile = isMobile();
  const { data, loading } = useCRUD({
    model: 'payment',
    pathOptions: `/${idPayment}`,
    options: {
      include: [
        {
          model: 'paymentItem',
          attributes: ['id'],
          include: [
            {
              model: 'order',
              attributes: ['idOrderStatus', 'total', 'code', 'id'],
              include: [
                { model: 'orderItems', attributes: ['price', 'quantity'] },
                {
                  model: 'purchaseStatus',
                  attributes: ['id', 'name']
                }
              ]
            }
          ]
        },
        {
          model: 'paymentStatus',
          attributes: ['id', 'name']
        }
      ],
      attributes: ['idPaymentStatus', 'value', 'name']
    }
  });

  const handleSubmit = idOrder => {
    return onClose({
      idOrder,
      idPayment
    });
  };

  return (
    <Modal
      width="fit-content"
      open
      loading={loading}
      title={`Detalhes de ${detailTypeTranslate[detailType]}`}
      cancelText="Fechar"
      hideSubmit
      onClose={() => onClose()}
      isMobile={_isMobile}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <Space size={32} align="start" direction={_isMobile ? 'vertical' : 'horizontal'}>
          <Space size={8} direction="vertical">
            <Subtitle color={colors.neutral600} type="secondary" weight={fonts.weight600}>
              Pagamento
            </Subtitle>
            <RenderCard
              data={data}
              isOrderModal
              cardId="payment-card"
              onClick={hasAdministrativePermission && (() => handleSubmit())}
              tooltipText={hasAdministrativePermission && 'Clique para ver o pagamento'}
              tooltipId="payment-tooltip"
            />
          </Space>
          <Space size={8} direction="vertical">
            <Subtitle color={colors.neutral600} type="secondary" weight={fonts.weight600}>
              Ordens de compra
            </Subtitle>
            <OverflowSpace size={8} direction="vertical">
              {data?.paymentItems?.map((paymentItem, index) => {
                const { order } = paymentItem || {};
                return (
                  <React.Fragment key={paymentItem?.id}>
                    <RenderCard
                      data={order}
                      isOrderModal
                      cardId={`order-card-${index + 1}`}
                      onClick={() => handleSubmit(order?.id)}
                      tooltipText="Clique para ver a ordem de compra"
                      tooltipId={`order-tooltip-${index + 1}`}
                    />
                  </React.Fragment>
                );
              })}
            </OverflowSpace>
          </Space>
        </Space>
      )}
    </Modal>
  );
};

OrderDetailModal.propTypes = {
  idPayment: PropTypes.string,
  onClose: PropTypes.func,
  detailType: PropTypes.oneOf(['fee', 'refound'])
};

export default OrderDetailModal;
