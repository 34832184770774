import React, { memo, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPenToSquare , faBoxOpen, faLayerGroup, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';

import { Tooltip , Menu } from 'antd';
import {
  faXmark,
  faMessage,
  faShareNodes,
  faEllipsis,
  faBookmark,
  faBookmarkSlash,
  faCopy,
  faTrashCan,
  faInfoCircle,
  faArrowRotateRight
} from '@fortawesome/pro-regular-svg-icons';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';

import { Container, FormContainer } from './EditItemModal.styles';
import { colors, Div, fonts, spaces } from '../../styles/style';
// import {
//   newBudgetItemMapping as budgetItemMapping,
//   linkedItemMapping as itemMapping
// } from '../../lib/mapping/Form/itemSchema';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatBdi from '../../lib/helpers/formatBdi';
import formatNumber from '../../lib/helpers/formatNumber';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import Button from '../Button/Button';
import ConfirmModal from './ConfirmModal';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import ConfirmModalAlertInfoBuilder from './ConfirmModalAlertInfoBuilder';
import { getCatalogDrawerDisplayToast, getLinkedItemText } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import Drawer from '../Drawer/Drawer';
import { Subtitle, Title } from '../Text/Text';
import DrawerHeaderComponents from '../Drawer/DrawerComponents/DrawerHeaderComponents';
import useFilter from '../../_Hooks/useFilter';
import DrawerComments, { calcDrawerWidth } from '../Drawer/DrawerComponents/DrawerComments';
import CopyLinkButton from '../Button/CopyLinkButton';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import HowDoesItWorksButton from "../Button/HowDoesItWorksButton";
import FlatTag from '../Tag/FlagTag';
import DrawerTabs from '../Drawer/DrawerComponents/DrawerTabs';
import FileUpload from '../File/FileUpload';
import { itemMappingV2, itemSchemaV2 } from '../../lib/mapping/Form/itemSchemaV2';
import FormV2 from '../Form/FormV2';
import TooltipIcon from '../Tooltip/TooltipIcon';
import EditableInput from '../Input/EditableInput';

const EditItemModal = ({
  id,
  idReference,
  onClose = f => f,
  afterSubmit = f => f,
  model = 'refurbish-items',
  readOnly,
  tab = '0',
  isTemplate,
  groupedReference,
  type,
  renderColumns,
  isLibrary = false,
  // onlyLabor,
  libraryProps,
  isChildrenDrawer,
  initialData,
  isProductConciliation,
  // hideAttachments = false,
  // hideComments = false
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishItemType, refurbishItemStatus } = useSelector(state => state.setup.enums);
  const isCustomer = user?.userType === userType.customer.value;
  const isUserAnonymous = user?.anonymous;
  const hideSupplier = !renderColumns?.idSupplier && isCustomer;

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [name, setName] = useState('');
  const [observation, setObservation] = useState('');
  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const [linkedData, setLinkedData] = useState();
  const [editableData, setEditableData] = useState();
  const [showEditLibraryItemModal, setShowEditLibraryItemModal] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState();
  const [linkedItem, setLinkedItem] = useState();
  // const [showAllLaborFields, setShowAllLaborFields] = useState(false);
  const [editSupplierId, setEditSupplierId] = useState();
  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [isEditableFormValid, setIsEditableFormValid] = useState(true);
  const forceFormUpdate = useRef(false);
  const { storedFilters, updateFilters } = useFilter({
    persistKey: 'show-comments-side-component'
  });
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const analytics = AnalyticsServiceFactory();

  let initialShowComments = storedFilters !== null ? storedFilters?.showCommentsSideComponent : true;
  if (isUserAnonymous || _isMobile) initialShowComments = false;

  const [showComponents, setShowComponents] = useState({
    showComments: initialShowComments,
    showOptions: false
  });

  useEffect(() => {
    if (showComponents.showComments !== storedFilters?.showCommentsSideComponent)
      updateFilters({ ...storedFilters, showCommentsSideComponent: showComponents.showComments });
  }, [showComponents.showComments]);


  const detailTitle = {
    [refurbishItemType.labor]: { name: 'Serviço', icon: faScrewdriverWrench },
    [refurbishItemType.product]: { name: 'Produto', icon: faBoxOpen },
    [refurbishItemType.composition]: { name: 'Composição', icon: faLayerGroup }
  };
  const createTitle = {
    [refurbishItemType.labor]: { name: 'Novo serviço', icon: faScrewdriverWrench },
    [refurbishItemType.product]: { name: 'Novo produto', icon: faBoxOpen }
  };

  const { data, loading, handleGet, handleUpdate, handleCreate, handleDelete } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    options: libraryProps?.options || {
      include: [
        'supplier',
        'parent',
        { attributes: ['id', 'name'], model: 'Unit', as: 'units' },
        ...(isTemplate || !idReference ? [] : ['files']),
        {
          model: 'costCenter',
          as: 'costCenter',
          attributes: ['id', 'name']
        },
        {
          model: 'refurbishGroup',
          as: 'refurbishGroup',
          attributes: ['id', 'name']
        },
        ...(isLibrary
          ? []
          : [
              {
                model: isTemplate ? 'template' : 'refurbish',
                attributes: ['version', 'laborBdi', 'productBdi']
              },
              {
                model: 'Item',
                as: 'item',
                include: [{ attributes: ['id', 'name'], model: 'Unit', as: 'units' }]
              }
            ])
      ],
      where: { idRefurbish: idReference }
    },
    immediatelyLoadData: !!id
  });

  const { handleGet: getLibraryItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });


  const hasSupplier = data?.supplier?.id && data?.supplier?.id !== 1;

  useEffect(() => {
    setLinkedData(data);
    forceFormUpdate.current = true;

    if (data?.id) {
      setFiles(data.files || []);
      setImages(data.images || []);
      setName(data.name || '');
      setObservation(data.observation || '');
      setLinkedItem(data?.externalBaseCode && !data?.idItem ? data : data?.item);
      setEditableData(data);
    }
  }, [data]);

  const propsForTrack = { idCompany: user?.idCompany, idRefurbish: data?.idRefurbish, idUser: user?.id };

  const handleSaveLink = itemToSave => {
    handleUpdate({
      values: {
        idItem: itemToSave?.id || null,
        externalBaseCode: itemToSave?.externalBaseCode || null,
        ...(itemToSave && { type: itemToSave?.type }),
        event: 'item-nature-changed',
        action: itemToSave ? 'attached' : 'detached'
      },
      refresh: false,
      displayToast: getCatalogDrawerDisplayToast(itemToSave)
    }).then(resp => {
      if (!resp?.error) {
        handleGet();
      }
    });
  };

  const handleSubmit = () => {
    if (!isEditableFormValid) return;
    const { bdi, height, width, length, weight, idUnit, code, ...submitData } = editableData || {};
    const formattedBdi = formatBdi(bdi);

    const values = {
      ...submitData,
      ...(formatCurrency(data?.bdi || 0) !== formatCurrency(formattedBdi) && { bdi: formattedBdi }),
      idRefurbish: idReference,
      image: null,
      price: formatNumber(submitData.price),
      observation,
      files,
      type: data?.type || type,
      autoCode: !id && libraryProps?.checkedValue,
      quantity: formatNumber(submitData?.quantity || 0),
      ...(data?.status !== submitData.status &&
        submitData.status === refurbishItemStatus.approved.value && { isApproved: true }),
      ...(!linkedItem && {
        height: formatNumber(height),
        width: formatNumber(width),
        length: formatNumber(length),
        weight: formatNumber(weight),
        idUnit: idUnit || 1,
        name,
        code,
        images,
      })
    };
    if (!id) {
      handleCreate({
        values,
        refresh: false,
        postOptions: {
          include: [
            'supplier',
            ...(isTemplate ? [] : ['files']),
            {
              model: 'costCenter',
              as: 'costCenter',
              attributes: ['id', 'name']
            },
            {
              model: 'refurbishGroup',
              as: 'refurbishGroup',
              attributes: ['id', 'name']
            }
          ]
        },
        displayToast: 'Item adicionado com sucesso.'
      }).then(resp => {
        if (!resp?.error) {
          onClose(resp);
          afterSubmit(resp);
        }
      });
    } else {
      handleUpdate({
        values,
        refresh: false,
        displayToast: 'Item atualizado com sucesso.'
      }).then(resp => {
        if (!resp?.error) {
          onClose(resp?.rows?.[0] || resp);
          afterSubmit(resp?.rows?.[0] || resp);
        }
      });
    }
  };

  const _type = linkedItem ? linkedItem?.type : data?.type || type;

  const handleConfirmModal = confirmModalType => {
    const confirmModalPropsMap = {
      unlinkItem: {
        title: 'Desvincular item',
        alertInfo: 'Ao desvincular esse item, ele perderá a relação com a Biblioteca',
        preInfoDescription: 'Deseja concluir ação?',
        onSubmit: () => handleSaveLink(null)
      },
      editLibraryItem: {
        title: 'Todas as ocorrências serão editadas',
        alertInfo: (
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao editar esse item, os
            seguintes campos de todas as aparições desse item serão alteradas:`}
            afterAlertList={[
              'Nome do item',
              'Código',
              ...(_type === refurbishItemType.product ? ['Unidade', 'Imagem', 'Dimensões', 'Peso'] : [])
            ]}
          />
        ),
        preInfoDescription: 'Deseja confirmar?',
        onSubmit: () => handleSubmit()
      }
    };
    if (id) {
      setConfirmModalProps(confirmModalPropsMap[confirmModalType]);
    } else {
      confirmModalPropsMap[confirmModalType].onSubmit();
    }
  };

  const handleLinkClick = () => {
    if (linkedItem) {
      handleConfirmModal('unlinkItem');
      return;
    }
    setShowCatalogDrawer(true);
  };

  // const _itemMapping = () => {
  //   const _linkedData = linkedData || data?.item || data || initialData || {};

  //   return itemMapping({
  //     id,
  //     type: _type,
  //     isCustomer: isCustomer || readOnly,
  //     refurbishItemTypeEnum: refurbishItemType,
  //     columnsToShow: renderColumns,
  //     linkedItem,
  //     isLibrary,
  //     onSeeMoreClick: () => setShowAllLaborFields(true),
  //     showAllLaborFields,
  //     libraryProps: libraryProps || {},
  //     headerProps: {
  //       onEditItem: linkedItem && !isCustomer && !readOnly ? () => setShowEditLibraryItemModal(true) : null,
  //       showLinkInfo: !isLibrary
  //     },
  //     isMobile: isMobile(),
  //     data: _linkedData
  //   });
  // };

  // const getBudgetItemMappingWithBDIFormatted = () => {
  //   let bdiLabel = null;

  //   const laborBDI = data?.[isTemplate ? 'template' : 'refurbish']?.laborBdi;
  //   const productBDI = data?.[isTemplate ? 'template' : 'refurbish']?.productBdi;

  //   if (_type === refurbishItemType.labor && laborBDI) {
  //     bdiLabel = `BDI (Padrão do orçamento ${laborBDI}%)`;
  //   }

  //   if (_type === refurbishItemType.product && productBDI) {
  //     bdiLabel = `BDI (Padrão do orçamento ${productBDI}%)`;
  //   }

  //   return budgetItemMapping({
  //     id,
  //     type: _type,
  //     isCustomer: isCustomer || readOnly,
  //     refurbishItemTypeEnum: refurbishItemType,
  //     isTemplate,
  //     idReference,
  //     columnsToShow: renderColumns,
  //     parentType: data?.parent?.type || data?.parent?.[0]?.type,
  //     linkedItem,
  //     bdiLabel,
  //     isLibrary
  //   });
  // };

  const DetailsContent = useMemo(() => {
    const _data = editableData || data || initialData || {};
    const _linkedData = _data || linkedData || data?.item || data || initialData || {};
    return (
      <>
        {/* <FormContainer $itemInfo>
          <Form
            mapping={_itemMapping()}
            schema={itemSchema({})}
            displayButtons={false}
            isFormValid={setIsEditableFormValid}
            data={{
              ..._linkedData,
              units: _linkedData?.item?.units || _linkedData?.units,
              class: _linkedData?.item?.class || _linkedData?.class,
              // eslint-disable-next-line prettier/prettier
              height: formatCurrency(_linkedData?.height),
              width: formatCurrency(_linkedData?.width),
              length: formatCurrency(_linkedData?.length),
              weight: formatCurrency(_linkedData?.weight),
              socialCharges: _linkedData?.priceWithSocialCharges ? 'Não desonerado' : 'Desonerado'
            }}
            groupedReference={groupedReference}
            onFormChange={!linkedItem ? setLinkedData : undefined}
            forceUpdate={forceFormUpdate}
            validateOnRender
          />
        </FormContainer>
        {!onlyLabor && (
          <FormContainer>
            <Form
              mapping={getBudgetItemMappingWithBDIFormatted()}
              displayButtons={false}
              onFormChange={setEditableData}
              data={
                {
                  ..._data,
                  bdi: formatCurrency(_data?.bdi || 0),
                  price: formatCurrency(_data?.price || 0),
                  quantity: formatNumberWithDynamicDecimals(data?.quantity)
                } || {}
              }
              groupedReference={groupedReference}
            />
          </FormContainer>
        )} */}
        <FormContainer>
          <FormV2
            id="itemForm"
            mapping={itemMappingV2({
              isMobile: _isMobile,
              userType,
              refurbishItemTypeEnum: refurbishItemType,
              isTemplate,
              idReference,
              linkedItem,
              type: _type
            })}
            isFormValid={setIsEditableFormValid}
            onFormChange={setEditableData}
            data={{
              ..._linkedData,
              units: _linkedData?.item?.units || _linkedData?.units,
              class: _linkedData?.item?.class || _linkedData?.class,
               // eslint-disable-next-line prettier/prettier
              height: formatCurrency(_linkedData?.height),
              width: formatCurrency(_linkedData?.width),
              length: formatCurrency(_linkedData?.length),
              weight: formatCurrency(_linkedData?.weight),
              code: _linkedData?.code,
            }}
            schema={itemSchemaV2}
            displayButtons={false}
            readOnly={isCustomer}
          />
        </FormContainer>
      </>
    );
  }, [linkedItem, groupedReference, data, libraryProps?.checkedValue, linkedData]);

  const drawerFooter = (
    <>
      <Button onClick={onClose} text loading={loading}>
        Cancelar
      </Button>
      <div style={{ display: 'flex', gap: spaces.space1 }}>
        {!isLibrary && id && !isProductConciliation && (
          <Tooltip title={getLinkedItemText(data?.item)}>
            <Button
              id={`link-item${isChildrenDrawer && id ? `-${id}` : ''}`}
              type="light"
              loading={loading}
              onClick={handleLinkClick}
            >
              {`${linkedItem ? 'Desvincular' : 'Adicionar na biblioteca e salvar'}`}
            </Button>
          </Tooltip>
        )}
        <Button
          id={`submit-drawer${isChildrenDrawer && id ? `-${id}` : ''}`}
          type="primary"
          loading={loading}
          onClick={() => (isLibrary ? handleConfirmModal('editLibraryItem') : handleSubmit())}
        >
          Salvar
        </Button>
      </div>
    </>
  );

  const handleShareWhatsapp = () => {
    analytics.track({
      event: 'construct-schedule-item-shared',
      eventProperties: { ...propsForTrack, type: 'Whatsapp' }
    });
    window.open(
      `https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20platafo
      rma:%20${window.location.origin}/o/${id}`,
      '_blank'
    );
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Button onClick={handleShareWhatsapp} id="share-refurbish-item" text>
          Compartilhar no Whatsapp
        </Button>
      </Menu.Item>
      <Menu.Item>
        <CopyLinkButton
          customEvent="refurbish-item-shared"
          customEventProperties={{ ...propsForTrack, type: 'Copy' }}
          urlContext="o"
          idData={id}
          id="copy-link"
          text
        >
          Copiar link
        </CopyLinkButton>
      </Menu.Item>
    </Menu>
  );


  const editOptionsMenu = () => {
    const menuItems = [
      {
        id: 'edit-library-item',
        icon: faPenToSquare,
        text: 'Editar na biblioteca',
        onClick: () => {
          setShowEditLibraryItemModal(true);
          setShowComponents({ ...showComponents, showOptions: false });
        },
        shouldDisplayWhen: linkedItem
      },
      {
        id: 'duplicate-refurbish-item',
        icon: faCopy,
        text: 'Duplicar',
        onClick: async () => {
          const resp = await handleCreate({
            values: { id },
            postPathOptions: '/duplicate'
          });
          if (!resp?.error) {
            handleGet();
            onClose(false);
          }
        },
        shouldDisplayWhen: true
      },
      {
        id: 'update-from-library',
        icon: faArrowRotateRight,
        text: 'Atualizar item com a biblioteca',
        onClick: () => {
          setConfirmModalProps({
            title: 'Atualizar item',
            alertInfo: (
              <ConfirmModalAlertInfoBuilder
                alertText={`Ao atualizar esse item, os seguintes campos serão
                  substituídos pelas informações do item na Biblioteca:`}
                afterAlertList={[
                  'Custo',
                  'Fornecedor',
                  'Marca',
                  'BDI',
                  'Grupo',
                  'Categoria',
                  'Link',
                  'Descrição',
                  ...(_type === refurbishItemType.composition ? ['Itens dentro da composição'] : [])
                ]}
              />
            ),
            preInfoDescription: 'Deseja atualizar as informações do item?',
            onSubmit: async () => {
              if (!data?.idItem) return;

              const resp = await getLibraryItem({
                refetchPathOptions: `/${data.idItem}`,
                refetchOptions: {
                  attributes: [
                    'price',
                    'idSupplier',
                    'idRefurbishGroup',
                    'idCostCenter',
                    'brand',
                    'link',
                    'description'
                  ]
                }
              });

              if (!resp) return;

              const updateResp = await handleUpdate({
                updatePathOptions: `/${data?.id}/setChildrenFromItem`,
                displayToast: 'Operação realizada com sucesso!',
                values: {
                  ...resp,
                  idItem: data.idItem,
                  externalBaseCode: data.externalBaseCode
                },
                refresh: false
              });

              if (!updateResp?.error) {
                setLinkedData(null);
                setEditableData(null);
                setFiles([]);
                setImages([]);
                setName('');
                setObservation('');

                handleGet();
              }
            }
          });
        },
        shouldDisplayWhen: linkedItem
      },
      {
        id: 'unlink-library-item',
        icon: faBookmarkSlash,
        text: 'Desvincular da biblioteca',
        onClick: () => {
          handleLinkClick();
          setShowComponents({ ...showComponents, showOptions: false });
        },
        shouldDisplayWhen: linkedItem
      },
      {
        id: 'link-library-item',
        icon: faBookmark,
        text: 'Vincular com item da biblioteca',
        onClick: () => {
          handleLinkClick();
          setShowComponents({ ...showComponents, showOptions: false });
        },
        shouldDisplayWhen: !linkedItem
      },
      {
        id: 'delete-task',
        icon: faTrashCan,
        text: 'Excluir',
        type: 'danger',
        onClick: () => {
          setShowComponents({ ...showComponents, showDeleteModal: true });
        },
        shouldDisplayWhen: true
      }
    ];

    return (
    <Menu style={{ padding: `${spaces.space1} ${spaces.space2} ${spaces.space1} 0` }}>
      {menuItems.filter(item => item.shouldDisplayWhen).map(item => (
        <Menu.Item key={item.id} style={{ padding: '0' }}>
          <Button
            onClick={item.onClick}
            id={item.id}
            text
            type={item.type}
          >
            <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
              <FontAwesomeIcon icon={item.icon} />
            </Div>
            {item.text}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
    );
  };
  const headerComponents = [
    {
      icon: faMessage,
      title: 'Comentários',
      hasBadge: true,
      badgeCount: data?.comments?.length || 0,
      showOnMobile: true,
      shouldDisplayWhen: !isTemplate && !isUserAnonymous,
      isPressed: showComponents.showComments,
      onClick: () =>
        setShowComponents({
          ...showComponents,
          showComments: !showComponents.showComments
        })
    },
    {
      icon: faShareNodes,
      title: 'Compartilhar',
      menu,
      isOpen: showComponents?.showShareDropdown,
      onClick: v => setShowComponents({ ...showComponents, showShareDropdown: v })
    },
    {
      icon: faEllipsis,
      title: 'Opções',
      menu: editOptionsMenu,
      isOpen: showComponents?.showOptions,
      onClick: v => setShowComponents({ ...showComponents, showOptions: v })
    },
    {
      component: (
        <HowDoesItWorksButton
          onlyIcon
          placement="bottomLeft"
          iconStyle={{ width: spaces.space2, height: spaces.space2 }}
          style={{ width: spaces.space4 }}
        />
      )
    },
    {
      icon: faXmark,
      title: 'Fechar',
      onClick: () => onClose(false)
    }
  ];

  const sections = [
    ...(!isTemplate
      ? [
          {
            key: '0',
            title: 'Anexos',
            component: (
              <Div padding={spaces.space2} $fullWidth>
                <ItemAttachmentContent
                  readOnly={isCustomer}
                  hideTitle
                  hasDescription={false}
                  data={{ files }}
                  setFiles={setFiles}
                />
              </Div>
            )
          }
        ]
      : []),
          {
            key: '1',
            title: 'Imagens',
            component: (
              <Div
                padding={spaces.space2}
                direction="column"
                align="flex-start"
                $fullWidth
              >
                <Div gap={spaces.space1}>
                  <Subtitle>Imagens</Subtitle>
                  <TooltipIcon
                    icon={faInfoCircle}
                    iconColor={colors.primary500}
                    tooltipProps={{ placement: 'right' }}
                    text="Tamanho máximo: 50mb"
                  />
                </Div>
                <FileUpload
                  id="refurbish-item-image-input"
                  name="refurbish-item-image-input"
                  onChange={(newImages) => {
                    setImages([...newImages]);
                  }}
                  onRemove={(_, index) => {
                    const newImages = [...images];
                    newImages.splice(index, 1);
                    if (newImages.length > 0) {
                      setImages(newImages);
                    } else {
                      setImages([]);
                    }
                  }}
                  multiple
                  initialFiles={images.length !== 0 ? [...images] : [...(data?.images || [])]}
                  textArray
                  text="Adicionar imagem"
                  galleryType="new"
                  buttonProps={{ type: 'primary', $noIcon: true,  ...(!!linkedItem && { style: { display: 'none' } }) }}
                  galleryProps={{ disabled: !!linkedItem }}
                  buttonObrigatory
                  listType={null}
                  fullWidth
                  noPadding
                  onlyButtonOnEmpty={!linkedItem}
                  displayLabelOnlyWhenNotEmpty
                />
              </Div>
            )
          },
          {
            key: '2',
            title: 'Dados do fornecedor',
            component: (
              <Div padding={spaces.space2} direction="column" $fullWidth>
                <Description
                  title="Dados bancários"
                  mapping={mappingDescriptionConf.bank}
                  column={3}
                  data={data?.supplier || {}}
                  background={null}
                  className="form-component"
                  noPadding
                />
                <Description
                  style={{ paddingTop: spaces.space2, borderTop: `1px solid ${colors.neutral100}` }}
                  title="Dados da empresa"
                  mapping={mappingDescriptionConf.supplier}
                  data={data?.supplier || {}}
                  background={null}
                  noPadding
                  extra={
                    hasSupplier ? (
                      <Button text onClick={() => setEditSupplierId(data?.supplier?.id)}>
                        <FontAwesomeIcon icon={faPenToSquare} color={colors.primary600} />
                      </Button>
                    ) : null
                  }
                />
              </Div>
            )
          }
  ];


  return (
    <Drawer
      open
      title={
        <Div gap={spaces.space1}>
          <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
            <FontAwesomeIcon
              icon={(id ? detailTitle[_type] : createTitle[_type])?.icon}
              style={{ color: colors.neutral300 }}
            />
          </Div>
          <Title>{(id ? detailTitle[_type] : createTitle[_type])?.name}</Title>
          {linkedItem ? (
            <FlatTag icon={faBookmark} className="neutral" value="Biblioteca" size="medium" />
          ) : (
            <FlatTag icon={faBookmarkSlash} className="neutral" value="Desvinculado com a biblioteca" size="medium" />
          )}
        </Div>
      }
      onClose={() => onClose(false)}
      width={calcDrawerWidth(showComponents?.showComments, _isMobile)}
      padding="0"
      closeIcon={false}
      hideFooter={isCustomer || readOnly}
      extra={<DrawerHeaderComponents components={headerComponents} />}
      footer={readOnly ? [] : drawerFooter}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <ConfirmModal
            open={confirmModalProps}
            onClose={() => setConfirmModalProps(null)}
            title={confirmModalProps?.title}
            alertInfo={confirmModalProps?.alertInfo}
            description={confirmModalProps?.description}
            alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
            onSubmit={confirmModalProps?.onSubmit}
          />
          {showCatalogDrawer && (
            <CatalogDrawer
              open
              shouldConfirm
              tabsToOpen={[0, 1]}
              cardProps={{ addTooltipText: 'Vincular', addIcon: faLink, iconStyle: { margin: '-3.5px' } }}
              disableTypeFilter
              newItemTypeRedirect={_type}
              fixedType={refurbishItemType.productLabor}
              onClose={() => setShowCatalogDrawer(false)}
              subtitle="Busque o item da biblioteca para vincular com o item selecionado"
              onSubmit={val => {
                setShowCatalogDrawer(false);
                handleSaveLink(val);
              }}
            />
          )}
          {showEditLibraryItemModal && (
            <EditItemModal
              id={linkedItem?.id}
              type={linkedItem.type}
              isLibrary
              onlyLabor
              model="item"
              isChildrenDrawer
              onClose={() => {
                setShowEditLibraryItemModal(false);
              }}
              afterSubmit={() => {
                handleGet();
              }}
            />
          )}
          <Container>
          <Div direction="row" $fullHeight>
            <Div direction="column" $fullHeight $fullWidth>
              <Div $fullWidth>
                <EditableInput
                  value={name}
                  onChange={newName => {
                    setName(newName);
                  }}
                  disabled={isCustomer}
                  initEdit={data?.id === -1}
                  placeholder="Nome do item"
                  style={{
                    fontWeight: fonts.weight700,
                    fontSize: fonts.sizeLg,
                    width: '100%',
                    cursor: 'pointer',
                    padding: `${spaces.space1} ${spaces.space1} 0 ${spaces.space1}`,
                    color: name === '' || name === null || name === undefined ? colors.neutral400 : colors.neutral800
                  }}
                  InputStyle={{
                    fontWeight: fonts.weight700,
                    fontSize: fonts.sizeLg,
                    padding: `${spaces.space0} ${spaces.space1} ${spaces.space0} ${spaces.space1}`,
                  }}
                />
              </Div>
              {DetailsContent}
              <DrawerTabs sections={sections} selectedTab={selectedTab} onTabChange={setSelectedTab} />
            </Div>
            {!isTemplate && (
              <DrawerComments
                show={showComponents.showComments}
                onToggle={value => {
                  setShowComponents({ ...showComponents, showComments: value });
                }}
                data={data}
                model={model}
                tooltipText="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
        Tudo que você comentar aqui também será apresentado no Orçamento."
                isMobile={_isMobile}
              />
            )}
            {showComponents?.showDeleteModal && (
              <ConfirmModal
                text="Deseja realmente excluir essa tarefa?"
                onClose={() => setShowComponents({ ...showComponents, showDeleteModal: false })}
                onSubmit={() => {
                  handleDelete({ displayToast: 'Operação realizada com sucesso!', refresh: false }).then(resp => {
                    if (!resp?.error) {
                      handleGet();
                      onClose(false);
                    }
                  });
                }}
              />
            )}
          </Div>
          </Container>
          {hasSupplier && !hideSupplier && editSupplierId && (
            <EditOrCreateCustomerOrSupplier
              id={editSupplierId}
              model="supplier"
              onClose={() => {
                setEditSupplierId(null);
                handleGet();
              }}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

EditItemModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  model: PropTypes.string,
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  isTemplate: PropTypes.bool,
  groupedReference: PropTypes.instanceOf(Object),
  type: PropTypes.number,
  renderColumns: PropTypes.instanceOf(Object),
  isLibrary: PropTypes.bool,
  // onlyLabor: PropTypes.bool,
  libraryProps: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  isChildrenDrawer: PropTypes.bool,
  initialData: PropTypes.instanceOf(Object),
  isProductConciliation: PropTypes.bool,
  // hideAttachments: PropTypes.bool,
  // hideComments: PropTypes.bool
};

export default memo(EditItemModal);
