import React from 'react';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { name, text, idReference as idReferenceSchema } from '../schema';

import SelectDropdown from '../../../components/Dropdown/SelectDropdown';
import Button from '../../../components/Button/Button';
import { colors, spaces } from '../../../styles/style';

const schema = (isModal, showTemplateModule) =>
  Yup.object().shape({
    name: name.min(3, 'Nome deve ter pelo menos 3 caracteres'),
    ...(!isModal && { module: text }),
    description: text,
    type: text.required('Campo obrigatório'),
    category: text.required('Campo obrigatório'),
    ...(showTemplateModule && { module: text.required('Campo obrigatório') })
  });

const schemaApplyTemplate = () =>
  Yup.object().shape({
    idReference: idReferenceSchema.required('Campo obrigatório')
  });

const applyTemplateMapping = version => ({
  idReference: {
    name: 'Selecione',
    type: 'select',
    placeholder: 'Projetos',
    model: 'refurbish',
    modelOptions: {
      where: { version },
      include: [
        {
          model: 'RefurbishStatus',
          as: 'status',
          where: { isActive: true, idStage: 2 }
        }
      ],
      order: [['name', 'asc']]
    },
    md: 24
  },
  fieldsHeader: {
    type: 'fixed',
    md: 24
  },
  hasSchedule: {
    type: 'checkbox',
    name: 'Tarefas',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', gap: spaces.space1 }
  },
  hasBudget: {
    type: 'checkbox',
    name: 'Orçamento',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', gap: spaces.space1 }
  },
  hasPlanning: {
    type: 'checkbox',
    name: 'Planejamento',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', gap: spaces.space1 }
  },
  hasFolders: {
    type: 'checkbox',
    name: 'Pastas',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', gap: spaces.space1 }
  },
  hasNotes: {
    type: 'checkbox',
    name: 'Anotações',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', gap: spaces.space1 }
  }
});

const templateMapping = ({
  isMobile,
  isModal,
  idTemplate,
  templateModule,
  templateModuleOptions,
  templateTypeOptions,
  showTemplateModule,
  isOperator,
  handleModal,
  isOpportunity
}) => ({
  image: {
    name: 'Imagem de capa',
    tooltipText: 'Tamanho máximo 50mb',
    type: 'image',
    loading: true,
    size: '230px',
    md: isModal ? 7 : 6
  },
  name: {
    name: 'Nome do template *',
    type: 'text',
    placeholder: `Escreva aqui`,
    md: 9
  },
  type: {
    name: 'Tipo *',
    type: 'select',
    dataType: 'templateType',
    md: isModal ? 4 : 3,
    defaultValue: isOpportunity ? templateTypeOptions?.opportunity?.value : templateTypeOptions?.project?.value
  },
  category: {
    name: 'Categoria *',
    type: 'select',
    dataType: 'templateCategory',
    placeholder: 'Categoria',
    md: isModal ? 4 : 3
  },
  ...(showTemplateModule && {
    module: {
      name: 'Módulo *',
      type: 'select',
      dataType: 'templateModule',
      placeholder: 'Módulo',
      readOnly: !!idTemplate,
      md: 3
    }
  }),
  fixed: {
    type: 'fixed',
    md: isModal ? 7 : 6
  },
  description: {
    id: 'template-editor',
    name: 'Descrição do template',
    type: 'editor',
    placeholder: `Escreva aqui`,
    style: { ...(!isMobile && { marginTop: isModal ? '-200px' : '-185px' }) },
    autoSize: { maxRows: isModal ? 2 : 6 },
    height: 100,
    md: isModal ? 17 : 18,
    toolbar: false
  },
  ...(!isModal &&
    idTemplate &&
    templateModule === templateModuleOptions.all.value && {
      copyProjectLink: {
        type: 'custom',
        xs: 24,
        Component: () => (
          <>
            <Button
              id="copy-existing-project"
              type="text"
              onClick={() => handleModal()}
              style={{ color: `${colors.primary600}` }}
              icon={<FontAwesomeIcon icon={faCopy} />}
            >
              Copiar um projeto existente
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Button>
          </>
        )
      }
    }),
  ...(isModal &&
    templateModule !== templateModuleOptions.budget.value && {
      fixed4: {
        type: 'fixed',
        md: 7
      },
      fieldsHeader: {
        type: 'fixed',
        md: 17,
        style: { marginTop: '-96px' }
      },
      fixed5: {
        type: 'fixed',
        md: 7
      },
      hasSchedule: {
        type: 'checkbox',
        name: 'Tarefas',
        md: 3,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1, justifyContent: 'space-evenly', marginTop: '-88px' }
      },
      hasBudget: {
        type: 'checkbox',
        name: 'Orçamento',
        md: 3,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1, justifyContent: 'space-evenly', marginTop: '-88px' }
      },
      hasPlanning: {
        type: 'checkbox',
        name: 'Planejamento',
        md: 3,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1, justifyContent: 'space-evenly', marginTop: '-88px' }
      },
      hasFolders: {
        type: 'checkbox',
        name: 'Pastas',
        md: 3,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1, justifyContent: 'space-evenly', marginTop: '-88px' }
      },
      hasNotes: {
        type: 'checkbox',
        name: 'Anotações',
        md: 3,
        defaultValue: true,
        style: { display: 'flex', gap: spaces.space1, justifyContent: 'space-evenly', marginTop: '-88px' }
      }
    }),
  ...(isOperator && {
    ...(isModal && {
      fixed2: {
        type: 'fixed',
        md: isModal ? 0 : 6
      },
      operatorFieldsHeader: {
        type: 'text',
        readOnly: true,
        defaultValue: 'Outras opções',
        md: isModal ? 24 : 18
      }
    }),
    ...(!isModal && {
      fixed3: {
        type: 'fixed',
        md: 6
      }
    }),
    community: {
      type: 'checkbox',
      name: 'Comunidade',
      md: isModal ? 6 : 2,
      defaultValue: false,
      style: { display: 'flex', justifyContent: 'space-evenly', marginTop: '30px' }
    },
    ...(!isModal && {
      company: {
        name: 'Criado por',
        type: 'custom',
        md: 6,
        // eslint-disable-next-line react/prop-types
        Component: ({ value, setFieldCustom }) => {
          return (
            <SelectDropdown
              model="company"
              onSelect={selected => {
                setFieldCustom('company')(selected);
                setFieldCustom('idCompany')(selected?.id);
              }}
              disabled={!!idTemplate}
              canCreate={false}
              data={value}
            />
          );
        }
      }
    })
  })
});

export { schema, schemaApplyTemplate, templateMapping, applyTemplateMapping };
