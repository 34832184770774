import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Align.module.scss';

const Align = ({ align, children }) => {
  const classes = classNames({
    [css['c-align']]: true,
    [css[`c-align--${align}`]]: align
  });

  return <div className={classes}>{children}</div>;
};

Align.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  align: PropTypes.string
};

Align.defaultProps = {
  align: 'left'
};

Align.displayName = 'Align';

export default Align;
