import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCRUD from '../../_Hooks/useCRUD';
import { TabContent } from '../Modal/EditItemModal.styles';
import Filters from '../Filters/Filters';
import { images } from '../../lib/mapping/Tabs/catalogTab';
import { filterForm } from '../../lib/mapping/Form/filterForm';
import { isNumber, RangePrice } from '../../lib/helpers/helper';
import { colors, Div, spaces } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';
import TableNoContent from '../NoContent/TableNoContent';
import { columns } from '../../lib/mapping/TableOrList/shoppingCatalogColumns';
import TableOrListV2 from '../List/TableOrListV2';
import useViewport from '../../_Hooks/useViewport';
import { Context } from '../../contexts/GeneralContext';
import BudgetListFooter from '../MobileFooter/BudgetListFooter';
import ConfirmModal from '../Modal/ConfirmModal';

const ITEMS_PAGE = 500;

const BudgetListTab = ({
  onSelect,
  keyword,
  tabIndex,
  idRefurbish,
  itemsOnListMap,
  priceWithBdi,
  onlyProducts,
  createLibrary,
  createItemLibraryModal,
  setCreateItemLibraryModal
}) => {
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const { refurbishItemTypes, refurbishItemStatus } = useSelector(state => state.setup.systemData);
  const [queryString, setQueryString] = useState();
  const [hasSearched, setHasSearched] = useState(false);
  const [typeFilters, setTypeFilters] = useState([]);
  const [delayLoad, setDelayLoad] = useState();
  const [offset, setOffset] = useState(null);
  const displayList = useRef([]);
  const generateLoad = useRef(true);
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const { loading, handleGet, totalItems } = useCRUD({
    model: 'refurbish-items/budgetListByRef',
    immediatelyLoadData: false
  });

  const { list: levels } = useCRUD({
    model: 'refurbish-items',
    options: { where: { idParent: null, type: refurbishItemType.parent, idRefurbish }, order: [['order'], ['id']] }
  });

  const { list: suppliers } = useCRUD({
    model: 'supplier',
    options: { onlyMine: true, order: [['name'], ['id']] }
  });

  const { handleCreate: handleCreateLibraryItem, loading: loadingAddLibrary } = useCRUD({
    model: 'refurbish-items',
    immediatelyLoadData: false
  });

  const NoContentInfo = images[!hasSearched ? 'initialState' : 'notFound'];

  const handleFilter = _filters => {
    const {
      Tipo: _type = [],
      Preço: price,
      Nível: _idLevel = [],
      Status: _status = [],
      Fornecedores: _suppliers = []
    } = _filters;

    const { min, max } = price || {};
    const isRange = isNumber(min) && isNumber(max);

    const idLevel = _idLevel.map(({ value }) => value);
    const status = _status.map(({ value }) => value);
    const type = _type.map(({ value }) => value);
    const idSupplier = _suppliers.map(({ value }) => value);

    const newQueryString = {
      ...queryString,
      where: { price: RangePrice(isRange, min, max), type, idLevel, status, idSupplier }
    };

    const newTypeFilter = typeFilters.map(item => ({ ...item, checked: type.includes(item.value) }));
    setTypeFilters(newTypeFilter);
    setQueryString(newQueryString);
  };

  const handleSearchSuggestion = value => {
    setQueryString({ ...queryString, keyword: value, where: {} });
  };

  const handleScroll = () => {
    if (totalItems > displayList.current.length && !loading) {
      setOffset(prev => prev + 1);
      generateLoad.current = false;
    }
  };

  const handleLoad = () => {
    if (!queryString) return;

    const { keyword: _keyword, where } = queryString || {};
    const nameOrCode = _keyword ? [{ name: { like: `%${_keyword}%` } }, { code: { like: `%${_keyword}%` } }] : {};
    const type = where?.type?.length ? where?.type : refurbishItemType[onlyProducts ? 'product' : 'productLabor'];

    setDelayLoad(offset === 1);

    handleGet({
      refetchOptions: {
        where: { ...where, or: nameOrCode, type, idRefurbish },
        offset: offset || 1,
        limit: ITEMS_PAGE
      },
      generateLoading: generateLoad.current
    }).then(resp => {
      if (resp?.error) return;
      generateLoad.current = true;
      const newList = resp?.map(row => {
        const parentKey = row.idItem || `ri-${row.id}`;
        return {
          ...row,
          key: `${offset}_${parentKey}`,
          parentList: row?.parentList?.map(child => ({ ...child, key: `${offset}_${parentKey}-${child?.id}` }))
        };
      });

      displayList.current = offset > 1 ? [...displayList.current, ...newList] : newList;
      setDelayLoad(false);
    });
  };

  useEffect(() => {
    if (offset === 1) handleLoad();
    else setOffset(1);
  }, [queryString]);

  useEffect(() => handleLoad(), [offset]);

  useEffect(() => {
    if (tabIndex === -1 && keyword !== null) {
      setHasSearched(!!keyword);
      setQueryString({ ...queryString, keyword, where: {} });
    }
  }, [keyword, tabIndex]);

  useEffect(() => {
    if (refurbishItemTypes && refurbishItemTypes.length) {
      setTypeFilters(
        refurbishItemTypes?.filter(p => p.notParent)?.map(({ label, value }) => ({ checked: false, label, value }))
      );
    }
  }, [refurbishItemTypes]);

  const createLibraryAndAddItem = (data, index) => {
    handleCreateLibraryItem({ values: data, postPathOptions: '/copy', refresh: false }).then(libraryData => {
      if (libraryData?.error) {
        toast.error(libraryData?.error?.message || 'Erro ao criar item');
        return;
      }
      displayList.current = displayList.current?.map((item, idx) =>
        idx === index ? { ...data, idItem: libraryData?.id } : item
      );

      onSelect({ ...data, idItem: libraryData?.id });
    });
  };

  const _onSelect = (data, index) => {
    if (createLibrary && !data?.idItem && !data?.itemData?.idItem) {
      const item = data?.childId ? { ...data?.itemData, parentList: [data] } || data : data;
      setCreateItemLibraryModal({ open: true, item, index });
      return;
    }
    onSelect(data);
  };

  const _columns = useMemo(
    () =>
      columns({
        onSelect: _onSelect,
        refurbishItemType,
        itemsOnListMap,
        isMobile: _isMobile,
        priceWithBdi,
        loadingAddLibrary
      }),
    [onSelect, _onSelect, itemsOnListMap, loadingAddLibrary]
  );

  const tableComponent = (
    <Context
      data={{
        onSelect,
        itemsOnListMap
      }}
    >
      <TableOrListV2
        id="budgetListTable"
        columns={_columns}
        childrenColumnName="parentList"
        list={displayList.current}
        rowSelection={false}
        scroll={{ y: 'calc(100vh - 300px)', x: '100%' }}
        withCommonRow
        customEmptyState={() => <TableNoContent description="Nenhum item encontrado." renderButton={false} />}
        delayLoad={delayLoad}
        refreshColumns
        customKey={rec => rec.key}
        onReachEnd={handleScroll}
        $childRowColor={colors.neutral50}
        mobileItemFooter={BudgetListFooter}
      />
    </Context>
  );

  return (
    <TabContent>
      <Div justify="space-between">
        <Filters
          key="shoppingCatalogFilters"
          position="bottom-center"
          filters={filterForm.shoppingCatalog(typeFilters, levels, refurbishItemStatus, suppliers, onlyProducts)}
          onFilter={handleFilter}
          displaySorting={false}
        />
      </Div>

      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          {!displayList.current.length && delayLoad === false ? (
            <NoContentInfo keyword={keyword} onClick={handleSearchSuggestion} loading={loading} />
          ) : (
            <div style={{ paddingTop: spaces.space1 }} id="shopping-catalog">
              {tableComponent}
            </div>
          )}
          {createItemLibraryModal?.open ? (
            <ConfirmModal
              alertInfo="Item não vinculado à biblioteca."
              text={`Ao confirmar, o item "${createItemLibraryModal?.item?.name}" será salvo na sua biblioteca.`}
              onSubmit={() => createLibraryAndAddItem(createItemLibraryModal?.item, createItemLibraryModal?.index)}
              onClose={() => setCreateItemLibraryModal({ open: false, item: null, index: null })}
            />
          ) : null}
        </>
      )}
    </TabContent>
  );
};

BudgetListTab.propTypes = {
  onSelect: PropTypes.func,
  keyword: PropTypes.string,
  tabIndex: PropTypes.number,
  idRefurbish: PropTypes.number,
  itemsOnListMap: PropTypes.instanceOf(Object),
  priceWithBdi: PropTypes.bool,
  onlyProducts: PropTypes.bool,
  createLibrary: PropTypes.bool,
  createItemLibraryModal: PropTypes.instanceOf(Object),
  setCreateItemLibraryModal: PropTypes.func
};

export default BudgetListTab;
