import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPix } from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  ButtonRegisterPix,
  CheckBoxStyle,
  ContainerTitle,
  Content,
  DescriptionText,
  PixText,
  PriceText,
  Space
} from './CardPaymentType.styles';
import Card from './Card';
import { Div, colors, spaces } from '../../styles/style';
import Button from '../Button/Button';
import { customRound } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import AccountDataModal from '../Modal/AccountDataModal';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { useContextHook } from '../../contexts/GeneralContext';
import { HelperLink, Paragraph, Subtitle } from '../Text/Text';
import useCRUD from '../../_Hooks/useCRUD';
import formatCurrency from '../../lib/helpers/formatCurrency';

const CardPaymentType = ({
  value,
  onChange = f => f,
  paymentTypes = [],
  type,
  installmentCount,
  disabled,
  tooltipText,
  fees,
  id,
  idPayment,
  total
}) => {
  const { vobiPayPaymentType, userType, paymentCreditPermission } = useSelector(state => state.setup.enums);
  const { isMobile } = useViewport(window.innerWidth);
  const defaultChecked = paymentTypes?.length ? paymentTypes?.some(el => vobiPayPaymentType[type] === el) : false;
  const [checkbox, setCheckBox] = useState(defaultChecked);
  const [openModalPix, setOpenModalPix] = useState(false);
  const cardColor = checkbox ? colors.primary50 : colors.neutral50;

  const { _user, setUser } = useContextHook();

  const { data: company } = useCRUD({
    model: 'company',
    pathOptions: `/${_user?.idCompany}`,
    options: {
      attributes: ['id', 'creditLimit']
    },
    immediatelyLoadData: type === 'creditCard'
  });

  const isCustomer = !!_user?.anonymous || _user?.userType === userType.customer.value;

  const paymentTypeCheck = vobiPayPaymentType[type];

  const pixDisable = type === 'pix' && !_user?.company?.pix;

  const creditDisabled = company?.creditLimit === paymentCreditPermission.blocked || total > company?.creditLimit;

  const creditTextCard =
    company?.creditLimit === paymentCreditPermission.blocked
      ? 'Solicite a liberação das cobranças via cartão de crédito entrando em'
      : `O valor máximo de cobrança no cartão deve ser de ${formatCurrency(company?.creditLimit, {
          currencySymbol: 'R$'
        })}. Para aumentar o valor entre em`;

  const _disabled = pixDisable || (creditDisabled && type === 'creditCard') || disabled;

  const pixTax = fees?.payment?.pix?.fixedFeeValue || 0;
  const bankSlipTax = fees?.payment?.bankSlip?.defaultValue || 0;
  const creditTax = fees?.payment?.creditCard;
  const showPrice = !!value && !_disabled;

  const calculateCreditTax = () => {
    if (installmentCount === 1) {
      return creditTax?.oneInstallmentPercentage;
    }
    if (installmentCount > 1 && installmentCount < 7) {
      return creditTax?.upToSixInstallmentsPercentage;
    }
    return creditTax?.upToTwelveInstallmentsPercentage;
  };

  const renderTitle = {
    pix:
      _user?.company?.pix || isCustomer ? (
        'PIX'
      ) : (
        <Div>
          PIX não cadastrado
          <Button
            id="register-pix-card"
            onClick={() => setOpenModalPix(true)}
            type="primary"
            style={{ margin: '0 8px' }}
          >
            <FontAwesomeIcon icon={faPix} />
            Cadastrar Pix
          </Button>
        </Div>
      ),
    bankSlip: 'Boleto bancário',
    creditCard: 'Cartão de crédito'
  };

  const renderTaxText = {
    pix: _user?.company?.pix ? (
      <>
        Taxa de {formatCurrency(pixTax, { currencySymbol: 'R$' })} por <b>PIX</b> recebido. Receba poucos segundos após
        o pagamento.
      </>
    ) : (
      <PixText>
        <div>
          Receba em segundos através do <b style={{ paddingLeft: 2 }}>PIX</b>. Para habilitar,
          <ButtonRegisterPix text onClick={() => setOpenModalPix(true)}>
            cadastre a sua chave PIX aqui.
          </ButtonRegisterPix>
        </div>
      </PixText>
    ),
    bankSlip: (
      <>
        Taxa de {formatCurrency(bankSlipTax, { currencySymbol: 'R$' })} por <b>Boleto</b> recebido. Receba em 1 dia útil
        após o pagamento.
      </>
    ),
    creditCard:
      creditDisabled && !idPayment ? (
        <div>
          <Paragraph type="small">
            {creditTextCard} <HelperLink id="helpCreditLimit">contato com o suporte.</HelperLink>
          </Paragraph>
        </div>
      ) : (
        <Div>
          Taxa de {formatCurrency(calculateCreditTax())}% sobre o valor da cobrança +{' '}
          {formatCurrency(creditTax?.operationValue, { currencySymbol: 'R$' })} . Receba em 32 dias após o pagamento de
          cada parcela.
        </Div>
      )
  };

  const calculatePrice = () => {
    if (type === 'pix') {
      return (value - pixTax).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    if (type === 'bankSlip') {
      return (value - bankSlipTax).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    const tax = 1 - calculateCreditTax() / 100;
    const fixedValue = value?.toFixed(4);
    const intervalTax = (creditTax?.operationValue / installmentCount).toFixed(4);
    const installmentValue = fixedValue * tax - intervalTax;
    return customRound(installmentValue, 2).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const handleCheckboxChange = check => {
    let array = [...paymentTypes];
    if (check) {
      array = array.concat(paymentTypeCheck);
    } else {
      array = array.filter(element => element !== paymentTypeCheck);
    }
    onChange([...new Set(array)]);
  };

  useEffect(() => {
    handleCheckboxChange(checkbox);
  }, [checkbox]);

  const RenderPrice = (
    <PriceText id={`render-price-${type}`}>
      {`Valor líquido${installmentCount > 1 ? ' por parcela:' : ':'} R$ ${calculatePrice()}`}
      <TooltipIcon
        iconColor={colors.primary500}
        icon={faInfoCircle}
        text={`O valor líquido é o valor total menos as taxas cobradas,
        ele representa o quanto você receberá na sua conta da Vobi Pay`}
        style={{ padding: `0 ${spaces.space1}`, height: spaces.space2 }}
      />
    </PriceText>
  );

  const handleDivClick = () => {
    setCheckBox(!checkbox);
  };

  return (
    <>
      <Card
        id={id}
        bodyBackgroundColor={_disabled ? colors.neutral100 : cardColor}
        footerBackgroundColor={_disabled ? colors.neutral100 : cardColor}
        disabled={_disabled}
        footer={isMobile() && showPrice && RenderPrice}
        hover={!_disabled && !isCustomer}
        onClick={handleDivClick}
      >
        <Tooltip title={tooltipText} placement="topRight">
          <Space>
            <Content>
              <ContainerTitle>
                <Div>
                  {!isCustomer && !_disabled && (
                    <CheckBoxStyle
                      defaultChecked={checkbox}
                      disabled={_disabled}
                      checked={checkbox}
                      onChange={e => setCheckBox(e.target.checked)}
                    />
                  )}
                  <Subtitle disabled={_disabled}>{renderTitle[type]}</Subtitle>
                </Div>
                {!isMobile() && showPrice && RenderPrice}
              </ContainerTitle>

              <DescriptionText>{renderTaxText[type]}</DescriptionText>
            </Content>
          </Space>
        </Tooltip>
      </Card>
      {openModalPix && (
        <AccountDataModal
          withDispatch={false}
          onClose={pixData => {
            setOpenModalPix(false);
            setUser({ ..._user, company: { ..._user?.company, pix: pixData } });
          }}
        />
      )}
    </>
  );
};

CardPaymentType.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  paymentTypes: PropTypes.instanceOf(Array),
  type: PropTypes.string,
  installmentCount: PropTypes.number,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  fees: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  idPayment: PropTypes.string,
  total: PropTypes.number
};

export default CardPaymentType;
