import React from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import { colors, Div, fonts, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import formatCurrency from '../../lib/helpers/formatCurrency';

const ApportionmentTooltip = ({ list = [], extraValues = {} }) => {
  return list?.map((r, idx, array) => {
    const { allSplitDueDateDiscount = 0, allSplitInterest = 0 } = extraValues || {};
    const price = r?.value || r?.price || 0;
    const percentage = parseFloat(r?.percentage || r?.percentageSplitPrice || r?.percentageSplitValue || 0) / 100;
    const total = Number(price) + Number(allSplitInterest * percentage) - Number(allSplitDueDateDiscount * percentage);
    return (
      <Div direction="column">
        <Div
          key={`${r.id}-apportionment`}
          padding={`${spaces.space1} 0px`}
          $fullWidth
          justify="space-between"
          gap={spaces.space1}
          align=" start"
        >
          <Div width="156px" justify="baseline">
            <Paragraph type="small" weight={fonts.weight500}>
              {r?.refurbish?.name}
            </Paragraph>
          </Div>

          <Div width="156px" gap={spaces.space1} justify="end">
            <Paragraph type="small" color={colors.neutral500}>
              {`(${formatCurrency(r?.percentage || r?.percentageSplitPrice || r?.percentageSplitValue || 0, {
                decimalCount: 0
              })}%)`}
            </Paragraph>
            <Paragraph type="small" weight={fonts.weight500}>
              {formatCurrency(total, { currencySymbol: 'R$' })}
            </Paragraph>
          </Div>
        </Div>
        {idx !== array?.length - 1 && <Divider style={{ margin: '0px' }} />}
      </Div>
    );
  });
};

ApportionmentTooltip.propTypes = {
  list: PropTypes.instanceOf(Array)
};

export default ApportionmentTooltip;
