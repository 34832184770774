const parentAssociation = ({ data = [{}], key = 'number' }) => {
  const temp = {};
  data.forEach(obj => {
    temp[obj[key]?.trim()] = obj.name;
  });
  return data.map(obj => {
    const indexes = obj[key]?.trim()?.split('.');
    const parent = indexes?.length > 1 ? indexes?.slice(0, -1)?.join('.') : null;
    const grandParent = indexes?.length > 2 ? indexes.slice(0, -2).join('.') : null;
    const attrs = Object.keys(obj).reduce((acc, attr) => {
      return ['-', ''].includes(acc[attr]) ? { ...acc, [attr]: null } : acc;
    }, obj);
    return {
      ...attrs,
      parentName: temp[parent],
      granParentName: temp[grandParent]
    };
  });
};

export default parentAssociation;
