import styled from 'styled-components';
import { colors, spaces } from '../../styles/style';

const CustomBadge = styled.div`
  width: ${spaces.space2};
  height: ${spaces.space2};
  border-radius: ${spaces.space0};
  background-color: ${colors.neutral400};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  font-size: 10px;
`;

export { CustomBadge };
