import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faCheck, faXmark, faPlus } from '@fortawesome/pro-regular-svg-icons';

import EditableInput from '../../../components/Input/EditableInput';
import Input from '../../../components/Input/Input';

import { Comment, CommentsContainer, NewComment, SideContainer } from './WorkReport.style';
import { colors } from '../../../styles/style';
import Button from '../../../components/Button/Button';
import { Description, Subtitle } from '../../../components/Text/Text';

const WorkReportComments = ({ commentList, setCommentList, isView }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [currentComment, setCurrentComment] = useState('');

  const createComment = () => {
    setCommentList(prev => [...prev, currentComment]);
    setCurrentComment('');
  };

  const handleChangeComment = (index, value) => {
    setCommentList(prev => {
      prev.splice(index, 1, value);
      return [...prev];
    });
  };

  const removeComment = commentIndex => {
    setCommentList(prev => {
      prev.splice(commentIndex, 1);
      return [...prev];
    });
  };

  if (isView) {
    return (
      commentList?.length > 0 && (
        <>
          <SideContainer>
            <Subtitle>Comentários</Subtitle>
            <ul>
              {commentList?.map((comment, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`comment-${index}`} id={`comment-${index}`}>
                  {comment}
                </li>
              ))}
            </ul>
          </SideContainer>
          <Divider />
        </>
      )
    );
  }

  return (
    <>
      <CommentsContainer>
        <Subtitle>Comentários</Subtitle>
        <Description>Inclua aqui atividades, serviços e ocorrências relacionadas a visita realizada.</Description>
        {commentList?.map((comment, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Comment id={`comment-${index}`} key={`comment-${index}`}>
            <EditableInput value={comment} onChange={value => handleChangeComment(index, value)} initEdit={false} />
            <FontAwesomeIcon icon={faTrashCan} color={colors.red500} onClick={() => removeComment(index)} />
          </Comment>
        ))}
        {isCreating ? (
          <NewComment>
            <Input
              id="new-comment"
              autoFocus
              placeholder=""
              value={currentComment}
              type="text"
              onChange={e => setCurrentComment(e.target.value)}
              onPressEnter={createComment}
            />
            <FontAwesomeIcon icon={faCheck} color={colors.primary600} onClick={createComment} />
            <FontAwesomeIcon icon={faXmark} color={colors.red500} onClick={() => setIsCreating(false)} />
          </NewComment>
        ) : (
          <Button id="add-comment" text type="primary" onClick={() => setIsCreating(true)}>
            <FontAwesomeIcon icon={faPlus} /> Novo comentário
          </Button>
        )}
      </CommentsContainer>
      <Divider />
    </>
  );
};

WorkReportComments.propTypes = {
  commentList: PropTypes.instanceOf(Array),
  setCommentList: PropTypes.func,
  isView: PropTypes.bool
};

export default WorkReportComments;
