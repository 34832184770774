import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import Tabs, { TabPane } from '../../Tabs/Tabs';
import { Div, spaces } from '../../../styles/style';
import useViewport from '../../../_Hooks/useViewport';

const DrawerTabs = ({ sections, selectedTab, onTabChange }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  if (_isMobile) {
    return (
      <>
        {sections.map((section, index) => (
          <>
            {section.component}
            {index < sections.length - 1 && (
              <Div padding={`0 ${spaces.space2}`} width="100%">
                <Divider style={{ margin: `${spaces.space1} 0` }} />
              </Div>
            )}
          </>
        ))}
      </>
    );
  }

  return (
    <Tabs activeKey={selectedTab} onChange={onTabChange} fullHeight={false} width="100%">
      {sections.map(section => (
        <TabPane key={section.key} tab={section.title} forceRender>
          {section.component}
        </TabPane>
      ))}
    </Tabs>
  );
};

DrawerTabs.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired
    })
  ).isRequired,
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func
};

export default DrawerTabs;
