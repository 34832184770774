import styled from 'styled-components';
import { breakpoints, fonts, radius, spaces } from '../../styles/style';

export const Container = styled.div`
  background-image: url("${props => props.$image}");
  background-size: 100% 144px;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 144px;
  max-height: 144px;
  width: 100%;
  border-radius: ${radius.radius1};
  margin-bottom: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    min-height: 136px;
    max-height: 136px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px;
  }
`;
export const Gadient = styled.div`
  width: 100%;
  height: 144px;
  border-radius: ${radius.radius1};
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(47, 57, 78, 0.9) 2.26%,
    rgba(49, 61, 85, 0.8) 29.42%,
    rgba(59, 67, 84, 0) 100%
  );
  @media (max-width: ${breakpoints.tablet}) {
    height: 136px;
    border-radius: 0px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  margin-top: ${spaces.space9};
  margin-left: ${spaces.space2};
  align-items: center;
  position: absolute;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: ${spaces.space2};
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image:url("${props => props.$image}");
  background-color: #FFF;
  background-size: cover;
  background-position: center;
  width: 56px;
  height: 56px;
  border-radius: ${radius.radius1};
  border: 1px solid white;
  font-size: ${fonts.sizeXs};
  text-align: center;
  padding: 1px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spaces.space2};
  justify-content: center;
  @media (max-width: ${breakpoints.tablet}) {
    margin-left: 0px;
    margin-top: ${props => (props.$withoutLogo ? '56px' : spaces.space1)};
  }
`;
export const OfficeName = styled.p`
  font-size: ${fonts.sizeSm};
  font-weight: 600;
  color: #ffff;
  line-height: 15.06px;
`;
export const ProjectName = styled.div`
  font-size: ${fonts.sizeH6};
  font-weight: 400;
  color: #ffff;
  line-height: 25.1px;

  @media (max-width: ${breakpoints.tablet}) {
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${radius.radius1};
  position: relative;
  background-color: rgba(100, 100, 100, 0.9);
  @media (max-width: ${breakpoints.tablet}) {
    height: 136px;
    border-radius: 0px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: ${spaces.space1};
  padding-right: ${spaces.space1};
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-right: ${spaces.space0};

  button {
    padding-top: 0px !important;
  }
`;
