import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck as faCircleCheckRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faCircleCheckSolid } from '@fortawesome/pro-solid-svg-icons';

import { spaces } from '../../styles/style';
import Button from './Button';

const ApproveButton = ({ approved, onClick }) => {
  return (
    <Button
      size="small"
      type="primary"
      shape="round"
      {...(!approved && { ghost: true })}
      roundPadding={`0 ${spaces.space0} 0 ${spaces.space1}`}
      onClick={e => {
        e.stopPropagation();
        onClick(!approved);
      }}
    >
      {approved ? 'Aprovado' : 'Aprovar'}
      <FontAwesomeIcon icon={approved ? faCircleCheckSolid : faCircleCheckRegular} size="lg" />
    </Button>
  );
};

ApproveButton.propTypes = {
  approved: PropTypes.bool,
  onClick: PropTypes.func
};

export default ApproveButton;
