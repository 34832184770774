import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Modal as AntModal, Space } from 'antd';
import Button from '../Button/Button';
import { colors, fonts, breakpoints, spaces, radius } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';
import { Paragraph } from '../Text/Text';

const StyledModal = styled(AntModal)`
  max-width: 100%;
  padding-bottom: 0;
  border-radius: ${radius.radius1};

  .ant-modal-content {
    border-radius: ${radius.radius1};
    min-height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-modal-header {
    padding: ${spaces.space2};
    border-bottom: ${props => (props.$headerLine ? `1px solid ${colors.neutral100}` : 'none')};
    border-style: none;

    .ant-modal-title {
      color: ${colors.neutral700};
      font-family: ${fonts.family};
      font-size: ${fonts.sizeLg};
      font-weight: ${fonts.weight600};
      color: ${colors.neutral700};
      line-height: 1.5;
    }
  }

  .ant-divider {
    border-color: ${colors.neutral100};
  }

  .ant-modal-body {
    overflow: auto;
    padding-top: ${props => props.$subtitlePadding || 0}px;
    flex: 1;
    ${props => props.$bodyHeight && `max-height: ${props.$bodyHeight};`}
    ${props => !props.$noBorderTop && `border-top: 1px solid ${colors.neutral100};`}
    ${props =>
      props.$noPadding &&
      css`
        padding: 0;
        height: 100%;
      `}
    .mainSection {
      padding-top: ${props => (props.$noPadding ? 0 : 16)}px;
      height: 100%;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: ${props => (props.$alignButtonRight ? 'flex-end' : 'space-between')};
    background-color: ${colors.white};
    padding: ${spaces.space1};
    border-color: ${colors.neutral100};
  }

  @media (min-width: ${breakpoints.desktop}) {
    .ant-modal-title {
      font-size: ${fonts.sizeH5};
    }

    .ant-modal-footer {
      padding: ${spaces.space1} ${spaces.space2};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin: 0 auto;
    vertical-align: ${props => (props.$alwaysOnScreenCenter ? 'center' : 'bottom')} !important;

    .ant-modal-content {
      border-radius: ${radius.radius1} ${radius.radius1} 0 0;
    }

    .ant-modal-footer {
      background-color: ${colors.white};
    }

    .ant-modal-title {
      font-family: ${fonts.family};
    }

    .radio-options {
      color: ${colors.neutral600}
    }

    ${props =>
      props.left &&
      css`
        max-width: 87%;
        height: 100%;
        vertical-align: top !important;
        left: -7%;
        .ant-modal-content {
          border-radius: 0;
        }
        .ant-modal-close {
          left: 0;
        }
      `}
  }
`;

const Modal = ({
  title,
  subtitle,
  open,
  formId,
  onSubmit = () => {},
  onClose = () => {},
  width = 700,
  centered = true,
  submitText = 'Confirmar',
  cancelText = 'Cancelar',
  children,
  loading,
  loadingText,
  loaderStyle,
  footer,
  hideFooter,
  noPadding,
  height,
  left,
  sectionStyle,
  closable = true,
  headerLine,
  zIndex,
  hideSubmit,
  hideCancel,
  showDiscardButton = false,
  alignButtonRight = false,
  alwaysOnScreenCenter = false,
  auxButton = false,
  auxButtonText = 'Saiba mais',
  auxText,
  disableButton = false,
  typeCancel = 'text',
  noBorderTop = false,
  subtitlePadding = 0,
  bodyHeight,
  sectionClass
}) => {
  const _footer = footer || (
    <>
      {!hideCancel && (
        <Button onClick={onClose} loading={loading} type={typeCancel} id="cancel-submit-button">
          {cancelText}
        </Button>
      )}
      <Space size={8}>
        {auxText && <Paragraph type="small">{auxText}</Paragraph>}
        {showDiscardButton && (
          <Button
            id="discard-submit-button"
            type="danger"
            text
            onClick={() => onClose({ discard: true })}
            loading={loading}
            underline
          >
            Descartar
          </Button>
        )}
        {auxButton && (
          <Button text onClick={e => e.stopPropagation()} id="new-version-button">
            {auxButtonText}
          </Button>
        )}
        {!hideSubmit && (
          <Button
            id="confirm-submit"
            onClick={onSubmit}
            form={formId}
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={disableButton}
          >
            {submitText}
          </Button>
        )}
      </Space>
    </>
  );
  // eslint-disable-next-line no-restricted-globals
  const _width = width && isNaN(width) ? width : `${width}px`;
  // eslint-disable-next-line no-restricted-globals
  const _height = height && isNaN(height) ? height : `${height}px`;

  return (
    <StyledModal
      title={title}
      visible={open}
      onCancel={onClose}
      width={_width}
      centered={centered}
      footer={hideFooter ? null : _footer}
      $noPadding={noPadding}
      height={_height}
      $bodyHeight={bodyHeight}
      left={left}
      closable={closable}
      $headerLine={headerLine}
      zIndex={zIndex}
      $alignButtonRight={alignButtonRight}
      $alwaysOnScreenCenter={alwaysOnScreenCenter}
      $noBorderTop={noBorderTop}
      $subtitlePadding={subtitlePadding}
    >
      {subtitle ? <Paragraph>{subtitle}</Paragraph> : null}
      {loading ? (
        <CenteredLoader text={loadingText} style={loaderStyle} />
      ) : (
        <section style={sectionStyle} className={`${sectionClass || ''} mainSection`}>
          {children}
        </section>
      )}
    </StyledModal>
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  centered: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  loaderStyle: PropTypes.instanceOf(Object),
  footer: PropTypes.instanceOf(Object),
  hideFooter: PropTypes.bool,
  noPadding: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.bool,
  formId: PropTypes.string,
  sectionStyle: PropTypes.instanceOf(Object),
  closable: PropTypes.bool,
  headerLine: PropTypes.bool,
  zIndex: PropTypes.number,
  hideSubmit: PropTypes.bool,
  hideCancel: PropTypes.bool,
  showDiscardButton: PropTypes.bool,
  alignButtonRight: PropTypes.bool,
  alwaysOnScreenCenter: PropTypes.bool,
  auxButton: PropTypes.bool,
  auxButtonText: PropTypes.string,
  auxText: PropTypes.string,
  disableButton: PropTypes.bool,
  typeCancel: PropTypes.string,
  noBorderTop: PropTypes.bool,
  subtitlePadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bodyHeight: PropTypes.string,
  sectionClass: PropTypes.string
};

export default Modal;
