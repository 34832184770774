import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import Button from './Button';

const CopyLinkButton = ({
  onClose = () => {},
  eventButton,
  customEvent,
  urlContext,
  idData,
  customUrl,
  successText = 'Link copiado.',
  customGetLink,
  customEventProperties = {},
  ...params
}) => {
  const analytics = AnalyticsServiceFactory();

  const handleCopyLink = () => {
    if (customGetLink) {
      return customGetLink();
    }
    analytics.track({
      event: customEvent || `project-${eventButton}-copy-link`,
      eventProperties: customEventProperties
    });
    const urlToCopy = customUrl || `${window.location.origin}/${urlContext}/${idData}`;
    setTimeout(() => navigator.clipboard.writeText(urlToCopy), 0);
    return toast.success(successText);
  };

  return (
    <Button
      align="start"
      text
      {...params}
      onClick={e => {
        e.stopPropagation();
        handleCopyLink();
        onClose();
      }}
    />
  );
};

CopyLinkButton.propTypes = {
  onClose: PropTypes.func,
  eventButton: PropTypes.string,
  urlContext: PropTypes.string,
  idData: PropTypes.number,
  customUrl: PropTypes.string,
  underline: PropTypes.bool,
  height: PropTypes.string,
  customEvent: PropTypes.string,
  successText: PropTypes.string,
  customGetLink: PropTypes.func,
  customEventProperties: PropTypes.instanceOf(Object)
};

export default CopyLinkButton;
