import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { Input } from 'antd';
import { colors, Div, spaces } from '../../styles/style';
import Select from './Select';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { EditContainer } from '../Input/EditableInput.style';
import Button from '../Button/Button';
import { Line } from '../Line/Line';
import EditModalFactory from '../EditModalFactory';

const ItemSelect = ({
  value,
  disabled,
  allowPropagation = false,
  onBlur = f => f,
  onChange = f => f,
  onClick = f => f,
  isTemplate,
  initEdit,
  idCompany,
  handleDelete = f => f,
  placeholder = 'Pesquise na biblioteca ou adicione um item',
  isMobile
}) => {
  const [isEdit, setEdit] = useState(initEdit);
  const [_value, setValue] = useState(value?.name);
  const [editItemModalParams, setEditItemModalParams] = useState({});
  const isClick = useRef();

  const handleBlur = (newValue, key = 'name') => {
    if (value[key] !== newValue || value?.isVirtual) {
      onChange(value, key, !value?.type)(newValue);
    }

    setEdit(false);
    isClick.current = true;
    onBlur();
  };

  const handleKey = (e, searchValue, list = []) => {
    if (e.key === 'Escape') {
      setEdit(false);
      if (value?.isVirtual) handleDelete(value);
    }
    if (e.key === 'Tab') {
      handleBlur(searchValue || _value);
    }
    if (e.key === 'Enter' && !list?.length) {
      handleBlur(searchValue || _value);
    }
  };

  const handleClick = e => {
    if (!allowPropagation || isClick.current) e.stopPropagation();
    setEdit(!isClick.current);
    isClick.current = false;
  };

  return (
    <Div $fullWidth>
      {isEdit && !disabled ? (
        <>
          {!value?.id ? (
            <Select
              autoFocus
              id={`select${value?.id}`}
              model="item"
              modelOptions={{ where: { idCompany, type: value?.type, isActive: true } }}
              onChange={val => {
                handleBlur({ target: { value: val } }, 'idItem');
              }}
              value={value?.id}
              onClick={e => e.stopPropagation()}
              onBlur={(e, searchValue) => {
                if (isMobile && !e?.relatedTarget?.id) return;
                if (value?.isVirtual && !editItemModalParams?.show && e?.relatedTarget?.id !== 'delete-virtual-row')
                  handleBlur(searchValue);
              }}
              dropdownMatchSelectWidth
              notFoundContent={<div />}
              onKeyDown={handleKey}
              footerOptions={searchValue => (
                <div>
                  <Line style={{ margin: `${spaces.space1} 0` }} />
                  <Button
                    text
                    type="primary"
                    onMouseDown={e => {
                      e.stopPropagation();
                      handleBlur(searchValue);
                    }}
                  >
                    + Adicionar no orçamento
                  </Button>
                  <Button
                    text
                    type="primary"
                    onMouseDown={e => {
                      e.stopPropagation();
                      setEditItemModalParams({ show: true, values: { ...value, id: undefined, name: searchValue } });
                    }}
                  >
                    + Criar na bilioteca e adicionar ao orçamento
                  </Button>
                </div>
              )}
              placeholder={placeholder}
              open
            />
          ) : (
            <Input
              id={`select${value?.id}`}
              value={_value}
              onKeyDown={handleKey}
              onChange={e => setValue(e.target?.value)}
              onBlur={e => handleBlur(e.target?.value)}
              onClick={e => e.stopPropagation()}
              disabled={disabled}
              autoFocus
            />
          )}
        </>
      ) : (
        <EditContainer
          id={`item${value?.id}`}
          style={{ padding: spaces.space0 }}
          role="presentation"
          onMouseDown={e => {
            isClick.current = true;
            e.stopPropagation();
            onClick();
          }}
          tabIndex={-1}
          onFocus={handleClick}
        >
          {value?.name || _value || '-'}
          {!disabled && (
            <TooltipIcon
              style={{ height: spaces.space2, width: spaces.space2 }}
              className="renameItem showHover"
              text="Renomear"
              icon={faPenToSquare}
              iconColor={colors.neutral600}
              onClick={e => {
                isClick.current = false;
                handleClick(e);
              }}
            />
          )}
        </EditContainer>
      )}
      {editItemModalParams?.show ? (
        <EditModalFactory
          type={value?.type}
          model={isTemplate ? 'template-items' : 'refurbish-items'}
          idReference={value?.idRefurbish}
          onClose={() => {
            setEditItemModalParams({});
          }}
          groupedReference={editItemModalParams?.values?.groupedReference}
          initialValues={{ ...editItemModalParams?.values }}
          {...editItemModalParams}
          isTemplate={isTemplate}
        />
      ) : null}
    </Div>
  );
};

ItemSelect.propTypes = {
  value: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  allowPropagation: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isTemplate: PropTypes.bool,
  initEdit: PropTypes.bool,
  idCompany: PropTypes.number,
  handleDelete: PropTypes.func,
  placeholder: PropTypes.string,
  isMobile: PropTypes.bool
};

export default ItemSelect;
