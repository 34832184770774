export default {
  general: {
    default: 'Vobi'
  },
  login: {
    fallback: '',
    default: 'Vobi',
    profissional: 'Vobi | Profissional',
    operador: 'Vobi | Operador',
    cliente: 'Vobi | Cliente',
    cadastrar: 'Vobi | Cadastrar',
    'recuperar-senha': 'Vobi | Recuperar Senha'
  },
  profissional: {
    fallback: '',
    dashboard: 'Home',
    contatos: {
      clientes: 'Meus Clientes',
      fornecedores: 'Meus Fornecedores'
    },
    oportunidades: {
      default: 'Minhas Oportunidades',
      subpage: 'Oportunidade'
    },
    'gestao-tarefas': {
      tarefas: 'Tarefas',
      'gestao-horas': 'Gestão de Horas'
    },
    ferramentas: {
      'meus-templates': 'Templates'
    },
    catalogo: {
      default: 'Catálogo',
      categorias: 'Categorias',
      produtos: 'Produtos',
      produto: 'Produto'
    },
    'grupos-e-categorias': 'Grupos, Categorias e Unidades',
    biblioteca: 'Minha Biblioteca',
    projetos: {
      default: 'Meus Projetos',
      editar: 'Atualizar',
      novo: 'Novo projeto',
      subpage: 'Projeto'
    },
    'cadastro-financeiro': 'Financeiro',
    financeiro: {
      default: 'Financeiro',
      resumo: 'Resumo',
      cobrancas: 'Pagamentos do cliente',
      receitas: 'Receitas',
      despesas: 'Despesas',
      resultados: 'Resultados',
      relatorios: 'Relatórios',
      transferencias: 'Transferências',
      conciliacoes: 'Conciliações',
      extrato: 'Extrato'
    },
    configuracoes: {
      default: 'Configurações',
      'dados-pessoais': 'Dados Pessoais',
      'meu-negocio': 'Meu Negócio',
      'configurar-calendario': 'Calendário de trabalho',
      'trocar-senha': 'Trocar Senha',
      equipe: 'Minha Equipe',
      status: 'Status',
      vobipay: 'Vobi Pay',
      notificacoes: 'Notificações'
    },
    'meu-site': 'Meu Site',
    notificacoes: 'Notificações',
    moodboard: 'Moodboard',
    pagamentos: 'Financeiro de projetos',
    cobrancas: 'Financeiro',
    'diario-de-obra': {
      default: 'Diário de obra',
      subpage: 'Diário de obra'
    },
    'gestao-de-compras': {
      solicitacoes: 'Solicitação de compras',
      'ordem-de-compras': 'Ordem de compras',
      cotacoes: 'Cotação',
      default: 'Gestão de compras',
      subpage: 'Gestão de compras'
    },
    vobipay: {
      resumo: 'Resumo',
      cobrancas: 'Cobranças',
      extrato: 'Extrato',
      abertura: 'Criação de conta',
      suporte: 'Suporte',
      'em-analise': 'Em análise',
      'comprar-creditos': 'Vobi Pay | Adicionar créditos',
      'reenviar-cobrancas': 'Vobi Pay | Notificações de cobrança'
    },
    'guia-implantacao': 'Começando com a Vobi',
    'vobi-cred': 'Vobi Cred',
    'relatorio-comercial': 'Relatório Comercial',
    relatorios: 'Detalhes'
  },
  cliente: {
    fallback: '',
    dashboard: 'Dashboard',
    orcamento: 'Orçamento',
    projeto: {
      default: 'Projeto',
      geral: 'Projeto',
      arquivos: 'Arquivos',
      propostas: 'Propostas'
    },
    'meus-dados': 'Gerenciamento Conta',
    'compras-e-contratacoes': 'Compras e contratações',
    solicitacoes: 'Solicitações de pagamento',
    arquivos: 'Arquivos',
    propostas: 'Propostas',
    obra: {
      planejamento: 'Planejamento de obra'
    },
    'vobi-cred': 'Vobi Cred',
    'gestao-de-compras': {
      cotacoes: 'Cotação'
    },
    relatorios: 'Detalhes'
  },
  operador: {
    fallback: '',
    dashboard: 'Dashboard',
    orcamento: {
      ambientes: 'Ambientes',
      composicoes: 'Composições',
      'centros-de-custo': 'Centro de Custo',
      marcas: 'Marcas',
      materiais: 'Materiais',
      orcamentos: 'Orçamentos',
      servicos: 'Serviços',
      'tipos-de-ambientes': 'Tipos de Ambientes',
      'tipos-de-mao-de-obra': 'Tipos de Mão de Obra',
      'tipos-de-materiais': 'Tipos de Materiais',
      'tipos-de-servicos': 'Tipos de Serviços'
    },
    obra: {
      projetos: 'Projetos'
    },
    usuarios: {
      clientes: 'Clientes',
      operadores: 'Operadores',
      profissionais: 'Profissionais'
    },
    configuracao: {
      pipedrive: 'Exportar Pipedrive',
      search: 'Configurações do Search '
    },
    'meus-dados': 'Gerenciamento de Conta'
  }
};
