export const ptBR = {
  uploadStep: {
    title: 'Carregar arquivo',
    manifestTitle: 'Dados que nós esperamos:',
    manifestDescription: `(As colunas marcadas com * são informações obrigatórias. Carregue o seu arquivo e
    você poderá relacionar as colunas com os campos no próximo passo)`,
    maxRecordsExceeded: maxRecords => `Muitos registros. É permitido até ${maxRecords} registros`,
    dropzone: {
      title: 'Carregue arquivo .xlsx, .xls ou .csv',
      errorToastDescription: 'carregamento rejeitado',
      activeDropzoneTitle: 'Solte o arquivo aqui...',
      buttonTitle: 'Selecionar arquivo',
      loadingTitle: 'Processando...'
    },
    selectSheet: {
      title: 'Selecionar a planilha a ser usada',
      nextButtonTitle: 'Próximo',
      backButtonTitle: 'Voltar'
    }
  },
  selectHeaderStep: {
    title: 'Selecione a linha do cabeçalho',
    nextButtonTitle: 'Próximo',
    backButtonTitle: 'Voltar'
  },
  matchColumnsStep: {
    title: 'Corresponder Colunas',
    nextButtonTitle: 'Próximo',
    backButtonTitle: 'Voltar',
    userTableTitle: 'Sua tabela',
    templateTitle: 'Se tornará',
    selectPlaceholder: 'Selecionar coluna',
    ignoredColumnText: 'Coluna ignorada',
    subSelectPlaceholder: 'Selecione',
    matchDropdownTitle: 'Relacione seus valores com',
    unmatched: 'opção(ões) encontrada no arquivo',
    duplicateColumnWarningTitle: 'Outra coluna desmarcada',
    duplicateColumnWarningDescription: 'Colunas não podem ser duplicadas'
  },
  validationStep: {
    title: 'Validar dados',
    nextButtonTitle: 'Confirmar',
    backButtonTitle: 'Voltar',
    noRowsMessage: 'Nenhum dado encontrado',
    noRowsMessageWhenFiltered: 'Nenhum dado contém erros',
    discardButtonTitle: 'Descartar linhas selecionadas',
    filterSwitchTitle: 'Mostrar somente linhas com erros'
  },
  alerts: {
    confirmClose: {
      headerTitle: 'Sair do fluxo de importação',
      bodyText: 'Tem certeza? Suas informações atuais não serão salvas.',
      cancelButtonTitle: 'Cancelar',
      exitButtonTitle: 'Sair'
    },
    submitIncomplete: {
      headerTitle: 'Erros detectados',
      bodyText: 'Ainda existem algumas linhas que contêm erros. Linhas com erros serão ignoradas no envio.',
      bodyTextSubmitForbidden: 'Ainda existem algumas linhas contendo erros.',
      cancelButtonTitle: 'Cancelar',
      finishButtonTitle: 'Enviar',
      backButtonTitle: 'Voltar'
    },
    unmatchedRequiredFields: {
      headerTitle: 'Nem todas as colunas correspondem',
      bodyText: 'Há colunas obrigatórias que não correspondem ou são ignoradas. Você quer continuar?',
      listTitle: 'Colunas não correspondidas:',
      cancelButtonTitle: 'Cancelar',
      continueButtonTitle: 'Continuar',
      backButtonTitle: 'Voltar'
    },
    toast: {
      error: 'Erro'
    }
  }
};
