import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { fonts, colors, spaces, breakpoints } from '../../styles/style';
import { Subtitle, Title } from '../Text/Text';

const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${colors.neutral100};
  padding: ${spaces.space2};
  gap: ${spaces.space3};

  @media (max-width: ${breakpoints.mobile}) {
    min-width: 120px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    span {
      font-size: ${fonts.sizeSm};
      margin: 2px 0;
    }
  }
`;

const TemplateFilter = ({ currentFilters, setFilter }) => {
  const { templateType, templateModule, templateCategory } = useSelector(state => state.setup.systemData);

  return (
    <FilterSection direction="column">
      <Title>Filtros</Title>
      <div>
        <Subtitle>Tipo</Subtitle>
        <Checkbox.Group value={currentFilters?.type} options={templateType} onChange={e => setFilter({ type: e })} />
      </div>
      <div>
        <Subtitle>Módulo</Subtitle>
        <Checkbox.Group
          value={currentFilters?.module}
          options={templateModule}
          onChange={e => setFilter({ module: e })}
        />
      </div>
      <div>
        <Subtitle>Categoria</Subtitle>
        <Checkbox.Group
          value={currentFilters?.category}
          options={templateCategory}
          onChange={e => setFilter({ category: e })}
        />
      </div>
    </FilterSection>
  );
};

TemplateFilter.propTypes = {
  currentFilters: PropTypes.instanceOf(Object),
  setFilter: PropTypes.func
};

export default TemplateFilter;
