import React, { useState, useEffect } from 'react';

// components
import AttachmentList from '../List/AttachmentList';
import FileUpload from '../File/FileUpload';
import { spaces } from '../../styles/style';
import { useContextHook } from '../../contexts/GeneralContext';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { Description, Subtitle } from '../Text/Text';
import { Content } from '../../_Pages/Payments/Payment.style';

const PaymentAttachmentContent = () => {
  const { values, isView, setField, isCreate } = useContextHook();
  const [files, setFiles] = useState(values?.files || []);
  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
  };

  useEffect(() => {
    setField('files')(files, true);
  }, [files]);

  return !isView ? (
    <Content>
      <Subtitle>Anexos</Subtitle>
      <div>
        <FileUpload
          style={{ marginTop: 0, marginLeft: `-${spaces.space1}` }}
          id="file-input"
          onChange={handleAddFile}
          multiple
          showGallery={false}
          text="Adicionar anexo"
          listType={null}
          buttonProps={{ type: 'primary' }}
          fullWidth
        />
        <Description>(Tamanho máximo 10mb)</Description>
      </div>
      <AttachmentList
        preventUpload
        files={files}
        setFiles={setFiles}
        enumsName="systemData"
        style={{ width: '100%' }}
      />
    </Content>
  ) : (
    <SimpleAccordion title="Anexos" initOpen={!!files.length || isCreate} id="payment-attachments" withPaddingContainer>
      <div style={{ maxWidth: '500px' }}>
        <AttachmentList preventUpload files={files} setFiles={setFiles} readOnly={isView} enumsName="systemData" />
      </div>
    </SimpleAccordion>
  );
};

export default PaymentAttachmentContent;
