import React, { useState } from 'react';

import PropTypes from 'prop-types';

import PreviewModal from './PreviewModal';

const ImageModal = ({ fileList, startIndex, onClose, hideSideBar }) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(startIndex);

  const directionFileFunc = direction => {
    const endIndexFile = fileList?.length - 1;

    if (direction) {
      setCurrentFileIndex(currentFileIndex + 1 > endIndexFile ? 0 : currentFileIndex + 1);
    } else {
      setCurrentFileIndex(currentFileIndex - 1 < 0 ? endIndexFile : currentFileIndex - 1);
    }
  };

  const currentFile = fileList[currentFileIndex];

  return (
    <PreviewModal
      size="90%"
      directionFile={directionFileFunc}
      hideSideBar={hideSideBar}
      file={currentFile}
      onToggleModal={onClose}
      isModuleShared
      isModal
      onlyInfo
    />
  );
};

ImageModal.propTypes = {
  onClose: PropTypes.func,
  fileList: PropTypes.instanceOf(Array),
  startIndex: PropTypes.number,
  hideSideBar: PropTypes.bool
};

export default ImageModal;
