import styled from 'styled-components';
import { Checkbox } from 'antd';
import { colors, fonts, spaces, breakpoints } from '../../styles/style';

const Container = styled.div`
  padding: 0 ${spaces.space2};
`;

const DescriptionText = styled.span`
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeSm};
  line-height: 18px;
  strong {
    font-weight: ${fonts.weight600};
  }
  color: ${props => (props.disabled ? colors.neutral400 : colors.neutral500)};
`;

const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckBoxStyle = styled(Checkbox)`
  .ant-checkbox {
    margin-right: ${spaces.space2};
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: ${spaces.space2};
  flex-direction: column;
`;

const Space = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PixText = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.gap && props.gap};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
`;

const ButtonRegisterPix = styled.span`
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeSm};
  color: ${colors.primary500};
  padding-left: 2px;
  cursor: pointer;
`;

const PriceText = styled.span`
  font-size: ${fonts.sizeMd};
  color: ${colors.neutral500};
  line-height: ${spaces.space3};
  font-weight: ${fonts.weight500};
  white-space: nowrap;
  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeSm};
    white-space: unset;
  }
`;

export {
  Container,
  CheckBoxStyle,
  Content,
  Space,
  PixText,
  ButtonRegisterPix,
  PriceText,
  ContainerTitle,
  DescriptionText
};
