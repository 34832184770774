import React from 'react';
import PropTypes from 'prop-types';
import { Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Loader from './Loader';

const CenteredLoader = ({ text = 'Carregando...', style }) => (
  <Div margin="auto" style={style} width="fit-content" $fullHeight padding={spaces.space2}>
    <Loader />
    {text ? <Paragraph>{text}</Paragraph> : null}
  </Div>
);

CenteredLoader.propTypes = {
  text: PropTypes.string,
  style: PropTypes.instanceOf(Object)
};

export default CenteredLoader;
