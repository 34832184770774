import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Segmented, Tooltip } from 'antd';
import { breakpoints, colors, spaces, fonts } from '../../styles/style';

const SegmentComponent = styled(Segmented)`
  width: ${props => (props.width ? props.width : '256px')};
  font-size: ${fonts.sizeSm};
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin-bottom: ${spaces.space1};
  }
  .ant-segmented {
    background-color: white;
    padding: 1px;
    border: 1px solid ${colors.neutral100};
    margin: 0;
    width: 100%;
  }
  .ant-segmented-item {
    transition: color 0s;
    margin: 0;
    width: 50%;
  }
  .ant-segmented-item-selected {
    background-color: ${colors.primary600};
    color: ${colors.white};
  }

  .ant-segmented-thumb {
    background-color: ${colors.primary600};
  }

  .ant-segmented-item.ant-segmented-item-disabled:not(.ant-segmented-item-selected) {
    background-color: ${colors.neutral200};
    color: ${colors.neutral500};
  }
`;

const Segment = ({ id, options, value, onChange, ...props }) => {
  const renderOptionsWithTooltip = options.map(option => ({
    ...option,
    label: (
      <Tooltip title={option.tooltip}>
        <span>{option.label}</span>
      </Tooltip>
    ),
    value: option.value
  }));
  return <SegmentComponent id={id} options={renderOptionsWithTooltip} value={value} onChange={onChange} {...props} />;
};

Segment.propTypes = {
  id: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  value: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string
};

export default Segment;
