import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons';

import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dayjs from 'dayjs';
import MaskedInput from '../Input/NewMaskedInput';
import { Paragraph, Subtitle } from '../Text/Text';
import { Div, colors, spaces } from '../../styles/style';
import Segment from '../Select/Segmented';
import formatNumber from '../../lib/helpers/formatNumber';
import formatCurrency from '../../lib/helpers/formatCurrency';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import TooltipIcon from '../Tooltip/TooltipIcon';
import Select from '../Select/Select';
import { InfoMessage } from '../../_Pages/Projects/FinancialPhysics.style';
import useViewport from '../../_Hooks/useViewport';
import WarningBar from '../Alert/WarningBar';
import { Content } from '../../_Pages/Payments/Payment.style';
import { Line } from '../Line/Line';

const FineInterestDiscount = ({
  values,
  onChangeValues = f => f,
  idButton = 'HowJMDWorks',
  total,
  isPayment,
  isVobiPayConfiguration
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { fineInterestDiscountDays } = useSelector(state => state.setup.systemData);
  const { fineInterestDiscountType } = useSelector(state => state.setup.enums);
  const { vobiPayPaymentType } = useSelector(state => state.setup.enums);
  const { percentage, fixed } = fineInterestDiscountType || {};
  const { paymentTypes, installmentCount } = values;
  const showWarning = paymentTypes?.includes(vobiPayPaymentType.creditCard) && installmentCount > 1;
  const disableFields = showWarning && paymentTypes?.length === 1;
  const typeOptions = [
    { value: percentage?.value, label: percentage?.label },
    { value: fixed?.value, label: fixed?.label }
  ];

  const today = dayjs().format('YYYY-MM-DD');
  const diff = dayjs(values?.dueDate).diff(today, 'days');
  const limitDaysAlertDueDate = diff < values?.dueDateLimitDays;

  useEffect(() => {
    if (disableFields) {
      onChangeValues({ field: 'fine', value: 0 });
      onChangeValues({ field: 'interest', value: 0 });
      onChangeValues({ field: 'dueDateDiscount', value: 0 });
    }
  }, []);

  const renderInput = ({ field, type, value, onChange = f => f, disabled }) => {
    const isPercentage = type === percentage?.value;
    const maskOptions = isPercentage
      ? {
          suffix: '%',
          prefix: '',
          allowDecimal: true
        }
      : {
          prefix: 'R$',
          allowDecimal: true
        };
    return (
      <MaskedInput
        type="number"
        placeholder={isPercentage ? '0,00%' : 'R$0,00'}
        maskOptions={maskOptions}
        disabled={disabled}
        value={formatCurrency(value)}
        onBlur={e => {
          if (isPayment) return onChangeValues({ field, value: formatNumber(e.target.value) });
          return onChange(prev => ({ ...prev, [field]: formatNumber(e.target.value) }));
        }}
        style={{ minWidth: isMobile() ? '100%' : '256px' }}
      />
    );
  };

  const onChangeTypes = ({ key, value, field }) => {
    if (isPayment) {
      onChangeValues({ field: key, value });
      return onChangeValues({
        field,
        value: formatNumber(
          (value !== percentage?.value ? (values?.[field] / 100) * total : (values?.[field] / total) * 100).toFixed(2)
        )
      });
    }

    return onChangeValues(prev => ({ ...prev, [key]: value }));
  };

  const Interest = (
    <Div gap={spaces.space2} direction="column" align="start">
      {isVobiPayConfiguration || showWarning ? (
        <Div justify="space-between" $fullWidth>
          {isVobiPayConfiguration && (
            <>
              <Paragraph type="small">
                Insira abaixo os valores que serão sugeridos na criação das suas cobranças Vobi Pay.
              </Paragraph>
              <HowDoesItWorksButton id={idButton} className="hideMobile" />
            </>
          )}

          {showWarning && (
            <WarningBar
              warning="Juros, multa e desconto não estão disponíveis para cobranças parceladas no cartão de crédito"
              width="100%"
            />
          )}
        </Div>
      ) : null}

      <Div gap={spaces.space1} direction="column" align="start">
        <Div justify="space-between" $fullWidth>
          <Subtitle>Juros</Subtitle>
          {!isVobiPayConfiguration ? <HowDoesItWorksButton id={idButton} className="hideMobile" /> : null}
        </Div>

        <Paragraph type="small">
          Cobre juros caso o pagamento não seja efetuado até a data de vencimento. O valor da parcela será atualizado
          automaticamente todos os dias até que o pagamento seja feito.
        </Paragraph>
        <Div gap={spaces.space2}>
          <Div direction="column" align="start">
            <Paragraph type="small">Percentual de juros ao mês</Paragraph>
            {renderInput({
              field: 'interest',
              type: percentage?.value,
              value: values?.interest,
              onChange: onChangeValues,
              disabled: disableFields
            })}
          </Div>
          {isPayment && (
            <Div direction="column" align="start">
              <Paragraph type="small">Valor de juros ao mês</Paragraph>
              {renderInput({
                field: 'interest',
                type: fixed?.value,
                value: total * (values?.interest / 100),
                disabled: true
              })}
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );

  const Fine = (
    <Div gap={spaces.space1} direction="column" align="start">
      <Subtitle>Multa</Subtitle>

      <Paragraph type="small">
        Se o pagamento for efetuado após a data de vencimento, a multa será somada ao valor da parcela.
      </Paragraph>

      <Segment
        id="fineType"
        options={typeOptions}
        value={values?.fineType}
        onChange={e => onChangeTypes({ key: 'fineType', value: e, field: 'fine' })}
      />
      <Div gap={spaces.space2}>
        <Div direction="column" align="start">
          <Paragraph type="small">
            {`${isPayment || values?.fineType === percentage?.value ? 'Percentual' : 'Valor'} da multa`}
          </Paragraph>
          {renderInput({
            field: 'fine',
            type: isPayment ? percentage?.value : values?.fineType,
            value: isPayment && values?.fineType !== percentage?.value ? (values?.fine / total) * 100 : values?.fine,
            onChange: onChangeValues,
            disabled: (isPayment && values?.fineType !== percentage?.value) || disableFields
          })}
        </Div>
        {isPayment && (
          <Div direction="column" align="start">
            <Paragraph type="small">Valor da multa</Paragraph>
            {renderInput({
              field: 'fine',
              type: fixed?.value,
              value: values?.fineType === percentage?.value ? total * (values?.fine / 100) : values?.fine,
              onChange: onChangeValues,
              disabled: values?.fineType === percentage?.value || disableFields
            })}
          </Div>
        )}
      </Div>
    </Div>
  );

  const Percentage = (
    <Div gap={spaces.space1} direction="column" align="start" $fullWidth>
      <Subtitle>Desconto por pagamento antecipado</Subtitle>
      <Paragraph type="small">
        Ofereça um desconto em cada parcela da cobrança para incentivar seus clientes a realizar o pagamento antes do
        vencimento.
      </Paragraph>
      <Segment
        id="fineType"
        options={typeOptions}
        value={values?.dueDateDiscountType}
        onChange={e => onChangeTypes({ key: 'dueDateDiscountType', value: e, field: 'dueDateDiscount' })}
      />
      <Div gap={spaces.space2}>
        <Div direction="column" align="start">
          <Paragraph type="small">
            {`${isPayment || values?.dueDateDiscountType === percentage?.value ? 'Percentual' : 'Valor'} do desconto`}
          </Paragraph>
          {renderInput({
            field: 'dueDateDiscount',
            type: isPayment ? percentage?.value : values?.dueDateDiscountType,
            value:
              isPayment && values?.dueDateDiscountType !== percentage?.value
                ? (values?.dueDateDiscount / total) * 100
                : values?.dueDateDiscount,
            onChange: onChangeValues,
            disabled: (isPayment && values?.dueDateDiscountType !== percentage?.value) || disableFields
          })}
        </Div>
        {isPayment && (
          <Div direction="column" align="start">
            <Paragraph type="small">Valor do desconto</Paragraph>
            {renderInput({
              field: 'dueDateDiscount',
              type: fixed?.value,
              value:
                values?.dueDateDiscountType === percentage?.value
                  ? (values?.dueDateDiscount / 100) * total
                  : values?.dueDateDiscount,
              onChange: onChangeValues,
              disabled: values?.dueDateDiscountType === percentage?.value || disableFields
            })}
          </Div>
        )}
      </Div>
      {isPayment && (
        <Div direction="column" align="start" gap={spaces.space0} $fullWidth>
          <Div gap={spaces.space1}>
            <Paragraph type="small">Prazo máximo do desconto</Paragraph>
            <TooltipIcon
              style={{ color: colors.primary600 }}
              icon={faInfoCircle}
              color={colors.primary600}
              text="Após este prazo, o desconto não será mais válido"
            />
          </Div>
          <Div gap={spaces.space1} $fullWidth wrap="wrap">
            <Select
              id="select-dueDateDiscountDays"
              options={fineInterestDiscountDays}
              defaultValue={values?.dueDateLimitDays || 0}
              onChange={value => onChangeValues({ field: 'dueDateLimitDays', value })}
              style={{ maxWidth: isMobile() ? '100%' : '256px' }}
              disabled={disableFields}
            />
            {limitDaysAlertDueDate ? (
              <InfoMessage alert $paddingRight={spaces.space1} $noMargin>
                <FontAwesomeIcon icon={faInfoCircleSolid} />
                <Paragraph type="small" colors={colors.neutral600}>
                  {`O vencimento da primeira parcela ocorrerá  ${diff === 0 ? 'hoje' : `em ${diff} dia(s)`}. `}
                </Paragraph>
              </InfoMessage>
            ) : null}
          </Div>
        </Div>
      )}
    </Div>
  );

  return (
    <Div direction="column" gap={spaces.space0} $fullWidth>
      <Content>{Interest}</Content>
      <Line />
      <Content>{Fine}</Content>
      <Line />
      <Content>{Percentage}</Content>
      {!isPayment && <Line />}
    </Div>
  );
};

FineInterestDiscount.propTypes = {
  values: PropTypes.instanceOf(Object),
  onChangeValues: PropTypes.func,
  idButton: PropTypes.string,
  total: PropTypes.number,
  isPayment: PropTypes.bool,
  isVobiPayConfiguration: PropTypes.bool
};

export default FineInterestDiscount;
