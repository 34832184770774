import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../Input/Input';

import { Div, spaces } from '../../styles/style';
import Button from '../Button/Button';
import { Paragraph } from '../Text/Text';
import useViewport from '../../_Hooks/useViewport';

const FilterRange = ({ data, onClick, closeModal }) => {
  const [amounts, setAmounts] = useState(data || { min: null, max: null });
  const { min, max } = amounts;
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  const handleChange = name => e => {
    const { value } = e.target;

    setAmounts(prevState => {
      return { ...prevState, [name]: Number(value) };
    });
  };

  const handleClick = () => {
    closeModal && closeModal();
    onClick(amounts);
  };

  return (
    <Div align="start" direction="column" gap={spaces.space1} width={isMobile ? '100%' : '220px'}>
      <Paragraph>Insira o valor</Paragraph>
      <Div gap={spaces.space1}>
        <Input value={min} name="min" onChange={handleChange('min')} prefix={<FontAwesomeIcon icon={faDollarSign} />} />
        <Paragraph type="small">até</Paragraph>
        <Input value={max} name="max" onChange={handleChange('max')} prefix={<FontAwesomeIcon icon={faDollarSign} />} />
      </Div>

      <Button $fullWidth id="apply-filter-button" noShadow onClick={handleClick}>
        Aplicar filtro
      </Button>
    </Div>
  );
};

FilterRange.propTypes = {
  data: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  closeModal: PropTypes.func
};

export default FilterRange;
