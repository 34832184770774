import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts, Div, spaces } from '../../styles/style';

const StyledCompanyName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: ${fonts.sizeMd};
  word-break: break-word;
`;
const StyledGuideButton = styled.div`
  display: flex;
  border: 1px solid ${colors.primary100};
  border-radius: ${spaces.space0};
  align-items: center;
  justify-content: center;
  height: ${spaces.space4};
  width: ${spaces.space4};
  :hover {
    background-color: ${colors.primary100};
  }
  svg {
    width: ${spaces.space2};
    height: ${spaces.space2};
  }
`;

const CompanyProgressDropDown = () => {
  const history = useHistory();
  const { user: { company } = {} } = useSelector(state => state.authReducer);

  return (
    <div
      aria-hidden="true"
      id="progressDropDown"
      style={{
        height: '100%',
        width: '100%',
        lineHeight: 'normal'
      }}
      onClick={() => {
        history.push(`/profissional/guia-implantacao`);
      }}
    >
      <Div style={{ cursor: 'pointer', paddingLeft: spaces.space1, justifyContent: 'space-between' }}>
        <Tooltip title={company?.name || company?.legalName}>
          <StyledCompanyName>{company?.name || company?.legalName}</StyledCompanyName>
        </Tooltip>
        <StyledGuideButton id="open-guide">
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </StyledGuideButton>
      </Div>
    </div>
  );
};

export default CompanyProgressDropDown;
