import * as dayjs from 'dayjs';
import formatCurrency from '../../helpers/formatCurrency';

export const instalment = {
  price: {
    label: 'Preço',
    format: value => formatCurrency(value || 0, { currencySymbol: 'R$' })
  },
  dueDate: { label: 'Vencimento', format: value => (value ? dayjs(value).format('DD/MM/YYYY') : '-') },
  refurbishItem: { label: 'Fornecedor', nestedKey: 'supplier.name' },
  description: { label: 'Descrição' }
};
