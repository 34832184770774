import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faArrowLeft, faFilter, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faFilter as faFilterHover } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import FilterDefault from './FilterDefault';
import { Paragraph } from '../Text/Text';
import { Div } from '../../styles/style';

const FilterMobile = ({
  handleDefaultFilter,
  currentFilters,
  hasFilter,
  clearFilter,
  onShowFilter = f => f,
  appliedFilters
}) => {
  const systemData = useSelector(state => state.setup.systemData);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  return (
    <>
      <Button
        text
        onClick={() => {
          setShowFilter(!showFilter);
          onShowFilter(!showFilter);
        }}
        noPadding
      >
        <FontAwesomeIcon size="lg" icon={hasFilter ? faFilterHover : faFilter} />
      </Button>
      {hasFilter ? (
        <Button text onClick={clearFilter}>
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </Button>
      ) : null}
      {showFilter && (
        <>
          {selectedFilter ? (
            <Modal
              centered
              title={
                <Div>
                  <Button text onClick={() => setSelectedFilter(null)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                  <Paragraph>{selectedFilter.filterName}</Paragraph>
                </Div>
              }
              open
              hideFooter
              onClose={() => {
                setShowFilter(false);
                setSelectedFilter(null);
              }}
            >
              <FilterDefault
                options={selectedFilter.dataType ? systemData[selectedFilter.dataType] : selectedFilter.options}
                name={selectedFilter.name}
                onClick={(...data) => {
                  handleDefaultFilter(selectedFilter.filterName)(...data);
                  setSelectedFilter(null);
                  setShowFilter(false);
                }}
                appliedFilters={appliedFilters}
              />
            </Modal>
          ) : (
            <Modal centered title="Filtros" open hideFooter onClose={() => setShowFilter(false)}>
              {currentFilters?.map?.(filter =>
                filter?.options || filter?.dataType ? (
                  <Button text key={filter?.filterName} onClick={() => setSelectedFilter(filter)}>
                    {filter?.filterName}
                  </Button>
                ) : null
              )}
            </Modal>
          )}
        </>
      )}
    </>
  );
};

FilterMobile.propTypes = {
  handleDefaultFilter: PropTypes.func,
  currentFilters: PropTypes.instanceOf(Object),
  hasFilter: PropTypes.bool,
  clearFilter: PropTypes.func,
  onShowFilter: PropTypes.func,
  appliedFilters: PropTypes.instanceOf(Array)
};

export default FilterMobile;
