import { useEffect } from 'react';

const getClasses = (element, level, depth) => {
  const classList = element?.classList ? [...element?.classList] : [];
  if (level === depth) {
    return classList;
  }
  return classList.concat(getClasses(element?.parentNode, level + 1, depth));
};

const UseOutsideClick = (ref, func, refPrevent, classPrevent = {}) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (refPrevent?.current && refPrevent?.current?.contains(event.target)) {
        return;
      }
      const { classes, depth = 1 } = classPrevent;

      if (Array.isArray(classes)) {
        const compareClasses = new Set(getClasses(event?.target, 1, depth));
        if (classes?.some(term => compareClasses?.has(term))) return;
      }

      if (ref?.current && (!ref?.current?.contains || !ref?.current?.contains(event.target)) && func) {
        func();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, func]);
};

export default UseOutsideClick;
