import { zipcode, street, addressNumber, complement, city, state } from '../mapping';

export const address = {
  zipcode,
  street,
  number: addressNumber,
  complement,
  state,
  city
};
