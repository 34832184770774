import styled, { css } from 'styled-components';
import { spaces } from '../../styles/style';

const InlineDivider = styled.div`
  flex: 1;
  width: ${props => props.$mobileDivWidth && props.$mobileDivWidth};
  ${props =>
    props.inline &&
    css`
      display: flex;
      justify-content: ${props.justify || 'space-between'};
      align-items: center;
      padding-top: ${props.$readOnly ? props.$inlinePadding || spaces.space1 : 0};
    `};
`;

const DynamicColumn = styled.div`
  flex: ${props => (props.$mobileWidth ? props.$mobileWidth : `calc(50% - ${spaces.space0})`)};
  min-height: ${props => !props.$minHeight && spaces.space7};
  margin: ${props => (props.$marginMobile ? props.$marginMobile : null)};
`;

export { InlineDivider, DynamicColumn };
