import styled from 'styled-components';

import { fonts, spaces, colors, breakpoints } from '../../styles/style';

export const CreatePixContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${spaces.space2};
`;

export const Img = styled.img`
  margin-bottom: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    width: ${spaces.space5};
    height: ${spaces.space5};
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    min-height: ${spaces.space2};
  }
`;

export const Number = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary500};
  color: #ffff;
  font-size: ${fonts.sizeSm};
  border-radius: 50%;
  min-width: ${spaces.space2};
  max-height: ${spaces.space2};
  margin-right: ${spaces.space2};
  padding-top: 1px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

export const PixTermContainer = styled.div`
  margin: ${spaces.space2} auto;
  width: 83%;
  span {
    font-size: ${fonts.sizeSm};
  }
`;
