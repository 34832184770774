import styled from 'styled-components';
import { Table } from 'antd';
import { colors, fonts, radius, spaces } from '../../styles/style';

export const MobileCardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const KeyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fonts.sizeSm};
  margin-bottom: ${spaces.space2};
  gap: ${spaces.space1};
  span {
    color: ${colors.neutral600};
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;
  border-radius: ${radius.radius1};
  padding-bottom: ${spaces.space1};
  border: 1px solid ${colors.neutral100};

  .ant-table-body {
    margin: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: ${fonts.sizeSm};
      font-weight: ${fonts.weight1};
    }
  }
`;

export const OtherDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${fonts.sizeSm};
  span {
    color: ${colors.neutral600};
  }
`;
