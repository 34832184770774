import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import NoAccess from '../../_Pages/NoAccess';

const hasPermission = (user, roles, plans = [], permissions = []) => {
  if (!user) return false;
  if (!roles?.length || user.userType !== 'provider') return true;
  if (!user?.permissions?.length) return false;

  const currentPlan = plans.find(p => p.id === user?.company?.idPlan);
  const currentPermission = permissions?.find(permission => roles.includes(permission.id));
  const hasPlanPermission = currentPlan?.permissions?.find(permission => roles.includes(permission.id));
  const hasUserPermission = user?.permissions?.find(permission => roles.includes(permission.id));
  const hasBetaPermission = user?.company?.permissions?.find(permission => roles.includes(permission.id));

  if (hasUserPermission || ((hasPlanPermission || hasBetaPermission) && user?.isOwner)) return true;

  return !hasPlanPermission && currentPermission && !currentPermission?.isBeta && user?.isOwner ? null : false;
};

const PrivateRoute = ({ component: Component, roles, children, noAccessOptions = [], ...rest }) => {
  const { user = null } = useSelector(state => state.authReducer) || {};
  const { plans, permissions } = useSelector(state => state.setup) || {};

  const hasAccess = hasPermission(user, roles, plans, permissions);

  if (!user || !hasAccess) {
    const validOption = noAccessOptions.find(({ optionRoles }) =>
      optionRoles?.length ? hasPermission(user, optionRoles, plans, permissions) : false
    );
    return validOption ? (
      <Redirect to={`${validOption.redirect}`} />
    ) : (
      <Route {...rest} component={() => <NoAccess hasAccess={hasAccess} />} />
    );
  }

  if (children) return <Route {...rest}>{children}</Route>;

  return <Route {...rest} render={props => <Component {...props} />} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  roles: PropTypes.instanceOf(Array),
  noAccessOptions: PropTypes.instanceOf(Array),
  children: PropTypes.instanceOf(Object)
};

export { hasPermission };

export default PrivateRoute;
