import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Paragraph } from '../Text/Text';
import { Line } from '../Line/Line';
import { Div, spaces } from '../../styles/style';

const CreatedByContent = ({ data = {}, showLine, noPadding }) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const { createdAt, createdBy } = data || {};
  const isProvider = user?.userType === userType.provider.value;

  return isProvider && createdAt ? (
    <>
      {showLine && <Line />}
      <Div padding={noPadding ? 0 : spaces.space2}>
        <Paragraph type="small">
          Criado em: {`${dayjs(createdAt).format('DD/MM/YYYY [às] HH:mm')}`}
          {createdBy && ` por ${createdBy?.name || createdBy}`}
        </Paragraph>
      </Div>
    </>
  ) : null;
};

CreatedByContent.propTypes = {
  data: PropTypes.instanceOf(Object),
  showLine: PropTypes.bool,
  noPadding: PropTypes.bool
};

export default CreatedByContent;
