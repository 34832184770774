import * as React from 'react';

function NoDrawerProduct() {
  return (
    <svg width="145" height="117" viewBox="0 0 145 117" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.808105 111.241H57.1855V111.92H0.808105V111.241ZM134.322 59.0944L134.124 59.0224C134.081 59.0068 129.764 57.4031 127.741 53.7671C125.719 50.1304 126.709 45.752 126.72 45.7092L126.767 45.5095L126.964 45.5815C127.007 45.5971 131.324 47.2008 133.347 50.8368C135.369 54.4735 134.379 58.8519 134.368 58.8947L134.322 59.0944ZM128.066 53.598C129.775 56.6722 133.187 58.2481 134.051 58.6081C134.215 57.7094 134.73 54.0775 133.023 51.006C131.314 47.9351 127.902 46.3565 127.037 45.9959C126.873 46.8945 126.358 50.5264 128.065 53.598H128.066Z" fill="#3F3D56"/>
<path d="M129.004 53.1441C132.567 55.3163 133.94 59.6322 133.94 59.6322C133.94 59.6322 129.535 60.4256 125.971 58.2527C122.407 56.0791 121.035 51.7639 121.035 51.7639C121.035 51.7639 125.44 50.9705 129.004 53.1434V53.1441Z" fill="#D0CDE1"/>
<path d="M133.601 72.0001C127.414 71.9933 122.4 66.8989 122.393 60.6132V60.4529H144.808V60.6132C144.801 66.8989 139.787 71.9933 133.601 72.0001ZM122.71 60.7735C122.796 66.8222 127.647 71.6794 133.601 71.6794C139.554 71.6794 144.405 66.8222 144.492 60.7735H122.71Z" fill="#3F3D56"/>
<path d="M133.601 69.9622C137.54 69.9622 140.733 66.6169 140.733 62.4905H126.469C126.469 66.6169 129.662 69.9622 133.601 69.9622Z" fill="#005AC3"/>
<path d="M36.129 16.981C40.6306 16.981 44.2799 13.4838 44.2799 9.16972C44.2799 4.85565 40.6306 1.3584 36.129 1.3584C31.6273 1.3584 27.978 4.85565 27.978 9.16972C27.978 13.4838 31.6273 16.981 36.129 16.981Z" fill="#2F2E41"/>
<path d="M33.4459 14.9434C33.4459 14.9434 34.5327 22.4388 32.5133 24.1926C30.4939 25.9471 35.7758 33.283 35.7758 33.283L42.9214 21.4818C42.9214 21.4818 37.4847 20.0465 40.2805 15.7408L33.4459 14.9434Z" fill="#FFDFE3"/>
<path d="M30.695 36.0796L29.5702 40.4458L28.4426 44.8303L24.5818 44.5124L26.3512 37.1935C26.3512 37.1935 26.0299 30.3522 25.869 28.7607C25.8305 28.4688 25.8529 28.1721 25.9348 27.8892C25.991 27.6906 26.108 27.5144 26.2692 27.3854C26.4305 27.2565 26.628 27.1811 26.8342 27.1699L30.695 36.0796Z" fill="#575A89"/>
<path d="M34.7705 96.181L30.3404 97.132L29.9091 93.0891L28.3577 78.5057L27.978 74.9431L33.1464 73.3584L33.1124 74.6265L32.9983 78.9058C33.2216 79.7429 33.3871 80.5945 33.4935 81.4543C33.7109 83.0764 33.7924 84.7167 33.7367 86.3544C33.5893 90.7926 34.7705 96.181 34.7705 96.181Z" fill="#FFDFE3"/>
<path d="M30.0156 96.5044L34.7112 95.0942L37.2856 101.673L40.9216 110.445C40.9216 110.445 45.6165 116.868 40.6186 116.085C35.6194 115.301 34.8627 114.048 34.8627 113.422V112.325L34.2568 112.482L33.8017 112.012L33.1965 107.313C33.1965 107.313 31.8333 106.059 32.4392 104.336L30.0156 96.5044Z" fill="#2F2E41"/>
<path opacity="0.2" d="M33.412 82.1785L33.3576 82.3232C33.3576 82.3232 32.5432 89.9749 29.903 93.2339L29.6198 93.7359L27.978 79.2503L29.0091 75.3962H33.0092L32.8883 79.647C33.124 80.477 33.2992 81.3227 33.412 82.1785Z" fill="black"/>
<path d="M36.1289 74.717L33.5511 81.5992C33.5511 81.5992 32.7612 89.2006 30.1991 92.4379L26.8789 98.4906C26.8789 98.4906 22.9366 97.0832 23.2395 97.0832C23.5431 97.0832 25.8179 89.1062 25.8179 89.1062L27.9406 80.0349L29.331 74.717H36.1289Z" fill="#FFDFE3"/>
<path d="M27.2987 96.5044L22.6031 95.0942L20.0288 101.673L16.3928 110.445C16.3928 110.445 11.6978 116.868 16.6957 116.085C21.695 115.301 22.4516 114.048 22.4516 113.422V112.325L23.0575 112.482L23.5126 112.012L24.1178 107.313C24.1178 107.313 25.4811 106.059 24.8752 104.336L27.2987 96.5044Z" fill="#2F2E41"/>
<path d="M37.4874 16.9812C40.4885 16.9812 42.9214 14.5483 42.9214 11.5472C42.9214 8.54615 40.4885 6.11328 37.4874 6.11328C34.4863 6.11328 32.0535 8.54615 32.0535 11.5472C32.0535 14.5483 34.4863 16.9812 37.4874 16.9812Z" fill="#FFDFE3"/>
<path opacity="0.2" d="M30.6949 36.5109L29.5075 40.7548L28.4873 40.6869C28.4588 40.3047 28.3426 39.9342 28.1477 39.6041C27.8081 38.9853 28.1477 39.4493 28.8269 38.8305C29.5062 38.2124 28.9967 38.5214 28.4873 37.7478C27.9779 36.9748 28.8269 35.2733 28.8269 35.2733C27.876 34.6552 26.6194 27.8491 26.6194 27.8491L30.6949 36.5109Z" fill="black"/>
<path d="M36.2484 30.4846L32.9337 23.4897L26.6194 27.7479C26.6194 27.7479 28.6714 34.287 29.7765 35.0471C29.7765 35.0471 28.9872 36.72 29.4607 37.4801C29.9341 38.2409 30.4075 37.9366 29.7765 38.5445C29.1448 39.1531 28.8296 38.6966 29.1448 39.3052C29.3275 39.634 29.4349 39.9974 29.4607 40.3696L44.457 41.434L45.4039 38.8488C45.4039 38.8488 47.2983 35.3507 46.6673 30.4852L49.0345 22.121L42.405 21.0566L36.2484 30.4846Z" fill="#575A89"/>
<path d="M44.4625 40.7846C44.4625 40.7846 47.1285 46.569 44.4319 56.0017C41.7353 65.4351 39.3566 75.3398 39.3566 75.3398L27.1438 76.7547L26.8266 71.2521L22.5439 70.6231C22.5439 70.6231 23.1784 52.8575 25.0816 48.4553C26.9849 44.0538 28.7998 40.0754 28.7998 40.0754L44.4632 40.7846H44.4625Z" fill="#2F2E41"/>
<path opacity="0.2" d="M27.0725 70.8798L30.0272 51.7537L30.3356 51.8012L27.3809 70.9274L27.0725 70.8798Z" fill="black"/>
<path d="M41.5397 7.37924C41.2061 6.42151 40.5296 5.63766 39.6541 5.19479C38.5707 4.65275 37.3073 4.69623 36.12 4.8769C34.7492 5.08475 33.3092 5.52015 32.4249 6.63819C31.857 7.35547 31.584 8.27245 31.3245 9.1643C31.0243 10.1947 30.7213 11.2414 30.6948 12.3187C30.6989 13.0652 30.7695 13.809 30.9054 14.5419C30.9441 14.944 31.0535 15.3359 31.2267 15.6966C31.4209 16.0457 31.7762 16.3256 32.16 16.3005C31.387 14.8435 31.4875 13.0482 32.4187 11.6972C32.6422 11.3983 32.884 11.1151 33.1442 10.8502L35.1187 8.74521C35.7246 8.09992 36.357 7.43494 37.166 7.11841C38.1101 6.75841 39.163 6.91464 39.9754 7.53275C40.3095 7.79562 40.5867 8.12913 40.7904 8.51222C40.9344 8.78256 41.2707 9.65404 41.2707 9.65404C41.2707 9.65404 41.6592 7.72702 41.5397 7.37857V7.37924Z" fill="#2F2E41"/>
<path d="M31.7137 12.9057C32.0888 12.9057 32.3929 12.4495 32.3929 11.8868C32.3929 11.3241 32.0888 10.8679 31.7137 10.8679C31.3385 10.8679 31.0344 11.3241 31.0344 11.8868C31.0344 12.4495 31.3385 12.9057 31.7137 12.9057Z" fill="#A0616A"/>
<path d="M32.7326 5.43396C34.2332 5.43396 35.4496 4.21753 35.4496 2.71698C35.4496 1.21643 34.2332 0 32.7326 0C31.2321 0 30.0156 1.21643 30.0156 2.71698C30.0156 4.21753 31.2321 5.43396 32.7326 5.43396Z" fill="#2F2E41"/>
<path d="M38.1957 50.9433H28.6279C28.1266 50.9433 27.7204 50.5582 27.7204 50.0827L27.2986 38.219C27.2986 37.7435 27.7048 37.3584 28.2067 37.3584H38.6168C39.1188 37.3584 39.525 37.7435 39.525 38.219L39.1039 50.0827C39.1039 50.5582 38.6963 50.9433 38.1957 50.9433Z" fill="#3F3D56"/>
<path d="M33.4119 40.7547C33.787 40.7547 34.0912 40.4506 34.0912 40.0755C34.0912 39.7003 33.787 39.3962 33.4119 39.3962C33.0368 39.3962 32.7327 39.7003 32.7327 40.0755C32.7327 40.4506 33.0368 40.7547 33.4119 40.7547Z" fill="#D0CDE1"/>
<path d="M44.6608 40.7546L39.5895 41.3544C39.5895 41.3544 30.9563 40.9088 33.064 43.5314C35.1724 46.1533 39.9923 43.994 39.9923 43.994L46.3175 43.6856L44.6608 40.7546ZM25.6521 42.1213C25.6521 42.1213 23.9934 42.9072 24.8058 44.3492C25.6175 45.7906 29.4586 47.2489 29.9578 45.1045C30.4571 42.9601 27.5812 42.2836 26.8673 42.1817C26.4651 42.1202 26.0577 42.1 25.6514 42.1213H25.6521Z" fill="#FFDFE3"/>
<path d="M46.7502 22.3709L48.7391 21.7358C49.4007 22.3218 49.8641 23.0985 50.0656 23.959C50.3971 25.3881 55.7013 41.5841 53.0496 42.8543C50.3971 44.1238 45.7558 46.1887 45.7558 46.1887L43.6006 40.79L47.0817 39.0437L45.7558 32.057C45.7558 32.057 44.4293 22.3709 46.7502 22.3709Z" fill="#575A89"/>
<path d="M110.166 71.3208H70.7703V31.9246H110.166V71.3208ZM71.078 71.0131H109.859V32.2323H71.078V71.0131Z" fill="#3F3D56"/>
<path d="M75.5251 36H106.091V66.566H75.5251V36Z" fill="#005AC3"/>
<path d="M86.6566 57.7359C85.7593 54.8878 84.627 52.8705 83.0946 51.3856L82.9968 51.2906L83.0756 51.1792C86.0079 46.9923 88.4281 42.8964 90.2681 39.005L90.404 38.717L90.5453 39.0023C92.8955 43.7591 95.2878 47.7401 97.8607 51.1744L97.9402 51.2817L97.8499 51.3795C95.9874 53.4037 94.7797 55.4808 94.1589 57.7305L93.8614 57.6442C94.4863 55.381 95.6885 53.2916 97.5354 51.2607C95.0324 47.9038 92.6992 44.0294 90.4101 39.4296C88.5965 43.2164 86.2409 47.1913 83.4051 51.2518C84.9266 52.7645 86.0561 54.7989 86.9507 57.6388L86.6566 57.7359Z" fill="#3F3D56"/>
<path d="M90.313 39.3962H90.9922V51.6227H90.313V39.3962Z" fill="#3F3D56"/>
<path d="M90.4686 52.3018C90.8437 52.3018 91.1478 51.9977 91.1478 51.6226C91.1478 51.2475 90.8437 50.9434 90.4686 50.9434C90.0934 50.9434 89.7893 51.2475 89.7893 51.6226C89.7893 51.9977 90.0934 52.3018 90.4686 52.3018Z" fill="#3F3D56"/>
<path d="M84.8572 65.211L96.9783 57.1687L97.1508 57.4268L85.0297 65.4704L84.8572 65.211Z" fill="#CFCCE0"/>
<path d="M83.6951 60.7097C83.1619 60.3191 83.0118 59.9367 82.9968 59.8959L83.2848 59.775L83.1408 59.8355L83.2841 59.7737C83.293 59.7975 84.2697 62.1415 90.5582 60.8618C97.1435 59.5217 97.911 61.611 97.9402 61.7007L97.6482 61.8114C97.6427 61.7944 96.8752 59.9143 90.6152 61.1885C86.5112 62.024 84.5781 61.3563 83.6951 60.7097Z" fill="#3F3D56"/>
<path d="M82.8269 59.7879L84.3579 59.4993L84.648 61.0269L83.1163 61.3163L82.8269 59.7879ZM97.3288 61.4005L98.8598 61.1111L99.1499 62.6387L97.6181 62.9281L97.3288 61.4005Z" fill="#3F3D56"/>
<path d="M90.4686 62.4906C90.8437 62.4906 91.1478 62.1865 91.1478 61.8113C91.1478 61.4362 90.8437 61.1321 90.4686 61.1321C90.0934 61.1321 89.7893 61.4362 89.7893 61.8113C89.7893 62.1865 90.0934 62.4906 90.4686 62.4906Z" fill="#CFCCE0"/>
<path d="M84.3553 65.8868C84.7304 65.8868 85.0345 65.5827 85.0345 65.2076C85.0345 64.8324 84.7304 64.5283 84.3553 64.5283C83.9801 64.5283 83.676 64.8324 83.676 65.2076C83.676 65.5827 83.9801 65.8868 84.3553 65.8868Z" fill="#CFCCE0"/>
<path d="M96.5816 57.7359C96.9567 57.7359 97.2608 57.4318 97.2608 57.0567C97.2608 56.6815 96.9567 56.3774 96.5816 56.3774C96.2065 56.3774 95.9023 56.6815 95.9023 57.0567C95.9023 57.4318 96.2065 57.7359 96.5816 57.7359Z" fill="#CFCCE0"/>
<path d="M90.313 51.6226H90.9923V54.3395H90.313V51.6226ZM68.0535 71.1651H143.45V71.8444H68.0535V71.1651Z" fill="#3F3D56"/>
</svg>

  );
}

function NoDrawerProductKeyword() {
  return (
    <svg width="145" height="120" viewBox="0 0 145 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.58905 114.134C9.54624 117.796 13.7001 119.368 13.7001 119.368C13.7001 119.368 14.6807 115.003 12.7235 111.34C10.7663 107.677 6.61249 106.105 6.61249 106.105C6.61249 106.105 5.63186 110.471 7.58905 114.134Z" fill="#2F2E41"/>
<path d="M8.8727 113.322C12.5352 115.51 13.9458 119.858 13.9458 119.858C13.9458 119.858 9.41821 120.657 5.75569 118.468C2.09317 116.279 0.682617 111.932 0.682617 111.932C0.682617 111.932 5.21018 111.133 8.8727 113.322Z" fill="#00B0FF"/>
<path d="M89.7353 5.68433H66.9984V6.31591H89.7353V5.68433Z" fill="#CFD5E7"/>
<path d="M83.4194 5.68433H82.7878V8.84222H83.4194V5.68433Z" fill="#CFD5E7"/>
<path d="M73.9458 5.68433H73.3142V8.84222H73.9458V5.68433Z" fill="#CFD5E7"/>
<path d="M101.104 20.842H78.3668V21.4736H101.104V20.842Z" fill="#CFD5E7"/>
<path d="M85.3142 18.3157H84.6826V21.4736H85.3142V18.3157Z" fill="#CFD5E7"/>
<path d="M94.7878 18.3157H94.1562V21.4736H94.7878V18.3157Z" fill="#CFD5E7"/>
<path d="M130.788 28.4211H108.051V29.0527H130.788V28.4211Z" fill="#CFD5E7"/>
<path d="M114.998 25.2632H114.367V28.4211H114.998V25.2632Z" fill="#CFD5E7"/>
<path d="M125.104 25.2632H124.472V28.4211H125.104V25.2632Z" fill="#CFD5E7"/>
<path d="M128.893 102.947H106.156V103.579H128.893V102.947Z" fill="#CFD5E7"/>
<path d="M112.472 99.7896H111.84V102.947H112.472V99.7896Z" fill="#CFD5E7"/>
<path d="M122.577 99.7896H121.946V102.947H122.577V99.7896Z" fill="#CFD5E7"/>
<path d="M90.3668 51.158H67.63V51.7895H90.3668V51.158Z" fill="#CFD5E7"/>
<path d="M74.5774 48.6316H73.9458V51.7895H74.5774V48.6316Z" fill="#CFD5E7"/>
<path d="M84.051 48.6316H83.4194V51.7895H84.051V48.6316Z" fill="#CFD5E7"/>
<path d="M97.3142 89.6843H74.5774V90.3159H97.3142V89.6843Z" fill="#CFD5E7"/>
<path d="M81.5248 87.158H80.8932V90.3159H81.5248V87.158Z" fill="#CFD5E7"/>
<path d="M90.9984 87.158H90.3668V90.3159H90.9984V87.158Z" fill="#CFD5E7"/>
<path d="M133.314 78.3159H110.577V78.9475H133.314V78.3159Z" fill="#CFD5E7"/>
<path d="M117.525 75.158H116.893V78.3159H117.525V75.158Z" fill="#CFD5E7"/>
<path d="M126.998 75.158H126.367V78.3159H126.998V75.158Z" fill="#CFD5E7"/>
<path d="M58.7879 112.421H20.8932V112.149H58.5152V98.9619H24.0789L22.406 95.486L22.6518 95.3684L24.2505 98.69H58.7879V112.421Z" fill="#2F2E41"/>
<path d="M25.6299 119.369C27.1996 119.369 28.4721 118.096 28.4721 116.526C28.4721 114.957 27.1996 113.684 25.6299 113.684C24.0603 113.684 22.7878 114.957 22.7878 116.526C22.7878 118.096 24.0603 119.369 25.6299 119.369Z" fill="#3F3D56"/>
<path d="M54.0511 119.369C55.6207 119.369 56.8932 118.096 56.8932 116.526C56.8932 114.957 55.6207 113.684 54.0511 113.684C52.4814 113.684 51.209 114.957 51.209 116.526C51.209 118.096 52.4814 119.369 54.0511 119.369Z" fill="#3F3D56"/>
<path d="M87.8405 57.4738C88.887 57.4738 89.7353 56.6255 89.7353 55.5791C89.7353 54.5326 88.887 53.6843 87.8405 53.6843C86.7941 53.6843 85.9458 54.5326 85.9458 55.5791C85.9458 56.6255 86.7941 57.4738 87.8405 57.4738Z" fill="#3F3D56"/>
<path d="M62.5656 94.7369H19.9984L10.7878 64.4211H72.051L71.9953 64.5995L62.5656 94.7369ZM20.1999 94.4626H62.3657L71.6796 64.6955H11.1561L20.1999 94.4626Z" fill="#2F2E41"/>
<path d="M59.8519 92.8422H22.1119L13.9458 66.3159H68.2616L68.2122 66.4721L59.8519 92.8422Z" fill="#CFD5E7"/>
<path d="M73.5721 61.8946L73.3142 61.8237L75.0745 54.9473H85.3142V55.2238H75.2798L73.5721 61.8946Z" fill="#2F2E41"/>
<path d="M69.5247 73.7581H13.9458V74.3896H69.5247V73.7581Z" fill="#2F2E41"/>
<path d="M66.3668 83.8633H17.1036V84.4949H66.3668V83.8633Z" fill="#2F2E41"/>
<path d="M42.23 64.4211H41.5984V94.1053H42.23V64.4211Z" fill="#2F2E41"/>
<path d="M52.1205 94.4668L54.0858 64.5425L54.359 64.5603L52.3937 94.4847L52.1205 94.4668Z" fill="#2F2E41"/>
<path d="M29.0294 64.5601L29.3027 64.5423L31.2565 94.4674L30.9833 94.4851L29.0294 64.5601Z" fill="#2F2E41"/>
<path d="M144.683 119.368H0.682617V120H144.683V119.368Z" fill="#2F2E41"/>
<path d="M96.6826 9.93297C96.6826 9.93297 99.0126 16.6507 97.5788 17.6842C96.1449 18.7177 102.418 27.1578 102.418 27.1578L109.946 25.0909L105.823 18.0287C105.823 18.0287 105.286 10.9665 105.286 9.93296C105.286 8.89947 96.6826 9.93297 96.6826 9.93297Z" fill="#DDBABF"/>
<path opacity="0.1" d="M96.6826 9.93297C96.6826 9.93297 99.0126 16.6507 97.5788 17.6842C96.1449 18.7177 102.418 27.1578 102.418 27.1578L109.946 25.0909L105.823 18.0287C105.823 18.0287 105.286 10.9665 105.286 9.93296C105.286 8.89947 96.6826 9.93297 96.6826 9.93297Z" fill="black"/>
<path d="M92.568 57.4618C92.568 57.4618 91.8786 66.0061 92.568 70.4491C93.2573 74.8921 94.2913 87.1959 94.2913 87.1959C94.2913 87.1959 94.2913 110.436 98.0827 110.778C101.874 111.12 104.631 111.462 104.976 110.095C105.321 108.727 103.253 108.044 104.287 107.36C105.321 106.677 105.665 104.284 104.287 101.55C102.908 98.8161 104.287 71.1326 104.287 71.1326L110.835 88.563C110.835 88.563 111.525 102.917 112.214 104.968C112.903 107.019 111.525 110.778 113.937 111.12C116.35 111.462 119.107 109.411 120.486 108.727C121.865 108.044 118.418 108.044 119.107 107.702C119.797 107.36 121.865 106.335 121.175 105.993C120.486 105.652 119.797 89.2465 119.797 89.2465C119.797 89.2465 117.901 54.215 115.488 52.8479C113.076 51.4808 111.525 53.8355 111.525 53.8355L92.568 57.4618Z" fill="#2F2E41"/>
<path d="M98.7037 109.263V112.074C98.7037 112.074 95.5774 119.715 98.7037 119.715C101.83 119.715 104.262 120.506 104.262 119.452V109.966L98.7037 109.263Z" fill="#2F2E41"/>
<path d="M118.662 109.263V112.074C118.662 112.074 121.788 119.715 118.662 119.715C115.535 119.715 113.104 120.506 113.104 119.452V109.966L118.662 109.263Z" fill="#2F2E41"/>
<path d="M101.104 15.1579C104.592 15.1579 107.419 12.3303 107.419 8.84216C107.419 5.35404 104.592 2.52637 101.104 2.52637C97.6155 2.52637 94.7878 5.35404 94.7878 8.84216C94.7878 12.3303 97.6155 15.1579 101.104 15.1579Z" fill="#DDBABF"/>
<path d="M99.2602 20.2104C99.2602 20.2104 100.967 25.3498 103.698 24.3219L106.429 23.2941L111.209 56.1861C111.209 56.1861 107.454 61.6681 101.991 58.2418C96.529 54.8156 99.2602 20.2104 99.2602 20.2104Z" fill="#00B0FF"/>
<path d="M104.262 19.058L105.792 15.7896C105.792 15.7896 114.804 20.0902 116.164 21.4664C117.525 22.8426 117.525 24.9069 117.525 24.9069L115.144 33.5082C115.144 33.5082 115.824 52.431 115.824 53.1191C115.824 53.8072 118.205 57.5917 116.505 56.2155C114.804 54.8393 114.464 53.4631 113.104 55.5274C111.743 57.5917 108.683 60.0001 108.683 60.0001L104.262 19.058Z" fill="#3F3D56"/>
<path d="M115.923 43.5789L114.903 53.1411C114.903 53.1411 108.783 58.6052 110.143 58.9467C111.503 59.2882 112.183 57.9222 112.183 57.9222C112.183 57.9222 114.563 60.3127 115.923 58.9467C117.283 57.5807 120.683 44.6034 120.683 44.6034L115.923 43.5789Z" fill="#DDBABF"/>
<path d="M101.456 0.0206241C100.061 -0.0277113 98.5685 -0.0516209 97.3924 0.676998C96.8513 1.04266 96.3767 1.49301 95.988 2.00961C94.8426 3.4132 93.8528 5.17924 94.2783 6.92071L94.7725 6.73389C94.7725 7.19874 94.6659 7.65778 94.4604 8.07779C94.53 7.88165 94.763 8.19872 94.7006 8.39713L94.1562 10.1282C95.0511 9.81022 96.1643 10.4541 96.3005 11.3684C96.3628 9.35776 96.5779 7.05216 98.2606 5.87484C99.1092 5.28112 100.183 5.09987 101.216 4.93892C102.17 4.79044 103.169 4.64885 104.082 4.95303C104.995 5.25722 105.773 6.16233 105.566 7.0764C105.987 6.93583 106.457 7.22022 106.666 7.60182C106.874 7.98341 106.884 8.43356 106.891 8.86512C107.34 9.17254 107.85 8.56209 108.033 8.0598C108.462 6.88078 108.844 5.62574 108.613 4.3957C108.381 3.16566 107.347 1.99017 106.057 1.98599C106.214 1.8193 106.298 1.60011 106.29 1.3748L105.227 1.28772C105.497 1.25068 105.745 1.12383 105.929 0.928897C105.503 1.38418 102.146 0.0445417 101.456 0.0206241Z" fill="#2F2E41"/>
<path d="M101.226 18.3693C101.226 18.3693 98.4447 15.6291 97.4439 15.797C96.4431 15.9648 95.0766 18.3693 95.0766 18.3693C95.0766 18.3693 86.8778 21.1173 87.2194 23.8653C87.561 26.6133 91.3188 40.0098 91.3188 40.0098C91.3188 40.0098 94.3933 56.1542 91.6604 57.8717C88.9275 59.5892 104.642 64.0547 104.983 61.9937C105.325 59.9327 106.008 39.3228 104.983 36.2313C103.959 33.1398 101.226 18.3693 101.226 18.3693Z" fill="#3F3D56"/>
<path d="M113.104 24H117.286C117.286 24 120.207 36.9231 120.545 38.2834C120.884 39.6437 121.56 46.1053 121.222 46.1053C120.884 46.1053 114.118 44.7449 114.118 45.7652L113.104 24Z" fill="#3F3D56"/>
<path d="M113.104 55.5789H96.6727C95.0693 48.3653 94.9354 41.3957 96.6727 34.7368H113.104C110.452 41.2759 110.286 48.1978 113.104 55.5789Z" fill="#CFD5E7"/>
<path d="M90.3668 47.0882L95.1114 49.0332C95.1114 49.0332 104.262 50.3298 104.262 46.7641C104.262 43.1983 95.1114 45.1433 95.1114 45.1433L92.0611 44.2104L90.3668 47.0882Z" fill="#DDBABF"/>
<path d="M90.8552 21.4736L86.7794 22.4921L83.7226 40.4842C83.7226 40.4842 82.7036 45.2368 84.4019 45.5763C86.1001 45.9157 90.8552 48.6315 90.8552 48.6315C90.8552 48.6315 91.5345 43.5394 92.8931 43.5394L89.4966 40.8236L91.5345 28.9421L90.8552 21.4736Z" fill="#3F3D56"/>
<path d="M32.8931 56.2104H10.1562V56.842H32.8931V56.2104Z" fill="#CFD5E7"/>
<path d="M17.1036 53.6843H16.472V56.8422H17.1036V53.6843Z" fill="#CFD5E7"/>
<path d="M26.5774 53.6843H25.9458V56.8422H26.5774V53.6843Z" fill="#CFD5E7"/>
<path d="M41.1037 8.84204H18.3668V9.47362H41.1037V8.84204Z" fill="#CFD5E7"/>
<path d="M34.7878 9.47363H34.1562V12.6315H34.7878V9.47363Z" fill="#CFD5E7"/>
<path d="M24.6826 9.47363H24.051V12.6315H24.6826V9.47363Z" fill="#CFD5E7"/>
<path d="M63.2089 40.4211H40.472V41.0527H63.2089V40.4211Z" fill="#CFD5E7"/>
<path d="M57.5248 41.0527H56.8932V44.2106H57.5248V41.0527Z" fill="#CFD5E7"/>
<path d="M47.4194 41.0527H46.7878V44.2106H47.4194V41.0527Z" fill="#CFD5E7"/>
<path d="M25.3142 40.4211H2.57739V41.0527H25.3142V40.4211Z" fill="#CFD5E7"/>
<path d="M18.9984 40.4211H18.3668V43.579H18.9984V40.4211Z" fill="#CFD5E7"/>
<path d="M9.52477 40.4211H8.89319V43.579H9.52477V40.4211Z" fill="#CFD5E7"/>
<path d="M52.4721 24.6316H29.7352V25.2632H52.4721V24.6316Z" fill="#CFD5E7"/>
<path d="M36.051 21.4736H35.4194V24.6315H36.051V21.4736Z" fill="#CFD5E7"/>
<path d="M46.1564 21.4736H45.5248V24.6315H46.1564V21.4736Z" fill="#CFD5E7"/>
</svg>


  );
}

export  {NoDrawerProduct, NoDrawerProductKeyword};
