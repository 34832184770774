import React, { useState, useEffect } from 'react';
import useViewport from './useViewport';
import Pagination from '../components/Pagination/Pagination';
import Button from '../components/Button/Button';
import { Div } from '../styles/style';

const ITEMS_PAGE = 30;

const useButtonPagination = ({ totalItems, handleLoad, listLength, filter, order, offset, setOffset = f => f }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [_totalItems, setTotalItems] = useState(totalItems);

  const handlePagination = page => {
    if (isMobile()) {
      setOffset(prev => prev + 1);
    } else setOffset(page);
  };

  useEffect(() => {
    if (!_totalItems || _totalItems > listLength) handleLoad({ delay: false, offset });
  }, [offset]);

  useEffect(() => {
    if (offset === 1) handleLoad({ delay: true, offset });
    else setOffset(1);
  }, [filter, order]);

  const deskTopPaginationComponent = totalItemsCount => (
    <Pagination
      activePage={Number(offset || 1)}
      itemsCountPerPage={ITEMS_PAGE}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={5}
      onChange={handlePagination}
    />
  );

  const mobilePaginationComponent = () => (
    <Div $fullWidth>
      <Button onClick={() => setOffset(prev => prev + 1)} type="primary" text>
        Carregar mais...
      </Button>
    </Div>
  );

  return {
    setTotalItems,
    deskTopPaginationComponent,
    mobilePaginationComponent
  };
};

const useScrollPagination = ({ totalItems, handleLoad, listLength, filter, order, initialOffset }) => {
  const [offset, setOffset] = useState(initialOffset);
  const [_totalItems, setTotalItems] = useState(totalItems);

  const handleScroll = () => {
    setOffset(prev => prev + 1);
  };

  useEffect(() => {
    if (offset > 1 && (!_totalItems || _totalItems > listLength)) handleLoad({ delay: false, offset });
  }, [offset]);

  useEffect(() => {
    if (offset === 1) handleLoad({ delay: true, offset });
    else setOffset(1);
  }, [filter, order]);

  return {
    offset,
    setOffset,
    setTotalItems,
    handleScroll
  };
};

export default { useScrollPagination, useButtonPagination };
