import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import { ContentMethod, Img, ContentDiv, BankSlipContent, ResponsibleDescription } from './VobiPayPayment.styles';
import ticketDefault from '../Images/ticketDefault.svg';
import ticketMobileDefault from '../Images/ticketMobileDefault.svg';
import vobiPaySecurity from '../Images/vobiPaySecurity.svg';
import Button from '../Button/Button';
import { formatDocument } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import { Div } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import { Subtitle } from '../Text/Text';
import CopyLinkButton from '../Button/CopyLinkButton';

const MethodTicket = ({ installmentToPay, company }) => {
  const { bankSlipUrl, idPaymentAsaas } = installmentToPay;
  const { isMobile } = useViewport(window.innerWidth);

  const { handleGet } = useCRUD({
    model: 'pay',
    pathOptions: `/identificationField/${idPaymentAsaas}/${company?.id}`,
    immediatelyLoadData: false
  });

  const redirect = () => {
    window.open(bankSlipUrl, '_blank');
  };

  const handleCopyCode = () => {
    return handleGet().then(data => {
      if (!data?.identificationField) {
        return toast.error('Código inválido.');
      }

      const urlToCopy = data?.identificationField;
      toast.success('Código copiado.');
      setTimeout(() => navigator.clipboard.writeText(urlToCopy), 0);
      return null;
    });
  };

  const TextDescription = (
    <div style={{ maxWidth: 736 }}>
      <ResponsibleDescription>
        Esta cobrança é de responsabilidade única e exclusiva de {company?.legalName} com{' '}
        <strong>CNPJ: {formatDocument(company?.cnpj)}</strong> e a Vobi não se responsabiliza pelo produto ou serviço
        prestado, em caso de dúvida entre em contato com seu fornecedor.
      </ResponsibleDescription>
    </div>
  );

  return (
    <ContentMethod>
      <Subtitle type="secondary">
        <strong>Boleto bancário para pagamento:</strong>
      </Subtitle>
      <BankSlipContent>
        <a href={bankSlipUrl} target="_blank">
          <Img src={isMobile() ? ticketMobileDefault : ticketDefault} alt="ticketDefault" />
        </a>
        <ContentDiv>
          <Img src={vobiPaySecurity} alt="vobiPaySecurity" />
          <Div>
            <div>
              <Button onClick={() => redirect()} type="primary">
                Ver boleto
              </Button>
            </div>
            <div>
              <CopyLinkButton type="primary" id="copy-code-button" text customGetLink={handleCopyCode}>
                <FontAwesomeIcon icon={faCopy} /> {`Copiar código ${isMobile() ? '' : 'de barras'}`}
              </CopyLinkButton>
            </div>
          </Div>
          {!isMobile() && TextDescription}
        </ContentDiv>
      </BankSlipContent>
      {isMobile() && TextDescription}
    </ContentMethod>
  );
};

MethodTicket.propTypes = {
  installmentToPay: PropTypes.instanceOf(Object),
  company: PropTypes.instanceOf(Object)
};

export default MethodTicket;
