import { Calendar as CalendarDHX, i18n } from 'dhx-suite';
import './DHXCalendar.scss';
import 'dhx-suite/codebase/suite.min.css';

const DhxDatepicker = ({ css, value, weekNumbers, timePicker, timeFormat }) => {
  const ptBr = {
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    days: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    today: 'Hoje',
    clear: 'Limpar',
    cancel: 'Cancelar'
  };

  i18n.setLocale('calendar', ptBr);

  return new CalendarDHX(null, {
    css,
    value,
    weekNumbers,
    timePicker,
    timeFormat
  });
};

export default DhxDatepicker;
