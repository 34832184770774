import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { Switch, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { spaces } from '../../styles/style';
import Button from '../Button/Button';
import Form from '../Form/Form';
import BubbleModalButton from '../Button/BubbleModalButton';

import { EnableShareContainer, ShareText, StyledModal } from './ShareModuleModal.style';
import { mappingForm, schema } from '../../lib/mapping/Form/shareModuleSchema';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import mapping from './ShareModuleModalMapping';
import { Subtitle } from '../Text/Text';
import { hasPermission } from '../../routes/Common/PrivateRoute';

const ShareModuleModal = ({ itemId, idRefurbish, onClose, isOpportunity, model, onShare = f => f }) => {
  const { plans, permissions } = useSelector(state => state.setup);
  const { user } = useSelector(state => state.authReducer);
  const [inputKey, setInputKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refurbish, setRefurbish] = useState({});

  const { shareLinkTag, key, shareKey, urlText, shareText, knowMoreId, shareReminderText } = mapping()[model];

  const { handleCreate } = useCRUD({
    model,
    pathOptions: '/send-email-share',
    immediatelyLoadData: false
  });

  const { loading: loadingRefurbish, handleGet, handleUpdate } = useCRUD({
    model: 'refurbish',
    pathOptions: `/${idRefurbish}`,
    immediatelyLoadData: false
  });

  const handleCopyToClipboard = () => {
    const input = document.getElementById('link-input');
    setTimeout(() => navigator.clipboard.writeText(input.value), 0);
    toast.success('Link copiado!');
  };

  useEffect(() => {
    handleGet({ refetchPathOptions: `/${idRefurbish}` }).then(resp => setRefurbish(resp));
  }, []);

  const link = `${window.location.origin}/${shareLinkTag}/${itemId}`;

  const onSubmit = formData => {
    if (formData.email === '') {
      toast.error('É obrigatório inserir um e-mail!');
      return;
    }
    setLoading(true);
    handleCreate({
      values: {
        [key]: itemId,
        ...formData
      },
      refresh: false,
      displayToast: 'Link enviado com sucesso'
    }).then(resp => {
      setLoading(false);
      if (!resp?.error) setInputKey(!inputKey);
      else toast.error('Não foi possível enviar, tente novamente!');
    });
  };

  const handleChange = checked => {
    handleUpdate({
      values: {
        itemsToShare: { ...refurbish.itemsToShare, [shareKey]: checked }
      },
      refresh: false
    }).then(resp => {
      if (checked) {
        onShare(itemId);
      }
      setRefurbish(resp);
    });
  };

  const _hasPermission = roles => {
    return hasPermission(user, roles, plans, permissions);
  };

  return (
    <StyledModal
      sectionStyle={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: spaces.space1,
        paddingRight: spaces.space1,
        marginBottom: `-${spaces.space3}`
      }}
      footer={
        <>
          <span />
          <Button type="text" onClick={() => onClose(false)} id="close-share-modal">
            Fechar
          </Button>
        </>
      }
      title="Compartilhar"
      open
      width="544px"
      onClose={() => onClose(false)}
    >
      <Subtitle>Para o seu cliente ter acesso, basta compartilhar o link abaixo.</Subtitle>
      <ShareText>COMPARTILHAR VIA:</ShareText>
      <Form
        id="emailSharingForm"
        data={{ email: '', link }}
        key={inputKey}
        mapping={mappingForm({ handleCopyToClipboard, link, loading, urlText })}
        schema={schema}
        displayButtons={false}
        onSubmit={onSubmit}
      />
      {loadingRefurbish ? (
        <CenteredLoader />
      ) : (
        <EnableShareContainer>
          {!isOpportunity && refurbish && (
            <>
              <div className="enableShareText">
                {_hasPermission([isOpportunity ? 'opportunityShare' : 'projectShare']) ? (
                  <Switch checked={refurbish?.itemsToShare?.[shareKey]} onChange={handleChange} />
                ) : (
                  <BubbleModalButton>
                    <Tooltip title="Clique e faça upgrade">
                      <FontAwesomeIcon icon={faLock} />
                    </Tooltip>
                  </BubbleModalButton>
                )}

                <span>{shareText}</span>
              </div>
              <p>{shareReminderText}</p>
            </>
          )}
          <div>
            <Button type="primary" id={knowMoreId} text>
              Saiba mais
            </Button>
          </div>
        </EnableShareContainer>
      )}
    </StyledModal>
  );
};

ShareModuleModal.propTypes = {
  model: PropTypes.string.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  isOpportunity: PropTypes.bool,
  onShare: PropTypes.func
};

export default ShareModuleModal;
