import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { indexList } from '../../lib/helpers/helper';
import { columns as scheduleColumns } from '../../lib/mapping/TableOrList/scheduleColumns';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';
import eventBus from '../../lib/helpers/eventBus';
import { handleTaskDateChange } from '../../lib/helpers/ganttHelperFunctions';

const useScheduleData = ({
  referModel = 'refurbish',
  referKey = 'idRefurbish',
  idReference,
  filter,
  generalTask,
  dateRangeDisplay,
  setEditScheduleItemParams,
  defaultOrder = [
    ['order', 'ASC NULLS FIRST'],
    ['tasks', 'order']
  ],
  isApply,
  idCompany,
  workDays,
  dayoffs,
  ganttInstance,
  getFormattedLinks,
  handleChangePredecessor,
  transformData,
  blockLoad,
  preventShowTask
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { taskStatus, taskPriority } = useSelector(state => state.setup.systemData);
  const { taskStatus: taskStatusEnum, refurbishStatus: refurbishStatusEnum, userType: userTypeEnum } = useSelector(
    state => state.setup.enums
  );
  const [list, setList] = useState([]);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const [listOrder, setListOrder] = useState(defaultOrder);
  const { isMobile } = useViewport(window.innerWidth);
  const [expandItem, setExpandedItem] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);
  const [serializedStatusArray, setSerializedStatusArray] = useState({});
  const [serializedPrioritiesArray, setSerializedPrioritiesArray] = useState({});
  const [currentData, setCurrentData] = useState(false);
  const [itemToRefresh, setItemToRefresh] = useState(null);
  const _filter = useRef(filter);
  const dataRangeSelected = useRef(false);
  const lastAdd = useRef(null);
  const _expandedRowKeys = useRef(null);

  const isTemplate = referModel === 'template';

  const { handleCreate: handleCreateTask, handleChunks, progress, handleUpdate: handleUpdateTask } = useCRUD({
    model: 'task',
    immediatelyLoadData: false
  });

  const {
    handleGet: handleGetReferStep,
    handleCreate: handleCreateReferStep,
    handleUpdate: handleUpdateReferStep,
    handleDelete: handleDeleteReferStep,
    loading
  } = useCRUD({
    model: `${referModel}-step`,
    immediatelyLoadData: false
  });

  const loadData = () => {
    if (!idReference || !referKey || blockLoad) return null;
    return handleGetReferStep({
      refetchOptions: {
        where: { or: [{ [referKey]: idReference }, { id: 1 }] },
        ...(!preventShowTask && {
          include: [
            {
              model: 'task',
              include: [
                'user',
                'performedHours',
                {
                  model: 'TaskLink',
                  as: 'targetLinks'
                }
              ],
              required: false,
              where: { [referKey]: idReference, ..._filter.current }
            }
          ]
        }),
        order: listOrder
      },
      generateLoading: false
    }).then(resp => {
      if (!resp?.error) setList(indexList({ currentList: resp, childrenColumn: 'tasks' }));
      setExpandedItem(null);
      setItemToRefresh(null);
    });
  };

  useEffect(() => {
    if (!itemToRefresh) return;
    loadData({});
  }, [itemToRefresh]);

  useEffect(() => {
    if (blockLoad === false) loadData({});
  }, [blockLoad]);

  const handleAdd = (idReferStep, isTask, values) => {
    return isTask || generalTask
      ? handleCreateTask({
          values: {
            ...(dataRangeSelected.current && { startDate: new Date(), endDate: new Date() }),
            name: '',
            [`${referKey}Step`]: idReferStep || null,
            [referKey]: idReference,
            ...values,
            source: generalTask ? 'General' : 'Project'
          },
          noLoading: true,
          refresh: false
        })
          .then(task => {
            if (idReferStep) {
              setExpandedItem(idReferStep);
            }
            const ganttTask = transformData({
              parentId: idReferStep,
              originalType: task?.type,
              // pegar color do parent
              color: undefined,
              type: 'task',
              ...task
            });
            ganttInstance.addTask(ganttTask);
            lastAdd.current = task.id;
            return task;
          })
          .then(resp => {
            if (!resp?.error) {
              eventBus.dispatch('updateGuide');
              loadData({});

              if (isMobile()) {
                setEditScheduleItemParams(prev => ({
                  ...prev,
                  id: resp.id
                }));
              }
            }
          })
      : handleCreateReferStep({
          values: { [referKey]: idReference, ...values },
          noLoading: true,
          refresh: false
        }).then(resp => {
          lastAdd.current = resp.id;
          if (!resp?.error) loadData({});
        });
  };

  const handleChange = ({ id: currentTaskId, ...values }, isTask = true) => {
    if (!isTask) {
      const newValues = Object?.entries(values)?.reduce((acc, curr) => {
        const [key, value] = curr;
        if (key === 'start_date') acc.startDate = value;
        else if (key === 'end_date') acc.endDate = value;
        else acc[key] = value;
        return acc;
      }, {});
      return handleUpdateReferStep({
        updatePathOptions: `/${currentTaskId}`,
        values: { ...newValues },
        refresh: false,
        noLoading: true
      }).then(() => {
        loadData({});
      });
    }
    if (isTemplate) {
      return handleUpdateTask({
        updatePathOptions: `/${currentTaskId}`,
        values: { ...values },
        refresh: false
      }).then(() => {
        loadData({});
      });
    }

    let isDateChange = false;
    const currentTask = ganttInstance.getTask(currentTaskId);

    Object?.entries(values)?.forEach(([key, value]) => {
      if (key === 'duration' || key === 'start_date' || key === 'end_date') isDateChange = true;
      currentTask[key] = value;
    });

    if (isDateChange) {
      handleTaskDateChange(ganttInstance, currentTask, {
        delay: 100,
        refreshTask: true,
        showTask: false,
        skipUpdateLinkLags: false,
        skipCleanUnscheduledFromChildren: false,
        autoSchedule: true,
        preserveUnscheduled: true
      });
    }

    ganttInstance.updateTask(currentTask.id);
    return null;
  };

  const handleSort = ({ key, order }) => {
    const _key = Array.isArray(key) ? key : [key];
    const _order =
      key && _key?.length
        ? [
            ['order', 'asc'],
            ['tasks', ..._key, order]
          ]
        : defaultOrder;

    setIsDefaultOrder(!_key);
    setListOrder(_order);
  };

  const handleBulkChange = (values, selectedRows) => {
    return handleChunks({ values: { ids: selectedRows, ...values, source: generalTask ? 'General' : 'Project' } }).then(
      resp => {
        const { failedCount, successCount } = resp || {};
        if (failedCount)
          toast.error(
            `${failedCount} tarefa${failedCount > 1 ? 's não puderam ser alteradas' : ' não pode ser alterada'}`
          );
        if (successCount) {
          toast.success(
            `${successCount} tarefa${
              successCount > 1 ? 's foram alteradas com sucesso.' : ' foi alterada com sucesso.'
            }`
          );

          loadData({});
        }
      }
    );
  };

  const handleBulkDelete = ids => {
    if (!ids || ids?.length === 0 || !ganttInstance) return;
    ganttInstance?.batchUpdate(() => {
      ids.forEach(taskId => {
        ganttInstance.deleteTask(taskId);
      });
    });
  };

  const handleToggleAllRow = () => {
    _expandedRowKeys.current = _expandedRowKeys?.current?.length ? [] : list?.map(l => l.id);
    setExpandedRowKeys(_expandedRowKeys.current);
  };

  useEffect(() => {
    const isFilter = _filter.current !== filter;
    _filter.current = filter;
    if (!isFilter && listOrder === defaultOrder) return;

    loadData({ generateLoading: isFilter });
  }, [filter, listOrder]);

  useEffect(() => {
    if (dateRangeDisplay?.value && dateRangeDisplay.value !== 'all') {
      dataRangeSelected.current = true;
    }
  }, [dateRangeDisplay]);

  useEffect(() => {
    const object = parseArrayAsObject(taskStatus, true);
    setSerializedStatusArray(object);
  }, [taskStatus]);

  useEffect(() => {
    const object = parseArrayAsObject(taskPriority, true);
    setSerializedPrioritiesArray(object);
  }, [taskPriority]);

  const stepColumns = scheduleColumns({
    isMobile: isMobile(),
    handleChange,
    handleAdd,
    loadData,
    priorityList: taskPriority,
    statusList: taskStatus,
    serializedStatusArray,
    serializedPrioritiesArray,
    taskStatusEnum,
    refurbishStatusEnum,
    userType: user.type,
    idReference,
    referKey,
    isApply,
    idCompany,
    generalTask,
    isDefaultOrder,
    setCurrentData,
    lastAdd,
    handleToggleAllRow,
    workDays,
    dayoffs,
    ganttInstance,
    getFormattedLinks,
    handleChangePredecessor,
    setEditScheduleItemParams,
    userTypeEnum,
    handleDeleteRefurbishStep: handleDeleteReferStep,
    handleDuplicateTask: handleCreateTask
  });

  return {
    list,
    setList,
    loadData,
    handleAdd,
    handleChange,
    handleSort,
    handleBulkChange,
    handleBulkDelete,
    isDefaultOrder,
    listOrder,
    expandItem,
    loading,
    progress,
    expandedRowKeys,
    currentData,
    setCurrentData,
    serializedPrioritiesArray,
    serializedStatusArray,
    stepColumns,
    setItemToRefresh,
    setEditScheduleItemParams
  };
};

export default useScheduleData;
