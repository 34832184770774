import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateUser } from '../../store/auth/actions/action';
import { saveRefurbish } from '../../store/refurbish/actions/action';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import Modal from '../Modal/Modal';
import AlertBar from '../Alert/AlertBar';

const CustomerViewAlert = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refurbish = useSelector(state => state.refurbishReducer);
  const { user } = useSelector(state => state.authReducer);
  const { userType: userTypeEnum } = useSelector(state => state.setup.enums) || {};

  const { handleGet: setIsCustomerView, loading } = useCRUD({
    model: 'auth/customer-view',
    baseUrl: process.env.REACT_APP_AUTH_API_URL || process.env.REACT_APP_API_URL,
    immediatelyLoadData: false
  });

  const handleClick = () => {
    setIsCustomerView({
      refetchOptions: {
        isCustomerView: false
      }
    }).then(response => {
      if (!response?.jwt) return;

      dispatch(saveRefurbish({}));
      dispatch(
        updateUser({
          ...user,
          userType: userTypeEnum.provider.value,
          isCustomerView: false,
          type: 'profissional',
          isOpportunityView: false,
          jwt: response?.jwt
        })
      );
      history.push(
        `/profissional/${user.isOpportunityView ? 'oportunidades' : 'projetos'}/perfil/${refurbish.id}/geral`
      );
    });
  };
  return (
    <>
      <AlertBar
        showAlert
        type="primary"
        message={`Você está visualizando exatamente como seu cliente vê.
          Saia desse modo para poder fazer alterações no projeto`}
        messageMobile="Visão do cliente"
        onExit={handleClick}
      />
      <Modal
        open={loading}
        closable={false}
        hideCloseBtn
        hideFooter
        title="Estamos fechando a visão de cliente"
        width="500px"
        height="240px"
      >
        <CenteredLoader text="Aguarde..." />
      </Modal>
    </>
  );
};

export default CustomerViewAlert;
