import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faList, faShop } from '@fortawesome/pro-solid-svg-icons';
import ConfigModal from './ConfigModal';

const QuotationMapConfigModal = ({ onClose, onSubmit, data, quoteSuppliers }) => {
  const { quotationMapItemColumns } = useSelector(state => state.setup.systemData);

  const { clientColumns, myColumns, mySuppliersColumns, clientSuppliersColumns } = data || {};

  const [columns, setColumn] = useState({ myColumns, mySuppliersColumns, clientColumns, clientSuppliersColumns });

  const quoteSupplierColumns = useMemo(
    () => quoteSuppliers?.map(qs => ({ key: qs?.id, label: qs?.supplier?.name, default: true })),
    [quoteSuppliers]
  );

  const handleChangeCheckbox = (columnType, key, tab) => e => {
    const columnsKeyPrefix = columnType === 'myColumns' ? 'my' : 'client';
    const columnsKeySuffix = tab === 'items' ? 'Columns' : 'SuppliersColumns';

    const columnKey = columnsKeyPrefix + columnsKeySuffix;

    setColumn(prev => ({ ...prev, [columnKey]: { ...prev?.[columnKey], [key]: e.target.checked } }));
  };

  const tabs = [
    {
      key: 'items',
      title: 'Itens',
      icon: faList,
      warningText: 'Alterar as colunas atualizará a visão do mapa de cotação para todos.',
      myColumnTooltip: null,
      clientColumnTooltip: 'Permitir cliente visualizar as informações selecionadas',
      columns: quotationMapItemColumns,
      checkedMap: { myColumns: columns?.myColumns, clientColumns: columns?.clientColumns }
    },
    {
      key: 'suppliers',
      title: 'Fornecedores',
      icon: faShop,
      warningText: 'Alterar as colunas atualizará a visão do mapa de cotação para todos.',
      myColumnTooltip: null,
      clientColumnTooltip: 'Permitir cliente visualizar as informações selecionadas',
      columns: quoteSupplierColumns,
      checkedMap: { myColumns: columns?.mySuppliersColumns, clientColumns: columns?.clientSuppliersColumns }
    }
  ];

  return (
    <ConfigModal
      modalTitle="Configurar Mapa de Cotação"
      tabs={tabs}
      handleChangeCheckbox={handleChangeCheckbox}
      onSubmit={() => onSubmit(columns)}
      onClose={onClose}
    />
  );
};

QuotationMapConfigModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  quoteSuppliers: PropTypes.instanceOf(Array)
};

export default QuotationMapConfigModal;
