import styled, { css } from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import { colors, fonts, breakpoints, spaces, radius } from '../../styles/style';

export const StyledDrawer = styled(AntDrawer)`
  max-width: 100%;
  font-family: ${fonts.family};

  .ant-drawer-content-wrapper {
    max-width: ${props => props.maxWidth || '100%'};
  }

  .ant-drawer-header {
    border: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.neutral100};
    background-color: ${props => (props.extraDrawerBackgroundColor ? props.extraDrawerBackgroundColor : 'none')};
    padding: ${spaces.space2};
    &-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-title {
      font-family: ${fonts.family};
      font-size: ${fonts.sizeLg};
      font-weight: ${fonts.weight600};
      color: ${colors.neutral700};
      line-height: 1.5;
      width: 100%;
    }

    .ant-drawer-extra {
      display: flex;
      align-items: center;
    }
  }
  .ant-drawer-close {
    right: ${props => (props.$isMenuMobile ? 'auto' : 0)};
    padding: ${props => (props.$isMenuMobile ? `${spaces.space3} 0` : '')};
  }

  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
    background-color: ${props => props.backgroundColor || props.backgroundcolor || props.extraDrawerBackgroundColor};
    ::-webkit-scrollbar {
      width: ${spaces.space1};
    }
    ::-webkit-scrollbar-track {
      background: ${colors.neutral100};
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.neutral300};
      border-radius: ${radius.radius1};
    }

    section {
      overflow: hidden auto;
      height: 100%;
      padding: ${props => props.padding || `${spaces.space1} ${spaces.space3}`};
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: space-between;
    padding: ${spaces.space1};
  }

  @media (min-width: ${breakpoints.desktop}) {
    .ant-modal-title {
      font-size: ${fonts.sizeH5};
    }

    .ant-drawer-footer {
      padding: 12px ${spaces.space3};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  ${props =>
    props.$footerPadding &&
    css`
      .ant-drawer-footer {
        padding: ${props.$footerPadding};
      }
    `}
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  ${props =>
    !props.noTitlePadding &&
    css`
      padding-right: ${spaces.space4};
    `}

  width: 100%;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  ${({ extraContentOnRight }) =>
    extraContentOnRight &&
    css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `}
`;
