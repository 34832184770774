import React from 'react';
import PropTypes from 'prop-types';

// components
import { SimpleTable as SimpleStyledTable } from './Table.styled';

const SimpleTable = ({ data, columns, isExpandable = true, ...props }) => {
  return (
    <SimpleStyledTable
      rowKey={row => row.id}
      pagination={false}
      size="small"
      dataSource={data}
      columns={columns}
      {...(isExpandable
        ? {
            expandable: {
              rowExpandable: row => row?.children?.length > 0,
              expandedRowRender: record => (record?.children.length ? record : null)
            }
          }
        : {
            expandable: {
              rowExpandable: () => false,
              expandedRowRender: () => null,
              expandIconColumnIndex: -1
            }
          })}
      {...props}
    />
  );
};

SimpleTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  columns: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(Array)]).isRequired,
  isExpandable: PropTypes.bool
};

export default SimpleTable;
