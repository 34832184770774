import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Text from 'antd/lib/typography/Text';
import { Div, colors, fonts, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import { CustomBadge } from './AddAppropriation.styled';

const AddAppropriation = ({
  index,
  item,
  isMobile,
  childrenColumnName,
  levelsList,
  itemAppropriationMap,
  addAppropriation
}) => {
  const appropCount = item?.[childrenColumnName]?.length || 0;

  const handleClick = e => {
    e.stopPropagation();
    addAppropriation(
      item?.id,
      levelsList.filter(i => !itemAppropriationMap[item?.id]?.children?.[i.id])
    );
  };

  const PlusIcon = () => (
    <FontAwesomeIcon
      id={`add-appropriation-${index + 1}`}
      icon={faPlus}
      color={isMobile ? colors.primary500 : colors.primary600}
      size={!isMobile && 'xl'}
      width={fonts.sizeMd}
      onClick={isMobile ? null : handleClick}
    />
  );

  return isMobile ? (
    <Div gap={spaces.space1} onClick={handleClick}>
      <PlusIcon />
      <Paragraph type="small" color={colors.primary500}>
        Adicionar apropriação
      </Paragraph>
    </Div>
  ) : (
    <Div gap={spaces.space1}>
      <Tooltip placement="top" title="Adicionar apropriação">
        <FontAwesomeIcon
          id={`add-appropriation-${index + 1}`}
          icon={faPlus}
          color={colors.primary600}
          size="xl"
          onClick={e => {
            e.stopPropagation();
            addAppropriation(
              item?.id,
              levelsList.filter(i => !itemAppropriationMap[item?.id]?.children?.[i.id])
            );
          }}
        />
      </Tooltip>
      <Text>
        <CustomBadge>{appropCount}</CustomBadge>
      </Text>
    </Div>
  );
};

AddAppropriation.propTypes = {
  index: PropTypes.number,
  item: PropTypes.instanceOf(Object),
  isMobile: PropTypes.bool,
  childrenColumnName: PropTypes.string,
  levelsList: PropTypes.instanceOf(Array),
  itemAppropriationMap: PropTypes.instanceOf(Object),
  addAppropriation: PropTypes.func
};

export default AddAppropriation;
