import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { spaces, breakpoints } from '../../styles/style';

const Container = styled.div`
  ${props => props.height && `height: ${props.height};`}
  ${props => props.$minHeight && `min-height: ${props.$minHeight};`}
  padding: ${props => (props.padding || props.padding === 0 ? `${props.padding}` : spaces.space2)};
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.$marginFactor && `margin: ${spaces[`space${props.$marginFactor}`]};`}
  border-radius: ${props => (props.$borderRadius ? `${props.$borderRadius}` : spaces.space0)};
  ${props => props.overflow && `overflow: ${props.overflow};`}

  h3,
  h4 {
    &:first-of-type {
      margin-top: 0;
    }
  }

  background-color: ${props => props.$bgColor};

  @media (max-width: ${breakpoints.tablet}) {
    padding: ${props => (props.padding || props.padding === 0 ? `${props.padding}` : spaces.space1)};
    margin: 0;
  }
`;

const Content = ({
  children,
  className,
  padding,
  margin,
  borderRadius,
  bgColor,
  marginFactor,
  minHeight,
  ...otherProps
}) => {
  return (
    <Container
      className={className}
      padding={padding}
      margin={margin}
      $borderRadius={borderRadius}
      $bgColor={bgColor}
      $marginFactor={marginFactor}
      $minHeight={minHeight}
      {...otherProps}
    >
      {children}
    </Container>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginFactor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.string,
  bgColor: PropTypes.string,
  minHeight: PropTypes.string
};

export default Content;
