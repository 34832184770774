import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from 'antd/es/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import { Content, ImageBox, Name, Price, StyledCardItem, Type } from '../Drawer/CatalogDrawer.styles';
import ImageInput from '../Input/ImageInput';
import Button from '../Button/Button';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { colors, Div, spaces } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';
import FlagTag from '../Tag/FlagTag';

const CatalogCard = ({
  onSelect = null,
  data = {},
  redirectUrl,
  renderInComposition,
  className,
  addTooltipText,
  addIcon,
  iconStyle,
  tags,
  disableCardButton = false,
  addButtonId = 'add-catalog-item'
}) => {
  const { isMobile, isUltraWide } = useViewport(window.innerWidth);
  const [isHovering, setIsHovering] = useState(isMobile());
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const valueOfTooltip = isUltraWide() ? 102 : 70;

  const handleHover = value => event => {
    if (event?.preventDefault) event.preventDefault();
    setIsHovering(isMobile() || value);
  };

  const textTooltip = addTooltipText || `Adicionar ${renderInComposition ? 'na composição' : 'no orçamento'}`;

  const getUnitText = () => {
    let text = '';

    if (data?.idUnit > 0) text = `/${data?.units?.name}`;
    if (data?.unit) text = `/${data?.unit}`;
    return text.toLowerCase();
  };

  return (
    <StyledCardItem
      id={data?.id}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
      className={className}
      $hasClick={redirectUrl}
    >
      <ImageBox>
        <ImageInput
          key={`image${data?.id}`}
          id={data?.id}
          value={data?.image}
          images={data?.images}
          disabled
          size="72px"
          hoveredShow={isHovering}
          bigSize
          clickImage={handleHover(false)}
          hideSideBar
        />
      </ImageBox>
      <Content onClick={() => (redirectUrl ? window.open(redirectUrl, '_blank') : null)}>
        <Tooltip title={!isMobile() && data?.name?.length > valueOfTooltip ? data?.name : null}>
          <Name>
            {data?.code ? `${data.code} -` : null} {data?.name}
          </Name>
        </Tooltip>
        <Type>{data?.type === refurbishItemType.composition ? 'Composição' : data?.brand || '-'}</Type>
        <Div>
          <Price>
            {formatCurrency(data?.price, { currencySymbol: 'R$' })}
            {getUnitText()}
          </Price>

          <Space size={8}>
            {tags
              ?.filter(tag => !!tag)
              .map(tag => (
                <FlagTag key={tag} value={tag} className="primary" />
              ))}
            <Button
              id={addButtonId}
              shape="circle"
              type="primary"
              roundMaxHeight={spaces.space3}
              onClick={e => {
                e.stopPropagation();
                onSelect(data);
              }}
              loading={disableCardButton}
            >
              {!disableCardButton && (
                <Tooltip placement="topRight" title={!isMobile() ? textTooltip : null}>
                  <FontAwesomeIcon
                    icon={addIcon || faPlus}
                    rotation={addIcon ? undefined : 90}
                    color={colors.white}
                    style={{ color: colors.white, margin: '-1.5px', ...iconStyle }}
                  />
                </Tooltip>
              )}
            </Button>
          </Space>
        </Div>
      </Content>
    </StyledCardItem>
  );
};

CatalogCard.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onSelect: PropTypes.func,
  redirectUrl: PropTypes.string,
  renderInComposition: PropTypes.bool,
  className: PropTypes.string,
  addTooltipText: PropTypes.string,
  addIcon: PropTypes.instanceOf(Object),
  iconStyle: PropTypes.instanceOf(Object),
  tags: PropTypes.instanceOf(Array),
  disableCardButton: PropTypes.bool,
  addButtonId: PropTypes.string
};

export default CatalogCard;
