import styled from 'styled-components';
import { Typography } from 'antd';

import { fonts, colors, radius, spaces } from '../../styles/style';

const { Text } = Typography;

const StyledText = styled(Text)`
  color: ${colors.neutral500};
  font-size: ${props => (props.size ? fonts[props.size] : null)};
  padding-top: 8px;
`;

const StyledContentDiv = styled.div`
  background-color: ${colors.white};
  min-height: 64px;
  border-radius: ${radius.radius1};
  font-size: ${fonts.sizeSm};

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 150px;
  }

  .ant-checkbox-group-item {
    max-width: 220px;
    text-align: justify;
    font-size: ${fonts.sizeSm};
  }
`;

const DivTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spaces.space2};
  gap: ${spaces.space2};
  margin-bottom: ${spaces.space1};
`;

const HighlightBadGe = styled.div`
  min-width: ${spaces.space2};
  height: ${spaces.space2};
  border-radius: ${radius.radius1};
  background-color: ${colors.neutral400};
  color: ${colors.white};
  font-size: ${fonts.sizeXs};
  font-weight: ${fonts.weight700};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { StyledText, StyledContentDiv, DivTotal, HighlightBadGe };
