import React from 'react';
import { Tag } from 'antd';

import { Subtitle } from '../Text/Text';
import { colors, radius } from '../../styles/style';

const LowestPriceTag = () => (
  <Tag
    style={{
      borderColor: colors.green700,
      borderWidth: '1px',
      borderRadius: radius.radius3
    }}
  >
    <Subtitle color={colors.green700}>Menor preço</Subtitle>
  </Tag>
);

export default LowestPriceTag;
