import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AboutContent, CheckboxGroup, Group, StyledCheckbox } from './Onboarding.styled';
import { Subtitle, Title } from '../../../components/Text/Text';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import { fonts, spaces, colors } from '../../../styles/style';

const MainChallenges = ({ data, onChange }) => {
  const { mainChallengesList, toolsList } = useSelector(state => state.setup.systemData);

  const [_data, setData] = useState(data);
  const [isToolInputVisible, setIsToolInputVisible] = useState(false);
  const [isChallengeInputVisible, setIsChallengeInputVisible] = useState(false);

  const handleChange = ({ onboarding }) => {
    const newData = { onboarding: { ..._data?.onboarding, ...onboarding } };
    setData(newData);
    onChange(newData);
  };

  const handleTool = tool => {
    const currentTools = _data?.onboarding?.tools || [];
    const toolValue = tool.target.value;
    const updatedTools = currentTools.includes(toolValue)
      ? currentTools.filter(t => t !== toolValue)
      : [...currentTools, toolValue];

    handleChange({ onboarding: { tools: updatedTools } });
  };

  useEffect(() => {
    const isOther = _data?.onboarding?.tools?.includes(6);
    setIsToolInputVisible(isOther);

    if (!isOther) {
      handleChange({ onboarding: { otherTools: undefined } });
    }
  }, [_data?.onboarding?.tools]);

  useEffect(() => {
    const isOther = _data?.onboarding?.mainChallenge === 11;
    setIsChallengeInputVisible(isOther);

    if (!isOther) {
      handleChange({ onboarding: { otherChallenges: undefined } });
    }
  }, [_data?.onboarding?.mainChallenge]);

  return (
    <AboutContent>
      <Group gap={spaces.space1}>
        <Title size={fonts.sizeH5} id="onboarding-page-title">
          Conte-nos um pouco sobre você!
        </Title>
        <Subtitle weight={fonts.weight500} color={colors.neutral600}>
          Isso nos permitirá oferecer uma experiência ainda mais personalizada.
        </Subtitle>
      </Group>

      <Group>
        <Group gap={spaces.space2}>
          <Subtitle color={colors.neutral600}>
            Qual o principal desafio que está enfrentando e que levou à contratação da Vobi?
          </Subtitle>
          <Select
            id="challengeOption"
            name="challengeOption"
            options={mainChallengesList}
            value={_data?.onboarding?.mainChallenge}
            type="select"
            placeholder="Escolha uma opção"
            onChange={e => handleChange({ onboarding: { mainChallenge: e } })}
          />
          {isChallengeInputVisible && (
            <Input
              type="text"
              placeholder="Informe o valor aqui"
              value={_data?.onboarding?.otherChallenges}
              onChange={e => handleChange({ onboarding: { otherChallenges: e?.target?.value } })}
              style={{ maxWidth: '304px' }}
            />
          )}
        </Group>

        <Group gap={spaces.space2}>
          <Subtitle color={colors.neutral600}>
            Quais ferramentas utilizava antes da Vobi para se organizar?{' '}
            <Subtitle size={fonts.sizeSm} weight={fonts.weight400} color={colors.neutral500}>
              (selecione todos que aplicar)
            </Subtitle>
          </Subtitle>

          <CheckboxGroup>
            {toolsList.map(tool => (
              <StyledCheckbox
                value={tool.value}
                key={`tool${tool.value}`}
                checked={_data?.onboarding?.tools?.includes(tool.value)}
                onChange={e => handleTool(e)}
                style={{ margin: '0px' }}
              >
                {tool.label}
              </StyledCheckbox>
            ))}
          </CheckboxGroup>
          {isToolInputVisible && (
            <Input
              type="text"
              placeholder="Informe o valor aqui"
              value={_data?.onboarding?.otherTools}
              onChange={e => handleChange({ onboarding: { otherTools: e?.target?.value } })}
              style={{ maxWidth: '304px' }}
            />
          )}
        </Group>
      </Group>
    </AboutContent>
  );
};

MainChallenges.propTypes = {
  data: PropTypes.instanceOf(Object),
  onChange: PropTypes.func
};

export default MainChallenges;
