import styled from 'styled-components';
import { colors, spaces } from '../../../styles/style';

export const NameColumnContainer = styled.div`
  display: flex !important;
  align-items: center !important;

  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.gap && `gap: ${props.gap};`}
  a {
    color: ${colors.primary500};
  }

  @media print {
    .table-name-image-margin {
      margin: 0 ${spaces.space1};
    }

    .table-name-image-margin-left {
      margin: 0 calc(${spaces.space1} + ${spaces.space0}) 0 ${spaces.space0};
    }
  }
`;
